export var LegacyTheme;
(function (LegacyTheme) {
    LegacyTheme.legacyThemeObject = {
        theme1: {
            backgroundColor: "#FFFFFF",
            loginMenuBackgroundColor: "#F6F6F6",
            focusColor: "#eb89bf",
            loadingColor: "#FFFFFF",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#757575",
            fontColorBlack: "#000000",
            themeColor: "#bc2075",
            themeColorDark: "#a61c68",
            themeColorDarker: "#90185a",
            loginMenuLogoSrc: "/sl3/images/compWhiteBackground.svg",
        },
        theme2: {
            backgroundColor: "#FFFFFF",
            loginMenuBackgroundColor: "#F6F6F6",
            focusColor: "#FC970A",
            loadingColor: "#D62C1B",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#757575",
            fontColorBlack: "#000000",
            themeColor: "#62757F",
            themeColorDark: "#4E5D65",
            themeColorDarker: "#374248",
            loginMenuLogoSrc: "/sl3/images/comp.svg",
        },
        theme3: {
            backgroundColor: "#FFFFFF",
            loginMenuBackgroundColor: "#F6F6F6",
            focusColor: "#fec16d",
            loadingColor: "#757575",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#757575",
            fontColorBlack: "#000000",
            themeColor: "#fc970a",
            themeColorDark: "#e88802",
            themeColorDarker: "#cf7a02",
            loginMenuLogoSrc: "/sl3/images/compWhiteBackground.svg",
        },
        theme4: {
            backgroundColor: "#f0f9fb",
            loginMenuBackgroundColor: "#e0e0e0",
            focusColor: "#8c8c8c",
            loadingColor: "#FFFFFF",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#00575",
            fontColorBlack: "#000000",
            themeColor: "#7d7d7d",
            themeColorDark: "#333333",
            themeColorDarker: "#262626",
            loginMenuLogoSrc: "/sl3/images/comp.svg",
        },
        sedc_theme: {
            backgroundColor: "#FFFFFF",
            loginMenuBackgroundColor: "#F6F6F6",
            focusColor: "#d9853b",
            loadingColor: "#FFFFFF",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#757575",
            fontColorBlack: "#000000",
            themeColor: "#558c89",
            themeColorDark: "#4c7b79",
            themeColorDarker: "#426c69",
            loginMenuLogoSrc: "/sl3/images/comp.svg",
        },
        enQuesta_theme: {
            backgroundColor: "#FFFFFF",
            loginMenuBackgroundColor: "#F6F6F6",
            focusColor: "#718ac1",
            loadingColor: "#FFFFFF",
            errorColor: "#D62C1B",
            fontColorWhite: "#FFFFFF",
            fontColor: "#757575",
            fontColorBlack: "#000000",
            themeColor: "#45619d",
            themeColorDark: "#3d558a",
            themeColorDarker: "#354b79",
            loginMenuLogoSrc: "/sl3/images/comp.svg",
        },
    };
})(LegacyTheme || (LegacyTheme = {}));
