import { InventoryContextMenuContainerActionTypes } from "./types";
export const initialState = {
    xPosition: 0,
    yPosition: 0,
    isVisible: false,
    offset: 10,
    data: [],
    previousStates: {}
};
export function inventoryContextMenuContainerReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryContextMenuContainerActionTypes.SET_CONTEXT_MENU_STATE: {
            return action.inventoryContextMenuContainerState;
        }
        default:
            return state;
    }
}
