import moment from "moment";
import { Fsrs } from "../../api/REST/Fsrs/Fsrs";
import { Orders } from "../../api/REST/Orders/Orders";
import _ from "lodash";
import { Utils } from "../../../helper/General/Utils";
var leftJoinViaPropertySelectors = Utils.leftJoinViaPropertySelectors;
export const getFsrsCalendarEvents = (fsrId, dateOpened) => {
    const start = moment(dateOpened).startOf('month');
    const end = moment(dateOpened).endOf('month');
    const dateOpenedMoment = moment(dateOpened);
    const timetablesPromise = Fsrs.getTimeTables(start, end, [fsrId])
        .then(({ response }) => {
        let groupedActivities = _.groupBy(response
            .flatMap(timetable => timetable.timeBlocks)
            .flatMap(tb => tb.activities), act => act.type);
        return groupedActivities.Order || [];
    });
    const moveOrderToNow = (o) => {
        const convertDateToNow = isoTime => moment(isoTime)
            .set('year', dateOpenedMoment.get('year'))
            .set('month', dateOpenedMoment.get('month'))
            .set('date', dateOpenedMoment.get('date'))
            .toISOString(true);
        return (Object.assign(Object.assign({}, o), { appointmentStartTime: convertDateToNow(o.appointmentStartTime), appointmentEndTime: convertDateToNow(o.appointmentEndTime) }));
    };
    const ordersPromise = Promise.all([
        Orders.getOrdersAssignedToFsrsInDateRange(start.valueOf(), end.valueOf(), [fsrId], [{ "STATE_ID": { "$in": [70] } }]),
        Orders.getOrdersAssignedToFsrsInDateRange(dateOpenedMoment.endOf('day').valueOf(), end.valueOf(), [fsrId]),
        Orders.getOrdersUpToGivenTimeForFsrsExcludingCompletedOrders(dateOpenedMoment.endOf('day').valueOf(), [fsrId])
    ]).then(orderResults => _.uniqBy(orderResults.flatMap(x => x.response), (x) => x.id));
    return Promise
        .all([timetablesPromise, ordersPromise])
        .then(([timetables, orders]) => {
        const todayDate = moment(dateOpenedMoment).startOf('day');
        const activitiesAfterToday = timetables.filter(ta => ta.start >= todayDate.valueOf());
        const activitiesGrouped = _.groupBy(activitiesAfterToday, ta => moment(ta.start).date());
        const ordersGrouped = _.groupBy(orders, (order) => moment(order.appointmentStartTime).date());
        const useDateKey = ([key, _]) => key;
        const actJoin = [Object.entries(activitiesGrouped), useDateKey];
        const orderJoin = [Object.entries(ordersGrouped), useDateKey];
        const joinResults = leftJoinViaPropertySelectors(orderJoin, actJoin);
        return joinResults.flatMap(([ordersDay, timeBlockActivitiesDay]) => {
            const [, orders] = ordersDay;
            if (timeBlockActivitiesDay == null) {
                //Assigned orders only exist on this day
                return orders;
            }
            else {
                const [, timeBlockActivities] = timeBlockActivitiesDay;
                const idToTimeBlock = _.keyBy(timeBlockActivities, (ta) => ta.order.id);
                const idToOrder = _.keyBy(orders, (o) => o.id);
                const tbOrders = timeBlockActivities
                    .filter(ta => idToOrder[ta.order.id] != null) //Schedules can have unassigned orders in them...
                    .map((ta) => {
                    const { order: { id, orderNumber }, start, end } = ta;
                    const originalOrder = idToOrder[id];
                    return Object.assign(Object.assign({}, originalOrder), {
                        number: orderNumber,
                        appointmentStartTime: moment(start).toISOString(true),
                        appointmentEndTime: moment(end).toISOString(true),
                    });
                });
                let calendarEvents = [...orders.filter(order => idToTimeBlock[order.id] == null), ...tbOrders];
                return calendarEvents;
            }
        });
    });
};
export function getCalculations(distanceSummary) {
    return {
        distance: getDistance(distanceSummary),
        date: getOffsetDate(distanceSummary),
        scheduleKey: distanceSummary.scheduleKey,
        ofInterest: distanceSummary.ofInterest,
        actualLocation: distanceSummary.actualLocation
    };
}
function getDistance(distanceSummary) {
    return parseFloat((distanceSummary.distance / 1609.344).toFixed(2));
    // return distanceSummary.distance;
}
function getOffsetDate(distanceSummary) {
    return recToDate(distanceSummary);
}
function recToDate(distanceSummary) {
    return moment(`${distanceSummary.day}/${distanceSummary.month}/${distanceSummary.year}`, "DD/MM/YYYY");
}
