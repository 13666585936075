import { LoginPageContainerActionTypes } from "./types";
export const initialState = {
    isInitialized: false,
    initialLogin: "",
    initialBusinessUnit: "",
    company: "",
    accessToken: "",
    errorMessage: "",
    message: "",
    businessUnitOptions: [],
    isLoadingComplete: true,
    activeDirectoryEnabled: false,
    slAuthEnabled: true,
    plainAdLoginEnabled: false,
    isTwoFactorAuthenticationEnabled: false,
    isTwoFactorAuthenticating: false,
    activeDirectoryMode: "",
    isAskingForBU: false,
    doMicrosoftLogout: false,
    isADLogin: false,
    countdownTimeSeconds: 60,
    contactInformation: "",
    logoBarLogoSrc: "/resources/images/comp.svg",
    loginMenuLogoSrc: "/resources/images/comp.svg",
    height: 456,
    width: 350,
    titleBarHeight: 50,
    backgroundColor: "#F6F6F6",
    focusColor: "#FC970A",
    loadingColor: "#D62C1B",
    errorColor: "#D62C1B",
    fontColorWhite: "#FFFFFF",
    fontColor: "#757575",
    fontColorBlack: "#000000",
    themeColor: "#62757F",
    themeColorDark: "#4E5D65",
    themeColorDarker: "#374248"
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE: {
            return action.state;
        }
        case LoginPageContainerActionTypes.SET_ACCESS_TOKEN: {
            return Object.assign(Object.assign({}, state), { accessToken: action.token });
        }
        case LoginPageContainerActionTypes.SET_AD_LOGIN: {
            return Object.assign(Object.assign({}, state), { isADLogin: action.isADLogin });
        }
        default:
            return state;
    }
};
export default reducer;
