import { useDrop } from "react-dnd";
import styled from "styled-components";
import React, { useState } from "react";
import { Fsrs } from "../../../state/api/REST/Fsrs/Fsrs";
import moment from "moment";
import { RouteSwitchButton } from "../FsrScheduler/RouteSwitchButton";
import "./style.css";
import { getIconStateName, getRowColor } from "../../../legacy/services/Theme/Fsr";
import { SLRawHtmlTooltip, SLTooltip } from "../FsrScheduler/Material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Spacer from "../../web/components/Spacer/Spacer";
import { Utils } from "../../../helper/General/Utils";
import { useRouteShowOnHoverWithGuiData } from "../../../hooks/Dispatch/useRouteShow";
import { DraggableTypes } from "../../../hooks/DragDrop/types";
import { dispatchOrders } from "../../../state/containers/DispatchPageContainer/actions";
import { toISODateFormat } from "../../web/components/FsrCalendar/FsrCalendar";
import { FlexParent } from "../../../helper/Layout/FlexParent";
var getRawHtmlTooltipForFsrProperty = Fsrs.getRawHtmlTooltipForFsrProperty;
import DOMPurify from 'dompurify';
export const FsrTimeTableCard = (props) => {
    var _a, _b;
    const { selectableRef, isSelected, isSelecting, selectSelf } = props;
    const { group: fsr } = props;
    const timeTable = fsr ? fsr.timeTable : undefined;
    const fsrId = parseInt((fsr === null || fsr === void 0 ? void 0 : fsr.FSR_ID) || "-1");
    const dispatch = useDispatch();
    const currentDate = useSelector(gState => gState.dispatch.date);
    const useDateDropDispatch = (date) => useDrop({
        accept: DraggableTypes.order,
        drop: (item) => {
            dispatch(dispatchOrders([fsrId], item.selectedOrders, toISODateFormat(date)));
        },
        collect: monitor => {
            return {
                dragHoverClassName: monitor.isOver() ? "drop_target" : "",
            };
        }
    });
    const [{ dragHoverClassName }, dropRef] = useDateDropDispatch(moment(currentDate));
    const topRef = passedRef => (fsr === null || fsr === void 0 ? void 0 : fsr.recommendations) ? Utils.doTo(passedRef, selectableRef)
        : Utils.doTo(passedRef, selectableRef, dropRef);
    const RecommendTodayCell = (props) => {
        const { distance } = props;
        const todayRef = useRouteShowOnHoverWithGuiData(props, fsrId);
        const [{ dragHoverClassName }, dropRef] = useDateDropDispatch(moment(currentDate));
        return (React.createElement(FlexParent, { vertical: true, className: `${dragHoverClassName}`, style: { flexGrow: 1 } },
            React.createElement(GenericEntry, { ref: passedRef => Utils.doTo(passedRef, todayRef, dropRef), style: { textAlign: "left" } },
                React.createElement("div", null,
                    React.createElement("b", null, "Today")),
                React.createElement("div", null,
                    "Distance: ",
                    distance))));
    };
    const RecommendFutureCell = (props) => {
        const { distance, date } = props;
        const futureRef = useRouteShowOnHoverWithGuiData(props, fsrId);
        const [{ dragHoverClassName }, dropRef] = useDateDropDispatch(date);
        return (React.createElement(FlexParent, { vertical: true, className: `${dragHoverClassName}`, style: { flexGrow: 1 } },
            React.createElement(GenericEntry, { ref: passedRef => Utils.doTo(passedRef, futureRef, dropRef), style: { textAlign: "left" } },
                React.createElement("div", null,
                    React.createElement("b", null, "Future Date:"),
                    " ",
                    date.format("YYYY-MM-DD")),
                React.createElement("div", null,
                    "Distance: ",
                    distance))));
    };
    if (fsr && fsr.FSR_ID) {
        let fsrCoordinate = { latitude: parseFloat(fsr.lat), longitude: parseFloat(fsr.lon) };
        let routeState = parseInt(fsr.ROUTE_STATE);
        let { backgroundColorWithOpacity, backgroundColor } = stateNameAndIndexToStyle(fsr, fsr.rowId);
        let firstBarStyle = { backgroundColor, height: "100%", width: "10px" };
        firstBarStyle.backgroundColor =
            isSelected ? `blue` :
                isSelecting ? `orange` :
                    backgroundColor;
        return (React.createElement("div", { ref: topRef, className: `sch-fsr-tr ${dragHoverClassName}`, "data-group-index": fsr.rowId, style: {
                backgroundColor: backgroundColorWithOpacity,
                width: "100%",
                height: "100%",
                display: "flex",
            }, onContextMenu: onContextMenu },
            React.createElement("div", { className: `first-bar`, style: firstBarStyle }),
            React.createElement("div", { className: "fsr-panel", style: {
                    display: "flex",
                    flexDirection: "column",
                    margin: "3px",
                    width: "calc(100% - 16px)",
                    overflow: "hidden"
                } },
                React.createElement(FlexParent, null,
                    React.createElement(RouteSwitchButton, { fsrId: fsrId, worker: fsr, routeState: routeState, coordinate: fsrCoordinate }),
                    React.createElement(Spacer, { width: 3 }),
                    React.createElement(SLRawTooltipFsr, { fsrId: fsr.FSR_ID, propertyName: "NAME" },
                        React.createElement(LongEntry, { backgroundColor: backgroundColorWithOpacity },
                            React.createElement("b", null, fsr.NAME),
                            " ")),
                    React.createElement(SLTooltip, { title: `Total Time: ${fsr["TOTAL_TIME"]}` },
                        React.createElement("span", null,
                            React.createElement("b", null, "OT:"),
                            " ",
                            fsr.TOTAL_ORDERS)),
                    React.createElement(GenericEntry, null,
                        React.createElement("b", null, "OR:"),
                        " ",
                        fsr.REMAINING_ORDERS),
                    React.createElement(GenericEntry, null,
                        React.createElement("b", null, "OC:"),
                        " ",
                        parseInt(fsr.TOTAL_ORDERS) - parseInt(fsr.REMAINING_ORDERS),
                        " "),
                    React.createElement(Spacer, { width: 3 }),
                    React.createElement(ShiftStatusLegacy, { worker: fsr }),
                    React.createElement(Spacer, { width: 3 }),
                    React.createElement(WorkerStatusLegacy, { worker: fsr })),
                React.createElement(FlexParent, null,
                    React.createElement(SLRawTooltipFsr, { fsrId: fsr.FSR_ID, propertyName: "AREA" },
                        React.createElement(LongEntry, { backgroundColor: backgroundColorWithOpacity },
                            React.createElement("span", null, fsr.AREA))),
                    React.createElement(Utilization, { timeTable: timeTable || { utilization: 0.0, efficiency: 0.0 } })),
                fsr.recommendations ? (React.createElement(FlexParent, null,
                    React.createElement(RecommendTodayCell, Object.assign({}, (_a = fsr === null || fsr === void 0 ? void 0 : fsr.recommendations) === null || _a === void 0 ? void 0 : _a.todayBest)),
                    React.createElement(RecommendFutureCell, Object.assign({}, (_b = fsr === null || fsr === void 0 ? void 0 : fsr.recommendations) === null || _b === void 0 ? void 0 : _b.futureBest)))) : null)));
    }
    else {
        return React.createElement("div", { ref: topRef }, "Loading...");
    }
    function SLRawTooltipFsr({ fsrId, propertyName, children }) {
        let currentDate = useSelector(reduxState => reduxState.dispatch.currentDate, shallowEqual);
        let [tooltipHtml, setTooltipHtml] = useState("Getting Data...");
        return React.createElement(SLRawHtmlTooltip, { enterDelay: 1000, html: tooltipHtml, onOpen: evt => getRawHtmlTooltipForFsrProperty(fsrId, propertyName, currentDate)
                .then(({ response }) => setTooltipHtml(response)) }, children);
    }
    function Utilization({ timeTable }) {
        let util = Math.round(timeTable.utilization * 100), utilClass = "";
        let eff = Math.round(timeTable.efficiency * 100), effClass = "";
        if (util > 0 && util <= 30) {
            utilClass = "sl-error";
        }
        else if (util > 30 && util <= 60) {
            utilClass = "sl-warning";
        }
        if (eff > 0 && eff <= 30) {
            effClass = "sl-error";
        }
        else if (eff > 30 && eff <= 60) {
            effClass = "sl-warning";
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(GenericEntry, { className: utilClass }, `util.: ${util}%`),
            React.createElement(GenericEntry, { className: effClass }, `eff.: ${eff}%`)));
    }
    function ShiftStatusLegacy({ worker }) {
        let shiftHtml = window.sl.getRemainingColumnsHtml(worker, 0, ["SHIFT"])[0];
        shiftHtml = shiftHtml.replace(/td/g, "div");
        let htmlElements = $(shiftHtml);
        let tooltipHtml = htmlElements.attr("title");
        htmlElements.removeAttr("title");
        return React.createElement(SLRawHtmlTooltip, { html: tooltipHtml },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(htmlElements.html()) } }));
    }
    function WorkerStatusLegacy({ worker }) {
        let html = window.getIconCellHtmlFactory("fsr")(worker, "", "", "", "STATE_ID");
        html = html.replace(/td/g, "div");
        let htmlElementJQuery = $(html);
        htmlElementJQuery.find("i").first()
            .css("position", undefined)
            .css("display", "block");
        let title = htmlElementJQuery.attr("title");
        htmlElementJQuery.removeAttr("title");
        let transformedHtml = htmlElementJQuery[0].outerHTML;
        return (React.createElement(SLRawHtmlTooltip, { html: title },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(transformedHtml) } })));
    }
    function onContextMenu(event) {
        selectSelf();
        buildDispatchContextMenu("fsr", event.nativeEvent);
    }
    function stateNameAndIndexToStyle(dataPoint, idx) {
        let [rowBaseColor, rowColor, isEmergency] = getRowColor(dataPoint, idx);
        return {
            stateIconName: getIconStateName(dataPoint, dataPoint.STATE_ID),
            backgroundColor: rowBaseColor,
            backgroundColorWithOpacity: rowColor
        };
    }
};
const GenericEntry = styled.div `
  :first-child {
    margin-left: 0;
  }
  margin-left: 1em;
`;
const LongEntry = styled.div `
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
flex-grow: 1;
width: 100px; //TODO: width should probably not be fixed 

  &:hover {
    overflow: visible;
    position: relative;
  }

  *:hover {
    background-color: ${props => props.backgroundColor};
    z-index: 1;
    position: relative;
  }
`;
