import React from "react";
import "./ContextMenuSeparator.css";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
const StyledContextMenuSeparator = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height || "0.2rem")};
  width: ${props => StyleUtils.getCssPixelString(props.width || "15.2rem")};
  background-color: ${props => props.backgroundColor || props.palette.black};
`;
class ContextMenuSeparator extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        return React.createElement(StyledContextMenuSeparator, Object.assign({ className: "context-menu-separator" }, this.props, this.theme));
    }
}
export default ContextMenuSeparator;
