import React from "react";
import SimpleButton from "../../../../SimpleButton/SimpleButton";
var ACCORDION_BUTTON_STATUS;
(function (ACCORDION_BUTTON_STATUS) {
    ACCORDION_BUTTON_STATUS["EXPANDED"] = "SL-Arrow-Down";
    ACCORDION_BUTTON_STATUS["HIDDEN"] = "SL-Arrow-Right";
})(ACCORDION_BUTTON_STATUS || (ACCORDION_BUTTON_STATUS = {}));
class AccordionExpandButton extends React.Component {
    constructor(props) {
        super(props);
        this.onButtonClickHandler = () => {
            this.props.toggleExpand();
        };
        this.state = {
            isHovering: false
        };
    }
    render() {
        const { isExpanded, size, color, backgroundColor } = this.props;
        const accordionButtonIconName = isExpanded
            ? ACCORDION_BUTTON_STATUS.EXPANDED
            : ACCORDION_BUTTON_STATUS.HIDDEN;
        return (React.createElement(SimpleButton, { className: "accordion-expand-button", iconProps: {
                iconName: accordionButtonIconName,
                size: size
            }, color: color, backgroundColor: backgroundColor, buttonClick: this.onButtonClickHandler }));
    }
}
export default AccordionExpandButton;
