import React from "react";
import { createStyles, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
const useStyles = makeStyles((theme) => createStyles({
    backdrop: {
        zIndex: 9999,
        position: "relative",
        left: "0px",
        top: "-100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    progress: {
        color: theme.palette.background.paper,
    }
}));
const LoadingPageTransparent = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { isLoading, } = props;
    return (React.createElement(Backdrop, { className: classes.backdrop, open: isLoading },
        React.createElement(CircularProgress, { className: classes.progress })));
};
export default LoadingPageTransparent;
