import { put, select, takeEvery } from "redux-saga/effects";
import { InventoryFilterContainerActionTypes } from "./types";
import { FILTER_ROW_TYPE } from "../../../display/components/Filter/FilterList/FilterRow/FilterRow";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { updateMultiFilter } from "../../services/InventoryFilter/actions";
import { Utils } from "../../../helper/General/Utils";
import { IconService } from "../../../helper/Icon/IconService";
var INVENTORY_TABLE_ICONS = IconService.INVENTORY_TABLE_ICONS;
import Cookie from "universal-cookie";
const cookie = new Cookie();
const SERVER_URL = "";
export const inventoryFilterContainerSagas = {
    getConfigurationSaga: takeEvery(InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION, getConfigurationSaga),
    deleteConfigurationSaga: takeEvery(InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_CONFIGURATION_REQUEST, deleteConfigurationSaga),
    saveConfigurationSaga: takeEvery(InventoryFilterContainerActionTypes.INVENTORY_FILTER_SAVE_CONFIGURATION_REQUEST, saveConfigurationSaga),
    filterStateChangeSaga: takeEvery(InventoryFilterContainerActionTypes.INVENTORY_FILTER_MUTATE, filterStateChangeSaga)
};
function* filterStateChangeSaga(action) {
    if (action.innerAction === undefined)
        return;
    yield put(action.innerAction);
    let inventoryFilterContainerState = yield select(selectInventoryFilterState);
    let workingConfigurationState = inventoryFilterContainerState.workingConfiguration;
    let filterRowsAsFilters = workingConfigurationState.filterRowsData
        //TODO: small hack to massage the data as the working state no longer has a type variable...this shouldn't be necessary
        .map((filterRow) => {
        let schema = inventoryFilterContainerState.schemas[filterRow.name];
        return {
            filterRowState: filterRow.filterRowState,
            filterRowData: null,
            type: schema.type,
            name: filterRow.name
        };
    })
        .map(function (filterRow) {
        switch (filterRow.type) {
            case FILTER_ROW_TYPE.CHECK_BOX:
                if (filterRow.filterRowState.selectedValues.length === 0)
                    return undefined;
                return [
                    {
                        field: filterRow.name,
                        operator: FilterUtils.J_FILTER_OPERATORS.in,
                        value: filterRow.filterRowState.selectedValues
                    }
                ];
            case FILTER_ROW_TYPE.MULTI_SELECT:
                if (filterRow.filterRowState.selectedKeys.length === 0)
                    return undefined;
                return [
                    {
                        field: filterRow.name,
                        operator: FilterUtils.J_FILTER_OPERATORS.in,
                        value: filterRow.filterRowState.selectedKeys
                    }
                ];
            case FILTER_ROW_TYPE.STRING:
                if (Utils.isNullOrEmpty(filterRow.filterRowState.filterString))
                    return undefined;
                return [
                    {
                        field: filterRow.name,
                        operator: filterRow.filterRowState.operator,
                        value: filterRow.filterRowState.filterString
                    }
                ];
        }
    })
        .filter(x => x);
    yield put(updateMultiFilter(filterRowsAsFilters));
}
function* deleteConfigurationSaga({ name }) {
    yield put({
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_CONFIGURATION_RESPONSE,
        name: name
    });
    let inventoryFilterContainerState = yield select(selectInventoryFilterState);
    yield saveStateToServer(inventoryFilterContainerState);
}
function* saveConfigurationSaga({ name }) {
    yield put({
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_SAVE_CONFIGURATION_RESPONSE,
        name: name
    });
    let inventoryFilterContainerState = yield select(selectInventoryFilterState);
    yield saveStateToServer(inventoryFilterContainerState);
}
function saveStateToServer(inventoryFilterContainerState) {
    return fetch(SERVER_URL + "/sl3/rest/userSettingsDispatch", {
        method: "POST",
        headers: { "Content-Type": "application/json",
            "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
        },
        body: JSON.stringify(inventoryFilterContainerState)
    });
}
function* getConfigurationSaga(action) {
    const globalState = yield select(getGlobalStateSelector);
    const inventoryCache = yield select(getCacheStateSelector);
    let response = yield fetch(SERVER_URL + "/sl3/rest/userSettingsDispatch");
    const stateValues = globalState.stateTransitions.stateTransitions.map(stateTransition => {
        const value = stateTransition.id.toString();
        const iconName = INVENTORY_TABLE_ICONS[stateTransition.stateName.toUpperCase()] || "";
        let displayedText = Utils.snakeCaseToReadable(stateTransition.stateName);
        if (displayedText === "Rma") {
            displayedText = "R.M.A.";
        }
        return {
            value: value,
            displayedText: displayedText,
            iconName: iconName
        };
    });
    const openIndex6Values = inventoryCache.pallets.map(pallet => {
        return {
            key: pallet.id.toString(),
            displayedText: `Pallet ${pallet.id}`
        };
    });
    const boxIds = inventoryCache.boxes.map(box => box.id);
    const parentContainerValues = Utils.removeArrayDuplicates(boxIds).map(box => {
        return {
            key: box,
            displayedText: `Box ${box}`
        };
    });
    let schemas = {
        State: {
            filterRowData: {
                values: stateValues
            },
            type: FILTER_ROW_TYPE.CHECK_BOX,
            displayedText: "Inventory State Filter"
        },
        "date 1": {
            filterRowData: {},
            type: FILTER_ROW_TYPE.DATE,
            displayedText: "Date Filter"
        },
        OpenIndex6: {
            filterRowData: {
                multiSelectData: openIndex6Values
            },
            type: FILTER_ROW_TYPE.MULTI_SELECT,
            displayedText: "Pallet Filter"
        },
        ParentContainer: {
            filterRowData: {
                multiSelectData: parentContainerValues
            },
            type: FILTER_ROW_TYPE.MULTI_SELECT,
            displayedText: "Box Filter"
        },
        "string 1": {
            filterRowData: {},
            type: FILTER_ROW_TYPE.STRING,
            displayedText: "String Filter"
        },
        forTest: {
            filterRowData: {},
            type: FILTER_ROW_TYPE.STRING,
            displayedText: "Newly added schema"
        }
    };
    if (!response.ok) {
        let inventoryFilterContainerState = yield select(selectInventoryFilterState);
        inventoryFilterContainerState = Utils.deepClone(inventoryFilterContainerState);
        inventoryFilterContainerState.schemas = schemas;
        yield put({
            type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION_RESPONSE,
            state: inventoryFilterContainerState
        });
        return;
    }
    let body = yield response.json();
    body.schemas = schemas;
    yield put({
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION_RESPONSE,
        state: body
    });
}
function getGlobalStateSelector(store) {
    return store.inventory.inventoryGlobalState;
}
function getTableStateSelector(store) {
    return store.inventory.inventoryTableContainer;
}
function getCacheStateSelector(store) {
    return store.inventory.inventoryCache;
}
function selectInventoryFilterState(state) {
    return state.inventory.inventoryFilterContainer;
}
export default inventoryFilterContainerSagas;
