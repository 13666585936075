var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import SelectableGroupContext from './Context';
class DeselectAllButton extends Component {
    constructor() {
        super(...arguments);
        this.root = null;
        this.getRootRef = (ref) => {
            this.root = ref;
        };
    }
    componentDidMount() {
        this.root.addEventListener('mousedown', (evt) => evt.stopPropagation());
    }
    render() {
        const _a = this.props, { component: ButtonComponent = 'div', children, className } = _a, rest = __rest(_a, ["component", "children", "className"]);
        return (React.createElement(ButtonComponent, Object.assign({ ref: this.getRootRef, className: `selectable-select-all ${className}`, onClick: this.context.selectable.clearSelection }, rest), children));
    }
}
DeselectAllButton.contextType = SelectableGroupContext;
export default DeselectAllButton;
