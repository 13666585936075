export var ManagePageContainerActionTypes;
(function (ManagePageContainerActionTypes) {
    ManagePageContainerActionTypes["SET_USER_FILTER"] = "manage.page.SET_USER_FILTER";
    ManagePageContainerActionTypes["USER_FILTER_WINDOW_INIT"] = "manage.page.USER_FILTER_WINDOW_INIT";
    ManagePageContainerActionTypes["SAVE_USER_FILTER"] = "manage.page.SAVE_USER_FILTER";
    ManagePageContainerActionTypes["CALENDAR_FILTER_WINDOW_INIT"] = "manage.page.CALENDAR_FILTER_WINDOW_INIT";
    ManagePageContainerActionTypes["SET_CALENDAR_FILTER"] = "manage.page.SET_CALENDAR_FILTER";
    ManagePageContainerActionTypes["SET_CALENDAR_FILTER_OPEN"] = "manage.page.SET_CALENDAR_FILTER_OPEN";
    ManagePageContainerActionTypes["SET_BIND_VEHICLE_WINDOW_STATE"] = "manage.page.SET_BIND_VEHICLE_WINDOW_STATE";
    ManagePageContainerActionTypes["OPEN_BIND_VEHICLE_WINDOW"] = "manage.page.OPEN_BIND_VEHICLE_WINDOW";
    ManagePageContainerActionTypes["BIND_VEHICLE"] = "manage.page.BIND_VEHICLE";
    ManagePageContainerActionTypes["SET_UNBIND_VEHICLE_WINDOW_STATE"] = "manage.page.SET_UNBIND_VEHICLE_WINDOW_STATE";
    ManagePageContainerActionTypes["OPEN_UNBIND_VEHICLE_WINDOW"] = "manage.page.OPEN_UNBIND_VEHICLE_WINDOW";
    ManagePageContainerActionTypes["UNBIND_VEHICLE"] = "manage.page.UNBIND_VEHICLE";
    ManagePageContainerActionTypes["SAVE_CALENDAR_FILTER"] = "manage.page.SAVE_CALENDAR_FILTER";
})(ManagePageContainerActionTypes || (ManagePageContainerActionTypes = {}));
