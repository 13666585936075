import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Callout, getTheme, DirectionalHint } from "office-ui-fabric-react";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
const StyledSimpleDateTimePicker = styled.div `
  height: 1.6rem;
  width: 28.8rem;
  padding: 0.1rem 0.5rem;
  border-style: solid;
  border-color: ${props => props.palette.black};
  border-width: 0.1rem;

  background-color: ${props => props.palette.white};
`;
const SimpleDateTimePickerDisplayText = styled.div `
  height: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${props => props.palette.black};
  user-select: none;
  cursor: pointer;
`;
class SimpleDateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.defaultSimpleDateTimeFormat = "MMMM Do YYYY, hh:mm a";
        this.handleDateTimeChange = (dateTimeMillis) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedTime: dateTimeMillis, calloutIsOpen: false, displayText: moment(dateTimeMillis).format(this.props.dateTimeFormat || this.defaultSimpleDateTimeFormat) })));
        };
        this.handleCloseCallout = () => {
            this.handleDateTimeChange(this.state.selectedTime);
        };
        this.handleOpenCallout = () => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { calloutIsOpen: true })));
        };
        this.displayTextElement = React.createRef();
        const currentTime = moment.now().valueOf();
        const currentTimeNoSeconds = moment(currentTime)
            .second(0)
            .millisecond(0)
            .valueOf();
        this.state = {
            currentTime: currentTimeNoSeconds,
            selectedTime: currentTimeNoSeconds,
            displayText: moment(currentTimeNoSeconds).format(this.props.dateTimeFormat || this.defaultSimpleDateTimeFormat),
            calloutIsOpen: false
        };
    }
    render() {
        const { currentTime, selectedTime, displayText, calloutIsOpen } = this.state;
        return (React.createElement(StyledSimpleDateTimePicker, Object.assign({ className: "simple-date-time-picker" }, this.theme, { ref: this.displayTextElement }),
            React.createElement(SimpleDateTimePickerDisplayText, Object.assign({ className: "simple-date-time-picker-display-text", onClick: this.handleOpenCallout }, this.theme), displayText),
            calloutIsOpen ? (React.createElement(Callout, { target: this.displayTextElement.current, className: "simple-date-time-picker-callout", gapSpace: 0, isBeakVisible: false, onDismiss: this.handleCloseCallout, directionalHint: DirectionalHint.bottomLeftEdge },
                React.createElement(DateTimePicker, { handleDateTimeChange: this.handleDateTimeChange, initialDateTime: selectedTime }))) : null));
    }
}
export default SimpleDateTimePicker;
