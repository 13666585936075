import React from "react";
import styled from "styled-components";
import CheckBoxFilterRow from "./FilterRows/CheckBoxFilterRow/CheckBoxFilterRow";
import DateFilterRow from "./FilterRows/DateFilterRow/DateFilterRow";
import MultiSelectFilterRow from "./FilterRows/MultiSelectFilterRow/MultiSelectFilterRow";
import StringFilterRow from "./FilterRows/StringFilterRow/StringFilterRow";
export var FILTER_ROW_TYPE;
(function (FILTER_ROW_TYPE) {
    FILTER_ROW_TYPE["CHECK_BOX"] = "CHECK_BOX";
    FILTER_ROW_TYPE["DATE"] = "DATE";
    FILTER_ROW_TYPE["MULTI_SELECT"] = "MULTI_SELECT";
    FILTER_ROW_TYPE["STRING"] = "STRING";
})(FILTER_ROW_TYPE || (FILTER_ROW_TYPE = {}));
const StyledFilterRow = styled.div `
  width: 100%;
  height: max-content;
  font-size: 1.6rem;
`;
class FilterRow extends React.Component {
    constructor(props) {
        super(props);
        this.getFilterRow = () => {
            switch (this.props.type) {
                case FILTER_ROW_TYPE.CHECK_BOX:
                    return React.createElement(CheckBoxFilterRow, Object.assign({}, this.props));
                case FILTER_ROW_TYPE.DATE:
                    return React.createElement(DateFilterRow, Object.assign({}, this.props));
                case FILTER_ROW_TYPE.MULTI_SELECT:
                    return React.createElement(MultiSelectFilterRow, Object.assign({}, this.props));
                case FILTER_ROW_TYPE.STRING:
                    return React.createElement(StringFilterRow, Object.assign({}, this.props));
            }
        };
    }
    render() {
        const { index } = this.props;
        return (React.createElement(StyledFilterRow, { key: index, className: "filter-row" }, this.getFilterRow()));
    }
}
export default FilterRow;
