import axios from "axios";
export function getDocument(documentFileName) {
    return axios({
        method: "GET",
        url: `/sl3/rest/docs/${documentFileName}`,
    });
}
export function saveDocument(documentFileName, payload) {
    return axios({
        method: "POST",
        url: `/sl3/rest/docs/${documentFileName}`,
        data: { content_to_save: payload }
    });
}
export function getSavePermissionsState() {
    return axios({
        method: "GET",
        url: `/sl3/rest/docs/state`,
    });
}
