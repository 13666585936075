import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
export const StyledSpacer = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
`;
const Spacer = (props) => {
    const { height = 0, width = 0 } = props;
    return (React.createElement(StyledSpacer, { className: "spacer", height: height, width: width }));
};
export default Spacer;
