import React from "react";
import DOMPurify from 'dompurify';
var html = require("./index.html");
var page = { __html: DOMPurify.sanitize(html) };
class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "dashboard-page", dangerouslySetInnerHTML: page }));
    }
}
export default DashboardPage;
