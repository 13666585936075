import React from "react";
import FirstCell from "./FirstCell/FirstCell";
import IconCell from "./IconCell/IconCell";
import TextCell from "./TextCell/TextCell";
import SchedulerCell from "./SchedulerCell/SchedulerCell";
export var TABLE_CELL_TYPES;
(function (TABLE_CELL_TYPES) {
    TABLE_CELL_TYPES["FirstCell"] = "FirstCell";
    TABLE_CELL_TYPES["IconCell"] = "IconCell";
    TABLE_CELL_TYPES["TextCell"] = "TextCell";
    TABLE_CELL_TYPES["SchedulerCell"] = "SchedulerCell";
})(TABLE_CELL_TYPES || (TABLE_CELL_TYPES = {}));
class TableCell extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        switch (this.props.type) {
            case TABLE_CELL_TYPES.FirstCell: {
                return React.createElement(FirstCell, Object.assign({}, this.props));
            }
            case TABLE_CELL_TYPES.IconCell: {
                return React.createElement(IconCell, Object.assign({}, this.props));
            }
            case TABLE_CELL_TYPES.TextCell: {
                return React.createElement(TextCell, Object.assign({}, this.props));
            }
            case TABLE_CELL_TYPES.SchedulerCell: {
                return React.createElement(SchedulerCell, Object.assign({}, this.props));
            }
        }
    }
}
export default TableCell;
