import React, { useEffect } from "react";
import { LegacyScriptLoader } from "../../helper/LegacyScriptLoader";
import DOMPurify from 'dompurify';
var html = require("./index.html");
var page = { __html: DOMPurify.sanitize(html) };
const LoginPage = (props) => {
    useEffect(() => {
        LegacyScriptLoader.loadScripts([
            "/sl3/scripts/jquery.min.js",
            "/sl3/scripts/jquery-migrate.min.js",
            "/sl3/scripts/jquery.cookie.min.js",
            "/sl3/scripts/login.js",
            "/sl3/scripts/sl-common.js",
            "/sl3/scripts/iehack.js",
        ]);
    }, []);
    return (React.createElement("div", { className: "login-page", dangerouslySetInnerHTML: page }));
};
export default LoginPage;
