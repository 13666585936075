import React from "react";
import { Button } from "@material-ui/core";
import { FlexParent } from "../../../../../helper/Layout/FlexParent";
import Spacer from "../../../components/Spacer/Spacer";
export const DocumentationNodeEditBar = (props) => {
    const { isEdit, allowDelete, allowSave, handleSave, handleEdit, handleCreate, handleDelete, handleDocxUpload } = props;
    return (!allowSave
        ? null
        : React.createElement(FlexParent, { style: { margin: 2.5 } },
            React.createElement(Button, { variant: "contained", color: "primary", onClick: isEdit ? handleEdit : handleSave }, isEdit ? "Save" : "Edit"),
            React.createElement(Spacer, { width: 5 }),
            React.createElement(Button, { variant: "contained", onClick: handleCreate }, "Create Page"),
            React.createElement(Spacer, { width: 5 }),
            allowDelete
                ? null
                : React.createElement(Button, { variant: "contained", onClick: handleDelete }, "Delete Page"),
            " ",
            React.createElement(Spacer, { width: 5 }),
            React.createElement(Button, { variant: "contained", component: "label" },
                React.createElement("input", { type: "file", onInput: handleDocxUpload, style: { display: "none" } }),
                "Upload .docx")));
};
