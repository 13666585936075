import React from "react";
import styled from "styled-components";
import moment from "moment";
import SimpleDateTimePicker from "../../../../../SimpleDateTimePicker/SimpleDateTimePicker";
import { getTheme } from "office-ui-fabric-react";
import Dropdown from "../../../../../Dropdown/Dropdown";
import SimpleButton from "../../../../../SimpleButton/SimpleButton";
const StyledDateFilterRow = styled.div `
  height: 4.1rem;
  width: 57.4rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const StyledDropdownWrapper = styled.div `
  height: 2rem;
  padding: 1.05rem 0;
  width: 15rem;
`;
const StyledSeparator = styled.div `
  height: 4.1rem;
  width: 1rem;
`;
const StyledDateSelectWrapper = styled.div `
  height: 2.6rem;
  width: 30rem;
`;
const StyledDateSeparator = styled.div `
  height: 0.1rem;
  width: 30rem;
`;
const StyledButtonAndIsAppliedWrapper = styled.div `
  height: 4.1rem;
  width: 10.4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;
const StyledIsApplied = styled.div `
  height: 1.2rem;
  width: 10rem;
  padding: 0.2rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${props => props.isApplied === null ||
    (!props.operator && (!props.filterPrimaryDate || (props.requireTwoDates && !props.filterSecondaryDate)))
    ? props.palette.black
    : props.palette.white};
  text-align: center;
  background-color: ${props => {
    const { isApplied, filterPrimaryDate, filterSecondaryDate, requireTwoDates, operator } = props;
    if (isApplied === null || (!operator && (!filterPrimaryDate || (requireTwoDates && !filterSecondaryDate)))) {
        return props.palette.neutralLight;
    }
    else {
        if (isApplied) {
            return props.palette.green;
        }
        else {
            return props.palette.red;
        }
    }
}}
  user-select: none;
`;
var DATE_FILTER_ROW_OPTIONS;
(function (DATE_FILTER_ROW_OPTIONS) {
    DATE_FILTER_ROW_OPTIONS["EQUALS"] = "EQUALS";
    DATE_FILTER_ROW_OPTIONS["NOT_EQUALS"] = "NOT_EQUALS";
    DATE_FILTER_ROW_OPTIONS["DATE_RANGE"] = "DATE_RANGE";
})(DATE_FILTER_ROW_OPTIONS || (DATE_FILTER_ROW_OPTIONS = {}));
class DateFilterRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.dateFilterRowOptions = [
            {
                key: DATE_FILTER_ROW_OPTIONS.EQUALS,
                text: "Equals to"
            },
            {
                key: DATE_FILTER_ROW_OPTIONS.NOT_EQUALS,
                text: "Not equals to"
            },
            {
                key: DATE_FILTER_ROW_OPTIONS.DATE_RANGE,
                text: "Date Range"
            }
        ];
        this.getIsAppliedText = () => {
            const { isApplied, filterPrimaryDate, requireTwoDates, filterSecondaryDate, operator } = this.state;
            if (isApplied === null || (!operator && (!filterPrimaryDate || (requireTwoDates && !filterSecondaryDate)))) {
                return "No filter selected";
            }
            else {
                if (isApplied) {
                    return "Filter applied";
                }
                else {
                    return "Filter not applied";
                }
            }
        };
        this.handleOperatorChange = (event) => { };
        this.handleApplyFilter = () => { };
        this.handlePrimaryDateChange = (primaryDateTime) => { };
        this.handleSecondaryDateChange = (secondaryDateTime) => { };
        this.state = {
            filterPrimaryDate: null,
            requireTwoDates: false,
            filterSecondaryDate: null,
            operator: null,
            isApplied: false
        };
    }
    render() {
        const currentTime = moment.now().valueOf();
        return (React.createElement(StyledDateFilterRow, { className: "date-filter-row-wrapper" },
            React.createElement(StyledDropdownWrapper, { className: "sting-filter-row-dropdown" },
                React.createElement(Dropdown, { options: this.dateFilterRowOptions, handleChange: this.handleOperatorChange })),
            React.createElement(StyledSeparator, { className: "date-filter-separator" }),
            React.createElement(StyledDateSelectWrapper, { className: "date-filter-date-time-picker-wrapper" },
                React.createElement(SimpleDateTimePicker, { handleDateTimeChange: this.handlePrimaryDateChange }),
                React.createElement(StyledDateSeparator, { className: "date-filter-date-time-picker-separator" }),
                React.createElement(SimpleDateTimePicker, { handleDateTimeChange: this.handleSecondaryDateChange })),
            React.createElement(StyledSeparator, { className: "date-filter-separator" }),
            React.createElement(StyledButtonAndIsAppliedWrapper, { className: "date-filter-button-wrapper" },
                React.createElement(StyledIsApplied, Object.assign({ className: "date-filter-is-applied" }, this.props, this.state, this.theme), this.getIsAppliedText()),
                React.createElement(SimpleButton, { className: "date-filter-button", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, padding: "0.3rem 1.1rem", textProps: {
                        width: "8rem",
                        height: "1.2rem",
                        iconText: "Apply Filter",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.handleApplyFilter }))));
    }
}
export default DateFilterRow;
