import uuidv1 from "uuid/v1";
import _ from "lodash";
import * as moment from "moment";
import compose1 from "compose-function";
export var Utils;
(function (Utils) {
    function deepClone(object) {
        try {
            return _.cloneDeep(object);
        }
        catch (error) {
            console.error(`Unable to deep clone object: ${object}.\n${error}.`);
        }
    }
    Utils.deepClone = deepClone;
    function callDynamicFunction(dynamicFunction, ...args) {
        try {
            dynamicFunction(...args);
        }
        catch (error) {
            console.error(`Unable to call function ${dynamicFunction}. \n${error}.`);
        }
    }
    Utils.callDynamicFunction = callDynamicFunction;
    function generateUUIDV1() {
        return uuidv1();
    }
    Utils.generateUUIDV1 = generateUUIDV1;
    function getCookieByName(cookieName) {
        let cookieString = null;
        try {
            cookieString = document.cookie.split(`; ${cookieName}=`)[1];
            cookieString = cookieString.split(";")[0];
        }
        catch (error) {
            console.error(`Cookie with the name ${cookieName} does not exist.`);
            return null;
        }
        return cookieString;
    }
    Utils.getCookieByName = getCookieByName;
    function snakeToCamelCase(s) {
        return s.replace(/([-_][a-z])/g, group => group
            .toUpperCase()
            .replace("-", "")
            .replace("_", ""));
    }
    Utils.snakeToCamelCase = snakeToCamelCase;
    function camelToSnakeCase(s) {
        return s.split(/(?=[A-Z])/).join('_').toLowerCase();
    }
    Utils.camelToSnakeCase = camelToSnakeCase;
    function isObject(obj) {
        return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function";
    }
    Utils.isObject = isObject;
    function snakeToCamelCaseObject(obj, exceptions = []) {
        if (isObject(obj)) {
            const newObj = {};
            Object.keys(obj).forEach(key => {
                if (exceptions.includes(key)) {
                    newObj[key] = obj[key];
                }
                else {
                    newObj[snakeToCamelCase(key)] = snakeToCamelCaseObject(obj[key], exceptions);
                }
            });
            return newObj;
        }
        else if (Array.isArray(obj)) {
            return obj.map(i => {
                return snakeToCamelCaseObject(i, exceptions);
            });
        }
        return obj;
    }
    Utils.snakeToCamelCaseObject = snakeToCamelCaseObject;
    function camelToSnakeCaseObject(obj, exceptions) {
        if (isObject(obj)) {
            const newObj = {};
            Object.keys(obj).forEach(key => {
                if (exceptions.includes(key)) {
                    newObj[key] = obj[key];
                }
                else {
                    newObj[camelToSnakeCase(key)] = camelToSnakeCaseObject(obj[key], exceptions);
                }
            });
            return newObj;
        }
        else if (Array.isArray(obj)) {
            return obj.map(i => {
                return camelToSnakeCaseObject(i, exceptions);
            });
        }
        return obj;
    }
    Utils.camelToSnakeCaseObject = camelToSnakeCaseObject;
    function getObjectValue(searchString, obj) {
        let value = "";
        const searchStringArray = searchString.split("/");
        let objHolder = Object.assign({}, obj);
        for (let i = 0; i < searchStringArray.length; i++) {
            const newObj = objHolder[searchStringArray[i]];
            if (newObj === undefined || newObj === null) {
                break;
            }
            if (i === searchStringArray.length - 1) {
                value = newObj;
            }
            objHolder = Object.assign({}, newObj);
        }
        return value;
    }
    Utils.getObjectValue = getObjectValue;
    function getTextFromDataAndValues(data, values, separator, postProcessor) {
        let displayText = "";
        values.forEach(attribute => {
            const attributeValue = Utils.getObjectValue(attribute, data);
            if (attributeValue !== undefined) {
                displayText =
                    displayText === "" ? `${attributeValue}` : `${displayText}${separator ? separator : ""}${attributeValue}`;
            }
            else {
                console.error(`Unable to find attribute for display text: ${attribute}.`);
                console.error(data);
            }
        });
        if (postProcessor) {
            displayText = postProcessor(displayText);
        }
        return displayText;
    }
    Utils.getTextFromDataAndValues = getTextFromDataAndValues;
    function camelCaseToReadable(s) {
        const snakeCaseString = camelToSnakeCase(s);
        return snakeCaseToReadable(snakeCaseString);
    }
    Utils.camelCaseToReadable = camelCaseToReadable;
    function snakeCaseToReadable(s) {
        if (!s) {
            return "";
        }
        const stringArray = s.split("_");
        if (stringArray.length === 1) {
            return capitalizeFirstCharacter(stringArray[0]);
        }
        else {
            return stringArray.map(segment => capitalizeFirstCharacter(segment)).join(" ");
        }
    }
    Utils.snakeCaseToReadable = snakeCaseToReadable;
    function capitalizeFirstCharacter(s) {
        if (!s) {
            return "";
        }
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    Utils.capitalizeFirstCharacter = capitalizeFirstCharacter;
    function millisDatePostProcessor(s) {
        const timeMillis = typeof s === "number" ? s : parseInt(s);
        if (!timeMillis) {
            return "";
        }
        else {
            return moment.unix(timeMillis / 1000).format("YYYY-MM-DD HH:mm");
        }
    }
    Utils.millisDatePostProcessor = millisDatePostProcessor;
    function moveArrayElements(array, source, destination) {
        const arr = deepClone(array);
        arr.splice(destination, 0, arr.splice(source, 1)[0]);
        return arr;
    }
    Utils.moveArrayElements = moveArrayElements;
    function objectIsEmpty(object) {
        return Object.entries(object).length === 0 && object.constructor === Object;
    }
    Utils.objectIsEmpty = objectIsEmpty;
    function getRootFontSize() {
        return parseInt(window
            .getComputedStyle(document.body)
            .getPropertyValue("font-size")
            .replace("px", "")) || 10;
    }
    Utils.getRootFontSize = getRootFontSize;
    function removeArrayDuplicates(array) {
        return [...new Set(array)];
    }
    Utils.removeArrayDuplicates = removeArrayDuplicates;
    function getArrayMatchingElements(arrays) {
        return arrays.shift().reduce(function (res, v) {
            if (res.indexOf(v) === -1 &&
                arrays.every(function (a) {
                    return a.indexOf(v) !== -1;
                }))
                res.push(v);
            return res;
        }, []);
    }
    Utils.getArrayMatchingElements = getArrayMatchingElements;
    function isNullOrEmpty(str) {
        return str == null || str.length === 0;
    }
    Utils.isNullOrEmpty = isNullOrEmpty;
    Utils.range = _.range;
    function rangeInclusive(start, end, step) {
        return end === undefined ? Utils.range(0, start + 1, step) : Utils.range(start, end + 1, step);
    }
    Utils.rangeInclusive = rangeInclusive;
    function peek(obj, msg) {
        if (msg == undefined)
            console.log(`The value was: ${obj}`);
        else
            console.log(`${msg}: ${obj}`);
        return obj;
    }
    Utils.peek = peek;
    function normalizeMillisToDay(time) {
        const msInDay = 86400000;
        return time / msInDay;
    }
    Utils.normalizeMillisToDay = normalizeMillisToDay;
    function readFileAsString(file) {
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.readAsText(file);
        });
    }
    Utils.readFileAsString = readFileAsString;
    function when(boolVal, mapToRun) {
        return boolVal ? mapToRun() : null;
    }
    Utils.when = when;
    function doTo(x, ...functions) {
        functions.forEach(fx => fx(x));
        return x;
    }
    Utils.doTo = doTo;
    Utils.leftJoinViaPropertySelectors = (...listsWithSelectorFunctions) => {
        let [lists, selectorFunctions] = _.unzip(listsWithSelectorFunctions);
        let entries = _.groupBy(lists.flatMap(x => x), x => selectorFunctions.reduce((acc, fx) => fx(x) || acc, null));
        //This returns the list in the order of the first list as groupBy
        //will not preserve ordering
        return lists[0].map(x => entries[selectorFunctions[0](x)]);
    };
    Utils.distance = (distanceInMeters) => {
        return {
            to: (unit) => distanceInMeters * unitToMeterConversion[unit],
            //TODO: this doesn't make much sense being on an instance
            name: (unit) => unitToStringRepresentation[unit]
        };
    };
    let DistanceUnits;
    (function (DistanceUnits) {
        DistanceUnits[DistanceUnits["KM"] = 0] = "KM";
        DistanceUnits[DistanceUnits["MILES"] = 1] = "MILES";
    })(DistanceUnits = Utils.DistanceUnits || (Utils.DistanceUnits = {}));
    function createMapping(kvps) {
        return Object.fromEntries(kvps);
    }
    const unitToMeterConversion = createMapping([
        [DistanceUnits.KM, Math.pow(1, -3)],
        [DistanceUnits.MILES, 0.000621371]
    ]);
    const unitToStringRepresentation = createMapping([
        [DistanceUnits.KM, "kilometers"],
        [DistanceUnits.MILES, "miles"]
    ]);
    Utils.compose = compose1;
    Utils.isJsonString = (s) => {
        try {
            JSON.parse(s);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    Utils.isInt = (s) => {
        const intMatch = s.match(/^-{0,1}\d+$/);
        return intMatch == null ? false : intMatch.length > 0;
    };
    //funcStr can be null, new Function still runs
    Utils.runCustomCode = (funcStr, inputData) => {
        const func = new Function("$INPUT", "moment", funcStr);
        let outputData = null;
        try {
            outputData = func(inputData, moment);
        }
        catch (e) {
            outputData = e;
        }
        return outputData;
    };
    Utils.parseCSV = (str) => {
        const arr = [];
        let quote = false;
        const delims = [',', ';', '|', '\t'];
        for (let row = 0, col = 0, c = 0; c < str.length; c++) {
            var cc = str[c], nc = str[c + 1]; // Current character, next character
            arr[row] = arr[row] || []; // Create a new row if necessary
            arr[row][col] = arr[row][col] || ''; // Create a new column (start with empty string) if necessary
            if (cc == '"' && quote && nc == '"') {
                arr[row][col] += cc;
                ++c;
                continue;
            }
            if (cc == '"') {
                quote = !quote;
                continue;
            }
            if (delims.includes(cc) && !quote) {
                ++col;
                continue;
            }
            if (cc == '\r' && nc == '\n' && !quote) {
                ++row;
                col = 0;
                ++c;
                continue;
            }
            if (cc == '\n' && !quote) {
                ++row;
                col = 0;
                continue;
            }
            if (cc == '\r' && !quote) {
                ++row;
                col = 0;
                continue;
            }
            arr[row][col] += cc;
        }
        return arr;
    };
})(Utils || (Utils = {}));
