import React from "react";
import styled from "styled-components";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
import SwitchInput from "../../../Input/SwitchInput/SwitchInput";
export const StyledBooleanFilterRow = styled.div `
  height: 24px;
  width: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const BooleanFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, handleFilterChange, } = props;
    const handleClearFilter = () => {
    };
    const handleChange = (value) => {
        const newFilter = {
            [filterKey]: {
                $eq: [value ? "true" : "false"],
            }
        };
        handleFilterChange({
            key: filterKey,
            filterObject: newFilter,
        });
    };
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledBooleanFilterRow, null,
            React.createElement(SwitchInput, { label: { on: "True", off: "False" }, startingValue: initialFilter === null || initialFilter === void 0 ? void 0 : initialFilter.$eq.includes("true"), handleChange: handleChange }))));
};
export default BooleanFilterRow;
