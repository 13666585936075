import React from "react";
import styled from "styled-components";
import OrderHistoryRow, { HistoryTableCommonStyles } from "./OrderHistoryRow";
const StyledChildValidatorHistoryTable = styled.div `
  ${HistoryTableCommonStyles}
`;
export const ORDER_HISTORY_FIELDS = {
    "EVENT": "Event",
    "ORIGINATOR": "Originator",
    "FSR": "Fsr",
    "STATE": "State",
    "TIMESTAMP": "Timestamp",
    "ONLINE_OFFLINE": "Online/Offline",
    "RECEIVED_TIMESTAMP": "Received Timestamp",
    "COMMENTS": "Comments"
};
export class ChildValidatorHistoryTable extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledChildValidatorHistoryTable, null,
            React.createElement("div", null,
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null, Object.keys(ORDER_HISTORY_FIELDS).map(fieldName => {
                            return (React.createElement("th", { "data-field": fieldName }, ORDER_HISTORY_FIELDS[fieldName]));
                        }))))),
            React.createElement("div", null,
                React.createElement("table", null,
                    React.createElement("tbody", null, Array.from(this.props.orderHistoryRows).map((orderHistoryRow) => {
                        return (React.createElement(OrderHistoryRow, { orderHistoryRowData: orderHistoryRow }));
                    }))))));
    }
}
