import { InventoryFilterContainerActionTypes } from "./types";
import { Utils } from "../../../helper/General/Utils";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { FILTER_ROW_TYPE } from "../../../display/components/Filter/FilterList/FilterRow/FilterRow";
export const initialState = {
    workingConfigurationName: "Default",
    schemas: {
        Field1: {
            filterRowData: {
                values: [
                    { displayedText: "Pending", iconName: "FsrTable-Fsr_OnSite-StateIcon", value: "10" },
                    { displayedText: "En Route", iconName: "FsrTable-Fsr_OnSite-StateIcon", value: "40" },
                    { displayedText: "On Site", iconName: "FsrTable-Fsr_OnSite-StateIcon", value: "35" },
                    { displayedText: "Cancelled", value: "90" },
                    { displayedText: "On Hold", value: "50" },
                    { displayedText: "Dispatched", value: "30" },
                    { displayedText: "Assigned", value: "20" }
                ]
            },
            type: FILTER_ROW_TYPE.CHECK_BOX,
            displayedText: "Inventory State Filter"
        },
        State: {
            filterRowData: {
                values: [
                    { value: "24", displayedText: "unverified" },
                    { value: "25", displayedText: "quarantined" },
                    { value: "26", displayedText: "in_test" },
                    { value: "27", displayedText: "available" },
                    { value: "28", displayedText: "checked_out" },
                    { value: "29", displayedText: "deployed" },
                    { value: "30", displayedText: "installed" },
                    { value: "31", displayedText: "missing" },
                    { value: "32", displayedText: "damaged" },
                    { value: "33", displayedText: "unavailable" },
                    { value: "34", displayedText: "created_in_error" }
                ]
            },
            type: FILTER_ROW_TYPE.CHECK_BOX,
            displayedText: "Order State Filter"
        },
        "date 1": {
            filterRowData: {},
            type: FILTER_ROW_TYPE.DATE,
            displayedText: "Date Filter"
        },
        "multi select 1": {
            filterRowData: {
                multiSelectData: [
                    { key: "b", displayedText: "wew" },
                    { key: "c", displayedText: "candy" },
                    { key: "d", displayedText: "dates" },
                    { key: "e", displayedText: "eggs" },
                    { key: "f", displayedText: "fries" },
                    { key: "a", displayedText: "apple" }
                ]
            },
            type: FILTER_ROW_TYPE.MULTI_SELECT,
            displayedText: "MultiSelect Filter"
        },
        "string 1": {
            filterRowData: {},
            type: FILTER_ROW_TYPE.STRING,
            displayedText: "String Filter"
        }
    },
    workingConfiguration: {
        filterRowsData: []
    },
    savedConfigurations: {
        Default: {
            filterRowsData: [
                {
                    filterRowState: {
                        selectedValues: []
                    },
                    name: "State",
                    isExpanded: true
                }
            ]
        }
    }
};
export function inventoryFilterContainerReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_TOGGLE_EXPAND: {
            const filterDataState = state.workingConfiguration.filterRowsData[action.index];
            filterDataState.isExpanded = !filterDataState.isExpanded;
            return state;
        }
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_STATE_UPDATE:
            let rowData = state.workingConfiguration.filterRowsData;
            let updatedState = action.update;
            rowData[updatedState.index].filterRowState = updatedState;
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_CHANGE_WORKING_SET:
            state.workingConfigurationName = action.name;
            state.workingConfiguration = Utils.deepClone(state.savedConfigurations[action.name]);
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_SAVE_CONFIGURATION_RESPONSE:
            state.savedConfigurations[action.name] = Utils.deepClone(state.workingConfiguration);
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_CONFIGURATION_RESPONSE:
            let [firstName, firstConfiguration] = Object.entries(state.savedConfigurations)[0];
            delete state.savedConfigurations[action.name];
            state.workingConfiguration = firstConfiguration;
            state.workingConfigurationName = firstName;
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_RESET_CONFIGURATION:
            let workingConfiguration = Utils.deepClone(state.savedConfigurations[state.workingConfigurationName]);
            state.workingConfiguration = workingConfiguration;
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_ROW:
            delete state.workingConfiguration.filterRowsData[action.index];
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_FIELDS_CHANGED:
            let alreadyExistingRows = Utils.deepClone(state.workingConfiguration.filterRowsData).reduce((acc, filterRow) => {
                return Object.assign(Object.assign({}, acc), { [filterRow.name]: filterRow });
            }, {});
            let filterRowStates = (state.workingConfiguration.filterRowsData = action.fieldsToFilterOn.map((filterName) => {
                let schema = state.schemas[filterName];
                let existingRowState = alreadyExistingRows[filterName];
                let filterRowState = null;
                //TODO: shouldn't have to create empty lists + objects?
                switch (schema.type) {
                    case FILTER_ROW_TYPE.CHECK_BOX:
                        filterRowState = { selectedValues: [] };
                        break;
                    case FILTER_ROW_TYPE.STRING:
                        filterRowState = {
                            filterString: "",
                            isApplied: false,
                            operator: FilterUtils.J_FILTER_OPERATORS.equal
                        };
                        break;
                    case FILTER_ROW_TYPE.MULTI_SELECT:
                        filterRowState = { selectedKeys: [] };
                        break;
                    case FILTER_ROW_TYPE.DATE:
                        filterRowState = {
                            filterPrimaryDate: null,
                            requireTwoDates: false,
                            filterSecondaryDate: null,
                            operator: null,
                            isApplied: false
                        };
                        break;
                }
                return {
                    name: filterName,
                    isExpanded: existingRowState ? existingRowState.isExpanded : false,
                    filterRowState: existingRowState ? existingRowState.filterRowState : filterRowState
                };
            }));
            state.workingConfiguration.filterRowsData = filterRowStates;
            return state;
        case InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION_RESPONSE:
            return action.state;
        default:
            return state;
    }
}
