import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryOwner;
(function (InventoryOwner) {
    InventoryOwner.OwnerServiceName = "owner";
    let OwnerActionName;
    (function (OwnerActionName) {
        OwnerActionName["OWNER_TYPES"] = "owner_types";
        OwnerActionName["CONTAINER"] = "container";
        OwnerActionName["CONTAINER_ASSIGN_ME"] = "containers/assign_me";
        OwnerActionName["CONTAINER_CLEAR"] = "container/clear";
        OwnerActionName["CONTAINERS"] = "containers";
        OwnerActionName["CONTAINERS_COUNT"] = "containers/count";
        OwnerActionName["CONTAINERS_MY"] = "containers/my";
        OwnerActionName["CONTAINER_HISTORY_QUERY"] = "container/history/query";
        OwnerActionName["INVENTORY"] = "inventory";
        OwnerActionName["INVENTORY_ASSIGN_ME"] = "inventory/assign_me";
        OwnerActionName["INVENTORY_CLEAR"] = "inventory/clear";
        OwnerActionName["INVENTORIES"] = "inventories";
        OwnerActionName["INVENTORIES_COUNT"] = "inventories/count";
        OwnerActionName["INVENTORIES_MY"] = "inventories/my";
        OwnerActionName["INVENTORY_HISTORY_QUERY"] = "inventory/history/query";
    })(OwnerActionName = InventoryOwner.OwnerActionName || (InventoryOwner.OwnerActionName = {}));
    function ownerTypesRequest() {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.OWNER_TYPES,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.ownerTypesRequest = ownerTypesRequest;
    function containerRequestById(ownerType, ownerId, targetId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER,
            request: {
                ownerType: ownerType,
                ownerId: ownerId,
                targetId: targetId,
                usePath: false
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerRequestById = containerRequestById;
    function containerRequestByPath(ownerType, ownerId, containerPath) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER,
            request: {
                ownerType: ownerType,
                ownerId: ownerId,
                containerPath: containerPath,
                usePath: true
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerRequestByPath = containerRequestByPath;
    function containerAssignMeRequestById(targetId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER_ASSIGN_ME,
            request: {
                targetId: targetId,
                usePath: false
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerAssignMeRequestById = containerAssignMeRequestById;
    function containerAssignMeRequestByPath(containerPath) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER_ASSIGN_ME,
            request: {
                containerPath: containerPath,
                usePath: true
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerAssignMeRequestByPath = containerAssignMeRequestByPath;
    function containerClearRequest() {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER_CLEAR,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerClearRequest = containerClearRequest;
    function containersRequest(startIndex, endIndex, ownerType, ownerId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINERS,
            request: {
                start: startIndex,
                end: endIndex,
                ownerType: ownerType,
                ownerId: ownerId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containersRequest = containersRequest;
    function containersCountRequest(ownerType, ownerId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINERS_COUNT,
            request: {
                ownerType: ownerType,
                ownerId: ownerId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containersCountRequest = containersCountRequest;
    function containersMyRequest(startIndex, endIndex) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINERS_MY,
            request: {
                start: startIndex,
                end: endIndex
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containersMyRequest = containersMyRequest;
    function inventoryRequestById(ownerType, ownerId, targetId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY,
            request: {
                ownerType: ownerType,
                ownerId: ownerId,
                targetId: targetId,
                usePath: false
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryRequestById = inventoryRequestById;
    function inventoryRequestByPath(ownerType, ownerId, itemId, containerPath) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY,
            request: {
                ownerType: ownerType,
                ownerId: ownerId,
                itemId: itemId,
                containerPath: containerPath,
                usePath: true
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryRequestByPath = inventoryRequestByPath;
    function inventoryAssignMeRequestById(targetId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY_ASSIGN_ME,
            request: {
                targetId: targetId,
                usePath: false
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryAssignMeRequestById = inventoryAssignMeRequestById;
    function inventoryAssignMeRequestByPath(itemId, containerPath) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY_ASSIGN_ME,
            request: {
                itemId: itemId,
                containerPath: containerPath,
                usePath: true
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryAssignMeRequestByPath = inventoryAssignMeRequestByPath;
    function inventoryClearRequest() {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY_CLEAR,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryClearRequest = inventoryClearRequest;
    function inventoriesRequest(startIndex, endIndex, ownerType, ownerId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORIES,
            request: {
                start: startIndex,
                end: endIndex,
                ownerType: ownerType,
                ownerId: ownerId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoriesRequest = inventoriesRequest;
    function inventoriesCountRequest(ownerType, ownerId) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORIES_COUNT,
            request: {
                ownerType: ownerType,
                ownerId: ownerId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoriesCountRequest = inventoriesCountRequest;
    function inventoriesMyRequest(startIndex, endIndex) {
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORIES_MY,
            request: {
                start: startIndex,
                end: endIndex
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoriesMyRequest = inventoriesMyRequest;
    function containerHistoryQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.CONTAINER_HISTORY_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.containerHistoryQueryRequest = containerHistoryQueryRequest;
    function inventoryHistoryQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryOwner.OwnerServiceName,
            action: OwnerActionName.INVENTORY_HISTORY_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryOwner.inventoryHistoryQueryRequest = inventoryHistoryQueryRequest;
})(InventoryOwner || (InventoryOwner = {}));
