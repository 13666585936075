import VectorLayer from "ol/layer/vector";
export class VectorGroupLayer extends VectorLayer {
    constructor(options) {
        super(options);
        this._layers = options.layers;
    }
    setMap(map) {
        this._layers.forEach(layer => layer.setMap(map));
    }
}
