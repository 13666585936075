import { InventoryUtils } from "../../state/api/WebSockets/Inventory/Utils";
export var FsrUtils;
(function (FsrUtils) {
    function getFsrFromServerObject(serverObject) {
        return {
            communicationOffline: serverObject.COMMUNICATION_OFFLINE,
            communicationFailure: serverObject.COMM_FAILURE,
            isCrew: serverObject.CREW,
            fsrId: parseInt(serverObject.FSR_ID) || 0,
            hasFsrNotes: serverObject.FSR_NOTES === "true" ? true : false,
            gpsDeviceStatus: serverObject.GPS_DEVICE_STATUS,
            icon: serverObject.ICON,
            login: serverObject.LOGIN,
            isPool: serverObject.POOL,
            routeState: parseInt(serverObject.ROUTE_STATE) || 0,
            state: parseInt(serverObject.STATE_ID),
            lat: parseInt(serverObject.lat) || 0,
            lon: parseInt(serverObject.lon) || 0
        };
    }
    FsrUtils.getFsrFromServerObject = getFsrFromServerObject;
    function getFsrLookupObjects(fsrIds) {
        return new Promise((resolve, reject) => {
            const allPromises = fsrIds.map(id => InventoryUtils.userLookupRequest(id));
            Promise.all(allPromises).then(responses => {
                const lookupObjects = responses.map(response => response.response);
                lookupObjects.push({
                    id: -1,
                    name: "N/A",
                    buId: -1,
                    buName: "N/A",
                    companyId: -1,
                    companyName: "N/A"
                });
                resolve(lookupObjects);
            });
        });
    }
    FsrUtils.getFsrLookupObjects = getFsrLookupObjects;
})(FsrUtils || (FsrUtils = {}));
