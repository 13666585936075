import { useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import { DraggableTypes } from "../DragDrop/types";
import { getCalculations } from "../../state/services/Dispatch/service";
import { Routing } from "../../state/api/REST/Routing/Routing";
import { getMapIconFromMapIconData, getMapRouteFromMapRoutingData, getNewUniqueColor } from "../../state/containers/DispatchPageContainer/sagas";
import { OrderAPI } from "../../state/api/REST/Order/Order";
import axios from "axios";
import { useEventListener } from "../General/useEventListener";
export const removeRouteOnMap = () => {
    //Clear Hover Route
    clearRoutes();
};
export const clearRoutes = () => {
    window.store.dispatch({
        type: "dispatch.page.SET_MAP_HOVER_ROUTE_DATA",
        mapHoverRouteData: null,
    });
};
const handleNoMapRoute = (fsrId, recommendDateString, mapIconCount, theme, fsrVehMode, fsrMapIcon, orderMapIcon) => {
    const getAssignedOrdersUrl = `/sl3/api/orders?start=1&end=1000&columns=STATE_ID&filter=%7B%22%24and%22%3A%5B%7B%22ASSIGNED_FSR_ID%22%3A%7B%22%24in%22%3A%5B%22${fsrId}%22%5D%7D%7D%5D%7D&filter_date=${recommendDateString}&loadtype=full&winnum=1`;
    axios({
        method: "POST",
        url: getAssignedOrdersUrl,
    }).then(rsp => {
        var _a;
        const assignedOrdersData = ((_a = rsp === null || rsp === void 0 ? void 0 : rsp.data) === null || _a === void 0 ? void 0 : _a.data) || [];
        const assignedOrders = assignedOrdersData.filter(o => o.lat !== null && o.lon !== null).map((order) => {
            mapIconCount++;
            const orderNumber = order.ORDER_NUMBER;
            const orderId = parseInt(order.ORDER_ID);
            const orderStateId = parseInt(order.STATE_ID);
            const isEmergency = order.EMG;
            const lat = parseInt(order.lat);
            const lon = parseInt(order.lon);
            const orderChild = {
                name: orderNumber,
                legacyIdentifier: `${orderId},${orderStateId},${orderNumber}`,
                type: "order",
                id: orderId,
                emergency: isEmergency,
                state: orderStateId,
                status: -1,
                customIcon: "",
            };
            const orderIconData = {
                lat: lat,
                lon: lon,
                childrenCount: 1,
                emergency: isEmergency,
                sameState: true,
                sameStatus: false,
                type: "order",
                state: orderStateId,
                status: -1,
                children: [orderChild],
                orderIds: [orderId],
                fsrIds: [],
                crewIds: [],
                vehicleIds: [],
                projectIds: [],
                customIcon: "",
            };
            return getMapIconFromMapIconData(orderIconData, theme, fsrVehMode, mapIconCount);
        });
        const data = {
            route: null,
            fsr: fsrMapIcon,
            assignedOrders: assignedOrders,
            order: orderMapIcon,
        };
        window.store.dispatch({
            type: "dispatch.page.SET_MAP_HOVER_ROUTE_DATA",
            mapHoverRouteData: data,
        });
    }).catch(e => {
        const data = {
            route: null,
            fsr: fsrMapIcon,
            assignedOrders: [],
            order: orderMapIcon,
        };
        window.store.dispatch({
            type: "dispatch.page.SET_MAP_HOVER_ROUTE_DATA",
            mapHoverRouteData: data,
        });
        console.error(e);
    });
    return mapIconCount;
};
export function displayRouteOnMap(recommendation, fsrId, order) {
    //order is not of type Order.
    const scheduleKey = recommendation.scheduleKey;
    const dateOfSchedule = recommendation.date.toISOString(true);
    const recommendDateString = recommendation.date.format("YYYY-MM-DD");
    const state = window.store.getState().dispatch;
    const fsrVehMode = state.fsrVehMode;
    const theme = state.theme;
    let mapIconCount = 1;
    if (scheduleKey) {
        clearRoutes();
        const req = {
            date: dateOfSchedule,
            id: fsrId,
            type: "fsr",
        };
        let orderMapIcon = null;
        const orderObject = order.selectedOrders[0] || null;
        if (!!orderObject && orderObject.longitude !== null && orderObject.latitude !== null) {
            const orderChild = {
                name: orderObject.number,
                legacyIdentifier: `${orderObject.id},${orderObject.stateId},${orderObject.number}`,
                type: "order",
                id: orderObject.id,
                emergency: false,
                state: orderObject.stateId,
                status: -1,
                customIcon: "",
            };
            const orderIconData = {
                lat: orderObject.latitude,
                lon: orderObject.longitude,
                childrenCount: 1,
                emergency: false,
                sameState: true,
                sameStatus: false,
                type: "order",
                state: orderObject.stateId,
                status: -1,
                children: [orderChild],
                orderIds: [orderObject.id],
                fsrIds: [],
                crewIds: [],
                vehicleIds: [],
                projectIds: [],
                customIcon: "",
            };
            orderMapIcon = getMapIconFromMapIconData(orderIconData, theme, fsrVehMode, mapIconCount);
        }
        let fsrMapIcon = null;
        const getFsrDataUrl = `/sl3/api/fsrs?start=1&end=100&columns=STATE_ID&filter=%7B%22%24and%22%3A%5B%7B%22FSR_ID%22%3A%7B%22%24in%22%3A%5B%22${fsrId}%22%5D%7D%7D%5D%7D&filter_date=${recommendDateString}&loadtype=full&winnum=1`;
        axios({
            method: "POST",
            url: getFsrDataUrl,
        }).then(rsp => {
            var _a;
            mapIconCount++;
            const fsrArray = ((_a = rsp === null || rsp === void 0 ? void 0 : rsp.data) === null || _a === void 0 ? void 0 : _a.data) || [];
            const fsr = fsrArray.length === 1 ? fsrArray[0] : null;
            if (!!fsr && fsr.lat !== null && fsr.lon !== null) {
                const fsrNameArray = fsr.LOGIN.split(" ");
                const firstName = (fsrNameArray[1] || "").replace(",", "").trim();
                const lastName = (fsrNameArray[0] || "").replace(",", "").trim();
                const login = (fsrNameArray[2] || "").replace(",", "").replace("(", "").replace(")", "").trim();
                const fsrChild = {
                    name: `${lastName}, ${firstName}`,
                    legacyIdentifier: `${fsr.FSR_ID},${fsr.STATE_ID},${lastName},${firstName}(${login})`,
                    type: "fsr",
                    id: parseInt(fsr.FSR_ID),
                    emergency: false,
                    state: parseInt(fsr.STATE_ID),
                    status: -1,
                    customIcon: "",
                };
                const fsrIconData = {
                    lat: parseFloat(fsr.lat),
                    lon: parseFloat(fsr.lon),
                    childrenCount: 1,
                    emergency: false,
                    sameState: true,
                    sameStatus: false,
                    type: "fsr",
                    state: parseInt(fsr.STATE_ID),
                    status: -1,
                    children: [fsrChild],
                    orderIds: [],
                    fsrIds: [parseInt(fsr.FSR_ID)],
                    crewIds: [],
                    vehicleIds: [],
                    projectIds: [],
                    customIcon: "",
                };
                fsrMapIcon = getMapIconFromMapIconData(fsrIconData, theme, fsrVehMode, mapIconCount);
            }
            else {
                console.error(`Unable to get FSR with id: ${fsrId}.`);
            }
            //Fetch Routing Data
            Routing.getMapRoutingData(req).then(rsp => {
                const mapRoutingData = rsp;
                if (!!mapRoutingData) {
                    const newUniqueColor = getNewUniqueColor([]);
                    const orderNumbers = mapRoutingData.wayPoints.map(wayPoint => wayPoint.orderNumber);
                    OrderAPI.getOrdersByOrderNumbers({
                        orderNumbers: orderNumbers,
                    }).then(orders => {
                        //Clear Existing Routes;
                        clearRoutes();
                        const theme = window.store.getState().dispatch.theme;
                        const mapRoute = getMapRouteFromMapRoutingData([], mapRoutingData, theme, newUniqueColor, orders);
                        const data = {
                            route: mapRoute,
                            fsr: fsrMapIcon,
                            assignedOrders: [],
                            order: orderMapIcon,
                        };
                        window.store.dispatch({
                            type: "dispatch.page.SET_MAP_HOVER_ROUTE_DATA",
                            mapHoverRouteData: data,
                        });
                    }).catch(e => {
                        const data = {
                            route: null,
                            fsr: fsrMapIcon,
                            assignedOrders: [],
                            order: orderMapIcon,
                        };
                        window.store.dispatch({
                            type: "dispatch.page.SET_MAP_HOVER_ROUTE_DATA",
                            mapHoverRouteData: data,
                        });
                        console.error(e);
                    });
                }
                else {
                    mapIconCount = handleNoMapRoute(fsrId, recommendDateString, mapIconCount, theme, fsrVehMode, fsrMapIcon, orderMapIcon);
                }
            }).catch(e => {
                mapIconCount = handleNoMapRoute(fsrId, recommendDateString, mapIconCount, theme, fsrVehMode, fsrMapIcon, orderMapIcon);
                console.error(e);
            });
        });
    }
}
export const useRouteShowOnHover = (recommendation, fsrId) => {
    return useRouteShowOnHoverWithGuiData(recommendation ? getCalculations(recommendation) : null, fsrId);
};
export const useRouteShowOnHoverWithGuiData = (recommendation, fsrId) => {
    // const manager: React.MutableRefObject<LegacyManager> = useLegacyRouteDisplayManager();
    const [{ isOver, order }, dropRef] = useDrop({
        accept: DraggableTypes.order,
        collect: monitor => {
            return {
                isOver: monitor.isOver(),
                order: monitor.getItem(),
            };
        },
        drop: item => {
            removeRouteOnMap();
        },
    });
    useEventListener("mouse-up", () => {
        removeRouteOnMap();
    });
    useEffect(() => {
        if (recommendation)
            if (isOver) {
                displayRouteOnMap(recommendation, fsrId, order);
            }
            else {
                removeRouteOnMap();
            }
        return () => {
            // manager.current.Remove()
        };
    }, [recommendation, isOver]);
    return dropRef;
};
export function useLegacyRouteDisplayManager() {
    return useRef({
        layers: [],
        Remove: function () {
            window.markerLayer.setVisible(true);
            window.highlightLayer.setVisible(true);
            for (let layer of this.layers) {
                layer.setMap(null);
            }
            this.layers = [];
        },
        Add: function (layer) {
            window.markerLayer.setVisible(false);
            window.highlightLayer.setVisible(false);
            //@ts-ignore
            layer.setMap(map);
            return this.layers.push(layer);
        }
    });
}
