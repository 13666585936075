import React, { useContext, useEffect, useState } from "react";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { AutoSizer } from "react-virtualized";
import "react-big-calendar/lib/css/react-big-calendar.css";
import _ from "lodash";
import chroma from "chroma-js";
import { DraggableTypes } from "../../../../hooks/DragDrop/types";
import { useDispatch, useSelector } from "react-redux";
import { dispatchOrders } from "../../../../state/containers/DispatchPageContainer/actions";
import { Fsrs } from "../../../../state/api/REST/Fsrs/Fsrs";
import { Utils } from "../../../../helper/General/Utils";
var convertFromMeters = Utils.distance;
var DistanceUnits = Utils.DistanceUnits;
import { useRouteShowOnHover } from "../../../../hooks/Dispatch/useRouteShow";
import styled from "styled-components";
var doTo = Utils.doTo;
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const toISODateFormat = (date) => date.format(DEFAULT_DATE_FORMAT);
const CalendarContext = React.createContext({
    fsrId: -1,
    recommendSummary: { daySummaries: {}, bestDay: null, worstDay: null }
});
export const FsrCalendar = (props) => {
    const { dateOpened, fsrId, orders } = props;
    const start = moment(dateOpened).startOf('month');
    const [recommendSummary, setRecommendSummary] = useState(null);
    //@ts-ignore
    const isRecommending = useSelector(state => state.dispatch.settings.isRecommending);
    const { isDraggingOrder, orderPayload } = useDragLayer(monitor => ({
        isDraggingOrder: monitor.getItemType() === DraggableTypes.order,
        orderPayload: monitor.getItem()
    }));
    useEffect(() => {
        if (isDraggingOrder && isRecommending) {
            Fsrs.getRecommendOnSingleFsr(moment(dateOpened).toISOString(true), orderPayload.selectedOrderIds[0], fsrId, 14)
                .then(rsp => {
                const distanceSummaries = rsp.response.recommends
                    //@ts-ignore
                    .flatMap(x => x.recommends.flatMap(x => x.fsrRecommends));
                const transformToDateString = dSummary => toISODateFormat(moment(dSummary.startTime));
                const recommendSummary = {
                    daySummaries: _.keyBy(distanceSummaries, transformToDateString),
                    bestDay: transformToDateString(_.minBy(distanceSummaries, (dSummary) => dSummary.distance)),
                    worstDay: transformToDateString(_.maxBy(distanceSummaries, (dSummary) => dSummary.distance))
                };
                setRecommendSummary(recommendSummary);
            });
        }
        else {
            setRecommendSummary({ daySummaries: {}, worstDay: null, bestDay: null });
        }
    }, [isDraggingOrder]);
    return (React.createElement(CalendarContext.Provider, { value: { recommendSummary, fsrId } },
        React.createElement(AutoSizer, null, ({ height, width }) => React.createElement(Calendar, { toolbar: true, localizer: momentLocalizer(moment), defaultDate: start.toDate(), step: 5, events: orders, components: {
                eventWrapper: DraggableOrderEvent,
                dateCellWrapper: DispatchableDateCell
            }, titleAccessor: (x) => `${x.jobCodeId} ${x.number} ${x.appointmentType}`, startAccessor: (x) => moment(x.appointmentStartTime).toDate(), endAccessor: (x) => moment(x.appointmentEndTime).toDate(), allDayAccessor: (x) => x.appointmentStartTime === x.appointmentEndTime, style: { height, width } }))));
};
const DispatchableDateCell = props => {
    const { children, value } = props;
    const date = moment(value);
    const dispatch = useDispatch();
    const { fsrId, recommendSummary } = useContext(CalendarContext);
    const dayIndex = toISODateFormat(date);
    const daySummary = recommendSummary === null || recommendSummary === void 0 ? void 0 : recommendSummary.daySummaries[dayIndex];
    const dayOffsetProps = {
        distance: daySummary === null || daySummary === void 0 ? void 0 : daySummary.distance,
        color: (recommendSummary === null || recommendSummary === void 0 ? void 0 : recommendSummary.bestDay) == dayIndex
            ? "green"
            : (recommendSummary === null || recommendSummary === void 0 ? void 0 : recommendSummary.worstDay) == dayIndex
                ? "red"
                : daySummary
                    ? "yellow"
                    : null
    };
    const recommendColor = (dayOffsetProps === null || dayOffsetProps === void 0 ? void 0 : dayOffsetProps.color) ? chroma(dayOffsetProps.color).alpha(0.2).css()
        : null;
    let [{ hoverColor, isDragging, isEventForThisDay }, dropRef] = useDrop({
        accept: DraggableTypes.order,
        drop: item => {
            dispatch(dispatchOrders([fsrId], item.selectedOrders, toISODateFormat(date)));
        },
        collect: monitor => {
            const item = monitor.getItem();
            const isDragging = item != null;
            const order = item === null || item === void 0 ? void 0 : item.selectedOrders[0];
            return {
                hoverColor: monitor.isOver() ? chroma("navy").alpha(0.2).css() : null,
                isDragging,
                isEventForThisDay: (order === null || order === void 0 ? void 0 : order.appointmentStartTime) ? moment(order.appointmentStartTime).get('date') == date.get('date') : false
            };
        }
    });
    const distanceRender = (dist) => `${dist.to(DistanceUnits.MILES).toFixed(2)} ${dist.name(DistanceUnits.MILES)}`;
    const recommendElement = React.createElement("div", null, (dayOffsetProps === null || dayOffsetProps === void 0 ? void 0 : dayOffsetProps.distance) ? distanceRender(convertFromMeters(dayOffsetProps.distance)) : null);
    const defaultBackgroundColor = "rgba(255,255,255,.2)";
    const routeHoverShowRef = useRouteShowOnHover(daySummary, fsrId);
    // @ts-ignore
    return React.createElement(StyledBackground, { ref: ref => doTo(ref, dropRef, routeHoverShowRef), highlight: hoverColor || recommendColor || defaultBackgroundColor, zIndex: isDragging && !isEventForThisDay ? 100 : null, 
        //@ts-ignore Strange fact that if you don't use this class name the formatting goes all over the place
        className: children.props.className }, recommendElement);
};
const DraggableOrderEvent = (props) => {
    const { event, children } = props;
    const { jobCodeId, appointmentType, number } = event;
    let [, dragRef] = useDrag({
        item: {
            type: DraggableTypes.order,
            id: event.id,
            selectedOrderIds: [event.id],
            selectedOrders: [event],
        },
    });
    return (React.createElement("div", { ref: dragRef }, children));
};
const StyledBackground = styled.div `
z-index: ${props => props.zIndex};
display: flex;
flex-direction: column-reverse;
justify-content: flex-end;
background-color: ${props => props.highlight};
`;
