import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import { LoginPageContainerActionTypes } from "./types";
import axios from "axios";
import $ from "jquery";
import Cookies from "universal-cookie";
import { Utils } from "../../../helper/General/Utils";
import IndexedDBService, { IndexedDBKeys } from "../../../helper/IndexedDB/IndexedDBService";
import ConfigJson from "../../api/REST/ConfigJson/ConfigJson";
import { MSALService } from "../../../helper/MSAL/MSALService";
import Auth from "../../api/REST/Auth/Auth";
import { LegacyTheme } from "../../../helper/LegacyTheme/LegacyTheme";
const cookies = new Cookies();
export const loginPageContainerSagas = {
    initializeSaga: takeEvery(LoginPageContainerActionTypes.INITIALIZE, initializeSaga),
    handleLogin: takeEvery(LoginPageContainerActionTypes.HANDLE_LOGIN, handleLoginSaga),
    handleADLogin: takeEvery(LoginPageContainerActionTypes.HANDLE_AD_LOGIN, handleActiveDirectoryLoginSaga),
    handleMSALLogin: takeEvery(LoginPageContainerActionTypes.HANDLE_MSAL_LOGIN, handleADLoginSaga),
    handle2FA: takeEvery(LoginPageContainerActionTypes.HANDLE_2FA, handle2FASaga),
    processTokenResponse: takeEvery(LoginPageContainerActionTypes.PROCESS_TOKEN_RESPONSE, processTokenResponse),
    setADLogin: takeEvery(LoginPageContainerActionTypes.SET_AD_LOGIN, setADLogin),
};
function* setADLogin(action) {
    const isADLogin = action.isADLogin;
    yield put({
        type: LoginPageContainerActionTypes.SET_AD_LOGIN,
        state: {
            isADLogin: isADLogin
        }
    });
}
//TODO: Login API and Style API should be changed and cleaned up. Style API should be moved to main user configuration object. Using legacy version for now.
function* initializeSaga(action) {
    const initialState = yield select(getLoginPageContainerStateSelector);
    yield call(IndexedDBService.initialize);
    const activeDirectorySettings = yield call(ConfigJson.getActiveDirectorySettings);
    const rsp = yield call(getSignOnFormData);
    const xml = rsp.data;
    const businessUnitOptions = [];
    $(xml)
        .find("BUS")
        .find("NAME")
        .each((index, element) => {
        const bu = element.innerText;
        businessUnitOptions.push(bu);
    });
    const company = $(xml)
        .find("COMPANIES")
        .find("NAME:first")
        .text();
    const lastBu = yield call(IndexedDBService.get, IndexedDBKeys.BU);
    const initialBu = businessUnitOptions.includes(lastBu) ? lastBu : businessUnitOptions[0];
    const lastLogin = yield call(IndexedDBService.get, IndexedDBKeys.LOGIN);
    const lastTheme = yield call(IndexedDBService.get, IndexedDBKeys.THEME);
    let loginPageStyleProps = {};
    if (lastTheme) {
        loginPageStyleProps = LegacyTheme.legacyThemeObject[lastTheme] || {};
    }
    else {
        const loginPageResponse = yield call(getLoginTheme);
        loginPageStyleProps = Utils.snakeToCamelCaseObject(loginPageResponse.data, []);
        loginPageStyleProps = Object.assign(Object.assign({}, loginPageStyleProps), { loginMenuLogoSrc: "/sl3/images/comp.svg" });
    }
    const initialLogin = lastLogin === null ? "" : lastLogin;
    yield put({
        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign(Object.assign(Object.assign({}, initialState), { isInitialized: true, company: company, businessUnitOptions: businessUnitOptions }), loginPageStyleProps), { activeDirectoryEnabled: activeDirectorySettings.isEnabled, activeDirectoryType: activeDirectorySettings.mode, activeDirectoryClientId: activeDirectorySettings.clientId, activeDirectoryMode: activeDirectorySettings.mode, doMicrosoftLogout: activeDirectorySettings.doMicrosoftLogout, initialLogin: initialLogin, initialBusinessUnit: initialBu, slAuthEnabled: activeDirectorySettings.slAuthEnabled, plainAdLoginEnabled: activeDirectorySettings.plainAdLoginEnabled })
    });
}
function* handleADLoginSaga(action) {
    const loginState = yield select(getLoginPageContainerStateSelector);
    const activeDirectorySettings = yield call(ConfigJson.getActiveDirectorySettings);
    const businessUnitOptions = [];
    const rsp = yield call(getSignOnFormData);
    const xml = rsp.data;
    businessUnitOptions.push(action.businessUnit);
    const company = $(xml)
        .find("COMPANIES")
        .find("NAME:first")
        .text();
    const lastBu = yield call(IndexedDBService.get, IndexedDBKeys.BU);
    yield put({
        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign({}, loginState), { isLoadingComplete: false, errorMessage: "", message: "Getting token from Microsoft..." })
    });
    MSALService.initialize(activeDirectorySettings.clientId, activeDirectorySettings.tenant, company, lastBu, businessUnitOptions, false);
}
//TODO: Login API should be changed and cleaned up. Using legacy version for now.
function* handleLoginSaga(action) {
    const nonLoadingState = yield select(getLoginPageContainerStateSelector);
    yield put({
        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: false, errorMessage: "", message: "Logging in..." })
    });
    const startTime = performance.now();
    const initialState = yield select(getLoginPageContainerStateSelector);
    const username = action.username;
    const password = action.password;
    const businessUnit = action.businessUnit;
    yield call(IndexedDBService.put, IndexedDBKeys.BU, businessUnit);
    if (!nonLoadingState.isAskingForBU) {
        yield call(IndexedDBService.put, IndexedDBKeys.LOGIN, username);
    }
    const company = initialState.company;
    const loginRequestRsp = nonLoadingState.isAskingForBU ? yield call(Auth.login, {
        company: nonLoadingState.company,
        bu: businessUnit,
        logon_type: "microsoft",
        user_type: "Desktop",
        access_token: nonLoadingState.accessToken,
        email: "",
    }) : yield call(sendLoginRequest, username, password, businessUnit, company);
    yield put({
        type: LoginPageContainerActionTypes.PROCESS_TOKEN_RESPONSE,
        data: loginRequestRsp,
        startTime: startTime,
    });
}
function* processTokenResponse(action) {
    const nonLoadingState = yield select(getLoginPageContainerStateSelector);
    const loginRequestRsp = action.data;
    const loginErrorMessage = loginRequestRsp.error;
    if (loginErrorMessage) {
        yield put({
            type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: loginErrorMessage })
        });
        return;
    }
    else {
        if (nonLoadingState.activeDirectoryEnabled && nonLoadingState.activeDirectoryMode === "azure") {
            yield put({
                type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                state: Object.assign(Object.assign({}, nonLoadingState), { errorMessage: "", message: "Logging in..." })
            });
        }
        if (loginRequestRsp.multiFactor) {
            yield put({
                type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, isTwoFactorAuthenticating: true, countdownTimeSeconds: loginRequestRsp.multiFactor.secondsToExpiration, contactInformation: loginRequestRsp.multiFactor.contactInformation, errorMessage: "", message: "" })
            });
        }
        else {
            if (!loginRequestRsp.user) {
                yield put({
                    type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                    state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: "Unable to obtain server user data." })
                });
            }
            else if (!loginRequestRsp.token) {
                yield put({
                    type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                    state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: "Unable to obtain server user token." })
                });
            }
            processLoginResponse(loginRequestRsp.user, loginRequestRsp.token);
            const userSettingsRsp = yield call(getUserSettings);
            const userSettingsErrorMessage = userSettingsRsp.error;
            if (userSettingsErrorMessage) {
                yield put({
                    type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                    state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: userSettingsErrorMessage })
                });
                return;
            }
            else {
                const endTime = performance.now();
                const timeToWait = Math.max(0, (500 - (endTime - action.startTime)));
                yield delay(timeToWait);
                processUserSettingsResponse(userSettingsRsp.data);
            }
            localStorage.removeItem("winNum");
            yield put({
                type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: "", message: "Logging in..." })
            });
        }
    }
}
function* handleActiveDirectoryLoginSaga(action) {
    const loginState = yield select(getLoginPageContainerStateSelector);
    const activeDirectorySettings = yield call(ConfigJson.getActiveDirectorySettings);
    const businessUnitOptions = [];
    const rsp = yield call(getSignOnFormData);
    const xml = rsp.data;
    $(xml)
        .find("BUS")
        .find("NAME:first")
        .text();
    const company = $(xml)
        .find("COMPANIES")
        .find("NAME:first")
        .text();
    const lastBu = yield call(IndexedDBService.get, IndexedDBKeys.BU);
    yield put({
        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign({}, loginState), { isLoadingComplete: false, errorMessage: "", message: "Getting token from Microsoft..." })
    });
    MSALService.initialize(activeDirectorySettings.clientId, activeDirectorySettings.tenant, company, lastBu, businessUnitOptions, true);
}
function* handle2FASaga(action) {
    const nonLoadingState = yield select(getLoginPageContainerStateSelector);
    yield put({
        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: false, errorMessage: "", message: "Sending code..." })
    });
    const loginRequestRsp = yield call(send2FALoginRequest, action.code);
    const loginErrorMessage = loginRequestRsp.error;
    if (loginErrorMessage) {
        yield put({
            type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: loginErrorMessage })
        });
        return;
    }
    else {
        processLoginResponse(loginRequestRsp.user, loginRequestRsp.token);
        const userSettingsRsp = yield call(getUserSettings);
        const userSettingsErrorMessage = userSettingsRsp.error;
        if (userSettingsErrorMessage) {
            yield put({
                type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: userSettingsErrorMessage })
            });
            return;
        }
        else {
            processUserSettingsResponse(userSettingsRsp.data);
        }
        yield put({
            type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: "", message: "Logging in..." })
        });
    }
}
function getLoginPageContainerStateSelector(store) {
    return store.login;
}
//Legacy functions. Do not use, Deprecated. Start.
//Deprecated
const getLoginTheme = () => {
    return axios.get("/sl3/rest/theme/login_page");
};
//Deprecated
const getSignOnFormData = () => {
    return axios.post("/sl3/signonForm", {
        request: "A"
    });
};
//Deprecated
const sendLoginRequest = (username, password, businessUnit, company) => {
    const request = {
        logon_type: "sl",
        user_name: username,
        password: password,
        company: company,
        bu: businessUnit,
        client_type: "Web",
        user_type: "Desktop"
    };
    return axios.post("/sl3/rest/auth/desktop", Object.assign({ headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Data-Type": "json",
            "Mobile-Type": "HTML"
        } }, request))
        .then((rsp) => rsp.data)
        .then((rsp) => {
        return {
            token: rsp.token,
            user: rsp.user,
            error: rsp.error,
            multiFactor: rsp.multi_factor_initiated ? {
                type: rsp.multi_factor_initiated.multi_factor_type || "",
                contactInformation: rsp.multi_factor_initiated.to || "",
                secondsToExpiration: (rsp.multi_factor_initiated.expires_in_minutes || 1) * 60
            } : null
        };
    })
        .catch((e) => {
        if (e.response) {
            let error = "";
            switch (e.response.status) {
                case 400: {
                    error = "Bad login request.";
                    break;
                }
                case 401: {
                    error = "Unauthorized.";
                    break;
                }
                case 403: {
                    error = "Forbidden.";
                    break;
                }
                case 404: {
                    error = "Login endpoint is not found.";
                    break;
                }
                case 500: {
                    error = "Internal server error.";
                    break;
                }
                case 503: {
                    error = "Server is unavailable.";
                    break;
                }
                default: {
                    error = "Unexpected server response.";
                }
            }
            console.error(e);
            return {
                token: null,
                user: null,
                error: error,
                multiFactor: null
            };
        }
    });
};
const send2FALoginRequest = (code) => {
    const request = {
        code: code
    };
    return axios.post("/sl3/rest/auth/multi_factor", Object.assign({ headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Data-Type": "json",
            "Mobile-Type": "HTML"
        } }, request))
        .then((rsp) => rsp.data)
        .then((rsp) => {
        return {
            token: rsp.token,
            user: rsp.user,
            error: rsp.error,
            multiFactor: null
        };
    })
        .catch((e) => {
        if (e.response) {
            let error = "";
            switch (e.response.status) {
                case 400: {
                    error = "Bad 2fa request.";
                    break;
                }
                case 401: {
                    error = "Unauthorized.";
                    break;
                }
                case 403: {
                    error = "Verification code incorrect.";
                    break;
                }
                case 404: {
                    error = "Login endpoint is not found.";
                    break;
                }
                case 500: {
                    error = "Internal server error.";
                    break;
                }
                case 503: {
                    error = "Server is unavailable.";
                    break;
                }
                default: {
                    error = "Unexpected server response.";
                }
            }
            console.error(e);
            return {
                token: null,
                user: null,
                error: error,
                multiFactor: null
            };
        }
    });
};
//Deprecated
const processLoginResponse = (user, token) => {
    cookies.set("UID", user.id, { expires: setCookieExpiry(7), path: "/" });
    cookies.set("UNAME", user.login, { expires: setCookieExpiry(7), path: "/" });
    cookies.set("BUNAME", user.bu, { expires: setCookieExpiry(7), path: "/" });
    cookies.set("BUID", user.bu_id, { expires: setCookieExpiry(7), path: "/" });
    cookies.set("APPTYPE", "Web", { expires: setCookieExpiry(7), path: "/" });
    cookies.set("NAME", user.family_name + "," + user.given_name + "(" + user.login + ")", { expires: setCookieExpiry(7), path: "/" });
    cookies.set("X-ServiceLink-Auth", token, { expires: setCookieExpiry(7), path: "/" });
    cookies.set("IS_REACT_LOGIN", true, { expires: setCookieExpiry(7), path: "/" });
    const exp = user.force_change_pass;
    //use valid temp filter that returns ALL fsrs/orders with valid winnum to initialize web cache
    fetch('/sl3/api/fsrs', { method: "POST", headers: { "X-ServiceLink-Auth": token }, body: JSON.stringify({ filter: '{"$and":[{"$or":[{"STATE_ID":{"$nin":["0"]}}]}]}', winnum: 1 }) });
    fetch('/sl3/api/orders', { method: "POST", headers: { "X-ServiceLink-Auth": token }, body: JSON.stringify({ filter: '{"$and":[{"$or":[{"STATE_ID":{"$nin":["0"]}}]}]}', winnum: 1 }) });
    if (exp == "true") {
        const expMsg = "Your password expired. Please change it";
        alert(expMsg);
        redirect("/change-password");
    }
};
//Deprecated
const getUserSettings = () => {
    return axios.get("/sl3/api/usersettings", {
        headers: {
            "Data-Type": "json",
            "Mobile-Type": "HTML",
            "X-ServiceLink-Auth": cookies.get('X-ServiceLink-Auth')
        }
    });
};
//Deprecated
const processUserSettingsResponse = (rsp) => {
    const userSettings = rsp.data;
    if (userSettings) {
        const defaultDateFormat = "YYYY-MM-DD";
        const displayDateFormat = userSettings[0]["display_date_format"] || defaultDateFormat;
        const jQueryDateFormat = displayDateFormat.replace("yyyy", "yy").replace("MM", "mm");
        userSettings[0]["default_date_format"] = defaultDateFormat;
        userSettings[0]["display_date_format"] = displayDateFormat.toUpperCase();
        userSettings[0]["jquery_date_format"] = jQueryDateFormat;
        localStorage.userSettings = JSON.stringify(userSettings);
        const userSettingObj = userSettings[0]["user_setting_object"];
        const curPage = userSettingObj ? userSettingObj["cur_page" + "1"] || "" : "";
        const roles = userSettings[0]["roles"];
        const views = userSettings[0]["views"];
        const inventoryRolesToCheck = ["CCI", "USF"];
        const inventoryRoles = [];
        let isRoleView = false;
        inventoryRolesToCheck.forEach(role => {
            if (roles.includes(role)) {
                inventoryRoles.push(role);
            }
        });
        if (inventoryRoles.length > 0) {
            let rolesString = JSON.stringify(inventoryRoles);
            rolesString = rolesString.replace(/"/g, "");
            rolesString = rolesString.replace("[", "").replace("]", "");
            cookies.set("Roles", rolesString, { expires: setCookieExpiry(7), path: "/" });
        }
        if ($.inArray("Manager", roles) !== -1 && curPage.indexOf("Admin") !== -1) {
            isRoleView = false;
            cookies.set("roleView", isRoleView, { expires: setCookieExpiry(7), path: "/" });
            redirect("/sl3/d/manage/");
            return;
        }
        if (views != "" && $.inArray("Dispatcher", views) !== -1 && $.inArray("Admin", views) !== -1) {
            if (curPage.indexOf("Admin") !== -1) {
                isRoleView = false;
                cookies.set("roleView", isRoleView, { expires: setCookieExpiry(7), path: "/" });
                redirect("/sl3/d/manage/");
                return;
            }
            else {
                isRoleView = false;
                cookies.set("roleView", isRoleView, { expires: setCookieExpiry(7), path: "/" });
                redirect("/sl3/d/dispatch/");
                return;
            }
        }
        else if (views != "" && $.inArray("Admin", views) != -1) {
            isRoleView = true;
            cookies.set("roleView", isRoleView, { expires: setCookieExpiry(7), path: "/" });
            redirect("/sl3/d/manage/");
            return;
        }
        else if (views != "" && $.inArray("Dispatcher", views) != -1) {
            isRoleView = false;
            cookies.set("roleView", isRoleView, { expires: setCookieExpiry(7), path: "/" });
            redirect("/sl3/d/dispatch/");
            return;
        }
        //}
    }
    redirect("/sl3/d/dispatch/");
};
//Deprecated
export const redirect = (url) => {
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
        const referLink = document.createElement("a");
        referLink.href = url;
        document.body.appendChild(referLink);
        referLink.click();
    }
    else {
        location.href = url;
    }
};
//Deprecated
const setCookieExpiry = (daysFromNow) => {
    const now = Date.now();
    const later = now + (daysFromNow * 24 * 60 * 60 * 1000);
    return new Date(later);
};
//Legacy functions. Do not use, Deprecated. End.
export default loginPageContainerSagas;
