import React, { useEffect } from "react";
import Modal from "../components/Modal/Modal";
import { ManagePageContainerActionTypes } from "../../../state/containers/ManagePageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UnbindVehicleScreen from "../components/Screens/UnbindVehicleScreen/UnbindVehicleScreen";
const ManagePageVehicleUnlinkWindowContainer = (props) => {
    const state = useSelector(store => store.manage, shallowEqual);
    const dispatch = useDispatch();
    const { open, handleClose, } = props;
    useEffect(() => {
        if (open) {
            dispatch({
                type: ManagePageContainerActionTypes.OPEN_UNBIND_VEHICLE_WINDOW,
            });
        }
    }, [open]);
    const handleUnbindVehicle = (vehicle) => {
        dispatch({
            type: ManagePageContainerActionTypes.UNBIND_VEHICLE,
            vehicle: vehicle,
        });
        handleClose();
    };
    const sortedVehicles = state.traccarBoundVehicles.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        else if (a.name > b.name) {
            return 1;
        }
        else {
            return 0;
        }
    });
    return (React.createElement(Modal, { open: open, title: "Unlink Vehicles", handleClose: handleClose, dimension: { width: 600, height: 260 } },
        React.createElement(UnbindVehicleScreen, { vehicles: sortedVehicles, handleUnbindVehicle: handleUnbindVehicle })));
};
export default ManagePageVehicleUnlinkWindowContainer;
