import React from "react";
import { connect } from "react-redux";
import Table from "../../components/Table/Table";
import { InventoryTableContainerActionTypes } from "../../../state/containers/InventoryTableContainer/types";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
const StyledInventoryTableContainer = styled.div `
  height: 100%;
  width: 100%;
  background-color: ${props => props.palette.white};
`;
class InventoryTableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        return (React.createElement(StyledInventoryTableContainer, Object.assign({ className: "inventory-table-container" }, this.theme), this.props.tableInitialized ? (React.createElement(Table, Object.assign({}, this.props, { titleText: "Inventory Table" }))) : null));
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        tableInitialized: state.inventory.inventoryTableContainer.tableInitialized,
        tableHeaderRowData: state.inventory.inventoryTableContainer.tableHeaderRowData,
        tableHeaderRowProps: state.inventory.inventoryTableContainer.tableHeaderRowProps,
        tableRowVerticalScrollDataProps: state.inventory.inventoryTableContainer.tableRowVerticalScrollDataProps,
        tableRowWrapperDataProps: state.inventory.inventoryTableContainer.tableRowWrapperDataProps
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSelectAll() {
            dispatch({
                type: InventoryTableContainerActionTypes.HANDLE_SELECT_ALL
            });
        },
        handleTableRowScrollWrapperRightClick(event) {
            dispatch({
                type: InventoryTableContainerActionTypes.HANDLE_BACKGROUND_RIGHT_CLICK,
                xPosition: event.clientX,
                yPosition: event.clientY
            });
        },
        tableHeaderRowActions: {
            reorderHandler(source, destination) {
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_COLUMN_REORDER,
                    source: source,
                    destination: destination
                });
            },
            resizeHandler(data) {
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_COLUMN_RESIZE,
                    data: data
                });
            }
        },
        tableRowScrollActions: {
            handleVerticalScroll(verticalScroll) {
                dispatch({
                    type: InventoryTableContainerActionTypes.SET_VERTICAL_SCROLL,
                    verticalScroll: verticalScroll
                });
            }
        },
        tableCellActions: {},
        tableCellCommonActions: {
            handleLeftClick(xPosition, yPosition, cellIndex, rowId, isCtrl, isShift, isAlt) {
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_CELL_LEFT_CLICK,
                    xPosition: xPosition,
                    yPosition: yPosition,
                    cellIndex: cellIndex,
                    rowId: rowId,
                    isCtrl: isCtrl,
                    isShift: isShift,
                    isAlt: isAlt
                });
            },
            handleRightClick(xPosition, yPosition, cellIndex, rowId, isCtrl, isShift, isAlt) {
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_CELL_RIGHT_CLICK,
                    xPosition: xPosition,
                    yPosition: yPosition,
                    cellIndex: cellIndex,
                    rowId: rowId,
                    isCtrl: isCtrl,
                    isShift: isShift,
                    isAlt: isAlt
                });
            }
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryTableContainer);
