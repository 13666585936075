import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import Text from "../../Text/Text";
import Spacer from "../../Spacer/Spacer";
import IconButton from "../../Button/IconButton/IconButton";
import IconTextButton from "../../Button/IconTextButton/IconTextButton";
export const StyledModalTitle = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: 100%;
  background-color: ${props => props.backgroundColor};
  
`;
export const StyledTitleWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: max-content;
`;
export const StyledIconWrapper = styled.div `
  display: flex;
  flex-direction: row-reverse;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: max-content;
`;
const ModalTitle = (props) => {
    const { title = "", height = 38, backgroundColor = "#0078d4", fontColor = "white", fontBackgroundColor = "#26408F", closeButtonColor = "#454545", icons = [], handleClose, } = props;
    return (React.createElement(StyledModalTitle, { className: "modal-title", height: height, backgroundColor: backgroundColor },
        React.createElement(StyledTitleWrapper, { className: "modal-title-text-wrapper", height: height },
            React.createElement(Spacer, { width: 10 }),
            React.createElement(Text, { text: title, horizontalAlignment: "left", width: "max-content", borderRadius: [3, 3, 0, 0], fontSize: 14, height: 32, fontColor: fontColor, backgroundColor: fontBackgroundColor, horizontalMargin: 15 }),
            React.createElement(Spacer, { width: 10 })),
        React.createElement(StyledIconWrapper, { className: "modal-title-button-wrapper", height: height },
            React.createElement(IconButton, { iconName: "cross", height: 28, width: 28, margin: [5, 10], borderWidth: 0, color: closeButtonColor, backgroundColor: fontColor, borderRadius: 3, handleClick: handleClose }),
            icons.map((icon, index) => (React.createElement(IconTextButton, { key: index, text: icon.text, iconName: icon.iconName, handleClick: icon.handleClick, color: icon.color || closeButtonColor, backgroundColor: icon.backgroundColor || fontColor, borderWidth: 0, borderRadius: 3, height: 28, width: "max-content", margin: [5, 10], padding: [0, 10], iconTextSeparation: 5, iconFirst: true }))))));
};
export default ModalTitle;
