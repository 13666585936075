import * as Rx from "rxjs";
import $ from "jquery";

import { SLTime } from "../services/util/SLTime";
import { DateChangedObservable } from "./events";
import { FsrRouting } from "../services/routing/FsrRouting";

//TODO: Hijacking init so that it doesn't break stuff
export const wrappedInit = function() {
  sl.init();

  sl.selectedFsrObservable = new Rx.Subject();

  sl.fsrEvents = new Rx.Observable.fromEvent(sl.eventSource["fsr"], "sl-fsr",x => JSON.parse(x.data) );

  sl.routingColumnCellHitEvent = new Rx.Subject();

  let RoutesBeingDisplayed = {};

  sl.redoRoutingOverlays = function() {
    RoutingOverlaysController.repaintOverlays(RoutesBeingDisplayed);
  };

  sl.routingColumnCellHitEvent
    .filter(() => isAlt)
    .filter(({ $cell }) => $cell.hasClass("routed"))
    .filter(({ $cell }) => $cell.data("location") === "valid")
    .catch(printErrorAndReturnSubjectObservable)
    .subscribe(({ $row }) => routingLocateOnMap($row));

  //If you hit to locate then you shouldn't still show the route?
  sl.routingColumnCellHitEvent
    .filter(({ $cell }) => !$cell.hasClass("routed"))
    .catch(printErrorAndReturnSubjectObservable)
    .subscribe(function({ $row }) { routingLocateOnMap($row); }, printError);

  //keep updating the icon based on the events coming in
  sl.fsrEvents
    .catch(printErrorAndReturnSubjectObservable)
    .subscribe(updateRoutingDisplay, printError);

  //stop displaying when orders have changed for the routed fsr
  let removeDisplayedRoutes = fsrEvent => RoutingOverlaysController.removeAllDisplayedRoutes(RoutesBeingDisplayed);

  sl.fsrEvents
    .filter(fsrEvent => RoutesBeingDisplayed.hasOwnProperty(fsrEvent.FSR_ID))
    .filter(fsrEvent => fsrEvent.ROUTE_STATE !== "4") //TODO: some things are numbers...some are strings
    .merge(DateChangedObservable)
    .catch(printErrorAndReturnSubjectObservable)
    .subscribe(removeDisplayedRoutes, printError);

  sl.getFsrInformationAsync = function(fsrID) {
    let jsonFilter = JSON.stringify({ FSR_ID: fsrID.toString() });
    return Rx.Observable.ajax(`/sl3/api/fsrs?filter=${jsonFilter}`)
      .map(x => x.response.data[0])
      .toPromise();
  };

  //Function that users call when they want to tell the observable that an fsr was selected
  sl.newFsrHasBeenSelected = function(fsrId) {
    sl.selectedFsrObservable.next(fsrId);
  };

  sl.AddMapRxObservables = function() {
    if (window.mapSettings.type === "ESRI") {
      return;
    }
    map.SingleClickObservable = new Rx.Observable.fromEvent(map, "singleclick")
      .catch(printErrorAndReturnSubjectObservable);
  };

  function printErrorAndReturnSubjectObservable(error, failingObs) {
    console.log(`routing observer failed with error: ${error}, continuing... `)
    return failingObs;
  }

  function routingLocateOnMap($row) {
    locateOnMap(...[$row.data("lon"), $row.data("lat")].map(x => x.toString()));
  }

  function printError(e) {
    console.log(e);
  }

  function findCorrespondingRowAndCell(fsrId) {
    let $row = $(`[data-type="fsr"][data-obj_id=${fsrId}]`);
    let $cell = $row.find('[data-id="fsr_route"]');
    return { $row, $cell };
  }

  function setCellImageToRoutingIcons(fsrEvent, $cell) {
    const fsrLocClass = getFsrLocateIconClass(fsrEvent);
    let iconElem = $cell.find("[data-id='route_status']");
    if (!iconElem[0]) {
      $cell.append(`<i style="color: #FF0000; display:block; margin-left: auto; margin-right: auto; width:auto; font-size:${(sl.rowH * 60) / 100}px;"></i>`);
    }
    $cell
      .find("[data-id='route_status']")
      .removeClass()
      .addClass(`icon-${fsrLocClass}`)
  }

  function handleFsrRouteEvent(fsrEvent) {
    let { $row, $cell } = findCorrespondingRowAndCell(fsrEvent.FSR_ID);
    RoutingOverlaysController.addDisplayed(RoutesBeingDisplayed, { $row, $cell, fsrId: fsrEvent.FSR_ID });
    setCellImageToRoutingIcons(fsrEvent, $cell);
  }

  function updateRoutingDisplay(fsrEvent) {
    let { $row, $cell } = findCorrespondingRowAndCell(fsrEvent.FSR_ID);
    setCellImageToRoutingIcons(fsrEvent, $cell);
  }

  function getFsrLocateIconClass(thisData) {
    var _isValidLocation = true,
      routeState = parseInt(thisData["ROUTE_STATE"]);
    let locClass = "";
    if (routeState === 4) {
      //NOT_ROUTABLE = 0;NOT_ROUTED = 1;ROUTE_IN_PROGRESS = 2;ROUTE_FAILURE = 3; ROUTE_SUCCESS = 4;
      locClass = "routing_completed";
    } else if (routeState === 3) {
      locClass = "routing_failed";
    } else if (routeState === 2) {
      locClass = "routing_progress";
    } else if (_isValidLocation) {
      locClass = "locate";
    }
    return locClass;
  }

  class RoutingOverlay {
    constructor($cell, fsrId) {
      this.$cell = $cell;
      this.fsrId = fsrId;
      this.route = FsrRouting.RetrieveGpxAtUrl(
        `/sl3/rest/routing/gpx?fsrId=${this.fsrId}&dispatchDate=${SLTime.GetCurrentlySelectedTime()}`
      );
      this.mapClickSubscription = map.SingleClickObservable.subscribe(this.getOnMapClickHandler());
    }

    getOnMapClickHandler() {
      let filterOnlyForRoutingLayer = layerCandidate => layerCandidate === this.route.routeLayer;

      let createHandlerDoPopupOnFeatureHit = event => {
        return function(feature, layer) {
          let routeSeqName = feature.get("name");
          hideMapPopup();
          let popupContentHTML = '<div style="margin:0;padding:0;overflow:hidden;">' + routeSeqName + "</div>";
          showMapPopup(event, popupContentHTML, "", "", map);
        };
      };

      let onMapClick = event =>
        map.forEachFeatureAtPixel(event.pixel, createHandlerDoPopupOnFeatureHit(event), filterOnlyForRoutingLayer);
      return onMapClick;
    }

    overlay() {
      this.$cell.css("background-color", updateOpacity(this.route.color, sl.opacity).rgba());

      this.route.featuresLoaded.then(layerSource => {
        const mapBounds = layerSource.getExtent();
        if (mapBounds.some(corner => corner === Infinity)) {
          //route inaccessible from microservice once today is past
          showStatusMsg("Fsr Route Expired", 1, "Route is more than 24Hrs old. Please reroute fsr orders.")
          return;
        }
        map.getView().fit(mapBounds, map.getSize());
      });

      markerLayer.setVisible(false);
      highlightLayer.setVisible(false);
      this.route.routeLayer.setMap(map);
    }

    dispose() {
      this.$cell.css("background-color", "rgba(0, 0, 0, 0)");
      markerLayer.setVisible(true);
      highlightLayer.setVisible(true);
      this.route.routeLayer.setMap(null);
      this.mapClickSubscription.unsubscribe();
    }
  }

  class RoutingOverlaysController {
    //TODO: Really only here until I implement multiple routing
    static removeAllDisplayedRoutes(state) {
      for (let key in state) {
        state[key].dispose();
      }

      for (let key in state) delete state[key];

      return state;
    }

    static addDisplayed(state, { $row, $cell, fsrId }) {
      //TODO: remove this line after implementing multiple routes
      RoutingOverlaysController.removeAllDisplayedRoutes(state);

      state[fsrId] = new RoutingOverlay($cell, fsrId);
      state[fsrId].overlay();
      return state;
    }

    static removeDisplayed(state, { $row, $cell, fsrId }) {
      state[fsrId].dispose();
      delete state[fsrId];
      return state;
    }

    static repaintOverlays(state) {
      for (let fsrId in state) {
        let { $row, $cell } = findCorrespondingRowAndCell(fsrId);
        state[fsrId].dispose();
        state[fsrId] = new RoutingOverlay($cell, fsrId);
        state[fsrId].overlay();
      }
    }
  }
};

export default wrappedInit;
