import styled from "styled-components";
import SimpleButton from "../SimpleButton/SimpleButton";
const StyleChildValidatorCheckboxRow = styled.div `
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #eeeeee;
  border-bottom: 1px solid white;
`;
class ChildValidatorCheckboxRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleCheckboxClick = (event) => {
            this.props.handleChildSelected(this.props.order);
            event.target.checked = true;
            this.setState(prevState => ({ isChecked: true }));
        };
        this.handleButtonClick = (event) => {
            event.target.parentElement.querySelector("input[type='radio']").click();
        };
        this.state = {
            isChecked: false
        };
    }
    render() {
        const validationText = this.props.order.validated_time ? 'Completion Validated at: ' + this.props.order.validated_time : '';
        const displayedText = `
            ${this.props.order.assigned_fsr_login}, 
            ${this.props.order.state_name}, 
            Return requested: ${this.props.order.return_requested},
            ${validationText}`;
        const stateColor = window.iconConstants["OrderTable"][`Order${this.props.order.state_name}_EvenRowColor`];
        return (React.createElement(StyleChildValidatorCheckboxRow, { className: "child-validator-order-row" },
            React.createElement("div", { style: { backgroundColor: stateColor } },
                React.createElement("input", { type: "radio", name: "childOrder", onClick: this.handleCheckboxClick })),
            React.createElement(SimpleButton, { buttonClick: this.handleButtonClick, className: "child-validator-order-row-button", textProps: {
                    iconText: displayedText,
                    height: "1.2rem",
                    width: "calc(100% - 1rem)",
                    textAlign: "left",
                    fontWeight: "normal"
                }, padding: "0.4rem 0.5rem", color: "black", backgroundColor: stateColor })));
    }
}
export default ChildValidatorCheckboxRow;
