export var FilterUtils;
(function (FilterUtils) {
    let J_FILTER_OPERATORS;
    (function (J_FILTER_OPERATORS) {
        J_FILTER_OPERATORS["in"] = "$in";
        J_FILTER_OPERATORS["notIn"] = "$nin";
        J_FILTER_OPERATORS["equal"] = "$eq";
        J_FILTER_OPERATORS["notEqual"] = "$ne";
        J_FILTER_OPERATORS["between"] = "$between";
        J_FILTER_OPERATORS["lessThanOrEqual"] = "$le";
        J_FILTER_OPERATORS["greaterThanOrEqual"] = "$ge";
        J_FILTER_OPERATORS["lessThan"] = "$lt";
        J_FILTER_OPERATORS["greaterThan"] = "$gt";
        J_FILTER_OPERATORS["contains"] = "$contains";
        J_FILTER_OPERATORS["notContains"] = "$nexist";
        J_FILTER_OPERATORS["startsWith"] = "$sw";
        J_FILTER_OPERATORS["endsWith"] = "$ew";
        J_FILTER_OPERATORS["within"] = "$within";
        J_FILTER_OPERATORS["currentDay"] = "$currentDay";
        J_FILTER_OPERATORS["yesterday"] = "$yesterday";
        J_FILTER_OPERATORS["nextDay"] = "$nextDay";
        J_FILTER_OPERATORS["currentMonth"] = "$currentMonth";
        J_FILTER_OPERATORS["nextMonth"] = "$nextMonth";
        J_FILTER_OPERATORS["lastMonth"] = "$lastMonth";
        J_FILTER_OPERATORS["currentWeek"] = "$currentWeek";
        J_FILTER_OPERATORS["nextWeek"] = "$nextWeek";
        J_FILTER_OPERATORS["lastWeek"] = "$lastWeek";
        J_FILTER_OPERATORS["future"] = "$future";
    })(J_FILTER_OPERATORS = FilterUtils.J_FILTER_OPERATORS || (FilterUtils.J_FILTER_OPERATORS = {}));
    let J_FILTER_JOIN_OPERATORS;
    (function (J_FILTER_JOIN_OPERATORS) {
        J_FILTER_JOIN_OPERATORS["and"] = "$and";
        J_FILTER_JOIN_OPERATORS["or"] = "$or";
    })(J_FILTER_JOIN_OPERATORS = FilterUtils.J_FILTER_JOIN_OPERATORS || (FilterUtils.J_FILTER_JOIN_OPERATORS = {}));
    function filterToJFilter(filters, joinOperator) {
        const jFiltersObject = {};
        const jFilterObjects = [];
        filters.forEach(filter => {
            const field = filter.field;
            const operator = filter.operator;
            const value = filter.value;
            const jFilterObject = {};
            const jFilterOperatorObject = {};
            jFilterOperatorObject[operator] = value;
            jFilterObject[field] = jFilterOperatorObject;
            jFilterObjects.push(jFilterObject);
        });
        jFiltersObject[joinOperator] = [...jFilterObjects];
        return jFiltersObject;
    }
    FilterUtils.filterToJFilter = filterToJFilter;
    function filtersToJFilter(filters, joinOperator) {
        const jFilterObject = {};
        jFilterObject[joinOperator] = filters.map(filter => filterToJFilter(filter, J_FILTER_JOIN_OPERATORS.and)) || [];
        return jFilterObject;
    }
    FilterUtils.filtersToJFilter = filtersToJFilter;
    function combineJFilterObjects(filters, joinOperator) {
        const jFilterObject = {};
        jFilterObject[joinOperator] = filters;
        return jFilterObject;
    }
    FilterUtils.combineJFilterObjects = combineJFilterObjects;
    FilterUtils.getFilterFieldKey = (filter) => {
        const key = Object.keys(filter)[0];
        let fieldKey = key;
        if (key === "$and") {
            const childKey = Object.keys(filter["$and"][0])[0];
            if (childKey === "$and") {
                fieldKey = Object.keys(filter["$and"][0]["$and"][0])[0];
            }
            else if (childKey === "or") {
                fieldKey = Object.keys(filter["$and"][0]["$or"][0])[0];
            }
            else {
                fieldKey = childKey;
            }
        }
        else if (key === "$or") {
            const childKey = Object.keys(filter["$or"][0])[0];
            if (childKey === "$and") {
                fieldKey = Object.keys(filter["$or"][0]["$and"][0])[0];
            }
            else if (childKey === "or") {
                fieldKey = Object.keys(filter["$or"][0]["$or"][0])[0];
            }
            else {
                fieldKey = childKey;
            }
        }
        return fieldKey;
    };
})(FilterUtils || (FilterUtils = {}));
