import React from "react";
import { StyleUtils } from "../../../../../../../helper/Style/StyleUtils";
import styled from "styled-components";
import TableCell, { TABLE_CELL_TYPES } from "./TableCell/TableCell";
const StyledTableRow = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.getTableCell = (index, cellData) => {
            const { tableCellActions, tableCellCommonActions, tableCellRowProps } = this.props;
            switch (cellData.type) {
                case TABLE_CELL_TYPES.FirstCell:
                    const firstCellActions = tableCellActions;
                    return (React.createElement(TableCell, Object.assign({ key: index }, cellData, tableCellRowProps, firstCellActions, tableCellCommonActions)));
                case TABLE_CELL_TYPES.IconCell:
                    const iconCellActions = tableCellActions;
                    return (React.createElement(TableCell, Object.assign({ key: index }, cellData, tableCellRowProps, iconCellActions, tableCellCommonActions)));
                case TABLE_CELL_TYPES.TextCell:
                    const textCellActions = tableCellActions;
                    return (React.createElement(TableCell, Object.assign({ key: index }, cellData, tableCellRowProps, textCellActions, tableCellCommonActions)));
            }
        };
    }
    render() {
        const { tableCellsData } = this.props;
        return (React.createElement(StyledTableRow, Object.assign({ className: "table-row" }, this.props), tableCellsData.map((cellData, index) => this.getTableCell(index, cellData))));
    }
}
export default TableRow;
