import { DocumentationNodeEditBar } from "./DocumentationNodeEditBar";
import React from "react";
import styled from "styled-components";
import { Editor } from '@tinymce/tinymce-react';
import "../styles.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const StyledEditorWrapper = styled.div `
width: 100%;
height: 100%;
`;
export const DocumentationEditor = (props) => {
    const { editorState, allowSave, handleEditorStateChange, isEdit, handleCreate, handleDelete, handleEdit, handleSave, allowDelete, handleDocxUpload } = props;
    return React.createElement(StyledEditorWrapper, null,
        React.createElement(DocumentationNodeEditBar, { allowDelete: allowDelete, allowSave: allowSave, isEdit: isEdit, handleCreate: handleCreate, handleDelete: handleDelete, handleEdit: handleEdit, handleSave: handleSave, handleDocxUpload: handleDocxUpload }),
        React.createElement(Editor, { initialValue: editorState, disabled: !isEdit, inline: !isEdit, init: {
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount autoresize'
                ],
                toolbar: `undo redo | formatselect | bold italic backcolor | 
                                    alignleft aligncenter alignright alignjustify | 
                                    bullist numlist outdent indent | removeformat | table | image | media | help`
            }, onEditorChange: handleEditorStateChange }));
};
