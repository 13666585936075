import React from "react";
import styled from "styled-components";
import FilterRow from "./FilterRow/FilterRow";
import { FilterUtils } from "../../../../helper/General/FilterUtils";
export const StyledFilterComponent = styled.div `
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;
const FilterComponent = (props) => {
    const { filters, filterFields, handleFilterChange, } = props;
    const getFilter = (filterField, filters) => {
        if (filterField.field === "JOB_CODE_ID1|JOB_CODE_ID2|JOB_CODE_ID3") {
            const filter = filters.filter(filter => {
                const fieldKey = FilterUtils.getFilterFieldKey(filter);
                const fieldName = filterField.field;
                return fieldName.includes(fieldKey);
            })[0];
            return filter;
        }
        else {
            const filter = filters.filter(filter => {
                const fieldKey = FilterUtils.getFilterFieldKey(filter);
                const fieldName = filterField.field;
                return fieldKey === fieldName;
            })[0];
            return filter;
        }
    };
    return (React.createElement(StyledFilterComponent, null, filterFields.map((filterField, index) => {
        const filter = getFilter(filterField, filters);
        return (React.createElement(FilterRow, { key: index, type: filterField.type, name: filterField.name, filterKey: filterField.field, filter: filter, filterField: filterField, handleFilterChange: handleFilterChange }));
    })));
};
export default FilterComponent;
