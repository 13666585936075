export var IconService;
(function (IconService) {
    IconService.TREE_ICONS = {
        TOGGLE_EXPANDED: "Inventory-Expand",
        TOGGLE_COLLAPSED: "Inventory-Collapse"
    };
    let STACKING_Z_INDEX;
    (function (STACKING_Z_INDEX) {
        STACKING_Z_INDEX[STACKING_Z_INDEX["BOTTOM_LEVEL"] = 0] = "BOTTOM_LEVEL";
        STACKING_Z_INDEX[STACKING_Z_INDEX["LEVEL_ONE"] = 1] = "LEVEL_ONE";
        STACKING_Z_INDEX[STACKING_Z_INDEX["LEVEL_TWO"] = 2] = "LEVEL_TWO";
        STACKING_Z_INDEX[STACKING_Z_INDEX["LEVEL_THREE"] = 3] = "LEVEL_THREE";
        STACKING_Z_INDEX[STACKING_Z_INDEX["LEVEL_FOUR"] = 4] = "LEVEL_FOUR";
        STACKING_Z_INDEX[STACKING_Z_INDEX["LEVEL_FIVE"] = 5] = "LEVEL_FIVE";
        STACKING_Z_INDEX[STACKING_Z_INDEX["TOP_LEVEL"] = 1000] = "TOP_LEVEL";
    })(STACKING_Z_INDEX = IconService.STACKING_Z_INDEX || (IconService.STACKING_Z_INDEX = {}));
    IconService.INVENTORY_TABLE_ICONS = {
        UPLOAD: "upload",
        DOWNLOAD: "download",
        INDICATOR_CIRCLE: "CommCircle",
        UNVERIFIED: "Inventory-State-Unverified",
        QUARANTINED: "Inventory-State-Quarantined",
        IN_TEST: "Inventory-State-InTest",
        AVAILABLE: "Inventory-State-Available",
        CHECKED_OUT: "FsrTable-Fsr_EnRoute-StateIcon",
        INSTALLED: "OrderTable-Order_Completed-StateIcon",
        MISSING: "Inventory-State-Missing",
        DAMAGED: "Inventory-State-Damaged",
        UNAVAILABLE: "OrderTable-Order_Cancelled-StateIcon",
        RMA: "Inventory-Item",
        REMOVED: "OrderTable-Order_Returned-StateIcon",
        SCRAPPED: "bin",
        CREATED_IN_ERROR: "OrderTable-Order_Unacknowledged-StateIcon",
        HISTORY: "FsrTable-Fsr_TimerActive-StateIcon",
        MODIFY_OWNER: "FsrTable-Fsr_SignedOn-StateIcon",
        MODIFY_PARENT_CONTAINER: "Inventory-Box"
    };
})(IconService || (IconService = {}));
