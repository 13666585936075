import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryWrite;
(function (InventoryWrite) {
    InventoryWrite.WriteServiceName = "write";
    let WriteActionName;
    (function (WriteActionName) {
        WriteActionName["CONTAINER_CREATE"] = "container/create";
        WriteActionName["CONTAINER_UPDATE"] = "container/update";
        WriteActionName["INVENTORY_CREATE"] = "inventory/create";
        WriteActionName["INVENTORY_UPDATE"] = "inventory/update";
    })(WriteActionName = InventoryWrite.WriteActionName || (InventoryWrite.WriteActionName = {}));
    function containerCreateRequest(containerObject) {
        const request = {
            service: InventoryWrite.WriteServiceName,
            action: WriteActionName.CONTAINER_CREATE,
            request: containerObject
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryWrite.containerCreateRequest = containerCreateRequest;
    function containerUpdateRequest(containerObject) {
        const request = {
            service: InventoryWrite.WriteServiceName,
            action: WriteActionName.CONTAINER_UPDATE,
            request: containerObject
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryWrite.containerUpdateRequest = containerUpdateRequest;
    function inventoryCreateRequest(inventoryObject) {
        const request = {
            service: InventoryWrite.WriteServiceName,
            action: WriteActionName.INVENTORY_CREATE,
            request: inventoryObject
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryWrite.inventoryCreateRequest = inventoryCreateRequest;
    function inventoryUpdateRequest(inventoryObject) {
        const request = {
            service: InventoryWrite.WriteServiceName,
            action: WriteActionName.INVENTORY_UPDATE,
            request: inventoryObject
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryWrite.inventoryUpdateRequest = inventoryUpdateRequest;
})(InventoryWrite || (InventoryWrite = {}));
