import React from "react";
import styled from "styled-components";
import MessageResenderOrderRow from "./MessageResenderOrderRow/MessageResenderOrderRow";
const StyledMessageResenderOrders = styled.div `
  width: calc(15% - 10px);
  max-width: calc(15% - 10px);
  height: 100%;
  min-width: 120px;
  padding: 5px;
  float: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-y: scroll;
  overflow: auto;
`;
class MessageResenderOrders extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledMessageResenderOrders, { className: "message-resender-orders-container" },
            React.createElement("label", { style: { marginBottom: "1rem" } }, "Orders: "),
            this.props.orderNumber.map(orderNumber => {
                if (orderNumber) {
                    return (React.createElement(MessageResenderOrderRow, { orderNumber: orderNumber, handleOrderRowClick: this.props.handleOrderRowClick }, { orderNumber: orderNumber }));
                }
            })));
    }
}
export default MessageResenderOrders;
