import React from "react";
import DOMPurify from 'dompurify';
const page = { __html: require("../../../../vott/build/index.html") };
export default class ImageTagging extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "image-tagging-page", dangerouslySetInnerHTML: DOMPurify.sanitize(page) }));
    }
}
