import { AppActionTypes, SLPages } from "./types";
export const initialState = {
    isAuthenticated: false,
    title: SLPages.LOGIN,
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AppActionTypes.SET_IS_AUTHENTICATED: {
            return Object.assign(Object.assign({}, state), { isAuthenticated: action.isAuthenticated });
        }
        case AppActionTypes.SET_TITLE: {
            window.document.title = `Service Link - ${action.title}`;
            return Object.assign(Object.assign({}, state), { title: action.title });
        }
        default:
            return state;
    }
};
