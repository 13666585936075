import React, { Component } from 'react';
class Selectbox extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            y: 0,
            x: 0,
            width: 0,
            height: 0,
            isSelecting: false
        };
        this.selectbox = null;
        this.getRef = () => this.selectbox;
        this.getSelectboxRef = (ref) => {
            this.selectbox = ref;
        };
    }
    render() {
        const { fixedPosition, className } = this.props;
        const boxStyle = {
            left: this.state.x,
            top: this.state.y,
            width: this.state.width,
            height: this.state.height,
            zIndex: 9000,
            position: fixedPosition ? 'fixed' : 'absolute',
            cursor: 'default'
        };
        return (React.createElement("div", null, this.state.isSelecting && (React.createElement("div", { ref: this.getSelectboxRef, style: boxStyle, className: className }))));
    }
}
Selectbox.defaultProps = {
    className: 'selectable-selectbox'
};
export default Selectbox;
