import { DispatchPageContainerActionTypes } from "./types";
export const initialize = (theme) => {
    return {
        type: DispatchPageContainerActionTypes.INITIALIZE,
        theme: theme
    };
};
export const setDispatchPageContainerState = (state) => {
    return {
        type: DispatchPageContainerActionTypes.SET_DISPATCH_PAGE_CONTAINER_STATE,
        state: state
    };
};
export const setOrderFilterString = (orderFilterString) => {
    return {
        type: DispatchPageContainerActionTypes.SET_ORDER_FILTER_STRING,
        orderFilterString: orderFilterString
    };
};
export const setFsrFilterString = (fsrFilterString) => {
    return {
        type: DispatchPageContainerActionTypes.SET_FSR_FILTER_STRING,
        fsrFilterString: fsrFilterString
    };
};
export const setVehicleFilterString = (vehicleFilterString) => {
    return {
        type: DispatchPageContainerActionTypes.SET_VEHICLE_FILTER_STRING,
        vehicleFilterString: vehicleFilterString
    };
};
export const setSelectedOrderIds = (selectedOrderIds) => {
    return {
        type: DispatchPageContainerActionTypes.SET_SELECTED_ORDER_IDS,
        selectedOrderIds: selectedOrderIds,
    };
};
export const setSelectedOrders = (selectedOrders) => {
    return {
        type: DispatchPageContainerActionTypes.SET_SELECTED_ORDERS,
        selectedOrders: selectedOrders,
    };
};
export const SetCachedOrders = (cachedOrders) => {
    return {
        type: DispatchPageContainerActionTypes.SET_CACHED_ORDERS,
        cachedOrders: cachedOrders,
    };
};
export const dispatchOrders = (fsrs, orders, dispatchDate) => ({
    type: DispatchPageContainerActionTypes.DISPATCH_ORDERS,
    fsrs,
    orders,
    dispatchDate
});
export var DispatchResultsType;
(function (DispatchResultsType) {
    DispatchResultsType[DispatchResultsType["NOOP"] = 0] = "NOOP";
    DispatchResultsType[DispatchResultsType["DISPATCH"] = 1] = "DISPATCH";
    DispatchResultsType[DispatchResultsType["REASSIGN"] = 2] = "REASSIGN";
    DispatchResultsType[DispatchResultsType["FAILURE"] = 3] = "FAILURE";
})(DispatchResultsType || (DispatchResultsType = {}));
export const setSelectedFsrIds = (selectedFsrIds) => {
    return {
        type: DispatchPageContainerActionTypes.SET_SELECTED_FSR_IDS,
        selectedFsrIds: selectedFsrIds
    };
};
export const setSelectedVehicleIds = (selectedVehicleIds) => {
    return {
        type: DispatchPageContainerActionTypes.SET_SELECTED_VEHICLE_IDS,
        selectedVehicleIds: selectedVehicleIds
    };
};
export const setDate = (date) => {
    return {
        type: DispatchPageContainerActionTypes.SET_DATE,
        date: date
    };
};
export const setSettings = (settings) => {
    return {
        type: DispatchPageContainerActionTypes.SET_SETTINGS,
        settings: settings
    };
};
export const modifySettings = (settings) => {
    return {
        type: DispatchPageContainerActionTypes.MODIFY_SETTINGS,
        settings: settings
    };
};
export const setIsDragging = (isDragging) => {
    return {
        type: DispatchPageContainerActionTypes.SET_IS_DRAGGING,
        isDragging: isDragging
    };
};
export const setFsrVehMode = (fsrVehMode) => {
    return {
        type: DispatchPageContainerActionTypes.SET_FSR_VEH_MODE,
        fsrVehMode: fsrVehMode
    };
};
export const setMapExtent = (extent) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_EXTENT,
        extent: extent
    };
};
export const setMapSize = (size) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_SIZE,
        size: size
    };
};
export const setMapIcons = (mapIcons) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_ICONS,
        mapIcons: mapIcons
    };
};
export const updateMapIcons = () => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_MAP_ICONS
    };
};
export const handleMapSizeChangeEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_SIZE_CHANGE_EVENT,
        event: event
    };
};
export const handleMapExtentChangeEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_EXTENT_CHANGE_EVENT,
        event: event
    };
};
export const updateMapSelect = () => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_MAP_SELECT
    };
};
export const handleMapSelectEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_SELECT_EVENT,
        event: event
    };
};
export const handleMapUnselectEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_UNSELECT_EVENT,
        event: event
    };
};
export const handleMapMultiSelectEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_EVENT,
        event: event,
    };
};
export const handleMapMultiSelectCtrlEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_CTRL_EVENT,
        event: event,
    };
};
export const handleMapViewMoveEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_VIEW_MOVE_EVENT,
        event: event
    };
};
export const handleMapDisplayRouteEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_DISPLAY_ROUTE_EVENT,
        event: event
    };
};
export const handleMapAddRouteEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_ADD_ROUTE_EVENT,
        event: event
    };
};
export const handleMapRemoveRouteEvent = (event) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_REMOVE_ROUTE_EVENT,
        event: event
    };
};
export const setMapIConsAndRoutes = (mapIcons, mapRoutes) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_ICONS_AND_ROUTES,
        mapIcons: mapIcons,
        mapRoutes: mapRoutes
    };
};
export const dateChanged = (date) => ({ type: DispatchPageContainerActionTypes.SET_DATE, date });
export const dispatchSettingsChanged = (settings) => ({
    type: DispatchPageContainerActionTypes.MODIFY_SETTINGS,
    settings
});
export const dispatchShowCalendar = (fsrId, dateOpened, orders) => ({
    type: DispatchPageContainerActionTypes.SHOW_CALENDAR,
    fsrId,
    dateOpened,
    orders
});
export const dispatchCloseCalendar = (windowId) => ({
    type: DispatchPageContainerActionTypes.CLOSE_CALENDAR,
    windowId
});
export const dispatchShowModal = (message, title = message) => ({
    type: DispatchPageContainerActionTypes.SHOW_MODAL_DIALOG,
    title,
    message
});
export const dispatchCloseModal = (isOk) => ({
    type: DispatchPageContainerActionTypes.CLOSE_MODAL_DIALOG,
    isOk
});
export const saveFilter = (filterType, filterName, filter) => {
    return {
        type: DispatchPageContainerActionTypes.SAVE_FILTER,
        filterType: filterType,
        filterName: filterName,
        filter: filter,
    };
};
export const setTheme = (theme) => {
    return {
        type: DispatchPageContainerActionTypes.SET_THEME,
        theme: theme,
    };
};
export const handleMapDragEvent = (orderIds, orderNumbers, mapIsDragging) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_DRAG_EVENT,
        orderIds: orderIds,
        orderNumbers: orderNumbers,
        mapIsDragging: mapIsDragging,
    };
};
export const setMapDragData = (orderIds, orderNumbers, mapIsDragging) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_DRAG_DATA,
        orderIds: orderIds,
        orderNumbers: orderNumbers,
        mapIsDragging: mapIsDragging,
    };
};
export const handleMapHoverEvent = (orderId, coordinates) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_EVENT,
        orderId: orderId,
        coordinates: coordinates,
    };
};
export const handleMapHoverEndEvent = () => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_END_EVENT
    };
};
export const setMapHoverDataAction = (mapHoverData) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_HOVER_DATA,
        mapHoverData: mapHoverData,
    };
};
export const setMapLayerTree = (mapLayerTree) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_LAYER_TREE,
        mapLayerTree: mapLayerTree,
    };
};
export const handleMapTreeDataChangeEvent = (newTreeData) => {
    return {
        type: DispatchPageContainerActionTypes.HANDLE_MAP_TREE_DATA_CHANGE_EVENT,
        newTreeData: newTreeData,
    };
};
export const setMapSetting = (mapLayerSettings) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_LAYER_SETTINGS,
        mapLayerSettings: mapLayerSettings,
    };
};
export const setMapFinishedLoading = (mapFinishedLoading) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_FINISHED_LOADING,
        mapFinishedLoading: mapFinishedLoading,
    };
};
export const setHoverRouteData = (mapHoverRouteData) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_HOVER_ROUTE_DATA,
        mapHoverRouteData: mapHoverRouteData,
    };
};
export const updateEventMapIcons = (data) => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS,
        data: data,
    };
};
export const updateEventMapIconsAdd = (data) => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_ADD,
        data: data,
    };
};
export const updateEventMapIconsModify = (data) => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_MODIFY,
        data: data,
    };
};
export const updateEventMapIconsDelete = (data) => {
    return {
        type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_DELETE,
        data: data,
    };
};
export const creators = { dateChanged, dispatchSettingsChanged, dispatchShowCalendar, dispatchCloseCalendar };
