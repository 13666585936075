import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleIcon from "../SimpleIcon/SimpleIcon";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
const StyledCheckboxWrapper = styled.div `
  height: 1.8rem;
  width: ${props => {
    let extraWidth = 1.8;
    if (props.hasIcon) {
        extraWidth = extraWidth + 2.3;
    }
    if (props.hasLabel) {
        extraWidth = extraWidth + 11;
    }
    return StyleUtils.getCssRemString(props.wrapperWidth || extraWidth);
}};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.wrapperPadding || "0.5rem")};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  cursor: pointer;
`;
const StyledCheckbox = styled.div `
  height: 1.2rem;
  width: 1.2rem;
  padding: 0.2rem;
  border-width: 0.1rem;
  border-radius: 0.2rem;
  border-style: solid;
  border-color: ${props => props.outlineColor};
  background-color: ${props => props.checkboxColor};
  transition: background-color 0.3s, border-color 0.3s;
`;
const StyledCheckIconWrapper = styled.div `
  height: 1rem;
  width: 1rem;
  padding: 0.1rem;
`;
const StyledCheckboxText = styled.div `
  height: 1.4rem;
  width: ${props => props.textWidth || "10rem"};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.textPadding || "0.2rem 0")};
  font-size: 1.4rem;
  line-height: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: ${props => props.textColor};
`;
const StyledIconWrapper = styled.div `
  height: 1.4rem;
  width: 1.4rem;
  padding: 0.2rem 0;
`;
const StyledSeparator = styled.div `
  height: 1.8rem;
  width: 0.5rem;
`;
class SimpleCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleOnMouseLeave = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isHover: false })));
            event.preventDefault();
            event.stopPropagation();
        };
        this.handleOnMouseEnter = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isHover: true })));
            event.preventDefault();
            event.stopPropagation();
        };
        this.handleOnClick = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isChecked: this.handleCheckboxChange(prevState.isChecked) })));
            event.preventDefault();
            event.stopPropagation();
        };
        this.handleCheckboxChange = (prevStateChecked) => {
            const newStateChecked = !prevStateChecked;
            if (this.props.onCheckboxChangeHandler) {
                this.props.onCheckboxChangeHandler({
                    id: this.props.id,
                    isChecked: newStateChecked
                });
            }
            return newStateChecked;
        };
        this.getIcon = (iconName, customIconColor) => {
            if (iconName === undefined || iconName === null) {
                return null;
            }
            else {
                return (React.createElement(StyledIconWrapper, { className: "checkbox-icon" },
                    React.createElement(SimpleIcon, { iconName: iconName, color: customIconColor, size: "1.4rem", offsetY: "-0.3rem" })));
            }
        };
        this.state = {
            isChecked: !!this.props.initialCheckedState,
            isHover: false
        };
    }
    render() {
        const { label, labelColor, iconName, iconColor, selectedColor, selectedHoverColor, backgroundColor, borderColor, checkColor, checkHoverColor, wrapperPadding, wrapperWidth, textPadding, textWidth } = this.props;
        const { isChecked, isHover } = this.state;
        const checkboxColor = isChecked
            ? isHover
                ? selectedHoverColor || this.theme.palette.themeDark
                : selectedColor || this.theme.palette.themePrimary
            : backgroundColor || this.theme.palette.white;
        const outlineColor = isChecked ? checkboxColor : borderColor || this.theme.palette.black;
        const checkIconColor = isChecked
            ? checkColor || this.theme.palette.white
            : isHover
                ? checkHoverColor || this.theme.palette.neutralPrimaryAlt
                : checkboxColor;
        const customIconColor = iconColor || this.theme.palette.black;
        const textColor = labelColor || this.theme.palette.black;
        const hasIcon = iconName !== undefined && iconName !== null;
        const hasLabel = label !== undefined && label !== null;
        return (React.createElement(StyledCheckboxWrapper, { className: "checkbox-wrapper", onMouseEnter: this.handleOnMouseEnter, onMouseLeave: this.handleOnMouseLeave, onClick: this.handleOnClick, wrapperPadding: wrapperPadding, wrapperWidth: wrapperWidth, hasIcon: hasIcon, hasLabel: hasLabel },
            React.createElement(StyledCheckbox, { className: "checkbox", checkboxColor: checkboxColor, outlineColor: outlineColor },
                React.createElement(StyledCheckIconWrapper, null,
                    React.createElement(SimpleIcon, { iconName: "SL-Checkbox-Check", color: checkIconColor, size: "1rem", offsetY: "-0.5rem" }))),
            hasIcon ? (React.createElement(React.Fragment, null,
                React.createElement(StyledSeparator, { className: "icon-separator" }),
                this.getIcon(iconName, customIconColor))) : null,
            hasLabel ? (React.createElement(React.Fragment, null,
                React.createElement(StyledSeparator, { className: "icon-separator" }),
                React.createElement(StyledCheckboxText, { className: "checkbox-label", textColor: textColor, hasIcon: hasIcon, hasLabel: hasLabel, textPadding: textPadding, textWidth: textWidth }, label))) : null));
    }
}
export default SimpleCheckbox;
