import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import Dropdown from "../../../Dropdown/Dropdown";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import { Utils } from "../../../../../helper/General/Utils";
const StyledInventoryModifyContainerScreen = styled.div `
  height: 100%;
  width: 100%;
  background-color: ${props => props.palette.white};
`;
const StyledContainerSelectDropdownWrapper = styled.div `
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledContainerSelectDropdown = styled.div `
  height: 40px;
  width: calc(50% - 10px);
  padding: 0 5px;
`;
const StyledContainerSelectTitle = styled.div `
  height: 20px;
  width: 100%;
  padding: 5px 0;
`;
class InventoryModifyParentContainerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleContainerChange = (event) => {
            if (this.state.selectedContainerId) {
                this.props.actions.handleContainerChange({
                    selectedItems: this.props.data.selectedItems,
                    containerId: this.state.selectedContainerId
                });
            }
        };
        this.state = {
            originalSelectedItems: Utils.deepClone(this.props.data.selectedItems),
            buttonId: -1,
            selectedContainerType: "",
            selectedContainerId: -1
        };
    }
    render() {
        const failureItemsReasonWrapperStyle = {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemContainerStyle = {
            height: "100%",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const itemStyle = {
            height: "22px",
            fontSize: "19px",
            padding: StyleUtils.getCssPropertyFromArray([2, 11, 2, 11])
        };
        const failureReasonTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const failReasonContainerStyle = {
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const buttonStyle = {
            height: "40px",
            width: "200px",
            padding: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10]),
            color: "#333333",
            fontSize: "20px",
            textAlign: "center",
            margin: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10])
        };
        const containerTypes = [
            {
                key: "pallet",
                text: "Pallet"
            },
            {
                key: "box",
                text: "Box"
            }
        ];
        const containers = this.state.selectedContainerType === ""
            ? []
            : this.state.selectedContainerType === "pallet"
                ? this.props.data.pallets
                : this.props.data.boxes;
        const containerTextPrefix = this.state.selectedContainerType === "pallet" ? "Pallet" : "Box";
        const containerOptions = containers.map(container => {
            const containerText = this.state.selectedContainerType === "pallet" ? container.id : `${container.parentContainerId}-${container.id}`;
            return {
                key: container.uniqueId.toString(),
                text: `${containerTextPrefix} ${containerText}`
            };
        });
        return (React.createElement(StyledInventoryModifyContainerScreen, Object.assign({}, this.theme),
            React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-reasons-wrapper", style: failureItemsReasonWrapperStyle },
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-container", style: itemContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-item-title text-unselectable", style: itemTitleStyle }, "Items"),
                    React.createElement("div", { style: {
                            height: "100%",
                            width: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden"
                        } }, this.state.originalSelectedItems.map((item, index) => {
                        const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                        return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-item text-unselectable", key: index, style: Object.assign(Object.assign({}, itemStyle), { backgroundColor }) }, item.attributes.flexNetId));
                    }))),
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-container", style: failReasonContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-title text-unselectable", style: failureReasonTitleStyle }, "Change Containers"),
                    React.createElement(StyledContainerSelectDropdownWrapper, null,
                        React.createElement(StyledContainerSelectDropdown, null,
                            React.createElement(StyledContainerSelectTitle, null, "Select Container Type"),
                            React.createElement(Dropdown, { options: containerTypes, handleChange: option => this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedContainerType: option.key.toString() }))) })),
                        React.createElement(StyledContainerSelectDropdown, null,
                            React.createElement(StyledContainerSelectTitle, null, "Select Container"),
                            React.createElement(Dropdown, { options: containerOptions, handleChange: option => this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedContainerId: parseInt(option.key.toString()) }))) }))),
                    React.createElement("div", { style: { height: "10px" } }),
                    React.createElement("button", { className: "text-unselectable", onMouseEnter: () => this.setState({ buttonId: 0 }), onMouseLeave: () => this.setState({ buttonId: -1 }), onClick: this.handleContainerChange, style: Object.assign(Object.assign({}, buttonStyle), { backgroundColor: this.state.buttonId === 0 ? "#A2DEFF" : "#FFFFFF" }) }, "Change Container")))));
    }
}
export default InventoryModifyParentContainerScreen;
