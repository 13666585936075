import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import { ThemeContext } from "../../../theme/ThemeContextProvider";
export const StyledButtonWrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: ${props => {
    switch (props.horizontalAlignment) {
        case "left": {
            return "flex-start";
        }
        case "center": {
            return "center";
        }
        case "right": {
            return "flex-end";
        }
    }
}};
  justify-content: center;
  user-select: none;
  cursor: ${props => props.isEnabled ? "pointer" : "auto"};
  transition: background-color 0.1s, border-color 0.1s;
  overflow: hidden;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isMouseOver, props.isMouseDown, props.isEnabled)};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => getVariableColor(props.borderColor, props.isMouseOver, props.isMouseDown, props.isEnabled)};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
`;
export const getVariableColor = (color, isMouseOver, isMouseDown, isEnabled) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        if (!isEnabled && color.disabled) {
            return color.disabled;
        }
        if (isMouseDown) {
            return color.mouseDown;
        }
        if (isMouseOver) {
            return color.mouseOver;
        }
        return color.normal;
    }
};
const Button = (props) => {
    const { className = "button", height = 16, width = 16, backgroundColor = "#FFFFFF", margin = 0, borderWidth = 1, borderColor = "#000000", color = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, horizontalAlignment = "center", isEnabled = true, handleClick, renderChild } = props;
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const { theme: contextTheme } = useContext(ThemeContext);
    const theme = contextTheme;
    useEffect(() => {
        window.addEventListener("mouseup", onWindowMouseUp);
        return () => {
            window.removeEventListener("mouseup", onWindowMouseUp);
        };
    });
    const onMouseEnter = (e) => {
        setIsMouseOver(true);
    };
    const onMouseLeave = (e) => {
        setIsMouseOver(false);
    };
    const onMouseDown = (e) => {
        setIsMouseDown(true);
    };
    const onMouseUp = (e) => {
        setIsMouseDown(false);
    };
    const onWindowMouseUp = () => {
        setIsMouseDown(false);
    };
    const onClick = (e) => {
        if (handleClick && isEnabled) {
            e.stopPropagation();
            handleClick();
        }
    };
    const renderChildColor = getVariableColor(color, isMouseOver, isMouseDown, isEnabled);
    const renderChildBackgroundColor = getVariableColor(backgroundColor, isMouseOver, isMouseDown, isEnabled);
    return (React.createElement(StyledButtonWrapper, { className: `${className}`, isMouseOver: isMouseOver, isMouseDown: isMouseDown, onClick: onClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseDown: onMouseDown, onMouseUp: onMouseUp, height: height, width: width, backgroundColor: backgroundColor, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding, horizontalAlignment: horizontalAlignment, isEnabled: isEnabled }, renderChild(renderChildColor, renderChildBackgroundColor)));
};
export default Button;
