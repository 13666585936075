import React from "react";
import LogoBar from "../components/LogoBar/LogoBar";
import styled from "styled-components";
import LoadingBar from "../components/LoadingBar/LoadingBar";
import LoginMenu from "../components/LoginMenu/LoginMenu";
const StyledLoginPage = styled.div `
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;
const StyledLoginMenuWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
const LoginPage = (props) => {
    const { handleLogin, handleADLogin, handleActiveDirectoryLogin, handle2FA, handle2FAResend, isInitialized, isTwoFactorAuthenticating, contactInformation, countdownTimeSeconds, activeDirectoryEnabled, slAuthEnabled, plainAdLoginEnabled, activeDirectoryMode, isAskingForBU, isTwoFactorAuthenticationEnabled, errorMessage, message, businessUnitOptions, initialBusinessUnit, initialLogin, loginMenuLogoSrc = "/resources/images/comp.svg", logoBarLogoSrc = "/resources/images/comp.svg", isLoadingComplete, height = 456, width = 350, titleBarHeight = 50, backgroundColor = "#FFFFFF", loginMenuBackgroundColor = "#F6F6F6", focusColor = "#FC970A", loadingColor = "#D62C1B", errorColor = "#D62C1B", fontColorWhite = "#FFFFFF", fontColor = "#757575", fontColorBlack = "#000000", themeColor = "#62757F", themeColorDark = "#4E5D65", themeColorDarker = "#374248" } = props;
    return (React.createElement(StyledLoginPage, { className: "login-page", backgroundColor: backgroundColor }, isInitialized ? (React.createElement(React.Fragment, null,
        React.createElement(LoadingBar, { isLoadingComplete: isLoadingComplete, loadingColor: loadingColor, loadedColor: themeColor }),
        React.createElement(LogoBar, { logoSrc: logoBarLogoSrc, backgroundColor: themeColor }),
        React.createElement(StyledLoginMenuWrapper, null,
            React.createElement(LoginMenu, { handleLogin: handleLogin, handleActiveDirectoryLogin: handleActiveDirectoryLogin, handle2FA: handle2FA, handle2FAResend: handle2FAResend, isTwoFactorAuthenticating: isTwoFactorAuthenticating, activeDirectoryEnabled: activeDirectoryEnabled, isAskingForBU: isAskingForBU, activeDirectoryMode: activeDirectoryMode, isTwoFactorAuthenticationEnabled: isTwoFactorAuthenticationEnabled, contactInformation: contactInformation, countdownTimeSeconds: countdownTimeSeconds, errorMessage: errorMessage, message: message, businessUnitOptions: businessUnitOptions, initialBusinessUnit: initialBusinessUnit, initialLogin: initialLogin, loginMenuLogoSrc: loginMenuLogoSrc, height: height, width: width, titleBarHeight: titleBarHeight, loginMenuBackgroundColor: loginMenuBackgroundColor, focusColor: focusColor, errorColor: errorColor, fontColorWhite: fontColorWhite, fontColor: fontColor, fontColorBlack: fontColorBlack, themeColor: themeColor, themeColorDark: themeColorDark, themeColorDarker: themeColorDarker, slAuthEnabled: slAuthEnabled, handleADLogin: handleADLogin, plainAdLoginEnabled: plainAdLoginEnabled })))) : null));
};
export default LoginPage;
