import Button from "../Button";
import React from "react";
import Icon from "../../Icon/Icon";
import styled from "styled-components";
import Spacer from "../../Spacer/Spacer";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
const StyledIconTextWrapper = styled.div `
  display: flex;
  flex-direction: ${props => props.iconFirst ? "row" : "row-reverse"};
  flex-wrap: nowrap;
`;
const StyledIconText = styled.div `
  color: ${props => props.fontColor};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
`;
const IconTextButton = (props) => {
    const { text = "", height = 16, width = 126, color = "#000000", backgroundColor = "#FFFFFF", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleClick, iconName, iconHeight = 14, iconWidth = 14, fontSize = 14, iconTextSeparation = 10, iconFirst = true, horizontalAlignment = "center", isEnabled, } = props;
    const renderChild = (color, backgroundColor) => {
        return (React.createElement(StyledIconTextWrapper, { iconFirst: iconFirst },
            React.createElement(Icon, { iconName: iconName, color: color, backgroundColor: backgroundColor, height: iconHeight, width: iconWidth, margin: 0, borderWidth: 0, padding: 0 }),
            React.createElement(Spacer, { width: iconTextSeparation }),
            React.createElement(StyledIconText, { fontSize: fontSize, fontColor: color }, text)));
    };
    return (React.createElement(Button, { className: "icon-text-button", renderChild: renderChild, handleClick: handleClick, height: height, width: width, color: color, backgroundColor: backgroundColor, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding, horizontalAlignment: horizontalAlignment, isEnabled: isEnabled }));
};
export default IconTextButton;
