import React from 'react';
import ApexCharts from 'apexcharts';
import { Utils } from "../../../helper/General/Utils";
class GraphView extends React.Component {
    constructor(props) {
        super(props);
    }
    async componentDidMount() {
        const url = new URL(window.location.href);
        const id = url.searchParams.get("id");
        const field = url.searchParams.get("field");
        let token = Utils.getCookieByName("X-ServiceLink-Auth");
        token = token || url.searchParams.get("token");
        const fileReq = await fetch("/sl3/api/orders?" + new URLSearchParams({
            columns: field,
            filter: `{"$and":[{"ID":{"$eq":[${id}]}}]}`
        }), {
            method: "GET",
            headers: { "X-ServiceLink-Auth": token }
        });
        const fileRsp = await fileReq.json();
        const file = fileRsp.data[0][field];
        const fileCsv = Utils.parseCSV(file);
        const parserReq = await fetch('/resources/generate_graph.js', { cache: "no-store" });
        const parser = await parserReq.text();
        const graphOptions = Utils.runCustomCode(parser, fileCsv); //options is an array of graph jsons!
        // for debugging, replace Utils.runCustomCode(parser, fileCsv) with fileCsv var, then paste contents of generate_graph.js here and adjust accordingly, eg
        // const inputData = fileCsv;
        // ...
        // <generate_graph.js>
        // ...
        // const graphOptions = [{ ... }];
        for (const graphOption of graphOptions) {
            const chart = new ApexCharts(document.getElementById("graph_view"), graphOption);
            chart.render();
        }
    }
    ;
    render() {
        return (React.createElement("div", { id: "graph_view" }));
    }
}
export default GraphView;
