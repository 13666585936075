export var AppActionTypes;
(function (AppActionTypes) {
    AppActionTypes["SET_IS_AUTHENTICATED"] = "app.SET_IS_AUTHENTICATED";
    AppActionTypes["SET_TITLE"] = "app.SET_TITLE";
})(AppActionTypes || (AppActionTypes = {}));
export var SLPages;
(function (SLPages) {
    SLPages["LOGIN"] = "Login";
    SLPages["DISPATCH"] = "Dispatch";
    SLPages["MANAGE"] = "Manage";
    SLPages["PASSWORD_CHANGE"] = "Password Change";
    SLPages["AUTOMATIC_VEHICLE_LOCATION"] = "Vehicle Location";
    SLPages["IMAGE_TAGGING"] = "Image Tagging";
    SLPages["DOCUMENTATION"] = "Documentation";
    SLPages["INVENTORY"] = "Inventory";
})(SLPages || (SLPages = {}));
