import m2 from "moment";
export class SLTime {
    //TODO: moment name clashes are currently happening so this is a small work around
    static NewMoment(dateString) {
        return m2(dateString);
    }
    static GetCurrentlySelectedTime() {
        return m2(sl.dispatchDate).toISOString(true);
    }
    static MapSchedulerDateStringIntoISODate(dateString) {
        return m2(dateString, "M/DD").toISOString(true);
    }
}
