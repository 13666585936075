import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import { Utils } from "../../../../../helper/General/Utils";
import Dropdown from "../../../Dropdown/Dropdown";
const StyledInventoryModifyOwnerScreen = styled.div `
  height: 100%;
  width: 100%;
  background-color: ${props => props.palette.white};
`;
const StyledOwnerSelectDropdownWrapper = styled.div `
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledOwnerSelectDropdown = styled.div `
  height: 40px;
  width: calc(50% - 10px);
  padding: 0 5px;
`;
const StyledOwnerSelectTitle = styled.div `
  height: 20px;
  width: 100%;
  padding: 5px 0;
`;
class InventoryModifyOwnerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleOwnerChange = (event) => {
            if (this.state.selectedOwnerType && this.state.selectedOwner) {
                this.props.actions.handleOwnerChange({
                    selectedItems: this.props.data.selectedItems,
                    ownerType: this.state.selectedOwnerType,
                    ownerId: parseInt(this.state.selectedOwner)
                });
            }
        };
        this.state = {
            originalSelectedItems: Utils.deepClone(this.props.data.selectedItems),
            buttonId: -1,
            selectedOwnerType: "",
            selectedOwner: ""
        };
    }
    render() {
        const failureItemsReasonWrapperStyle = {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemContainerStyle = {
            height: "100%",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const itemStyle = {
            height: "22px",
            fontSize: "19px",
            padding: StyleUtils.getCssPropertyFromArray([2, 11, 2, 11])
        };
        const failureReasonTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const failReasonContainerStyle = {
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const buttonStyle = {
            height: "40px",
            width: "200px",
            padding: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10]),
            color: "#333333",
            fontSize: "20px",
            textAlign: "center",
            margin: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10])
        };
        const ownerTypeDropdownOptions = this.props.data.inventoryOwnerTypes.length > 0
            ? this.props.data.inventoryOwnerTypes.map(ownerType => ({
                key: ownerType,
                text: ownerType
            }))
            : [];
        let ownerDropdownOptions = [];
        switch (this.state.selectedOwnerType) {
            case "user": {
                ownerDropdownOptions =
                    this.props.data.fsrs.map(fsr => ({
                        key: fsr.fsrId,
                        text: fsr.login
                    })) || [];
                break;
            }
            case "nobody": {
                ownerDropdownOptions = [
                    {
                        key: "-1",
                        text: "nobody"
                    }
                ];
                break;
            }
        }
        return (React.createElement(StyledInventoryModifyOwnerScreen, Object.assign({ className: "inventory-modify-owner-screen" }, this.theme),
            React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-reasons-wrapper", style: failureItemsReasonWrapperStyle },
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-container", style: itemContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-item-title text-unselectable", style: itemTitleStyle }, "Items"),
                    React.createElement("div", { style: {
                            height: "100%",
                            width: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden"
                        } }, this.state.originalSelectedItems.map((item, index) => {
                        const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                        return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-item text-unselectable", key: index, style: Object.assign(Object.assign({}, itemStyle), { backgroundColor }) }, item.attributes.flexNetId));
                    }))),
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-container", style: failReasonContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-title text-unselectable", style: failureReasonTitleStyle }, "Change Owners"),
                    React.createElement(StyledOwnerSelectDropdownWrapper, null,
                        React.createElement(StyledOwnerSelectDropdown, null,
                            React.createElement(StyledOwnerSelectTitle, null, "Select Owner Type"),
                            React.createElement(Dropdown, { options: ownerTypeDropdownOptions, handleChange: option => this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedOwnerType: option.key.toString() }))) })),
                        React.createElement(StyledOwnerSelectDropdown, null,
                            React.createElement(StyledOwnerSelectTitle, null, "Select Owner"),
                            React.createElement(Dropdown, { options: ownerDropdownOptions, handleChange: option => this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedOwner: option.key.toString() }))) }))),
                    React.createElement("div", { style: { height: "10px" } }),
                    React.createElement("button", { className: "text-unselectable", onMouseEnter: () => this.setState({ buttonId: 0 }), onMouseLeave: () => this.setState({ buttonId: -1 }), onClick: this.handleOwnerChange, style: Object.assign(Object.assign({}, buttonStyle), { backgroundColor: this.state.buttonId === 0 ? "#A2DEFF" : "#FFFFFF" }) }, "Change Owner")))));
    }
}
export default InventoryModifyOwnerScreen;
