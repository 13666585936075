import React from "react";
import SelectInput from "../SelectInput/SelectInput";
import moment from "moment";
const TimeInput = (props) => {
    const { isTwelveHourTime = false, interval = 5, initialTime, handleTimeChange, } = props;
    const minTwoDigits = (n) => {
        return (n < 10 ? "0" : "") + n;
    };
    const getOptions = () => {
        const options = [];
        const minutesInDay = 24 * 60;
        const optionsCount = minutesInDay / interval;
        for (let index = 0; index < optionsCount; index++) {
            const key = (index * interval).toString();
            const currentMinute = index * interval;
            const hour = Math.floor((index * interval) / 60);
            const normalizedHour = isTwelveHourTime ? hour % 12 : hour;
            const hourString = minTwoDigits(normalizedHour === 0 && isTwelveHourTime ? 12 : normalizedHour);
            const minuteString = minTwoDigits((index * interval) % 60);
            const isOverHalf = currentMinute >= minutesInDay / 2;
            const morningOrNightString = isTwelveHourTime ? (isOverHalf ? "PM" : "AM") : "";
            const text = `${hourString}:${minuteString} ${morningOrNightString}`;
            options.push(text);
        }
        return options;
    };
    const handleChange = (value) => {
        let hour = 0;
        let minute = 0;
        if (isTwelveHourTime) {
            const isAM = value.includes("AM");
            const hourTimeString = value.replace("AM", "").replace("PM", "").trim();
            const hourTimeStringArray = hourTimeString.split(":");
            hour = isAM ? parseInt(hourTimeStringArray[0]) : parseInt(hourTimeStringArray[0]) + 12;
            minute = parseInt(hourTimeStringArray[1]);
        }
        else {
            const hourTimeString = value.trim();
            const hourTimeStringArray = hourTimeString.split(":");
            hour = parseInt(hourTimeStringArray[0]);
            minute = parseInt(hourTimeStringArray[1]);
        }
        handleTimeChange(hour, minute);
    };
    const getInitialTime = () => {
        const hour = moment(initialTime).hour();
        const minute = moment(initialTime).minute();
        const totalMinutes = hour * 60 + minute;
        const totalNormalizedMinutes = Math.floor(totalMinutes / interval) * interval;
        const normalizedHour = Math.floor(totalNormalizedMinutes / 60);
        const normalizedMinute = totalNormalizedMinutes % 60;
        const hourString = minTwoDigits(isTwelveHourTime ? (normalizedHour === 0 ? 12 : normalizedHour > 12 ? normalizedHour - 12 : normalizedHour) : normalizedHour);
        const minuteString = minTwoDigits(normalizedMinute);
        const morningOrNightString = isTwelveHourTime ? (normalizedHour >= 12 ? " PM" : " AM") : "";
        return `${hourString}:${minuteString}${morningOrNightString}`;
    };
    return (React.createElement(SelectInput, { options: getOptions(), handleChange: handleChange, height: 20, width: 150, initialValue: getInitialTime() }));
};
export default TimeInput;
