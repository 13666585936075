import React from "react";
import { StyleUtils } from "../../../../../../../../../helper/Style/StyleUtils";
import styled from "styled-components";
const StyledFirstCell = styled.div `
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  background-color: ${props => (props.isSelected ? props.selectedColor : props.color)};
  border-style: solid;
  border-color: ${props => props.tableCellData.topBorderColor};
  border-width: ${props => `${StyleUtils.getCssPixelString(props.topBorderWidth)} 0 0 0`};
`;
class FirstCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onLeftClickHandler = (event) => {
            this.props.handleLeftClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
        this.onRightClickHandler = (event) => {
            this.props.handleRightClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
    }
    render() {
        return (React.createElement(StyledFirstCell, Object.assign({ className: "table-first-cell", onClick: this.onLeftClickHandler, onContextMenu: this.onRightClickHandler }, this.props)));
    }
}
export default FirstCell;
