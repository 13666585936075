import React, { useEffect, useRef, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import Fade from "@material-ui/core/Fade";
import WindowTitle from "./WindowTitle/WindowTitle";
import { useEventListener } from "../../../../hooks/General/useEventListener";
const StyledWindow = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  top: ${props => StyleUtils.getCssPixelString(props.top)};
  left: ${props => StyleUtils.getCssPixelString(props.left)};
  outline: none;
  z-index: ${props => props.zIndex};
  user-select: none;
`;
const StyledWindowContentWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  overflow: auto;
`;
const StyledEdgeResizeBar = styled.div `
  position: absolute;
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  top: ${props => StyleUtils.getCssPixelString(props.top)};
  left: ${props => StyleUtils.getCssPixelString(props.left)};
  outline: none;
  z-index: ${props => props.zIndex};
  cursor: ${props => props.cursor};
`;
const StyledCornerResizeBar = styled.div `
  position: absolute;
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  top: ${props => StyleUtils.getCssPixelString(props.top)};
  left: ${props => StyleUtils.getCssPixelString(props.left)};
  outline: none;
  z-index: ${props => props.zIndex};
  cursor: ${props => props.cursor};
`;
export var ResizeDirection;
(function (ResizeDirection) {
    ResizeDirection["NW"] = "nw";
    ResizeDirection["N"] = "n";
    ResizeDirection["NE"] = "ne";
    ResizeDirection["E"] = "e";
    ResizeDirection["SE"] = "se";
    ResizeDirection["S"] = "s";
    ResizeDirection["SW"] = "sw";
    ResizeDirection["W"] = "w";
})(ResizeDirection || (ResizeDirection = {}));
const Window = (props) => {
    const ref = useRef();
    const { open, title, icons = [], horizontalSpaceFromEdge = 50, verticalSpaceFromEdge = 50, titleHeight = 38, zIndex = 1, allowDragOutOfBounds = false, allowResizing = true, minHeight = 200, minWidth = 200, resizeHandleSize = 5, handleClose, } = props;
    const baseZIndex = zIndex * 10;
    const [state, setState] = useState({
        top: verticalSpaceFromEdge,
        left: horizontalSpaceFromEdge,
        height: minHeight,
        width: minWidth,
        windowHeight: minHeight,
        windowWidth: minWidth,
        startingTop: 0,
        startingHeight: 0,
        mousePositionTop: 0,
        startingLeft: 0,
        startingWidth: 0,
        mousePositionLeft: 0,
    });
    const [isDragging, setIsDragging] = useState(false);
    const [resizeDirection, setResizeDirection] = useState(null);
    useEffect(() => {
        window.addEventListener("mouseup", handleEventMouseUp);
        setInitialSize();
    }, []);
    const handleEventMouseUp = (e) => {
    };
    const setInitialSize = () => {
        setState({
            top: verticalSpaceFromEdge,
            left: horizontalSpaceFromEdge,
            height: window.innerHeight - verticalSpaceFromEdge * 2,
            width: window.innerWidth - horizontalSpaceFromEdge * 2,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            startingTop: 0,
            startingHeight: window.innerHeight - verticalSpaceFromEdge * 2,
            mousePositionTop: 0,
            startingLeft: 0,
            startingWidth: window.innerWidth - horizontalSpaceFromEdge * 2,
            mousePositionLeft: 0,
        });
    };
    const onResize = (e) => {
        if (allowResizing) {
            setState(prevState => {
                const heightRatio = window.innerHeight / prevState.windowHeight;
                const widthRatio = window.innerWidth / prevState.windowWidth;
                return Object.assign(Object.assign({}, prevState), { height: Math.round(Math.max(minHeight, prevState.height * heightRatio)), width: Math.round(Math.max(minWidth, prevState.width * widthRatio)), top: Math.round(Math.max(0, prevState.top * heightRatio)), left: Math.round(Math.max(0, prevState.left * widthRatio)), windowHeight: window.innerHeight, windowWidth: window.innerWidth });
            });
        }
        else {
            setInitialSize();
        }
    };
    const handleTitleMouseDown = (e) => {
        setIsDragging(true);
        const x = e.pageX;
        const y = e.pageY;
        setState(prevState => {
            return Object.assign(Object.assign({}, prevState), { mousePositionTop: y, mousePositionLeft: x });
        });
    };
    const onWindowMouseUp = (e) => {
        handleMouseUp();
    };
    const handleMouseUp = () => {
        setResizeDirection(null);
        setIsDragging(false);
        setState(prevState => {
            return Object.assign(Object.assign({}, prevState), { startingTop: 0, startingHeight: 0, startingLeft: 0, startingWidth: 0, mousePositionLeft: 0, mousePositionTop: 0 });
        });
    };
    const getNewSize = (prevState, pageX, pageY) => {
        let [newTop, newLeft, newHeight, newWidth] = [prevState.top, prevState.left, prevState.height, prevState.width];
        const maxHeight = prevState.startingTop + prevState.startingHeight;
        const maxTop = maxHeight - minHeight;
        const minTop = 0;
        const maxWidth = prevState.startingLeft + prevState.startingWidth;
        const maxLeft = maxWidth - minWidth;
        const minLeft = 0;
        const mouseLeftChange = pageX < 0 ? -prevState.left : pageX - prevState.mousePositionLeft;
        const mouseTopChange = pageY < 0 ? -prevState.top : pageY - prevState.mousePositionTop;
        switch (resizeDirection) {
            case ResizeDirection.W: {
                newLeft = Math.max(minLeft, Math.min(maxLeft, prevState.left + mouseLeftChange));
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width - mouseLeftChange));
                break;
            }
            case ResizeDirection.NW: {
                newTop = Math.max(minTop, Math.min(maxTop, prevState.top + mouseTopChange));
                newLeft = Math.max(minLeft, Math.min(maxLeft, prevState.left + mouseLeftChange));
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height - mouseTopChange));
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width - mouseLeftChange));
                break;
            }
            case ResizeDirection.N: {
                newTop = Math.max(minTop, Math.min(maxTop, prevState.top + mouseTopChange));
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height - mouseTopChange));
                break;
            }
            case ResizeDirection.NE: {
                newTop = Math.max(minTop, Math.min(maxTop, prevState.top + mouseTopChange));
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height - mouseTopChange));
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width + mouseLeftChange));
                break;
            }
            case ResizeDirection.E: {
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width + mouseLeftChange));
                break;
            }
            case ResizeDirection.SE: {
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height + mouseTopChange));
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width + mouseLeftChange));
                break;
            }
            case ResizeDirection.S: {
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height + mouseTopChange));
                break;
            }
            case ResizeDirection.SW: {
                newLeft = Math.max(minLeft, Math.min(maxLeft + mouseLeftChange));
                newHeight = Math.max(minHeight, Math.min(maxHeight, prevState.height + mouseTopChange));
                newWidth = Math.max(minWidth, Math.min(maxWidth, prevState.width - mouseLeftChange));
                break;
            }
        }
        return [newTop, newLeft, newHeight, newWidth];
    };
    const onWindowMouseMove = (e) => {
        const pageX = e.pageX;
        const pageY = e.pageY;
        if (isDragging) {
            setState(prevState => {
                const minTop = 0;
                const maxTop = prevState.windowHeight - prevState.height;
                const topChange = pageY - prevState.mousePositionTop;
                const newTop = allowDragOutOfBounds ? prevState.top + topChange : Math.max(minTop, Math.min(maxTop, prevState.top + topChange));
                const minLeft = 0;
                const maxLeft = prevState.windowWidth - prevState.width;
                const leftChange = pageX - prevState.mousePositionLeft;
                const newLeft = allowDragOutOfBounds ? prevState.left + leftChange : Math.max(minLeft, Math.min(maxLeft, prevState.left + leftChange));
                return Object.assign(Object.assign({}, prevState), { top: newTop, left: newLeft, mousePositionLeft: pageX, mousePositionTop: pageY });
            });
        }
        if (resizeDirection) {
            setState(prevState => {
                const [newTop, newLeft, newHeight, newWidth] = getNewSize(prevState, pageX, pageY);
                return Object.assign(Object.assign({}, prevState), { top: newTop, left: newLeft, height: newHeight, width: newWidth, windowHeight: window.innerHeight, windowWidth: window.innerWidth, mousePositionLeft: pageX, mousePositionTop: pageY });
            });
        }
        else {
            setState(prevState => {
                return Object.assign(Object.assign({}, prevState), { mousePositionLeft: pageX, mousePositionTop: pageY });
            });
        }
    };
    const handleResize = () => {
        setState(prevState => (Object.assign(Object.assign({}, prevState), { startingTop: ref.current ? ref.current.offsetTop : prevState.startingTop, startingLeft: ref.current ? ref.current.offsetLeft : prevState.startingLeft, startingHeight: ref.current ? ref.current.offsetHeight : prevState.startingHeight, startingWidth: ref.current ? ref.current.offsetWidth : prevState.startingWidth })));
    };
    const windowHeight = Math.max(minHeight, state.height);
    const windowWidth = Math.max(minWidth, state.width);
    useEventListener("mousemove", onWindowMouseMove, window);
    useEventListener("mouseup", onWindowMouseUp, window);
    return (React.createElement(React.Fragment, null,
        React.createElement(Fade, { in: open, timeout: 500 },
            React.createElement(StyledWindow, { className: "window", ref: ref, height: windowHeight, width: windowWidth, top: state.top, left: state.left, zIndex: baseZIndex },
                React.createElement(WindowTitle, { handleClose: handleClose, handleMouseDown: handleTitleMouseDown, title: title, icons: icons, height: titleHeight }),
                React.createElement(StyledWindowContentWrapper, { height: state.height - titleHeight, width: state.width }, props.children),
                allowResizing ? (React.createElement(React.Fragment, null,
                    React.createElement(StyledEdgeResizeBar, { height: windowHeight, width: resizeHandleSize, top: 0, left: 0, zIndex: baseZIndex + 1, cursor: "ew-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.W); handleResize(); } }),
                    React.createElement(StyledEdgeResizeBar, { height: resizeHandleSize, width: windowWidth, top: 0, left: 0, zIndex: baseZIndex + 1, cursor: "ns-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.N); handleResize(); } }),
                    React.createElement(StyledEdgeResizeBar, { height: windowHeight, width: resizeHandleSize, top: 0, left: windowWidth - resizeHandleSize, zIndex: baseZIndex + 1, cursor: "ew-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.E); handleResize(); } }),
                    React.createElement(StyledEdgeResizeBar, { height: resizeHandleSize, width: windowWidth, top: windowHeight - resizeHandleSize, left: 0, zIndex: baseZIndex + 1, cursor: "ns-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.S); handleResize(); } }),
                    React.createElement(StyledCornerResizeBar, { height: resizeHandleSize, width: resizeHandleSize, top: 0, left: 0, zIndex: baseZIndex + 2, cursor: "nwse-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.NW); handleResize(); } }),
                    React.createElement(StyledCornerResizeBar, { height: resizeHandleSize, width: resizeHandleSize, top: 0, left: windowWidth - resizeHandleSize, zIndex: baseZIndex + 2, cursor: "nesw-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.NE); handleResize(); } }),
                    React.createElement(StyledCornerResizeBar, { height: resizeHandleSize, width: resizeHandleSize, top: windowHeight - resizeHandleSize, left: windowWidth - resizeHandleSize, zIndex: baseZIndex + 2, cursor: "nwse-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.SE); handleResize(); } }),
                    React.createElement(StyledCornerResizeBar, { height: resizeHandleSize, width: resizeHandleSize, top: windowHeight - resizeHandleSize, left: 0, zIndex: baseZIndex + 2, cursor: "nesw-resize", onMouseDown: () => { setResizeDirection(ResizeDirection.SW); handleResize(); } }))) : null)),
        React.createElement(ReactResizeDetector, { handleWidth: true, handleHeight: true, onResize: onResize })));
};
export default Window;
