import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import ResizablePanel from "../../components/ResizableWrapper/ResizableWrapper";
import InventoryOmniSearchContainer from "../InventoryOmniSearchContainer/InventoryOmniSearchContainer";
import InventoryFilterContainer from "../InventoryFilterContainer/InventoryFilterContainer";
import InventoryTableContainer from "../InventoryTableContainer/InventoryTableContainer";
import InventoryScreenContainer from "../InventoryScreenContainer/InventoryScreenContainer";
import InventoryContextMenuContainer from "../InventoryContextMenuContainer/InventoryContextMenuContainer";
import { inventoryInitialize } from "../../../state/services/InventoryInitialization/actions";
import { AppStore } from "../../../App";
const StyledInventoryPageContainer = styled.div `
  height: 100%;
  width: 100%;
  background-color: ${props => props.palette.white};
  color: ${props => props.palette.black};
`;
class InventoryPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.state = {
            isLoaded: false
        };
    }
    componentDidMount() {
        AppStore.store.dispatch(inventoryInitialize(getTheme()));
    }
    render() {
        if (this.props.isLoaded) {
            return (React.createElement(StyledInventoryPageContainer, Object.assign({ className: "inventory-page-container" }, this.theme),
                React.createElement(ResizablePanel, { horizontal: true },
                    React.createElement(ResizablePanel.Item, { length: 35 },
                        React.createElement(ResizablePanel, null,
                            React.createElement(ResizablePanel.Item, { length: 10, name: "Inventory Omnisearch" },
                                React.createElement(InventoryOmniSearchContainer, null)),
                            React.createElement(ResizablePanel.Item, { length: 90, name: "Inventory Filter" },
                                React.createElement(InventoryFilterContainer, null)))),
                    React.createElement(ResizablePanel.Item, { length: 65, name: "Inventory Table" },
                        React.createElement(InventoryTableContainer, null))),
                React.createElement(InventoryContextMenuContainer, null),
                React.createElement(InventoryScreenContainer, { minDistanceFromEdge: 200 })));
        }
        else {
            return (React.createElement(StyledInventoryPageContainer, Object.assign({ className: "inventory-page-container" }, this.theme), "Loading..."));
        }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        isLoaded: state.inventory.inventoryGlobalState.initializationComplete
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryPageContainer);
