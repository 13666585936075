import axios from "axios";
import { FilterTypes } from "../../../../display/web/components/Filter/types";
import { Utils } from "../../../../helper/General/Utils";
import { FilterUtils } from "../../../../helper/General/FilterUtils";
import { SLRestEndpoint } from "../types";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export var Filter;
(function (Filter) {
    Filter.FilterServiceName = "filters";
    Filter.getFilterSettings = () => {
        return new Promise((resolve, reject) => {
            const promiseArray = [
                axios({
                    method: "POST",
                    url: `/sl3/api/${Filter.FilterServiceName}?type=order&module=filter`,
                    headers: {
                        "X-ServiceLink-Auth": cookies.get('X-ServiceLink-Auth')
                    }
                }),
                axios({
                    method: "POST",
                    url: `/sl3/api/${Filter.FilterServiceName}?type=fsr&module=filter`,
                    headers: {
                        "X-ServiceLink-Auth": cookies.get('X-ServiceLink-Auth')
                    }
                }),
                axios({
                    method: "POST",
                    url: `/sl3/api/${Filter.FilterServiceName}?type=vehicle&module=filter`,
                    headers: {
                        "X-ServiceLink-Auth": cookies.get('X-ServiceLink-Auth')
                    }
                })
            ];
            Promise.all(promiseArray).then((rsp) => {
                const orderServerFilterSetting = rsp[0].data;
                const fsrServerFilterSetting = rsp[1].data;
                const vehicleServerFilterSetting = rsp[2].data;
                const orderFilterSetting = getFilterSettingObjectFromServerFilterSetting(orderServerFilterSetting);
                const fsrFilterSetting = getFilterSettingObjectFromServerFilterSetting(fsrServerFilterSetting);
                const vehicleFilterSetting = getFilterSettingObjectFromServerFilterSetting(vehicleServerFilterSetting);
                resolve({
                    order: orderFilterSetting,
                    fsr: fsrFilterSetting,
                    vehicle: vehicleFilterSetting,
                });
            });
        });
    };
    Filter.saveUserFilter = (req) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: `${SLRestEndpoint}/users/filters`,
                data: req,
                headers: {
                    "X-ServiceLink-Auth": cookies.get('X-ServiceLink-Auth')
                }
            }).then(rsp => {
                resolve(rsp.status === 200);
            });
        });
    };
    //@ts-ignore
    window.saveUserFilter = Filter.saveUserFilter; //Calling this end point from legacy manage-tables.js remove if not used.
    Filter.saveDispatchFilter = (filterType, filterName, filter) => {
        return new Promise((resolve, reject) => {
            const filterString = Filter.getFilterString(filter);
            axios({
                method: "POST",
                url: "/sl3/api/filters",
                data: `type=${filterType}&module=filter&action=save&name=${filterName}&filter=${filterString}`,
            }).then(rsp => {
                resolve(rsp.status === 200);
            });
        });
    };
    Filter.getFilterString = (filter) => {
        const filterArray = filter.$and.filter(filter => {
            const key = Object.keys(filter)[0];
            return key !== "ALL_DATES";
        });
        let isAllDates = false;
        filter.$and.forEach(filter => {
            const key = Object.keys(filter)[0];
            if (key === "ALL_DATES") {
                isAllDates = filter[key].$eq.includes("true");
            }
        });
        const filterStringArray = filterArray.map(filter => {
            const filterKey = FilterUtils.getFilterFieldKey(filter);
            const filterString = JSON.stringify(filter);
            const filterStringNoBrackets = filterString.substring(1, filterString.length - 1);
            return `${filterKey}=${filterStringNoBrackets}`;
        });
        filterStringArray.push(`ALL_DATES=${isAllDates ? "true" : "false"}`);
        return filterStringArray.join(";");
    };
    const getFilterSettingObjectFromServerFilterSetting = (filterSetting) => {
        const filterFields = filterSetting.fields.map(field => ({
            name: field.label,
            field: field.field,
            primary: field.primary,
            images: field.images,
            type: field.type,
            selectType: field.select_type,
            displayedIcons: field.image_file,
            displayedNames: field.values,
            values: field.ids,
        }));
        filterFields.push({
            name: "All Dates",
            field: "ALL_DATES",
            primary: true,
            images: false,
            type: FilterTypes.BOOLEAN,
            selectType: "null",
            displayedIcons: [],
            displayedNames: [],
            values: [],
        });
        return {
            filterFields: filterFields,
            globalSearchFields: filterSetting.global_search_fields[0].fields.split(","),
            savedFilters: filterSetting.saved_filters.map(savedFilter => {
                const filterStringArray = savedFilter.setting.split(";");
                const filterArray = [];
                filterStringArray.forEach(filterString => {
                    const key = filterString.split("=")[0];
                    const string = `{${filterString.split("=")[1]}}`;
                    if (Utils.isJsonString(string)) {
                        const object = JSON.parse(string);
                        filterArray.push(object);
                    }
                    else {
                        if (key === "ALL_DATES") {
                            const object = {
                                [key]: {
                                    $eq: [filterString.split("=")[1]],
                                }
                            };
                            filterArray.push(object);
                        }
                    }
                });
                const filter = {
                    $and: filterArray
                };
                return {
                    name: savedFilter.name,
                    filter: filter
                };
            }),
        };
    };
})(Filter || (Filter = {}));
export default Filter;
