import React from "react";
import "./InventoryScreenContainer.css";
import Screen from "../../components/Screen/Screen";
import { connect } from "react-redux";
import { InventoryScreenContainerActionTypes } from "../../../state/containers/InventoryScreenContainer/types";
import { InventoryTableContainerActionTypes } from "../../../state/containers/InventoryTableContainer/types";
import { InventoryCacheActionTypes } from "../../../state/services/InventoryCache/types";
import { Inventory } from "../../../state/api/REST/Inventory/Inventory";
var importInventoryUploadRequest = Inventory.importInventoryUploadRequest;
class InventoryScreenContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(Screen, Object.assign({}, this.props));
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        titleText: state.inventory.inventoryScreenContainer.titleText,
        type: state.inventory.inventoryScreenContainer.type,
        name: state.inventory.inventoryScreenContainer.name,
        isVisible: state.inventory.inventoryScreenContainer.isVisible,
        screenData: state.inventory.inventoryScreenContainer.screenData
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        screenActions: {
            onDismiss() {
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            async handleUpload(fileList) {
                console.log(fileList);
                await importInventoryUploadRequest(fileList.item(0));
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            handleOwnerChange(event) {
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_OWNER,
                    itemIds: event.selectedItems.map(item => item.uniqueId),
                    ownerType: event.ownerType,
                    ownerId: event.ownerId
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            handleContainerChange(event) {
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_CONTAINER,
                    itemIds: event.selectedItems.map(item => item.uniqueId),
                    containerId: event.containerId
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            inventoryInTestToUnavailableScreenProceed(selectedItems, comment) {
                const selectedIds = selectedItems.map(item => item.uniqueId);
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: -3,
                    itemIds: selectedIds,
                    comment: comment
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            inventoryUnverifiedToQuarantinedScreenProceed(selectedItems, warehouseId) {
                const propertiesUpdateData = selectedItems.map(item => {
                    return {
                        inventoryData: item,
                        propertiesData: [
                            {
                                property: "originatingWarehouse",
                                value: warehouseId.toString()
                            }
                        ]
                    };
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_PROPERTIES,
                    data: propertiesUpdateData
                });
                const selectedIds = selectedItems.map(item => item.uniqueId);
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: 2,
                    itemIds: selectedIds
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_CONTAINER,
                    itemIds: selectedIds,
                    containerId: warehouseId
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            inventoryUnverifiedToQuarantinedScreenUpdateSearch(searchString) {
                dispatch({
                    type: InventoryScreenContainerActionTypes.UNVERIFIED_TO_QUARANTINED_SCREEN_UPDATE_SEARCH,
                    searchString: searchString
                });
            },
            inventoryDamagedToRemovedScreenProceed(selectedItems, containerId) {
                const propertiesUpdateData = selectedItems.map(item => {
                    return {
                        inventoryData: item,
                        propertiesData: [
                            {
                                property: "originatingWarehouse",
                                value: containerId.toString()
                            }
                        ]
                    };
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_PROPERTIES,
                    data: propertiesUpdateData
                });
                const selectedIds = selectedItems.map(item => item.uniqueId);
                dispatch({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: 7,
                    itemIds: selectedIds
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_CONTAINER,
                    itemIds: selectedIds,
                    containerId: containerId
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            inventoryDamagedToRemovedScreenUpdateSearch(searchString) {
                dispatch({
                    type: InventoryScreenContainerActionTypes.UNVERIFIED_TO_QUARANTINED_SCREEN_UPDATE_SEARCH,
                    searchString: searchString
                });
            },
            inventoryModifyContainerScanScreenProceed(selectedItems, containerId) {
                const selectedIds = selectedItems.map(item => item.uniqueId);
                const propertiesUpdateData = selectedItems.map(item => {
                    return {
                        inventoryData: item,
                        propertiesData: [
                            {
                                property: "originatingWarehouse",
                                value: containerId.toString()
                            }
                        ]
                    };
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_PROPERTIES,
                    data: propertiesUpdateData
                });
                dispatch({
                    type: InventoryCacheActionTypes.ITEM_UPDATE_CONTAINER,
                    itemIds: selectedIds,
                    containerId: containerId
                });
                dispatch({
                    type: InventoryScreenContainerActionTypes.CLOSE_SCREEN
                });
            },
            inventoryModifyContainerScanScreenUpdateSearch(searchString) {
                dispatch({
                    type: InventoryScreenContainerActionTypes.UNVERIFIED_TO_QUARANTINED_SCREEN_UPDATE_SEARCH,
                    searchString: searchString
                });
            }
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryScreenContainer);
