import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../../../SimpleButton/SimpleButton";
const StyledInventoryUploadScreen = styled.div `
  height: 100%;
  width: 20rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledInventoryUploadScreenCaption = styled.div `
  height: 1.6rem;
  width: calc(100% - 1rem);
  padding: 0.2rem 0.5rem;
  color: ${props => props.palette.black};
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: left;
`;
const StyledInventoryUploadInput = styled.input `
  height: 2rem;
  width: 100%;
  padding: 0.2rem 0.5rem;
  outline: none;
`;
class InventoryUploadScreen extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleUpload = (event) => {
            if (this.state.fileList) {
                this.props.actions.handleUpload(this.state.fileList);
            }
        };
        this.handleFileSelectChange = (event) => {
            const fileList = event.target.files;
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { fileList: fileList })));
        };
        this.state = {
            fileList: undefined
        };
    }
    render() {
        const acceptString = ".csv";
        return (React.createElement(StyledInventoryUploadScreen, { className: "inventory-upload-screen" },
            React.createElement(StyledInventoryUploadScreenCaption, Object.assign({}, this.theme), "Upload file here"),
            React.createElement(StyledInventoryUploadInput, Object.assign({}, this.theme, { type: "file", onChange: this.handleFileSelectChange, accept: acceptString })),
            React.createElement(SimpleButton, { className: "inventory-file-upload-button", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                    iconText: "Upload File",
                    height: "2rem",
                    width: "20rem"
                }, padding: "0.2rem 0.5rem", borderRadius: "0.2rem", borderWidth: "0.1rem", margin: "0.5rem", buttonClick: this.handleUpload })));
    }
}
export default InventoryUploadScreen;
