import React, { Component } from 'react';
import { bool } from 'prop-types';
import { getBoundsForNode } from './utils';
import SelectableGroupContext from './Context';
const createSelectable = (WrappedComponent) => { var _a; return _a = class SelectableItem extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                isSelected: this.props.isSelected,
                isSelecting: false
            };
            this.node = null;
            this.bounds = null;
            this.selectSelf = () => this.context.selectable.selectInList([this]);
            this.registerSelectable = (containerScroll) => {
                this.bounds = getBoundsForNode(this.node, containerScroll);
                this.context.selectable.register(this);
            };
            this.getSelectableRef = (ref) => {
                this.node = ref;
            };
        }
        componentDidMount() {
            this.registerSelectable();
        }
        componentWillUnmount() {
            this.context.selectable.unregister(this);
        }
        render() {
            return (React.createElement(WrappedComponent, Object.assign({}, this.props, this.state, { selectableRef: this.getSelectableRef, selectSelf: this.selectSelf })));
        }
    },
    _a.contextType = SelectableGroupContext,
    _a.propTypes = {
        isSelected: bool
    },
    _a.defaultProps = {
        isSelected: false
    },
    _a; };
export default createSelectable;
