import axios from "axios";
import { SLRestEndpoint } from "../types";
export var Auth;
(function (Auth) {
    Auth.AuthServiceName = "auth";
    let AuthEndpointName;
    (function (AuthEndpointName) {
        AuthEndpointName["DESKTOP"] = "desktop";
    })(AuthEndpointName = Auth.AuthEndpointName || (Auth.AuthEndpointName = {}));
    Auth.login = (req) => {
        return axios({
            method: "POST",
            url: generateURL(AuthEndpointName.DESKTOP),
            data: req,
        }).then(rsp => {
            return rsp.data;
        });
    };
    const generateURL = (endpoint) => {
        return `${SLRestEndpoint}/${Auth.AuthServiceName}/${endpoint}`;
    };
})(Auth || (Auth = {}));
export default Auth;
