import React from "react";
import "./InventoryContextMenuContainer.css";
import { connect } from "react-redux";
import ContextMenu from "../../components/ContextMenu/ContextMenu";
import { InventoryContextMenuContainerActionTypes } from "../../../state/containers/InventoryContextMenuContainer/types";
class InventoryContextMenuContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(ContextMenu, Object.assign({}, this.props));
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        xPosition: state.inventory.inventoryContextMenuContainer.xPosition,
        yPosition: state.inventory.inventoryContextMenuContainer.yPosition,
        isVisible: state.inventory.inventoryContextMenuContainer.isVisible,
        offset: state.inventory.inventoryContextMenuContainer.offset,
        data: state.inventory.inventoryContextMenuContainer.data
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        contextMenuActions: {
            onLeftClick(event) {
                dispatch({
                    type: InventoryContextMenuContainerActionTypes.OPTION_SELECT,
                    data: event
                });
            },
            handleDismiss() {
                dispatch({
                    type: InventoryContextMenuContainerActionTypes.CLOSE
                });
            },
            handleMouseDown() { },
            handleMouseUp() { }
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryContextMenuContainer);
