import React, { useState } from "react";
import styled from "styled-components";
import IconTextCheckbox from "../../../Checkbox/IconTextCheckbox";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
export const StyledCheckBoxFilterRow = styled.div `
  height: max-content;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const CheckBoxFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, selectableValues, handleFilterChange, } = props;
    const getInitialSelectedValues = () => {
        if (!initialFilter) {
            return [];
        }
        if (filterKey === "STATE_ID") {
            const selectedValues = initialFilter.$or[0][filterKey].$in;
            return selectedValues ? selectedValues : [];
        }
        else {
            const selectedValues = initialFilter[filterKey].$in;
            return selectedValues ? selectedValues : [];
        }
    };
    const getInitialIsEmergency = () => {
        if (!initialFilter) {
            return false;
        }
        if (filterKey === "STATE_ID") {
            const selectedValues = initialFilter.$or[0][filterKey].$in;
            return selectedValues.includes("000");
        }
        else {
            return false;
        }
    };
    const [selectedValues, setSelectedValues] = useState(getInitialSelectedValues());
    const [isEmergency, setIsEmergency] = useState(getInitialIsEmergency());
    const handleCheckboxChange = (e) => {
        setSelectedValues(prevState => {
            const newSelected = e.checked ? [...prevState, e.identifier] : prevState.filter(identifier => identifier !== e.identifier);
            const hasSelected = newSelected.length > 0;
            if (hasSelected && filterKey === "STATE_ID") {
                if (newSelected.includes("000")) {
                    setIsEmergency(true);
                    const mainFilterObject = {
                        [filterKey]: {
                            $in: newSelected,
                        }
                    };
                    const emergencyFilter = {
                        $and: [
                            {
                                "emergency": {
                                    $in: ["true"]
                                }
                            },
                            {
                                [filterKey]: {
                                    $nin: ["70", "90"]
                                }
                            },
                        ]
                    };
                    const combinedFilter = {
                        $or: [
                            mainFilterObject,
                            emergencyFilter,
                        ]
                    };
                    handleFilterChange({
                        filterObject: combinedFilter,
                        key: filterKey,
                    });
                }
                else {
                    setIsEmergency(false);
                    const filterObject = {
                        [filterKey]: {
                            $in: newSelected,
                        }
                    };
                    const combinedFilter = hasSelected ? {
                        $or: [
                            filterObject,
                        ]
                    } : null;
                    handleFilterChange({
                        filterObject: combinedFilter,
                        key: filterKey,
                    });
                }
            }
            else {
                setIsEmergency(false);
                const filterObject = hasSelected ? {
                    [filterKey]: {
                        $in: newSelected,
                    }
                } : null;
                handleFilterChange({
                    filterObject: filterObject,
                    key: filterKey,
                });
            }
            return newSelected;
        });
    };
    const handleClearFilter = () => {
        setSelectedValues([]);
        handleFilterChange({
            filterObject: null,
            key: filterKey,
        });
    };
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledCheckBoxFilterRow, null, Object.entries(selectableValues).map(([id, value], index) => {
            const name = value.name;
            const icon = value.icon;
            const selected = selectedValues.includes(id);
            return (React.createElement(IconTextCheckbox, { key: index, identifier: id, displayedText: name, initialState: selected, icon: icon, handleCheckboxChange: handleCheckboxChange }));
        }))));
};
export default CheckBoxFilterRow;
