import React from "react";
import styled from "styled-components";
import { MessageResenderSearchField } from "./MessageResenderSearchField/MessageResenderSearchField";
import { MessageResenderSearchButton } from "./MessageResenderSearchButton/MessageResenderSearchButton";
import moment from "moment";
const StyledMessageResenderSearchBar = styled.div `
  display: flex;
  & div {
    padding-left: 1ex;
  }
`;
export class MessageResenderSearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.handleOrderNumberChange = (orderNumber) => {
            this.setState({ orderNumber: orderNumber }, () => this.handleSearchChange());
        };
        this.handleStartDateChange = (startDate) => {
            this.setState({ startDate: startDate }, () => this.handleSearchChange());
        };
        this.handleEndDateChange = (endDate) => {
            this.setState({ endDate: endDate }, () => this.handleSearchChange());
        };
        this.handleSearchChange = () => {
            this.props.handleSearchStringsChange(this.state.orderNumber, this.state.startDate, this.state.endDate);
        };
        this.state = {
            orderNumber: "",
            startDate: moment(this.props.startDate).format(userSettings.display_date_format + " HH:mm"),
            endDate: moment(this.props.endDate).format(userSettings.display_date_format + " HH:mm")
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderSearchBar, null,
            React.createElement(MessageResenderSearchField, { domInputType: "text", handleInputChange: this.handleOrderNumberChange, description: "Order Number: ", editable: true }),
            React.createElement(MessageResenderSearchField, { domInputType: "datetime-local", handleInputChange: this.handleStartDateChange, description: "Start Time: ", editable: false, defaultValue: this.state.startDate }),
            React.createElement(MessageResenderSearchField, { domInputType: "datetime-local", handleInputChange: this.handleEndDateChange, description: "End Time: ", editable: false, defaultValue: this.state.endDate }),
            React.createElement(MessageResenderSearchButton, { handleClick: this.props.handleSearchButtonClick })));
    }
}
