import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
export const StyledTextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: ${props => {
    switch (props.horizontalAlignment) {
        case "left":
            return "flex-start";
        case "center":
            return "center";
        case "right":
            return "flex-end";
    }
}};
  justify-content: ${props => {
    switch (props.verticalAlignment) {
        case "top":
            return "flex-start";
        case "center":
            return "center";
        case "bottom":
            return "flex-end";
    }
}};
  user-select: none;
  overflow: hidden;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
`;
export const StyledText = styled.div `
  color: ${props => props.fontColor};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  font-weight: ${props => props.fontWeight};
  margin-left: ${props => props.horizontalAlignment === "left" ? StyleUtils.getNormalizedPixelString(props.horizontalSpaceFromEdge + props.horizontalMargin) : StyleUtils.getNormalizedPixelString(props.horizontalMargin)};
  margin-right: ${props => props.horizontalAlignment === "right" ? StyleUtils.getNormalizedPixelString(props.horizontalSpaceFromEdge + props.horizontalMargin) : StyleUtils.getNormalizedPixelString(props.horizontalMargin)};
  margin-top: ${props => props.verticalAlignment === "top" ? StyleUtils.getNormalizedPixelString(props.verticalSpaceFromEdge + props.verticalMargin) : StyleUtils.getNormalizedPixelString(props.verticalMargin)};
  margin-bottom: ${props => props.verticalAlignment === "bottom" ? StyleUtils.getNormalizedPixelString(props.verticalSpaceFromEdge + props.verticalMargin) : StyleUtils.getNormalizedPixelString(props.verticalMargin)};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Text = (props) => {
    const { className = "text", text = "text", height = 16, width = 100, backgroundColor = "#FFFFFF00", fontSize = 14, fontWeight = "normal", fontStyle = "normal", fontColor = "#000000", verticalAlignment = "center", horizontalAlignment = "center", horizontalMargin = 0, verticalMargin = 0, verticalSpaceFromEdge = 0, horizontalSpaceFromEdge = 0, borderRadius = 0, } = props;
    return (React.createElement(StyledTextWrapper, { className: `${className}-wrapper`, height: height, width: width, backgroundColor: backgroundColor, verticalAlignment: verticalAlignment, horizontalAlignment: horizontalAlignment, borderRadius: borderRadius },
        React.createElement(StyledText, { className: className, fontSize: fontSize, fontWeight: fontWeight, fontStyle: fontStyle, fontColor: fontColor, verticalAlignment: verticalAlignment, horizontalAlignment: horizontalAlignment, verticalSpaceFromEdge: verticalSpaceFromEdge, horizontalSpaceFromEdge: horizontalSpaceFromEdge, verticalMargin: verticalMargin, horizontalMargin: horizontalMargin }, text)));
};
export default Text;
