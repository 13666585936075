import React from "react";
import styled from "styled-components";
import Title from "../Title/Title";
import { getTheme } from "office-ui-fabric-react";
import { Subject } from "rxjs";
import { interval } from "rxjs/observable/interval";
const StyledOmniSearch = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledOmniSearchInputWrapper = styled.div `
  height: calc(100% - 4rem);
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: ${props => props.palette.white};
  overflow: hidden;
`;
const StyledOmniSearchInput = styled.input `
  height: 2rem;
  width: calc(100% - 2.2rem);
  font-size: 2rem;
  line-height: 2rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${props => props.palette.neutralDark};
  border-radius: 0.1rem;
  padding: 0.2rem 1rem;
  user-select: none;
`;
class OmniSearch extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleOmniSearchChange = (event) => {
            const newValue = event.currentTarget ? event.currentTarget.value || "" : "";
            this.onOmniSearchChangeThrottled.next(newValue);
        };
        this.onOmniSearchChangeThrottled = new Subject();
    }
    componentDidMount() {
        this.onOmniSearchChangeThrottled
            .debounce(() => interval(this.props.searchChangeDebounceTime))
            .subscribe(string => {
            this.props.handleSearchChange(string);
        });
    }
    render() {
        const { title } = this.props;
        return (React.createElement(StyledOmniSearch, { className: "omni-search" },
            React.createElement(Title, { titleDisplayedText: title }),
            React.createElement(StyledOmniSearchInputWrapper, Object.assign({ className: "omni-search-input-wrapper" }, this.props, this.theme),
                React.createElement(StyledOmniSearchInput, Object.assign({ className: "omni-search-input" }, this.props, this.theme, { onChange: this.handleOmniSearchChange, placeholder: "Search..." })))));
    }
}
export default OmniSearch;
