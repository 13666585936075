export var InventoryCacheActionTypes;
(function (InventoryCacheActionTypes) {
    InventoryCacheActionTypes["SET_INVENTORY_CACHE"] = "state.inventory.inventoryCache.SET_INVENTORY_CACHE";
    InventoryCacheActionTypes["INITIALIZE_CACHE"] = "state.inventory.inventoryCache.INITIALIZE_CACHE";
    InventoryCacheActionTypes["ITEM_UPDATE_CONTAINER"] = "state.inventory.inventoryCache.ITEM_UPDATE_CONTAINER";
    InventoryCacheActionTypes["ITEM_UPDATE_OWNER"] = "state.inventory.inventoryCache.ITEM_UPDATE_OWNER";
    InventoryCacheActionTypes["ITEM_UPDATE_PROPERTIES"] = "state.inventory.inventoryCache.ITEM_UPDATE_PROPERTIES";
})(InventoryCacheActionTypes || (InventoryCacheActionTypes = {}));
export var InventoryJsonClasses;
(function (InventoryJsonClasses) {
    InventoryJsonClasses["WAREHOUSE"] = "com.sl.philly.models.Warehouse";
    InventoryJsonClasses["PALLET"] = "com.sl.philly.models.Pallet";
    InventoryJsonClasses["BOX"] = "com.sl.philly.models.Box";
})(InventoryJsonClasses || (InventoryJsonClasses = {}));
