export var InventoryContextMenuContainerActionTypes;
(function (InventoryContextMenuContainerActionTypes) {
    InventoryContextMenuContainerActionTypes["SET_CONTEXT_MENU_STATE"] = "SET_CONTEXT_MENU_STATE.inventoryContextMenuContainer.inventory.state";
    InventoryContextMenuContainerActionTypes["OPEN"] = "OPEN.inventoryContextMenuContainer.inventory.state";
    InventoryContextMenuContainerActionTypes["CLOSE"] = "CLOSE.inventoryContextMenuContainer.inventory.state";
    InventoryContextMenuContainerActionTypes["OPTION_SELECT"] = "OPTION_SELECT.inventoryContextMenuContainer.inventory.state";
})(InventoryContextMenuContainerActionTypes || (InventoryContextMenuContainerActionTypes = {}));
export var InventoryContextMenuSpecialOptions;
(function (InventoryContextMenuSpecialOptions) {
    InventoryContextMenuSpecialOptions["UNVERIFIED_QUARANTINED_IMPORT"] = "1_2_IMPORT.SCAN";
    InventoryContextMenuSpecialOptions["REMOVED_REMOVED_RETURN"] = "7_7_RETURN.SCAN";
    InventoryContextMenuSpecialOptions["REMOVED_REMOVED_EXPORT"] = "7_7_EXPORT.SCAN";
    InventoryContextMenuSpecialOptions["DAMAGED_DAMAGED_RETURN"] = "-2_-2_RETURN.SCAN";
    InventoryContextMenuSpecialOptions["DAMAGED_DAMAGED_EVALUATE"] = "-2_-2_EVALUATE.SCAN";
    InventoryContextMenuSpecialOptions["DAMAGED_REMOVED_EVALUATE"] = "-2_7_EVALUATE.SCAN";
    InventoryContextMenuSpecialOptions["RMA_RMA_RETURN"] = "-5_-5_RETURN.SCAN";
    InventoryContextMenuSpecialOptions["RMA_RMA_EXPORT"] = "-5_-5_EXPORT.SCAN";
})(InventoryContextMenuSpecialOptions || (InventoryContextMenuSpecialOptions = {}));
