import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import TextButton from "../../Button/TextButton/TextButton";
import Spacer from "../../Spacer/Spacer";
import Text from "../../Text/Text";
import TextCountdown from "../../TextCountdown/TextCountdown";
import Paragraph from "../../Paragraph/Paragraph";
import ControlledTwoFactorAuthenticationInput from "../../Input/ControlledTwoFactorAuthenticationInput/ControlledTwoFactorAuthenticationInput";
export const StyledLoginMenu2FAInput = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
`;
export const ButtonCountdownWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
const LoginMenu2FAInput = (props) => {
    const { countdownTimeSeconds = 60, errorMessage = "", message = "", authenticationCodeLength = 6, serverName = "Service Link", contactInformation, height = 300, width = 350, backgroundColor = "#F6F6F6", topSpacerHeight = 10, bottomSpacerHeight = 10, messageSpacerHeight = 14, messageHeight = 60, messageWidth = 250, messageFontSize = 12, messageFontColor = "#000000", labelHeight = 16, labelFontSize = 12, labelFontColor = "#62757F", inputWrapperHeight = 29, inputWrapperWidth = 250, inputHeight = 27, inputWidth = 27, inputBorderWidth = 1, inputBorderRadius = 5, inputBorderColor = "#757575", inputFontColor = "#000000", inputFontSize = 18, inputFontWeight = "bold", inputSpacerHeight = 10, inputSpacerWidth = 10, inputMiddleSpacerWidth = 36, inputResendButtonHeight = 16, inputResendButtonWidth = 60, inputResendButtonFontColor = "#FFFFFF", inputResendButtonFontSize = 12, inputResendButtonBackgroundColor = {
        normal: "#62757F",
        mouseOver: "#4e5d65",
        mouseDown: "#374248"
    }, inputResendButtonBorderRadius = 5, countdownHeight = 18, countdownWidth = 190, countdownFontSize = 18, errorMessageSpacerHeight = 66, errorMessageHeight = 60, errorMessageWidth = 250, errorMessageFontSize = 12, errorMessageFontColor = "#D62C1B", buttonSpacerHeight = 10, buttonHeight = 28, buttonWidth = 250, buttonFontColor = "#FFFFFF", buttonFontSize = 16, buttonBackgroundColor = {
        normal: "#62757F",
        mouseOver: "#4e5d65",
        mouseDown: "#374248"
    }, buttonBorderRadius = 5, handle2FA, handle2FAResend } = props;
    const [isCountdownComplete, setIsCountdownComplete] = useState(false);
    const [sentMessage, setSentMessage] = useState("");
    const [internalErrorMessage, setInternalErrorMessage] = useState("");
    const [authenticationCode, setAuthenticationCode] = useState("");
    const getInitialValue = () => {
        const valueArray = [];
        for (let index = 0; index < authenticationCodeLength; index++) {
            valueArray.push("");
        }
        return valueArray;
    };
    const [authenticationCodeArray, setAuthenticationCodeArray] = useState(getInitialValue());
    const displayedErrorMessage = internalErrorMessage || errorMessage;
    const displayedNonErrorMessage = message;
    const displayedMessage = displayedErrorMessage || displayedNonErrorMessage;
    const isError = !!displayedErrorMessage;
    const displayedMessageColor = isError ? errorMessageFontColor : inputFontColor;
    const on2FAButtonClick = () => {
        if (authenticationCode.length !== authenticationCodeLength) {
            setInternalErrorMessage(`Please enter all ${authenticationCodeLength} digits of the code.`);
        }
        else {
            handle2FA(authenticationCode);
            setInternalErrorMessage("");
        }
    };
    const on2FAInputSubmit = (code) => {
        on2FAButtonClick();
    };
    const on2FAInputChange = (code) => {
        setAuthenticationCodeArray(code);
        setAuthenticationCode(code.join(""));
    };
    const on2FAResendButtonClick = () => {
        setIsCountdownComplete(true);
        setAuthenticationCodeArray(getInitialValue());
        setAuthenticationCode("");
        setTimeout(() => { setIsCountdownComplete(false); }, 1500);
        handle2FAResend();
    };
    const get2FAMessage = () => {
        let messageString = "";
        messageString += `${serverName} sent a verification code to:\n\n`;
        messageString += contactInformation;
        messageString += `\n\nPlease enter the code below.`;
        return messageString;
    };
    useEffect(() => {
        setSentMessage(get2FAMessage());
    });
    return (React.createElement(StyledLoginMenu2FAInput, { className: "login-menu-two-factor-authentication-input", height: height, width: width, backgroundColor: backgroundColor },
        React.createElement(Spacer, { height: topSpacerHeight }),
        React.createElement(Paragraph, { text: sentMessage, height: messageHeight, width: messageWidth, backgroundColor: backgroundColor, fontSize: messageFontSize, fontColor: messageFontColor }),
        React.createElement(Spacer, { height: messageSpacerHeight }),
        React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWrapperWidth, text: "Verification Code", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
        React.createElement(Spacer, { height: inputSpacerHeight }),
        React.createElement(ControlledTwoFactorAuthenticationInput, { value: authenticationCodeArray, handleSubmit: on2FAInputSubmit, handleChange: on2FAInputChange, capitalize: true, numberOfInputs: authenticationCodeLength, filterRegex: /[a-zA-Z0-9]|\./, height: inputWrapperHeight, width: inputWrapperWidth, backgroundColor: backgroundColor, spacerWidth: inputSpacerWidth, middleSpacerWidth: inputMiddleSpacerWidth, inputHeight: inputHeight, inputWidth: inputWidth, inputBorderWidth: inputBorderWidth, inputBorderRadius: inputBorderRadius, inputBorderColor: inputBorderColor, inputFontColor: inputFontColor, inputFontSize: inputFontSize, inputFontWeight: inputFontWeight }),
        React.createElement(Spacer, { height: inputSpacerHeight }),
        React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWrapperWidth, text: "Time Remaining", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
        React.createElement(ButtonCountdownWrapper, { className: "button-countdown-wrapper", height: Math.max(countdownHeight, inputResendButtonHeight), width: countdownWidth + inputResendButtonWidth, backgroundColor: backgroundColor },
            React.createElement(TextCountdown, { isCountdownComplete: isCountdownComplete, countdownTimeSeconds: countdownTimeSeconds, height: countdownHeight, width: countdownWidth, fontSize: countdownFontSize, text: "", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: inputBorderColor }),
            React.createElement(TextButton, { handleClick: on2FAResendButtonClick, text: "Resend", height: inputResendButtonHeight, width: inputResendButtonWidth, color: inputResendButtonFontColor, fontWeight: "bold", backgroundColor: inputResendButtonBackgroundColor, fontSize: inputResendButtonFontSize, borderWidth: 0, borderRadius: inputResendButtonBorderRadius })),
        React.createElement(Spacer, { height: errorMessageSpacerHeight }),
        React.createElement(Paragraph, { text: displayedMessage, height: errorMessageHeight, width: errorMessageWidth, backgroundColor: backgroundColor, fontSize: errorMessageFontSize, fontColor: displayedMessageColor }),
        React.createElement(Spacer, { height: buttonSpacerHeight }),
        React.createElement(TextButton, { handleClick: on2FAButtonClick, text: "Verify", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius }),
        React.createElement(Spacer, { height: bottomSpacerHeight })));
};
export default LoginMenu2FAInput;
