import { InventoryCacheActionTypes } from "./types";
export const inventoryCacheInitialState = {
    containers: {},
    generalWarehouses: [],
    allowedWarehouses: [],
    warehouses: [],
    pallets: [],
    boxes: []
};
export function inventoryCacheReducer(state = inventoryCacheInitialState, action) {
    switch (action.type) {
        case InventoryCacheActionTypes.SET_INVENTORY_CACHE: {
            return action.inventoryCacheState;
        }
        default:
            return state;
    }
}
