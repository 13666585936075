import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
const StyledMapDragSelect = styled.div `
  position: absolute;
  top: ${props => StyleUtils.getCssPixelString(props.top)};
  left: ${props => StyleUtils.getCssPixelString(props.left)};
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  box-sizing: border-box;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid ${props => props.isCtrl ? "#efae00" : "#00aeef"};
`;
const MapDragSelect = (props) => {
    const { isActive, top, left, height, width, isCtrl, } = props;
    return (isActive ? (React.createElement(StyledMapDragSelect, { isCtrl: isCtrl, top: top, left: left, height: height, width: width })) : null);
};
export default MapDragSelect;
