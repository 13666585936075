import React from "react";
import styled from "styled-components";
import SimpleCheckbox from "../../../../../SimpleCheckbox/SimpleCheckbox";
import { FILTER_ROW_TYPE } from "../../FilterRow";
const StyledCheckBoxFilterRow = styled.div `
  height: max-content;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
class CheckBoxFilterRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleCheckboxChange = (event) => {
            let selectedValues = this.props.filterRowState.selectedValues;
            let valueToAddOrRemove = this.props.filterRowData.values[event.id].value;
            let resultArray = event.isChecked
                ? selectedValues.concat(valueToAddOrRemove)
                : selectedValues.filter(x => x !== valueToAddOrRemove);
            this.props.filterRowActions.checkBoxFilterRowCheckBoxChange({
                selectedValues: resultArray,
                index: this.props.index,
                type: FILTER_ROW_TYPE.CHECK_BOX
            });
        };
    }
    render() {
        const { filterRowData, filterRowState } = this.props;
        return (React.createElement(StyledCheckBoxFilterRow, { className: "checkbox-filter-row" }, filterRowData.values.map((value, index) => {
            const initialCheckedState = filterRowState.selectedValues.includes(value.value);
            return (React.createElement(SimpleCheckbox, { id: index, key: index, label: value.displayedText, iconName: value.iconName, onCheckboxChangeHandler: this.handleCheckboxChange, initialCheckedState: initialCheckedState }));
        })));
    }
}
export default CheckBoxFilterRow;
