import styled from "styled-components";
import Modal from "../../components/Modal/Modal";
import Spacer from "../../components/Spacer/Spacer";
import TextButton from "../../components/Button/TextButton/TextButton";
import React from "react";
const StyledModalDialogWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
const StyledModalDialogTextButtonsWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
`;
const StyledModalDialogText = styled.div `
  font-size: 14px;
  line-height: 14px;
`;
const StyledModalDialog = styled.div `
  height: 150px;
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
export const ModalDialog = (props) => {
    const { handleYes, handleNo, dialogMessage, dialogTitle, isOpen } = props;
    return (React.createElement(Modal, { open: isOpen, title: dialogTitle, dimension: { width: 300, height: 150 }, handleClose: handleNo },
        React.createElement(StyledModalDialogWrapper, null,
            React.createElement(StyledModalDialog, null,
                React.createElement(StyledModalDialogText, null, dialogMessage),
                React.createElement(Spacer, { height: 10 }),
                React.createElement(StyledModalDialogTextButtonsWrapper, null,
                    React.createElement(TextButton, { text: "Yes", color: "#FFFFFF", backgroundColor: "#3F51B5", borderRadius: 2, margin: [0, 0, 0, 100], borderWidth: 0, handleClick: handleYes }),
                    React.createElement(Spacer, { width: 5 }),
                    React.createElement(TextButton, { text: "No", color: "#FFFFFF", backgroundColor: "#3F51B5", borderRadius: 2, margin: [0, 100, 0, 0], borderWidth: 0, handleClick: handleNo }))))));
};
