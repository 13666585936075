import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../SimpleButton/SimpleButton";
const StyledChildValidatorMenuBar = styled.div `
  display: flex;
  & div {
    padding-right: 1ex;
  }
`;
export class ChildValidatorMenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        return (React.createElement(StyledChildValidatorMenuBar, null,
            React.createElement("div", null,
                React.createElement(SimpleButton, { className: "error-message-get-messages-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                        width: "10rem",
                        height: "1.2rem",
                        iconText: "Details",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.props.handleDetailsClick })),
            React.createElement("div", null,
                React.createElement(SimpleButton, { className: "error-message-get-messages-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                        width: "10rem",
                        height: "1.2rem",
                        iconText: "Modify",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.props.handleModifyClick })),
            React.createElement("div", null,
                React.createElement(SimpleButton, { className: "error-message-get-messages-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                        width: "10rem",
                        height: "1.2rem",
                        iconText: "Validate",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.props.handleValidateClick })),
            React.createElement("div", null,
                React.createElement(SimpleButton, { className: "error-message-get-messages-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                        width: "10rem",
                        height: "1.2rem",
                        iconText: "History",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.props.handleHistoryClick }))));
    }
}
