import React from "react";
import TableHeaderRowWrapper from "./TableHeaderRowWrapper/TableHeaderRowWrapper";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
const StyledTableHeaderRowScrollWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.tableHeaderRowProps.height)};
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;
class TableHeaderRowScrollWrapper extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tableHeaderRowScrollWrapper.scrollLeft = this.props.horizontalScroll;
    }
    render() {
        return (React.createElement(StyledTableHeaderRowScrollWrapper, Object.assign({ className: "table-header-row-scroll-wrapper", onScroll: this.props.handleHorizontalScroll, ref: ref => (this.tableHeaderRowScrollWrapper = ref) }, this.props),
            React.createElement(TableHeaderRowWrapper, Object.assign({}, this.props))));
    }
}
export default TableHeaderRowScrollWrapper;
