var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import DOMPurify from 'dompurify';
export const SLTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 800,
        fontSize: theme.typography.pxToRem(18),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
export const SLRawHtmlTooltip = (_a) => {
    var { html, children } = _a, rest = __rest(_a, ["html", "children"]);
    return (React.createElement(SLTooltip, Object.assign({ title: React.createElement(React.Fragment, null,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(html) } })) }, rest), children));
};
