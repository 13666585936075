import { combineReducers } from "redux-immer";
import produce from "immer";
import { testContainerReducer } from "./containers/TestContainer/reducer";
import { inventoryInitializationReducer } from "./services/InventoryInitialization/reducer";
import { inventoryTableContainerReducer } from "./containers/InventoryTableContainer/reducer";
import { inventoryScreenContainerReducer } from "./containers/InventoryScreenContainer/reducer";
import { inventoryCacheReducer } from "./services/InventoryCache/reducer";
import { inventoryFilterContainerReducer } from "./containers/InventoryFilterContainer/reducer";
import { inventoryOmniSearchContainerReducer } from "./containers/InventoryOmniSearchContainer/reducer";
import { inventoryFilterReducer } from "./services/InventoryFilter/reducer";
import { inventoryContextMenuContainerReducer } from "./containers/InventoryContextMenuContainer/reducer";
import { reducer as appReducer } from "./services/App/reducer";
import { reducer as dispatchPageContainerReducer } from "./containers/DispatchPageContainer/reducer";
import { reducer as managePageContainerReducer } from "./containers/ManagePageContainer/reducer";
import { reducer as loginPageContainerReducer } from "./containers/LoginPageContainer/reducer";
import { reducer as passwordChangePageContainerReducer } from "./containers/PasswordChangePageContainer/reducer";
import { reducer as avlPageContainerReducer } from "./containers/AVLPageContainer/reducer";
import { reducer as dragDropReducer } from "./services/DragDrop/reducer";
export const inventoryReducer = combineReducers(produce, {
    inventoryGlobalState: inventoryInitializationReducer,
    inventoryCache: inventoryCacheReducer,
    testContainer: testContainerReducer,
    inventoryTableContainer: inventoryTableContainerReducer,
    inventoryContextMenuContainer: inventoryContextMenuContainerReducer,
    inventoryScreenContainer: inventoryScreenContainerReducer,
    inventoryFilterContainer: inventoryFilterContainerReducer,
    inventoryOmniSearchContainer: inventoryOmniSearchContainerReducer,
    inventoryFilterState: inventoryFilterReducer
});
export const rootReducer = combineReducers(produce, {
    app: appReducer,
    inventory: inventoryReducer,
    avl: avlPageContainerReducer,
    dispatch: dispatchPageContainerReducer,
    manage: managePageContainerReducer,
    login: loginPageContainerReducer,
    passwordChange: passwordChangePageContainerReducer,
    dragDrop: dragDropReducer,
});
