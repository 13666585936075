import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../theme/ThemeContextProvider";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
export const StyledIcon = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => props.borderColor};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
`;
export const Path = styled.path `
  fill: ${props => props.color};
`;
const Icon = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { iconName = "", color = "#000000", backgroundColor = "#FFFFFF00", height = 16, width = 16, margin = 0, borderWidth = 0, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, } = props;
    const theme = useContext(ThemeContext).theme;
    const iconObject = theme.icon;
    const paths = ((_b = (_a = iconObject === null || iconObject === void 0 ? void 0 : iconObject.icons[iconName]) === null || _a === void 0 ? void 0 : _a.icon) === null || _b === void 0 ? void 0 : _b.paths) || [];
    const iconArray = Array.isArray(color) ? color.length === paths.length && color.length > 0 ? color : [] : [];
    const iconString = Array.isArray(color) ? null : color;
    const attrs = ((_d = (_c = iconObject === null || iconObject === void 0 ? void 0 : iconObject.icons[iconName]) === null || _c === void 0 ? void 0 : _c.icon) === null || _d === void 0 ? void 0 : _d.attrs) || [];
    return (React.createElement(StyledIcon, { className: "icon", height: height, width: width, backgroundColor: backgroundColor, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding },
        React.createElement("i", { className: "icon" },
            React.createElement("svg", { viewBox: `0 0 ${((_f = (_e = iconObject === null || iconObject === void 0 ? void 0 : iconObject.icons[iconName]) === null || _e === void 0 ? void 0 : _e.icon) === null || _f === void 0 ? void 0 : _f.width) || theme.icon.height} ${theme.icon.height}`, xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("g", null, paths.map((path, index) => {
                    const color = iconArray.length > 0 ? iconArray[index] : iconString;
                    return React.createElement(Path, Object.assign({ key: index, d: path }, attrs[index], { color: color }));
                }))))));
};
export default Icon;
