import React from "react";
import styled from "styled-components";
import MessageResenderMessageContent from "./MessageResenderMessageContent/MessageResenderMessageContent";
import MessageResenderMessages from "./MessageResenderMessages/MessageResenderMessages";
import MessageResenderOrders from "./MessageResenderOrders/MessageResenderOrders";
import MessageResenderSendButton from "./MessageResenderSendButton/MessageResenderSendButton";
import { MessageResenderSearchBar } from "./MessageResenderSearchBar/MessageResenderSearchBar";
import ModalScreen from "../ModalScreen/ModalScreen";
import MessageResenderFilter from "./MessageResenderFilter/MessageResenderFilter";
import Cookie from "universal-cookie";
const cookie = new Cookie();
const StyledMessageResenderScreen = styled.div `
  height: calc(100% - 20px);
  width: 100%;
  background-color: white;
  overflow: hidden;
`;
const StyledModalScreenContent = styled.div `
  margin: 10px;
  background-color: white;
  height: 100%;
  width: 100%;
`;
const StyledModalScreenContentText = styled.div `
  white-space: pre-wrap;
  color: red;
`;
const functionFilters = [
    "ORDER_CREATE", "ORDER_MODIFY", "COMPLETE", "ENROUTE", "DISPATCH",
    "ONSITE", "RELEASE", "REASSIGNED", "ACKNOWLEDGE", "SUSPEND", "RETURN",
    "PARK", "UNPARK", "CANCEL"
];
class MessageResenderScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleOrderRowClick = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedOrderNumber: event.orderNumber, BasicServerQueueMessages: [], BasicLocalQueueMessages: [], filteredMessages: [] })), () => {
                this.getOrderMessages(this.state.selectedOrderNumber.split(","));
            });
        };
        this.handleMessageRowClick = (event) => {
            this.getOrderMessagesDetail(event.messageId);
        };
        this.handleCheckboxChange = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { BasicLocalQueueMessages: prevState.BasicLocalQueueMessages.map(message => {
                    if (message.id === event.id) {
                        return Object.assign(Object.assign({}, message), { resend: event.isChecked });
                    }
                    else {
                        return message;
                    }
                }), filteredMessages: prevState.filteredMessages.map(message => {
                    if (message.id === event.id) {
                        return Object.assign(Object.assign({}, message), { resend: event.isChecked });
                    }
                    else {
                        return message;
                    }
                }) })));
        };
        this.handleFilterCheckboxChange = (filt) => {
            if (!this.state.selectedFilters.includes(filt)) {
                this.checkBox(filt);
            }
            else {
                this.uncheckBox(filt);
            }
        };
        this.checkBox = (filt) => {
            this.setState(prevState => ({
                selectedFilters: [...prevState.selectedFilters, filt]
            }), () => {
                this.filterMessages();
            });
        };
        this.uncheckBox = (filt) => {
            this.setState(prevState => ({
                selectedFilters: prevState.selectedFilters.filter(filter => {
                    return filter !== filt;
                }),
            }), () => {
                this.filterMessages();
            });
        };
        this.filterMessages = () => {
            this.setState(prevState => ({
                filteredMessages: prevState.selectedFilters.length !== 0 ? prevState.BasicLocalQueueMessages.filter(message => {
                    return this.state.selectedFilters.includes(message.function);
                }) : prevState.BasicLocalQueueMessages
            }));
        };
        this.handlePayloadChange = (payload) => {
            this.setState(prevState => ({
                LocalQueueMessage: Object.assign(Object.assign({}, prevState.LocalQueueMessage), { payload: payload })
            }));
        };
        this.handleSendMessage = () => {
            let resendMessages = this.state.BasicLocalQueueMessages.filter(m => {
                if (m.resend == true)
                    return m;
            });
            let promises = [];
            resendMessages.map(message => {
                let req = fetch(`/sl3/rest/sent_message_management/resend_message/${message.id}`, {
                    headers: {
                        "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
                    },
                });
                promises.push(req);
            });
            Promise.all(promises)
                .then(res => Promise.all(res.map(data => data.json())))
                .then(res => {
                const failed = (response) => response.success === false;
                if (res.some(failed)) {
                    alert("Failed to resend message(s)");
                }
            });
            alert("Resend message request sent to server.");
        };
        this.handleSearchStringsChange = (orderNumber, startDate, endDate) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { searchOrderNumber: orderNumber || "", searchStartDate: new Date(startDate) || new Date(), searchEndDate: new Date(endDate) || new Date() })));
        };
        this.handleSearchButtonClick = () => {
            if (!this.validateSearchDate())
                return;
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderNumbers: [], ServerQueueMessage: null, LocalQueueMessage: null })), () => {
                if (this.state.searchOrderNumber === "") {
                    this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isModalScreenVisible: true, modalScreenMessage: "Order number cannot be blank." })));
                    // this.getDateMessages();
                }
                else if (this.state.searchOrderNumber !== "") {
                    this.getOrderMessages(this.state.searchOrderNumber.split(","));
                }
            });
        };
        this.validateSearchDate = () => {
            let isSearchValid = true;
            let errorMessage = "Unable to retrieve messages. ";
            if (this.state.searchStartDate > new Date()) {
                isSearchValid = false;
                errorMessage += "Start time can not be in the future. ";
            }
            if (this.state.searchEndDate > new Date()) {
                isSearchValid = false;
                errorMessage += "End time can not be in the future. ";
            }
            if (this.state.searchStartDate > this.state.searchEndDate) {
                isSearchValid = false;
                errorMessage += "Start time can not be later than end time. ";
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isModalScreenVisible: !isSearchValid, modalScreenMessage: errorMessage })));
            return isSearchValid;
        };
        this.getDateMessages = () => {
            fetch(`/sl3/rest/sent_message_management/?startTime=${this.state.searchStartDate.toISOString()}&endTime=${this.state.searchEndDate.toISOString()}`, {
                headers: {
                    "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
                },
            })
                .then(res => res.json())
                .then((ServerQueueMessages) => {
                const queueMessages = Array.from(ServerQueueMessages);
                let orderNumbers = new Set(queueMessages.map(m => m.order_number));
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderNumbers: Array.from(orderNumbers), ServerQueueMessages: queueMessages, LocalQueueMessages: queueMessages.map(m => (Object.assign(Object.assign({}, m), { resend: false }))) })));
            })
                .catch(console.log);
        };
        this.getOrderMessages = (orderNumber) => {
            // Would format the array so the backend parses it as a list
            // Turns the array from ['SL0', 'SL1'] to orderNumbers=SL0&orderNumbers=SL1
            fetch(`/sl3/rest/sent_message_management/by_order_number?startTime=${this.state.searchStartDate.toISOString()}&endTime=${this.state.searchEndDate.toISOString()}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
                },
                body: JSON.stringify(orderNumber)
            })
                .then(res => res.json())
                .then((ServerQueueMessages) => {
                const queueMessages = Array.from(ServerQueueMessages);
                let orderNumbers = new Set(queueMessages.map(m => m.order_number));
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { orderNumbers: Array.from(orderNumbers), BasicServerQueueMessages: queueMessages, BasicLocalQueueMessages: queueMessages.map(m => (Object.assign(Object.assign({}, m), { resend: false }))) })), () => {
                    this.setState(prevState => ({
                        filteredMessages: prevState.BasicLocalQueueMessages
                    }));
                });
            })
                .catch(console.log);
        };
        this.getOrderMessagesDetail = (id) => {
            fetch(`/sl3/rest/sent_message_management/${id}`, {
                headers: {
                    "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
                },
            })
                .then(res => res.json())
                .then((ServerQueueMessage) => {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { ServerQueueMessage: ServerQueueMessage, LocalQueueMessage: Object.assign(Object.assign({}, ServerQueueMessage), { resend: false }) })));
            })
                .catch(console.log);
        };
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 90);
        this.state = {
            selectedOrderNumber: "",
            orderNumbers: [],
            BasicServerQueueMessages: [],
            BasicLocalQueueMessages: [],
            ServerQueueMessage: null,
            LocalQueueMessage: null,
            searchOrderNumber: "",
            searchStartDate: startDate,
            searchEndDate: endDate,
            isModalScreenVisible: false,
            modalScreenTitle: "",
            modalScreenMessage: "",
            selectedFilters: [],
            filteredMessages: []
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderScreen, { className: "message-resender-screen-container" },
            React.createElement(MessageResenderSearchBar, { handleSearchStringsChange: this.handleSearchStringsChange, handleSearchButtonClick: this.handleSearchButtonClick, startDate: this.state.searchStartDate, endDate: this.state.searchEndDate }),
            React.createElement(MessageResenderSendButton, { handleSendMessage: this.handleSendMessage }),
            React.createElement(MessageResenderFilter, { filters: functionFilters, handleCheckboxChange: this.handleFilterCheckboxChange }),
            React.createElement(MessageResenderOrders, { orderNumber: this.state.orderNumbers, handleOrderRowClick: this.handleOrderRowClick }),
            React.createElement(MessageResenderMessages, { localQueueMessages: this.state.filteredMessages, handleMessageRowClick: this.handleMessageRowClick, handleCheckboxChange: this.handleCheckboxChange }),
            React.createElement(MessageResenderMessageContent, { LocalQueueMessage: this.state.LocalQueueMessage, handlePayloadChange: this.handlePayloadChange }),
            React.createElement(ModalScreen, { isVisible: this.state.isModalScreenVisible, minDistanceFromEdge: 500, maxWidth: 500, maxHeight: 200, titleText: "", isBlocking: false, onDismiss: () => this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isModalScreenVisible: false, modalScreenMessage: "" }))) },
                React.createElement(StyledModalScreenContent, { className: "modal-screen-content" },
                    React.createElement(StyledModalScreenContentText, { className: "modal-screen-content-text" }, this.state.modalScreenMessage)))));
    }
    componentDidMount() {
    }
}
export default MessageResenderScreen;
