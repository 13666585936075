export var InventoryFilterContainerActionTypes;
(function (InventoryFilterContainerActionTypes) {
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_MUTATE"] = "INVENTORY_FILTER_MUTATE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_SAVE_CONFIGURATION_REQUEST"] = "INVENTORY_FILTER_SAVE_CONFIGURATION_REQUEST";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_SAVE_CONFIGURATION_RESPONSE"] = "INVENTORY_FILTER_SAVE_CONFIGURATION_RESPONSE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_SAVE_CONFIGURATION_FAILURE"] = "INVENTORY_FILTER_SAVE_CONFIGURATION_FAILURE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_DELETE_CONFIGURATION_REQUEST"] = "INVENTORY_FILTER_DELETE_CONFIGURATION_REQUEST";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_DELETE_CONFIGURATION_RESPONSE"] = "INVENTORY_FILTER_DELETE_CONFIGURATION_RESPONSE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_GET_CONFIGURATION"] = "INVENTORY_FILTER_GET_CONFIGURATION";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_GET_CONFIGURATION_RESPONSE"] = "INVENTORY_FILTER_GET_CONFIGURATION_RESPONSE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_DELETE_ROW"] = "INVENTORY_FILTER_DELETE_ROW";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_STATE_UPDATE"] = "INVENTORY_FILTER_UPDATE";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_DELETE_CONFIGURATION"] = "INVENTORY_FILTER_DELETE_CONFIGURATION";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_RESET_CONFIGURATION"] = "INVENTORY_FILTER_RESET_CONFIGURATION";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_CHANGE_WORKING_SET"] = "INVENTORY_FILTER_CHANGE_WORKING_SET";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_TOGGLE_EXPAND"] = "INVENTORY_FILTER_TOGGLE_EXPAND";
    InventoryFilterContainerActionTypes["INVENTORY_FILTER_FIELDS_CHANGED"] = "INVENTORY_FILTER_FIELDS_CHANGED";
})(InventoryFilterContainerActionTypes || (InventoryFilterContainerActionTypes = {}));
