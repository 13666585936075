import React from "react";
import { getTheme } from "office-ui-fabric-react";
import { Dropdown as FabricUiDropdown } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleIcon from "../SimpleIcon/SimpleIcon";
const StyledDropdownWrapper = styled.div `
  height: 2rem;
  width: 15rem;
  background-color: ${props => props.backgroundColor || props.palette.white};
`;
const StyledLabel = styled.div ``;
const StyledTitle = styled.div `
  height: 1.4rem;
  width: 100%;
  color: ${props => props.color || props.palette.black};
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 0.2rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledPlaceholder = styled.div `
  height: 1.4rem;
  width: 100%;
  color: ${props => props.color || props.palette.neutralSecondary};
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 0.2rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SimpleIconWrapper = styled.div `
  height: 1.2rem;
  width: 1.2rem;
  padding: 0.3rem;
`;
class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.defaultPlaceholder = "Select options";
        this.defaultMaximumRowsBeforeOverflow = 10;
        this.documentResizeHandler = () => {
            this.forceUpdate();
        };
        this.getStyles = () => {
            const { options, color, backgroundColor, headerColor, headerBackgroundColor, highlightColor, highlightBackgroundColor, disabledColor, disabledBackgroundColor, dividerColor, maximumRowsBeforeOverflow } = this.props;
            const count = options.length;
            const windowWidth = window.innerWidth;
            const isPanel = windowWidth < 640; //Microsoft magic width number to switch to mobile panel.
            const root = {
                height: "2rem",
                width: "15rem",
                fontSize: "1rem",
                backgroundColor: backgroundColor || this.theme.palette.white
            };
            const dropdown = {
                height: "2rem",
                width: "15rem"
            };
            const title = {
                height: "2rem",
                width: "15rem"
            };
            const caretDownWrapper = {
                height: "1.8rem",
                width: "1.8rem",
                lineHeight: "unset"
            };
            const errorMessage = {};
            const dropdownItemsWrapper = {
                height: `${Math.min(count, maximumRowsBeforeOverflow || this.defaultMaximumRowsBeforeOverflow) * 2}rem`,
                width: isPanel ? "100%" : "15rem"
            };
            const dropdownItems = {
                height: "100%",
                width: isPanel ? "100%" : "15rem",
                padding: 0,
                margin: 0
            };
            const dropdownItem = {
                height: "2rem",
                width: isPanel
                    ? "100%"
                    : count > maximumRowsBeforeOverflow || this.defaultMaximumRowsBeforeOverflow
                        ? "calc(100% - 17px)"
                        : "100%",
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                margin: 0,
                borderWidth: 0,
                color: color || this.theme.palette.black,
                backgroundColor: backgroundColor || this.theme.palette.white
            };
            const dropdownItemSelected = Object.assign(Object.assign({}, dropdownItem), { color: highlightColor || this.theme.palette.black, backgroundColor: highlightBackgroundColor || this.theme.palette.themeLight });
            const dropdownItemDisabled = Object.assign(Object.assign({}, dropdownItem), { color: disabledColor || this.theme.palette.neutralSecondary, backgroundColor: disabledBackgroundColor || this.theme.palette.neutralLight });
            const dropdownItemSelectedAndDisabled = Object.assign(Object.assign({}, dropdownItem), { color: disabledColor || this.theme.palette.neutralSecondary, backgroundColor: disabledBackgroundColor || this.theme.palette.neutralLight });
            const dropdownItemHeader = Object.assign(Object.assign({}, dropdownItem), { color: headerColor || this.theme.palette.themePrimary, backgroundColor: headerBackgroundColor || this.theme.palette.white });
            const dropdownDivider = Object.assign(Object.assign({}, dropdownItem), { height: "0.2rem", backgroundColor: dividerColor || this.theme.palette.black });
            const dropdownOptionText = {
                textAlign: "left",
                height: "1.4rem",
                fontSize: "1.4rem",
                lineHeight: "1.4rem",
                width: isPanel ? "calc(100% - 1rem)" : "14rem",
                padding: "0.3rem 0.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: 0
            };
            return {
                root: root,
                dropdown: dropdown,
                title: title,
                dropdownItems: dropdownItems,
                dropdownItemSelected: dropdownItemSelected,
                dropdownItemDisabled: dropdownItemDisabled,
                dropdownItemSelectedAndDisabled: dropdownItemSelectedAndDisabled,
                caretDownWrapper: caretDownWrapper,
                errorMessage: errorMessage,
                dropdownItemHeader: dropdownItemHeader,
                dropdownItemsWrapper: dropdownItemsWrapper,
                dropdownItem: dropdownItem,
                dropdownOptionText: dropdownOptionText,
                dropdownDivider: dropdownDivider
            };
        };
        this.renderLabel = () => {
            if (this.props.renderLabel) {
                return this.props.renderLabel();
            }
            if (this.props.label) {
                return this.renderDefaultLabel(this.props.label);
            }
        };
        this.renderDefaultLabel = (label) => {
            return (React.createElement(StyledLabel, Object.assign({ className: "label" }, this.props, this.theme), label));
        };
        this.renderPlaceholder = () => {
            if (this.props.renderPlaceholder) {
                return this.props.renderPlaceholder();
            }
            else {
                return this.renderDefaultPlaceholder();
            }
        };
        this.renderDefaultPlaceholder = () => {
            return (React.createElement(StyledPlaceholder, Object.assign({ className: "placeholder" }, this.props, this.theme), this.getPlaceholderText()));
        };
        this.getPlaceholderText = () => {
            const { selectedKey, options } = this.props;
            const selectedElement = selectedKey
                ? options.find(option => option.key === selectedKey)
                : undefined;
            return selectedElement ? selectedElement.text : this.props.placeholder || this.defaultPlaceholder;
        };
        this.renderTitle = () => {
            if (this.props.renderTitle) {
                return this.props.renderTitle();
            }
            else {
                return this.renderDefaultTitle();
            }
        };
        this.renderDefaultTitle = () => {
            return (React.createElement(StyledTitle, Object.assign({ className: "title" }, this.props, this.theme), this.state.selectedText || this.getPlaceholderText()));
        };
        this.renderCaretDown = () => {
            if (this.props.renderCaretDown) {
                return this.props.renderCaretDown();
            }
            else {
                return this.renderDefaultCaretDown();
            }
        };
        this.renderDefaultCaretDown = () => {
            return (React.createElement(SimpleIconWrapper, Object.assign({ className: "dropdown-caret-down-wrapper" }, this.props, this.theme),
                React.createElement(SimpleIcon, { iconName: "SL-Arrow-Down", color: this.props.color || this.theme.palette.black, size: "1.2rem", offsetY: "-0.4rem" })));
        };
        this.handleChange = (event, option, index) => {
            const newText = option.text;
            if (this.props.handleChange) {
                this.props.handleChange(option);
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedText: newText })));
        };
        this.state = {
            selectedText: ""
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.documentResizeHandler);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.documentResizeHandler);
    }
    render() {
        const { options, placeholder, disabled, useFabricUIDefaultStyles, handleDismiss, multiSelect, multiSelectDefaultKeys, selectedKey } = this.props;
        return (React.createElement(StyledDropdownWrapper, Object.assign({ className: "dropdown-wrapper" }, this.props, this.theme),
            React.createElement(FabricUiDropdown, { options: options, selectedKey: selectedKey, styles: useFabricUIDefaultStyles ? {} : this.getStyles(), placeholder: this.getPlaceholderText(), disabled: disabled, onChange: this.handleChange, onDismiss: handleDismiss, onRenderLabel: this.renderLabel, onRenderPlaceholder: this.renderPlaceholder, onRenderTitle: this.renderTitle, onRenderCaretDown: this.renderCaretDown, multiSelect: multiSelect, defaultSelectedKeys: multiSelectDefaultKeys, theme: this.theme })));
    }
}
export default Dropdown;
