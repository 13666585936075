import { call, put, select, takeEvery } from "redux-saga/effects";
import { InventoryContextMenuContainerActionTypes, InventoryContextMenuSpecialOptions } from "./types";
import { InventoryTableContainerActionTypes } from "../InventoryTableContainer/types";
import { TableUtils } from "../../../helper/General/TableUtils";
import { InventoryHistoryDataType } from "../../../display/components/Screen/Inventory/InventoryHistoryScreen/InventoryHistoryScreen";
import { MAX_RESULT_PER_REQUEST } from "../../../helper/WebSocket/WebSocketService";
import { InventoryState } from "../../api/WebSockets/Inventory/State";
import { InventoryScreenContainerActionTypes } from "../InventoryScreenContainer/types";
import { ScreenUtils } from "../../../helper/General/ScreenUtils";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { InventoryRead } from "../../api/WebSockets/Inventory/Read";
import { InventorySchemaService } from "../../../helper/InventorySchema/InventorySchemaService";
import { Fsrs } from "../../api/REST/Fsrs/Fsrs";
import { InventoryOwner } from "../../api/WebSockets/Inventory/Owner";
import { Utils } from "../../../helper/General/Utils";
import { InventoryUtils } from "../../api/WebSockets/Inventory/Utils";
import { InventoryLinkHistory } from "../../api/WebSockets/Inventory/LinkHistory";
var CONTEXT_MENU_SHARED_ACTIONS = TableUtils.CONTEXT_MENU_SHARED_ACTIONS;
var SCREEN_TYPES = ScreenUtils.SCREEN_TYPES;
var INVENTORY_SCREEN_NAMES = ScreenUtils.INVENTORY_SCREEN_NAMES;
var J_FILTER_JOIN_OPERATORS = FilterUtils.J_FILTER_JOIN_OPERATORS;
var CONTEXT_MENU_FILE_ACTIONS = TableUtils.CONTEXT_MENU_FILE_ACTIONS;
export const inventoryContextMenuContainerSagas = {
    openSaga: takeEvery(InventoryContextMenuContainerActionTypes.OPEN, openSaga),
    closeSaga: takeEvery(InventoryContextMenuContainerActionTypes.CLOSE, closeSaga),
    optionSelectSaga: takeEvery(InventoryContextMenuContainerActionTypes.OPTION_SELECT, optionSelectSaga)
};
export function* openSaga(action) {
    const contextMenuState = yield select(getContextMenuContainerStateSelector);
    yield put({
        type: InventoryContextMenuContainerActionTypes.SET_CONTEXT_MENU_STATE,
        inventoryContextMenuContainerState: Object.assign(Object.assign({}, contextMenuState), { isVisible: true, xPosition: action.xPosition, yPosition: action.yPosition, data: action.data, previousStates: action.previousStates })
    });
}
export function* closeSaga(action) {
    const contextMenuState = yield select(getContextMenuContainerStateSelector);
    yield put({
        type: InventoryContextMenuContainerActionTypes.SET_CONTEXT_MENU_STATE,
        inventoryContextMenuContainerState: Object.assign(Object.assign({}, contextMenuState), { isVisible: false, xPosition: 0, yPosition: 0, data: [], previousStates: {} })
    });
}
export function* optionSelectSaga(action) {
    const globalState = yield select(getGlobalStateSelector);
    const contextMenuState = yield select(getContextMenuContainerStateSelector);
    const inventoryCache = yield select(getCacheStateSelector);
    const data = action.data;
    const newState = typeof data.identifier === "number" ? data.identifier : parseInt(data.identifier.split("_")[1]);
    const previousStates = contextMenuState.previousStates;
    const previousStateIds = Object.keys(previousStates).map(key => parseInt(key));
    const allItemIds = Object.entries(previousStates).reduce((accumulator, item) => {
        const [state, itemIds] = item;
        return [...accumulator, ...itemIds];
    }, []);
    const sortedAllItemIds = allItemIds.sort((a, b) => a - b);
    const searchFieldFilters = allItemIds.map(id => {
        return {
            field: "Id",
            operator: FilterUtils.J_FILTER_OPERATORS.equal,
            value: id.toString()
        };
    });
    const filterObject = FilterUtils.filterToJFilter(searchFieldFilters, searchFieldFilters.length > 1 ? J_FILTER_JOIN_OPERATORS.or : J_FILTER_JOIN_OPERATORS.and);
    const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, 0, MAX_RESULT_PER_REQUEST, [], filterObject);
    const inventoryServerItemList = inventoriesQueryResponse.response || [];
    const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
    const userName = Utils.getCookieByName("UNAME");
    yield put({
        type: InventoryContextMenuContainerActionTypes.CLOSE
    });
    switch (data.identifier) {
        case CONTEXT_MENU_SHARED_ACTIONS.HISTORY.identifier: {
            const inventoriesStateHistoryData = yield call(getStateHistoryScreenData, sortedAllItemIds);
            const inventoriesOwnerHistoryData = yield call(getOwnerHistoryScreenData, sortedAllItemIds);
            const inventoriesLinkingHistoryData = yield call(getContainerHistoryScreenData, sortedAllItemIds, inventoryCache);
            const combinedInventoriesHistoryData = inventoriesStateHistoryData.map(stateDataList => {
                const ownerHistoryData = inventoriesOwnerHistoryData.find(ownerDataList => ownerDataList.id === stateDataList.id);
                const ownerDataList = ownerHistoryData
                    ? ownerHistoryData.inventoryHistoryDataList || []
                    : [];
                const linkingHistoryData = inventoriesLinkingHistoryData.find(linkingDataList => linkingDataList.id === stateDataList.id);
                const linkingDataList = linkingHistoryData
                    ? linkingHistoryData.inventoryHistoryDataList || []
                    : [];
                const combinedDataList = [
                    ...stateDataList.inventoryHistoryDataList,
                    ...ownerDataList,
                    ...linkingDataList
                ];
                const sortedCombinedDataList = combinedDataList.sort((a, b) => {
                    const aTime = a.type === InventoryHistoryDataType.STATE ? a.transitionTime : a.timestamp;
                    const bTime = b.type === InventoryHistoryDataType.STATE ? b.transitionTime : b.timestamp;
                    return bTime - aTime;
                });
                return Object.assign(Object.assign({}, stateDataList), { inventoryHistoryDataList: sortedCombinedDataList });
            });
            const fsrLookupObjects = yield call(getFsrLookupObjects, combinedInventoriesHistoryData);
            const combinedInventoriesHistoryDataWithNames = combinedInventoriesHistoryData.map(dataList => {
                const matchingItem = inventoryItemList.find(item => item.uniqueId.toString() === dataList.displayedText);
                const displayedText = matchingItem ? matchingItem.id : "";
                return Object.assign(Object.assign({}, dataList), { displayedText: displayedText, inventoryHistoryDataList: dataList.inventoryHistoryDataList.map(data => {
                        switch (data.type) {
                            case InventoryHistoryDataType.STATE:
                                const fsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.ownerId);
                                if (fsrLookupObject) {
                                    return Object.assign(Object.assign({}, data), { ownerName: fsrLookupObject.name });
                                }
                                else {
                                    return data;
                                }
                            case InventoryHistoryDataType.OWNER:
                                const oldFsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.oldOwner.split("*")[1]);
                                const newFsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.newOwner.split("*")[1]);
                                let newData = data;
                                if (oldFsrLookupObject) {
                                    newData = Object.assign(Object.assign({}, newData), { oldOwnerName: oldFsrLookupObject.name });
                                }
                                if (newFsrLookupObject) {
                                    newData = Object.assign(Object.assign({}, newData), { newOwnerName: newFsrLookupObject.name });
                                }
                                return newData;
                            case InventoryHistoryDataType.LINKING: {
                                return data;
                            }
                        }
                    }) });
            });
            yield put({
                type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                titleText: "Inventory History Screen",
                screenType: SCREEN_TYPES.INVENTORY,
                screenName: INVENTORY_SCREEN_NAMES.HISTORY,
                screenData: {
                    inventoriesHistoryDataLists: combinedInventoriesHistoryDataWithNames
                }
            });
            break;
        }
        case CONTEXT_MENU_SHARED_ACTIONS.MODIFY_OWNER.identifier: {
            const ownerTypes = ["user", "nobody"];
            const fsrsResponse = yield call(Fsrs.fsrsRequest);
            const fsrs = fsrsResponse.response || [];
            const inventoryModifyOwnerScreenData = {
                selectedItems: inventoryItemList,
                inventoryOwnerTypes: ownerTypes,
                fsrs: fsrs
            };
            yield put({
                type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                titleText: "Inventory Modify Owner Screen",
                screenType: SCREEN_TYPES.INVENTORY,
                screenName: INVENTORY_SCREEN_NAMES.MODIFY_OWNER,
                screenData: inventoryModifyOwnerScreenData
            });
            break;
        }
        case CONTEXT_MENU_SHARED_ACTIONS.MODIFY_PARENT_CONTAINER.identifier: {
            const inventoryModifyParentContainerScreenData = {
                selectedItems: inventoryItemList,
                pallets: inventoryCache.pallets,
                boxes: inventoryCache.boxes
            };
            yield put({
                type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                titleText: "Inventory Modify Container Screen",
                screenType: SCREEN_TYPES.INVENTORY,
                screenName: INVENTORY_SCREEN_NAMES.MODIFY_PARENT_CONTAINER,
                screenData: inventoryModifyParentContainerScreenData
            });
            break;
        }
        case CONTEXT_MENU_FILE_ACTIONS.DOWNLOAD.identifier: {
            yield put({
                type: InventoryTableContainerActionTypes.TABLE_TO_CSV
            });
            break;
        }
        case CONTEXT_MENU_FILE_ACTIONS.UPLOAD.identifier: {
            yield put({
                type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                titleText: "Inventory Upload Screen",
                screenType: SCREEN_TYPES.INVENTORY,
                screenName: INVENTORY_SCREEN_NAMES.UPLOAD,
                screenData: {}
            });
            break;
        }
        case CONTEXT_MENU_FILE_ACTIONS.DOWNLOAD_HISTORY.identifier: {
            yield put({
                type: InventoryTableContainerActionTypes.HISTORICAL_DATA_TO_CSV
            });
            break;
        }
        case InventoryContextMenuSpecialOptions.UNVERIFIED_QUARANTINED_IMPORT: {
            const previousUnverifiedIds = previousStates[1] || [];
            const allOtherIds = allItemIds.filter(id => !previousUnverifiedIds.includes(id));
            if (allOtherIds.length > 0) {
                yield put({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: newState,
                    itemIds: allOtherIds
                });
            }
            if (previousUnverifiedIds.length > 0) {
                const previousUnverifiedItemList = inventoryItemList.filter(item => previousUnverifiedIds.includes(item.uniqueId));
                yield put({
                    type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                    titleText: "Inventory Unverified to Quarantined Screen",
                    screenType: SCREEN_TYPES.INVENTORY,
                    screenName: INVENTORY_SCREEN_NAMES.UNVERIFIED_TO_QUARANTINED,
                    screenData: {
                        selectedItems: previousUnverifiedItemList,
                        warehouses: inventoryCache.warehouses.filter(warehouse => warehouse.id !== "UnionTown")
                    }
                });
            }
            break;
        }
        case InventoryContextMenuSpecialOptions.DAMAGED_REMOVED_EVALUATE: {
            const previousDamagedIds = previousStates[-2] || [];
            const allOtherIds = allItemIds.filter(id => !previousDamagedIds.includes(id));
            if (allOtherIds.length > 0) {
                yield put({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: newState,
                    itemIds: allOtherIds
                });
            }
            if (previousDamagedIds.length > 0) {
                const previousDamagedItemList = inventoryItemList.filter(item => previousDamagedIds.includes(item.uniqueId));
                yield put({
                    type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                    titleText: "Inventory Damaged to Removed Screen",
                    screenType: SCREEN_TYPES.INVENTORY,
                    screenName: INVENTORY_SCREEN_NAMES.DAMAGED_TO_REMOVED,
                    screenData: {
                        selectedItems: previousDamagedItemList,
                        warehouses: inventoryCache.warehouses.filter(warehouse => warehouse.id === "UnionTown")
                    }
                });
            }
            break;
        }
        case InventoryContextMenuSpecialOptions.REMOVED_REMOVED_RETURN:
        case InventoryContextMenuSpecialOptions.DAMAGED_DAMAGED_RETURN:
        case InventoryContextMenuSpecialOptions.RMA_RMA_RETURN: {
            if (allItemIds.length > 0) {
                const titleText = `Inventory ${Utils.snakeCaseToReadable(data.identifier.split("_")[2].replace(".", "_"))} Scan Screen`;
                yield put({
                    type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                    titleText: titleText,
                    screenType: SCREEN_TYPES.INVENTORY,
                    screenName: INVENTORY_SCREEN_NAMES.MODIFY_CONTAINER_SCAN,
                    screenData: {
                        selectedItems: inventoryItemList,
                        warehouses: inventoryCache.warehouses.filter(warehouse => warehouse.id !== "UnionTown")
                    }
                });
            }
            break;
        }
        case InventoryContextMenuSpecialOptions.REMOVED_REMOVED_EXPORT:
        case InventoryContextMenuSpecialOptions.DAMAGED_DAMAGED_EVALUATE:
        case InventoryContextMenuSpecialOptions.RMA_RMA_EXPORT: {
            if (allItemIds.length > 0) {
                const titleText = `Inventory ${Utils.snakeCaseToReadable(data.identifier.split("_")[2].replace(".", "_"))} Scan Screen`;
                yield put({
                    type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                    titleText: titleText,
                    screenType: SCREEN_TYPES.INVENTORY,
                    screenName: INVENTORY_SCREEN_NAMES.MODIFY_CONTAINER_SCAN,
                    screenData: {
                        selectedItems: inventoryItemList,
                        warehouses: inventoryCache.warehouses.filter(warehouse => warehouse.id === "UnionTown")
                    }
                });
            }
            break;
        }
        case -3: {
            const previousInTestIds = previousStates[3] || [];
            const allOtherIds = allItemIds.filter(id => !previousInTestIds.includes(id));
            if (allOtherIds.length > 0) {
                yield put({
                    type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                    newState: newState,
                    itemIds: allOtherIds
                });
            }
            if (previousInTestIds.length > 0) {
                const previousInTestItemList = inventoryItemList.filter(item => previousInTestIds.includes(item.uniqueId));
                yield put({
                    type: InventoryScreenContainerActionTypes.OPEN_SCREEN,
                    titleText: "Inventory In Test to Unavailable Screen",
                    screenType: SCREEN_TYPES.INVENTORY,
                    screenName: INVENTORY_SCREEN_NAMES.IN_TEST_TO_UNAVAILABLE,
                    screenData: {
                        selectedItems: previousInTestItemList
                    }
                });
            }
            break;
        }
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case -1:
        case -2:
        case -4:
        case -5:
        case -6: {
            yield put({
                type: InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE,
                newState: newState,
                itemIds: allItemIds
            });
            return;
        }
        default: {
            console.warn(`State: ${newState} is not handled.`);
        }
    }
}
export function getStateHistoryScreenData(ids) {
    return new Promise((resolve, reject) => {
        const allPromises = ids.map(id => InventoryState.inventoryHistoryRequestById(id));
        Promise.all(allPromises).then(responses => {
            const inventoryHistoryObjectsList = responses.map(response => response.response);
            let index = 0;
            const inventoryHistoryDataLists = inventoryHistoryObjectsList.map(objects => {
                const inventoryHistoryDataList = [];
                if (!!objects && objects.length > 0) {
                    objects.forEach(object => {
                        inventoryHistoryDataList.push({
                            type: InventoryHistoryDataType.STATE,
                            id: object.entity.id,
                            ownerId: object.ownerData.ownerId,
                            ownerName: object.ownerData.ownerId,
                            ownerType: object.ownerData.ownerType,
                            stateId: object.state.id,
                            stateName: object.state.stateName,
                            transitionTime: object.transitionTime,
                            comments: object.comment
                        });
                    });
                }
                const displayedText = ids[index] ? ids[index].toString() : "";
                const objectId = ids[index];
                index++;
                return {
                    id: objectId,
                    displayedText: displayedText,
                    inventoryHistoryDataList: inventoryHistoryDataList
                };
            });
            resolve(inventoryHistoryDataLists);
        });
    });
}
export function getOwnerHistoryScreenData(ids) {
    return new Promise((resolve, reject) => {
        const filters = ids.map(id => {
            return [
                {
                    field: "id",
                    value: id.toString(),
                    operator: FilterUtils.J_FILTER_OPERATORS.equal
                }
            ];
        });
        const queries = filters.map(filter => FilterUtils.filterToJFilter(filter, J_FILTER_JOIN_OPERATORS.and));
        const allPromises = queries.map(query => InventoryOwner.inventoryHistoryQueryRequest(0, MAX_RESULT_PER_REQUEST, [], query));
        Promise.all(allPromises).then(responses => {
            const inventoryHistoryObjectsList = responses.map(response => response.response);
            let index = 0;
            const inventoryHistoryDataLists = inventoryHistoryObjectsList.map(objects => {
                const inventoryHistoryDataList = [];
                if (!!objects && objects.length > 0) {
                    objects.forEach(object => {
                        inventoryHistoryDataList.push({
                            type: InventoryHistoryDataType.OWNER,
                            oldOwnerName: object.oldOwner,
                            oldOwner: object.oldOwner,
                            newOwnerName: object.newOwner,
                            newOwner: object.newOwner,
                            timestamp: object.timestamp
                        });
                    });
                }
                const displayedText = ids[index] ? ids[index].toString() : "";
                const objectId = ids[index];
                index++;
                return {
                    id: objectId,
                    displayedText: displayedText,
                    inventoryHistoryDataList: inventoryHistoryDataList
                };
            });
            resolve(inventoryHistoryDataLists);
        });
    });
}
export function getContainerHistoryScreenData(ids, inventoryCache) {
    return new Promise((resolve, reject) => {
        const filters = ids.map(id => {
            return [
                {
                    field: "id",
                    value: id.toString(),
                    operator: FilterUtils.J_FILTER_OPERATORS.equal
                }
            ];
        });
        const queries = filters.map(filter => FilterUtils.filterToJFilter(filter, J_FILTER_JOIN_OPERATORS.and));
        const allPromises = queries.map(query => InventoryLinkHistory.inventoryQueryRequest(0, MAX_RESULT_PER_REQUEST, [], query));
        Promise.all(allPromises).then(responses => {
            const containerObject = inventoryCache.containers;
            const inventoryHistoryObjectsList = responses.map(response => response.response);
            let index = 0;
            const inventoryHistoryDataLists = inventoryHistoryObjectsList.map(objects => {
                const inventoryHistoryDataList = [];
                if (!!objects && objects.length > 0) {
                    objects.forEach(object => {
                        const parentId = object.parentId;
                        const parentIdName = parentId.toString();
                        const parentName = containerObject[parentIdName] ? containerObject[parentIdName].name || "" : "";
                        inventoryHistoryDataList.push({
                            type: InventoryHistoryDataType.LINKING,
                            container: object.container,
                            entityId: object.entityId,
                            parentId: object.parentId,
                            parentName: parentName,
                            timestamp: object.timestamp
                        });
                    });
                }
                const displayedText = ids[index] ? ids[index].toString() : "";
                const objectId = ids[index];
                index++;
                return {
                    id: objectId,
                    displayedText: displayedText,
                    inventoryHistoryDataList: inventoryHistoryDataList
                };
            });
            resolve(inventoryHistoryDataLists);
        });
    });
}
export function getFsrLookupObjects(dataList) {
    return new Promise((resolve, reject) => {
        const ownerIds = [];
        dataList.forEach(data => data.inventoryHistoryDataList.forEach(history => {
            switch (history.type) {
                case InventoryHistoryDataType.OWNER: {
                    const newOwnerId = parseInt(history.newOwner.split("*")[1] || "0");
                    if (newOwnerId) {
                        ownerIds.push(newOwnerId);
                    }
                    const oldOwnerId = parseInt(history.oldOwner.split("*")[1] || "0");
                    if (oldOwnerId) {
                        ownerIds.push(oldOwnerId);
                    }
                    break;
                }
                case InventoryHistoryDataType.STATE: {
                    const ownerId = parseInt(history.ownerId || "0");
                    if (ownerId) {
                        ownerIds.push(ownerId);
                    }
                }
            }
        }));
        const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds).filter(ownerId => ownerId !== -1);
        const ownerIdsPromises = uniqueOwnerIds.map(id => InventoryUtils.userLookupRequest(id));
        Promise.all(ownerIdsPromises).then(responses => {
            resolve(responses.map(response => response.response));
        });
    });
}
function getContextMenuContainerStateSelector(store) {
    return store.inventory.inventoryContextMenuContainer;
}
function getCacheStateSelector(store) {
    return store.inventory.inventoryCache;
}
function getGlobalStateSelector(store) {
    return store.inventory.inventoryGlobalState;
}
export default inventoryContextMenuContainerSagas;
