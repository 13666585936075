import { loadTheme, registerIcons } from "office-ui-fabric-react";
import iconJson from "../../../resources/icons/selection.json";
import { getIcoMoonIcon } from "../../helper/Icon/IcoMoonIcon";
import { setIconOptions } from "office-ui-fabric-react/lib/Styling";
var Theme;
(function (Theme) {
    const defaultFontStyle = {
        fontFamily: "Roboto, sans-serif"
        // fontSize: "10px"
    };
    const palette = {
        themePrimary: "#0078d4",
        themeLighterAlt: "#f3f9fd",
        themeLighter: "#d0e7f8",
        themeLight: "#a9d3f2",
        themeTertiary: "#5ca9e5",
        themeSecondary: "#1a86d9",
        themeDarkAlt: "#006cbe",
        themeDark: "#005ba1",
        themeDarker: "#004377",
        neutralLighterAlt: "#f8f8f8",
        neutralLighter: "#f4f4f4",
        neutralLight: "#eaeaea",
        neutralQuaternaryAlt: "#dadada",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c8c8",
        neutralTertiary: "#bab8b7",
        neutralSecondary: "#a3a2a0",
        neutralPrimaryAlt: "#8d8b8a",
        neutralPrimary: "#323130",
        neutralDark: "#605e5d",
        black: "#494847",
        white: "#ffffff"
    };
    const theme = {
        palette: Object.assign({}, palette),
        defaultFontStyle: Object.assign({}, defaultFontStyle)
    };
    const iconsFromLibrary = {
        icons: {}
    };
    const getIconsFromFile = () => {
        const iconsFromFile = {
            icons: {}
        };
        iconJson.icons.forEach(icon => {
            const name = icon.properties.name;
            iconsFromFile.icons[name] = getIcoMoonIcon(name, icon.icon);
        });
        return iconsFromFile;
    };
    Theme.initializeTheme = () => {
        loadTheme(Object.assign({}, theme));
        registerIcons({
            icons: Object.assign(Object.assign({}, getIconsFromFile().icons), iconsFromLibrary.icons)
        });
        const disableWarnings = true;
        if (disableWarnings) {
            setIconOptions({
                disableWarnings: true
            });
        }
    };
})(Theme || (Theme = {}));
export default Theme;
