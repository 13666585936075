import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../../../../../display/components/SimpleButton/SimpleButton";
const StyledMessageResenderOrderRow = styled.div `
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #eeeeee;
  border-bottom: 1px solid white;
`;
class MessageResenderOrderRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleClick = (event) => {
            this.props.handleOrderRowClick({ orderNumber: this.props.orderNumber });
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderOrderRow, null,
            React.createElement(SimpleButton, { className: "message-resender-order-row", textProps: {
                    iconText: this.props.orderNumber ? this.props.orderNumber.toString() : "unknown Order",
                    height: "2rem",
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "normal"
                }, color: this.theme.palette.black, backgroundColor: "#eeeeee", buttonClick: this.handleClick })));
    }
}
export default MessageResenderOrderRow;
