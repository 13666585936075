import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../../../../../helper/Style/StyleUtils";
const StyledTextCell = styled.div `
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.tableCellData.padding)};
  text-align: ${props => props.tableCellData.textAlign};
  font-weight: ${props => props.tableCellData.fontWeight};
  color: ${props => props.tableCellData.fontColor};
  font-size: ${props => props.tableCellData.fontSize};
  line-height: ${props => props.tableCellData.fontSize};
  background-color: ${props => props.color};
  border-style: solid;
  border-color: ${props => (props.isSelected || props.isAboveSelected ? props.selectedColor : props.color)};
  border-width: ${props => `${StyleUtils.getCssPixelString(props.topBorderWidth)} 0 0 0`};
  user-select: none;
`;
class TextCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onLeftClickHandler = (event) => {
            this.props.handleLeftClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
        this.onRightClickHandler = (event) => {
            this.props.handleRightClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
    }
    render() {
        return (React.createElement(StyledTextCell, Object.assign({ className: "table-text-cell", onClick: this.onLeftClickHandler, onContextMenu: this.onRightClickHandler }, this.props), this.props.tableCellData.displayedText));
    }
}
export default TextCell;
