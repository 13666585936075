import { takeEvery, put } from "redux-saga/effects";
import { GlobalActionTypes } from "./types";
import { DispatchPageContainerActionTypes } from "../../containers/DispatchPageContainer/types";
export const globalActionSagas = {
    handleBeforeDragStartSaga: takeEvery(GlobalActionTypes.HANDLE_BEFORE_DRAG_START, handleBeforeDragStartSaga),
    handleDragStartSaga: takeEvery(GlobalActionTypes.HANDLE_DRAG_START, handleDragStartSaga),
    handleDragUpdateSaga: takeEvery(GlobalActionTypes.HANDLE_DRAG_UPDATE, handleDragUpdateSaga),
    handleDragEndSaga: takeEvery(GlobalActionTypes.HANDLE_DRAG_END, handleDragEndSaga)
};
function* handleBeforeDragStartSaga(action) {
}
function* handleDragStartSaga(action) {
    yield put({
        type: DispatchPageContainerActionTypes.SET_IS_DRAGGING,
        isDragging: true
    });
}
function* handleDragUpdateSaga(action) {
}
function* handleDragEndSaga(action) {
    yield put({
        type: DispatchPageContainerActionTypes.SET_IS_DRAGGING,
        isDragging: false
    });
}
export default globalActionSagas;
