import { call, put, takeEvery } from "redux-saga/effects";
import { AVLPageContainerActionTypes } from "./types";
import ConfigJson from "../../api/REST/ConfigJson/ConfigJson";
import Traccar from "../../api/REST/Traccar/Traccar";
export const avlPageContainerSagas = {
    initSaga: takeEvery(AVLPageContainerActionTypes.INIT, initSaga),
};
function* initSaga(action) {
    yield call(Traccar.authenticate);
    const traccarSettings = yield call(ConfigJson.getTraccarSettings);
    yield put({
        type: AVLPageContainerActionTypes.SET_CONNECTION_INFO,
        host: traccarSettings.host,
        port: traccarSettings.port,
    });
}
function getAVLPageContainerStateSelector(store) {
    return store.avl;
}
export default avlPageContainerSagas;
