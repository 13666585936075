import { call, put, select, takeEvery } from "redux-saga/effects";
import { InventoryCacheActionTypes, InventoryJsonClasses } from "./types";
import { InventoryRead } from "../../api/WebSockets/Inventory/Read";
import { InventorySchemaService } from "../../../helper/InventorySchema/InventorySchemaService";
import { MAX_RESULT_PER_REQUEST } from "../../../helper/WebSocket/WebSocketService";
import { InventoryFilterContainerActionTypes } from "../../containers/InventoryFilterContainer/types";
import { InventoryLinking } from "../../api/WebSockets/Inventory/Linking";
import { InventoryWrite } from "../../api/WebSockets/Inventory/Write";
import { InventoryOwner } from "../../api/WebSockets/Inventory/Owner";
import { InventoryInitializationActionTypes } from "../InventoryInitialization/types";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { InventoryFilterActionTypes } from "../InventoryFilter/types";
import { InventoryTableContainerActionTypes } from "../../containers/InventoryTableContainer/types";
import { Utils } from "../../../helper/General/Utils";
var getInventoryContainerFromServerObject = InventorySchemaService.getInventoryContainerFromServerObject;
var getInventoryServerObjectFromItem = InventorySchemaService.getInventoryServerObjectFromItem;
var J_FILTER_OPERATORS = FilterUtils.J_FILTER_OPERATORS;
export const inventoryCacheSagas = {
    initializeCacheSaga: takeEvery(InventoryCacheActionTypes.INITIALIZE_CACHE, initializeCacheSaga),
    itemUpdateContainerSaga: takeEvery(InventoryCacheActionTypes.ITEM_UPDATE_CONTAINER, itemUpdateContainerSaga),
    itemUpdateOwnerSaga: takeEvery(InventoryCacheActionTypes.ITEM_UPDATE_OWNER, itemUpdateOwnerSaga),
    itemUpdatePropertiesSaga: takeEvery(InventoryCacheActionTypes.ITEM_UPDATE_PROPERTIES, itemUpdatePropertiesSaga)
};
export function* initializeCacheSaga(action) {
    const serverTopLevelContainersResponse = yield call(InventoryRead.containersRequest, 0, MAX_RESULT_PER_REQUEST);
    const serverTopLevelContainers = ((serverTopLevelContainersResponse.response || []));
    const serverTopLevelContainerIds = serverTopLevelContainers.map(container => container.id);
    const simpleTopContainers = yield call(getSimpleTopLevelContainers, serverTopLevelContainerIds);
    const flatSimpleContainers = simpleContainersTreeToFlatMap(simpleTopContainers);
    const flatSimpleContainerIds = flatSimpleContainers.map(simpleContainer => parseInt(simpleContainer.id));
    const flatContainerResponses = yield call(getAllContainerData, flatSimpleContainerIds);
    const flatContainerServerObjects = flatContainerResponses.map(response => response.response);
    const flatContainerObjects = flatContainerServerObjects.map(object => getInventoryContainerFromServerObject(object));
    let index = 0;
    const flatContainerCacheData = flatSimpleContainers.map(simpleContainer => {
        const cacheData = Object.assign(Object.assign({}, flatContainerObjects[index]), { parentId: simpleContainer.parentId === null ? null : parseInt(simpleContainer.parentId), children: simpleContainer.children.map(child => parseInt(child)) });
        index++;
        return cacheData;
    });
    const inventoryRolesString = Utils.getCookieByName("Roles") || "";
    const allowedWarehouses = inventoryRolesString.split("%2C") || [];
    const generalWarehouses = ["UnionTown"];
    const warehouses = flatContainerCacheData.filter(container => container.jsonClass === InventoryJsonClasses.WAREHOUSE);
    const filteredWarehouses = warehouses.filter(warehouse => [...generalWarehouses, ...allowedWarehouses].includes(warehouse.id));
    const filteredWarehouseIds = [
        "unkn",
        ...filteredWarehouses.map(warehouse => warehouse.uniqueId.toString())
    ];
    const warehouseFilters = [
        [
            {
                field: "OpenIndex7",
                operator: J_FILTER_OPERATORS.in,
                value: filteredWarehouseIds
            }
        ]
    ];
    yield put({
        type: InventoryFilterActionTypes.UPDATE_INVENTORY_WAREHOUSE_FILTER,
        filters: warehouseFilters
    });
    const pallets = flatContainerCacheData.filter(container => container.jsonClass === InventoryJsonClasses.PALLET);
    const boxes = flatContainerCacheData.filter(container => container.jsonClass === InventoryJsonClasses.BOX);
    const inventoryContainersCacheObject = {};
    flatContainerCacheData.forEach(simpleContainer => {
        const uniqueId = simpleContainer.uniqueId.toString();
        inventoryContainersCacheObject[uniqueId] = simpleContainer;
    });
    yield put({
        type: InventoryCacheActionTypes.SET_INVENTORY_CACHE,
        inventoryCacheState: {
            containers: inventoryContainersCacheObject,
            generalWarehouses: generalWarehouses,
            allowedWarehouses: allowedWarehouses,
            warehouses: filteredWarehouses,
            pallets: pallets,
            boxes: boxes
        }
    });
    yield put({ type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION });
    const globalState = yield select(getGlobalStateSelector);
    yield put({ type: InventoryTableContainerActionTypes.INITIALIZE });
    yield put({
        type: InventoryInitializationActionTypes.INVENTORY_SET_GLOBAL_STATE,
        globalState: Object.assign(Object.assign({}, globalState), { initializationComplete: true })
    });
}
function getSimpleTopLevelContainers(serverTopLevelContainerIds) {
    return new Promise(function (resolve, reject) {
        const simpleTopLevelContainerPromises = serverTopLevelContainerIds.map(id => {
            return InventoryRead.hierarchyRequest(id);
        });
        Promise.all(simpleTopLevelContainerPromises).then(function (simpleTopLevelContainerResponses) {
            resolve(simpleTopLevelContainerResponses.map(simpleTopLevelContainer => {
                return simpleTopLevelContainer.response;
            }));
        });
    });
}
function getAllContainerData(ids) {
    return new Promise((resolve, reject) => {
        const containerPromises = ids.map(id => {
            return InventoryRead.containerRequestById(0, MAX_RESULT_PER_REQUEST, id);
        });
        Promise.all(containerPromises).then(response => {
            resolve(response);
        });
    });
}
function getCacheSelector(store) {
    return store.inventory.inventoryCache;
}
function simpleContainersTreeToFlatMap(serverSimpleRootContainers) {
    const flatInventorySimpleContainers = [];
    getSimpleContainer(serverSimpleRootContainers, 0, null);
    return flatInventorySimpleContainers;
    function getSimpleContainer(serverSimpleContainers, depth, parentId) {
        serverSimpleContainers.forEach(serverSimpleContainer => {
            const children = serverSimpleContainer.children;
            const uniqueId = serverSimpleContainer.id.toString();
            flatInventorySimpleContainers.push({
                id: uniqueId,
                parentId: parentId,
                children: children.map(child => child.id.toString()),
                depth: depth
            });
            if (Array.isArray(children) && children.length > 0) {
                getSimpleContainer(children, depth + 1, uniqueId);
            }
        });
    }
}
function* itemUpdateContainerSaga(action) {
    action.itemIds.forEach(itemId => {
        InventoryLinking.inventoryLinkRequestById(itemId, action.containerId);
    });
}
function* itemUpdateOwnerSaga(action) {
    action.itemIds.forEach(itemId => {
        InventoryOwner.inventoryRequestById(action.ownerType, action.ownerId.toString(), itemId);
    });
}
function* itemUpdatePropertiesSaga(action) {
    action.data.forEach(data => {
        const inventoryObject = data.inventoryData;
        const inventoryServerObject = getInventoryServerObjectFromItem(inventoryObject);
        const propertiesData = data.propertiesData;
        propertiesData.forEach(propertyData => {
            inventoryServerObject.properties[propertyData.property] = propertyData.value;
        });
        InventoryWrite.inventoryUpdateRequest(inventoryServerObject);
    });
}
function getGlobalStateSelector(store) {
    return store.inventory.inventoryGlobalState;
}
export default inventoryCacheSagas;
