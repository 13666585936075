import React, { useEffect, useState } from "react";
import "../../../legacy/css/icon_css/style.css";
import { RouteStates } from "./RouteStates";
import { SLRawHtmlTooltip } from "./Material";
import DOMPurify from 'dompurify';
export const RouteSwitchButton = (props) => {
    let [state, setState] = useState({ routeShowing: false });
    let css = {
        cursor: "pointer"
    };
    useEffect(() => {
        return () => {
            if (state.routeShowing)
                callForRoutingUpdate();
        };
    }, [state.routeShowing]);
    let clickFun;
    if (props.routeState === RouteStates.ROUTE_SUCCESS)
        clickFun = onRouteClickHandler;
    else
        clickFun = noRouteClickHandler;
    //@ts-ignore
    let html = window.getDefaultRenderHtml("fsr", props.worker)[0];
    let htmlElementJQuery = $(html);
    let toolTipHtml = htmlElementJQuery.attr("title");
    htmlElementJQuery.attr("title", null);
    html = htmlElementJQuery.html();
    return (React.createElement(SLRawHtmlTooltip, { html: toolTipHtml },
        React.createElement("a", { className: `.not-selectable`, style: css, onMouseDown: clickFun, dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(html) } })));
    function noRouteClickHandler() {
        //@ts-ignore
        window.locateOnMap(props.coordinate.longitude.toString(), props.coordinate.latitude.toString());
    }
    function callForRoutingUpdate(event) {
        const $cell = event ? $(event.target) : null;
        window.store.dispatch({
            type: "dispatch.page.HANDLE_MAP_DISPLAY_ROUTE_EVENT",
            event: {
                id: props.fsrId,
                type: "fsr",
                cell: $cell //TODO: LEGACY This is to support legacy table. Remove this when the FSR table is replaced by react.
            }
        });
        setState({ routeShowing: !state.routeShowing });
    }
    function onRouteClickHandler(event) {
        if (event.ctrlKey) {
            noRouteClickHandler();
        }
        else {
            callForRoutingUpdate(event);
        }
    }
};
