import axios from "axios";
import moment from "moment";
import { js2xml } from "xml-js";
import $ from "jquery";
const OrdersServiceName = "/sl3/api/orders";
export const getOrderFromBigOrder = (order) => {
    return {
        id: order.id,
        stateId: order.stateId,
        latitude: order.location.lat,
        longitude: order.location.lon,
        number: order.number,
        appointmentType: order.appointment.type,
        jobCodeId: order.jobCode.jobCode1Id.toString(),
    };
};
const LegacyOrderHelper = {
    lat: "",
    lon: "",
    EMG: true,
    CHILD: true,
    DEL: true,
    STALE: true,
    ICON: "",
    COLOR: { ALL: "", STATE_ID: "" },
    ORDER_ID: "",
    STATE_ID: "",
    ORDER_NUMBER: "",
    TYPE: "",
    ASSIGNED_FSR_ID: "",
    CREATION_DATE: "",
    METER: "",
    ACCOUNT_NAME: "",
    DISTRICT: "",
    POLENUMBER: "",
    APPOINTMENT: "",
    APPOINTMENT_TYPE: "",
    JOB_CODE_ID1: "",
    LOCATION: "",
    RETURN_REASON: "",
    APPT_START_TIME: "",
    APPT_END_TIME: "",
};
// Legacy server apis read date formats in the formats below, ideally these should not be used for data exchange when ISO8601 exists
//TODO: New apis should move away from these formats for data transfer
const LegacyFormats = {
    shortFormat: "YYYYMMDD HHmmss",
    longFormat: "YYYY-MM-DD HH:mm:ss",
    twelveHourFormat: "MM/DD/YYYY hh:mm:ss a",
    shortDateFormat: "YYYYMMDD",
    longDateFormat: "YYYY-MM-DD",
};
export var Orders;
(function (Orders) {
    const MAX_SAFE_SERVER_INTEGER = Math.pow(2, 31) - 1;
    function getOrdersWithFilter(legacyFilter) {
        return axios({
            method: "post",
            params: {
                start: 1,
                end: MAX_SAFE_SERVER_INTEGER,
                columns: Object.keys(LegacyOrderHelper).reduce((acc, val) => acc + "," + val),
                filter: JSON.stringify(legacyFilter),
                load_type: 'full'
            },
            url: OrdersServiceName
        }).then(({ data: { data } }) => ({
            success: true,
            response: data.map(convertLegacyOrder)
        }));
    }
    Orders.getOrderByNumber = (orderNumber, date) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "POST",
                url: "/sl3/api/orders",
                data: `start=1&end=80&columns=STATE_ID&filter={"$and":[{"$or":[{"ORDER_NUMBER":{"$in":["${orderNumber}"]}}]}]}&filter_date=${date}&loadtype=full&winnum=1`,
                dataType: "json",
                async: true,
                cache: false,
                success: (rsp) => {
                    resolve(rsp);
                },
                error: (e) => {
                    console.log(e);
                }
            });
        });
    };
    function modifyOrders(orders) {
        const orderXml = js2xml({
            REQUEST: {
                _attributes: {
                    FUNC: "ORDER_MODIFY",
                    TIME: moment().format(LegacyFormats.longFormat),
                    GUID: ""
                },
                ORDERS: orders.map(order => ({
                    ORDER: {
                        APPOINTMENT_START_DATETIME: moment(order.appointmentStartTime).format(LegacyFormats.longFormat),
                        APPOINTMENT_END_DATETIME: moment(order.appointmentEndTime).format(LegacyFormats.longFormat),
                        APPOINTMENT_TYPE: order.appointmentType,
                        ORDER_NUMBER: order.number,
                        APPT_START_MILLIS: moment(order.appointmentStartTime).valueOf(),
                        APPT_END_MILLIS: moment(order.appointmentEndTime).valueOf(),
                    }
                }))
            }
        }, { compact: true });
        return axios({
            method: "POST",
            params: {
                request: orderXml
            },
            url: "/sl3/order"
        });
    }
    Orders.modifyOrders = modifyOrders;
    function convertLegacyOrder(legacyOrder) {
        let { ORDER_ID, ORDER_NUMBER, STATE_ID, APPT_START_TIME, APPT_END_TIME, APPOINTMENT_TYPE, lat, lon, JOB_CODE_ID1, } = legacyOrder;
        return {
            id: parseInt(ORDER_ID),
            stateId: parseInt(STATE_ID),
            latitude: parseFloat(lat),
            longitude: parseFloat(lon),
            appointmentType: APPOINTMENT_TYPE,
            appointmentStartTime: moment(APPT_START_TIME).toISOString(true),
            appointmentEndTime: moment(APPT_END_TIME).toISOString(true),
            number: ORDER_NUMBER,
            jobCodeId: JOB_CODE_ID1
        };
    }
    Orders.convertLegacyOrder = convertLegacyOrder;
    function getOrdersUpToGivenTimeForFsrsExcludingCompletedOrders(time, fsrIds) {
        let legacyFilter = {
            "$and": [{
                    "$and": [
                        {
                            "$and": [
                                { "APPOINTMENT": { "$le": [time.toString()] } }
                            ]
                        },
                        { "APPOINTMENT": { "$nin": [0] } },
                        { "STATE_ID": { "$nin": [70] } }
                    ]
                }, { "ASSIGNED_FSR_ID": { "$in": fsrIds.map(x => x.toString()) } }]
        };
        return getOrdersWithFilter(legacyFilter);
    }
    Orders.getOrdersUpToGivenTimeForFsrsExcludingCompletedOrders = getOrdersUpToGivenTimeForFsrsExcludingCompletedOrders;
    function getOrdersFromIds(...ids) {
        return getOrdersWithFilter({
            "$and": [{
                    "id": { $in: ids }
                }]
        });
    }
    Orders.getOrdersFromIds = getOrdersFromIds;
    function getOrdersFromIdArray(ids) {
        return getOrdersWithFilter({
            "$and": [{
                    "id": { $in: ids }
                }]
        });
    }
    Orders.getOrdersFromIdArray = getOrdersFromIdArray;
    //@ts-ignore
    window.getOrdersFromIdArray = getOrdersFromIdArray;
    function getOrdersAssignedToFsrsInDateRange(startTime, endTime, fsrIds, additionalFilters = []) {
        let legacyFilter = {
            "$and": [{
                    "$and": [
                        {
                            "$and": [
                                { "APPOINTMENT": { "$ge": [startTime.toString()] } },
                                { "APPOINTMENT": { "$le": [endTime.toString()] } },
                                ...additionalFilters
                            ]
                        }
                    ]
                }, { "ASSIGNED_FSR_ID": { "$in": fsrIds.map(x => x.toString()) } }]
        };
        return getOrdersWithFilter(legacyFilter);
    }
    Orders.getOrdersAssignedToFsrsInDateRange = getOrdersAssignedToFsrsInDateRange;
    function getRawHtmlTooltipForOrder(orderId) {
        return axios({
            method: "post",
            params: {
                tooltip: true,
                id: orderId,
                field: "map",
            },
            url: OrdersServiceName
        }).then(({ data }) => ({
            success: true,
            response: data
        }));
    }
    Orders.getRawHtmlTooltipForOrder = getRawHtmlTooltipForOrder;
})(Orders || (Orders = {}));
