import React from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection[OrderDirection["None"] = 0] = "None";
    OrderDirection[OrderDirection["Ascending"] = 1] = "Ascending";
    OrderDirection[OrderDirection["Descending"] = 2] = "Descending";
})(OrderDirection || (OrderDirection = {}));
export const PropertyOrderingSelect = props => {
    const { selectedFilterPath, orderDirection, propPathToReadableTexts } = props;
    let orderDirectionMapping = {}; //Typescript doesn't allow enums as keys?
    orderDirectionMapping[OrderDirection.None] = OrderDirection.Descending;
    orderDirectionMapping[OrderDirection.Ascending] = OrderDirection.Descending;
    orderDirectionMapping[OrderDirection.Descending] = OrderDirection.Ascending;
    const handleOnClick = (eventSelectedValue) => (e) => {
        let ordering = eventSelectedValue == selectedFilterPath ? orderDirectionMapping[orderDirection] : OrderDirection.Ascending;
        props.onOrderingChange({ orderDirection: ordering, propertyPath: eventSelectedValue });
    };
    let OrderingEntrySlim = ({ propertyPath, propertyName }) => {
        if (selectedFilterPath == propertyPath && orderDirection !== OrderDirection.None) {
            return React.createElement(React.Fragment, null,
                React.createElement("div", null, `${propertyName} (${orderDirection == OrderDirection.Descending ? "Descending" : "Ascending"})`));
        }
        else {
            return React.createElement(React.Fragment, null,
                React.createElement("div", null, `${propertyName}`));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, { shrink: true, style: { width: 150, marginLeft: 5, marginTop: 5 }, id: "orderBySelectLabel" }, "Order By"),
        React.createElement(Select
        // @ts-ignore
        , { 
            // @ts-ignore
            labelId: "orderBySelectLabel", id: "orderBySelect", style: { width: 150, marginLeft: 5 }, value: selectedFilterPath, displayEmpty: true }, propPathToReadableTexts.map(({ propertyPath, propertyName }) => (React.createElement(MenuItem, { button: true, onClick: handleOnClick(propertyPath), key: propertyPath, value: propertyPath, style: { width: "250px" } },
            React.createElement("div", { style: { display: "flex", flexDirection: "row", height: "100%", width: "100%" } },
                React.createElement(OrderingEntrySlim, { propertyName: propertyName, propertyPath: propertyPath }))))))));
};
