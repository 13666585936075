import React from "react";
import TableRowWrapper from "./TableRowWrapper/TableRowWrapper";
import styled from "styled-components";
import { Subject } from "rxjs";
import { interval } from "rxjs/observable/interval";
import { sample } from "rxjs/operators";
import ReactResizeDetector from "react-resize-detector";
import { Utils } from "../../../../../helper/General/Utils";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
const StyledTableRowScrollWrapper = styled.div `
  height: ${props => `calc(100% - ${StyleUtils.getCssPixelString(props.height)})`};
  width: 100%;
  overflow: scroll;
`;
class TableRowScrollWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleResize = () => {
            this.onResizeThrottled.next();
        };
        this.handleScroll = (event) => {
            this.props.handleHorizontalScroll(event);
            const scrollTop = event.currentTarget.scrollTop;
            this.onScrollDebounced.next(scrollTop);
        };
        this.getRowHeight = () => {
            const rootFontSize = Utils.getRootFontSize();
            const rowHeightRootFontSizeRatio = 2;
            const rowSeparatorHeightRootFontSizeRatio = 0.1;
            return rootFontSize * (rowHeightRootFontSizeRatio + rowSeparatorHeightRootFontSizeRatio);
        };
        this.getSpacerRowHeight = () => {
            const rootFontSize = Utils.getRootFontSize();
            const rowHeightRootFontSizeRatio = 2;
            return rootFontSize * rowHeightRootFontSizeRatio;
        };
        this.getVerticalScrollState = (scrollTopEvent) => {
            const scrollTop = scrollTopEvent || this.tableRowScrollWrapper.current ? this.tableRowScrollWrapper.current.scrollTop : 0;
            const rowHeight = this.getRowHeight();
            const totalRowsCount = this.props.tableRowWrapperDataProps.totalRowsCount;
            const bufferRowsCount = this.props.tableRowVerticalScrollDataProps.bufferRowsCount;
            const topScrollRow = Math.floor(scrollTop / rowHeight);
            const topBufferRowsCount = Math.min(topScrollRow, bufferRowsCount);
            const topSpacerRowsCount = Math.max(topScrollRow - topBufferRowsCount, 0);
            const offsetHeight = this.tableRowScrollWrapper.current
                ? this.tableRowScrollWrapper.current.offsetHeight
                : 0;
            const visibleRowsCount = Math.ceil(offsetHeight / rowHeight);
            const bottomScrollRow = topScrollRow + visibleRowsCount;
            const bottomBufferRowsCount = Math.max(Math.min(totalRowsCount - bottomScrollRow, bufferRowsCount), 0);
            const bottomSpacerRowsCount = totalRowsCount - topSpacerRowsCount - topBufferRowsCount - visibleRowsCount - bottomBufferRowsCount;
            const displayedRowsCount = topBufferRowsCount + visibleRowsCount + bottomBufferRowsCount;
            const start = topScrollRow - topBufferRowsCount;
            const end = Math.max(start + displayedRowsCount - 1, 0);
            return {
                displayedRowsCount: displayedRowsCount,
                topSpacerRowsCount: topSpacerRowsCount,
                start: start,
                end: end
            };
        };
        this.tableRowScrollWrapper = React.createRef();
        this.onResizeThrottled = new Subject();
        this.onScrollDebounced = new Subject();
    }
    componentDidMount() {
        this.onResizeThrottled
            .pipe(sample(interval(this.props.tableRowVerticalScrollDataProps.scrollThrottleTime)))
            .subscribe(event => {
            const verticalScrollEvent = this.getVerticalScrollState();
            this.props.tableRowScrollActions.handleVerticalScroll(verticalScrollEvent);
        });
        this.onScrollDebounced
            .debounce(() => interval(this.props.tableRowVerticalScrollDataProps.scrollThrottleTime))
            .subscribe(scrollTop => {
            const verticalScrollEvent = this.getVerticalScrollState(scrollTop);
            this.props.tableRowScrollActions.handleVerticalScroll(verticalScrollEvent);
        });
    }
    componentWillUnmount() {
        if (this.onScrollDebounced) {
            this.onScrollDebounced.unsubscribe();
        }
        if (this.onResizeThrottled) {
            this.onResizeThrottled.unsubscribe();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tableRowScrollWrapper.current.scrollLeft = this.props.horizontalScroll;
    }
    render() {
        const { tableRowWrapperDataProps, tableRowVerticalScrollDataProps, tableCellActions, tableCellCommonActions, handleTableRowScrollWrapperRightClick } = this.props;
        const { totalRowsCount, tableRowsData } = tableRowWrapperDataProps;
        const { topSpacerRowsCount, displayedRowsCount } = tableRowVerticalScrollDataProps.verticalScroll;
        return (React.createElement(StyledTableRowScrollWrapper, Object.assign({ className: "table-row-scroll-wrapper", onScroll: this.handleScroll, ref: this.tableRowScrollWrapper }, this.props, { onContextMenu: handleTableRowScrollWrapperRightClick }),
            React.createElement(ReactResizeDetector, { handleHeight: true, handleWidth: true, onResize: this.handleResize }),
            React.createElement(TableRowWrapper, { rowHeight: this.getRowHeight(), tableRowWrapperData: {
                    totalRowsCount: totalRowsCount,
                    tableRowsData: tableRowsData
                }, topSpacerRowsCount: topSpacerRowsCount, displayedRowsCount: displayedRowsCount, tableCellActions: tableCellActions, tableCellCommonActions: tableCellCommonActions })));
    }
}
export default TableRowScrollWrapper;
