import React, { useEffect, useState } from "react";
import LoginPage from "../pages/LoginPage";
import { LoginPageContainerActionTypes } from "../../../state/containers/LoginPageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
const LoginPageContainer = (props) => {
    const state = useSelector(store => store.login, shallowEqual);
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [businessUnit, setBusinessUnit] = useState("");
    useEffect(() => {
        dispatch({
            type: LoginPageContainerActionTypes.INITIALIZE
        });
        document.title = "Service-Link: Login";
    }, []);
    const handleLogin = (username, password, businessUnit) => {
        dispatch({
            type: LoginPageContainerActionTypes.HANDLE_LOGIN,
            username: username,
            password: password,
            businessUnit: businessUnit
        });
    };
    const handleADLogin = (username, password, businessUnit) => {
        dispatch({
            type: LoginPageContainerActionTypes.HANDLE_MSAL_LOGIN,
            username: username,
            password: password,
            businessUnit: businessUnit
        });
    };
    const handleActiveDirectoryLogin = () => {
        dispatch({
            type: LoginPageContainerActionTypes.HANDLE_AD_LOGIN
        });
    };
    const handle2FA = (code) => {
        dispatch({
            type: LoginPageContainerActionTypes.HANDLE_2FA,
            code: code
        });
    };
    const handle2FAResend = () => {
        handleLogin(username, password, businessUnit);
    };
    const handleLoginInterceptor = (interceptedUsername, interceptedPassword, interceptedBusinessUnit) => {
        setUsername(interceptedUsername);
        setPassword(interceptedPassword);
        setBusinessUnit(interceptedBusinessUnit);
        handleLogin(interceptedUsername, interceptedPassword, interceptedBusinessUnit);
    };
    const handleADLoginInterceptor = (interceptedUsername, interceptedPassword, interceptedBusinessUnit) => {
        setUsername(interceptedUsername);
        setPassword(interceptedPassword);
        setBusinessUnit(interceptedBusinessUnit);
        handleADLogin(interceptedUsername, interceptedPassword, interceptedBusinessUnit);
    };
    return (React.createElement(LoginPage, Object.assign({ handleLogin: handleLoginInterceptor, handleADLogin: handleADLoginInterceptor, handle2FA: handle2FA, handle2FAResend: handle2FAResend, handleActiveDirectoryLogin: handleActiveDirectoryLogin }, state)));
};
export default LoginPageContainer;
