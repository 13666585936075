import React, { useEffect, useState } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import styled from "styled-components";
import Icon from "../Icon/Icon";
import Spacer from "../Spacer/Spacer";
import Text from "../Text/Text";
export const StyledIconTextCheckbox = styled.div `
  height: 33px;
  width: 220px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
`;
const IconTextCheckbox = (props) => {
    const { identifier, displayedText, initialState, icon, handleCheckboxChange, } = props;
    useEffect(() => {
        if (initialState !== checked) {
            setChecked(initialState);
        }
    }, [initialState]);
    const [checked, setChecked] = useState(initialState);
    const handleChange = () => {
        setChecked(prevState => {
            const newCheckedState = !prevState;
            handleCheckboxChange({
                checked: newCheckedState,
                identifier: identifier,
            });
            return newCheckedState;
        });
    };
    return (React.createElement(StyledIconTextCheckbox, null,
        React.createElement(Checkbox, { color: "primary", checked: checked, onChange: handleChange }),
        !!icon ? (React.createElement(React.Fragment, null,
            React.createElement(Icon, { iconName: icon, height: 16, width: 16, margin: [8.5, 0, 8.5, 0] }),
            React.createElement(Spacer, { width: 10 }))) : null,
        React.createElement(Text, { text: displayedText, height: 33, fontSize: 16, horizontalAlignment: "left", width: !!icon ? 161 : 187 })));
};
export default IconTextCheckbox;
