import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventorySchema;
(function (InventorySchema) {
    InventorySchema.SchemaServiceName = "schema";
    let SchemaActionName;
    (function (SchemaActionName) {
        SchemaActionName["CONTAINER"] = "container";
        SchemaActionName["CONTAINERS"] = "containers";
        SchemaActionName["CONTAINER_INDEXES"] = "container/indexes";
        SchemaActionName["INVENTORY"] = "inventory";
        SchemaActionName["INVENTORIES"] = "inventories";
        SchemaActionName["INVENTORY_INDEXES"] = "inventory/indexes";
    })(SchemaActionName = InventorySchema.SchemaActionName || (InventorySchema.SchemaActionName = {}));
    function containerRequest(containerName) {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.CONTAINER,
            request: {
                name: containerName
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.containerRequest = containerRequest;
    function containersRequest() {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.CONTAINERS,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.containersRequest = containersRequest;
    function containerIndexesRequest(containerName) {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.CONTAINER_INDEXES,
            request: {
                name: containerName
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.containerIndexesRequest = containerIndexesRequest;
    function inventoryRequest(inventoryName) {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.INVENTORY,
            request: {
                name: inventoryName
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.inventoryRequest = inventoryRequest;
    function inventoriesRequest() {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.INVENTORIES,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.inventoriesRequest = inventoriesRequest;
    function inventoryIndexesRequest(inventoryName) {
        const request = {
            service: InventorySchema.SchemaServiceName,
            action: SchemaActionName.INVENTORY_INDEXES,
            request: {
                name: inventoryName
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventorySchema.inventoryIndexesRequest = inventoryIndexesRequest;
})(InventorySchema || (InventorySchema = {}));
