import React from "react";
import TableRowScrollWrapper from "./TableRowScrollWrapper/TableRowScrollWrapper";
import TableHeaderRowScrollWrapper from "./TableHeaderRowScrollWrapper/TableHeaderRowScrollWrapper";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
const StyledTableHeaderAndBodyWrapper = styled.div `
  height: calc(100% - 1.6rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
  background-color: ${props => props.palette.white};
`;
class TableHeaderAndBodyWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleHorizontalScroll = (event) => {
            const scrollWrapper = event.currentTarget;
            const scrollLeft = scrollWrapper.scrollLeft;
            if (scrollLeft !== this.state.horizontalScroll) {
                this.setState({ horizontalScroll: scrollWrapper.scrollLeft });
            }
        };
        this.state = {
            horizontalScroll: 0
        };
    }
    render() {
        const { horizontalScroll } = this.state;
        return (React.createElement(StyledTableHeaderAndBodyWrapper, Object.assign({ className: "table-header-and-body-wrapper" }, this.theme),
            React.createElement(TableHeaderRowScrollWrapper, Object.assign({}, this.props, { horizontalScroll: horizontalScroll, handleHorizontalScroll: this.handleHorizontalScroll })),
            React.createElement(TableRowScrollWrapper, Object.assign({}, this.props, { height: this.props.tableHeaderRowProps.height, horizontalScroll: horizontalScroll, handleHorizontalScroll: this.handleHorizontalScroll, handleTableRowScrollWrapperRightClick: this.props.handleTableRowScrollWrapperRightClick }))));
    }
}
export default TableHeaderAndBodyWrapper;
