import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import Icon from "../../Icon/Icon";
import PasswordInput from "../../Input/PasswordInput/PasswordInput";
import Spacer from "../../Spacer/Spacer";
import TextButton from "../../Button/TextButton/TextButton";
import Text from "../../Text/Text";
import Paragraph from "../../Paragraph/Paragraph";
export const Logo = styled.img `
  height: ${props => StyleUtils.getNormalizedPixelString(props.logoHeight)};
  width: auto;
`;
export const StyledPasswordChangeMenuInput = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
`;
export const InputWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => props.borderColor};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: solid;
`;
export const ButtonsWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
`;
const PasswordChangeMenuInput = props => {
    const { errorMessage = "", message = "", slAuthEnabled = true, handleChangePassword, handleGoBack, height = 300, width = 430, backgroundColor = "#F6F6F6", topSpacerHeight = 39, bottomSpacerHeight = 10, focusColor = "#FC970A", labelHeight = 16, labelFontSize = 12, labelFontColor = "#62757F", inputHeight = 28, inputWidth = 328, inputBorderWidth = 1, inputBorderRadius = 5, inputPlaceholderColor = "#757575", inputFontColor = "#000000", inputFontSize = 16, inputIconPadding = 5, inputIconSpacerWidth = 5, inputSpacerHeight = 10, inputOldPasswordIconName = "key", inputOldPasswordPlaceholder = "Enter your old password", inputNewPasswordIconName = "key", inputNewPasswordPlaceholder = "Enter your new password", inputRePasswordIconName = "key", inputRePasswordPlaceholder = "Enter your new password again", errorMessageSpacerHeight = 10, errorMessageHeight = 100, errorMessageWidth = 250, errorMessageFontSize = 12, errorMessageFontColor = "#D62C1B", authErrorMessageFontSize = 16, buttonSpacerHeight = 10, buttonSpacerWidth = 30, buttonsHeight = 28, buttonsWidth = 328, buttonHeight = 28, buttonWidth = 180, buttonFontColor = "#FFFFFF", buttonFontSize = 16, buttonBackgroundColor = {
        normal: "#62757F",
        mouseOver: "#4E5D65",
        mouseDown: "#374248"
    }, buttonBorderRadius = 5 } = props;
    useEffect(() => {
        setInternalMessage(props.slAuthEnabled ? "" : "Service Link authorization is not enabled. Please go back");
    }, []);
    const [oldPasswordValue, setOldPasswordValue] = useState("");
    const [newPasswordValue, setNewPasswordValue] = useState("");
    const [rePasswordValue, setRePasswordValue] = useState("");
    const [isOldPasswordFocus, setIsOldPasswordFocus] = useState(false);
    const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
    const [isRePasswordFocus, setIsRePasswordFocus] = useState(false);
    const [isOldPasswordInputError, setIsOldPasswordInputError] = useState(false);
    const [isNewPasswordInputError, setIsNewPasswordInputError] = useState(false);
    const [isRePasswordInputError, setIsRePasswordInputError] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState("");
    const [internalMessage, setInternalMessage] = useState("");
    const oldPasswordBorderColor = isOldPasswordInputError
        ? errorMessageFontColor
        : isOldPasswordFocus
            ? focusColor
            : inputPlaceholderColor;
    const newPasswordBorderColor = isNewPasswordInputError
        ? errorMessageFontColor
        : isNewPasswordFocus
            ? focusColor
            : inputPlaceholderColor;
    const rePasswordBorderColor = isRePasswordInputError
        ? errorMessageFontColor
        : isRePasswordFocus
            ? focusColor
            : inputPlaceholderColor;
    const displayedErrorMessage = internalErrorMessage || errorMessage;
    const displayedNonErrorMessage = internalMessage || message;
    const displayedMessage = displayedErrorMessage || displayedNonErrorMessage;
    const isError = !!displayedErrorMessage;
    const displayedMessageColor = isError ? errorMessageFontColor : inputFontColor;
    const handleOldPasswordChange = (oldPassword) => {
        setOldPasswordValue(oldPassword);
    };
    const handleNewPasswordChange = (newPassword) => {
        setNewPasswordValue(newPassword);
    };
    const handleRePasswordChange = (rePassword) => {
        setRePasswordValue(rePassword);
    };
    const handleOldPasswordFocus = () => {
        setIsOldPasswordFocus(true);
    };
    const handleNewPasswordFocus = () => {
        setIsNewPasswordFocus(true);
    };
    const handleRePasswordFocus = () => {
        setIsRePasswordFocus(true);
    };
    const handleOldPasswordBlur = () => {
        setIsOldPasswordFocus(false);
    };
    const handleNewPasswordBlur = () => {
        setIsNewPasswordFocus(false);
    };
    const handleRePasswordBlur = () => {
        setIsRePasswordFocus(false);
    };
    const handleOldPasswordSubmit = (oldPassword) => {
        handleChangePasswordButton(oldPassword, newPasswordValue, rePasswordValue);
    };
    const handleNewPasswordSubmit = (newPassword) => {
        handleChangePasswordButton(oldPasswordValue, newPassword, rePasswordValue);
    };
    const handleRePasswordSubmit = (rePassword) => {
        handleChangePasswordButton(oldPasswordValue, newPasswordValue, rePassword);
    };
    const handleChangePasswordButtonClick = () => {
        handleChangePasswordButton(oldPasswordValue, newPasswordValue, rePasswordValue);
    };
    const isValidPassword = React.useCallback((oldPassword, newPassword, rePassword) => {
        return !!oldPassword && !!newPassword && !!rePassword && newPassword === rePassword && oldPassword != newPassword;
    }, []);
    const clearInputErrors = () => {
        setInternalErrorMessage("");
        setIsOldPasswordInputError(false);
        setIsNewPasswordInputError(false);
        setIsRePasswordInputError(false);
    };
    const handleChangePasswordButton = (oldPassword, newPassword, rePassword) => {
        let passwordErrors = [];
        if (localStorage.getItem("winNum")) {
            localStorage.removeItem("winNum");
        }
        const isValid = isValidPassword(oldPassword, newPassword, rePassword);
        if (isValid) {
            clearInputErrors();
            handleChangePassword(oldPassword, newPassword);
        }
        else {
            if (!oldPassword) {
                setIsOldPasswordInputError(true);
                passwordErrors.push("Old Password is Required");
            }
            if (!newPassword) {
                setIsNewPasswordInputError(true);
                passwordErrors.push("New Password is Required");
            }
            if (newPassword === rePassword) {
                setIsRePasswordInputError(false);
            }
            else {
                setIsRePasswordInputError(true);
                passwordErrors.push("Passwords do not match");
            }
            if (newPassword === oldPassword) {
                setIsNewPasswordInputError(true);
                passwordErrors.push("New Password must be different from Old Password");
            }
            const newErrorMessage = passwordErrors.join("\n\n");
            setInternalErrorMessage(newErrorMessage);
            setOldPasswordValue(oldPassword);
            setNewPasswordValue(newPassword);
            setRePasswordValue(rePassword);
        }
    };
    const handleGoBackButtonClick = () => {
        handleGoBack();
    };
    return (React.createElement(StyledPasswordChangeMenuInput, { className: "password-change-menu-input", height: height, width: width, backgroundColor: backgroundColor }, slAuthEnabled ? (React.createElement(React.Fragment, null,
        React.createElement(Spacer, { height: topSpacerHeight }),
        React.createElement(Spacer, { height: labelHeight }),
        React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "Old password", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
        React.createElement(InputWrapper, { className: "password-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: oldPasswordBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
            React.createElement(Icon, { iconName: inputNewPasswordIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
            React.createElement(Spacer, { width: inputIconSpacerWidth }),
            React.createElement(PasswordInput, { className: "password-input", handleSubmit: handleOldPasswordSubmit, handleChange: handleOldPasswordChange, handleFocus: handleOldPasswordFocus, handleBlur: handleOldPasswordBlur, placeholder: inputOldPasswordPlaceholder, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, iconColor: inputPlaceholderColor, placeholderColor: inputPlaceholderColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })),
        React.createElement(Spacer, { height: inputSpacerHeight }),
        React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "New password", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
        React.createElement(InputWrapper, { className: "password-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: newPasswordBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
            React.createElement(Icon, { iconName: inputOldPasswordIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
            React.createElement(Spacer, { width: inputIconSpacerWidth }),
            React.createElement(PasswordInput, { className: "password-input", handleSubmit: handleNewPasswordSubmit, handleChange: handleNewPasswordChange, handleFocus: handleNewPasswordFocus, handleBlur: handleNewPasswordBlur, placeholder: inputNewPasswordPlaceholder, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, iconColor: inputPlaceholderColor, placeholderColor: inputPlaceholderColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })),
        React.createElement(Spacer, { height: inputSpacerHeight }),
        React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "Confirm new password", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
        React.createElement(InputWrapper, { className: "password-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: rePasswordBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
            React.createElement(Icon, { iconName: inputRePasswordIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
            React.createElement(Spacer, { width: inputIconSpacerWidth }),
            React.createElement(PasswordInput, { className: "password-input", handleSubmit: handleRePasswordSubmit, handleChange: handleRePasswordChange, handleFocus: handleRePasswordFocus, handleBlur: handleRePasswordBlur, placeholder: inputRePasswordPlaceholder, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, iconColor: inputPlaceholderColor, placeholderColor: inputPlaceholderColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })),
        React.createElement(Spacer, { height: errorMessageSpacerHeight }),
        React.createElement(Paragraph, { text: displayedMessage, height: errorMessageHeight, width: errorMessageWidth, backgroundColor: backgroundColor, fontSize: errorMessageFontSize, fontColor: displayedMessageColor }),
        React.createElement(Spacer, { height: buttonSpacerHeight }),
        React.createElement(ButtonsWrapper, { className: "buttons-wrapper", height: buttonsHeight, width: buttonsWidth },
            React.createElement(TextButton, { handleClick: handleChangePasswordButtonClick, text: "Submit", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius }),
            React.createElement(Spacer, { width: buttonSpacerWidth }),
            React.createElement(TextButton, { handleClick: handleGoBackButtonClick, text: "Go Back", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius })),
        React.createElement(Spacer, { height: buttonSpacerHeight }),
        React.createElement(Spacer, { height: bottomSpacerHeight }))) : (React.createElement(React.Fragment, null,
        React.createElement(Paragraph, { text: displayedMessage, height: errorMessageHeight, width: errorMessageWidth, backgroundColor: backgroundColor, fontSize: authErrorMessageFontSize, fontColor: displayedMessageColor, horizontalAlignment: "center" }),
        React.createElement(Spacer, { height: buttonSpacerHeight }),
        React.createElement(TextButton, { handleClick: handleGoBackButtonClick, text: "Go Back", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius })))));
};
export default PasswordChangeMenuInput;
