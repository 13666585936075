import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { AppStore } from "../../../App";
import { creators, DispatchResultsType } from "../../../state/containers/DispatchPageContainer/actions";
import FsrScheduler from "../../../display/components/FsrScheduler/FsrScheduler";
import { DndContext } from "react-dnd";
import ESRIMapContainer from "../../../display/web/containers/ESRIMapContainer";
import { LegacyScriptLoader } from "../../helper/LegacyScriptLoader";
import init from "../../scripts/setup.js";
import { apptBooking } from "../../scripts/appt-booking";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import XmlJs from "xml-js";
import { useDispatch, useSelector } from "react-redux";
import { DragDropActionTypes } from "../../../state/services/DragDrop/types";
import { DraggableTypes } from "../../../hooks/DragDrop/types";
import { DispatchPageContainerActionTypes, ModalTypes } from "../../../state/containers/DispatchPageContainer/types";
import { FsrCalendarWindow } from "../../../display/web/components/FsrCalendar/FsrCalendarWindow";
import { dateValueToLegacyDateString } from "../../../display/web/hooks/legacy/Dispatch";
import { Orders } from "../../../state/api/REST/Orders/Orders";
var convertLegacyOrder = Orders.convertLegacyOrder;
import ModalDialog from "../../../display/components/Modals/ModalDialog";
import legacyReactInit from "../../../display/web/components/legacy/legacy-react-components";
import { ThemeContext } from "../../../display/theme/ThemeContextProvider";
import { renderLegacyPopup } from "../../../display/web/components/Map/OLMap/LegacyOLMapPopup";
import { useEventListener } from "../../../hooks/General/useEventListener";
import DOMPurify from 'dompurify';
import { throttle } from "lodash";
var html = require("./index.html");
var page = { __html: DOMPurify.sanitize(html) };
let lastMouseDownPosition = { x: 0, y: 0, };
function ready(callbackFunction) {
    if (document.readyState != 'loading')
        callbackFunction(event);
    else
        document.addEventListener("DOMContentLoaded", callbackFunction);
}
const DispatchPage = (props) => {
    const handleMousePositionChange = (e) => {
        lastMouseDownPosition = { x: e.clientX, y: e.clientY, };
    };
    useEventListener("mousemove", handleMousePositionChange);
    //@ts-ignore
    window.pageType = "legacy-dispatch";
    const openWindows = useSelector((state) => state.dispatch.openWindows);
    const openModals = useSelector((state) => state.dispatch.openModals);
    const dragDropContext = useContext(DndContext);
    const dragDropDispatch = useDispatch();
    const keyGen = useKeyGen();
    const dispatchDispatch = useDispatch();
    const { theme: contextTheme } = useContext(ThemeContext);
    useEffect(() => {
        const theme = contextTheme;
        dispatchDispatch({
            type: DispatchPageContainerActionTypes.INITIALIZE,
            theme: theme
        });
        import("./scripts").then(() => {
            LegacyScriptLoader.loadScripts([
                "/sl3/scripts/jquery.min.js",
                "/sl3/scripts/jquery.xml2json.js",
                "/sl3/scripts/jquery.menu.js",
                "/sl3/scripts/jquery.multiple.check.select.js",
                "/sl3/scripts/jquery.colorpicker.js",
                "/sl3/scripts/jquery-ui.custom.min.js",
                "/sl3/scripts/jquery.cookie.min.js",
                "/sl3/scripts/jquery-ui-timepicker-addon.js",
                "/sl3/scripts/ol.js",
                "/sl3/scripts/ol-ext.min.js",
                "/sl3/scripts/ol-contextmenu.js",
                "/sl3/scripts/manage-functions-jobschedule.js",
                "/sl3/scripts/chosen.jquery.min.js",
                "/sl3/scripts/manage-functions-messages.js",
                "/sl3/scripts/manage-functions-locations.js",
                "/sl3/scripts/d3.min.js",
                "/sl3/scripts/metricsgraphics.js",
                "/sl3/scripts/manage-functions-shifts.js",
                "/sl3/scripts/moment.js",
                "/sl3/scripts/fullcalendar.min.js",
                "/sl3/scripts/chroma.js",
                "/sl3/scripts/iconConstants.js",
                "/sl3/scripts/tableHeadFixer.js",
                "/sl3/scripts/underscore-min.js",
                "/sl3/scripts/dateformats.js",
                "/sl3/scripts/mousewheel.js",
                "/sl3/scripts/sl-common.js",
                "/sl3/scripts/manage-functions-messages.js",
                "/sl3/scripts/dispatch-splitter.js",
                "/sl3/scripts/orderFsrStates.js",
                "/sl3/scripts/dispatch-filters.js",
                "/sl3/scripts/dispatch-maps.js",
                "/sl3/scripts/dispatch-common.js",
                "/sl3/scripts/dispatch-tables.js",
                "/sl3/scripts/dispatch-functions.js",
                "/sl3/scripts/manage-tables.js",
                "/sl3/scripts/self-dispatch-filters.js",
                "/sl3/scripts/key_shortcut.js",
                "/sl3/scripts/iv-viewer.js",
            ]).then(() => {
                ready(event => {
                    //@ts-ignore
                    window.sl.store = AppStore.store;
                    //@ts-ignore
                    window.sl.actionCreators = Object.assign({}, creators);
                    //@ts-ignore
                    window.sl.mountScheduler = mountScheduler;
                    //@ts-ignore
                    window.sl.unmountScheduler = unmountScheduler;
                    //@ts-ignore
                    window.sl.mountESRIMap = mountESRIMap;
                    //@ts-ignore
                    window.sl.unmountESRIMap = unmountESRIMap;
                    //@ts-ignore
                    //TODO: Remove when ol map is changed to react component.
                    window.sl.renderLegacyPopup = renderLegacyPopup;
                    const getOrderTableDragSource = (order) => {
                        const { id } = order;
                        return {
                            beginDrag(monitor, targetId) {
                                const state = AppStore.store.getState();
                                const { selectedOrderIds, selectedOrders } = state.dispatch;
                                const item = { type: DraggableTypes.order, id, selectedOrderIds, selectedOrders };
                                //region for legacy map interop; remove once it's been refactored into a React component
                                window.store.dispatch({ type: "HANDLE_DRAG_START" });
                                window.sl.setPreDragDropHighlightedElements(JSON.parse(JSON.stringify(window.highlightedElements)));
                                window.enablePopup = false;
                                window.dragOn = true;
                                window.dragType = "o";
                                if (window._isRecommend && window.fsrVehMode === "fsr" && !window.isHoldRecommend) {
                                    $("#" + "f" + "table_refresh").trigger("click"); //refresh table
                                    window.isDragRecommend = true;
                                }
                                //endregion
                                $("#dragdrop").css({
                                    transform: `translate(${lastMouseDownPosition.x}px, ${lastMouseDownPosition.y}px)`,
                                    zIndex: -1,
                                });
                                $("#dragdrop")
                                    .removeClass("fsr")
                                    .addClass("order")
                                    .html("" + item.selectedOrderIds.length);
                                $("#dragdrop").show();
                                dragDropDispatch({
                                    type: DragDropActionTypes.BEGIN,
                                    item: item,
                                });
                                return item;
                            },
                            canDrag(monitor, targetId) {
                                //if you can't dispatch or reassign don't bother dragging; legacy code to check that
                                return $("#order_dispatch").is(":visible") ||
                                    $("#order_dispatch_add").hasClass("act") ||
                                    $("#order_reassign").is(":visible") ||
                                    $("#order_reassign_add").hasClass("act") ||
                                    $("#order_dispatch_reassign").is(":visible") ||
                                    $("#order_dispatch_reassign").hasClass("act");
                            },
                            endDrag(monitor, targetId) {
                                window.dragOn = false;
                                if (window._isRecommend && window.fsrVehMode === "fsr") {
                                    $("#" + "f" + "table_refresh").trigger("click"); //refresh table
                                }
                                $("#dragdrop").hide();
                                dragDropDispatch({
                                    type: DragDropActionTypes.END
                                });
                                window.isHoldRecommend = false;
                                window.isDragRecommend = false;
                            },
                            isDragging(monitor, targetId) {
                                return monitor.isDragging();
                            }
                        };
                    };
                    const connectOrderTableDragBackend = (backEnd, sourceId, element) => {
                        const dragDropPreview = $("#dragdrop")[0];
                        backEnd.connectDragPreview(sourceId, dragDropPreview, {});
                        backEnd.connectDragSource(sourceId, element, {});
                    };
                    const connectFsrTableDropBackend = (backEnd, sourceId, element) => {
                        backEnd.connectDropTarget(sourceId, element, {});
                    };
                    const getFsrTableDropTarget = (selectedFsr, registeredElement) => {
                        if (registeredElement != null) {
                            registeredElement.addEventListener("dragleave", evt => {
                                setTimeout(() => {
                                    $(registeredElement).find("td").removeClass("drop_target");
                                }, 50);
                            });
                        }
                        const throttledDropTargetHover = throttle(() => {
                            $(registeredElement).find("td").addClass("drop_target");
                        }, 50);
                        return {
                            canDrop(monitor, targetId) {
                                const item = monitor.getItem();
                                switch (item.type) {
                                    case DraggableTypes.order: {
                                        return true;
                                    }
                                    default: {
                                        return false;
                                    }
                                }
                            },
                            drop(monitor, targetId) {
                                $("#" + "f" + "table_refresh").trigger("click"); //refresh table
                                const item = monitor.getItem();
                                if (item.type === DraggableTypes.order) {
                                    const state = AppStore.store.getState();
                                    const date = state.dispatch.date;
                                    //@ts-ignore
                                    window.sl.dispatchBridge({
                                        dispatchDate: dateValueToLegacyDateString(date),
                                        fsrs: [selectedFsr],
                                        orders: item.selectedOrders
                                    });
                                }
                            },
                            hover(monitor, targetId) {
                                throttledDropTargetHover();
                            }
                        };
                    };
                    const getMapDropTarget = (targetedElement) => {
                        function toMapRelativePixel(monitor) {
                            const { x: clientX, y: clientY } = monitor.getClientOffset();
                            const { x: elementX, y: elementY } = targetedElement.getBoundingClientRect();
                            return [clientX - elementX, clientY - elementY];
                        }
                        return {
                            canDrop(monitor, targetId) {
                                switch (monitor.getItemType()) {
                                    case DraggableTypes.order: {
                                        return true;
                                    }
                                    default: {
                                        return false;
                                    }
                                }
                            },
                            drop(monitor, targetId) {
                                window.sl.setupDragDrop().doMapDragDrop(toMapRelativePixel(monitor));
                            },
                            hover(monitor, targetId) {
                                window.sl.mapInteractionForHoverWithPixel(toMapRelativePixel(monitor));
                            }
                        };
                    };
                    //@ts-ignore
                    window.dragDropManager = dragDropContext.dragDropManager;
                    //@ts-ignore
                    window.getOrderTableDragSource = getOrderTableDragSource;
                    //@ts-ignore
                    window.connectOrderTableDragBackend = connectOrderTableDragBackend;
                    //@ts-ignore
                    window.getFsrTableDropTarget = getFsrTableDropTarget;
                    //@ts-ignore
                    window.connectFsrTableDropBackend = connectFsrTableDropBackend;
                    window.getMapDropTarget = getMapDropTarget;
                    window.sl.convertLegacyOrder = convertLegacyOrder;
                    window.sl.DispatchResultsType = DispatchResultsType;
                    //@ts-ignore
                    window.apptBooking = apptBooking;
                    //@ts-ignore
                    window.StyleUtils = StyleUtils;
                    //@ts-ignore
                    window.XmlJs = XmlJs;
                    legacyReactInit();
                    init();
                });
            });
        });
    }, []);
    const [displayMap, setDisplayMap] = useState(false);
    const [displayScheduler, setDisplayScheduler] = useState(false);
    const mountESRIMap = () => {
        $("#map_container").find(".ol-viewport").html("");
        setDisplayMap(true);
    };
    const unmountESRIMap = () => {
        setDisplayMap(false);
    };
    const mountScheduler = () => {
        $('#ftable_container').html("");
        setDisplayScheduler(true);
    };
    const unmountScheduler = () => {
        setDisplayScheduler(false);
    };
    const renderMap = () => {
        return (ReactDOM.createPortal((React.createElement(ESRIMapContainer, null)), $("#map_container").find(".ol-viewport")[0]));
    };
    const renderScheduler = () => {
        return (ReactDOM.createPortal((React.createElement(FsrScheduler, null)), $('#ftable_container')[0]));
    };
    return (React.createElement(React.Fragment, null,
        Object.entries(openWindows).map(([id, props]) => {
            return React.createElement(FsrCalendarWindow, Object.assign({ key: props.fsrId }, props));
        }),
        openModals.map(modalData => {
            switch (modalData.type) {
                case ModalTypes.DIALOG:
                    return React.createElement(ModalDialog, Object.assign({ key: keyGen() }, modalData));
                default:
                    return null;
            }
        }),
        React.createElement("div", { className: "dispatch-page", dangerouslySetInnerHTML: page }),
        displayMap ? (renderMap()) : null,
        displayScheduler ? (renderScheduler()) : null));
};
const useKeyGen = () => {
    const key = useRef(1);
    return () => {
        return key.current++;
    };
};
export default DispatchPage;
