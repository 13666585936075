import { TestContainerActionTypes } from "./types";
export const initialState = {
    count: 0
};
export function testContainerReducer(state = initialState, action) {
    switch (action.type) {
        case TestContainerActionTypes.INCREMENT_COUNT: {
            return Object.assign(Object.assign({}, state), { count: state.count + 1 });
        }
        case TestContainerActionTypes.DECREMENT_COUNT: {
            return Object.assign(Object.assign({}, state), { count: state.count - 1 });
        }
        default:
            return state;
    }
}
