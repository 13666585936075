import axios from "axios";
import { Utils } from "../../../../helper/General/Utils";
import * as Querystring from "querystring";
import ConfigJson from "../ConfigJson/ConfigJson";
export var Traccar;
(function (Traccar) {
    Traccar.authenticate = () => {
        return new Promise((resolve, reject) => {
            ConfigJson.getTraccarSettings().then((setting) => {
                const data = Querystring.stringify({
                    "email": atob(setting.var1),
                    "password": atob(setting.var2),
                });
                axios({
                    method: "POST",
                    url: "/avl/api/session",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
                    },
                    data: data
                }).then(rsp => {
                    resolve(true);
                }).catch(e => {
                    console.error(e);
                    resolve(false);
                });
            });
        });
    };
    Traccar.getVehicles = () => {
        return axios({
            method: "GET",
            url: "/avl/api/service_link/vehicles",
            headers: {
                "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
            },
        }).then(rsp => {
            const serverVehicles = rsp.data;
            return serverVehicles.map(serverVehicle => ({
                id: serverVehicle.id,
                buId: serverVehicle.bu_id,
                name: serverVehicle.name,
                uniqueId: serverVehicle.unique_id,
            }));
        });
    };
    Traccar.getBoundVehicles = () => {
        return axios({
            method: "GET",
            url: "/avl/api/service_link/bound_vehicles",
            headers: {
                "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
            },
        }).then(rsp => {
            const serverBoundVehicles = rsp.data;
            return serverBoundVehicles.map(serverBoundVehicle => ({
                id: serverBoundVehicle.vehicleId,
                name: serverBoundVehicle.vehicleName,
                uniqueId: serverBoundVehicle.uniqueDeviceId,
            }));
        });
    };
    Traccar.getDevices = () => {
        return axios({
            method: "GET",
            url: "/avl/api/devices/servicelink",
            headers: {
                "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
            },
        }).then(rsp => {
            const serverDevices = rsp.data;
            return serverDevices.map(serverDevice => (Object.assign({}, serverDevice)));
        });
    };
    Traccar.bindVehicle = (req) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: "/avl/api/service_link/bind/vehicle",
                data: req,
                headers: {
                    "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
                },
            }).then(rsp => {
                resolve(rsp.status === 200);
            }).catch(e => {
                console.error(e);
                resolve(false);
            });
        });
    };
    Traccar.unbindVehicle = (req) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "DELETE",
                url: "/avl/api/service_link/unbind/vehicle",
                data: req,
                headers: {
                    "X-ServiceLink-Auth": Utils.getCookieByName("X-ServiceLink-Auth"),
                },
            }).then(rsp => {
                resolve(rsp.status === 200);
            }).catch(e => {
                console.error(e);
                resolve(false);
            });
        });
    };
})(Traccar || (Traccar = {}));
export default Traccar;
