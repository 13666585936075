import React from "react";
import styled from "styled-components";
import SimpleButton from "../../SimpleButton/SimpleButton";
import moment from "moment";
const StyledMonthPickerWrapper = styled.div `
  height: 15rem;
  width: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: ${props => props.backgroundColor};
`;
const StyledMonthElement = styled.div `
  height: 4rem;
  width: 4rem;
  padding: 0.5rem;
`;
class MonthPicker extends React.Component {
    constructor(props) {
        super(props);
        this.getMonthGrid = () => {
            const monthGrid = [];
            for (let month = 0; month < 12; month++) {
                monthGrid.push(this.getMonthElement(month));
            }
            return monthGrid;
        };
        this.getMonthElement = (month) => {
            const { colorOptions, selectedMonth, displayedMonth, selectedYear, displayedYear, currentTime, monthOptions } = this.props;
            const { color, backgroundColor, selectedColor, selectedBackgroundColor, hoverColor, hoverBackgroundColor, todayColor, todayBackgroundColor } = colorOptions;
            const monthObject = monthOptions[month];
            const textProps = {
                iconText: monthObject.monthShortName,
                height: "1.2rem",
                width: "2.4rem",
                textAlign: "center",
                fontWeight: 400
            };
            const currentYear = moment(currentTime).year();
            const currentMonth = moment(currentTime).month();
            const isCurrentYear = currentYear === displayedYear;
            const isSelectedYear = displayedYear === selectedYear;
            const isSelectedMonth = selectedMonth === month && isSelectedYear;
            const isCurrentMonth = currentMonth === month && isCurrentYear;
            const normalOptionalProps = {
                backgroundColor: isCurrentMonth
                    ? todayBackgroundColor
                    : isSelectedMonth
                        ? selectedBackgroundColor
                        : backgroundColor,
                borderColor: isCurrentMonth
                    ? isSelectedMonth
                        ? selectedBackgroundColor
                        : backgroundColor
                    : isSelectedMonth
                        ? selectedBackgroundColor
                        : backgroundColor,
                textColor: isCurrentMonth ? todayColor : isSelectedMonth ? selectedColor : color
            };
            const hoveringOptionalProps = {
                backgroundColor: isCurrentMonth
                    ? todayBackgroundColor
                    : isSelectedMonth
                        ? selectedBackgroundColor
                        : hoverBackgroundColor,
                borderColor: isCurrentMonth
                    ? isSelectedMonth
                        ? selectedBackgroundColor
                        : hoverBackgroundColor
                    : isSelectedMonth
                        ? selectedBackgroundColor
                        : hoverBackgroundColor,
                textColor: isCurrentMonth ? todayColor : isSelectedMonth ? selectedColor : hoverColor
            };
            const mouseDownOptionalProps = {
                backgroundColor: isCurrentMonth ? todayBackgroundColor : selectedBackgroundColor,
                borderColor: selectedBackgroundColor,
                textColor: isCurrentMonth ? todayColor : selectedColor
            };
            const handleMonthClick = (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.props.handleMonthClick(month);
            };
            return (React.createElement(StyledMonthElement, { className: "month-element" },
                React.createElement(SimpleButton, { className: "month-element-button", textProps: textProps, color: color, backgroundColor: backgroundColor, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, borderRadius: "0.2rem", borderWidth: "0.5rem", padding: "0.9rem 0.3rem", buttonClick: handleMonthClick })));
        };
    }
    render() {
        return (React.createElement(StyledMonthPickerWrapper, Object.assign({ className: "month-picker" }, this.props.colorOptions), this.getMonthGrid()));
    }
}
export default MonthPicker;
