import React, { useState } from "react";
import styled from "styled-components";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";
import SwitchInput from "../../../Input/SwitchInput/SwitchInput";
export const StyledMultiSelectFilterRow = styled.div `
  height: max-content;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const MultiSelectFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, selectableValues, handleFilterChange, } = props;
    const [selectedValues, setSelectedValues] = useState((initialFilter === null || initialFilter === void 0 ? void 0 : initialFilter.$in) || []);
    const [switchValue, setSwitchValue] = useState((initialFilter === null || initialFilter === void 0 ? void 0 : initialFilter.$in) ? initialFilter.$in.includes("0") : false);
    const handleChange = (selectedValues, switchValue) => {
        if (switchValue) {
            if (filterKey === "AREA_ID") {
                selectedValues.push("0");
            }
        }
        const hasSelected = selectedValues.length > 0;
        if (filterKey === "JOB_CODE_ID1|JOB_CODE_ID2|JOB_CODE_ID3") {
            const filter = {
                $or: [
                    {
                        ["JOB_CODE_ID1"]: {
                            $in: [...selectedValues],
                        }
                    },
                    {
                        ["JOB_CODE_ID2"]: {
                            $in: [...selectedValues],
                        }
                    },
                    {
                        ["JOB_CODE_ID3"]: {
                            $in: [...selectedValues],
                        }
                    }
                ]
            };
            handleFilterChange({
                filterObject: filter,
                key: filterKey,
            });
        }
        else {
            const filterObject = hasSelected ? {
                [filterKey]: {
                    $in: [...selectedValues],
                }
            } : null;
            handleFilterChange({
                filterObject: filterObject,
                key: filterKey,
            });
        }
    };
    const handleMultiSelectChange = (e) => {
        setSelectedValues(prevState => {
            const selectedValues = e.selected.map(value => value.key).filter(string => string !== "0");
            handleChange(selectedValues, switchValue);
            return selectedValues;
        });
    };
    const handleSwitchChange = (value) => {
        setSwitchValue(prevstate => {
            handleChange(selectedValues, value);
            return value;
        });
    };
    const handleClearFilter = () => {
        setSelectedValues([]);
        handleFilterChange({
            filterObject: null,
            key: filterKey,
        });
    };
    const multiSelectData = Object.entries(selectableValues).map(([id, value]) => {
        const data = {
            key: id,
            displayedText: value.name,
        };
        return data;
    });
    const renderCheckbox = filterKey === "AREA_ID";
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledMultiSelectFilterRow, null,
            renderCheckbox ? (React.createElement(SwitchInput, { startingValue: switchValue, label: "Secondary Areas", width: 170, handleChange: handleSwitchChange })) : null,
            React.createElement(MultiSelect, { multiSelectData: multiSelectData, multiSelectUpdated: handleMultiSelectChange, selectedKeys: selectedValues }))));
};
export default MultiSelectFilterRow;
