import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryLinking;
(function (InventoryLinking) {
    InventoryLinking.LinkingServiceName = "linking";
    let LinkingActionName;
    (function (LinkingActionName) {
        LinkingActionName["CONTAINER_LINK"] = "container/link";
        LinkingActionName["CONTAINER_DELINK"] = "container/delink";
        LinkingActionName["INVENTORY_LINK"] = "inventory/link";
    })(LinkingActionName = InventoryLinking.LinkingActionName || (InventoryLinking.LinkingActionName = {}));
    function containerLinkRequestById(sourceId, targetId) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.CONTAINER_LINK,
            request: {
                source: {
                    usePath: false,
                    targetId: sourceId
                },
                target: {
                    usePath: false,
                    targetId: targetId
                }
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.containerLinkRequestById = containerLinkRequestById;
    function containerLinkRequestByPath(sourceContainerPath, targetContainerPath) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.CONTAINER_LINK,
            request: {
                source: {
                    usePath: true,
                    containerPath: sourceContainerPath
                },
                target: {
                    usePath: true,
                    containerPath: targetContainerPath
                }
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.containerLinkRequestByPath = containerLinkRequestByPath;
    function containerDelinkRequestById(targetId) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.CONTAINER_DELINK,
            request: {
                targetId: targetId,
                usePath: false
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.containerDelinkRequestById = containerDelinkRequestById;
    function containerDelinkRequestByPath(containerPath) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.CONTAINER_DELINK,
            request: {
                containerPath: containerPath,
                usePath: true
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.containerDelinkRequestByPath = containerDelinkRequestByPath;
    function inventoryLinkRequestById(sourceItemId, targetContainerId) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.INVENTORY_LINK,
            request: {
                source: {
                    usePath: false,
                    targetId: sourceItemId
                },
                target: {
                    usePath: false,
                    targetId: targetContainerId
                }
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.inventoryLinkRequestById = inventoryLinkRequestById;
    function inventoryLinkRequestByPath(sourceItemPath, sourceItemId, targetContainerPath) {
        const request = {
            service: InventoryLinking.LinkingServiceName,
            action: LinkingActionName.INVENTORY_LINK,
            request: {
                source: {
                    usePath: true,
                    containerPath: sourceItemPath,
                    item_id: sourceItemId
                },
                target: {
                    usePath: true,
                    containerPath: targetContainerPath
                }
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinking.inventoryLinkRequestByPath = inventoryLinkRequestByPath;
})(InventoryLinking || (InventoryLinking = {}));
