import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleCheckbox from "../../../../../display/components/SimpleCheckbox/SimpleCheckbox";
import SimpleButton from "../../../../../display/components/SimpleButton/SimpleButton";
import moment from "moment";
const StyledMessageResenderMessageRow = styled.div `
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #eeeeee;
  border-bottom: 1px solid white;
`;
class MessageResenderMessageRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleClick = (event) => {
            this.props.handleMessageRowClick({
                messageId: this.props.localQueueMessage.id
            });
        };
    }
    render() {
        const orderNumber = (this.props.localQueueMessage.order_number || this.props.localQueueMessage.order_number !== "") ? this.props.localQueueMessage.order_number : "unknown order";
        const timestamp = moment(this.props.localQueueMessage.timestamp).format(userSettings.display_date_format + " HH:mm");
        const displayedText = `
            ${orderNumber}, 
            ${this.props.localQueueMessage.function}, 
            ${timestamp}`;
        return (React.createElement(StyledMessageResenderMessageRow, { className: "message-resender-message-row" },
            React.createElement(SimpleCheckbox, { id: this.props.localQueueMessage.id, wrapperPadding: "0.1rem", onCheckboxChangeHandler: this.props.handleCheckboxChange }),
            React.createElement(SimpleButton, { className: "message-resender-message-row", textProps: {
                    iconText: displayedText,
                    height: "1.2rem",
                    width: "calc(100% - 1rem)",
                    textAlign: "left",
                    fontWeight: "normal"
                }, padding: "0.4rem 0.5rem", color: this.props.localQueueMessage.send_failed ? this.theme.palette.red : this.theme.palette.black, backgroundColor: "#EEEEEE", buttonClick: this.handleClick })));
    }
}
export default MessageResenderMessageRow;
