import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import Dropdown from "../../../../../Dropdown/Dropdown";
import SimpleButton from "../../../../../SimpleButton/SimpleButton";
import { FilterUtils } from "../../../../../../../helper/General/FilterUtils";
import { FILTER_ROW_TYPE } from "../../FilterRow";
var J_FILTER_OPERATORS = FilterUtils.J_FILTER_OPERATORS;
import { Utils } from "../../../../../../../helper/General/Utils";
const StyledStringFilterRow = styled.div `
  height: 4.1rem;
  width: 47.4rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const StyledDropdownWrapper = styled.div `
  height: 2rem;
  padding: 1.05rem 0;
  width: 15rem;
`;
const StyledSeparator = styled.div `
  height: 4.1rem;
  width: 1rem;
`;
const StyledInputWrapper = styled.div `
  height: 2.6rem;
  padding: 0.75rem 0;
  width: 20rem;
`;
const StyledStringFilterInput = styled.input `
  height: 2rem;
  width: 19.4rem;
  border-width: 0.2rem;
  padding: 0.1rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${props => props.palette.black};
  user-select: none;
`;
const StyledButtonAndIsAppliedWrapper = styled.div `
  height: 4.1rem;
  width: 10.4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;
const StyledIsApplied = styled.div `
  height: 1.2rem;
  width: 10rem;
  padding: 0.2rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${props => props.isApplied === null || (!props.operator && !props.filterString) ? props.palette.black : props.palette.white};
  text-align: center;
  background-color: ${props => {
    const { isApplied, filterString, operator } = props;
    if (isApplied === null || (!operator && !filterString)) {
        return props.palette.neutralLight;
    }
    else {
        if (isApplied) {
            return props.palette.green;
        }
        else {
            return props.palette.red;
        }
    }
}};
  user-select: none;
`;
export var STRING_FILTER_ROW_OPTIONS;
(function (STRING_FILTER_ROW_OPTIONS) {
    STRING_FILTER_ROW_OPTIONS["EQUALS"] = "EQUALS";
    STRING_FILTER_ROW_OPTIONS["NOT_EQUALS"] = "NOT_EQUALS";
    STRING_FILTER_ROW_OPTIONS["CONTAINS"] = "CONTAINS";
    STRING_FILTER_ROW_OPTIONS["NOT_CONTAINS"] = "NOT_CONTAINS";
    STRING_FILTER_ROW_OPTIONS["STARTS_WITH"] = "START_WITH";
    STRING_FILTER_ROW_OPTIONS["ENDS_WITH"] = "ENDS_WITH";
})(STRING_FILTER_ROW_OPTIONS || (STRING_FILTER_ROW_OPTIONS = {}));
class StringFilterRow extends React.Component {
    constructor(props) {
        super(props);
        this.stringFilterRowOptions = [
            {
                key: STRING_FILTER_ROW_OPTIONS.EQUALS,
                text: "Equals to"
            },
            {
                key: STRING_FILTER_ROW_OPTIONS.NOT_EQUALS,
                text: "Not Equals to"
            },
            {
                key: STRING_FILTER_ROW_OPTIONS.CONTAINS,
                text: "Contains"
            },
            {
                key: STRING_FILTER_ROW_OPTIONS.NOT_CONTAINS,
                text: "Not contains"
            },
            {
                key: STRING_FILTER_ROW_OPTIONS.STARTS_WITH,
                text: "Starts with"
            },
            {
                key: STRING_FILTER_ROW_OPTIONS.ENDS_WITH,
                text: "Ends with"
            }
        ];
        this.theme = getTheme();
        this.handleInputChange = (event) => {
            const newFilterString = event.currentTarget ? event.currentTarget.value || "" : "";
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { filterString: newFilterString, isApplied: false })));
        };
        this.handleInputKeyDown = (event) => {
            if (event.key === "Enter") {
                this.handleApplyFilter();
                event.preventDefault();
                event.stopPropagation();
            }
        };
        this.keyToOperatorMap = {
            [STRING_FILTER_ROW_OPTIONS.EQUALS]: J_FILTER_OPERATORS.equal,
            [STRING_FILTER_ROW_OPTIONS.NOT_EQUALS]: J_FILTER_OPERATORS.notEqual,
            [STRING_FILTER_ROW_OPTIONS.CONTAINS]: J_FILTER_OPERATORS.contains,
            [STRING_FILTER_ROW_OPTIONS.NOT_CONTAINS]: J_FILTER_OPERATORS.notContains,
            [STRING_FILTER_ROW_OPTIONS.STARTS_WITH]: J_FILTER_OPERATORS.startsWith,
            [STRING_FILTER_ROW_OPTIONS.ENDS_WITH]: J_FILTER_OPERATORS.endsWith
        };
        this.operatorToKeyMap = Object.entries(this.keyToOperatorMap).reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [value]: key })), {});
        this.getOperatorFromKey = (key) => {
            switch (key) {
                case STRING_FILTER_ROW_OPTIONS.EQUALS:
                    return J_FILTER_OPERATORS.equal;
                case STRING_FILTER_ROW_OPTIONS.NOT_EQUALS:
                    return J_FILTER_OPERATORS.notEqual;
                case STRING_FILTER_ROW_OPTIONS.CONTAINS:
                    return J_FILTER_OPERATORS.contains;
                case STRING_FILTER_ROW_OPTIONS.NOT_CONTAINS:
                    return J_FILTER_OPERATORS.notContains;
                case STRING_FILTER_ROW_OPTIONS.STARTS_WITH:
                    return J_FILTER_OPERATORS.startsWith;
                case STRING_FILTER_ROW_OPTIONS.ENDS_WITH:
                    return J_FILTER_OPERATORS.endsWith;
                default:
                    console.error(`Key: ${key} does not have a matching filter operator.`);
            }
        };
        this.handleOperatorChange = (event) => {
            const key = event.key;
            const newOperator = this.getOperatorFromKey(key);
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { operator: newOperator, isApplied: false })));
        };
        this.handleApplyFilter = () => {
            const { filterString, operator } = this.state;
            if (filterString && operator) {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isApplied: true })));
                this.props.filterRowActions.stringFilterRowApplyFilter({
                    filterString: filterString,
                    operator: operator,
                    index: this.props.index,
                    type: FILTER_ROW_TYPE.STRING,
                    isApplied: true
                });
            }
        };
        this.getIsAppliedText = () => {
            const { isApplied, filterString, operator } = this.state;
            if (isApplied === null || (!operator && !filterString)) {
                return "No filter selected";
            }
            else {
                if (isApplied) {
                    return "Filter applied";
                }
                else {
                    return "Filter not applied";
                }
            }
        };
        this.state = Utils.deepClone(props.filterRowState);
    }
    render() {
        return (React.createElement(StyledStringFilterRow, { className: "string-filter-row-wrapper" },
            React.createElement(StyledDropdownWrapper, { className: "sting-filter-row-dropdown" },
                React.createElement(Dropdown, { options: this.stringFilterRowOptions, selectedKey: this.operatorToKeyMap[this.state.operator], handleChange: this.handleOperatorChange })),
            React.createElement(StyledSeparator, { className: "string-filter-separator" }),
            React.createElement(StyledInputWrapper, { className: "string-filter-input-wrapper" },
                React.createElement(StyledStringFilterInput, Object.assign({ className: "string-filter-row-input", placeholder: "Enter string to filter...", value: this.state.filterString, onChange: this.handleInputChange, onKeyPress: this.handleInputKeyDown }, this.theme))),
            React.createElement(StyledSeparator, { className: "string-filter-separator" }),
            React.createElement(StyledButtonAndIsAppliedWrapper, { className: "string-filter-button-wrapper" },
                React.createElement(StyledIsApplied, Object.assign({ className: "string-filter-is-applied" }, this.props, this.state, this.theme), this.getIsAppliedText()),
                React.createElement(SimpleButton, { className: "string-filter-button", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, padding: "0.3rem 1.1rem", textProps: {
                        width: "8rem",
                        height: "1.2rem",
                        iconText: "Apply Filter",
                        textAlign: "center",
                        fontWeight: "bold"
                    }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.handleApplyFilter }))));
    }
}
export default StringFilterRow;
