import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleList from "../../SimpleList/SimpleList";
import MultiSelectLeftPanelControl from "./MultiSelectLeftPanelControl/MultiSelectLeftPanelControl";
const MultiSelectLeftPanelWrapper = styled.div `
  width: 20rem;
  height: 22.2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class MultiSelectLeftPanel extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        const { unselected, pendingSelect, backgroundColor, borderColor, selectColor, fontColor, handlePendingSelectChange, handlePendingSelectInvoke, handlePersistPendingSelect, handleAllSelect, selectedPerformClearSelect, handleSelectedFinishClearSelect, showOpposite, handleToggleOpposite, handleSearchBarChange } = this.props;
        return (React.createElement(MultiSelectLeftPanelWrapper, null,
            React.createElement(MultiSelectLeftPanelControl, { handlePersistPendingSelect: handlePersistPendingSelect, handleAllSelect: handleAllSelect, showOpposite: showOpposite, handleToggleOpposite: handleToggleOpposite, handleSearchBarChange: handleSearchBarChange }),
            React.createElement(SimpleList, { rows: unselected, height: "20rem", rowHeight: "1.8rem", width: "20rem", borderColor: borderColor, backgroundColor: backgroundColor, selectColor: selectColor, fontColor: fontColor, handleSelectionChange: handlePendingSelectChange, handleInvoke: handlePendingSelectInvoke, performClearSelect: selectedPerformClearSelect, handleFinishClearSelect: handleSelectedFinishClearSelect })));
    }
}
export default MultiSelectLeftPanel;
