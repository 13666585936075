import { IconService } from "../Icon/IconService";
export var TableUtils;
(function (TableUtils) {
    TableUtils.CONTEXT_MENU_SHARED_ACTIONS = {
        HISTORY: {
            identifier: 1000,
            displayedText: "History",
            iconName: IconService.INVENTORY_TABLE_ICONS.HISTORY
        },
        MODIFY_OWNER: {
            identifier: 1001,
            displayedText: "Modify Owner",
            iconName: IconService.INVENTORY_TABLE_ICONS.MODIFY_OWNER
        },
        MODIFY_PARENT_CONTAINER: {
            identifier: 1002,
            displayedText: "Modify Container",
            iconName: IconService.INVENTORY_TABLE_ICONS.MODIFY_PARENT_CONTAINER
        }
    };
    TableUtils.CONTEXT_MENU_FILE_ACTIONS = {
        DOWNLOAD: {
            identifier: 1003,
            displayedText: "Download to .csv",
            iconName: IconService.INVENTORY_TABLE_ICONS.DOWNLOAD
        },
        UPLOAD: {
            identifier: 1004,
            displayedText: "Upload .csv",
            iconName: IconService.INVENTORY_TABLE_ICONS.UPLOAD
        },
        DOWNLOAD_HISTORY: {
            identifier: 1005,
            displayedText: "History to .csv",
            iconName: IconService.INVENTORY_TABLE_ICONS.HISTORY
        }
    };
})(TableUtils || (TableUtils = {}));
