import React from "react";
import styled from "styled-components";
const StyledSchedulerCell = styled.div ``;
class SchedulerCell extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(StyledSchedulerCell, Object.assign({ className: "table-scheduler-cell" }, this.props));
    }
}
export default SchedulerCell;
