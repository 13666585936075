import React from "react";
import styled from "styled-components";
import SimpleButton from "../../../SimpleButton/SimpleButton";
import { getTheme } from "office-ui-fabric-react";
const StyledMultiSelectRightPanelControl = styled.div `
  width: 20rem;
  height: 1.8rem;
  padding: 0.2rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const ButtonSeparator = styled.div `
  height: 1.8rem;
  width: 0.5rem;
`;
const CountText = styled.div `
  height: 1.4rem;
  width: 14.9rem;
  padding: 0.2rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: bold;
  color: ${props => props.fontColor || props.palette.black};
  user-select: none;
`;
class MultiSelectRightPanelControl extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        const color = this.props.color || this.theme.palette.black;
        const backgroundColor = this.props.backgroundColor || this.theme.palette.white;
        const { totalCount, selectedCount, handlePersistPendingUnselect, handleAllUnselect } = this.props;
        return (React.createElement(StyledMultiSelectRightPanelControl, null,
            React.createElement(SimpleButton, { className: "unselect-icon", iconProps: {
                    iconName: "SL-Arrow-DoubleLeft",
                    size: "1.2rem"
                }, color: color, backgroundColor: backgroundColor, borderRadius: "0.2rem", padding: "0.2rem", iconOffsetY: "-0.1rem", borderWidth: "0.1rem", buttonClick: handleAllUnselect }),
            React.createElement(ButtonSeparator, null),
            React.createElement(SimpleButton, { className: "unselect-all-icon", iconProps: {
                    iconName: "SL-Arrow-Left",
                    size: "1.2rem"
                }, color: color, backgroundColor: backgroundColor, borderRadius: "0.2rem", padding: "0.2rem", iconOffsetY: "-0.1rem", borderWidth: "0.1rem", buttonClick: handlePersistPendingUnselect }),
            React.createElement(CountText, Object.assign({ className: "total-selected-count-text" }, this.props, this.theme), `${selectedCount} of ${totalCount} Selected.`)));
    }
}
export default MultiSelectRightPanelControl;
