import { InventoryScreenContainerActionTypes } from "./types";
export const initialState = {
    titleText: "",
    type: "",
    name: "",
    isVisible: false,
    screenData: null
};
export function inventoryScreenContainerReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryScreenContainerActionTypes.SET_SCREEN_CONTAINER_STATE: {
            return action.inventoryScreenContainerState;
        }
        default:
            return state;
    }
}
