import { call, put, select, takeEvery } from "redux-saga/effects";
import { InventoryScreenContainerActionTypes } from "./types";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { MAX_RESULT_PER_REQUEST } from "../../../helper/WebSocket/WebSocketService";
import { InventoryRead } from "../../api/WebSockets/Inventory/Read";
import { InventorySchemaService } from "../../../helper/InventorySchema/InventorySchemaService";
var J_FILTER_JOIN_OPERATORS = FilterUtils.J_FILTER_JOIN_OPERATORS;
export const inventoryScreenContainerSagas = {
    openScreenSaga: takeEvery(InventoryScreenContainerActionTypes.OPEN_SCREEN, openScreenSaga),
    closeScreenSaga: takeEvery(InventoryScreenContainerActionTypes.CLOSE_SCREEN, closeScreenSaga),
    unverifiedToQuarantinedScreenUpdateSearchSaga: takeEvery(InventoryScreenContainerActionTypes.UNVERIFIED_TO_QUARANTINED_SCREEN_UPDATE_SEARCH, unverifiedToQuarantinedScreenUpdateSearchSaga)
};
function* openScreenSaga(action) {
    yield put({
        type: InventoryScreenContainerActionTypes.SET_SCREEN_CONTAINER_STATE,
        inventoryScreenContainerState: {
            isVisible: true,
            titleText: action.titleText,
            type: action.screenType,
            name: action.screenName,
            screenData: action.screenData
        }
    });
}
function* closeScreenSaga(action) {
    yield put({
        type: InventoryScreenContainerActionTypes.SET_SCREEN_CONTAINER_STATE,
        inventoryScreenContainerState: {
            isVisible: false,
            titleText: "",
            type: "",
            name: "",
            screenData: null
        }
    });
}
function* unverifiedToQuarantinedScreenUpdateSearchSaga(action) {
    const inventoryScreenContainerState = yield select(getInventoryScreenContainerState);
    const searchField = "OpenIndex1";
    const searchStrings = action.searchString.split(",").map(string => string.trim());
    const searchFieldFilters = searchStrings.map(string => {
        return {
            field: searchField,
            operator: FilterUtils.J_FILTER_OPERATORS.equal,
            value: string
        };
    });
    const filterObject = FilterUtils.filterToJFilter(searchFieldFilters, searchFieldFilters.length > 1 ? J_FILTER_JOIN_OPERATORS.or : J_FILTER_JOIN_OPERATORS.and);
    const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, 0, MAX_RESULT_PER_REQUEST, [], filterObject);
    const inventoryServerItemList = inventoriesQueryResponse.response || [];
    const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
    yield put({
        type: InventoryScreenContainerActionTypes.SET_SCREEN_CONTAINER_STATE,
        inventoryScreenContainerState: Object.assign(Object.assign({}, inventoryScreenContainerState), { screenData: Object.assign(Object.assign({}, inventoryScreenContainerState.screenData), { selectedItems: inventoryItemList }) })
    });
}
function getInventoryScreenContainerState(store) {
    return store.inventory.inventoryScreenContainer;
}
export default inventoryScreenContainerSagas;
