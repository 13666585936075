import React from "react";
import { StyleUtils } from "../../../../../../../../../helper/Style/StyleUtils";
import styled from "styled-components";
import SimpleIcon from "../../../../../../../SimpleIcon/SimpleIcon";
const StyledIconCell = styled.div `
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  background-color: ${props => props.color};
  overflow: visible;
  border-style: solid;
  border-color: ${props => (props.isSelected || props.isAboveSelected ? props.selectedColor : props.color)};
  border-width: ${props => `${StyleUtils.getCssPixelString(props.topBorderWidth)} 0 0 0`};
`;
const StyledIconCellIconsWrapper = styled.div `
  width: ${props => `calc(${StyleUtils.getCssPixelString(props.width)} + ${StyleUtils.getCssPixelString(props.tableCellData.indicatorCircleData ? props.tableCellData.indicatorCircleData.indicatorCircleSize : "0px")})`};
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
const StyledIconCellMainIconWrapper = styled.div `
  width: ${props => StyleUtils.getCssPixelString(props.tableCellData.iconWidth)};
  height: ${props => StyleUtils.getCssPixelString(props.tableCellData.iconHeight)};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.tableCellData.iconPadding)};
`;
class IconCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onLeftClickHandler = (event) => {
            this.props.handleLeftClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
        this.onRightClickHandler = (event) => {
            this.props.handleRightClick(event.pageX, event.pageY, this.props.cellIndex, this.props.rowId, event.ctrlKey, event.shiftKey, event.altKey);
        };
    }
    render() {
        //icon-CommCircle
        const { tableCellData, color, height, width } = this.props;
        const { iconName, iconHeight, iconWidth, iconPadding, iconColor, indicatorCircleData } = tableCellData;
        return (React.createElement(StyledIconCell, Object.assign({ className: "table-icon-cell", onClick: this.onLeftClickHandler, onContextMenu: this.onRightClickHandler }, this.props),
            React.createElement(StyledIconCellIconsWrapper, Object.assign({ className: "table-icon-cell-icons-wrapper" }, this.props),
                React.createElement(StyledIconCellMainIconWrapper, Object.assign({ className: "table-icon-cell-main-icon-wrapper" }, this.props),
                    React.createElement(SimpleIcon, { iconName: iconName, color: iconColor, size: "", width: iconWidth, height: iconHeight })),
                indicatorCircleData ? (React.createElement(SimpleIcon, { iconName: "CommCircle", color: indicatorCircleData.indicatorCircleColor, size: StyleUtils.getCssPixelString(indicatorCircleData.indicatorCircleSize), offsetX: -StyleUtils.getCssPixelString(indicatorCircleData.indicatorCircleSize), offsetY: `-0.1rem` })) : null)));
    }
}
export default IconCell;
