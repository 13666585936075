import { InventoryInitializationActionTypes } from "./types";
export function inventoryInitialize(theme) {
    return {
        type: InventoryInitializationActionTypes.INVENTORY_INITIALIZE,
        theme: theme
    };
}
export function inventorySetGlobalState(state) {
    return {
        type: InventoryInitializationActionTypes.INVENTORY_SET_GLOBAL_STATE,
        globalState: state
    };
}
