import React, { useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import { useInterval } from "../../hooks/General";
export const StyledLoadingBar = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;
export const LoadingSection = styled.div `
  height: 100%;
  width: ${props => `${Math.min(100, props.isLoadingComplete ? 100 : props.percentLoaded)}%`};
  background-color: ${props => props.isLoadingComplete ? props.loadedColor : props.loadingColor};
  transition: background-color 500ms;
`;
export const LoadedSection = styled.div `
  height: 100%;
  width: ${props => `${Math.max(0, props.isLoadingComplete ? 0 : 100 - props.percentLoaded)}%`};
  background-color: ${props => props.loadedColor};
`;
const LoadingBar = (props) => {
    const [percentLoaded, setPercentLoaded] = useState(0);
    const [activelyLoading, setActivelyLoading] = useState(false);
    const { height = 3, loadedColor = "white", loadingColor = "#D62C1B", updateMillis = 200, updateWidthPercentage = 1, stuckPercentage = 99, isLoadingComplete } = props;
    useInterval(() => {
        if (activelyLoading) {
            if (isLoadingComplete) {
                setActivelyLoading(false);
                setPercentLoaded(0);
            }
            else {
                setPercentLoaded((prevState) => {
                    return Math.min(stuckPercentage, prevState + updateWidthPercentage);
                });
            }
        }
        else {
            if (!isLoadingComplete) {
                setActivelyLoading(true);
                setPercentLoaded((prevState) => {
                    return updateWidthPercentage;
                });
            }
        }
    }, updateMillis);
    return (React.createElement(StyledLoadingBar, { className: "loading-bar", height: height },
        React.createElement(LoadingSection, { className: "loading-section", loadingColor: loadingColor, loadedColor: loadedColor, percentLoaded: percentLoaded, isLoadingComplete: isLoadingComplete }),
        React.createElement(LoadedSection, { className: "loaded-section", loadedColor: loadedColor, percentLoaded: percentLoaded, isLoadingComplete: isLoadingComplete })));
};
export default LoadingBar;
