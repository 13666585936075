import React from "react";
import styled from "styled-components";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { getTheme, Label } from "office-ui-fabric-react";
const StyledErrorMessageDetailsContent = styled.div `
  width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  max-height: 100%;
  padding: 5px;
  float: left;
`;
class ErrorMessageDetailsContent extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleDataChange = (event) => {
            console.log(event);
            this.props.handleDataChange(event.target.value, this.props.orderErrorDetails.messageId);
        };
    }
    render() {
        return (React.createElement(StyledErrorMessageDetailsContent, { className: "error-message-details-content-container" }, !this.props.orderErrorDetails ? null : (React.createElement("div", null,
            React.createElement(Label, null,
                "Message ID: ",
                this.props.orderErrorDetails.messageId),
            React.createElement(Label, null,
                "Function: ",
                this.props.orderErrorDetails.func),
            React.createElement(Label, null,
                "FSR: ",
                this.props.orderErrorDetails.userName),
            React.createElement(Label, null,
                "Creation Timestamp: ",
                this.props.orderErrorDetails.creationTimeStamp),
            React.createElement(TextField, { label: "Error Message:", className: "error-message-details-error-message", multiline: true, rows: 5, readOnly: true, style: { width: "100%" }, value: this.props.orderErrorDetails.errorMessage }),
            React.createElement(TextField, { label: "Data:", className: "error-message-details-data", multiline: true, rows: 15, style: { width: "100%" }, value: this.props.orderErrorDetails.data, onChange: this.handleDataChange })))));
    }
}
export default ErrorMessageDetailsContent;
