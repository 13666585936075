import { ORDER_HISTORY_FIELDS } from "./ChildValidatorTable";
import styled from "styled-components";
export const HistoryTableCommonStyles = `
  table {
    border-style: solid;
    border-width: 1px;
  }

  [data-field="EVENT"] {
    width: 110px
  }
  [data-field="STATE"] {
    width: 100px
  }
  [data-field="ORIGINATOR"] {
    width: 200px;
    max-width: 200px;
    overflow-x: hidden;
  }
  [data-field="FSR"] {
    width: 200px;
    max-width: 200px;
    overflow-x: hidden;
  }
  [data-field="COMMENTS"] {
    width: 400px;
    max-width: 400px;
    overflow-x: hidden;
  }
  [data-field="TIMESTAMP"] {
    width: 170px
  }
  [data-field="RECEIVED_TIMESTAMP"] {
    width: 170px
  }
  [data-field="ONLINE_OFFLINE"] {
    width: 90px
  }`;
const StyledOrderHistoryRow = styled.tr `
${HistoryTableCommonStyles}
`;
class OrderHistoryRow extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledOrderHistoryRow, null, Object.keys(ORDER_HISTORY_FIELDS).map((field) => {
            return (React.createElement("td", { "data-field": field }, this.props.orderHistoryRowData.getElementsByTagName(field)[0].innerHTML));
        })));
    }
}
export default OrderHistoryRow;
