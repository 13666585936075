export var LoginPageContainerActionTypes;
(function (LoginPageContainerActionTypes) {
    LoginPageContainerActionTypes["INITIALIZE"] = "LOGIN_PAGE_CONTAINER_INITIALIZE";
    LoginPageContainerActionTypes["SET_LOGIN_PAGE_CONTAINER_STATE"] = "SET_LOGIN_PAGE_CONTAINER_STATE";
    LoginPageContainerActionTypes["SET_ACCESS_TOKEN"] = "SET_ACCESS_TOKEN";
    LoginPageContainerActionTypes["PROCESS_TOKEN_RESPONSE"] = "PROCESS_TOKEN_RESPONSE";
    LoginPageContainerActionTypes["HANDLE_LOGIN"] = "HANDLE_LOGIN";
    LoginPageContainerActionTypes["HANDLE_AD_LOGIN"] = "HANDLE_AD_LOGIN";
    LoginPageContainerActionTypes["HANDLE_MSAL_LOGIN"] = "HANDLE_MSAL_LOGIN";
    LoginPageContainerActionTypes["HANDLE_2FA"] = "HANDLE_2FA";
    LoginPageContainerActionTypes["SET_AD_LOGIN"] = "SET_AD_LOGIN";
})(LoginPageContainerActionTypes || (LoginPageContainerActionTypes = {}));
