import React from "react";
export class MessageResenderSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.isDatetime = false;
        this.handleInputChange = (event) => {
            const fieldVal = event.currentTarget ? event.currentTarget.value || "" : "";
            this.props.handleInputChange(fieldVal);
        };
    }
    render() {
        let supportedInputType = this.props.domInputType || "text";
        const defaultValue = this.props.defaultValue ? this.props.defaultValue : "";
        if (supportedInputType.includes("date")) {
            supportedInputType = "text";
            this.isDatetime = true;
        }
        return (React.createElement("div", null,
            React.createElement("label", { htmlFor: this.props.description }, this.props.description),
            React.createElement("input", { name: this.props.description, type: supportedInputType, onChange: this.handleInputChange, defaultValue: defaultValue, readOnly: !this.props.editable })));
    }
    componentDidMount() {
        if (this.isDatetime) {
            const self = this;
            $(`input[name='${this.props.description}']`).datetimepicker({
                dateFormat: userSettings.jquery_date_format,
                altFormat: "yy-mm-dd",
                onClose: function (selectedDate) {
                    self.props.handleInputChange(selectedDate);
                }
            });
        }
    }
}
