import React from "react";
import "./Screen.css";
import InventoryHistoryScreen from "./Inventory/InventoryHistoryScreen/InventoryHistoryScreen";
import { ScreenUtils } from "../../../helper/General/ScreenUtils";
import InventoryInTestToUnavailableScreen from "./Inventory/InventoryStateTransitionScreen/InventoryInTestToUnavailableScreen/InventoryInTestToUnavailableScreen";
import InventoryUnverifiedToQuarantinedScreen from "./Inventory/InventoryStateTransitionScreen/InventoryUnverifiedToQuarantinedScreen/InventoryUnverifiedToQuarantinedScreen";
import ModalScreen from "../ModalScreen/ModalScreen";
import InventoryModifyOwnerScreen from "./Inventory/InventoryModifyOwnerScreen/InventoryModifyOwnerScreen";
import InventoryModifyParentContainerScreen from "./Inventory/InventoryModifyParentContainerScreen/InventoryModifyParentContainerScreen";
import InventoryDamagedToRemovedScreen from "./Inventory/InventoryStateTransitionScreen/InventoryDamagedToRemovedScreen/InventoryDamagedToRemovedScreen";
import InventoryModifyContainerScanScreen from "./Inventory/InventoryModifyContainerScanScreen/InventoryModifyContainerScanScreen";
import InventoryUploadScreen from "./Inventory/InventoryUploadScreen/InventoryUploadScreen";
var SCREEN_TYPES = ScreenUtils.SCREEN_TYPES;
var INVENTORY_SCREEN_NAMES = ScreenUtils.INVENTORY_SCREEN_NAMES;
class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.getScreenFromNameAndType = (type, name, data, actions) => {
            switch (type) {
                case SCREEN_TYPES.INVENTORY:
                    switch (name) {
                        case INVENTORY_SCREEN_NAMES.UPLOAD:
                            return (React.createElement(InventoryUploadScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.HISTORY:
                            return (React.createElement(InventoryHistoryScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.MODIFY_OWNER:
                            return (React.createElement(InventoryModifyOwnerScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.MODIFY_PARENT_CONTAINER:
                            return (React.createElement(InventoryModifyParentContainerScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.MODIFY_CONTAINER_SCAN: {
                            return (React.createElement(InventoryModifyContainerScanScreen, { data: data, actions: actions }));
                        }
                        case INVENTORY_SCREEN_NAMES.UNVERIFIED_TO_QUARANTINED:
                            return (React.createElement(InventoryUnverifiedToQuarantinedScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.IN_TEST_TO_UNAVAILABLE:
                            return (React.createElement(InventoryInTestToUnavailableScreen, { data: data, actions: actions }));
                        case INVENTORY_SCREEN_NAMES.DAMAGED_TO_REMOVED:
                            return (React.createElement(InventoryDamagedToRemovedScreen, { data: data, actions: actions }));
                        default:
                            return React.createElement("div", null);
                    }
                default:
                    return React.createElement("div", null);
            }
        };
    }
    render() {
        const { isVisible, titleText, type, name, minDistanceFromEdge, screenData, screenActions } = this.props;
        return (React.createElement(ModalScreen, { titleText: titleText, isVisible: isVisible, minDistanceFromEdge: minDistanceFromEdge, onDismiss: this.props.screenActions.onDismiss }, this.getScreenFromNameAndType(type, name, screenData, screenActions)));
    }
}
export default Screen;
