export var ModalTypes;
(function (ModalTypes) {
    ModalTypes[ModalTypes["DIALOG"] = 0] = "DIALOG";
})(ModalTypes || (ModalTypes = {}));
export var DispatchPageContainerActionTypes;
(function (DispatchPageContainerActionTypes) {
    DispatchPageContainerActionTypes["INITIALIZE"] = "dispatch.page.INITIALIZE";
    DispatchPageContainerActionTypes["SHOW_CALENDAR"] = "dispatch.page.SHOW_CALENDAR";
    DispatchPageContainerActionTypes["CLOSE_CALENDAR"] = "dispatch.page.CLOSE_CALENDAR";
    DispatchPageContainerActionTypes["SET_DISPATCH_PAGE_CONTAINER_STATE"] = "dispatch.page.SET_DISPATCH_PAGE_CONTAINER_STATE";
    DispatchPageContainerActionTypes["SET_ORDER_FILTER_STRING"] = "dispatch.page.SET_ORDER_FILTER_STRING";
    DispatchPageContainerActionTypes["SET_FSR_FILTER_STRING"] = "dispatch.page.SET_FSR_FILTER_STRING";
    DispatchPageContainerActionTypes["SET_VEHICLE_FILTER_STRING"] = "dispatch.page.SET_VEHICLE_FILTER_STRING";
    DispatchPageContainerActionTypes["SET_CACHED_ORDERS"] = "dispatch.page.SET_CACHED_ORDERS";
    DispatchPageContainerActionTypes["SET_SELECTED_ORDER_IDS"] = "dispatch.page.SET_SELECTED_ORDER_IDS";
    DispatchPageContainerActionTypes["SET_SELECTED_ORDERS"] = "dispatch.page.SET_SELECTED_ORDERS";
    DispatchPageContainerActionTypes["SET_SELECTED_FSR_IDS"] = "dispatch.page.SET_SELECTED_FSR_IDS";
    DispatchPageContainerActionTypes["SET_SELECTED_VEHICLE_IDS"] = "dispatch.page.SET_SELECTED_VEHICLE_IDS";
    DispatchPageContainerActionTypes["SET_DATE"] = "dispatch.page.SET_DATE";
    DispatchPageContainerActionTypes["SET_SETTINGS"] = "dispatch.page.SET_SETTINGS";
    DispatchPageContainerActionTypes["MODIFY_SETTINGS"] = "dispatch.page.MODIFY_SETTINGS";
    DispatchPageContainerActionTypes["SET_IS_DRAGGING"] = "dispatch.page.SET_IS_DRAGGING";
    DispatchPageContainerActionTypes["SET_FSR_VEH_MODE"] = "dispatch.page.SET_FSR_VEH_MODE";
    DispatchPageContainerActionTypes["SET_MAP_EXTENT"] = "dispatch.page.SET_MAP_EXTENT";
    DispatchPageContainerActionTypes["SET_MAP_SIZE"] = "dispatch.page.SET_MAP_SIZE";
    DispatchPageContainerActionTypes["SET_MAP_ICONS"] = "dispatch.page.SET_MAP_ICONS";
    DispatchPageContainerActionTypes["SET_MAP_ICONS_AND_ROUTES"] = "dispatch.page.SET_MAP_ICONS_AND_ROUTES";
    DispatchPageContainerActionTypes["SET_MAP_HOVER_DATA"] = "dispatch.page.SET_MAP_HOVER_DATA";
    DispatchPageContainerActionTypes["SET_TIME_SINCE_LAST_MAP_HOVER_EVENT"] = "dispatch.page.SET_TIME_SINCE_LAST_MAP_HOVER_EVENT";
    DispatchPageContainerActionTypes["SET_MAP_LAYER_TREE"] = "dispatch.page.SET_MAP_LAYER_TREE";
    DispatchPageContainerActionTypes["SET_MAP_LAYER_SETTINGS"] = "dispatch.page.SET_MAP_LAYER_SETTINGS";
    DispatchPageContainerActionTypes["SET_MAP_FINISHED_LOADING"] = "dispatch.page.SET_MAP_FINISHED_LOADING";
    DispatchPageContainerActionTypes["SET_MAP_HOVER_ROUTE_DATA"] = "dispatch.page.SET_MAP_HOVER_ROUTE_DATA";
    DispatchPageContainerActionTypes["UPDATE_MAP_ICONS"] = "dispatch.page.UPDATE_MAP_ICONS";
    DispatchPageContainerActionTypes["UPDATE_EVENT_MAP_ICONS"] = "dispatch.page.UPDATE_EVENT_MAP_ICONS";
    DispatchPageContainerActionTypes["UPDATE_EVENT_MAP_ICONS_ADD"] = "dispatch.page.UPDATE_EVENT_MAP_ICONS";
    DispatchPageContainerActionTypes["UPDATE_EVENT_MAP_ICONS_MODIFY"] = "dispatch.page.UPDATE_EVENT_MAP_ICONS_MODIFY";
    DispatchPageContainerActionTypes["UPDATE_EVENT_MAP_ICONS_DELETE"] = "dispatch.page.UPDATE_EVENT_MAP_ICONS_DELETE";
    DispatchPageContainerActionTypes["HANDLE_MAP_SIZE_CHANGE_EVENT"] = "dispatch.page.HANDLE_MAP_SIZE_CHANGE_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_EXTENT_CHANGE_EVENT"] = "dispatch.page.HANDLE_MAP_EXTENT_CHANGE_EVENT";
    DispatchPageContainerActionTypes["UPDATE_MAP_SELECT"] = "dispatch.page.UPDATE_MAP_SELECT";
    DispatchPageContainerActionTypes["HANDLE_MAP_SELECT_EVENT"] = "dispatch.page.HANDLE_MAP_SELECT_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_UNSELECT_EVENT"] = "dispatch.page.HANDLE_MAP_UNSELECT_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_MULTI_SELECT_EVENT"] = "dispatch.page.HANDLE_MAP_MULTI_SELECT_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_MULTI_SELECT_CTRL_EVENT"] = "dispatch.page.HANDLE_MAP_MULTI_SELECT_CTRL_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_VIEW_MOVE_EVENT"] = "dispatch.page.HANDLE_MAP_VIEW_MOVE_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_DISPLAY_ROUTE_EVENT"] = "dispatch.page.HANDLE_MAP_DISPLAY_ROUTE_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_ADD_ROUTE_EVENT"] = "dispatch.page.HANDLE_MAP_ADD_ROUTE_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_REMOVE_ROUTE_EVENT"] = "dispatch.page.HANDLE_MAP_REMOVE_ROUTE_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_DRAG_EVENT"] = "dispatch.page.HANDLE_MAP_DRAG_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_HOVER_EVENT"] = "dispatch.page.HANDLE_MAP_HOVER_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_HOVER_END_EVENT"] = "dispatch.page.HANDLE_MAP_HOVER_END_EVENT";
    DispatchPageContainerActionTypes["HANDLE_MAP_TREE_DATA_CHANGE_EVENT"] = "dispatch.page.HANDLE_MAP_TREE_DATA_CHANGE_EVENT";
    DispatchPageContainerActionTypes["SET_MAP_DRAG_DATA"] = "dispatch.page.SET_MAP_DRAG_DATA";
    DispatchPageContainerActionTypes["DISPATCH_ORDERS"] = "dispatch.page.DISPATCH_ORDERS";
    DispatchPageContainerActionTypes["SHOW_MODAL_DIALOG"] = "dispatch.page.SHOW_MODAL_DIALOG";
    DispatchPageContainerActionTypes["CLOSE_MODAL_DIALOG"] = "dispatch.page.CLOSE_MODAL_DIALOG";
    DispatchPageContainerActionTypes["SAVE_FILTER"] = "dispatch.page.SAVE_FILTER";
    DispatchPageContainerActionTypes["SET_THEME"] = "dispatch.page.SET_THEME";
})(DispatchPageContainerActionTypes || (DispatchPageContainerActionTypes = {}));
export const setMapExtent = (extent) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_EXTENT,
        extent: extent
    };
};
export const setMapSize = (size) => {
    return {
        type: DispatchPageContainerActionTypes.SET_MAP_SIZE,
        size: size
    };
};
