import { call, put, select, takeEvery } from "redux-saga/effects";
import downloadCsv from "download-csv";
import { InventoryTableContainerActionTypes } from "./types";
import { InventoryRead } from "../../api/WebSockets/Inventory/Read";
import moment from "moment";
import { MAX_RESULT_PER_REQUEST, SERVER_ORIGIN_MESSAGE_ACTION_TYPES, } from "../../../helper/WebSocket/WebSocketService";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { Utils } from "../../../helper/General/Utils";
import { TABLE_CELL_TYPES } from "../../../display/components/Table/TableHeaderAndBodyWrapper/TableRowScrollWrapper/TableRowWrapper/TableRow/TableCell/TableCell";
import { InventorySchemaService } from "../../../helper/InventorySchema/InventorySchemaService";
import { IconService } from "../../../helper/Icon/IconService";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import { InventoryFilterActionTypes } from "../../services/InventoryFilter/types";
import { CONTEXT_MENU_COSTANTS } from "../../../display/components/ContextMenu/ContextMenu";
import { InventoryContextMenuContainerActionTypes, InventoryContextMenuSpecialOptions } from "../InventoryContextMenuContainer/types";
import { InventoryState } from "../../../state/api/WebSockets/Inventory/State";
import { TableUtils } from "../../../helper/General/TableUtils";
import { FsrUtils } from "../../../helper/General/FsrUtils";
import { InventoryHistoryDataType } from "../../../display/components/Screen/Inventory/InventoryHistoryScreen/InventoryHistoryScreen";
import { getContainerHistoryScreenData, getFsrLookupObjects, getOwnerHistoryScreenData, getStateHistoryScreenData } from "../InventoryContextMenuContainer/sagas";
import produce from "immer";
var INVENTORY_TABLE_ICONS = IconService.INVENTORY_TABLE_ICONS;
var INVENTORY_TABLE_COLORS = StyleUtils.INVENTORY_TABLE_COLORS;
var J_FILTER_OPERATORS = FilterUtils.J_FILTER_OPERATORS;
var INVENTORY_TABLE_TOP_BORDER_WIDTH_MULTIPLIER = StyleUtils.INVENTORY_TABLE_TOP_BORDER_WIDTH_MULTIPLIER;
var INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER = StyleUtils.INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER;
var CONTEXT_MENU_SHARED_ACTIONS = TableUtils.CONTEXT_MENU_SHARED_ACTIONS;
var CONTEXT_MENU_FILE_ACTIONS = TableUtils.CONTEXT_MENU_FILE_ACTIONS;
export const inventoryTableContainerSagas = {
    initializeSaga: takeEvery(InventoryTableContainerActionTypes.INITIALIZE, initializeSaga),
    updateSaga: takeEvery(InventoryTableContainerActionTypes.UPDATE, updateSaga),
    getTableRowsDataSaga: takeEvery(InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA, getTableRowsDataSaga),
    setVerticalScrollSaga: takeEvery(InventoryTableContainerActionTypes.SET_VERTICAL_SCROLL, setVerticalScrollSaga),
    setTableJFilterObjectSaga: takeEvery(InventoryTableContainerActionTypes.SET_TABLE_J_FILTER_OBJECT, setTableJFilterObjectSaga),
    handleCellLeftClickSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_CELL_LEFT_CLICK, handleCellLeftClickSaga),
    handleCellRightClickSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_CELL_RIGHT_CLICK, handleCellRightClickSaga),
    handleColumnReorderSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_COLUMN_REORDER, handleColumnReorderSaga),
    handleColumnResizeSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_COLUMN_RESIZE, handleColumnResizeSaga),
    handleSelectAllSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_SELECT_ALL, handleSelectAllSaga),
    openContextMenuSaga: takeEvery(InventoryTableContainerActionTypes.OPEN_CONTEXT_MENU, openContextMenuSaga),
    handleStateChangeSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_STATE_CHANGE, handleStateChangeSaga),
    handleServerOriginMessageSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_SERVER_ORIGIN_MESSAGE, handleServerOriginMessageSaga),
    getTableRowsDataSelectedChangeSaga: takeEvery(InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA_SELECTED_CHANGE, getTableRowsDataSelectedChangeSaga),
    getTableRowsDataStateChangeSaga: takeEvery(InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA_STATE_CHANGE, getTableRowsDataStateChangeSaga),
    getTableRowsDataByIdsSaga: takeEvery(InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA_BY_IDS, getTableRowsDataByIdsSaga),
    tableToCSVSaga: takeEvery(InventoryTableContainerActionTypes.TABLE_TO_CSV, tableToCSVSaga),
    historicalDataToCSVSaga: takeEvery(InventoryTableContainerActionTypes.HISTORICAL_DATA_TO_CSV, historicalDataToCSVSaga),
    handleBackgroundRightClickSaga: takeEvery(InventoryTableContainerActionTypes.HANDLE_BACKGROUND_RIGHT_CLICK, handleBackgroundRightClickSaga)
};
function* initializeSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const bufferRowsCount = 50;
    const scrollThrottleTime = 250;
    const rootFontSize = Utils.getRootFontSize();
    const height = rootFontSize * 2;
    const cacheState = yield select(getCacheSelector);
    const tableColumnsData = getInventoryHeaders(height, rootFontSize, cacheState);
    const rowsCount = yield call(getTotalRowsCount, tableState.tableJFilterObject);
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowVerticalScrollDataProps: Object.assign(Object.assign({}, tableState.tableRowVerticalScrollDataProps), { bufferRowsCount: bufferRowsCount, scrollThrottleTime: scrollThrottleTime }), tableHeaderRowProps: Object.assign(Object.assign({}, tableState.tableHeaderRowProps), { height: height }), tableHeaderRowData: tableColumnsData, tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { totalRowsCount: rowsCount }), tableInitialized: true })
    });
    yield put({ type: InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT });
    yield put({
        type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA,
        verticalScroll: tableState.tableRowVerticalScrollDataProps.verticalScroll
    });
}
function* updateSaga(action) { }
function getTotalRowsCount(jFilterObject) {
    return new Promise((resolve, reject) => {
        InventoryRead.inventoriesQueryCountRequest([], jFilterObject).then(response => {
            resolve(response ? (response.response ? response.response.count : 0) : 0);
        });
    });
}
function* getTableRowsDataSaga(action) {
    const globalState = yield select(getGlobalStateSelector);
    const tableState = yield select(getTableStateSelector);
    const start = action.verticalScroll.start;
    const end = action.verticalScroll.end;
    const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, start, end, [], tableState.tableJFilterObject);
    const inventoryServerItemList = inventoriesQueryResponse.response || [];
    const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
    const ownerIds = inventoryItemList
        .map(item => parseInt(item.ownerId))
        .filter(ownerId => ownerId && ownerId !== -1);
    const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds);
    const uniqueOwnerLookupObjects = yield call(FsrUtils.getFsrLookupObjects, uniqueOwnerIds);
    const inventoryProcessedItemList = inventoryItemList.map(inventoryItem => {
        const ownerId = parseInt(inventoryItem.ownerId);
        const ownerLookupObject = uniqueOwnerLookupObjects.find(object => object.id === ownerId);
        const ownerName = ownerLookupObject ? ownerLookupObject.name : inventoryItem.ownerId;
        return Object.assign(Object.assign({}, inventoryItem), { ownerName: ownerName });
    });
    let extractRowId = iiO => ({
        rowId: iiO.uniqueId,
        dataPoint: iiO
    });
    let stateNameAndIndexToStyle = ({ state: { state: { stateName } } }, idx) => {
        stateName = stateName ? stateName.toUpperCase() : "";
        const stateIconName = INVENTORY_TABLE_ICONS[stateName]; // Map state to an icon
        const backgroundColor = INVENTORY_TABLE_COLORS[stateName]; //Map state to a color
        const backgroundColorWithOpacity = StyleUtils.tableGenerateAlternatingRowColor(INVENTORY_TABLE_COLORS[stateName], idx);
        return { stateIconName, backgroundColor, backgroundColorWithOpacity };
    };
    const inventoryTableContainerState = produce(tableState, draftState => {
        const theme = globalState.theme;
        const startIndex = action.verticalScroll.start;
        const selectedIds = tableState.selectedIds;
        const normalizedDataPointsWithStateAndId = inventoryProcessedItemList.map(extractRowId);
        let lastSelected = false;
        let index = startIndex;
        const rootFontSize = Utils.getRootFontSize();
        const rowHeight = rootFontSize * (INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER + INVENTORY_TABLE_TOP_BORDER_WIDTH_MULTIPLIER);
        const height = rootFontSize * INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER;
        const topBorderWidth = rootFontSize * INVENTORY_TABLE_TOP_BORDER_WIDTH_MULTIPLIER;
        draftState.tableRowWrapperDataProps.tableRowsData = normalizedDataPointsWithStateAndId.map(({ rowId, dataPoint }) => {
            const isSelected = selectedIds.includes(rowId);
            const isAboveSelected = lastSelected;
            lastSelected = isSelected;
            //get the uniqueRowId from object and put in defaults
            const tableCellRowProps = {
                rowId,
                topBorderWidth: topBorderWidth,
                isSelected: isSelected,
                isAboveSelected: isAboveSelected,
                selectedColor: theme.palette.themePrimary
            };
            const { stateIconName, backgroundColor, backgroundColorWithOpacity } = stateNameAndIndexToStyle(dataPoint, index);
            const tableCellsData = getTableCellsData(tableState.tableHeaderRowData, height, backgroundColor, backgroundColorWithOpacity, rootFontSize, theme, stateIconName, 
            // @ts-ignore
            dataPoint);
            index++;
            return {
                height: rowHeight,
                tableCellRowProps: tableCellRowProps,
                tableCellsData: tableCellsData
            };
        });
        draftState.tableRowVerticalScrollDataProps.verticalScroll = action.verticalScroll;
    });
    if (inventoryTableContainerState.tableHeaderRowData.length > 0) {
        yield put({
            type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
            inventoryTableContainerState
        });
    }
}
export function getTableCellsData(columnSchema, height, backgroundColor, backgroundColorWithOpacity, rootFontSize, theme, dataObject) {
    function defaultTransformer(tableCellData) {
        const tableCellCommonProps = {
            cellIndex: tableCellData.index,
            height: height,
            width: tableCellData.width,
            color: tableCellData.index === 0 ? backgroundColor : backgroundColorWithOpacity
        };
        switch (tableCellData.type) {
            case TABLE_CELL_TYPES.FirstCell: {
                tableCellCommonProps.width = rootFontSize * 0.6;
                return Object.assign(Object.assign({ type: TABLE_CELL_TYPES.FirstCell }, tableCellCommonProps), { tableCellData: {
                        topBorderColor: theme.palette.white
                    } });
            }
            case TABLE_CELL_TYPES.IconCell: {
                return Object.assign(Object.assign({ type: TABLE_CELL_TYPES.IconCell }, tableCellCommonProps), { tableCellData: {
                        iconName: "zaDefault",
                        iconHeight: rootFontSize * 1.4,
                        iconWidth: rootFontSize * 1.4,
                        iconPadding: rootFontSize * 0.3,
                        iconColor: theme.palette.neutralDark
                    } });
            }
            case TABLE_CELL_TYPES.TextCell: {
                const displayedText = Utils.getTextFromDataAndValues(dataObject, tableCellData.values, tableCellData.valuesSeparator, tableCellData.valuesPostProcessor);
                tableCellCommonProps.height = tableCellCommonProps.height - rootFontSize * 0.6;
                tableCellCommonProps.width = tableCellCommonProps.width - rootFontSize * 2;
                return Object.assign(Object.assign({ type: TABLE_CELL_TYPES.TextCell }, tableCellCommonProps), { tableCellData: {
                        displayedText: displayedText,
                        fontColor: tableCellData.fontColor || theme.palette.black,
                        textAlign: tableCellData.textAlign || isNaN(displayedText) ? "left" : "right",
                        fontWeight: tableCellData.fontWeight || "normal",
                        fontSize: tableCellData.fontSize || StyleUtils.getCssPixelString(rootFontSize * 1.4),
                        padding: tableCellData.padding ||
                            `${StyleUtils.getCssPixelString(rootFontSize * 0.3)} ${StyleUtils.getCssPixelString(rootFontSize * 1)}`
                    } });
            }
        }
    }
    return columnSchema
        .map(tableCellData => tableCellData.columnDataPostProcessor
        ? tableCellData.columnDataPostProcessor(defaultTransformer(tableCellData), dataObject)
        : defaultTransformer(tableCellData));
}
function* setVerticalScrollSaga(action) {
    const globalState = yield select(getGlobalStateSelector);
    const isLoaded = globalState.initializationComplete;
    if (isLoaded) {
        yield put({
            type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA,
            verticalScroll: action.verticalScroll
        });
    }
}
function getInventoryHeaders(heightParam, rootFontSize, cacheState) {
    const warehouses = cacheState.warehouses;
    const tableColumnData = [
        {
            type: TABLE_CELL_TYPES.FirstCell,
            height: heightParam,
            index: 0,
            width: 0,
            displayedText: "",
            resizable: false,
            reorderable: false,
            values: []
        },
        {
            type: TABLE_CELL_TYPES.IconCell,
            height: heightParam,
            index: 1,
            width: rootFontSize * 2,
            displayedText: "",
            resizable: false,
            reorderable: false,
            values: ["state/state"],
            columnDataPostProcessor(defaultCellOutput, dataPoint) {
                const stateIconName = INVENTORY_TABLE_ICONS[dataPoint.stateName] || "";
                defaultCellOutput.tableCellData.iconName = stateIconName;
                return defaultCellOutput;
            }
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 2,
            width: rootFontSize * 15,
            displayedText: "Id",
            resizable: true,
            reorderable: true,
            values: ["uniqueId"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 3,
            width: rootFontSize * 20,
            displayedText: "Flex Net ID",
            resizable: true,
            reorderable: true,
            values: ["attributes/flexNetId"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 4,
            width: rootFontSize * 20,
            displayedText: "Old Flex Net ID",
            resizable: true,
            reorderable: true,
            values: ["attributes/oldFlexNetId"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 5,
            width: rootFontSize * 20,
            displayedText: "Itron ID",
            resizable: true,
            reorderable: true,
            values: ["attributes/itronId"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 6,
            width: rootFontSize * 20,
            displayedText: "Pallet ID",
            resizable: true,
            reorderable: true,
            values: ["attributes/originatingPallet"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 7,
            width: rootFontSize * 20,
            displayedText: "Box ID",
            resizable: true,
            reorderable: true,
            values: ["attributes/boxId"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 8,
            width: rootFontSize * 15,
            displayedText: "Part Number",
            resizable: true,
            reorderable: true,
            values: ["attributes/partNumber"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 9,
            width: rootFontSize * 15,
            displayedText: "Product Category",
            resizable: true,
            reorderable: true,
            values: ["attributes/productCategory"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 10,
            width: rootFontSize * 10,
            displayedText: "Warehouse",
            resizable: true,
            reorderable: true,
            values: ["attributes/originatingWarehouse"],
            valuesPostProcessor: (s) => {
                const warehouseId = parseInt(s);
                const warehouseObject = warehouses.find(warehouse => warehouse.uniqueId === warehouseId);
                if (warehouseObject) {
                    return warehouseObject.name;
                }
                else {
                    return "";
                }
            }
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 11,
            width: rootFontSize * 15,
            displayedText: "Owner Type",
            resizable: true,
            reorderable: true,
            values: ["ownerType"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 12,
            width: rootFontSize * 15,
            displayedText: "Owner Name",
            resizable: true,
            reorderable: true,
            values: ["ownerName"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 13,
            width: rootFontSize * 15,
            displayedText: "Lat",
            resizable: true,
            reorderable: true,
            values: ["lat"],
            valuesPostProcessor: (s) => {
                return parseFloat(s).toFixed(2);
            }
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 14,
            width: rootFontSize * 15,
            displayedText: "Lon",
            resizable: true,
            reorderable: true,
            values: ["lon"],
            valuesPostProcessor: (s) => {
                return parseFloat(s).toFixed(2);
            }
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 15,
            width: rootFontSize * 15,
            displayedText: "Creation Date",
            resizable: true,
            reorderable: true,
            values: ["creationDate"],
            valuesPostProcessor: Utils.millisDatePostProcessor
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 16,
            width: rootFontSize * 15,
            displayedText: "Box Date",
            resizable: true,
            reorderable: true,
            values: ["attributes/boxDate"],
            valuesPostProcessor: Utils.millisDatePostProcessor
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 17,
            width: rootFontSize * 15,
            displayedText: "Ship Date",
            resizable: true,
            reorderable: true,
            values: ["attributes/shipDate"],
            valuesPostProcessor: Utils.millisDatePostProcessor
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 18,
            width: rootFontSize * 30,
            displayedText: "Comment",
            resizable: true,
            reorderable: true,
            values: ["comment"]
        },
        {
            type: TABLE_CELL_TYPES.TextCell,
            height: heightParam,
            index: 19,
            width: rootFontSize * 15,
            displayedText: "Order Number",
            resizable: true,
            reorderable: true,
            values: ["attributes/orderNumber"]
        }
    ];
    return tableColumnData;
}
function* handleCellLeftClickSaga(action) {
    const tableState = yield select(getTableStateSelector);
    let selectedIds = [];
    if (action.isCtrl) {
        selectedIds = Utils.removeArrayDuplicates([...tableState.selectedIds, action.rowId]);
    }
    else {
        selectedIds = [action.rowId];
    }
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { selectedIds: selectedIds })
    });
    yield put({
        type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA_SELECTED_CHANGE
    });
}
function* handleCellRightClickSaga(action) {
    yield put({
        type: InventoryTableContainerActionTypes.HANDLE_CELL_LEFT_CLICK,
        xPosition: action.xPosition,
        yPosition: action.yPosition,
        cellIndex: action.cellIndex,
        rowId: action.rowId,
        isCtrl: action.isCtrl,
        isShift: action.isShift,
        isAlt: action.isAlt
    });
    yield put({
        type: InventoryTableContainerActionTypes.OPEN_CONTEXT_MENU,
        isBackground: false,
        xPosition: action.xPosition,
        yPosition: action.yPosition
    });
}
function* handleColumnReorderSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const isDestinationDroppable = tableState.tableHeaderRowData[action.destination].reorderable;
    let newTableHeaderRowData = [];
    let newTableRowsData = [];
    if (isDestinationDroppable) {
        newTableHeaderRowData = Utils.moveArrayElements(tableState.tableHeaderRowData, action.source, action.destination);
        newTableRowsData = tableState.tableRowWrapperDataProps.tableRowsData.map(tableRowData => {
            return Object.assign(Object.assign({}, tableRowData), { tableCellsData: Utils.moveArrayElements(tableRowData.tableCellsData, action.source, action.destination) });
        });
    }
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableHeaderRowData: newTableHeaderRowData, tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { tableRowsData: newTableRowsData }) })
    });
}
function* handleColumnResizeSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const rootFontSize = Utils.getRootFontSize();
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableHeaderRowData: tableState.tableHeaderRowData.map(tableHeaderRowData => {
                return tableHeaderRowData.index === action.data.index
                    ? Object.assign(Object.assign({}, tableHeaderRowData), { width: action.data.width }) : tableHeaderRowData;
            }), tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { tableRowsData: tableState.tableRowWrapperDataProps.tableRowsData.map(tableRowData => {
                    return Object.assign(Object.assign({}, tableRowData), { tableCellsData: tableRowData.tableCellsData.map(tableCellData => {
                            return tableCellData.cellIndex === action.data.index
                                ? Object.assign(Object.assign({}, tableCellData), { width: action.data.width - rootFontSize * 2 + rootFontSize * 0.6 }) : tableCellData;
                        }) });
                }) }) })
    });
}
function* handleSelectAllSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const ids = tableState.tableRowWrapperDataProps.tableRowsData.map(data => data.tableCellRowProps.rowId) || [];
    const selectedIds = [...ids];
    // TODO: Set a proper select all instead of just the currently loaded items. This only works for a small amount of items loaded.
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { selectedIds: selectedIds })
    });
    yield put({
        type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA_SELECTED_CHANGE
    });
}
function* setTableJFilterObjectSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const rowsCount = yield call(getTotalRowsCount, action.filterObject);
    if (tableState.tableHeaderRowData.length > 0) {
        yield put({
            type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
            inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { totalRowsCount: rowsCount }), tableJFilterObject: action.filterObject })
        });
        yield put({
            type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA,
            verticalScroll: tableState.tableRowVerticalScrollDataProps.verticalScroll
        });
    }
}
function* openContextMenuSaga(action) {
    const fileContextMenuData = Object.entries(CONTEXT_MENU_FILE_ACTIONS).map(([key, value]) => {
        return {
            identifier: value.identifier,
            displayedText: value.displayedText,
            iconName: value.iconName
        };
    });
    if (action.isBackground) {
        yield put({
            type: InventoryContextMenuContainerActionTypes.OPEN,
            xPosition: action.xPosition,
            yPosition: action.yPosition,
            data: fileContextMenuData,
            previousStates: {}
        });
    }
    else {
        const globalState = yield select(getGlobalStateSelector);
        const tableState = yield select(getTableStateSelector);
        const selectedIds = tableState.selectedIds;
        const idField = globalState.schema.itemsIndexes[0].indexes.find(index => index.value === "id").name || "Id";
        const selectedItemsFilter = [
            {
                field: idField,
                operator: J_FILTER_OPERATORS.in,
                value: selectedIds.map(id => id.toString())
            }
        ];
        const filterObject = FilterUtils.filterToJFilter(selectedItemsFilter, FilterUtils.J_FILTER_JOIN_OPERATORS.and);
        const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, 0, selectedIds.length - 1, [], filterObject);
        const inventoryServerItemList = inventoriesQueryResponse.response || [];
        const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
        const selectedStates = inventoryItemList.map(item => item.state.state.state);
        const uniqueSelectedStates = Utils.removeArrayDuplicates(selectedStates);
        const isSingleState = uniqueSelectedStates.length === 1;
        const availableStateTransitions = globalState.stateTransitions.stateTransitions;
        const selectedStateTransitions = uniqueSelectedStates.map(selectedState => availableStateTransitions.find(transition => transition.state === selectedState));
        const selectedCanTransitionTo = selectedStateTransitions.map(stateTransition => stateTransition.canTransitionTo);
        const combinedCanTransitionTo = Utils.getArrayMatchingElements(selectedCanTransitionTo);
        const normalStates = combinedCanTransitionTo.filter(state => state > 0);
        const errorStates = combinedCanTransitionTo.filter(state => state < 0);
        const specialOptions = [];
        if (isSingleState) {
            const originalState = uniqueSelectedStates[0];
            switch (originalState) {
                case 1:
                    specialOptions.push(InventoryContextMenuSpecialOptions.UNVERIFIED_QUARANTINED_IMPORT);
                    break;
                case 7:
                    specialOptions.push(InventoryContextMenuSpecialOptions.REMOVED_REMOVED_RETURN);
                    specialOptions.push(InventoryContextMenuSpecialOptions.REMOVED_REMOVED_EXPORT);
                    break;
                case -2:
                    specialOptions.push(InventoryContextMenuSpecialOptions.DAMAGED_DAMAGED_RETURN);
                    specialOptions.push(InventoryContextMenuSpecialOptions.DAMAGED_DAMAGED_EVALUATE);
                    specialOptions.push(InventoryContextMenuSpecialOptions.DAMAGED_REMOVED_EVALUATE);
                    break;
                case -5:
                    specialOptions.push(InventoryContextMenuSpecialOptions.RMA_RMA_RETURN);
                    specialOptions.push(InventoryContextMenuSpecialOptions.RMA_RMA_EXPORT);
                    break;
                default:
                    break;
            }
        }
        const previousStates = inventoryItemList.reduce((accumulator, item) => {
            if (!accumulator[item.state.state.state]) {
                accumulator[item.state.state.state] = [];
            }
            accumulator[item.state.state.state].push(item.uniqueId);
            return accumulator;
        }, {});
        const contextMenuData = getContextMenuData(fileContextMenuData, globalState, normalStates, errorStates, specialOptions);
        yield put({
            type: InventoryContextMenuContainerActionTypes.OPEN,
            xPosition: action.xPosition,
            yPosition: action.yPosition,
            data: contextMenuData,
            previousStates: previousStates
        });
    }
}
function getContextMenuData(fileContextMenuData, globalState, normalStates, errorStates, specialOptions) {
    const theme = globalState.theme;
    const combinedStates = [];
    if (specialOptions.length > 0) {
        const specialOptionsNormalStates = specialOptions.filter(option => parseInt(option.split("_")[1]) > 0);
        const combinedNormalStates = [
            ...normalStates,
            ...specialOptionsNormalStates
        ].sort((a, b) => {
            const aState = typeof a === "number" ? a : parseInt(a.split("_")[1]);
            const bState = typeof b === "number" ? b : parseInt(b.split("_")[1]);
            if (aState === bState) {
                if (typeof a === "number") {
                    return -1;
                }
                else if (typeof b === "number") {
                    return 1;
                }
                else {
                    return 0;
                }
            }
            else {
                return aState - bState;
            }
        });
        const specialOptionsErrorStates = specialOptions.filter(option => parseInt(option.split("_")[1]) < 0);
        const combinedErrorStates = [...errorStates, ...specialOptionsErrorStates].sort((a, b) => {
            const aState = typeof a === "number" ? a : parseInt(a.split("_")[1]);
            const bState = typeof b === "number" ? b : parseInt(b.split("_")[1]);
            if (aState === bState) {
                if (typeof a === "number") {
                    return 1;
                }
                else if (typeof b === "number") {
                    return -1;
                }
                else {
                    return 0;
                }
            }
            else {
                return bState - aState;
            }
        });
        if (combinedNormalStates.length > 0) {
            combinedNormalStates.forEach(normalState => combinedStates.push(normalState));
            combinedStates.push(CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING);
        }
        if (combinedErrorStates.length > 0) {
            combinedErrorStates.forEach(errorState => combinedStates.push(errorState));
            combinedStates.push(CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING);
        }
    }
    else {
        if (normalStates.length > 0) {
            normalStates.sort((a, b) => a - b).forEach(normalState => combinedStates.push(normalState));
            combinedStates.push(CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING);
        }
        if (errorStates.length > 0) {
            errorStates.sort((a, b) => b - a).forEach(errorState => combinedStates.push(errorState));
            combinedStates.push(CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING);
        }
    }
    const combinedStatesContextMenuData = combinedStates.map(state => {
        switch (state) {
            case CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING: {
                return state;
            }
            default: {
                const stateTransition = globalState.stateTransitions.stateTransitions.find(stateTransition => {
                    const stateId = typeof state === "number" ? state : parseInt(state.split("_")[1]);
                    return stateTransition.state === stateId;
                });
                const stateName = stateTransition ? stateTransition.stateName : "";
                const displayedText = Utils.snakeCaseToReadable(typeof state === "number"
                    ? stateName
                    : state
                        .split("_")[2]
                        .toLowerCase()
                        .replace(".", "_"));
                const stateNameUpperCase = stateName.toUpperCase();
                const stateIconName = INVENTORY_TABLE_ICONS[stateNameUpperCase];
                const backgroundColor = INVENTORY_TABLE_COLORS[stateNameUpperCase];
                const backgroundColorWithOpacity = StyleUtils.tableGenerateAlternatingRowColor(INVENTORY_TABLE_COLORS[stateNameUpperCase], 0);
                return {
                    identifier: state,
                    displayedText: displayedText,
                    iconName: stateIconName,
                    normalStyle: {
                        color: theme.palette.black,
                        backgroundColor: theme.palette.white,
                        borderColor: theme.palette.black
                    },
                    onHoverStyle: {
                        color: theme.palette.black,
                        backgroundColor: backgroundColorWithOpacity,
                        borderColor: theme.palette.black
                    },
                    onMouseDownStyle: {
                        color: theme.palette.white,
                        backgroundColor: backgroundColor,
                        borderColor: theme.palette.black
                    }
                };
            }
        }
    });
    const sharedContextMenuData = Object.entries(CONTEXT_MENU_SHARED_ACTIONS).map(([key, value]) => {
        return {
            identifier: value.identifier,
            displayedText: value.displayedText,
            iconName: value.iconName
        };
    });
    sharedContextMenuData.push(CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING);
    return [...combinedStatesContextMenuData, ...sharedContextMenuData, ...fileContextMenuData];
}
function* handleStateChangeSaga(action) {
    const globalState = yield select(getGlobalStateSelector);
    const availableStateTransitions = globalState.stateTransitions.stateTransitions;
    const matchingStateTransition = availableStateTransitions.find(stateTransition => stateTransition.state === action.newState);
    if (!matchingStateTransition || Utils.objectIsEmpty(matchingStateTransition)) {
        console.error(`Unable to transition state: State: ${action.newState} does not exist.`);
    }
    else {
        const stateObject = {
            id: matchingStateTransition.id,
            buId: globalState.userData.buId,
            state: matchingStateTransition.state,
            stateName: matchingStateTransition.stateName,
            system: globalState.stateTransitions.system
        };
        const comment = action.comment || `Dispatcher: ${globalState.userData.userName} transitioned state.`;
        action.itemIds.forEach(itemId => {
            InventoryState.inventoryTransitionRequestById(itemId, comment, stateObject);
        });
    }
}
function* handleServerOriginMessageSaga(action) {
    const tableState = yield select(getTableStateSelector);
    switch (action.messageType) {
        case SERVER_ORIGIN_MESSAGE_ACTION_TYPES.MODIFIED:
        case SERVER_ORIGIN_MESSAGE_ACTION_TYPES.CREATED:
        case SERVER_ORIGIN_MESSAGE_ACTION_TYPES.DELETED: {
            const tableState = yield select(getTableStateSelector);
            const rowsCount = yield call(getTotalRowsCount, tableState.tableJFilterObject);
            yield put({
                type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
                inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { totalRowsCount: rowsCount }) })
            });
            yield put({
                type: InventoryTableContainerActionTypes.GET_TABLE_ROWS_DATA,
                verticalScroll: tableState.tableRowVerticalScrollDataProps.verticalScroll
            });
            break;
        }
    }
}
function* getTableRowsDataSelectedChangeSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const selectedIds = tableState.selectedIds;
    let isAboveSelected = false;
    const newTableRowsData = tableState.tableRowWrapperDataProps.tableRowsData.map(tableRowData => {
        const isSelected = selectedIds.includes(tableRowData.tableCellRowProps.rowId);
        const newTableRowData = Object.assign(Object.assign({}, tableRowData), { tableCellRowProps: Object.assign(Object.assign({}, tableRowData.tableCellRowProps), { isSelected: isSelected, isAboveSelected: isAboveSelected }) });
        isAboveSelected = isSelected;
        return newTableRowData;
    });
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { tableRowsData: newTableRowsData }) })
    });
}
function getInventoryItems(itemIds) {
    return new Promise((resolve, reject) => {
        const getItemsPromises = itemIds.map(itemId => InventoryRead.inventoryRequestById(itemId));
        Promise.all(getItemsPromises).then(responses => {
            resolve(responses);
        });
    });
}
function* getTableRowsDataStateChangeSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const globalState = yield select(getGlobalStateSelector);
    const itemIds = action.messages.map(message => message.uniqueId);
    const inventoriesResponse = yield call(getInventoryItems, itemIds);
    const inventoryServerItems = inventoriesResponse.map(response => response.response);
    const inventoryItems = inventoryServerItems.map(item => InventorySchemaService.getInventoryItemFromServerObject(item));
    const ownerIds = inventoryItems
        .map(item => parseInt(item.ownerId))
        .filter(ownerId => ownerId && ownerId !== -1);
    const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds);
    const uniqueOwnerLookupObjects = yield call(FsrUtils.getFsrLookupObjects, uniqueOwnerIds);
    const inventoryProcessedItems = inventoryItems.map(inventoryItem => {
        const ownerId = parseInt(inventoryItem.ownerId);
        const ownerLookupObject = uniqueOwnerLookupObjects.find(object => object.id === ownerId);
        const ownerName = ownerLookupObject ? ownerLookupObject.name : inventoryItem.ownerId;
        return Object.assign(Object.assign({}, inventoryItem), { ownerName: ownerName });
    });
    let index = tableState.tableRowVerticalScrollDataProps.verticalScroll.start;
    const newTableRowsData = tableState.tableRowWrapperDataProps.tableRowsData.map(tableRowData => {
        const itemId = tableRowData.tableCellRowProps.rowId;
        if (itemIds.includes(itemId)) {
            const rootFontSize = Utils.getRootFontSize();
            const inventoryItem = inventoryProcessedItems.find(item => item.uniqueId === itemId);
            const message = action.messages.find(message => message.uniqueId === itemId);
            const stateName = message.stateName ? message.stateName.toUpperCase() : "";
            const height = rootFontSize * INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER;
            const backgroundColor = INVENTORY_TABLE_COLORS[stateName] || "";
            const backgroundColorWithOpacity = StyleUtils.tableGenerateAlternatingRowColor(backgroundColor, index);
            const newTableCellsData = getTableCellsData(tableState.tableHeaderRowData, height, backgroundColor, backgroundColorWithOpacity, rootFontSize, globalState.theme, Object.assign(Object.assign({}, inventoryItem), { stateName }));
            index++;
            return Object.assign(Object.assign({}, tableRowData), { tableCellsData: newTableCellsData });
        }
        else {
            index++;
            return tableRowData;
        }
    });
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { tableRowsData: newTableRowsData }) })
    });
}
function* getTableRowsDataByIdsSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const globalState = yield select(getGlobalStateSelector);
    const idsToUpdate = action.ids;
    const inventoriesResponse = yield call(getInventoryItems, idsToUpdate);
    const inventoryServerItems = inventoriesResponse.map(response => response.response);
    const inventoryItems = inventoryServerItems.map(item => InventorySchemaService.getInventoryItemFromServerObject(item));
    const ownerIds = inventoryItems
        .map(item => parseInt(item.ownerId))
        .filter(ownerId => ownerId && ownerId !== -1);
    const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds);
    const uniqueOwnerLookupObjects = yield call(FsrUtils.getFsrLookupObjects, uniqueOwnerIds);
    const inventoryProcessedItems = inventoryItems.map(inventoryItem => {
        const ownerId = parseInt(inventoryItem.ownerId);
        const ownerLookupObject = uniqueOwnerLookupObjects.find(object => object.id === ownerId);
        const ownerName = ownerLookupObject ? ownerLookupObject.name : inventoryItem.ownerId;
        return Object.assign(Object.assign({}, inventoryItem), { ownerName: ownerName });
    });
    let index = tableState.tableRowVerticalScrollDataProps.verticalScroll.start;
    const newTableRowsData = tableState.tableRowWrapperDataProps.tableRowsData.map(tableRowData => {
        const itemId = tableRowData.tableCellRowProps.rowId;
        if (idsToUpdate.includes(itemId)) {
            const rootFontSize = Utils.getRootFontSize();
            const inventoryItem = inventoryProcessedItems.find(item => item.uniqueId === itemId);
            const height = rootFontSize * INVENTORY_TABLE_ROW_HEIGHT_MULTIPLIER;
            const stateName = inventoryItem.state.state.stateName
                ? inventoryItem.state.state.stateName.toUpperCase()
                : "";
            const backgroundColor = INVENTORY_TABLE_COLORS[stateName] || "";
            const backgroundColorWithOpacity = StyleUtils.tableGenerateAlternatingRowColor(backgroundColor, index);
            const newTableCellsData = getTableCellsData(tableState.tableHeaderRowData, height, backgroundColor, backgroundColorWithOpacity, rootFontSize, globalState.theme, Object.assign(Object.assign({}, inventoryItem), { stateName }));
            index++;
            return Object.assign(Object.assign({}, tableRowData), { tableCellsData: newTableCellsData });
        }
        else {
            index++;
            return tableRowData;
        }
    });
    yield put({
        type: InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE,
        inventoryTableContainerState: Object.assign(Object.assign({}, tableState), { tableRowWrapperDataProps: Object.assign(Object.assign({}, tableState.tableRowWrapperDataProps), { tableRowsData: newTableRowsData }) })
    });
}
function* tableToCSVSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const globalState = yield select(getGlobalStateSelector);
    const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, 0, MAX_RESULT_PER_REQUEST, [], tableState.tableJFilterObject);
    const inventoryServerItemList = inventoriesQueryResponse.response || [];
    const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
    const ownerIds = inventoryItemList
        .map(item => parseInt(item.ownerId))
        .filter(ownerId => ownerId && ownerId !== -1);
    const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds);
    const uniqueOwnerLookupObjects = yield call(FsrUtils.getFsrLookupObjects, uniqueOwnerIds);
    const inventoryProcessedItemList = inventoryItemList.map(inventoryItem => {
        const ownerId = parseInt(inventoryItem.ownerId);
        const ownerLookupObject = uniqueOwnerLookupObjects.find(object => object.id === ownerId);
        const ownerName = ownerLookupObject ? ownerLookupObject.name : inventoryItem.ownerId;
        return Object.assign(Object.assign({}, inventoryItem), { ownerName: ownerName });
    });
    const tableHeaderRowData = tableState.tableHeaderRowData;
    const columns = {};
    tableHeaderRowData.forEach(headerRowData => {
        if (headerRowData.index === 1) {
            columns["State"] = "State";
        }
        else {
            columns[headerRowData.displayedText] = headerRowData.displayedText;
        }
    });
    const datas = inventoryProcessedItemList.map(inventoryItemObject => {
        const outputObject = {};
        tableHeaderRowData.forEach(headerRowData => {
            let name = headerRowData.displayedText;
            let value = Utils.getTextFromDataAndValues(inventoryItemObject, headerRowData.values, headerRowData.valuesSeparator, headerRowData.valuesPostProcessor);
            if (headerRowData.index === 1) {
                name = "State";
                value = Utils.snakeCaseToReadable(inventoryItemObject.state.state.stateName);
            }
            outputObject[name] = value;
        });
        return outputObject;
    });
    const timeString = Utils.millisDatePostProcessor(moment.now().valueOf()).replace(":", "-");
    const exportFileName = `Inventory Table Data - ${timeString}.csv`;
    downloadCsv(datas, columns, exportFileName);
}
function* historicalDataToCSVSaga(action) {
    const tableState = yield select(getTableStateSelector);
    const globalState = yield select(getGlobalStateSelector);
    const inventoryCache = yield select(getCacheSelector);
    const inventoriesQueryResponse = yield call(InventoryRead.inventoryQueryRequest, 0, MAX_RESULT_PER_REQUEST, [], tableState.tableJFilterObject);
    const inventoryServerItemList = inventoriesQueryResponse.response || [];
    const inventoryItemList = inventoryServerItemList.map(serverItem => InventorySchemaService.getInventoryItemFromServerObject(serverItem));
    const ownerIds = inventoryItemList
        .map(item => parseInt(item.ownerId))
        .filter(ownerId => ownerId && ownerId !== -1);
    const uniqueOwnerIds = Utils.removeArrayDuplicates(ownerIds);
    const uniqueOwnerLookupObjects = yield call(FsrUtils.getFsrLookupObjects, uniqueOwnerIds);
    const inventoryProcessedItemList = inventoryItemList.map(inventoryItem => {
        const ownerId = parseInt(inventoryItem.ownerId);
        const ownerLookupObject = uniqueOwnerLookupObjects.find(object => object.id === ownerId);
        const ownerName = ownerLookupObject ? ownerLookupObject.name : inventoryItem.ownerId;
        return Object.assign(Object.assign({}, inventoryItem), { ownerName: ownerName });
    });
    const allItemIds = inventoryProcessedItemList.map(item => item.uniqueId);
    const sortedAllItemIds = allItemIds.sort((a, b) => a - b);
    const inventoriesStateHistoryData = yield call(getStateHistoryScreenData, sortedAllItemIds);
    const inventoriesOwnerHistoryData = yield call(getOwnerHistoryScreenData, sortedAllItemIds);
    const inventoriesLinkingHistoryData = yield call(getContainerHistoryScreenData, sortedAllItemIds, inventoryCache);
    const combinedInventoriesHistoryData = inventoriesStateHistoryData.map(stateDataList => {
        const ownerHistoryData = inventoriesOwnerHistoryData.find(ownerDataList => ownerDataList.id === stateDataList.id);
        const ownerDataList = ownerHistoryData
            ? ownerHistoryData.inventoryHistoryDataList || []
            : [];
        const linkingHistoryData = inventoriesLinkingHistoryData.find(linkingDataList => linkingDataList.id === stateDataList.id);
        const linkingDataList = linkingHistoryData
            ? linkingHistoryData.inventoryHistoryDataList || []
            : [];
        const combinedDataList = [
            ...stateDataList.inventoryHistoryDataList,
            ...ownerDataList,
            ...linkingDataList
        ];
        const sortedCombinedDataList = combinedDataList.sort((a, b) => {
            const aTime = a.type === InventoryHistoryDataType.STATE ? a.transitionTime : a.timestamp;
            const bTime = b.type === InventoryHistoryDataType.STATE ? b.transitionTime : b.timestamp;
            return bTime - aTime;
        });
        return Object.assign(Object.assign({}, stateDataList), { inventoryHistoryDataList: sortedCombinedDataList });
    });
    const fsrLookupObjects = yield call(getFsrLookupObjects, combinedInventoriesHistoryData);
    const combinedInventoriesHistoryDataWithNames = combinedInventoriesHistoryData.map(dataList => {
        const matchingItem = inventoryItemList.find(item => item.uniqueId.toString() === dataList.displayedText);
        const displayedText = matchingItem ? matchingItem.id : "";
        return Object.assign(Object.assign({}, dataList), { displayedText: displayedText, inventoryHistoryDataList: dataList.inventoryHistoryDataList.map(data => {
                switch (data.type) {
                    case InventoryHistoryDataType.STATE:
                        const fsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.ownerId);
                        if (fsrLookupObject) {
                            return Object.assign(Object.assign({}, data), { ownerName: fsrLookupObject.name });
                        }
                        else {
                            return data;
                        }
                    case InventoryHistoryDataType.OWNER:
                        const oldFsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.oldOwner.split("*")[1]);
                        const newFsrLookupObject = fsrLookupObjects.find(object => object.id.toString() === data.newOwner.split("*")[1]);
                        let newData = data;
                        if (oldFsrLookupObject) {
                            newData = Object.assign(Object.assign({}, newData), { oldOwnerName: oldFsrLookupObject.name });
                        }
                        if (newFsrLookupObject) {
                            newData = Object.assign(Object.assign({}, newData), { newOwnerName: newFsrLookupObject.name });
                        }
                        return newData;
                    case InventoryHistoryDataType.LINKING: {
                        return data;
                    }
                }
            }) });
    });
    const headers = [
        "Item",
        "Type",
        "Date",
        "State",
        "Container",
        "Owner Name",
        "Owner Type",
        "Previous Owner",
        "Previous Owner Type",
        "Comments"
    ];
    const columns = { "": "" };
    headers.forEach(header => {
        columns[header] = header;
    });
    const datas = [];
    combinedInventoriesHistoryDataWithNames.forEach(data => {
        const displayedText = data.displayedText;
        const inventoryHistoryDataList = data.inventoryHistoryDataList;
        inventoryHistoryDataList.forEach(row => {
            const outputObject = { "": "" };
            const item = displayedText;
            const type = row.type;
            let date = "";
            let state = "";
            let container = "";
            let ownerName = "";
            let ownerType = "";
            let previousOwner = "";
            let previousOwnerType = "";
            let comments = "";
            switch (row.type) {
                case InventoryHistoryDataType.OWNER: {
                    date = Utils.millisDatePostProcessor(row.timestamp);
                    ownerName = row.newOwner.split("*")[1] === "-1" ? "N/A" : row.newOwnerName;
                    ownerType = row.newOwner.split("*")[0] || "";
                    previousOwner = row.oldOwner.split("*")[1] === "-1" ? "N/A" : row.oldOwnerName;
                    previousOwnerType = row.oldOwner.split("*")[0] || "";
                    break;
                }
                case InventoryHistoryDataType.STATE: {
                    date = Utils.millisDatePostProcessor(row.transitionTime);
                    state = Utils.snakeCaseToReadable(row.stateName);
                    ownerName = row.ownerId === "-1" ? "N/A" : row.ownerName;
                    ownerType = row.ownerType;
                    comments = row.comments;
                    break;
                }
                case InventoryHistoryDataType.LINKING: {
                    date = Utils.millisDatePostProcessor(row.timestamp);
                    container = row.parentName;
                    break;
                }
            }
            headers.forEach(header => {
                switch (header) {
                    case "Item": {
                        outputObject[header] = item;
                        break;
                    }
                    case "Type": {
                        outputObject[header] = type;
                        break;
                    }
                    case "Date": {
                        outputObject[header] = date;
                        break;
                    }
                    case "State": {
                        outputObject[header] = state;
                        break;
                    }
                    case "Container": {
                        outputObject[header] = container;
                        break;
                    }
                    case "Owner Name": {
                        outputObject[header] = ownerName;
                        break;
                    }
                    case "Owner Type": {
                        outputObject[header] = ownerType;
                        break;
                    }
                    case "Previous Owner": {
                        outputObject[header] = previousOwner;
                        break;
                    }
                    case "Previous Owner Type": {
                        outputObject[header] = previousOwnerType;
                        break;
                    }
                    case "Comments": {
                        outputObject[header] = comments;
                        break;
                    }
                }
            });
            datas.push(outputObject);
        });
    });
    const timeString = Utils.millisDatePostProcessor(moment.now().valueOf()).replace(":", "-");
    const exportFileName = `Inventory History Data - ${timeString}.csv`;
    downloadCsv(datas, columns, exportFileName);
}
function* handleBackgroundRightClickSaga(action) {
    yield put({
        type: InventoryTableContainerActionTypes.OPEN_CONTEXT_MENU,
        isBackground: true,
        xPosition: action.xPosition,
        yPosition: action.yPosition
    });
}
function getTableStateSelector(store) {
    return store.inventory.inventoryTableContainer;
}
function getGlobalStateSelector(store) {
    return store.inventory.inventoryGlobalState;
}
function getCacheSelector(store) {
    return store.inventory.inventoryCache;
}
export default inventoryTableContainerSagas;
