import React, { useState } from "react";
import styled from "styled-components";
import SelectInput from "../../../Input/SelectInput/SelectInput";
import Spacer from "../../../Spacer/Spacer";
import ControlledTextInput from "../../../Input/ControlledTextInput/ControlledTextInput";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
import ControlledDateTimeInput from "../../../Input/ControlledDateTimeInput/ControlledDateTimeInput";
import moment from "moment";
import { Utils } from "../../../../../../helper/General/Utils";
export const StyledDateTimeFilterRow = styled.div `
  height: 18px;
  width: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
export var DateTimeFilterRowSelectOption;
(function (DateTimeFilterRowSelectOption) {
    DateTimeFilterRowSelectOption["EQUAL_TO"] = "Equal To";
    DateTimeFilterRowSelectOption["NOT_EQUAL_TO"] = "Not Equal To";
    DateTimeFilterRowSelectOption["DATE_RANGE"] = "Date Range";
    DateTimeFilterRowSelectOption["LESS_THAN_OR_EQUAL_TO"] = "<=";
    DateTimeFilterRowSelectOption["GREATER_THAN_OR_EQUAL_TO"] = ">=";
    DateTimeFilterRowSelectOption["LESS_THAN"] = "<";
    DateTimeFilterRowSelectOption["GREATER_THAN"] = ">";
    DateTimeFilterRowSelectOption["WITHIN_NEXT_DAYS"] = "Within Next (days)";
    DateTimeFilterRowSelectOption["WITHIN_NEXT_HOURS"] = "Within Next (hours)";
    DateTimeFilterRowSelectOption["OUTSIDE_DAYS"] = "Outside (days)";
    DateTimeFilterRowSelectOption["OUTSIDE_HOURS"] = "Outside (hours)";
    DateTimeFilterRowSelectOption["TODAY"] = "Today";
    DateTimeFilterRowSelectOption["YESTERDAY"] = "Yesterday";
    DateTimeFilterRowSelectOption["NEXT_DAY"] = "Next Day";
    DateTimeFilterRowSelectOption["THIS_MONTH"] = "This Month";
    DateTimeFilterRowSelectOption["NEXT_MONTH"] = "Next Month";
    DateTimeFilterRowSelectOption["LAST_MONTH"] = "Last Month";
    DateTimeFilterRowSelectOption["THIS_WEEK"] = "This Week";
    DateTimeFilterRowSelectOption["NEXT_WEEK"] = "Next Week";
    DateTimeFilterRowSelectOption["LAST_WEEK"] = "Last Week";
    DateTimeFilterRowSelectOption["FUTURE"] = "Future";
})(DateTimeFilterRowSelectOption || (DateTimeFilterRowSelectOption = {}));
const DateTimeFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, interval = 5, handleFilterChange, } = props;
    const getIsTimelessFilter = (filterObject, hasTwoFilter) => {
        if (!filterObject || hasTwoFilter) {
            return false;
        }
        const filterKeyString = Object.keys(filterObject)[0];
        const matchingKeys = ["currentDay", "nextDay", "currentMonth", "nextMonth", "lastMonth", "currentWeek", "nextWeek", "lastWeek", "future"];
        return matchingKeys.includes(filterKeyString);
    };
    const getInitialDateTime = (initialFilter) => {
        if (initialFilter && initialFilter.$and) {
            const filterPair = initialFilter.$and[0];
            const hasTwoFilter = !!filterPair.$and;
            const firstFilter = hasTwoFilter ? filterPair.$and[0][filterKey] : filterPair[filterKey];
            const isTimelessFilter = getIsTimelessFilter(firstFilter, hasTwoFilter);
            if (!firstFilter || isTimelessFilter) {
                return [null, null];
            }
            else {
                const firstDate = parseInt(firstFilter[Object.keys(firstFilter)[0]][0]);
                const secondFilter = hasTwoFilter ? filterPair.$and[1][filterKey] : null;
                if (!secondFilter) {
                    return [firstDate, null];
                }
                else {
                    const secondDate = parseInt(secondFilter[Object.keys(secondFilter)[0]][0]);
                    return [firstDate, secondDate];
                }
            }
        }
        else {
            return [null, null];
        }
    };
    const getInitialType = (initialFilter) => {
        if (initialFilter && initialFilter.$and) {
            const filterPair = initialFilter.$and[0];
            const hasTwoFilter = !!filterPair.$and;
            const firstFilter = hasTwoFilter ? filterPair.$and[0][filterKey] : filterPair[filterKey];
            if (!firstFilter) {
                return "";
            }
            else {
                const firstKey = Object.keys(firstFilter)[0];
                const secondFilter = hasTwoFilter ? filterPair.$and[1][filterKey] : null;
                if (!secondFilter) {
                    switch (firstKey) {
                        case "$in": {
                            return DateTimeFilterRowSelectOption.EQUAL_TO;
                        }
                        case "$nin": {
                            return DateTimeFilterRowSelectOption.NOT_EQUAL_TO;
                        }
                        case "$le": {
                            return DateTimeFilterRowSelectOption.LESS_THAN_OR_EQUAL_TO;
                        }
                        case "$ge": {
                            return DateTimeFilterRowSelectOption.GREATER_THAN_OR_EQUAL_TO;
                        }
                        case "$lt": {
                            return DateTimeFilterRowSelectOption.LESS_THAN;
                        }
                        case "$gt": {
                            return DateTimeFilterRowSelectOption.GREATER_THAN;
                        }
                        case "$within": {
                            return DateTimeFilterRowSelectOption.WITHIN_NEXT_DAYS;
                        }
                        case "$withinHours": {
                            return DateTimeFilterRowSelectOption.WITHIN_NEXT_HOURS;
                        }
                        case "$outside": {
                            return DateTimeFilterRowSelectOption.OUTSIDE_DAYS;
                        }
                        case "$outsideHours": {
                            return DateTimeFilterRowSelectOption.OUTSIDE_HOURS;
                        }
                        case "$currentDay": {
                            return DateTimeFilterRowSelectOption.TODAY;
                        }
                        case "$yesterday": {
                            return DateTimeFilterRowSelectOption.YESTERDAY;
                        }
                        case "$nextDay": {
                            return DateTimeFilterRowSelectOption.NEXT_DAY;
                        }
                        case "$currentMonth": {
                            return DateTimeFilterRowSelectOption.THIS_MONTH;
                        }
                        case "$nextMonth": {
                            return DateTimeFilterRowSelectOption.NEXT_MONTH;
                        }
                        case "$lastMonth": {
                            return DateTimeFilterRowSelectOption.LAST_MONTH;
                        }
                        case "$currentWeek": {
                            return DateTimeFilterRowSelectOption.THIS_WEEK;
                        }
                        case "$nextWeek": {
                            return DateTimeFilterRowSelectOption.NEXT_WEEK;
                        }
                        case "$lastWeek": {
                            return DateTimeFilterRowSelectOption.LAST_WEEK;
                        }
                        case "$future": {
                            return DateTimeFilterRowSelectOption.FUTURE;
                        }
                    }
                }
                else {
                    const secondKey = Object.keys(secondFilter)[0];
                    if (firstKey === "$ge" && secondKey === "$le") {
                        return DateTimeFilterRowSelectOption.DATE_RANGE;
                    }
                }
            }
        }
        else {
            return "";
        }
    };
    const [dateTime, setDateTime] = useState(getInitialDateTime(initialFilter));
    const [type, setType] = useState(getInitialType(initialFilter));
    const handleClearFilter = () => {
    };
    const handleFilterSubmit = (type, dateTime) => {
        if (type === "") {
            handleFilterChange({
                key: filterKey,
                filterObject: null,
            });
            return;
        }
        let filterObject = null;
        switch (type) {
            case DateTimeFilterRowSelectOption.EQUAL_TO: {
                filterObject = {
                    [filterKey]: {
                        $in: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.NOT_EQUAL_TO: {
                filterObject = {
                    [filterKey]: {
                        $nin: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.DATE_RANGE: {
                filterObject = {
                    $and: [
                        {
                            [filterKey]: {
                                $ge: [dateTime[0].toString()]
                            }
                        },
                        {
                            [filterKey]: {
                                $le: [dateTime[1].toString()]
                            }
                        }
                    ]
                };
                break;
            }
            case DateTimeFilterRowSelectOption.GREATER_THAN_OR_EQUAL_TO: {
                filterObject = {
                    [filterKey]: {
                        $ge: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.LESS_THAN_OR_EQUAL_TO: {
                filterObject = {
                    [filterKey]: {
                        $le: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.GREATER_THAN: {
                filterObject = {
                    [filterKey]: {
                        $gt: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.LESS_THAN: {
                filterObject = {
                    [filterKey]: {
                        $lt: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.WITHIN_NEXT_DAYS: {
                filterObject = {
                    [filterKey]: {
                        $within: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.WITHIN_NEXT_HOURS: {
                filterObject = {
                    [filterKey]: {
                        $withinHours: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.OUTSIDE_DAYS: {
                filterObject = {
                    [filterKey]: {
                        $outside: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.OUTSIDE_HOURS: {
                filterObject = {
                    [filterKey]: {
                        $outsideHours: [dateTime[0].toString()]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.TODAY: {
                filterObject = {
                    [filterKey]: {
                        $currentDay: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.YESTERDAY: {
                filterObject = {
                    [filterKey]: {
                        $currentDay: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.NEXT_DAY: {
                filterObject = {
                    [filterKey]: {
                        $nextDay: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.THIS_MONTH: {
                filterObject = {
                    [filterKey]: {
                        $currentMonth: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.NEXT_MONTH: {
                filterObject = {
                    [filterKey]: {
                        $nextMonth: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.LAST_MONTH: {
                filterObject = {
                    [filterKey]: {
                        $lastMonth: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.THIS_WEEK: {
                filterObject = {
                    [filterKey]: {
                        $currentWeek: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.NEXT_WEEK: {
                filterObject = {
                    [filterKey]: {
                        $nextWeek: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.LAST_WEEK: {
                filterObject = {
                    [filterKey]: {
                        $lastWeek: [""]
                    }
                };
                break;
            }
            case DateTimeFilterRowSelectOption.FUTURE: {
                filterObject = {
                    [filterKey]: {
                        $future: [""]
                    }
                };
                break;
            }
        }
        handleFilterChange({
            key: filterKey,
            filterObject: { $and: [filterObject] },
        });
    };
    const handleTypeChange = (newType) => {
        setType(newType);
        const currentDateTime = moment.now().valueOf() - (moment.now().valueOf() % (interval * 60000));
        switch (newType) {
            case DateTimeFilterRowSelectOption.TODAY:
            case DateTimeFilterRowSelectOption.YESTERDAY:
            case DateTimeFilterRowSelectOption.NEXT_DAY:
            case DateTimeFilterRowSelectOption.THIS_MONTH:
            case DateTimeFilterRowSelectOption.NEXT_MONTH:
            case DateTimeFilterRowSelectOption.LAST_MONTH:
            case DateTimeFilterRowSelectOption.THIS_WEEK:
            case DateTimeFilterRowSelectOption.NEXT_WEEK:
            case DateTimeFilterRowSelectOption.LAST_WEEK:
            case DateTimeFilterRowSelectOption.FUTURE: {
                setDateTime([null, null]);
                handleFilterSubmit(newType, [null, null]);
                break;
            }
            case DateTimeFilterRowSelectOption.EQUAL_TO:
            case DateTimeFilterRowSelectOption.NOT_EQUAL_TO:
            case DateTimeFilterRowSelectOption.LESS_THAN:
            case DateTimeFilterRowSelectOption.LESS_THAN_OR_EQUAL_TO:
            case DateTimeFilterRowSelectOption.GREATER_THAN:
            case DateTimeFilterRowSelectOption.GREATER_THAN_OR_EQUAL_TO:
            case DateTimeFilterRowSelectOption.WITHIN_NEXT_DAYS:
            case DateTimeFilterRowSelectOption.WITHIN_NEXT_HOURS:
            case DateTimeFilterRowSelectOption.OUTSIDE_DAYS:
            case DateTimeFilterRowSelectOption.OUTSIDE_HOURS: {
                setDateTime([currentDateTime, null]);
                handleFilterSubmit(newType, [currentDateTime, null]);
                break;
            }
            case DateTimeFilterRowSelectOption.DATE_RANGE: {
                setDateTime([currentDateTime, currentDateTime]);
                handleFilterSubmit(newType, [currentDateTime, currentDateTime]);
                break;
            }
        }
    };
    const handleFirstDateTimeChange = (value, index) => {
        setDateTime(prevState => {
            handleFilterSubmit(type, [value, prevState[1]]);
            return [value, prevState[1]];
        });
    };
    const handleFirstDateTimeStringChange = (value, index) => {
        if (Utils.isInt(value)) {
            setDateTime(prevState => {
                handleFilterSubmit(type, [Utils.isInt(value) ? parseInt(value) : prevState[0], prevState[1]]);
                return [Utils.isInt(value) ? parseInt(value) : prevState[0], prevState[1]];
            });
        }
    };
    const handleSecondDateTimeChange = (value, index) => {
        setDateTime(prevState => {
            handleFilterSubmit(type, [prevState[0], value]);
            return [prevState[0], value];
        });
    };
    const getIsWithinOrOutside = (type) => {
        return type === DateTimeFilterRowSelectOption.WITHIN_NEXT_HOURS || type === DateTimeFilterRowSelectOption.WITHIN_NEXT_DAYS || type === DateTimeFilterRowSelectOption.OUTSIDE_HOURS || type === DateTimeFilterRowSelectOption.OUTSIDE_DAYS;
    };
    const inputOptions = Object.values(DateTimeFilterRowSelectOption);
    const initialType = getInitialType(initialFilter);
    const isWithinOrOutside = getIsWithinOrOutside(type);
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledDateTimeFilterRow, null,
            React.createElement(SelectInput, { options: ["", ...inputOptions], height: 18, initialValue: initialType, handleChange: handleTypeChange }),
            (dateTime[0] !== null && !isNaN(dateTime[0])) ? (isWithinOrOutside ? (React.createElement(React.Fragment, null,
                React.createElement(Spacer, { width: 10 }),
                React.createElement(ControlledTextInput, { value: dateTime[0].toString(), handleChange: handleFirstDateTimeStringChange }))) : (React.createElement(React.Fragment, null,
                React.createElement(Spacer, { width: 10 }),
                React.createElement(ControlledDateTimeInput, { dateTime: dateTime[0], handleSubmit: handleFirstDateTimeChange })))) : null,
            (dateTime[1] !== null && !isNaN(dateTime[1])) ? (React.createElement(React.Fragment, null,
                React.createElement(Spacer, { width: 10 }),
                React.createElement(ControlledDateTimeInput, { dateTime: dateTime[1], handleSubmit: handleSecondDateTimeChange }))) : null)));
};
export default DateTimeFilterRow;
