import React from "react";
import styled from "styled-components";
import MessageResenderMessageRow from "./MessageResenderMessageRow/MessageResenderMessageRow";
const StyledMessageResenderMessages = styled.div `
  width: calc(35% - 10px);
  height: 100%;
  max-width: calc(35% - 10px);
  padding: 5px;
  float: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-y: scroll;
  overflow: auto;
`;
class MessageResenderMessages extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledMessageResenderMessages, { className: "message-resender-messages-container" },
            React.createElement("label", { style: { marginBottom: "1rem" } }, "Messages: "),
            this.props.localQueueMessages.map(message => {
                return (React.createElement(MessageResenderMessageRow, { localQueueMessage: message, handleMessageRowClick: this.props.handleMessageRowClick, handleCheckboxChange: this.props.handleCheckboxChange }));
            })));
    }
}
export default MessageResenderMessages;
