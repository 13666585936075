import React from "react";
import FilterList from "./FilterList/FilterList";
import styled from "styled-components";
import Title from "../Title/Title";
import FilterControl from "./FilterControl/FilterControl";
const StyledFilter = styled.div `
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.resetToDefaultHandler = (event) => {
            this.props.filterControlProps.filterControlActions.resetToDefaultHandler(event);
            this.setState({ keyForRefresh: this.state.keyForRefresh + 1 });
        };
        this.changeWorkingSetHandler = (option) => {
            this.props.filterControlProps.filterControlActions.getFilterConfigurationHandler(option);
            this.setState({ keyForRefresh: this.state.keyForRefresh + 1 });
        };
        this.state = { keyForRefresh: 0 };
    }
    render() {
        const { filterControlProps, filterListProps } = this.props;
        return (React.createElement(StyledFilter, { className: "filter-wrapper" },
            React.createElement(Title, { titleDisplayedText: "Inventory Filter" }),
            React.createElement(FilterControl, Object.assign({}, filterControlProps, { filterControlActions: Object.assign(Object.assign({}, filterControlProps.filterControlActions), { resetToDefaultHandler: this.resetToDefaultHandler, getFilterConfigurationHandler: this.changeWorkingSetHandler }) })),
            React.createElement(FilterList, Object.assign({ key: this.state.keyForRefresh }, filterListProps))));
    }
}
export default Filter;
