import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import Button from "../Button";
import React from "react";
export const StyledTextButton = styled.div `
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  color: ${props => props.fontColor};
  font-weight: ${props => props.fontWeight};
`;
const TextButton = (props) => {
    const { height = 16, width = 80, color = "#000000", backgroundColor = "#FFFFFF", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleClick, text = "Button", fontSize = 14, fontWeight = "normal", isEnabled, } = props;
    const renderChild = (color, backgroundColor) => {
        return (React.createElement(StyledTextButton, { fontSize: fontSize, fontColor: color, fontWeight: fontWeight }, text));
    };
    return (React.createElement(Button, { className: "text-button", renderChild: renderChild, handleClick: handleClick, height: height, width: width, color: color, backgroundColor: backgroundColor, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding, isEnabled: isEnabled }));
};
export default TextButton;
