import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import FilterEditScreen from "../components/Screens/FilterEditScreen/FilterEditScreen";
import { Utils } from "../../../helper/General/Utils";
import { ManagePageContainerActionTypes } from "../../../state/containers/ManagePageContainer/types";
const ManagePageUserFilterWindowContainer = (props) => {
    var _a, _b;
    const state = useSelector(store => store.manage, shallowEqual);
    const dispatch = useDispatch();
    const { open, filterName, filterType, handleClose, } = props;
    useEffect(() => {
        dispatch({
            type: ManagePageContainerActionTypes.USER_FILTER_WINDOW_INIT,
            filterName: filterName,
            filterType: filterType,
        });
    }, [open]);
    const title = `${Utils.capitalizeFirstCharacter(filterType)} Filter: ${filterName}`;
    const displayedFilter = ((_b = (_a = state.userFilter) === null || _a === void 0 ? void 0 : _a.filter) === null || _b === void 0 ? void 0 : _b.$and) || null;
    const handleFiltersSave = (e) => {
        dispatch({
            type: ManagePageContainerActionTypes.SAVE_USER_FILTER,
            filterType: filterType,
            filterName: filterName,
            filter: e,
        });
    };
    return (displayedFilter === null ? null : (React.createElement(FilterEditScreen, { open: open, windowTitle: title, filterFields: state.userFilterFields, filters: displayedFilter, handleClose: handleClose, editable: true, saveButtonText: "Apply", handleFiltersSave: handleFiltersSave })));
};
export default ManagePageUserFilterWindowContainer;
