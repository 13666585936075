/**
 * @buttonNumber
 * 1: Left button
 * 2: Middle/Right button
 * 3: Right/Back button
 */
export function detectMouseButton(evt, buttonNumber = 1, options = {}) {
    if (evt.metaKey || evt.ctrlKey || evt.altKey || (evt.shiftKey && !options.allowShiftClick)) {
        return false;
    }
    if ('buttons' in evt) {
        return evt.buttons === buttonNumber;
    }
    if ('which' in evt) {
        return evt.which === buttonNumber;
    }
    return evt.button === buttonNumber - 1;
}
