import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../../../SimpleButton/SimpleButton";
const StyledFilterTitle = styled.div `
  height: 2.6rem;
  width: 100%;
  background-color: ${props => props.titleBackgroundColor || props.palette.white};
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const StyledFilterTitleText = styled.div `
  height: 1.6rem;
  width: 100%;
  font-size: 1.6rem;
  color: ${props => props.titleColor || props.palette.black};
  line-height: 1.6rem;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
const StyledFilterTitleButtonWrapper = styled.div `
  height: 2.6rem;
  width: 12rem;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const StyledRemoveFilterButtonWrapper = styled.div `
  height: 2.2rem;
  width: 2.2rem;
  padding: 0.2rem;
`;
class FilterTitle extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.removeFilter = () => {
            this.props.removeFilter(this.props.index);
        };
        this.getButtons = () => {
            const buttonArray = [];
            buttonArray.push(this.getRemoveFilterButton());
            return buttonArray;
        };
        this.getRemoveFilterButton = () => {
            const index = 0;
            const color = this.props.filterTitleConfiguration
                ? this.props.filterTitleConfiguration.titleColor || this.theme.palette.black
                : this.theme.palette.black;
            const backgroundColor = this.props.filterTitleConfiguration
                ? this.props.filterTitleConfiguration.titleBackgroundColor || this.theme.palette.white
                : this.theme.palette.white;
            return (React.createElement(StyledRemoveFilterButtonWrapper, { key: index, className: "filter-remove-button-wrapper" },
                React.createElement(SimpleButton, { className: "filter-remove-button", iconProps: {
                        iconName: "bin",
                        size: "1.6rem"
                    }, color: color, backgroundColor: backgroundColor, borderWidth: "0.1rem", borderRadius: "0.2rem", iconOffsetX: "0rem", iconOffsetY: "-0.1rem", padding: "0.2rem", buttonClick: this.removeFilter })));
        };
    }
    render() {
        return (React.createElement(StyledFilterTitle, Object.assign({ className: "filter-title" }, this.theme, this.props.filterTitleConfiguration),
            React.createElement(StyledFilterTitleButtonWrapper, { className: "filter-title-button-wrapper" }, this.getButtons()),
            React.createElement(StyledFilterTitleText, Object.assign({ className: "filter-title-text" }, this.theme, this.props.filterTitleConfiguration), this.props.displayedText)));
    }
}
export default FilterTitle;
