import React from "react";
import { getTheme } from "office-ui-fabric-react";
import { controlBarBorderWidth, controlBarWidth } from "../ResizablePanelControlBar/ResizablePanelControlBar";
import { IconService } from "../../../../helper/Icon/IconService";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
class ResizablePanelResizer extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.resizeStart = (event) => {
            const target = event.target;
            const targetId = parseInt(target.id);
            if (targetId === this.props.index) {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isResizing: true })));
                event.stopPropagation();
                event.preventDefault();
            }
        };
        this.resizeMove = (event) => {
            if (this.state.isResizing) {
                const { horizontal, itemHeight, itemWidth, parentGeometry, position, index, lastIndex } = this.props;
                const newPosition = horizontal ? event.pageX : event.pageY;
                const itemLength = horizontal ? itemWidth : itemHeight;
                const minimumPosition = controlBarWidth - itemLength;
                const parentPosition = horizontal ? parentGeometry.parentLeft : parentGeometry.parentTop;
                const parentLength = horizontal ? parentGeometry.parentWidth : parentGeometry.parentHeight;
                const maximumPosition = parentLength - position - (lastIndex - index) * controlBarWidth;
                const positionOffset = Math.min(Math.max(newPosition - position - parentPosition, minimumPosition), maximumPosition);
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { positionOffset: positionOffset })));
                event.stopPropagation();
                event.preventDefault();
            }
        };
        this.resizeStop = (event) => {
            if (this.state.isResizing) {
                this.props.resizeHandler({
                    index: this.props.index,
                    change: this.state.positionOffset
                });
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isResizing: false, positionOffset: 0 })));
                event.stopPropagation();
                event.preventDefault();
            }
        };
        this.resizablePanelResizer = React.createRef();
        this.state = {
            isResizing: false,
            positionOffset: 0
        };
    }
    componentDidMount() {
        this.parentElement = this.resizablePanelResizer.current.parentElement.parentElement;
        this.parentElement.addEventListener("mousedown", this.resizeStart);
        this.parentElement.addEventListener("mousemove", this.resizeMove);
        this.parentElement.addEventListener("mouseup", this.resizeStop);
        this.parentElement.addEventListener("mouseleave", this.resizeStop);
        document.addEventListener("mouseleave", this.resizeStop);
    }
    componentWillUnmount() {
        this.parentElement.removeEventListener("mousedown", this.resizeStart);
        this.parentElement.removeEventListener("mousemove", this.resizeMove);
        this.parentElement.removeEventListener("mouseup", this.resizeStop);
        this.parentElement.removeEventListener("mouseleave", this.resizeStop);
        document.removeEventListener("mouseleave", this.resizeStop);
    }
    render() {
        const { horizontal, index, lastIndex, position, length, color, parentGeometry, itemHeight, itemWidth } = this.props;
        const { isResizing, positionOffset } = this.state;
        const resizerStyle = {
            backgroundColor: isResizing ? this.theme.palette.black : color,
            position: "absolute",
            top: StyleUtils.getCssPixelString(horizontal
                ? parentGeometry.parentTop
                : parentGeometry.parentTop + position + positionOffset - controlBarBorderWidth),
            height: StyleUtils.getCssPixelString(horizontal ? length : controlBarBorderWidth),
            left: StyleUtils.getCssPixelString(horizontal
                ? parentGeometry.parentLeft + position + positionOffset - controlBarBorderWidth
                : parentGeometry.parentLeft),
            width: StyleUtils.getCssPixelString(horizontal ? controlBarBorderWidth : length),
            zIndex: IconService.STACKING_Z_INDEX.LEVEL_ONE,
            cursor: horizontal ? "col-resize" : "row-resize",
            opacity: isResizing ? 1 : 0
        };
        return (React.createElement("div", { className: "resizable-panel-resizer", id: index.toString(), style: resizerStyle, ref: this.resizablePanelResizer }));
    }
}
export default ResizablePanelResizer;
