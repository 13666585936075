import React from "react";
import "./InventoryHistoryScreen.css";
import { Utils } from "../../../../../helper/General/Utils";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
export var InventoryHistoryDataType;
(function (InventoryHistoryDataType) {
    InventoryHistoryDataType["STATE"] = "State Change";
    InventoryHistoryDataType["OWNER"] = "Owner Change";
    InventoryHistoryDataType["LINKING"] = "Container Change";
})(InventoryHistoryDataType || (InventoryHistoryDataType = {}));
class InventoryHistoryScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewingIndex: 0
        };
    }
    render() {
        const { data } = this.props;
        const { viewingIndex } = this.state;
        const { inventoriesHistoryDataLists } = data;
        const historyDataList = inventoriesHistoryDataLists[viewingIndex];
        const inventoryHistoryScreenStyle = {
            height: "100%",
            width: "100%",
            borderWidth: "2px",
            borderColor: "#333333",
            borderStyle: "solid",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            backgroundColor: "#FFFFFF"
        };
        const inventoryHistoryScreenMultiSelectRowStyle = {
            height: "50px",
            width: "100%",
            display: "flex",
            margin: StyleUtils.getCssPropertyFromArray([5, 0, 0, 0]),
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            overflowX: "scroll",
            overflowY: "hidden",
            backgroundColor: "#FFFFFF"
        };
        const inventoryHistoryScreenMultiSelectCellStyle = {
            height: "23px",
            width: "150px",
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: StyleUtils.getCssPropertyFromArray([1, 10, 1, 10]),
            margin: StyleUtils.getCssPropertyFromArray([0, 5, 0, 5]),
            textAlign: "center",
            fontSize: "15px",
            color: "#333333",
            backgroundColor: "#FFFFFF"
        };
        const inventoryHistoryHeaderRowStyle = {
            height: "25px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            fontWeight: "bold",
            backgroundColor: "#FFFFFF"
        };
        const inventoryHistoryHeaderCellStyle = {
            height: "22px",
            borderColor: "#333333",
            borderWidth: "1px",
            fontSize: "16px",
            borderStyle: "solid",
            padding: StyleUtils.getCssPropertyFromArray([1, 10, 1, 10])
        };
        const inventoryHistoryRowWrapperStyle = {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            backgroundColor: "#E1E1E1",
            overflowY: "scroll"
        };
        const inventoryHistoryRowStyle = {
            height: "26px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            backgroundColor: "#E1E1E1"
        };
        const inventoryHistoryCellStyle = {
            height: "22px",
            fontSize: "16px",
            padding: StyleUtils.getCssPropertyFromArray([2, 11, 2, 11])
        };
        return (React.createElement("div", { className: "inventory-history-screen", style: inventoryHistoryScreenStyle },
            React.createElement("div", { className: "inventory-history-multi-select-row", style: inventoryHistoryScreenMultiSelectRowStyle }, inventoriesHistoryDataLists
                .sort((a, b) => parseInt(a.displayedText) - parseInt(b.displayedText))
                .map((item, index) => (React.createElement("div", { key: index, className: "inventory-history-multi-select-cell text-unselectable", style: viewingIndex === index
                    ? Object.assign(Object.assign({}, inventoryHistoryScreenMultiSelectCellStyle), { backgroundColor: "#A2DEFF" }) : Object.assign({}, inventoryHistoryScreenMultiSelectCellStyle), onClick: () => this.setState({ viewingIndex: index }) }, item.displayedText)))),
            React.createElement("div", { className: "inventory-history-data-header", style: inventoryHistoryHeaderRowStyle },
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "150px" }) }, "Type"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "130px" }) }, "Date"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "120px" }) }, "State"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "120px" }) }, "Container"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "120px" }) }, "Owner Name"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "100px" }) }, "Owner Type"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "200px" }) }, "Previous Owner Name"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "170px" }) }, "Previous Owner Type"),
                React.createElement("div", { className: "inventory-history-header-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryHeaderCellStyle), { width: "280px" }) }, "Comments")),
            React.createElement("div", { className: "inventory-history-data-row-wrapper", style: inventoryHistoryRowWrapperStyle }, historyDataList.inventoryHistoryDataList.map((row, index) => {
                const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                switch (row.type) {
                    case InventoryHistoryDataType.OWNER: {
                        return (React.createElement("div", { key: index, className: "inventory-history-data-row", style: Object.assign(Object.assign({}, inventoryHistoryRowStyle), { backgroundColor: backgroundColor }) },
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "150px", backgroundColor: backgroundColor }) }, row.type),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "130px", backgroundColor: backgroundColor }) }, Utils.millisDatePostProcessor(row.timestamp)),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, row.newOwner.split("*")[1] === "-1" ? "N/A" : row.newOwnerName),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "100px", backgroundColor: backgroundColor }) }, row.newOwner.split("*")[0] || ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "200px", backgroundColor: backgroundColor }) }, row.oldOwner.split("*")[1] === "-1" ? "N/A" : row.oldOwnerName),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "170px", backgroundColor: backgroundColor }) }, row.oldOwner.split("*")[0] || ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "280px", backgroundColor: backgroundColor }) }, "")));
                    }
                    case InventoryHistoryDataType.STATE: {
                        return (React.createElement("div", { key: index, className: "inventory-history-data-row", style: Object.assign(Object.assign({}, inventoryHistoryRowStyle), { backgroundColor: backgroundColor }) },
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "150px", backgroundColor: backgroundColor }) }, row.type),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "130px", backgroundColor: backgroundColor }) }, Utils.millisDatePostProcessor(row.transitionTime)),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, Utils.snakeCaseToReadable(row.stateName)),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, row.ownerId === "-1" ? "N/A" : row.ownerName),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "100px", backgroundColor: backgroundColor }) }, row.ownerType),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "200px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "170px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "280px", backgroundColor: backgroundColor }) }, row.comments)));
                    }
                    case InventoryHistoryDataType.LINKING: {
                        return (React.createElement("div", { key: index, className: "inventory-history-data-row", style: Object.assign(Object.assign({}, inventoryHistoryRowStyle), { backgroundColor: backgroundColor }) },
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "150px", backgroundColor: backgroundColor }) }, row.type),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "130px", backgroundColor: backgroundColor }) }, Utils.millisDatePostProcessor(row.timestamp)),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, row.parentName),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "120px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "100px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "200px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "170px", backgroundColor: backgroundColor }) }, ""),
                            React.createElement("div", { className: "inventory-history-data-cell text-unselectable", style: Object.assign(Object.assign({}, inventoryHistoryCellStyle), { width: "280px", backgroundColor: backgroundColor }) }, "")));
                    }
                }
            }))));
    }
}
export default InventoryHistoryScreen;
