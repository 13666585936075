import React from "react";
import styled from "styled-components";
const StyledAccordion = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
`;
class Accordion extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(StyledAccordion, { className: "accordion" }, this.props.children);
    }
}
export default Accordion;
