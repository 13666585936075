import React, { useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
export const StyledSelect = styled.select `
  outline: none;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  color: ${props => getVariableColor(props.fontColor, props.isInFocus)};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isInFocus)};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => getVariableColor(props.borderColor, props.isInFocus)};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
`;
export const StyledOption = styled.option `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  text-align: ${props => props.textAlign};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isInFocus)};
  color: ${props => getVariableColor(props.fontColor, props.isInFocus)};
  font-weight: ${props => props.fontWeight};
`;
const getVariableColor = (color, isInFocus) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        return isInFocus ? color.inFocus : color.normal;
    }
};
const SelectInput = (props) => {
    const { options, initialValue = "", height = 16, width = 160, fontSize = 14, backgroundColor = "#FFFFFF", fontColor = "#000000", fontWeight = "normal", textAlign = "left", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleSubmit, handleChange, handleFocus, handleBlur } = props;
    const [isInFocus, setIsInFocus] = useState(false);
    const [value, setValue] = useState(initialValue);
    const onFocus = (e) => {
        setIsInFocus(true);
        if (handleFocus) {
            handleFocus();
        }
    };
    const onBlur = (e) => {
        setIsInFocus(false);
        if (handleBlur) {
            handleBlur();
        }
    };
    const onKeyPress = (e) => {
        const charCode = e.charCode;
        switch (charCode) {
            case 13: { //Enter
                e.preventDefault();
                if (handleSubmit) {
                    handleSubmit(value);
                }
                break;
            }
        }
    };
    const onChange = (e) => {
        var _a;
        const newValue = ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || "";
        setValue(prevState => {
            if (newValue) {
                if (handleChange) {
                    handleChange(newValue);
                }
                return newValue;
            }
        });
    };
    return (React.createElement(StyledSelect, { className: "select", onFocus: onFocus, onBlur: onBlur, onKeyPress: onKeyPress, onChange: onChange, value: value, isInFocus: isInFocus, height: height, width: width, fontSize: fontSize, backgroundColor: backgroundColor, fontColor: fontColor, fontWeight: fontWeight, textAlign: textAlign, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding }, options.map((option, index) => {
        return (React.createElement(StyledOption, { className: "option", key: index, isInFocus: isInFocus, height: height, width: width, fontSize: fontSize, backgroundColor: backgroundColor, fontColor: fontColor, fontWeight: fontWeight, textAlign: textAlign }, option));
    })));
};
export default SelectInput;
