import iconJson from "../../../resources/icons/selection.json";
var Theme;
(function (Theme) {
    Theme.getIconObject = () => {
        var _a, _b;
        const iconObject = {};
        const icons = iconJson.icons || [];
        const sortedIcons = icons.sort((a, b) => {
            var _a, _b;
            const aIconName = ((_a = a === null || a === void 0 ? void 0 : a.properties) === null || _a === void 0 ? void 0 : _a.name) || null;
            const bIconName = ((_b = b === null || b === void 0 ? void 0 : b.properties) === null || _b === void 0 ? void 0 : _b.name) || null;
            if (aIconName === bIconName) {
                return 0;
            }
            else if (aIconName < bIconName) {
                return -1;
            }
            else if (aIconName > bIconName) {
                return 1;
            }
        });
        sortedIcons.forEach(icon => {
            var _a;
            const iconName = ((_a = icon === null || icon === void 0 ? void 0 : icon.properties) === null || _a === void 0 ? void 0 : _a.name) || null;
            if (iconName) {
                iconObject[iconName] = icon;
            }
        });
        return {
            icons: iconObject,
            height: (iconJson === null || iconJson === void 0 ? void 0 : iconJson.height) || 1024,
            metadata: {
                name: ((_a = iconJson === null || iconJson === void 0 ? void 0 : iconJson.metadata) === null || _a === void 0 ? void 0 : _a.name) || "",
                designer: ((_b = iconJson === null || iconJson === void 0 ? void 0 : iconJson.metadata) === null || _b === void 0 ? void 0 : _b.designer) || ""
            }
        };
    };
    Theme.initializeTheme = () => {
        return {
            icon: Theme.getIconObject()
        };
    };
})(Theme || (Theme = {}));
export default Theme;
