import React from "react";
class ResizablePanelRef extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (ref) => {
            this.ref = ref;
            this.props.provided.innerRef(ref);
        };
    }
    render() {
        return (React.createElement("div", Object.assign({ className: "resizable-panel-ref", style: this.props.style, ref: this.setRef }, this.props.provided.droppableProps), this.props.children));
    }
}
export default ResizablePanelRef;
