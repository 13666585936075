'use strict';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import "./Timeline/src/style.css";
import Timeline from "./Timeline/src/timeline";
import { customGroupRenderer, customItemRenderer } from "./Timeline/src/demo/customRenderers";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import distinctColors from "distinct-colors";
import { produce } from "immer";
import { Observable } from "rxjs";
import { Fsrs } from "../../../state/api/REST/Fsrs/Fsrs";
import { RouteStates } from "./RouteStates";
import { getAllJobCodes } from "../../../state/api/REST/JobCode";
import { Utils } from "../../../helper/General/Utils";
import { DispatchPageContainerActionTypes } from "../../../state/containers/DispatchPageContainer/types";
import { getCalculations } from "../../../state/services/Dispatch/service";
import { OrderDirection, PropertyOrderingSelect } from "./PropertyOrderingSelect";
import { DateSlider } from "./DateSlider";
import { useDragLayer } from "react-dnd";
import { DraggableTypes } from "../../../hooks/DragDrop/types";
var fsrGetRowsRequestFactory = Fsrs.fsrGetRowsRequestFactory;
const FsrScheduler = props => {
    const { fsrFilterString, date, settings: { isRecommending } } = useSelector(reduxState => reduxState.dispatch, shallowEqual);
    const [isNotLoaded, setIsNotLoaded] = useState(true);
    const [fieldOrderings, setFieldOrderings] = useState([
        { propertyPath: "COMM_FAILURE", isAscending: false },
        { propertyPath: "TOTAL_ORDERS", isAscending: false },
        { propertyPath: "NAME", isAscending: true }
    ]);
    //For removing routes already being shown
    const state = useSelector((state) => state.dispatch);
    const dispatch = useDispatch();
    const colorMapperRef = useRef(null);
    useEffect(() => {
        getAllJobCodes().then(payload => {
            //Initialize job colors
            const jobCodeToColor = Object.fromEntries(_.zip(distinctColors({ count: payload.length, lightMin: 70 }), payload)
                .map(([color, jobcode]) => [jobcode.id, color.css()]));
            colorMapperRef.current = (orderActivity) => { var _a, _b; return jobCodeToColor[(_b = (_a = orderActivity.order) === null || _a === void 0 ? void 0 : _a.jobCode) === null || _b === void 0 ? void 0 : _b.id] || 'black'; };
            setIsNotLoaded(false);
        });
        //remove shown routes from dispatch table when we switch over
        const mapRoutes = state.mapRoutes;
        for (let route of mapRoutes) {
            dispatch({
                type: DispatchPageContainerActionTypes.HANDLE_MAP_REMOVE_ROUTE_EVENT,
                event: {
                    id: route.id,
                    type: route.type
                }
            });
        }
    }, []);
    const timelineRef = useRef();
    useEffect(() => {
        $("#ftable_refresh")
            .off("click")
            .on("click", (event) => {
            if (timelineRef.current) {
                timelineRef.current.refresh();
            }
        });
        return () => { };
    }, [fsrFilterString, date]);
    const [dateRange, setDateRange] = useState([8, 15]);
    const isDraggingOrder = useDragLayer(monitor => {
        return monitor.getItemType() == DraggableTypes.order;
    });
    const isRecommendingAndDragging = isRecommending && isDraggingOrder;
    //Because recommend doesn't really support getting the count of a recommend in progress, we memoize the results once
    //then use this result later to populate the count and get the results for the react virtualized list. It's possible
    //that we dont use the virtualized timeline to display everything but currently to make everything happy, this works.
    const loadMoreRowsToUse = useMemo(() => {
        if (isNotLoaded) {
            return null;
        }
        if (isRecommendingAndDragging) {
            let immediatelyEvaluated = loadMoreRowsForRecommendFactory(fsrFilterString, moment(date), fieldOrderings)();
            //We ignore all arguments because we've immediately evaluated and recommend is not virtualized at the moment
            return (() => () => immediatelyEvaluated);
        }
        else {
            return loadMoreRowsFactory;
        }
    }, [fsrFilterString, date, fieldOrderings, isNotLoaded, isRecommendingAndDragging]);
    if (isNotLoaded)
        return React.createElement("div", null, "\"Loading...\"");
    const [timelineRangeStart, timelineRangeEnd] = dateRange.map(x => moment(date).hour(x));
    const fsrEvents = sl.fsrEvents;
    const convertToReactTimelineData = convertToReactTimelineDataFactory(colorMapperRef.current);
    const groupChangeEvents = fsrEvents
        .bufferTime(2000)
        .flatMap(x => combineFsrRowsWithTimelines(x))
        .map(y => {
        console.log(y);
        return y.map(x => ([x])).map(convertToReactTimelineData(0));
    });
    const getRowCount = async () => {
        try {
            if (isRecommendingAndDragging) {
                const ignoreArguments = loadMoreRowsToUse;
                let result = await ignoreArguments()({ startIndex: 0, stopIndex: 0 });
                return Object.keys((result === null || result === void 0 ? void 0 : result.groups) || {}).length;
            }
            else {
                return Fsrs.fsrGetCountRequest(fsrFilterString, moment(date));
            }
        }
        catch (e) {
            return 0;
        }
    };
    const rowHeight = isRecommendingAndDragging ? 75 : 37;
    return (React.createElement(Timeline, { ref: timelineRef, key: fsrFilterString + date + `r${isRecommendingAndDragging}` + fieldOrderings[1].propertyPath + fieldOrderings[1].isAscending, shallowUpdateCheck: true, getRowCount: getRowCount, loadMoreRows: loadMoreRowsToUse(fsrFilterString, moment(date), fieldOrderings), itemHeight: rowHeight, groupChangeEvents: groupChangeEvents.share(), filter: fsrFilterString, startDate: timelineRangeStart, endDate: timelineRangeEnd, groupOffset: 300, snapMinutes: 15, itemRenderer: customItemRenderer, 
        //@ts-ignore
        groupRenderer: customGroupRenderer, timelineRenderer: TimeBarDateSlider, groupTitleRenderer: (props) => {
            const propertyPaths = [
                { propertyPath: "TOTAL_ORDERS", propertyName: "Total Orders" },
                { propertyPath: "COMPLETE_ORDERS", propertyName: "Completed Orders" },
                { propertyPath: "REMAINING_ORDERS", propertyName: "Remaining Orders" },
                { propertyPath: "LOGIN", propertyName: "Name" },
                { propertyPath: "ROUTE_STATE", propertyName: "Route State" },
            ];
            return React.createElement(PropertyOrderingSelect, { orderDirection: fieldOrderings[1].isAscending ? OrderDirection.Ascending : OrderDirection.Descending, selectedFilterPath: fieldOrderings[1].propertyPath, propPathToReadableTexts: propertyPaths, onOrderingChange: (evt) => {
                    setFieldOrderings(produce(fieldOrderings, (state) => {
                        state[1] = {
                            propertyPath: evt.propertyPath,
                            isAscending: evt.orderDirection === OrderDirection.Ascending
                        };
                    }));
                } });
        } }));
    function loadMoreRowsFactory(filter, date, fieldOrderings) {
        const fsrRowRequest = fsrGetRowsRequestFactory({ filter, filterDate: date, fieldOrderings });
        return ({ startIndex, stopIndex }) => {
            return Observable.fromPromise(fsrRowRequest(startIndex + 1, stopIndex + 1))
                .map(({ response }) => response)
                .flatMap(combineFsrRowsWithTimelines)
                .map(convertToReactTimelineData(startIndex))
                .toPromise();
        };
    }
    function loadMoreRowsForRecommendFactory(filter, queryDate, fieldOrderings) {
        return async () => {
            //TODO: move gross global state to redux
            //@ts-ignore
            let dispFilters = window.userSettings.user_setting_object[`disp_filters${1}`];
            //@ts-ignore
            let orderIds = [window.orderSelected[0].split(",")[0]];
            let { response: { recommends } } = await Fsrs.getRecommends(queryDate.toISOString(), orderIds, dispFilters);
            let todayAndFutureRecommendData = recommends
                .flatMap((recommendationsOnOrder) => recommendationsOnOrder.recommends)
                .map((fsrRecommendData) => {
                let [todayRecommendation, ...futureRecommendations] = fsrRecommendData.fsrRecommends;
                futureRecommendations.sort((a, b) => a.distance - b.distance);
                let bestFutureRecommendation = futureRecommendations[0];
                let todayBest;
                let futureBest;
                if (todayRecommendation)
                    todayBest = getCalculations(todayRecommendation);
                if (bestFutureRecommendation)
                    futureBest = getCalculations(bestFutureRecommendation);
                return { fsrId: fsrRecommendData.fsrId, todayBest, futureBest };
            });
            let composedFilterQuery = { $and: [{ FSR_ID: { $in: todayAndFutureRecommendData.map(x => x.fsrId.toString()) } }] };
            const fsrRowRequest = fsrGetRowsRequestFactory({
                filter: composedFilterQuery,
                filterDate: date,
                fieldOrderings,
            });
            let schedulerRows = await Observable.fromPromise(fsrRowRequest(1, 2147483647)) //the filter limits how many fsrs we get back
                .map(({ response }) => response)
                .flatMap(combineFsrRowsWithTimelines)
                .toPromise();
            let schedulerRowsWithRecommendations = todayAndFutureRecommendData
                .map(recommendations => {
                let matchingRowToRecommend = schedulerRows.find(row => parseInt(row[0].FSR_ID) == recommendations.fsrId);
                let [fsrData, timeTable] = matchingRowToRecommend;
                fsrData.recommendations = recommendations;
                return matchingRowToRecommend;
            });
            return convertToReactTimelineData(0)(schedulerRowsWithRecommendations);
        };
    }
    function combineFsrRowsWithTimelines(fsrObjects) {
        let fsrIds = fsrObjects.map(fsrObj => parseInt(fsrObj.FSR_ID));
        console.log(fsrObjects.map(fs => fs.REMAINING_ORDERS));
        if (fsrIds.length) {
            let currentDateMoment = moment(date);
            return Observable.fromPromise(Fsrs
                //TODO: currently makes a hard assumption that we will
                //only ever want to get timetables for one date.
                //It will break the display logic if you try to give it an interval
                .getTimeTables(currentDateMoment, currentDateMoment, fsrIds)
                .then(({ response: timetables }) => {
                //TODO: because this function is shared with updating event data; you have a situation
                //where it is not the case that the fsr objects being passed in are unique in terms of their ID;
                // so we need to update each one with it's matching timetable.
                const selectFSRID = x => parseInt(x.FSR_ID);
                let joinedRows = Utils.leftJoinViaPropertySelectors([_.uniqBy(fsrObjects, selectFSRID), selectFSRID], [timetables, (time) => time.workerId]);
                return joinedRows.flatMap(([fsr, tt]) => fsrObjects
                    .filter(x => selectFSRID(x) == selectFSRID(fsr))
                    .map(unkeyedFsr => {
                    return [unkeyedFsr, tt];
                }));
            })
                .then(results => results.map(([fsr, timetable]) => fsr.ROUTE_STATE == RouteStates.ROUTE_SUCCESS ? [fsr, timetable] : [fsr, null])));
        }
        else {
            return Observable.empty();
        }
    }
    function TimeBarDateSlider() {
        return React.createElement(DateSlider, { dateRange: dateRange, updateDate: (event, range) => setDateRange(range) });
    }
};
const convertToReactTimelineDataFactory = (colorMapper) => (startIndex) => (fsrsWithTimeTables) => {
    let [groups, timeBlocks, rowLayers] = fsrsWithTimeTables.reduce((timeTableRowData, [fsr, timeTable], idx) => {
        let [groups, items, rowLayers] = timeTableRowData;
        let rowOffset = startIndex + idx;
        groups.push(Object.assign({ rowId: rowOffset, timeTable }, fsr));
        if (timeTable) {
            let foregroundActivityTypes = new Set(['TravelTime', 'Order']);
            let { true: foregroundTimeBlocks, false: nonForeGroundTimeBlocks } = _.groupBy(timeTable.timeBlocks, tb => tb.activities.some(act => foregroundActivityTypes.has(act.type))
                && tb.activities.every(act => "RoutingBreak" !== act.type));
            rowLayers.push(...nonForeGroundTimeBlocks
                .filter(tb => tb.activities[0].type != 'RoutingBreak') // We currently don't display the routing break
                .flatMap(timeBlock => timeBlock.activities.map(activity => {
                let backgroundColor = 'red';
                if (activity.type == 'Shift')
                    backgroundColor = 'gray';
                else
                    backgroundColor = 'darkgray';
                return {
                    start: moment(activity.start),
                    end: moment(activity.end),
                    rowNumber: rowOffset,
                    style: { backgroundColor: backgroundColor, opacity: '0.3' }
                };
            })));
            foregroundTimeBlocks = foregroundTimeBlocks || [];
            items.push(...foregroundTimeBlocks.map((tb) => {
                let timeBlockActivity = tb.activities.filter(x => x.type === "Order")[0];
                return {
                    key: `${timeTable.scheduleKey}-${timeBlockActivity.activityId}`,
                    scheduleKey: timeTable.scheduleKey,
                    color: colorMapper(timeBlockActivity),
                    start: moment(tb.start),
                    end: moment(tb.end),
                    row: rowOffset,
                    originalTimeBlock: tb
                };
            }));
        }
        return timeTableRowData;
    }, [[], [], []]);
    return {
        groups: _.keyBy(groups, x => x.rowId),
        items: timeBlocks,
        rowLayers
    };
};
export default FsrScheduler;
