import React from "react";
import { connect } from "react-redux";
import Filter from "../../components/Filter/Filter";
import { bindActionCreators } from "redux";
import { createMutateAction, deleteFilterRequest, filterRowDeleteHandler, filterRowToggleExpandHandler, inventoryFilterFieldsChanged, resetHandler, saveFilterRequest, updateHandler, workingFilterChangeRequestHandler } from "../../../state/containers/InventoryFilterContainer/action";
import { FILTER_ROW_TYPE } from "../../components/Filter/FilterList/FilterRow/FilterRow";
class InventoryFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.getFilterControlProps = () => {
            const { savedConfigurations, workingFilterChangeRequestHandler, saveFilterHandler, deleteFilterHandler, fieldsToFilterOnChangedHandler, resetToDefaultHandler } = this.props;
            const filterControlActions = {
                getFilterConfigurationHandler: workingFilterChangeRequestHandler,
                saveFilterHandler: saveFilterHandler,
                deleteFilterHandler: deleteFilterHandler,
                fieldsToFilterOnChangedHandler: fieldsToFilterOnChangedHandler,
                resetToDefaultHandler: resetToDefaultHandler
            };
            const filterControlState = {
                filterNames: Object.entries(savedConfigurations).map(([key, value]) => key)
            };
            const filterControlData = {
                workingConfigurationName: this.props.workingConfigurationName,
                workingConfiguration: this.props.workingConfiguration,
                schemas: this.props.schemas
            };
            return {
                filterControlActions: filterControlActions,
                filterControlData: filterControlData,
                filterControlState: filterControlState
            };
        };
        this.getFilterListProps = () => {
            const { schemas, workingConfiguration, filterRowRemoveHandler, filterRowToggleExpandHandler } = this.props;
            const filterData = workingConfiguration;
            const filterRowCommonActions = {
                filterRowRemoveHandler: filterRowRemoveHandler,
                filterRowToggleExpandHandler: filterRowToggleExpandHandler
            };
            const filterRowAndWrapperProps = filterData.filterRowsData
                .map((filterRowData) => {
                return Object.assign(Object.assign({}, schemas[filterRowData.name]), filterRowData);
            })
                .map((filterRowData, index) => this.getFilterRowDataAndAction(filterRowData, index));
            return {
                filterRowCommonActions: filterRowCommonActions,
                filterRowAndWrapperProps: filterRowAndWrapperProps
            };
        };
        this.getFilterRowDataAndAction = (filterRowData, index) => {
            let indexProp = { index };
            switch (filterRowData.type) {
                case FILTER_ROW_TYPE.CHECK_BOX:
                    const checkBoxFilterRowActions = {
                        checkBoxFilterRowCheckBoxChange: this.props.checkBoxFilterRowCheckBoxChange
                    };
                    return Object.assign(Object.assign(Object.assign({}, filterRowData), indexProp), { filterRowActions: checkBoxFilterRowActions });
                case FILTER_ROW_TYPE.DATE:
                    const dateFilterRowActions = {};
                    return Object.assign(Object.assign(Object.assign({}, filterRowData), indexProp), { filterRowActions: dateFilterRowActions });
                case FILTER_ROW_TYPE.MULTI_SELECT:
                    const multiSelectFilterRowActions = {
                        multiSelectUpdated: this.props.multiSelectUpdated
                    };
                    return Object.assign(Object.assign(Object.assign({}, filterRowData), indexProp), { filterRowActions: multiSelectFilterRowActions });
                case FILTER_ROW_TYPE.STRING:
                    const stringFilterRowActions = {
                        stringFilterRowApplyFilter: this.props.stringFilterRowApplyFilter
                    };
                    return Object.assign(Object.assign(Object.assign({}, filterRowData), indexProp), { filterRowActions: stringFilterRowActions });
            }
        };
    }
    componentDidMount() {
        this.props.workingFilterChangeRequestHandler({ key: this.props.workingConfigurationName, text: "nothing" });
    }
    render() {
        return React.createElement(Filter, { filterListProps: this.getFilterListProps(), filterControlProps: this.getFilterControlProps() });
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        workingConfigurationName: state.inventory.inventoryFilterContainer.workingConfigurationName,
        workingConfiguration: state.inventory.inventoryFilterContainer.workingConfiguration,
        savedConfigurations: state.inventory.inventoryFilterContainer.savedConfigurations,
        schemas: state.inventory.inventoryFilterContainer.schemas
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    let identity = () => { };
    let handlersThatUpdateFilterState = {
        //FilterControlHandlers
        workingFilterChangeRequestHandler: workingFilterChangeRequestHandler,
        resetToDefaultHandler: resetHandler,
        filterRowRemoveHandler: filterRowDeleteHandler,
        checkBoxFilterRowCheckBoxChange: updateHandler,
        //DateFilter needed
        multiSelectUpdated: updateHandler,
        stringFilterRowApplyFilter: updateHandler
    };
    let augmentedHandlersThatDontUpdateState = bindActionCreators({
        filterRowToggleExpandHandler: filterRowToggleExpandHandler,
        fieldsToFilterOnChangedHandler: inventoryFilterFieldsChanged,
        saveFilterHandler: saveFilterRequest,
        deleteFilterHandler: deleteFilterRequest
    }, dispatch);
    let augmentedHandlersThatUpdateState = Object.entries(handlersThatUpdateFilterState).reduce((accumulate, [handlerName, handlerFunc]) => {
        accumulate[handlerName] = (...args) => {
            dispatch(createMutateAction(handlerFunc.apply(undefined, args)));
        };
        return accumulate;
    }, {});
    return Object.assign(Object.assign({}, augmentedHandlersThatDontUpdateState), augmentedHandlersThatUpdateState);
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryFilterContainer);
