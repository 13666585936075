import React from "react";
import "./ContextMenuOption.css";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import SimpleIcon from "../../SimpleIcon/SimpleIcon";
const StyledContextMenuOption = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height || "1.4rem")};
  width: ${props => StyleUtils.getCssPixelString(props.width || "14rem")};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.padding || "0.2rem 0.5rem")};
  border-style: solid;
  border-width: ${props => StyleUtils.getCssPropertyFromArray(props.borderWidth || "0.1rem")};
  background-color: ${props => {
    let backgroundColor = props.normalStyle
        ? props.normalStyle.backgroundColor || props.palette.white
        : props.palette.white;
    if (props.isMouseOver) {
        backgroundColor = props.onHoverStyle
            ? props.onHoverStyle.backgroundColor || props.palette.themeLight
            : props.palette.themeLight;
    }
    if (props.isMouseDown) {
        backgroundColor = props.onMouseDownStyle
            ? props.onMouseDownStyle.backgroundColor || props.palette.themePrimary
            : props.palette.themePrimary;
    }
    return backgroundColor;
}};
  border-color: ${props => {
    let borderColor = props.normalStyle
        ? props.normalStyle.borderColor || props.palette.black
        : props.palette.black;
    if (props.isMouseOver) {
        borderColor = props.onHoverStyle ? props.onHoverStyle.borderColor || props.palette.black : props.palette.black;
    }
    if (props.isMouseDown) {
        borderColor = props.onMouseDownStyle
            ? props.onMouseDownStyle.borderColor || props.palette.black
            : props.palette.black;
    }
    return borderColor;
}};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
const StyledContextMenuOptionSeparator = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height || "1.4rem")};
  width: ${props => StyleUtils.getCssPixelString(props.width || "0.5rem")};
`;
const StyledContextMenuOptionTextWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height || "1.4rem")};
  width: ${props => !!props.iconName
    ? StyleUtils.getCssPixelString(props.width || "12.1rem")
    : StyleUtils.getCssPixelString(props.width || "14rem")};
  font-size: ${props => StyleUtils.getCssPixelString(props.fontSize || "1.4rem")};
  line-height: ${props => StyleUtils.getCssPixelString(props.fontSize || "1.4rem")};
  font-weight: ${props => props.fontWeight || "normal"};
  text-align: ${props => props.textAlign || "left"};
  color: ${props => {
    let color = props.normalStyle ? props.normalStyle.color || props.palette.black : props.palette.black;
    if (props.isMouseOver) {
        color = props.onHoverStyle ? props.onHoverStyle.color || props.palette.black : props.palette.black;
    }
    if (props.isMouseDown) {
        color = props.onMouseDownStyle ? props.onMouseDownStyle.color || props.palette.white : props.palette.white;
    }
    return color;
}};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
class ContextMenuOption extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleMouseDown = (event) => {
            if (this.props.contextMenuOptionActions.handleMouseDown) {
                this.props.contextMenuOptionActions.handleMouseDown();
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: true })));
        };
        this.handleMouseUp = (event) => {
            if (this.props.contextMenuOptionActions.handleMouseUp) {
                this.props.contextMenuOptionActions.handleMouseUp();
            }
            if (this.contextMenuOption.current === event.currentTarget) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (this.props.contextMenuOptionActions.onLeftClick && this.state.isMouseDown) {
                this.props.contextMenuOptionActions.onLeftClick({
                    identifier: this.props.contextMenuOptionDataProps.identifier
                });
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: false })));
        };
        this.handleOutOfBoundsMouseUp = (event) => {
            if (this.props.contextMenuOptionActions.handleMouseUp) {
                this.props.contextMenuOptionActions.handleMouseUp();
            }
            if (this.contextMenuOption.current === event.currentTarget) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: false })));
        };
        this.handleMouseEnter = (event) => {
            this.setState({ isMouseOver: true });
        };
        this.handleMouseLeave = (event) => {
            this.setState({ isMouseOver: false });
        };
        this.state = {
            isMouseOver: false,
            isMouseDown: false
        };
        this.contextMenuOption = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mouseup", this.handleOutOfBoundsMouseUp);
    }
    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleOutOfBoundsMouseUp);
    }
    render() {
        const { displayedText, iconName, height, normalStyle, onHoverStyle, onMouseDownStyle } = this.props.contextMenuOptionDataProps;
        const { isMouseOver, isMouseDown } = this.state;
        let color = normalStyle ? normalStyle.color || this.theme.palette.black : this.theme.palette.black;
        if (isMouseOver) {
            color = onHoverStyle ? onHoverStyle.color || this.theme.palette.black : this.theme.palette.black;
        }
        if (isMouseDown) {
            color = onMouseDownStyle ? onMouseDownStyle.color || this.theme.palette.white : this.theme.palette.white;
        }
        return (React.createElement(StyledContextMenuOption, Object.assign({ className: "context-menu-option", onMouseDown: this.handleMouseDown, onMouseUp: this.handleMouseUp, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave, ref: this.contextMenuOption }, this.props.contextMenuOptionDataProps, this.state, this.theme), iconName ? (React.createElement(React.Fragment, null,
            React.createElement(SimpleIcon, { iconName: iconName, color: color, size: StyleUtils.getCssPixelString(height || "1.4rem"), offsetY: "-0.3rem" }),
            React.createElement(StyledContextMenuOptionSeparator, Object.assign({ className: "context-menu-option-separator" }, this.props.contextMenuOptionDataProps, this.state, this.theme)),
            React.createElement(StyledContextMenuOptionTextWrapper, Object.assign({ className: "context-menu-option-text-wrapper" }, this.props.contextMenuOptionDataProps, this.state, this.theme), displayedText))) : (React.createElement(StyledContextMenuOptionTextWrapper, Object.assign({ className: "context-menu-option-text-wrapper" }, this.props.contextMenuOptionDataProps, this.state, this.theme), displayedText))));
    }
}
export default ContextMenuOption;
