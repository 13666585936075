import { MAX_RESULT_PER_REQUEST } from "../../../../helper/WebSocket/WebSocketService";
import axios from "axios";
import { FsrUtils } from "../../../../helper/General/FsrUtils";
import moment from "moment";
import { Utils } from "../../../../helper/General/Utils";
import _ from "lodash";
import $ from "jquery";
export var Fsrs;
(function (Fsrs) {
    Fsrs.FsrsServiceName = "/sl3/api/fsrs";
    let FsrsActionName;
    (function (FsrsActionName) {
        FsrsActionName["FSRS"] = "";
    })(FsrsActionName = Fsrs.FsrsActionName || (Fsrs.FsrsActionName = {}));
    function fsrGetCountRequest(filter = null, filterDate = null) {
        let queryDateFilter = filterDate ? moment(filterDate).format("YYYY-MM-DD") : null;
        return axios({
            method: "post",
            url: "/sl3/api/fsrs",
            params: { filter, filter_date: queryDateFilter },
        }).then(({ data: { count } }) => count);
    }
    Fsrs.fsrGetCountRequest = fsrGetCountRequest;
    Fsrs.fsrGetRowsRequestFactory = ({ filter = null, filterDate = null, fieldOrderings = [], winnum = 1 }) => (start, end) => {
        let queryDateFilter = filterDate ? moment(filterDate).format("YYYY-MM-DD") : null;
        return fsrGetRowsRequestHelper(start, end, filter, queryDateFilter, fieldOrderings, winnum);
    };
    function fsrGetRowsRequestHelper(start, end, filter = null, filterDate = null, fieldOrderings, winnum = 1) {
        const formData = Object.entries({
            filter,
            filter_date: filterDate,
            start,
            end,
            winnum,
            sort: _.join(fieldOrderings.map(x => x.propertyPath)),
            asc: _.join(fieldOrderings.map(x => x.isAscending)),
            columns: "STATE_ID,REMAINING_ORDERS,GPS_DEVICE_STATUS,FSR_NOTES,COMPLETE_ORDERS,COMMENTS,VEHICLE_TYPE,NAME,VEHICLE_STATE,SHIFT,TOTAL_ORDERS,AREA,ASSIGNED_ORDERS_CHECKBOX,COMMUNICATION_OFFLINE"
        })
            .filter(([_, value]) => value != null)
            .reduce((acc, [key, value]) => {
            acc.append(key, value);
            return acc;
        }, new URLSearchParams());
        return axios({
            method: "post",
            data: formData,
            url: Fsrs.FsrsServiceName
        }).then(({ data: { data } }) => ({
            success: true,
            response: data
        }));
    }
    Fsrs.getFsrById = (fsrId, date) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "POST",
                url: "/sl3/api/fsrs",
                data: `start=1&end=50&columns=STATE_ID&filter={"$and":[{"FSR_ID":{"$in":["${fsrId}"]}}]}&filter_date=${date}&asc=false,true&loadtype=full&winnum=1`,
                dataType: "json",
                async: true,
                cache: false,
                success: (rsp) => {
                    resolve(rsp);
                },
                error: (e) => {
                    console.log(e);
                }
            });
        });
    };
    function getRawHtmlTooltipForFsrProperty(fsrId, propertyName, dispatchDate) {
        let queryDateFilter = dispatchDate ? moment(dispatchDate).format("YYYY-MM-DD") : null;
        return axios({
            method: "post",
            params: {
                tooltip: true,
                id: fsrId,
                field: propertyName,
                filter_date: queryDateFilter
            },
            url: Fsrs.FsrsServiceName
        }).then(({ data }) => ({
            success: true,
            response: data
        }));
    }
    Fsrs.getRawHtmlTooltipForFsrProperty = getRawHtmlTooltipForFsrProperty;
    function fsrsRequest() {
        const url = `${Fsrs.FsrsServiceName}${FsrsActionName.FSRS}`;
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: url,
                data: `start=1&end=${MAX_RESULT_PER_REQUEST}`
            })
                .then(response => {
                const serverFsrsObject = JSON.parse(response.request.response);
                const serverFsrObjects = serverFsrsObject.data || [];
                const fsrObjects = serverFsrObjects.map(serverFsrObject => FsrUtils.getFsrFromServerObject(serverFsrObject));
                resolve({
                    success: true,
                    response: fsrObjects
                });
            })
                .catch(error => {
                resolve({
                    success: false,
                    error: error
                });
            });
        });
    }
    Fsrs.fsrsRequest = fsrsRequest;
    function getTimeTables(start, end, fsrIds) {
        let x_ServiceLink_Auth = document.cookie.split("; " + "X-ServiceLink-Auth" + "=")[1];
        if (x_ServiceLink_Auth.includes(";")) {
            x_ServiceLink_Auth = x_ServiceLink_Auth.substr(0, x_ServiceLink_Auth.indexOf(";"));
        }
        return axios({
            method: "POST",
            url: "/sl/schedule/timetables_new",
            data: {
                workers: fsrIds,
                start_date: start.toISOString(true),
                end_date: end.toISOString(true)
            },
            headers: {
                "X-ServiceLink-Auth": x_ServiceLink_Auth
            }
        })
            .then(response => ({
            success: true,
            response: Utils.snakeToCamelCaseObject(response.data, [])
        }));
    }
    Fsrs.getTimeTables = getTimeTables;
    function getRecommendOnSingleFsr(requestDate, orderId, fsrId, searchRangeInDays = 7) {
        let x_ServiceLink_Auth = document.cookie.split("; " + "X-ServiceLink-Auth" + "=")[1];
        if (x_ServiceLink_Auth.includes(";")) {
            x_ServiceLink_Auth = x_ServiceLink_Auth.substr(0, x_ServiceLink_Auth.indexOf(";"));
        }
        return axios({
            method: "POST",
            url: `/sl/recommend/fsr`,
            data: Utils.camelToSnakeCaseObject({ requestDate, orderId, fsrId, searchRangeInDays }, []),
            headers: {
                "Content-Type": "application/json",
                "X-ServiceLink-Auth": x_ServiceLink_Auth
            },
        }).then(response => ({ success: true, response: Utils.snakeToCamelCaseObject(response.data, []) }));
    }
    Fsrs.getRecommendOnSingleFsr = getRecommendOnSingleFsr;
    function getRecommends(isoDateString, orderIds, dispatchFilters = "NoopFilter") {
        let x_ServiceLink_Auth = document.cookie.split("; " + "X-ServiceLink-Auth" + "=")[1];
        if (x_ServiceLink_Auth.includes(";")) {
            x_ServiceLink_Auth = x_ServiceLink_Auth.substr(0, x_ServiceLink_Auth.indexOf(";"));
        }
        return axios({
            method: "POST",
            url: `/sl/schedule/recommend`,
            data: {
                order_ids: orderIds,
                dispatch_filters: dispatchFilters,
                request_date: isoDateString
            },
            headers: {
                "Content-Type": "application/json",
                "X-ServiceLink-Auth": x_ServiceLink_Auth
            },
        }).then(response => ({ success: true, response: Utils.snakeToCamelCaseObject(response.data, []) }));
    }
    Fsrs.getRecommends = getRecommends;
})(Fsrs || (Fsrs = {}));
