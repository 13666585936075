import React from "react";
import { Icon, mergeStyles } from "office-ui-fabric-react";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
class SimpleIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { iconName, color, size, width, height, offsetY, offsetX } = this.props;
        const selector = {};
        selector[`.${iconName}`] = {
            fill: color
        };
        const rawStyle = {
            width: StyleUtils.getCssPixelString(width || size),
            height: StyleUtils.getCssPixelString(height || size),
            position: "relative",
            top: StyleUtils.getCssPixelString(offsetY || 0),
            left: StyleUtils.getCssPixelString(offsetX || 0),
            selectors: selector
        };
        return React.createElement(Icon, { iconName: iconName, className: mergeStyles(iconName, rawStyle) });
    }
}
export default SimpleIcon;
