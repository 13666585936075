import { InventoryFilterActionTypes } from "./types";
export const initialState = {
    inventoryWarehouseFilter: [],
    inventoryFilter: [],
    inventoryOmniSearchFilter: []
};
export function inventoryFilterReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryFilterActionTypes.SET_INVENTORY_FILTER_STATE: {
            return action.inventoryFilterState;
        }
        default:
            return state;
    }
}
