import { call, put, select, takeEvery, throttle, debounce } from "redux-saga/effects";
import { DispatchPageContainerActionTypes } from "./types";
import { DispatchResultsType, dispatchShowCalendar, dispatchShowModal } from "./actions";
import Map from "../../../state/api/REST/Map/Map";
import { CrewMapIconColor, CrewState, ErrorMapIconColor, FsrMapIconColor, FsrState, FsrStateMapIconName, MapIconStyleType, MixedMapIconColor, OrderIdToOrderState, OrderMapIconColor, OrderStateMapIconName, RoutingMapIconColor, RoutingUniqueColors, VehicleMapIconColor, VehicleStatus, VehicleType, VehicleTypeMapIconName } from "../../../display/web/components/Map/types";
import moment from "moment";
import chroma from "chroma-js";
import { Utils } from "../../../helper/General/Utils";
import { Routing } from "../../api/REST/Routing/Routing";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import { getFsrsCalendarEvents } from "../../services/Dispatch/service";
import { Orders } from "../../api/REST/Orders/Orders";
import { take } from "@redux-saga/core/effects";
var modifyOrders = Orders.modifyOrders;
import { Filter } from "../../api/REST/Filter/Filter";
import { OrderAPI } from "../../api/REST/Order/Order";
import IndexedDBService, { IndexedDBKeys } from "../../../helper/IndexedDB/IndexedDBService";
import $ from "jquery";
import { MapUtils } from "../../../display/web/uils/MapUtils";
export const dispatchPageContainerSagas = {
    initializeSaga: takeEvery(DispatchPageContainerActionTypes.INITIALIZE, initializeSaga),
    handleMapSizeChangeEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_SIZE_CHANGE_EVENT, handleMapSizeChangeEvent),
    handleMapExtentChangeEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_EXTENT_CHANGE_EVENT, handleMapExtentChangeEvent),
    updateMapIconsSaga: takeEvery(DispatchPageContainerActionTypes.UPDATE_MAP_ICONS, updateMapIconsSaga),
    updateEventMapIconsSaga: takeEvery(DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS, updateEventMapIconsSaga),
    updateMapSelectSaga: takeEvery(DispatchPageContainerActionTypes.UPDATE_MAP_SELECT, updateMapSelectSaga),
    handleMapSelectEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_SELECT_EVENT, handleMapSelectEvent),
    handleMapUnselectEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_UNSELECT_EVENT, handleMapUnselectEvent),
    handleMapMultiSelectEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_EVENT, handleMapMultiSelectEvent),
    handleMapMultiSelectCtrlEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_CTRL_EVENT, handleMapMultiSelectCtrlEvent),
    handleMapDisplayRouteEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_DISPLAY_ROUTE_EVENT, handleMapDisplayRouteEvent),
    handleMapDragEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_DRAG_EVENT, handleMapDragEvent),
    handleMapHoverEvent: debounce(500, DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_EVENT, handleMapHoverEvent),
    handleMapHoverEndEvent: throttle(500, DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_END_EVENT, handleMapHoverEndEvent),
    handleDispatchOrdersEvent: takeEvery(DispatchPageContainerActionTypes.DISPATCH_ORDERS, handleDispatchOrders),
    handleSaveFilterEvent: takeEvery(DispatchPageContainerActionTypes.SAVE_FILTER, handleSaveFilterEvent),
    handleMapTreeDataChangeEvent: takeEvery(DispatchPageContainerActionTypes.HANDLE_MAP_TREE_DATA_CHANGE_EVENT, handleMapTreeDataChangeEvent),
    setThemeSaga: takeEvery(DispatchPageContainerActionTypes.SET_THEME, setThemeSaga),
};
function* initializeSaga(action) {
    yield call(IndexedDBService.initialize);
    const mapSettings = yield call(Map.getMapSettings);
    const esriLayerGroups = [];
    const esriCustomLayerDisplaySettings = [];
    if (mapSettings.urls) {
        const indexedDBEsriMapLayerSettings = yield call(IndexedDBService.get, IndexedDBKeys.ESRI_MAP_LAYER_SETTINGS);
        const esriMapLayerSettings = indexedDBEsriMapLayerSettings || [];
        const matchingEsriMapLayerSettings = esriMapLayerSettings.filter(setting => mapSettings.urls.includes(setting.url));
        let esriToken = null;
        if (mapSettings.tokenUrl) {
            //token fetched once for layer groups. Token fetched separately for map layers (see ESRIMap.tsx::initialize)
            esriToken = yield call(Map.fetchEsriToken, mapSettings.tokenUrl, mapSettings.tokenMethod, mapSettings.tokenRequest);
            sessionStorage.setItem(MapUtils.ESRI_URL_PREFIX, mapSettings.urlPrefix);
        }
        //must use esriCustomLayerDisplaySettings.hiddenLayerIds, else hidden layers will be unselected but still display
        let hiddenLayerNames = [];
        esriLayerGroups.push(...yield call(Map.getLayerGroups, mapSettings.urls, mapSettings.featureLayerNames, esriToken, hiddenLayerNames));
        const esriLayerTree = MapUtils.getESRILayerTree(esriLayerGroups, matchingEsriMapLayerSettings);
        yield put({
            type: DispatchPageContainerActionTypes.SET_MAP_LAYER_TREE,
            mapLayerTree: esriLayerTree,
        });
        mapSettings.urls.forEach(url => {
            const mapSetting = matchingEsriMapLayerSettings.find(setting => setting.url === url);
            if (!!mapSetting) {
                esriCustomLayerDisplaySettings.push(mapSetting);
            }
            else {
                esriCustomLayerDisplaySettings.push({
                    url: url,
                    disabledLayerIds: [],
                    hiddenLayerNames: hiddenLayerNames,
                });
            }
        });
    }
    const filterSettings = yield call(Filter.getFilterSettings);
    const state = yield select(getDispatchPageContainerStateSelector);
    yield put({
        type: DispatchPageContainerActionTypes.SET_DISPATCH_PAGE_CONTAINER_STATE,
        state: Object.assign(Object.assign({}, state), { mapUrlPrefix: mapSettings.urlPrefix, mapProxyUrl: mapSettings.proxyUrl, mapIconSize: mapSettings.iconSize, mapMaxChildrenCount: mapSettings.maxChildrenCount, filterSettings: filterSettings, theme: action.theme, esriCustomLayerDisplaySettings: esriCustomLayerDisplaySettings, esriCustomLayerGroups: esriLayerGroups, esriCustomLayerPopupsEnabled: mapSettings.featureLayerPopupsEnabled })
    });
}
function* handleMapSizeChangeEvent(action) {
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_SIZE,
        size: action.event
    });
    yield put({
        type: DispatchPageContainerActionTypes.UPDATE_MAP_ICONS
    });
}
function* handleMapExtentChangeEvent(action) {
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_EXTENT,
        extent: action.event
    });
    yield put({
        type: DispatchPageContainerActionTypes.UPDATE_MAP_ICONS
    });
}
function* updateMapIconsSaga(action) {
    const state = yield select(getDispatchPageContainerStateSelector);
    const size = state.mapSize;
    const extent = state.mapExtent;
    const fsrVehMode = state.fsrVehMode;
    const req = Object.assign(Object.assign(Object.assign({}, size), extent), { date: moment(state.date).format("YYYY-MM-DD"), order_filter: state.orderFilterString ? decodeURIComponent(state.orderFilterString) : state.orderFilterString, fsr_filter: fsrVehMode === "fsr" ? state.fsrFilterString : '{"FSR_ID":"-1"}', vehicle_filter: fsrVehMode === "vehicle" ? state.vehicleFilterString : '{"id":"-1"}', max_children_count: state.mapMaxChildrenCount });
    const mapIconsData = yield call(Map.getMapIconsData, req);
    const theme = state.theme;
    const mapIconsNoSelect = mapIconsData.map((mapIconData, index) => getMapIconFromMapIconData(mapIconData, theme, fsrVehMode, index));
    const mapIcons = getMapIconsWithSelect(mapIconsNoSelect, state.selectedOrderIds, state.selectedFsrIds, state.selectedOrderIds);
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_ICONS,
        mapIcons: mapIcons
    });
}
function* updateEventMapIconsSaga(action) {
    const state = yield select(getDispatchPageContainerStateSelector);
    if (state.mapFinishedLoading === true) {
        const data = action.data;
        const windowObj = window;
        if (data.method === windowObj.sl.method.ROW_ADD) {
            if (data.tblName === "fsr") {
                const mapIconData = {
                    lat: parseFloat(data.lat),
                    lon: parseFloat(data.lon),
                    childrenCount: 1,
                    emergency: false,
                    sameState: true,
                    sameStatus: false,
                    type: "fsr",
                    state: parseInt(data.STATE_ID),
                    status: 0,
                    children: [{
                            name: data.LOGIN + "",
                            legacyIdentifier: `${data.FSR_ID},${data.STATE_ID},${data.LOGIN}`,
                            type: "fsr",
                            id: parseInt(data.FSR_ID),
                            emergency: false,
                            state: parseInt(data.STATE_ID),
                            status: 0,
                            customIcon: "",
                        }],
                    orderIds: [],
                    fsrIds: [parseInt(data.FSR_ID)],
                    crewIds: [],
                    vehicleIds: [],
                    projectIds: [],
                    customIcon: "",
                };
                const theme = state.theme;
                const mapIcon = getMapIconFromMapIconData(mapIconData, theme, "fsr", state.mapIcons.length);
                yield put({
                    type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_ADD,
                    data: {
                        mapIcon: mapIcon,
                    },
                });
            }
            else if (data.tblName === "order") {
                const mapIconData = {
                    lat: parseFloat(data.lat),
                    lon: parseFloat(data.lon),
                    childrenCount: 1,
                    emergency: !!data.EMG,
                    sameState: true,
                    sameStatus: false,
                    type: "order",
                    state: parseInt(data.STATE_ID),
                    status: 0,
                    children: [{
                            name: data.ORDER_NUMBER + "",
                            legacyIdentifier: `${data.ORDER_ID},${data.STATE_ID},${data.ORDER_NUMBER}`,
                            type: "order",
                            id: parseInt(data.ORDER_ID),
                            emergency: !!data.EMG,
                            state: parseInt(data.STATE_ID),
                            status: 0,
                            customIcon: "",
                        }],
                    orderIds: [parseInt(data.ORDER_ID)],
                    fsrIds: [],
                    crewIds: [],
                    vehicleIds: [],
                    projectIds: [],
                    customIcon: "",
                };
                const theme = state.theme;
                const mapIcon = getMapIconFromMapIconData(mapIconData, theme, "fsr", state.mapIcons.length);
                yield put({
                    type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_ADD,
                    data: {
                        mapIcon: mapIcon,
                    },
                });
            }
        }
        else if (data.method === windowObj.sl.method.ROW_DELETE) {
            let id = -1;
            state.mapIcons.forEach(mapIcon => {
                if (mapIcon.childrenCount === 1 && mapIcon.fsrIds.length === 1 && data.tblName === "fsr") {
                    if (mapIcon.fsrIds[0] === parseInt(data.FSR_ID)) {
                        id = data.FSR_ID;
                    }
                }
                else if (mapIcon.childrenCount === 1 && mapIcon.orderIds.length === 1 && data.tblName === "order") {
                    if (mapIcon.fsrIds[0] === parseInt(data.ORDER_ID)) {
                        id = data.ORDER_ID;
                    }
                }
            });
            if (id !== -1) {
                yield put({
                    type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_DELETE,
                    data: {
                        type: data.tblName,
                        id: id,
                    },
                });
            }
        }
        else if (data.method === windowObj.sl.method.ROW_MODIFY) {
            let newMapIcon = null;
            let id = -1;
            let type = "";
            state.mapIcons.forEach(mapIcon => {
                if (mapIcon.childrenCount === 1 && mapIcon.fsrIds.length === 1 && data.tblName === "fsr") {
                    if (mapIcon.fsrIds[0] === parseInt(data.FSR_ID)) {
                        const mapIconData = {
                            lat: parseFloat(data.lat),
                            lon: parseFloat(data.lon),
                            childrenCount: 1,
                            emergency: false,
                            sameState: true,
                            sameStatus: false,
                            type: "fsr",
                            state: parseInt(data.STATE_ID),
                            status: 0,
                            children: [{
                                    name: data.LOGIN + "",
                                    legacyIdentifier: mapIcon.children[0].legacyIdentifier,
                                    type: mapIcon.children[0].type,
                                    id: mapIcon.children[0].id,
                                    emergency: false,
                                    state: parseInt(data.STATE_ID),
                                    status: 0,
                                    customIcon: "",
                                }],
                            orderIds: [],
                            fsrIds: mapIcon.fsrIds,
                            crewIds: [],
                            vehicleIds: [],
                            projectIds: [],
                            customIcon: "",
                        };
                        const theme = state.theme;
                        newMapIcon = getMapIconFromMapIconData(mapIconData, theme, "fsr", mapIcon.internalId);
                        type = "fsr";
                        id = parseInt(data.FSR_ID);
                    }
                }
                else if (mapIcon.childrenCount === 1 && mapIcon.orderIds.length === 1 && data.tblName === "order") {
                    if (mapIcon.fsrIds[0] === parseInt(data.ORDER_ID)) {
                        const mapIconData = {
                            lat: parseFloat(data.lat),
                            lon: parseFloat(data.lon),
                            childrenCount: 1,
                            emergency: mapIcon.iconPopupTitleString.includes("Emergency"),
                            sameState: true,
                            sameStatus: false,
                            type: "order",
                            state: parseInt(data.STATE_ID),
                            status: 0,
                            children: [{
                                    name: data.ORDER_NUMBER + "",
                                    legacyIdentifier: mapIcon.children[0].legacyIdentifier,
                                    type: mapIcon.children[0].type,
                                    id: mapIcon.children[0].id,
                                    emergency: mapIcon.iconPopupTitleString.includes("Emergency"),
                                    state: parseInt(data.STATE_ID),
                                    status: 0,
                                    customIcon: "",
                                }],
                            orderIds: mapIcon.orderIds,
                            fsrIds: [],
                            crewIds: [],
                            vehicleIds: [],
                            projectIds: [],
                            customIcon: "",
                        };
                        const theme = state.theme;
                        newMapIcon = getMapIconFromMapIconData(mapIconData, theme, "fsr", mapIcon.internalId);
                        type = "order";
                        id = parseInt(data.ORDER_ID);
                    }
                }
            });
            if (id !== -1 && type !== "" && newMapIcon !== null) {
                yield put({
                    type: DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_MODIFY,
                    data: {
                        type: data.tblName,
                        id: id,
                        mapIcon: newMapIcon,
                    },
                });
            }
        }
    }
}
function* updateMapSelectSaga(action) {
    const state = yield select(getDispatchPageContainerStateSelector);
    const mapIcons = getMapIconsWithSelect(state.mapIcons, state.selectedOrderIds, state.selectedFsrIds, state.selectedOrderIds);
    const mapRoutes = getMapRoutesWithSelect(state.mapRoutes, state.selectedOrderIds, state.selectedFsrIds, state.selectedVehicleIds);
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_ICONS_AND_ROUTES,
        mapIcons: mapIcons,
        mapRoutes: mapRoutes
    });
}
function* handleMapSelectEvent(action) {
    const event = action.event;
    //TODO: LEGACY Remove this when selected items all reference the redux store. This was done to ensure legacy compatibility. This saga should be dispatching another event to update the selected id array.
    switch (event.type) {
        case "order":
        case "project": {
            window["orderSelected"] = [...window["orderSelected"], event.legacyIdentifier];
            if (window["moveTablePositionOnSelect"]) {
                window["moveTablePositionOnSelect"]("order", event.legacyIdentifier);
            }
            break;
        }
        case "fsr":
        case "crew": {
            window["fsrSelected"] = [...window["fsrSelected"], event.legacyIdentifier];
            if (window["moveTablePositionOnSelect"]) {
                window["moveTablePositionOnSelect"]("fsr", event.legacyIdentifier);
            }
            break;
        }
        case "vehicle": {
            window["vehicleSelected"] = [...window["vehicleSelected"], event.legacyIdentifier];
            if (window["moveTablePositionOnSelect"]) {
                window["moveTablePositionOnSelect"]("vehicle", event.legacyIdentifier);
            }
            break;
        }
        default: {
            console.error(`Unknown child type: ${event.type}.`);
        }
    }
}
function* handleMapUnselectEvent(action) {
    const event = action.event;
    //TODO: LEGACY Remove this when selected items all reference the redux store. This was done to ensure legacy compatibility. This saga should be dispatching another event to update the selected id array.
    switch (event.type) {
        case "order":
        case "project": {
            window["orderSelected"] = window["orderSelected"].filter(s => s !== action.event.legacyIdentifier);
            break;
        }
        case "fsr":
        case "crew": {
            window["fsrSelected"] = window["fsrSelected"].filter(s => s !== action.event.legacyIdentifier);
            break;
        }
        case "vehicle": {
            window["vehicleSelected"] = window["vehicleSelected"].filter(s => s !== action.event.legacyIdentifier);
            break;
        }
        default: {
            console.error(`Unknown child type: ${event.type}.`);
        }
    }
}
function* handleMapMultiSelectEvent(action) {
    const event = action.event;
    //TODO: LEGACY Remove this when selected items all reference the redux store. This was done to ensure legacy compatibility. This saga should be dispatching another event to update the selected id array.
    const orderMapSelectEvents = event.filter(event => event.type === "order" || event.type === "project");
    const fsrMapSelectEvents = event.filter(event => event.type === "fsr" || event.type === "crew");
    const vehicleMapSelectEvents = event.filter(event => event.type === "vehicle");
    const newOrderSelectedArray = [...new Set(orderMapSelectEvents.map(event => event.legacyIdentifier))];
    window["orderSelected"] = newOrderSelectedArray;
    if (newOrderSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("order", newOrderSelectedArray[0]);
        }
    }
    const newFsrSelectedArray = [...new Set(fsrMapSelectEvents.map(event => event.legacyIdentifier))];
    window["fsrSelected"] = newFsrSelectedArray;
    if (newFsrSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("fsr", newFsrSelectedArray[0]);
        }
    }
    const newVehicleSelectedArray = [...new Set(vehicleMapSelectEvents.map(event => event.legacyIdentifier))];
    window["vehicleSelected"] = newVehicleSelectedArray;
    if (newVehicleSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("vehicle", newVehicleSelectedArray[0]);
        }
    }
}
function* handleMapMultiSelectCtrlEvent(action) {
    const event = action.event;
    //TODO: LEGACY Remove this when selected items all reference the redux store. This was done to ensure legacy compatibility. This saga should be dispatching another event to update the selected id array.
    const orderMapSelectEvents = event.filter(event => event.type === "order" || event.type === "project");
    const fsrMapSelectEvents = event.filter(event => event.type === "fsr" || event.type === "crew");
    const vehicleMapSelectEvents = event.filter(event => event.type === "vehicle");
    const oldOrderSelectedArray = window["orderSelected"];
    const eventOrderSelectedArray = orderMapSelectEvents.map(event => event.legacyIdentifier);
    const oldOrderSelectedMatchingArray = oldOrderSelectedArray.filter(s => eventOrderSelectedArray.includes(s));
    const newOrderSelectedArray = [...new Set([...oldOrderSelectedArray, ...eventOrderSelectedArray].filter(s => !oldOrderSelectedMatchingArray.includes(s)))];
    window["orderSelected"] = newOrderSelectedArray;
    if (newOrderSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("order", newOrderSelectedArray[0]);
        }
    }
    const oldFsrSelectedArray = window["fsrSelected"];
    const eventFsrSelectedArray = fsrMapSelectEvents.map(event => event.legacyIdentifier);
    const oldFsrSelectedMatchingArray = oldFsrSelectedArray.filter(s => eventFsrSelectedArray.includes(s));
    const newFsrSelectedArray = [...new Set([...oldFsrSelectedArray, ...eventFsrSelectedArray].filter(s => !oldFsrSelectedMatchingArray.includes(s)))];
    window["fsrSelected"] = newFsrSelectedArray;
    if (newFsrSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("fsr", newFsrSelectedArray[0]);
        }
    }
    const oldVehicleSelectedArray = window["vehicleSelected"];
    const eventVehicleSelectedArray = vehicleMapSelectEvents.map(event => event.legacyIdentifier);
    const oldVehicleSelectedMatchingArray = oldVehicleSelectedArray.filter(s => eventVehicleSelectedArray.includes(s));
    const newVehicleSelectedArray = [...new Set([...oldVehicleSelectedArray, ...eventVehicleSelectedArray].filter(s => !oldVehicleSelectedMatchingArray.includes(s)))];
    window["vehicleSelected"] = newVehicleSelectedArray;
    if (newVehicleSelectedArray.length > 0) {
        if (window["moveTablePositionOnSelect"]) {
            window["moveTablePositionOnSelect"]("vehicle", newVehicleSelectedArray[0]);
        }
    }
}
function* handleMapDisplayRouteEvent(action) {
    var _a, _b, _c, _d;
    const state = yield select(getDispatchPageContainerStateSelector);
    const date = state.date;
    const mapRoutes = state.mapRoutes;
    const matchingRoutes = mapRoutes.filter(mapRoute => mapRoute.id === action.event.id && mapRoute.type === action.event.type);
    if (matchingRoutes.length === 0) {
        const existingRouteColors = mapRoutes.map(route => route.color);
        const newUniqueColor = getNewUniqueColor(existingRouteColors);
        const mapRoutingData = yield call(Routing.getMapRoutingData, {
            date: date,
            id: action.event.id,
            type: action.event.type
        });
        if (!!mapRoutingData) {
            const orderNumbers = mapRoutingData.wayPoints.map(wayPoint => wayPoint.orderNumber);
            const orders = yield call(OrderAPI.getOrdersByOrderNumbers, {
                orderNumbers: orderNumbers,
            });
            const theme = state.theme;
            const mapRoute = getMapRouteFromMapRoutingData(mapRoutes, mapRoutingData, theme, newUniqueColor, orders);
            yield put({
                type: DispatchPageContainerActionTypes.HANDLE_MAP_ADD_ROUTE_EVENT,
                event: mapRoute
            });
            const outlineWidth = StyleUtils.getCssPixelString(2); //TODO: LEGACY Jquery object. Remove when we move to react table.
            (_a = action.event.cell) === null || _a === void 0 ? void 0 : _a.css("outline-offset", `-${outlineWidth}`);
            (_b = action.event.cell) === null || _b === void 0 ? void 0 : _b.css("outline", `${outlineWidth} solid ${newUniqueColor}`);
        }
    }
    else {
        (_c = action.event.cell) === null || _c === void 0 ? void 0 : _c.css("outline", ""); //TODO: LEGACY Jquery object. Remove when we move to react table.
        (_d = action.event.cell) === null || _d === void 0 ? void 0 : _d.css("outline-offset", "0px");
        yield put({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_REMOVE_ROUTE_EVENT,
            event: {
                id: action.event.id,
                type: action.event.type
            }
        });
    }
}
function* handleMapDragEvent(action) {
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_DRAG_DATA,
        orderNumbers: action.orderNumbers,
        orderIds: action.orderIds,
        mapIsDragging: action.mapIsDragging,
    });
}
function* handleMapHoverEvent(action) {
    const orderId = action.orderId;
    const coordinates = action.coordinates;
    const data = yield call(Map.getMapHoverData, orderId);
    if (data) {
        $("#map-hover").show();
        $("#map-hover").html(data);
        $("#map-hover").css({
            position: "absolute",
            left: "0px",
            top: "0px",
            height: "67px",
            width: "280px",
            padding: "15px",
            backgroundColor: "#FFFFFF",
            zIndex: 9999,
            transform: `translate(${coordinates.x}px, ${coordinates.y}px)`,
        });
    }
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_HOVER_DATA,
        mapHoverData: {
            coordinates: {
                x: coordinates.x,
                y: coordinates.y,
            },
            isVisible: true,
            data: data,
            orderId: orderId,
        }
    });
}
function* handleMapHoverEndEvent(action) {
    $("#map-hover").hide();
    $("#map-hover").html("");
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_HOVER_DATA,
        mapHoverData: {
            coordinates: {
                x: 0,
                y: 0,
            },
            isVisible: false,
            data: "",
            orderId: 0,
        }
    });
}
function* handleDispatchOrders(action) {
    const ordersThatArePastDated = action.orders.filter(order => moment(order.appointmentStartTime).isBefore(moment()) && order.appointmentType !== "U");
    let thereAreDatedOrdersThatNeedModification = ordersThatArePastDated.length > 0;
    if (thereAreDatedOrdersThatNeedModification) {
        const message = `The following orders are past their due date and must have their dates adjusted:
    
${ordersThatArePastDated.map(o => o.number).reduce((acc, val) => acc + "\n" + val)}
    
Continue?
    `;
        const title = "Rescheduling past dated orders into future!";
        yield put(dispatchShowModal(message, title));
        const { isOk } = yield take(DispatchPageContainerActionTypes.CLOSE_MODAL_DIALOG);
        if (!isOk)
            return;
    }
    const response = yield call(window.sl.dispatchBridge, action);
    const openWindows = yield select(state => state.dispatch.openWindows);
    switch (response.result) {
        case DispatchResultsType.REASSIGN:
        case DispatchResultsType.DISPATCH:
            if (thereAreDatedOrdersThatNeedModification)
                yield call(modifyOrders, ordersThatArePastDated.map(o => ({
                    number: o.number,
                    appointmentType: "U",
                    appointmentStartTime: action.dispatchDate,
                    appointmentEndTime: action.dispatchDate
                })));
            yield* Object.entries(openWindows).map(([fsrId, window]) => {
                return getFsrCalendarEventsSaga(parseInt(fsrId), window);
            });
            break;
    }
}
function* getFsrCalendarEventsSaga(fsrId, window) {
    const orders = yield call(getFsrsCalendarEvents, fsrId, window.dateOpened);
    yield put(dispatchShowCalendar(fsrId, window.dateOpened, orders));
}
function* handleSaveFilterEvent(action) {
    const state = yield select(getDispatchPageContainerStateSelector);
    const success = yield call(Filter.saveDispatchFilter, action.filterType, action.filterName, action.filter);
    if (success) {
        switch (action.filterType) {
            case "order":
            case "fsr":
            case "avl": {
                const savedFilters = state.filterSettings[action.filterType].savedFilters;
                const newSavedFilters = savedFilters.map(filter => {
                    if (filter.name === action.filterName) {
                        return {
                            name: action.filterName,
                            filter: action.filter,
                        };
                    }
                    else {
                        return filter;
                    }
                });
                const newState = Object.assign(Object.assign({}, state), { filterSettings: Object.assign(Object.assign({}, state.filterSettings), { [action.filterType]: Object.assign(Object.assign({}, state.filterSettings[action.filterType]), { savedFilters: newSavedFilters }) }) });
                yield put({
                    type: DispatchPageContainerActionTypes.SET_DISPATCH_PAGE_CONTAINER_STATE,
                    state: newState,
                });
                break;
            }
        }
    }
}
function* handleMapTreeDataChangeEvent(action) {
    const flatMap = MapUtils.treeToFlatMap(action.newTreeData);
    const newMapLayerSettings = [];
    flatMap.forEach((layer) => {
        const matchingSetting = newMapLayerSettings.find(setting => setting.url === layer.url);
        if (!!matchingSetting) {
            if (!layer.visible) {
                matchingSetting.hiddenLayerNames.push(layer.name);
            }
            if (layer.disabled) {
                matchingSetting.disabledLayerIds.push(layer.id);
            }
        }
        else {
            const hiddenNames = layer.visible ? [] : [layer.name];
            const disabledIds = layer.disabled ? [layer.id] : [];
            newMapLayerSettings.push({
                url: layer.url,
                hiddenLayerNames: hiddenNames,
                disabledLayerIds: disabledIds,
            });
        }
    });
    const newMapLayerSettingsUrls = newMapLayerSettings.map(setting => setting.url);
    yield put({
        type: DispatchPageContainerActionTypes.SET_MAP_LAYER_SETTINGS,
        mapLayerSettings: newMapLayerSettings,
    });
    const oldSettingsData = yield call(IndexedDBService.get, IndexedDBKeys.ESRI_MAP_LAYER_SETTINGS);
    const oldSettings = oldSettingsData || [];
    const nonMatchingSettings = oldSettings.filter(setting => !newMapLayerSettingsUrls.includes(setting.url));
    const newSettings = [...nonMatchingSettings, ...newMapLayerSettings];
    yield call(IndexedDBService.put, IndexedDBKeys.ESRI_MAP_LAYER_SETTINGS, newSettings);
}
function* setThemeSaga(action) {
    yield call(IndexedDBService.initialize);
    yield call(IndexedDBService.put, IndexedDBKeys.THEME, action.theme);
}
function getDispatchPageContainerStateSelector(store) {
    return store.dispatch;
}
export const getNewUniqueColor = (existingColors) => {
    if (existingColors.length === 0) {
        return RoutingUniqueColors[0];
    }
    const unusedColors = RoutingUniqueColors.filter(color => !existingColors.includes(color));
    if (unusedColors.length === 0) {
        return StyleUtils.getRandomColor();
    }
    const sortedUnusedColors = unusedColors.sort((a, b) => {
        const aChroma = chroma(a);
        const bChroma = chroma(b);
        const aColorDifferenceScore = getColorDifferenceScore(aChroma, unusedColors);
        const bColorDifferenceScore = getColorDifferenceScore(bChroma, unusedColors);
        if (aColorDifferenceScore === bColorDifferenceScore) {
            return 0;
        }
        else {
            return bColorDifferenceScore - aColorDifferenceScore;
        }
    });
    return sortedUnusedColors[0];
};
const getColorDifferenceScore = (color, unusedColors) => {
    const unusedColorsHues = unusedColors.map(color => chroma(color).hsl()[0]);
    const lightnessSaturationImportance = 0.3; //0 to 1
    const hue = color.hsl()[0];
    const lightnessSaturationMultiplier = (color.hsl()[1] + color.hsl()[2]) / 2;
    const hueDifferenceScore = hue ? unusedColorsHues.reduce((accumulator, value) => accumulator + (hue - value) ^ 2) : -1;
    return hueDifferenceScore + (1 + lightnessSaturationImportance * lightnessSaturationMultiplier);
};
const getExistingInternalIdsFromMapRoutes = (existingMapRoutes) => {
    const existingInternalIds = [];
    existingMapRoutes.forEach((mapRoute) => {
        mapRoute.wayPoints.forEach(wayPoint => {
            existingInternalIds.push(wayPoint.internalId);
        });
    });
    return existingInternalIds;
};
export const getMapRouteFromMapRoutingData = (existingMapRoutes, mapRouteData, theme, color, orders) => {
    const existingInternalIds = getExistingInternalIdsFromMapRoutes(existingMapRoutes);
    const startingInternalId = existingInternalIds.length === 0 ? 0 : Math.max(...existingInternalIds) + 1;
    const startIconStyle = getMapStartEndWayPointStyle(mapRouteData, theme, color, "start");
    const endIconStyle = getMapStartEndWayPointStyle(mapRouteData, theme, color, "end");
    return {
        id: mapRouteData.id,
        type: mapRouteData.type,
        start: {
            lat: mapRouteData.start.lat,
            lon: mapRouteData.start.lon,
            iconPopupTitleString: startIconStyle.popupTitleString,
            iconPopupContentString: startIconStyle.popupContentString,
            iconStyleName: startIconStyle.name,
            iconStyleString: getMapIconStyleStringFromLayers(startIconStyle.layers)
        },
        end: {
            lat: mapRouteData.end.lat,
            lon: mapRouteData.end.lon,
            iconPopupTitleString: endIconStyle.popupTitleString,
            iconPopupContentString: endIconStyle.popupContentString,
            iconStyleName: endIconStyle.name,
            iconStyleString: getMapIconStyleStringFromLayers(endIconStyle.layers)
        },
        wayPoints: mapRouteData.wayPoints.map((wayPoint, index) => getMapWayPointFromWayPoint(wayPoint, startingInternalId + index, theme, color, orders.find(order => order.number === wayPoint.orderNumber))),
        trackPoints: mapRouteData.trackPoints,
        color: color
    };
};
export const getMapIconFromMapIconData = (mapIconData, theme, fsrVehMode, index) => {
    const mapIconStyle = getMapIconStyle(mapIconData, fsrVehMode, theme);
    const mapIconStyleString = getMapIconStyleStringFromLayers(mapIconStyle.layers);
    return {
        internalId: index,
        lat: mapIconData.lat,
        lon: mapIconData.lon,
        childrenCount: mapIconData.childrenCount,
        iconPopupTitleString: mapIconStyle.popupTitleString,
        iconPopupContentString: mapIconStyle.popupContentString,
        iconStyleString: mapIconStyleString,
        iconStyleName: mapIconStyle.name,
        children: mapIconData.children.map(mapIconChildData => getMapIconChild(mapIconChildData)),
        orderIds: mapIconData.orderIds,
        fsrIds: mapIconData.fsrIds,
        crewIds: mapIconData.crewIds,
        vehicleIds: mapIconData.vehicleIds,
        projectIds: mapIconData.projectIds,
        selected: false
    };
};
const getMapIconsWithSelect = (mapIcons, selectedOrders, selectedFsrs, selectedVehicles) => {
    return mapIcons.map(mapIcon => {
        const newMapIcon = Object.assign({}, mapIcon);
        const mapIconSelected = getMapIconSelected(mapIcon, selectedOrders, selectedFsrs, selectedVehicles);
        if (mapIconSelected) {
            newMapIcon.selected = true;
            newMapIcon.children = mapIcon.children.map(child => {
                let selected = false;
                switch (child.type) {
                    case "order":
                    case "project": {
                        selected = selectedOrders.includes(child.id);
                        break;
                    }
                    case "fsr":
                    case "crew": {
                        selected = selectedFsrs.includes(child.id);
                        break;
                    }
                    case "vehicle": {
                        selected = selectedVehicles.includes(child.id);
                        break;
                    }
                    default: {
                        console.error(`Unknown child type: ${child.type}.`);
                        selected = false;
                    }
                }
                return Object.assign(Object.assign({}, child), { selected: selected });
            });
        }
        else {
            newMapIcon.selected = false;
            newMapIcon.children = newMapIcon.children.map(child => (Object.assign(Object.assign({}, child), { selected: false })));
        }
        return newMapIcon;
    });
};
const getMapRoutesWithSelect = (mapRoutes, selectedOrders, selectedFsrs, selectedVehicles) => {
    return mapRoutes.map(mapRoute => {
        const newWayPoints = mapRoute.wayPoints.map(wayPoint => {
            const newWayPoint = Object.assign(Object.assign({}, wayPoint), { selected: selectedOrders.includes(wayPoint.id) });
            return newWayPoint;
        });
        const newTrackPoints = mapRoute.trackPoints.map(trackPoint => (Object.assign({}, trackPoint)));
        const newMapRoute = Object.assign(Object.assign({}, mapRoute), { start: Object.assign({}, mapRoute.start), end: Object.assign({}, mapRoute.end), trackPoints: [...newTrackPoints], wayPoints: [...newWayPoints] });
        return newMapRoute;
    });
};
const getMapIconSelected = (mapIcon, selectedOrders, selectedFsrs, selectedVehicles) => {
    const ordersSelected = [...mapIcon.orderIds, ...mapIcon.projectIds].some(id => selectedOrders.includes(id));
    if (ordersSelected) {
        return true;
    }
    const fsrsSelected = [...mapIcon.fsrIds, ...mapIcon.crewIds].some(id => selectedFsrs.includes(id));
    if (fsrsSelected) {
        return true;
    }
    const vehiclesSelected = mapIcon.vehicleIds.some(id => selectedVehicles.includes(id));
    if (vehiclesSelected) {
        return true;
    }
    return false;
};
const getMapIconStyleStringFromLayers = (layers) => {
    const layersString = layers.reduce((accumulator, layer) => {
        switch (layer.type) {
            case MapIconStyleType.PATH: {
                const layerString = layer.paths.reduce((accumulator, path) => {
                    return `${accumulator}<path d="${path}" fill="${layer.color}"/>`;
                }, "");
                return `${accumulator}${layerString}`;
            }
            case MapIconStyleType.TEXT: {
                return `${accumulator}<text x="${layer.x}" y="${layer.y}" font-family="Roboto, sans-serif" font-size="${layer.fontSize}" fill="${layer.color}">${layer.text}</text>`;
            }
            case MapIconStyleType.RECT: {
                return `${accumulator}<rect x="${layer.x}" y="${layer.y}" rx="${layer.rx || 0}" ry="${layer.ry || 0}" width="${layer.width}" height="${layer.height}" fill="${layer.fill}" stroke="${layer.stroke || layer.fill}" stroke-width="${layer.strokeWidth || 0}"/>`;
            }
        }
    }, "");
    const svgString = `
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024">
      <g>
        ${layersString}
      </g>
    </svg>
  `;
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
};
const getMapStartEndWayPointStyle = (mapRouteData, theme, color, startEnd) => {
    const name = Utils.capitalizeFirstCharacter(startEnd);
    const popupTitleString = `Routing ${name} Point`;
    const popupContentString = `This is the ${startEnd} of the route.`;
    const layers = [];
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-TempIcon-Outline"),
        color: color
    });
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-TempIcon-Background"),
        color: RoutingMapIconColor.Background
    });
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-TempIcon-Body"),
        color: RoutingMapIconColor[name]
    });
    return {
        name: name,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString,
        layers: layers
    };
};
const getMapWayPointFromWayPoint = (wayPoint, internalId, theme, color, order) => {
    const iconStyle = getOrderMapRouteIconStyle(wayPoint, theme, color, order);
    const legacyIdentifier = `${wayPoint.orderId},${wayPoint.stateId},${wayPoint.orderNumber}`;
    const mapIconChildData = {
        name: wayPoint.orderNumber,
        legacyIdentifier: legacyIdentifier,
        type: "order",
        id: wayPoint.orderId,
        emergency: wayPoint.emergency,
        state: wayPoint.stateId,
        status: -1,
        customIcon: ""
    };
    const mapIconChildStyleData = getMapIconChildIcon(mapIconChildData);
    return {
        internalId: internalId,
        legacyIdentifier: legacyIdentifier,
        lat: wayPoint.lat,
        lon: wayPoint.lon,
        id: wayPoint.orderId,
        index: wayPoint.index,
        iconPopupTitleString: iconStyle.popupTitleString,
        iconPopupContentString: iconStyle.popupContentString,
        iconStyleString: getMapIconStyleStringFromLayers(iconStyle.layers),
        iconStyleName: iconStyle.name,
        displayedIcon: mapIconChildStyleData.displayedIcon,
        displayedIconColor: mapIconChildStyleData.displayedIconColor,
        displayedIconBackgroundColor: mapIconChildStyleData.displayedIconBackgroundColor,
        displayedText: wayPoint.orderNumber,
        selected: false
    };
};
const getOrderMapRouteIconStyle = (wayPoint, theme, color, order) => {
    const stateName = OrderIdToOrderState[wayPoint.stateId];
    const bodyColor = wayPoint.emergency ? OrderMapIconColor.Emergency : OrderMapIconColor[stateName];
    const stateIcon = OrderStateMapIconName[stateName];
    const hasAppointment = order ? order.appointment.type !== "U" : false;
    const layers = [];
    const name = `${wayPoint.index}-Route-Order-${wayPoint.emergency ? "Emergency-" : ""}${stateName}${hasAppointment ? "Appointment-" : ""}-${color}`;
    const popupTitleString = `${wayPoint.emergency ? "Emergency " : ""}${Utils.camelCaseToReadable(stateName)} Order - ${wayPoint.index}${hasAppointment ? " With Appointment" : ""}`;
    const popupContentString = wayPoint.orderNumber;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme, color));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-BodyOutline"),
        color: color
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, stateIcon),
        color: getStateIconColor(bodyColor, OrderMapIconColor.stateIconLightColor, OrderMapIconColor.stateIconDarkColor)
    });
    let wayPointIndexString = (wayPoint.index).toString();
    const wayPointIndexStringLength = wayPointIndexString.length;
    let width = 350;
    let height = 350;
    let x = 100;
    let y = 300;
    let fontSize = 300;
    switch (wayPointIndexStringLength) {
        case 1: {
            width = 350;
            height = 350;
            x = 100;
            y = 300;
            fontSize = 300;
            break;
        }
        case 2: {
            width = 400;
            height = 350;
            x = 40;
            y = 300;
            fontSize = 300;
            break;
        }
        case 3: {
            width = 500;
            height = 330;
            x = 25;
            y = 280;
            fontSize = 280;
            break;
        }
        case 4: {
            width = 600;
            height = 300;
            x = 25;
            y = 250;
            fontSize = 250;
            break;
        }
        case 5: {
            width = 650;
            height = 270;
            x = 25;
            y = 220;
            fontSize = 220;
            break;
        }
        default: {
            width = 650;
            height = 270;
            x = 25;
            y = 220;
            fontSize = 220;
            wayPointIndexString = "XXXXX";
            break;
        }
    }
    //Count Background
    layers.push({
        type: MapIconStyleType.RECT,
        x: 0,
        y: 0,
        rx: 50,
        ry: 50,
        width: width,
        height: height,
        fill: hasAppointment ? "#FFFF55" : "#FFFFFF",
        stroke: color,
        strokeWidth: 30
    });
    // Count Text
    layers.push({
        type: MapIconStyleType.TEXT,
        text: wayPointIndexString,
        x: x,
        y: y,
        fontSize: fontSize,
        color: "#000000"
    });
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString
    };
};
const getMapIconStyle = (mapIconData, fsrVehMode, theme) => {
    const type = mapIconData.type;
    switch (type) {
        case "order": {
            return getOrderMapIconStyle(mapIconData, theme);
        }
        case "project": {
            return getOrderMapIconStyle(mapIconData, theme);
        }
        case "fsr": {
            return getFsrMapIconStyle(mapIconData, theme);
        }
        case "crew": {
            return getCrewMapIconStyle(mapIconData, theme);
        }
        case "vehicle": {
            return getVehicleMapIconStyle(mapIconData, theme);
        }
        case "mixed": {
            return getMixedMapIconStyle(mapIconData, theme);
        }
        default: {
            console.error(`Invalid map icon type: ${type}.`);
            console.error(mapIconData);
            return getErrorMapIconStyle(mapIconData, theme);
        }
    }
};
const getThemePathsByName = (theme, name) => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.icon) === null || _a === void 0 ? void 0 : _a.icons[name]) === null || _b === void 0 ? void 0 : _b.icon) === null || _c === void 0 ? void 0 : _c.paths) || [];
};
const getShadowLayers = (theme, shadowOutlineColor, shadowColor) => {
    const layers = [];
    //Shadow
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Shadow"),
        color: shadowColor || "#666666"
    });
    //Shadow Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-ShadowOutline"),
        color: shadowOutlineColor || "#E1E1E1"
    });
    return layers;
};
const getOrderMapIconStyle = (mapIconData, theme) => {
    const stateName = OrderIdToOrderState[mapIconData.state];
    const bodyColor = mapIconData.emergency ? OrderMapIconColor.Emergency : mapIconData.sameState ? OrderMapIconColor[stateName] : OrderMapIconColor.Mixed;
    const stateIcon = mapIconData.sameState ? OrderStateMapIconName[stateName] : OrderStateMapIconName.Mixed;
    const layers = [];
    const name = `Order-${mapIconData.emergency ? "Emergency-" : ""}${mapIconData.sameState ? stateName : "Mixed"}-${mapIconData.childrenCount > 1 ? "Multiple" : "Single"}`;
    const popupTitleString = `${mapIconData.emergency ? "Emergency " : ""}${mapIconData.sameState ? Utils.camelCaseToReadable(stateName) : "Mixed"} ${mapIconData.childrenCount > 1 ? "Orders (" + mapIconData.childrenCount + ")" : "Order"}`;
    const popupContentString = mapIconData.childrenCount > 1 ? "" : "Order: " + mapIconData.children[0].name;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-BodyOutline"),
        color: OrderMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, stateIcon),
        color: getStateIconColor(bodyColor, OrderMapIconColor.stateIconLightColor, OrderMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Orders-MultipleBackground"),
            color: OrderMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Orders-Multiple"),
            color: OrderMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString
    };
};
const getFsrMapIconStyle = (mapIconData, theme) => {
    const stateName = FsrState[mapIconData.state];
    const bodyColor = mapIconData.sameState ? FsrMapIconColor[stateName] : FsrMapIconColor.Mixed;
    const stateIcon = mapIconData.sameState ? FsrStateMapIconName[stateName] : FsrStateMapIconName.Mixed;
    const layers = [];
    const name = `Fsr-${mapIconData.sameState ? stateName : "Mixed"}-${mapIconData.childrenCount > 1 ? "Multiple" : "Single"}`;
    const popupTitleString = `${mapIconData.sameState ? Utils.camelCaseToReadable(stateName) : "Mixed"} ${mapIconData.childrenCount > 1 ? "FSRs (" + mapIconData.childrenCount + ")" : "FSR"}`;
    const popupContentString = mapIconData.childrenCount > 1 ? "" : "FSR: " + mapIconData.children[0].name;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Fsr-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Fsr-BodyOutline"),
        color: FsrMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, stateIcon),
        color: getStateIconColor(bodyColor, FsrMapIconColor.stateIconLightColor, FsrMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Fsrs-MultipleBackground"),
            color: FsrMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Fsrs-Multiple"),
            color: FsrMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString
    };
};
const getCrewMapIconStyle = (mapIconData, theme) => {
    const stateName = CrewState[mapIconData.state];
    const bodyColor = mapIconData.sameState ? CrewMapIconColor[stateName] : CrewMapIconColor.Mixed;
    const layers = [];
    const name = `Crew-${mapIconData.sameState ? stateName : "Mixed"}-${mapIconData.childrenCount > 1 ? "Multiple" : "Single"}`;
    const popupTitleString = `${mapIconData.sameState ? Utils.camelCaseToReadable(stateName) : "Mixed"} ${mapIconData.childrenCount > 1 ? "Crew or FSRs (" + mapIconData.childrenCount + ")" : "Crew"}`;
    const popupContentString = mapIconData.childrenCount > 1 ? "" : "Crew: " + mapIconData.children[0].name;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Fsr-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Fsr-BodyOutline"),
        color: CrewMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Fsr_Crew-StateIcon"),
        color: getStateIconColor(bodyColor, CrewMapIconColor.stateIconLightColor, CrewMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Fsrs-MultipleBackground"),
            color: CrewMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Fsrs-Multiple"),
            color: CrewMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString
    };
};
const getVehicleMapIconStyle = (mapIconData, theme) => {
    const statusName = VehicleStatus[mapIconData.status];
    const typeName = VehicleType[mapIconData.state];
    const bodyColor = mapIconData.sameStatus && mapIconData.sameState ? VehicleMapIconColor[statusName] : VehicleMapIconColor.Mixed;
    const stateIcon = mapIconData.sameState ? VehicleTypeMapIconName[typeName] : VehicleTypeMapIconName.Mixed;
    const layers = [];
    const name = `Vehicle-${mapIconData.status ? statusName : "Mixed"}-${mapIconData.sameState ? typeName : "Mixed"}-${mapIconData.childrenCount > 1 ? "Multiple" : "Single"}`;
    const popupTitleString = `${mapIconData.status ? Utils.camelCaseToReadable(statusName) + " " : "Mixed "}${mapIconData.sameState ? Utils.camelCaseToReadable(typeName) + " " : "Mixed "}${mapIconData.childrenCount > 1 ? "Vehicles (" + mapIconData.childrenCount + ")" : "Vehicle"}`;
    const popupContentString = mapIconData.childrenCount > 1 ? "" : "Vehicle:" + mapIconData.children[0].name;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Vehicle-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Vehicle-BodyOutline"),
        color: VehicleMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, stateIcon),
        color: getStateIconColor(bodyColor, VehicleMapIconColor.stateIconLightColor, VehicleMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Vehicles-MultipleBackground"),
            color: VehicleMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Vehicles-Multiple"),
            color: VehicleMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: popupContentString
    };
};
const getMixedMapIconStyle = (mapIconData, theme) => {
    const layers = [];
    const name = `${mapIconData.emergency ? "Emergency" : ""}-Mixed`;
    const bodyColor = mapIconData.emergency ? MixedMapIconColor.Emergency : MixedMapIconColor.Mixed;
    const popupTitleString = `${mapIconData.emergency ? "Emergency " : ""}Orders, FSRs, or Vehicles (${mapIconData.childrenCount})`;
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-OrderFsr-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-OrderFsr-BodyOutline"),
        color: MixedMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-OrderFsr-StateIcon"),
        color: getStateIconColor(bodyColor, MixedMapIconColor.stateIconLightColor, MixedMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-OrderFsr-MultipleBackground"),
            color: MixedMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-OrderFsr-Multiple"),
            color: MixedMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: ""
    };
};
const getErrorMapIconStyle = (mapIconData, theme) => {
    const ErrorStateMapIconName = {
        Error: "MapWindow-Order_Cancelled-StateIcon"
    };
    const bodyColor = ErrorMapIconColor.Error;
    const stateIcon = ErrorStateMapIconName.Error;
    const layers = [];
    const name = "Error";
    const popupTitleString = "Error";
    //Shadow and Shadow Outline
    layers.push(...getShadowLayers(theme));
    //Body
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-Body"),
        color: bodyColor
    });
    //Body Outline
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, "MapWindow-Order-BodyOutline"),
        color: ErrorMapIconColor.bodyOutlineColor
    });
    //State Icon
    layers.push({
        type: MapIconStyleType.PATH,
        paths: getThemePathsByName(theme, stateIcon),
        color: getStateIconColor(bodyColor, ErrorMapIconColor.stateIconLightColor, ErrorMapIconColor.stateIconDarkColor)
    });
    if (mapIconData.childrenCount > 1) {
        //Multiple Background
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Orders-MultipleBackground"),
            color: ErrorMapIconColor.multipleBackgroundColor
        });
        //Multiple
        layers.push({
            type: MapIconStyleType.PATH,
            paths: getThemePathsByName(theme, "MapWindow-Orders-Multiple"),
            color: ErrorMapIconColor.multipleColor
        });
    }
    return {
        name: name,
        layers: layers,
        popupTitleString: popupTitleString,
        popupContentString: ""
    };
};
const getStateIconColor = (backgroundColor, lightColor, darkColor) => {
    const backgroundLightness = chroma(backgroundColor).hsl()[2];
    if (backgroundLightness > 0.7) {
        return darkColor;
    }
    else {
        return lightColor;
    }
};
const getMapIconChild = (mapIconChildData) => {
    const mapIconChildStyleData = getMapIconChildIcon(mapIconChildData);
    return {
        type: mapIconChildData.type,
        id: mapIconChildData.id,
        legacyIdentifier: mapIconChildData.legacyIdentifier,
        displayedIcon: mapIconChildStyleData.displayedIcon,
        displayedIconColor: mapIconChildStyleData.displayedIconColor,
        displayedIconBackgroundColor: mapIconChildStyleData.displayedIconBackgroundColor,
        displayedText: mapIconChildData.name,
        selected: false
    };
};
const getMapIconChildIcon = (mapIconChildData) => {
    switch (mapIconChildData.type) {
        case "order": {
            const state = OrderIdToOrderState[mapIconChildData.state];
            const backgroundColor = mapIconChildData.emergency ? OrderMapIconColor.Emergency : OrderMapIconColor[state];
            return {
                displayedIcon: `OrderTable-Order_${state}-StateIcon`,
                displayedIconColor: getStateIconColor(backgroundColor, OrderMapIconColor.stateIconLightColor, OrderMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
        case "project": {
            const state = OrderIdToOrderState[mapIconChildData.state];
            const backgroundColor = mapIconChildData.emergency ? OrderMapIconColor.Emergency : OrderMapIconColor[state];
            return {
                displayedIcon: `OrderTable-Order_${state}-StateIcon`,
                displayedIconColor: getStateIconColor(backgroundColor, OrderMapIconColor.stateIconLightColor, OrderMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
        case "fsr": {
            const state = FsrState[mapIconChildData.state];
            const backgroundColor = FsrMapIconColor[state];
            return {
                displayedIcon: `FsrTable-Fsr_${state}-StateIcon`,
                displayedIconColor: getStateIconColor(backgroundColor, FsrMapIconColor.stateIconLightColor, FsrMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
        case "crew": {
            const state = CrewState[mapIconChildData.state];
            const backgroundColor = FsrMapIconColor[state];
            return {
                displayedIcon: "FsrTable-Fsr_Crew-StateIcon",
                displayedIconColor: getStateIconColor(backgroundColor, FsrMapIconColor.stateIconLightColor, FsrMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
        case "vehicle": {
            const type = VehicleType[mapIconChildData.state];
            const status = VehicleStatus[mapIconChildData.status];
            const backgroundColor = VehicleMapIconColor[status];
            return {
                displayedIcon: `AvlTable-Vehicle_${type}-StateIcon`,
                displayedIconColor: getStateIconColor(backgroundColor, VehicleMapIconColor.stateIconLightColor, VehicleMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
        default: {
            const backgroundColor = ErrorMapIconColor.Error;
            return {
                displayedIcon: "OrderTable-Order_Cancelled-StateIcon",
                displayedIconColor: getStateIconColor(backgroundColor, ErrorMapIconColor.stateIconLightColor, ErrorMapIconColor.stateIconDarkColor),
                displayedIconBackgroundColor: backgroundColor
            };
        }
    }
};
export default dispatchPageContainerSagas;
