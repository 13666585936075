import React from "react";
import styled from "styled-components";
import SimpleButton from "../../SimpleButton/SimpleButton";
import moment from "moment";
const StyledYearPickerWrapper = styled.div `
  width: 20rem;
  height: 2rem;
  background-color: ${props => props.colorOptions.backgroundColor};
`;
const StyledYearPicker = styled.div `
  width: 20rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledYearElement = styled.div `
  width: 4rem;
  height: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-radius: 0.2rem;
  user-select: none;
`;
const StyledMiddleSeparator = styled.div `
  width: 12.5rem;
  height: 2rem;
`;
const StyledEndSeparator = styled.div `
  width: 0.5rem;
  height: 2rem;
`;
class YearPicker extends React.Component {
    constructor(props) {
        super(props);
        this.time = undefined;
        this.defaultInterval = 500;
        this.repeatInterval = this.defaultInterval;
        this.intervalMultiplier = 0.7;
        this.lowestInterval = 100;
        this.repeatIncrease = () => {
            this.props.handleYearChange(this.props.displayedYear + 1);
            this.time = window.setTimeout(this.repeatIncrease, Math.max(this.repeatInterval, this.lowestInterval));
            this.repeatInterval = this.repeatInterval * this.intervalMultiplier;
        };
        this.handleMouseDownIncrease = () => {
            this.repeatIncrease();
        };
        this.repeatDecrease = () => {
            this.props.handleYearChange(this.props.displayedYear - 1);
            this.time = window.setTimeout(this.repeatDecrease, Math.max(this.repeatInterval, this.lowestInterval));
            this.repeatInterval = this.repeatInterval * this.intervalMultiplier;
        };
        this.handleMouseDownDecrease = () => {
            this.repeatDecrease();
        };
        this.handleMouseUp = () => {
            clearTimeout(this.time);
            this.repeatInterval = this.defaultInterval;
        };
    }
    render() {
        const { displayedYear, currentTime, colorOptions } = this.props;
        const currentYear = moment(currentTime).year();
        const { color, backgroundColor, selectedColor, selectedBackgroundColor, hoverColor, hoverBackgroundColor, todayColor, todayBackgroundColor } = colorOptions;
        const isCurrentYear = displayedYear === currentYear;
        const normalOptionalProps = {
            backgroundColor: backgroundColor,
            iconColor: color
        };
        const hoveringOptionalProps = {
            backgroundColor: hoverBackgroundColor,
            iconColor: hoverColor
        };
        const mouseDownOptionalProps = {
            backgroundColor: selectedBackgroundColor,
            iconColor: selectedColor
        };
        const yearColor = isCurrentYear ? todayColor : color;
        const yearBackgroundColor = isCurrentYear ? todayBackgroundColor : todayColor;
        return (React.createElement(StyledYearPickerWrapper, Object.assign({ className: "year-picker-wrapper" }, this.props),
            React.createElement(StyledYearPicker, Object.assign({ className: "year-picker" }, this.props),
                React.createElement(StyledYearElement, { className: "year-element", color: yearColor, backgroundColor: yearBackgroundColor }, displayedYear),
                React.createElement(StyledMiddleSeparator, { className: "year-middle-separator" }),
                React.createElement(SimpleButton, { className: "year-decrease-button", color: color, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: backgroundColor, iconProps: { iconName: "arrow-left2", size: "1.4rem" }, margin: "0.3rem", borderRadius: "0.2rem", iconOffsetY: "-0.1rem", handleMouseDown: this.handleMouseDownDecrease, handleMouseUp: this.handleMouseUp, toolTip: `Go to previous year ${displayedYear - 1}` }),
                React.createElement(SimpleButton, { className: "year-increase-button", color: color, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: backgroundColor, iconProps: { iconName: "arrow-right2", size: "1.4rem" }, margin: "0.3rem", borderRadius: "0.2rem", iconOffsetY: "-0.1rem", handleMouseDown: this.handleMouseDownIncrease, handleMouseUp: this.handleMouseUp, toolTip: `Go to next year ${displayedYear + 1}` }),
                React.createElement(StyledEndSeparator, { className: "year-end-separator" }))));
    }
}
export default YearPicker;
