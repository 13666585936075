import React from "react";
import { ManagePageContainerActionTypes } from "../../../state/containers/ManagePageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import FilterEditScreen from "../components/Screens/FilterEditScreen/FilterEditScreen";
const ManagePageCalendarFilterWindowContainer = (props) => {
    var _a, _b, _c;
    const state = useSelector(store => store.manage, shallowEqual);
    const dispatch = useDispatch();
    const displayedFilter = ((_b = (_a = state.calendarFilter) === null || _a === void 0 ? void 0 : _a.filter) === null || _b === void 0 ? void 0 : _b.$and) || null;
    const windowTitle = `Calendar Filter: ${((_c = state.calendarFilter) === null || _c === void 0 ? void 0 : _c.name) || ""}`;
    const handleClose = () => {
        dispatch({
            type: ManagePageContainerActionTypes.SET_CALENDAR_FILTER_OPEN,
            open: false,
        });
    };
    const renderFilter = !!state.calendarFilter && !!state.calendarFilter.filter.$and;
    const handleFiltersSave = (filter) => {
        dispatch({
            type: ManagePageContainerActionTypes.SAVE_CALENDAR_FILTER,
            filter: filter,
            filterName: state.calendarFilterFilterName,
            calendarName: state.calendarFilterCalendarName,
        });
    };
    return (renderFilter ? (React.createElement(FilterEditScreen, { open: state.calendarFilterOpen, windowTitle: windowTitle, filterFields: state.calendarFilterFields, filters: displayedFilter, editable: true, saveButtonText: "Save", handleClose: handleClose, handleFiltersSave: handleFiltersSave })) : null);
};
export default ManagePageCalendarFilterWindowContainer;
