import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../../../../../../../components/SimpleButton/SimpleButton";
const StyledErrorMessageDetailsOrderRow = styled.div `
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #eeeeee;
  border-bottom: 1px solid white;
`;
class ErrorMessageDetailsOrderRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleClick = (event) => {
            this.props.handleOrderRowClick({ orderId: this.props.orderId });
        };
    }
    render() {
        return (React.createElement(StyledErrorMessageDetailsOrderRow, null,
            React.createElement(SimpleButton, { className: "error-message-details-order-row", textProps: {
                    iconText: this.props.orderId.toString(),
                    height: "2rem",
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "normal"
                }, color: this.theme.palette.black, backgroundColor: "#eeeeee", buttonClick: this.handleClick })));
    }
}
export default ErrorMessageDetailsOrderRow;
