import React from "react";
import SimpleButton from "../../../../../display/components/SimpleButton/SimpleButton";
import { getTheme } from "office-ui-fabric-react";
export class MessageResenderSearchButton extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(SimpleButton, { className: "error-message-get-messages-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                    width: "10rem",
                    height: "1.2rem",
                    iconText: "Get Messages",
                    textAlign: "center",
                    fontWeight: "bold"
                }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.props.handleClick })));
    }
}
