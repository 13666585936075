import React from "react";
import styled from "styled-components";
import AccordionExpandButton from "./AccordionExpandButton/AccordionExpandButton";
import { getTheme } from "office-ui-fabric-react";
const StyledAccordionTitle = styled.div `
  width: 100%;
  height: 2.6rem;
  background-color: ${props => props.backgroundColor || props.palette.white};
  border-style: solid;
  border-width: 0.1rem;
  border-radius: ${props => (props.isExpanded ? "0.5rem 0.5rem 0 0" : "0.5rem 0.5rem 0.5rem 0.5rem")};
  border-color: ${props => props.borderColor || props.palette.black};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
const StyledAccordionButtonWrapper = styled.div `
  height: 1.4rem;
  width: 1.4rem;
  padding: 0.6rem;
  overflow: hidden;
`;
const StyledAccordionTitleElementWrapper = styled.div `
  width: calc(100% - 2.6rem);
  height: 2.6rem;
  background-color: ${props => props.backgroundColor || props.palette.white};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
class AccordionTitle extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleMouseDown = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: true })));
            event.stopPropagation();
            event.preventDefault();
        };
        this.handleMouseUp = (event) => {
            if (this.state.isMouseDown) {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: false })));
                this.props.titleClick();
            }
            event.stopPropagation();
            event.preventDefault();
        };
        this.state = {
            isMouseDown: false
        };
    }
    render() {
        const { isExpanded, color, backgroundColor, borderColor, toggleExpand, titleClick, titleElements } = this.props;
        return (React.createElement(StyledAccordionTitle, Object.assign({ className: "accordion-title" }, this.theme, this.props, { onMouseUp: this.handleMouseUp, onMouseDown: this.handleMouseDown }),
            React.createElement(StyledAccordionButtonWrapper, { className: "accordion-expand-button-wrapper" },
                React.createElement(AccordionExpandButton, { isExpanded: isExpanded, size: "1.4rem", color: color || this.theme.palette.themePrimary, backgroundColor: backgroundColor || this.theme.palette.white, toggleExpand: toggleExpand })),
            React.createElement(StyledAccordionTitleElementWrapper, Object.assign({ className: "accordion-title-element-wrapper" }, this.theme, this.props), titleElements)));
    }
}
export default AccordionTitle;
