import { ManagePageContainerActionTypes } from "./types";
export const initialState = {
    userFilterFields: [],
    userFilterType: null,
    userFilter: null,
    calendarFilterFilterName: "",
    calendarFilterCalendarName: "",
    calendarFilterFields: [],
    calendarFilter: null,
    calendarFilterOpen: false,
    traccarVehicles: [],
    traccarBoundVehicles: [],
    traccarDevices: [],
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ManagePageContainerActionTypes.SET_USER_FILTER: {
            return Object.assign(Object.assign({}, state), { userFilter: action.userFilter, userFilterType: action.userFilterType, userFilterFields: action.userFilterFields });
        }
        case ManagePageContainerActionTypes.SET_CALENDAR_FILTER: {
            return Object.assign(Object.assign({}, state), { calendarFilterFilterName: action.calendarFilterFilterName, calendarFilterCalendarName: action.calendarFilterCalendarName, calendarFilter: action.calendarFilter, calendarFilterFields: action.calendarFilterFields });
        }
        case ManagePageContainerActionTypes.SET_CALENDAR_FILTER_OPEN: {
            return Object.assign(Object.assign({}, state), { calendarFilterOpen: action.open });
        }
        case ManagePageContainerActionTypes.SET_BIND_VEHICLE_WINDOW_STATE: {
            return Object.assign(Object.assign({}, state), { traccarVehicles: action.vehicles, traccarDevices: action.devices });
        }
        case ManagePageContainerActionTypes.SET_UNBIND_VEHICLE_WINDOW_STATE: {
            return Object.assign(Object.assign({}, state), { traccarBoundVehicles: action.boundVehicles });
        }
        default:
            return state;
    }
};
