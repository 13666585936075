import React from "react";
import "./InventoryInTestToUnavailableScreen.css";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
const failureReasons = [
    "1. Cleanliness.",
    "2. Sticker placement.",
    "3. Damaged goods.",
    "4. Spin test failure.",
    "5. Failed to program SmartPoint."
];
class InventoryInTestToUnavailableScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSelected: -1
        };
    }
    render() {
        const mainWrapperStyle = {
            height: "100%",
            width: "100%",
            borderWidth: "2px",
            borderColor: "#333333",
            borderStyle: "solid",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            backgroundColor: "#E1E1E1"
        };
        const failReasonContainerStyle = {
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const failReasonRowStyle = {
            width: "350px",
            height: "30px",
            fontSize: "20px",
            borderColor: "#333333",
            borderWidth: "2px",
            borderStyle: "solid",
            textAlign: "left",
            padding: StyleUtils.getCssPropertyFromArray([1, 10, 1, 10]),
            margin: StyleUtils.getCssPropertyFromArray([2, 10, 2, 10])
        };
        const failureItemsReasonWrapperStyle = {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemContainerStyle = {
            height: "100%",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemStyle = {
            height: "22px",
            fontSize: "19px",
            padding: StyleUtils.getCssPropertyFromArray([2, 11, 2, 11])
        };
        const itemTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const failureReasonTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const failureReasonsWrapperStyle = {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen", style: mainWrapperStyle },
            React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-reasons-wrapper", style: failureItemsReasonWrapperStyle },
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-container", style: itemContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-item-title", style: itemTitleStyle }, "Items"),
                    React.createElement("div", { style: {
                            height: "100%",
                            width: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden"
                        } }, this.props.data.selectedItems.map((item, index) => {
                        const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                        return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-item", key: index, style: Object.assign(Object.assign({}, itemStyle), { backgroundColor }) }, item.attributes.flexNetId));
                    }))),
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-container", style: failReasonContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-title", style: failureReasonTitleStyle }, "Test Failure Reason"),
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-wrapper", style: failureReasonsWrapperStyle }, failureReasons.map((reason, index) => (React.createElement("button", { className: "inventory-in-test-to-unavailable-screen-failure-reason", key: index, style: Object.assign(Object.assign({}, failReasonRowStyle), { backgroundColor: this.state.currentSelected === index ? "#A2DEFF" : "#FFFFFF" }), onMouseEnter: () => this.setState({ currentSelected: index }), onMouseLeave: () => this.setState({ currentSelected: -1 }), onClick: () => {
                            this.props.actions.inventoryInTestToUnavailableScreenProceed(this.props.data.selectedItems, failureReasons[index]);
                        } }, reason))))))));
    }
}
export default InventoryInTestToUnavailableScreen;
