export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["CHECK_BOX"] = "check";
    FilterTypes["MULTI_SELECT"] = "select";
    FilterTypes["STRING"] = "string";
    FilterTypes["DATE_TIME"] = "datetime";
    FilterTypes["NUMERIC"] = "numeric";
    FilterTypes["BOOLEAN"] = "boolean";
})(FilterTypes || (FilterTypes = {}));
export var FilterOperator;
(function (FilterOperator) {
    FilterOperator["in"] = "$in";
    FilterOperator["notIn"] = "$nin";
    FilterOperator["equal"] = "$eq";
    FilterOperator["notEqual"] = "$ne";
    FilterOperator["between"] = "$between";
    FilterOperator["lessThanOrEqual"] = "$le";
    FilterOperator["greaterThanOrEqual"] = "$ge";
    FilterOperator["lessThan"] = "$lt";
    FilterOperator["greaterThan"] = "$gt";
    FilterOperator["contains"] = "$contains";
    FilterOperator["notContains"] = "$nexist";
    FilterOperator["startsWith"] = "$sw";
    FilterOperator["endsWith"] = "$ew";
    FilterOperator["within"] = "$within";
    FilterOperator["currentDay"] = "$currentDay";
    FilterOperator["yesterday"] = "$yesterday";
    FilterOperator["nextDay"] = "$nextDay";
    FilterOperator["currentMonth"] = "$currentMonth";
    FilterOperator["nextMonth"] = "$nextMonth";
    FilterOperator["lastMonth"] = "$lastMonth";
    FilterOperator["currentWeek"] = "$currentWeek";
    FilterOperator["nextWeek"] = "$nextWeek";
    FilterOperator["lastWeek"] = "$lastWeek";
    FilterOperator["future"] = "$future";
})(FilterOperator || (FilterOperator = {}));
export var FilterJoinOperator;
(function (FilterJoinOperator) {
    FilterJoinOperator["and"] = "$and";
    FilterJoinOperator["or"] = "$or";
})(FilterJoinOperator || (FilterJoinOperator = {}));
