import * as React from "react";
import configureStore from "./state/store";
import { Provider } from "react-redux";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Theme from "./display/theme/Theme";
import LoginPageLegacy from "./legacy/pages/Login/LoginPage";
import DispatchPageLegacy from "./legacy/pages/Dispatch/DispatchPage";
import ManagePageLegacy from "./legacy/pages/Manage/ManagePage";
import InventoryPageContainer from "./display/containers/InventoryPageContainer/InventoryPageContainer";
import PasswordChangePageLegacy from "./legacy/pages/PasswordChange/PasswordChangePage";
import DashboardPageLegacy from "./legacy/pages/Dashboard/DashboardPage";
import ImageTagging from "./display/containers/ImageTagging";
import DispatchPage from "./display/web/pages/DispatchPage";
import ManagePage from "./display/web/pages/ManagePage";
import InventoryPage from "./display/web/pages/InventoryPage";
import { useEffect } from "react";
import ThemeContextProvider from "./display/theme/ThemeContextProvider";
import TestPage from "./display/web/pages/TestPage";
import LoginPageContainer from "./display/web/containers/LoginPageContainer";
import PasswordChangePageContainer from "./display/web/containers/PasswordChangePageContainer";
import FsrScheduler from "./display/components/FsrScheduler/FsrScheduler";
import Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import AVLPage from "./display/web/pages/AVLPage";
import AVLTestTool from "./display/web/pages/AVLTestTool";
import DocumentationPage from "./display/web/pages/documentation/DocumentationPage";
import FieldForce from "./display/containers/FieldForce";
import GraphView from "./legacy/pages/Graph/GraphView";
export var AppStore;
(function (AppStore) {
    AppStore.store = configureStore();
})(AppStore || (AppStore = {}));
const StyledApp = styled.div `
  width: 100%;
  height: 100%;
`;
const App = props => {
    const [login, setLogin] = React.useState("legacy");
    const [loading, setLoading] = React.useState(false);
    const setBodyStyle = () => {
        document.body.style.height = "100%";
        document.body.style.width = "100%";
        document.body.style.margin = "0px";
        document.body.style.borderWidth = "0px";
        document.body.style.padding = "0px";
    };
    const init = () => {
        Theme.initializeTheme();
        setBodyStyle();
        window.store = AppStore.store;
    };
    useEffect(() => {
        init();
        (async () => {
            var _a, _b;
            setLoading(true);
            try {
                const isNewGUIEnabledResp = await fetch("/sl3/rest/config_json/new_gui_enabled");
                const isNewGUIEnabled = await isNewGUIEnabledResp.json();
                if (isNewGUIEnabled === true) {
                    const userSettings = (_a = JSON.parse(localStorage.getItem("userSettings"))) === null || _a === void 0 ? void 0 : _a[0];
                    if (((_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.user_setting_object) === null || _b === void 0 ? void 0 : _b.defaultGui1) === "sl7") {
                        setLogin("sl7");
                    }
                    else {
                        setLogin("legacy");
                    }
                }
                else {
                    setLogin("legacy");
                }
            }
            catch (e) {
                console.error(e);
                setLogin("legacy");
            }
            setLoading(false);
        })();
    }, []);
    return (React.createElement(ThemeContextProvider, null,
        React.createElement(Provider, { store: AppStore.store },
            React.createElement(DndProvider, { backend: Backend },
                React.createElement(StyledApp, { className: "app" },
                    React.createElement(Router, null,
                        React.createElement(Switch, null,
                            React.createElement(Route, { exact: true, path: "/" }, loading ? (React.createElement("div", { style: {
                                    width: "100vw",
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                } },
                                React.createElement("img", { src: "/sl3/images/ServiceLinkAnimation.gif", alt: "loading" }))) : login == "sl7" ? (React.createElement(FieldForce, null)) : (React.createElement(LoginPageContainer, null))),
                            React.createElement(Route, { path: "/login" },
                                React.createElement(LoginPageContainer, null)),
                            React.createElement(Route, { path: "/change-password" },
                                React.createElement(PasswordChangePageContainer, null)),
                            React.createElement(Route, { path: "/dispatch" },
                                React.createElement(DispatchPage, null)),
                            React.createElement(Route, { path: "/manage" },
                                React.createElement(ManagePage, null)),
                            React.createElement(Route, { path: "/inventory" },
                                React.createElement(InventoryPage, null)),
                            React.createElement(Route, { path: "/vott" },
                                React.createElement(ImageTagging, null)),
                            React.createElement(Route, { path: "/sl7" },
                                React.createElement(FieldForce, null)),
                            React.createElement(Route, { path: "/test" },
                                React.createElement(TestPage, null)),
                            React.createElement(Route, { path: "/sl3/documentation" },
                                React.createElement(DocumentationPage, null)),
                            React.createElement(Route, { path: "/sl3/d/avl" },
                                React.createElement(AVLPage, null)),
                            React.createElement(Route, { path: "/avltool" },
                                React.createElement(AVLTestTool, null))
                        //Legacy Pages
                        ,
                            "//Legacy Pages",
                            React.createElement(Route, { path: "/sl3/public/login" },
                                React.createElement(LoginPageLegacy, null)),
                            React.createElement(Route, { path: "/sl3/d/dispatch" },
                                React.createElement(DispatchPageLegacy, null)),
                            React.createElement(Route, { path: "/sl3/d/manage" },
                                React.createElement(ManagePageLegacy, null)),
                            React.createElement(Route, { path: "/sl3/d/inventory" },
                                React.createElement(InventoryPageContainer, null)),
                            React.createElement(Route, { path: "/sl3/d/changepassword" },
                                React.createElement(PasswordChangePageLegacy, null)),
                            React.createElement(Route, { path: "/sl3/graph" },
                                React.createElement(GraphView, null)),
                            React.createElement(Route, { path: "/sl3/vott" },
                                React.createElement(ImageTagging, null)),
                            React.createElement(Route, { path: "/sl3/d/dashboard" },
                                " ",
                                "//TODO: Dashboard is not ported yet.",
                                React.createElement(DashboardPageLegacy, null)),
                            React.createElement(Route, { path: "/sl3/sandbox" },
                                React.createElement("div", null,
                                    React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
                                        React.createElement(FsrScheduler, null)))))))))));
};
export default App;
