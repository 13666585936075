import React, { useRef, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import DateTimePicker from "../../../../components/DateTimePicker/DateTimePicker";
import { Collapse } from "@material-ui/core";
import { useEventListener } from "../../../../../hooks/General/useEventListener";
import moment from "moment";
const getVariableColor = (color, isInFocus) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        return isInFocus ? color.inFocus : color.normal;
    }
};
export const StyledControlledDateTimeInputWrapper = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  display: flex;
  flex-direction: column;
`;
export const StyledControlledDateTimeInput = styled.div `
  outline: none;
  cursor: text;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  text-align: ${props => props.textAlign};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isInFocus)};
  color: ${props => getVariableColor(props.fontColor, props.isInFocus)};
  font-weight: ${props => props.fontWeight};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => getVariableColor(props.borderColor, props.isInFocus)};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
  ::placeholder {
    color: ${props => getVariableColor(props.placeholderColor, props.isInFocus)}
  }
`;
const ControlledDateTimeInput = (props) => {
    const ref = useRef(null);
    const { index, interval = 10, dateTime, dateFormat = "MMMM Do YYYY, h:mm a", height = 16, width = 210, fontSize = 14, backgroundColor = "#FFFFFF", placeholderColor = "#757575", fontColor = "#000000", fontWeight = "normal", textAlign = "left", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleSubmit, } = props;
    const [isInFocus, setIsInFocus] = useState(false);
    const onWindowClick = (e) => {
        if (!ref.current.contains(e.target)) {
            e.stopPropagation();
            setIsInFocus(false);
        }
    };
    useEventListener("click", onWindowClick);
    const onClick = (e) => {
        setIsInFocus(true);
    };
    const handleDateTimeChange = (dateTime) => {
        setIsInFocus(false);
        if (handleSubmit) {
            handleSubmit(dateTime, index);
        }
    };
    return (React.createElement(StyledControlledDateTimeInputWrapper, { className: "datetime-input-wrapper", height: height, width: width },
        React.createElement(StyledControlledDateTimeInput, { className: "datetime-input", ref: ref, onClick: onClick, isInFocus: isInFocus, height: height, width: width, fontSize: fontSize, backgroundColor: backgroundColor, placeholderColor: placeholderColor, fontColor: fontColor, fontWeight: fontWeight, textAlign: textAlign, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding }, moment(dateTime).format(dateFormat)),
        React.createElement(Collapse, { in: isInFocus },
            React.createElement(DateTimePicker, { key: dateTime, handleDateTimeChange: handleDateTimeChange, initialDateTime: dateTime, stopClickPropagation: true, interval: interval }))));
};
export default ControlledDateTimeInput;
