import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import Spacer from "../../Spacer/Spacer";
import ControlledTextInput from "../ControlledTextInput/ControlledTextInput";
export const ControlledTwoFactorAuthenticationInputWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
`;
const ControlledTwoFactorAuthenticationInput = (props) => {
    const { value, capitalize = false, numberOfInputs = 6, filterRegex = /[0-9]|\./, height = 100, width = 250, backgroundColor = "#F6F6F6", spacerWidth = 10, middleSpacerWidth = 36, inputHeight = 27, inputWidth = 27, inputBorderWidth = 1, inputBorderRadius = 5, inputBorderColor = "#757575", inputFontColor = "#000000", inputFontSize = 18, inputFontWeight = "bold", handleChange, handleSubmit } = props;
    const onChange = (newValue, index) => {
        const newState = [...value];
        if (!!filterRegex) {
            if (filterRegex.test(newValue)) {
                if (newValue.length > 1) {
                    newState[index] = newValue[1];
                }
                else {
                    newState[index] = newValue;
                }
                onRightMove(index);
            }
            else {
                if (!newValue) {
                    newState[index] = newValue;
                }
            }
        }
        else {
            if (!newValue) {
                newState[index] = newValue;
            }
            else {
                if (newValue.length > 1) {
                    newState[index] = newValue[1];
                }
                else {
                    newState[index] = newValue;
                }
                onRightMove(index);
            }
        }
        const previousStringValue = value.join("");
        const newStringValue = newState.join("");
        if (previousStringValue !== newStringValue && handleChange) {
            handleChange(capitalize ? newState.map(s => s.toUpperCase()) : newState);
        }
    };
    const onSubmit = () => {
        if (handleSubmit) {
            handleSubmit(value);
        }
    };
    const onLeftMove = (index) => {
        if (index > 0) {
            refs[index - 1].current.focus();
            refs[index - 1].current.setSelectionRange(1, 1);
        }
        else {
            refs[index].current.focus();
            refs[index].current.setSelectionRange(1, 1);
        }
    };
    const onRightMove = (index) => {
        if (index < numberOfInputs - 1) {
            refs[index + 1].current.focus();
            refs[index + 1].current.setSelectionRange(1, 1);
        }
        else {
            refs[index].current.focus();
            refs[index].current.setSelectionRange(1, 1);
        }
    };
    const onDelete = (index) => {
        const stringValue = value[index];
        if (index > 0 && !stringValue) {
            onLeftMove(index);
        }
    };
    useEffect(() => {
        refs[0].current.focus();
    }, []);
    const refs = Array.from({ length: numberOfInputs }, e => useRef(null));
    return (React.createElement(ControlledTwoFactorAuthenticationInputWrapper, { className: "two-factor-authentication-input-wrapper", height: height, width: width, backgroundColor: backgroundColor }, refs.map((ref, index) => {
        let calculatedSpacerWidth = spacerWidth;
        if (index === 0) {
            calculatedSpacerWidth = 0;
        }
        if (index === Math.floor(numberOfInputs / 2)) {
            calculatedSpacerWidth = middleSpacerWidth;
        }
        return (React.createElement(React.Fragment, { key: index },
            React.createElement(Spacer, { key: index * 2, width: calculatedSpacerWidth }),
            React.createElement(ControlledTextInput, { key: index * 2 + 1, index: index, ref: ref, value: value[index], handleChange: onChange, handleSubmit: onSubmit, handleLeftMove: onLeftMove, handleRightMove: onRightMove, handleDelete: onDelete, placeholder: "", height: inputHeight, width: inputWidth, textAlign: "center", fontSize: inputFontSize, fontColor: inputFontColor, fontWeight: inputFontWeight, borderRadius: inputBorderRadius, borderWidth: inputBorderWidth, borderColor: inputBorderColor })));
    })));
};
export default ControlledTwoFactorAuthenticationInput;
