import React, { useState } from "react";
import styled from "styled-components";
import SimpleButton from "../../SimpleButton/SimpleButton";
import { Callout, DirectionalHint, getTheme, PrimaryButton, Stack } from "office-ui-fabric-react";
import Dropdown from "../../Dropdown/Dropdown";
import MultiSelect from "../../MultiSelect/MultiSelect";
import ModalScreen from "../../ModalScreen/ModalScreen";
const StyledFlexBoxItem = styled.div `
  margin: 0.3rem;
`;
const StyledFilterHeader = styled.div `
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
const StyledFilterControlWrapper = styled.div ``;
const StyledStringFilterInput = styled.input `
  height: 2rem;
  width: 19.4rem;
  border-width: 0.2rem;
  padding: 0.1rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${props => props.palette.black};
  user-select: none;
`;
class FilterControl extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.renderFilterSettingButton = ([partClassName, iconName, handler], index) => {
            return (React.createElement(StyledFlexBoxItem, { key: index },
                React.createElement(SimpleButton, { className: `filter-settings-${partClassName}`, iconProps: {
                        iconName: iconName,
                        size: "1.6rem"
                    }, padding: ".3rem", color: this.theme.palette.themePrimary, backgroundColor: "white", buttonClick: handler })));
        };
        this.saveConfigurationRef = React.createRef();
        this.state = { saveName: "", savePromptOpen: false, isAddingFilterFieldsModalOpen: false };
    }
    render() {
        const { filterControlState, filterControlActions } = this.props;
        const { saveFilterHandler, resetToDefaultHandler } = filterControlActions;
        let saveCallout = (React.createElement(Callout, { directionalHint: DirectionalHint.bottomLeftEdge, target: this.saveConfigurationRef, onDismiss: e => this.setState({ savePromptOpen: false }) },
            React.createElement("div", { style: { padding: "1.0rem" } },
                React.createElement(Stack, null,
                    React.createElement(Stack.Item, null,
                        React.createElement("div", { style: { paddingBottom: "0.3rem" } }, "Save filter as:")),
                    React.createElement(Stack.Item, null,
                        React.createElement(Stack, { horizontal: true },
                            React.createElement(Stack.Item, null,
                                React.createElement(StyledStringFilterInput, Object.assign({}, this.theme, { onChange: e => this.setState({ saveName: e.target.value }) }))),
                            React.createElement(Stack.Item, null,
                                React.createElement("div", { style: { padding: "0.3rem" } },
                                    React.createElement(SimpleButton, { className: "string-filter-button", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, padding: "0.3rem 1.1rem", textProps: {
                                            width: "8rem",
                                            height: "1.2rem",
                                            iconText: "Save Filter",
                                            textAlign: "center",
                                            fontWeight: "bold"
                                        }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: () => {
                                            saveFilterHandler({ saveName: this.state.saveName });
                                            this.setState({ savePromptOpen: false });
                                        } })))))))));
        let fieldsThatCanBeFilteredOn = Object.entries(this.props.filterControlData.schemas).map(([fieldName, schema]) => ({ key: fieldName, displayedText: schema.displayedText }));
        let fieldsThatAreAlreadyBeingFiltered = this.props.filterControlData.workingConfiguration.filterRowsData.map(({ name }) => name);
        return (React.createElement(StyledFilterControlWrapper, { className: "filter-control-wrapper" },
            React.createElement(ModalScreen, { isVisible: this.state.isAddingFilterFieldsModalOpen, minDistanceFromEdge: 500, maxWidth: 500, maxHeight: 400, titleText: "Select which fields to filter on", isBlocking: false, onDismiss: () => this.setState({ isAddingFilterFieldsModalOpen: false }) },
                React.createElement(FieldFilterSelection, { possibleFields: fieldsThatCanBeFilteredOn, alreadyFilteredFields: fieldsThatAreAlreadyBeingFiltered, fieldsSelectionCommittedHandler: fieldsSelected => {
                        this.setState({ isAddingFilterFieldsModalOpen: false });
                        this.props.filterControlActions.fieldsToFilterOnChangedHandler(fieldsSelected);
                    } })),
            React.createElement(StyledFilterHeader, null,
                React.createElement(StyledFlexBoxItem, null,
                    React.createElement(Dropdown, { selectedKey: this.props.filterControlData.workingConfigurationName, handleChange: this.props.filterControlActions.getFilterConfigurationHandler, options: filterControlState.filterNames.map(filterName => ({ key: filterName, text: filterName })) })),
                React.createElement(StyledFlexBoxItem, { ref: this.saveConfigurationRef },
                    React.createElement(SimpleButton, { className: `filter-settings-${"save"}`, iconProps: {
                            iconName: "floppy-disk",
                            size: "1.6rem"
                        }, padding: ".3rem", color: this.theme.palette.themePrimary, backgroundColor: "white", buttonClick: e => this.setState({ savePromptOpen: true }) })),
                this.state.savePromptOpen ? saveCallout : null,
                [
                    [
                        "delete",
                        "bin",
                        () => {
                            return this.props.filterControlActions.deleteFilterHandler({
                                saveName: this.props.filterControlData.workingConfigurationName
                            });
                        }
                    ],
                    [
                        "add",
                        "plus",
                        () => {
                            this.setState({ isAddingFilterFieldsModalOpen: true });
                        }
                    ],
                    ["reset", "loop", resetToDefaultHandler]
                ].map((element, index) => this.renderFilterSettingButton(element, index)))));
    }
}
const FieldFilterSelection = ({ alreadyFilteredFields, possibleFields, fieldsSelectionCommittedHandler }) => {
    let [selectedValues, setSelectedValues] = useState(alreadyFilteredFields);
    return (React.createElement(Stack, null,
        React.createElement(Stack.Item, null,
            React.createElement(MultiSelect, { multiSelectData: possibleFields, selectedKeys: alreadyFilteredFields, multiSelectUpdated: event => setSelectedValues(event.selected.map(({ key }) => key)) })),
        React.createElement(Stack.Item, null,
            React.createElement("div", { style: { paddingLeft: "0.3rem", paddingRight: "0.3rem", paddingBottom: "0.3rem" } },
                React.createElement(PrimaryButton, { text: "Finish", onClick: e => fieldsSelectionCommittedHandler(selectedValues) })))));
};
export default FilterControl;
