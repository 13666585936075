import React from "react";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import ResizablePanelControlBar, { controlBarWidth } from "../ResizablePanelControlBar/ResizablePanelControlBar";
import { getTheme } from "office-ui-fabric-react";
import { Draggable } from "react-beautiful-dnd";
import ResizablePanelResizer from "../ResizablePanelResizer/ResizablePanelResizer";
class ResizablePanelItemWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.onToggleHide = () => {
            this.props.toggleHideHandler({ index: this.props.index });
        };
    }
    render() {
        const { index, orderIndex, lastIndex, lengthPosition, itemState, horizontal, parentGeometry, resizeHandler, children } = this.props;
        const hidden = itemState ? itemState.hidden : false;
        const itemHeight = itemState ? itemState.itemHeight : 0;
        const itemWidth = itemState ? itemState.itemWidth : 0;
        const color = itemState
            ? itemState.color
                ? itemState.color
                : this.theme.palette.themePrimary
            : this.theme.palette.themePrimary;
        const name = itemState ? itemState.name || "" : "";
        const itemStyle = {
            height: StyleUtils.getCssPixelString(itemHeight),
            width: StyleUtils.getCssPixelString(itemWidth),
            display: "flex",
            flexDirection: horizontal ? "row" : "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            overflow: "hidden"
        };
        const parentWidth = horizontal ? itemHeight : itemWidth;
        if (hidden) {
            return (React.createElement(Draggable, { key: index, draggableId: index.toString(), index: index }, (provided, snapshot) => (React.createElement("div", Object.assign({ className: "resizable-panel-item-wrapper", key: index, ref: provided.innerRef }, provided.draggableProps, { style: Object.assign(Object.assign({}, itemStyle), provided.draggableProps.style) }),
                React.createElement(ResizablePanelControlBar, { horizontal: horizontal, hidden: hidden, length: parentWidth, color: color, name: name, dragHandleProps: provided.dragHandleProps, toggleHideHandler: this.onToggleHide })))));
        }
        else {
            const itemChildrenWrapperStyle = {
                height: StyleUtils.getCssPixelString(horizontal ? itemHeight : itemHeight - controlBarWidth),
                width: StyleUtils.getCssPixelString(horizontal ? itemWidth - controlBarWidth : itemWidth)
            };
            if (index === lastIndex) {
                return (React.createElement(Draggable, { key: index, draggableId: index.toString(), index: index }, (provided, snapshot) => (React.createElement("div", Object.assign({ className: "resizable-panel-item-wrapper", key: index, ref: provided.innerRef }, provided.draggableProps, { style: Object.assign(Object.assign({}, itemStyle), provided.draggableProps.style) }),
                    React.createElement("div", { className: "resizable-panel-item-children-wrapper", style: itemChildrenWrapperStyle }, children),
                    React.createElement(ResizablePanelControlBar, { horizontal: horizontal, hidden: hidden, length: parentWidth, color: color, name: name, dragHandleProps: provided.dragHandleProps, toggleHideHandler: this.onToggleHide })))));
            }
            else {
                return (React.createElement(Draggable, { key: index, draggableId: index.toString(), index: index }, (provided, snapshot) => (React.createElement("div", Object.assign({ className: "resizable-panel-item-wrapper", key: index, ref: provided.innerRef }, provided.draggableProps, { style: Object.assign(Object.assign({}, itemStyle), provided.draggableProps.style) }),
                    React.createElement("div", { className: "resizable-panel-item-children-wrapper", style: itemChildrenWrapperStyle }, children),
                    React.createElement(ResizablePanelControlBar, { horizontal: horizontal, hidden: hidden, length: parentWidth, color: color, name: name, dragHandleProps: provided.dragHandleProps, toggleHideHandler: this.onToggleHide }),
                    React.createElement(ResizablePanelResizer, { horizontal: horizontal, index: index, lastIndex: lastIndex, position: lengthPosition, length: parentWidth, color: color, parentGeometry: parentGeometry, itemHeight: itemHeight, itemWidth: itemWidth, resizeHandler: resizeHandler })))));
            }
        }
    }
}
export default ResizablePanelItemWrapper;
