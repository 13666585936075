import { all } from "redux-saga/effects";
import InventoryTableContainerSagas from "./containers/InventoryTableContainer/sagas";
import InventoryContextMenuContainerSagas from "./containers/InventoryContextMenuContainer/sagas";
import InventoryScreenContainerSagas from "./containers/InventoryScreenContainer/sagas";
import InitializationSagas from "./services/InventoryInitialization/sagas";
import InventoryCacheSagas from "./services/InventoryCache/sagas";
import InventoryFilterContainerSagas from "./containers/InventoryFilterContainer/sagas";
import InventoryOmniSearchSagas from "./containers/InventoryOmniSearchContainer/sagas";
import InventoryFilterSagas from "./services/InventoryFilter/sagas";
import LoginPageContainerSagas from "./containers/LoginPageContainer/sagas";
import PasswordChangePageContainerSagas from "./containers/PasswordChangePageContainer/sagas";
import DispatchPageContainerSagas from "./containers/DispatchPageContainer/sagas";
import ManagePageContainerSagas from "./containers/ManagePageContainer/sagas";
import AvlPageContainerSagas from "./containers/AVLPageContainer/sagas";
import GlobalActionSagas from "./services/GlobalActions/sagas";
function* rootSaga() {
    yield all({
        inventory: all(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, InventoryTableContainerSagas), InventoryContextMenuContainerSagas), InventoryScreenContainerSagas), InitializationSagas), InventoryFilterContainerSagas), InventoryCacheSagas), InventoryOmniSearchSagas), InventoryFilterSagas)),
        login: all(Object.assign({}, LoginPageContainerSagas)),
        passwordChange: all(Object.assign({}, PasswordChangePageContainerSagas)),
        dispatch: all(Object.assign({}, DispatchPageContainerSagas)),
        manage: all(Object.assign({}, ManagePageContainerSagas)),
        avl: all(Object.assign({}, AvlPageContainerSagas)),
        global: all(Object.assign({}, GlobalActionSagas)),
    });
}
export default rootSaga;
