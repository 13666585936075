import { call, put, select, takeEvery } from "redux-saga/effects";
import Cookies from "universal-cookie";
import { PasswordChangePageContainerActionTypes } from "./types";
import axios from "axios";
import { Utils } from "../../../helper/General/Utils";
import IndexedDBService, { IndexedDBKeys } from "../../../helper/IndexedDB/IndexedDBService";
import { LegacyTheme } from "../../../helper/LegacyTheme/LegacyTheme";
const cookies = new Cookies();
export const passwordChangePageContainerSagas = {
    initializeSaga: takeEvery(PasswordChangePageContainerActionTypes.INITIALIZE, initializeSaga),
    handleChangePassword: takeEvery(PasswordChangePageContainerActionTypes.HANDLE_CHANGE_PASSWORD, handleChangePasswordSaga),
    processResponse: takeEvery(PasswordChangePageContainerActionTypes.PROCESS_RESPONSE, processResponse)
};
//TODO: Change password API and Style API should be changed and cleaned up. Style API should be moved to main user configuration object. Using legacy version for now.
function* initializeSaga(action) {
    const initialState = yield select(getStateSelector);
    yield call(IndexedDBService.initialize);
    let logined = legacyIsLogined();
    if (!logined) {
        yield put({
            type: PasswordChangePageContainerActionTypes.SET_STATE,
            state: Object.assign(Object.assign({}, initialState), { shouldGoBack: true })
        });
    }
    const lastTheme = yield call(IndexedDBService.get, IndexedDBKeys.THEME);
    let passwordChangePageStyleProps = {};
    if (lastTheme) {
        passwordChangePageStyleProps = LegacyTheme.legacyThemeObject[lastTheme] || {};
    }
    else {
        const passwordChangePageResponse = yield call(getTheme);
        passwordChangePageStyleProps = Utils.snakeToCamelCaseObject(passwordChangePageResponse.data, []);
        passwordChangePageStyleProps = Object.assign(Object.assign({}, passwordChangePageStyleProps), { loginMenuLogoSrc: "/sl3/images/comp.svg", width: 430 });
    }
    yield put({
        type: PasswordChangePageContainerActionTypes.SET_STATE,
        state: Object.assign(Object.assign(Object.assign({}, initialState), { isInitialized: true }), passwordChangePageStyleProps)
    });
}
function* handleChangePasswordSaga(action) {
    const nonLoadingState = yield select(getStateSelector);
    yield put({
        type: PasswordChangePageContainerActionTypes.SET_STATE,
        state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: false, errorMessage: "", message: "Changing password..." })
    });
    const startTime = performance.now();
    const oldPassword = action.oldPassword;
    const newPassword = action.newPassword;
    const changePasswordRsp = yield call(sendChangePasswordRequest, oldPassword, newPassword);
    yield put({
        type: PasswordChangePageContainerActionTypes.PROCESS_RESPONSE,
        data: changePasswordRsp,
        startTime: startTime,
    });
}
function* processResponse(action) {
    const nonLoadingState = yield select(getStateSelector);
    const changePasswordRsp = action.data;
    const rspErrorMessage = changePasswordRsp.error;
    if (rspErrorMessage) {
        yield put({
            type: PasswordChangePageContainerActionTypes.SET_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: rspErrorMessage })
        });
        return;
    }
    else if (!changePasswordRsp) {
        yield put({
            type: PasswordChangePageContainerActionTypes.SET_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { isLoadingComplete: true, errorMessage: "Unable to obtain server data." })
        });
    }
    else {
        yield put({
            type: PasswordChangePageContainerActionTypes.SET_STATE,
            state: Object.assign(Object.assign({}, nonLoadingState), { shouldGoBack: true, isLoadingComplete: true, errorMessage: "", message: "Password changed." })
        });
    }
}
function getStateSelector(store) {
    return Object.assign(Object.assign({}, store.login), { shouldGoBack: store.passwordChange.shouldGoBack, isInitialized: store.passwordChange.isInitialized, errorMessage: store.passwordChange.errorMessage, message: store.passwordChange.message, isLoadingComplete: store.passwordChange.isLoadingComplete, width: store.passwordChange.width });
}
//Legacy functions. Do not use, Deprecated. Start.
//Deprecated
const getTheme = () => {
    return axios.get("/sl3/rest/theme/login_page");
};
//fake function because legacy dispatch page does not store accesstoken
//Deprecated
const legacyIsLogined = () => !!cookies.get("X-ServiceLink-Auth");
const sendChangePasswordRequest = (oldPassword, newPassword) => {
    const request = {
        old_password: oldPassword,
        new_password: newPassword
    };
    return axios.post("/sl3/rest/auth/change_password", request, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Data-Type": "json",
            "Mobile-Type": "HTML",
            "X-ServiceLink-Auth": cookies.get("X-ServiceLink-Auth")
        }
    })
        .then((rsp) => rsp.data)
        .then((rsp) => {
        return {
            success: rsp.success,
            error: rsp.error
        };
    })
        .catch((e) => {
        if (e.response.data.error) {
            console.error(e);
            return {
                success: false,
                error: e.response.data.error.reason
            };
        }
        let error = "";
        switch (e.response.status) {
            case 400: {
                error = "Bad change password request.";
                break;
            }
            case 401: {
                error = "Unauthorized.";
                break;
            }
            case 403: {
                error = "Forbidden.";
                break;
            }
            case 404: {
                error = "Change password endpoint is not found.";
                break;
            }
            case 500: {
                error = "Internal server error.";
                break;
            }
            case 503: {
                error = "Server is unavailable.";
                break;
            }
            default: {
                error = "Unexpected server response.";
            }
        }
        console.error(e);
        return {
            success: false,
            error: error
        };
    });
};
//Deprecated
export const redirect = (url) => {
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
        const referLink = document.createElement("a");
        referLink.href = url;
        document.body.appendChild(referLink);
        referLink.click();
    }
    else {
        location.href = url;
    }
};
export default passwordChangePageContainerSagas;
