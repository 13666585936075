import React, { useEffect, useState } from "react";
import { createStyles, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "14px",
        paddingTop: "4px",
        paddingBottom: "4px",
        boxSizing: "content-box",
        paddingLeft: "4px",
        backgroundColor: theme.palette.background.paper,
    },
    checkbox: {
        height: "12px",
        width: "12px",
        padding: "1px",
        marginRight: "10px",
    },
    label: {
        height: "14px",
        fontSize: "14px",
    },
}));
const CheckboxTreeItem = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { isChecked, isDisabled, id, url, label, handleClick, } = props;
    const [checked, setChecked] = useState(isChecked);
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);
    const handleChange = (event) => {
        event.stopPropagation();
        if (!isDisabled) {
            setChecked(prevState => {
                const newState = !prevState;
                handleClick(newState, id, url);
                return newState;
            });
        }
    };
    return (React.createElement("div", { className: classes.root, onClick: handleChange },
        React.createElement(Checkbox, { className: classes.checkbox, checked: checked, color: "primary", disabled: isDisabled }),
        React.createElement(Typography, { className: classes.label, variant: "h5" }, label)));
};
export default CheckboxTreeItem;
