import React from "react";
import styled from "styled-components";
import { FilterTypes } from "../types";
import CheckBoxFilterRow from "./CheckBoxFilterRow/CheckBoxFilterRow";
import MultiSelectFilterRow from "./MultiSelectFilterRow/MultiSelectFilterRow";
import StringFilterRow from "./StringFilterRow/StringFilterRow";
import DateTimeFilterRow from "./DateTimeFilterRow/DateTimeFilterRow";
import NumericFilterRow from "./NumericFilterRow/NumericFilterRow";
import BooleanFilterRow from "./BooleanFilterRow/BooleanFilterRow";
const StyledFilterRow = styled.div `
  width: 100%;
  height: max-content;
`;
const FilterRow = (props) => {
    const { type, name, filterKey, filter, filterField, handleFilterChange, } = props;
    const renderFilterRow = (type) => {
        switch (type) {
            case FilterTypes.CHECK_BOX: {
                const selectableElements = {};
                filterField.values.forEach((value, index) => {
                    const name = filterField.displayedNames[index];
                    const icon = filterField.displayedIcons ? filterField.displayedIcons[index] : null;
                    selectableElements[value] = {
                        name: name,
                        icon: icon,
                    };
                });
                return (React.createElement(CheckBoxFilterRow, { filterKey: filterKey, filterName: name, initialFilter: filter, initialExpand: !!filter, selectableValues: selectableElements, handleFilterChange: handleFilterChange }));
            }
            case FilterTypes.MULTI_SELECT: {
                const selectableElements = {};
                filterField.values.forEach((value, index) => {
                    const name = filterField.displayedNames[index];
                    const icon = filterField.displayedIcons ? filterField.displayedIcons[index] : null;
                    selectableElements[value] = {
                        name: name,
                        icon: icon,
                    };
                });
                let initialFilter = null;
                if (!!filter) {
                    if (filterKey === "JOB_CODE_ID1|JOB_CODE_ID2|JOB_CODE_ID3") {
                        if (filter.$or) {
                            initialFilter = filter.$or[0]["JOB_CODE_ID1"];
                        }
                        else if (filter["JOB_CODE_ID1"]) {
                            initialFilter = filter["JOB_CODE_ID1"];
                        }
                    }
                    else {
                        initialFilter = filter[filterKey];
                    }
                }
                return (React.createElement(MultiSelectFilterRow, { filterKey: filterKey, filterName: name, initialFilter: initialFilter, initialExpand: !!filter, selectableValues: selectableElements, handleFilterChange: handleFilterChange }));
            }
            case FilterTypes.STRING: {
                return (React.createElement(StringFilterRow, { filterKey: filterKey, filterName: name, initialFilter: filter ? filter[filterKey] : null, initialExpand: !!filter, handleFilterChange: handleFilterChange }));
            }
            case FilterTypes.DATE_TIME: {
                return (React.createElement(DateTimeFilterRow, { filterKey: filterKey, filterName: name, initialFilter: filter, initialExpand: !!filter, handleFilterChange: handleFilterChange }));
            }
            case FilterTypes.NUMERIC: {
                return (React.createElement(NumericFilterRow, { filterKey: filterKey, filterName: name, initialFilter: filter ? filter[filterKey] : null, initialExpand: !!filter, handleFilterChange: handleFilterChange }));
            }
            case FilterTypes.BOOLEAN: {
                return (React.createElement(BooleanFilterRow, { filterKey: filterKey, filterName: name, initialFilter: filter ? filter[filterKey] : null, initialExpand: !!filter, handleFilterChange: handleFilterChange }));
            }
            default: {
                return null;
            }
        }
    };
    return (React.createElement(StyledFilterRow, null, renderFilterRow(type)));
};
export default FilterRow;
