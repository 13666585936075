import React from "react";
import styled from "styled-components";
import { TooltipHost } from "office-ui-fabric-react";
import DayPickerTitle from "./DayPickerTitle/DayPickerTitle";
import moment from "moment";
import SimpleButton from "../../SimpleButton/SimpleButton";
const StyledDayPickerWrapper = styled.div `
  height: 20rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: ${props => props.backgroundColor};
`;
const StyledDayElementsWrapper = styled.div `
  height: 19.6rem;
  width: 19.6rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const StyledTitleElement = styled.div `
  height: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  width: 1.2rem;
  padding: 0.8rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
`;
const StyledDayElement = styled.div `
  height: 2.8rem;
  width: 2.8rem;
`;
class DayPicker extends React.Component {
    constructor(props) {
        super(props);
        this.getDayTitle = () => {
            const { weekdayOptions } = this.props;
            const weekdayTitle = [];
            for (let weekday = 0; weekday < 7; weekday++) {
                const weekdayObject = weekdayOptions[weekday];
                weekdayTitle.push(React.createElement(TooltipHost, { content: weekdayObject.weekdayLongName, calloutProps: { gapSpace: 0 } },
                    React.createElement(StyledTitleElement, Object.assign({ className: "weekday-title-element" }, this.props.colorOptions), weekdayObject.weekdayShortName)));
            }
            return weekdayTitle;
        };
        this.getRenderColors = (day, monthOffset, yearOffset) => {
            const { currentTime, selectedYear, displayedYear, selectedMonth, displayedMonth, selectedDay, displayedDay, colorOptions } = this.props;
            const { color, backgroundColor, selectedColor, selectedBackgroundColor, hoverColor, hoverBackgroundColor, todayColor, todayBackgroundColor, otherMonthColor } = colorOptions;
            const displayedYearWithOffset = displayedYear + yearOffset;
            const displayedMonthWithOffset = displayedMonth + monthOffset;
            const currentYear = moment(currentTime).year();
            const currentMonth = moment(currentTime).month();
            const currentDay = moment(currentTime).date();
            const isCurrentYear = currentYear === displayedYearWithOffset;
            const isCurrentMonth = currentMonth === displayedMonthWithOffset && isCurrentYear;
            const isCurrentDay = currentDay === day && isCurrentMonth;
            const isSelectedYear = selectedYear === displayedYearWithOffset;
            const isSelectedMonth = selectedMonth === displayedMonthWithOffset && isSelectedYear;
            const isSelectedDay = selectedDay === day && isSelectedMonth;
            if (monthOffset === 0) {
                return {
                    normalOptionalProps: {
                        backgroundColor: isCurrentDay
                            ? todayBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor,
                        borderColor: isCurrentDay
                            ? isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor,
                        textColor: isCurrentDay ? todayColor : isSelectedDay ? selectedColor : color
                    },
                    hoveringOptionalProps: {
                        backgroundColor: isCurrentDay
                            ? todayBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor,
                        borderColor: isCurrentDay
                            ? isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor,
                        textColor: isCurrentDay ? todayColor : isSelectedDay ? selectedColor : hoverColor
                    },
                    mouseDownOptionalProps: {
                        backgroundColor: isCurrentDay ? todayBackgroundColor : selectedBackgroundColor,
                        borderColor: selectedBackgroundColor,
                        textColor: isCurrentDay ? todayColor : selectedColor
                    }
                };
            }
            else {
                return {
                    normalOptionalProps: {
                        backgroundColor: isCurrentDay
                            ? todayBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor,
                        borderColor: isCurrentDay
                            ? isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : backgroundColor,
                        textColor: isCurrentDay ? todayColor : otherMonthColor
                    },
                    hoveringOptionalProps: {
                        backgroundColor: isCurrentDay
                            ? todayBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor,
                        borderColor: isCurrentDay
                            ? isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor
                            : isSelectedDay
                                ? selectedBackgroundColor
                                : hoverBackgroundColor,
                        textColor: isCurrentDay ? todayColor : otherMonthColor
                    },
                    mouseDownOptionalProps: {
                        backgroundColor: isCurrentDay ? todayBackgroundColor : selectedBackgroundColor,
                        borderColor: selectedBackgroundColor,
                        textColor: isCurrentDay ? todayColor : otherMonthColor
                    }
                };
            }
        };
        this.getDays = () => {
            const { currentTime, displayedYear, displayedMonth, displayedDay, colorOptions, handleYearMonthDayChange } = this.props;
            const { color, backgroundColor, selectedColor, selectedBackgroundColor, hoverColor, hoverBackgroundColor, todayColor, todayBackgroundColor, otherMonthColor } = colorOptions;
            let totalDays = 0;
            const displayedTime = moment()
                .year(displayedYear)
                .month(displayedMonth)
                .date(displayedDay)
                .valueOf();
            const daysInMonth = moment(displayedTime).daysInMonth();
            const startWeekdayOfMonth = moment(displayedTime)
                .startOf("month")
                .day();
            const daysInLastMonth = moment(displayedTime)
                .subtract(1, "months")
                .daysInMonth();
            const days = [];
            const textProps = {
                iconText: "",
                height: "1.2rem",
                width: "1.6rem",
                textAlign: "center"
            };
            const borderRadius = "0.2rem";
            const borderWidth = "0.5rem 0.3rem";
            const padding = "0.3rem";
            for (let index = 0; index < startWeekdayOfMonth; index++) {
                const lastMonthDay = daysInLastMonth - startWeekdayOfMonth + index + 1;
                const lastMonthDayString = lastMonthDay.toString();
                const yearOffset = displayedMonth === 0 ? -1 : 0;
                const handleButtonClick = () => {
                    handleYearMonthDayChange(displayedYear + yearOffset, displayedMonth === 0 ? 11 : displayedMonth - 1, lastMonthDay);
                };
                days.push(React.createElement(StyledDayElement, { className: "last-month-day-element" },
                    React.createElement(SimpleButton, Object.assign({ className: "last-month-day-element-button", textProps: Object.assign(Object.assign({}, textProps), { iconText: lastMonthDayString }), color: color, backgroundColor: backgroundColor }, this.getRenderColors(lastMonthDay, -1, yearOffset), { borderRadius: borderRadius, borderWidth: borderWidth, padding: padding, buttonClick: handleButtonClick }))));
                totalDays++;
            }
            for (let index = 0; index < daysInMonth; index++) {
                const thisMonthDay = index + 1;
                const thisMonthDayString = thisMonthDay.toString();
                const handleButtonClick = () => {
                    handleYearMonthDayChange(displayedYear, displayedMonth, thisMonthDay);
                };
                days.push(React.createElement(StyledDayElement, { className: "day-element" },
                    React.createElement(SimpleButton, Object.assign({ className: "this-month-day-element-button", textProps: Object.assign(Object.assign({}, textProps), { iconText: thisMonthDayString }), color: color, backgroundColor: backgroundColor }, this.getRenderColors(thisMonthDay, 0, 0), { borderRadius: borderRadius, borderWidth: borderWidth, padding: padding, buttonClick: handleButtonClick }))));
                totalDays++;
            }
            const nextMonthDays = totalDays > 5 * 7 ? 6 * 7 - totalDays : 5 * 7 - totalDays;
            for (let index = 0; index < nextMonthDays; index++) {
                const nextMonthDay = index + 1;
                const nextMonthDayString = nextMonthDay.toString();
                const yearOffset = displayedMonth === 11 ? 1 : 0;
                const handleButtonClick = () => {
                    handleYearMonthDayChange(displayedYear + yearOffset, displayedMonth === 11 ? 0 : displayedMonth + 1, nextMonthDay);
                };
                days.push(React.createElement(StyledDayElement, { className: "next-month-day-element" },
                    React.createElement(SimpleButton, Object.assign({ className: "next-month-day-element-button", textProps: Object.assign(Object.assign({}, textProps), { iconText: nextMonthDayString }), color: color, backgroundColor: backgroundColor }, this.getRenderColors(nextMonthDay, 1, yearOffset), { borderRadius: borderRadius, borderWidth: borderWidth, padding: padding, buttonClick: handleButtonClick }))));
                totalDays++;
            }
            const longMonth = totalDays > 5 * 7;
            if (!longMonth) {
                for (let index = 0; index < 7; index++) {
                    days.push(React.createElement(StyledDayElement, { className: "empty-month-day-element" }));
                    totalDays++;
                }
            }
            return days;
        };
    }
    render() {
        const { selectedYear, displayedYear, selectedMonth, displayedMonth, currentTime, monthOptions, colorOptions, handleYearMonthChange } = this.props;
        return (React.createElement(StyledDayPickerWrapper, Object.assign({ className: "day-picker" }, this.props.colorOptions),
            React.createElement(DayPickerTitle, { selectedYear: selectedYear, displayedYear: displayedYear, selectedMonth: selectedMonth, displayedMonth: displayedMonth, currentTime: currentTime, monthOptions: monthOptions, colorOptions: colorOptions, handleYearMonthChange: handleYearMonthChange }),
            React.createElement(StyledDayElementsWrapper, { className: "day-picker-elements-wrapper" }, [...this.getDayTitle(), ...this.getDays()])));
    }
}
export default DayPicker;
