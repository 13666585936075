import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryRead;
(function (InventoryRead) {
    InventoryRead.ReadServiceName = "read";
    let ReadActionName;
    (function (ReadActionName) {
        ReadActionName["CONTAINER"] = "container";
        ReadActionName["CONTAINERS"] = "containers";
        ReadActionName["CONTAINERS_COUNT"] = "containers/count";
        ReadActionName["CONTAINERS_QUERY_COUNT"] = "containers/query/count";
        ReadActionName["CONTAINERS_CHILDREN_COUNT"] = "containers/children/count";
        ReadActionName["CONTAINER_CHILDREN"] = "containers/children";
        ReadActionName["CONTAINER_QUERY"] = "containers/query";
        ReadActionName["INVENTORY"] = "inventory";
        ReadActionName["INVENTORIES"] = "inventories";
        ReadActionName["INVENTORIES_COUNT"] = "inventories/count";
        ReadActionName["INVENTORIES_QUERY_COUNT"] = "inventories/query/count";
        ReadActionName["INVENTORY_QUERY"] = "inventories/query";
        ReadActionName["HIERARCHY"] = "hierarchy";
    })(ReadActionName = InventoryRead.ReadActionName || (InventoryRead.ReadActionName = {}));
    function containerRequestById(startIndex, endIndex, id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINER,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containerRequestById = containerRequestById;
    function containerRequestByPath(startIndex, endIndex, path) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINER,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: true,
                containerPath: path
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containerRequestByPath = containerRequestByPath;
    function containersRequest(startIndex, endIndex) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINERS,
            request: {
                start: startIndex,
                end: endIndex
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containersRequest = containersRequest;
    function containersCountRequest() {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINERS_COUNT,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containersCountRequest = containersCountRequest;
    function containersQueryCountRequest(orderBy, query) {
        const queryRequest = {
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINERS_QUERY_COUNT,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containersQueryCountRequest = containersQueryCountRequest;
    function containerChildrenRequestById(startIndex, endIndex, id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINER_CHILDREN,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containerChildrenRequestById = containerChildrenRequestById;
    function containerChildrenRequestByPath(startIndex, endIndex, path) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINER_CHILDREN,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: true,
                containerPath: path
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containerChildrenRequestByPath = containerChildrenRequestByPath;
    function containersChildrenCountRequestById(id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINERS_CHILDREN_COUNT,
            request: {
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containersChildrenCountRequestById = containersChildrenCountRequestById;
    function containersChildrenCountRequestByPath(path) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINERS_CHILDREN_COUNT,
            request: {
                usePath: true,
                containerPath: path
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containersChildrenCountRequestByPath = containersChildrenCountRequestByPath;
    function containerQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.CONTAINER_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.containerQueryRequest = containerQueryRequest;
    function inventoryRequestById(id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORY,
            request: {
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoryRequestById = inventoryRequestById;
    function inventoryRequestByPath(containerPath, inventoryId) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORY,
            request: {
                usePath: true,
                containerPath: containerPath,
                itemId: inventoryId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoryRequestByPath = inventoryRequestByPath;
    function inventoriesRequestById(startIndex, endIndex, id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORIES,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoriesRequestById = inventoriesRequestById;
    function inventoriesRequestByPath(startIndex, endIndex, containerPath) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORIES,
            request: {
                start: startIndex,
                end: endIndex,
                usePath: true,
                containerPath: containerPath
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoriesRequestByPath = inventoriesRequestByPath;
    function inventoriesCountRequestById(id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORIES_COUNT,
            request: {
                usePath: false,
                targetId: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoriesCountRequestById = inventoriesCountRequestById;
    function inventoriesCountRequestByPath(containerPath) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORIES_COUNT,
            request: {
                usePath: true,
                containerPath: containerPath
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoriesCountRequestByPath = inventoriesCountRequestByPath;
    function inventoryQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORY_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoryQueryRequest = inventoryQueryRequest;
    function inventoriesQueryCountRequest(orderBy, query) {
        const queryRequest = {
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.INVENTORIES_QUERY_COUNT,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.inventoriesQueryCountRequest = inventoriesQueryCountRequest;
    function hierarchyRequest(id) {
        const request = {
            service: InventoryRead.ReadServiceName,
            action: ReadActionName.HIERARCHY,
            request: {
                id: id
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryRead.hierarchyRequest = hierarchyRequest;
})(InventoryRead || (InventoryRead = {}));
