import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import LogoBar from "../../LogoBar/LogoBar";
export const StyledLoginMenuTitleBar = styled.div `
  width: 200%;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
`;
export const TextBar = styled.div `
  user-select: none;
  color: ${props => props.textColor};
  text-align: right;
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.textFontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.textFontSize)};
  font-weight: 700;
  margin-right: ${props => StyleUtils.getNormalizedPixelString(props.textOffSet)};
`;
export const TextBarWrapper = styled.div `
  position: relative;
  width: 50%;
  height: 100%;
  top: -100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
`;
const LoginMenuTitleBar = (props) => {
    const { height = 50, backgroundColor = "white", text = "Login", logoSrc = "/resources/images/comp.svg", logoHeight = 17, logoEdgeOffset = 35, fontColor = "#000000", fontSize = 17, textOffSet = 35 } = props;
    return (React.createElement(StyledLoginMenuTitleBar, { className: "login-menu-title-bar", height: height },
        React.createElement(LogoBar, { height: height, backgroundColor: backgroundColor, logoHeight: logoHeight, logoEdgeOffset: logoEdgeOffset, logoSrc: logoSrc }),
        React.createElement(TextBarWrapper, { className: "text-bar-wrapper", height: height },
            React.createElement(TextBar, { className: "text-bar", textColor: fontColor, textFontSize: fontSize, textOffSet: textOffSet }, text))));
};
export default LoginMenuTitleBar;
