import React from "react";
import styled from "styled-components";
import SimpleButton from "../../../SimpleButton/SimpleButton";
import moment from "moment";
const StyledMonthPickerWrapper = styled.div `
  width: 20rem;
  height: 2rem;
  background-color: ${props => props.colorOptions.backgroundColor};
`;
const StyledMonthPicker = styled.div `
  width: 20rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledMonthElement = styled.div `
  width: 14.5rem;
  height: 1.6rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 0.2rem 0.5rem;
  text-align: left;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-radius: 0.2rem;
  user-select: none;
`;
const StyledMiddleSeparator = styled.div `
  width: 2rem;
  height: 2rem;
`;
const StyledEndSeparator = styled.div `
  width: 0.5rem;
  height: 2rem;
`;
class DayPickerTitle extends React.Component {
    constructor(props) {
        super(props);
        this.time = undefined;
        this.defaultInterval = 500;
        this.repeatInterval = this.defaultInterval;
        this.intervalMultiplier = 0.7;
        this.lowestInterval = 100;
        this.repeatIncrease = () => {
            const { displayedYear, displayedMonth, handleYearMonthChange } = this.props;
            if (displayedMonth === 11) {
                handleYearMonthChange(displayedYear + 1, 0);
            }
            else {
                handleYearMonthChange(displayedYear, displayedMonth + 1);
            }
            this.time = window.setTimeout(this.repeatIncrease, Math.max(this.repeatInterval, this.lowestInterval));
            this.repeatInterval = this.repeatInterval * this.intervalMultiplier;
        };
        this.handleMouseDownIncrease = () => {
            this.repeatIncrease();
        };
        this.repeatDecrease = () => {
            const { displayedYear, displayedMonth, handleYearMonthChange } = this.props;
            if (displayedMonth === 0) {
                handleYearMonthChange(displayedYear - 1, 11);
            }
            else {
                handleYearMonthChange(displayedYear, displayedMonth - 1);
            }
            this.time = window.setTimeout(this.repeatDecrease, Math.max(this.repeatInterval, this.lowestInterval));
            this.repeatInterval = this.repeatInterval * this.intervalMultiplier;
        };
        this.handleMouseDownDecrease = () => {
            this.repeatDecrease();
        };
        this.handleMouseUp = () => {
            clearTimeout(this.time);
            this.repeatInterval = this.defaultInterval;
        };
    }
    render() {
        const { displayedYear, displayedMonth, currentTime, colorOptions, monthOptions } = this.props;
        const { color, backgroundColor, selectedColor, selectedBackgroundColor, hoverColor, hoverBackgroundColor, todayColor, todayBackgroundColor } = colorOptions;
        const currentYear = moment(currentTime).year();
        const currentMonth = moment(currentTime).month();
        const isCurrentYear = displayedYear === currentYear;
        const isCurrentMonth = displayedMonth === currentMonth;
        const isCurrentYearAndMonth = isCurrentYear && isCurrentMonth;
        const normalOptionalProps = {
            backgroundColor: backgroundColor,
            iconColor: color
        };
        const hoveringOptionalProps = {
            backgroundColor: hoverBackgroundColor,
            iconColor: hoverColor
        };
        const mouseDownOptionalProps = {
            backgroundColor: selectedBackgroundColor,
            iconColor: selectedColor
        };
        const monthColor = isCurrentYearAndMonth ? todayColor : color;
        const monthBackgroundColor = isCurrentYearAndMonth ? todayBackgroundColor : todayColor;
        const monthName = `${monthOptions[displayedMonth].monthLongName}`;
        const nextMonthName = displayedMonth === 11 ? monthOptions[0].monthLongName : monthOptions[displayedMonth + 1].monthLongName;
        const prevMonthName = displayedMonth === 0 ? monthOptions[11].monthLongName : monthOptions[displayedMonth - 1].monthLongName;
        const yearMonthName = `${monthName} ${displayedYear}`;
        return (React.createElement(StyledMonthPickerWrapper, Object.assign({ className: "month-picker-small-wrapper" }, this.props),
            React.createElement(StyledMonthPicker, Object.assign({ className: "month-picker-small" }, this.props),
                React.createElement(StyledMonthElement, { className: "month-picker-small-element", color: monthColor, backgroundColor: monthBackgroundColor }, yearMonthName),
                React.createElement(StyledMiddleSeparator, { className: "month-middle-separator" }),
                React.createElement(SimpleButton, { className: "month-decrease-button", color: color, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: backgroundColor, iconProps: { iconName: "arrow-left2", size: "1.4rem" }, margin: "0.3rem", borderRadius: "0.2rem", iconOffsetY: "-0.1rem", handleMouseDown: this.handleMouseDownDecrease, handleMouseUp: this.handleMouseUp, toolTip: `Go to previous month ${prevMonthName}` }),
                React.createElement(SimpleButton, { className: "month-increase-button", color: color, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: backgroundColor, iconProps: { iconName: "arrow-right2", size: "1.4rem" }, margin: "0.3rem", borderRadius: "0.2rem", iconOffsetY: "-0.1rem", handleMouseDown: this.handleMouseDownIncrease, handleMouseUp: this.handleMouseUp, toolTip: `Go to next month ${nextMonthName}` }),
                React.createElement(StyledEndSeparator, { className: "month-end-separator" }))));
    }
}
export default DayPickerTitle;
