import React, { useEffect, useState } from "react";
import TextButton from "../../Button/TextButton/TextButton";
import styled from "styled-components";
import Text from "../../Text/Text";
import Spacer from "../../Spacer/Spacer";
import SelectInput from "../../Input/SelectInput/SelectInput";
const StyledBindVehicleScreen = styled.div `
  height: calc(100% - 40px);
  width: calc(100% - 100px);
  margin: 20px 50px;
`;
const StyledButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
`;
const StyledInputWrapper = styled.div `
  height: calc(100% - 30px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const BindVehicleScreen = (props) => {
    const { vehicles, devices, handleBindVehicle, } = props;
    useEffect(() => {
        setDevice(devices[0]);
        setVehicle(vehicles[0]);
    }, [vehicles, devices]);
    const [vehicle, setVehicle] = useState(vehicles[0]);
    const [device, setDevice] = useState(devices[0]);
    const vehicleOptions = vehicles.map(vehicle => vehicle.name);
    const deviceOptions = devices.map(device => `${device.name} (${device.uniqueId})`);
    const handleClick = () => {
        if (!!vehicle && !!device) {
            handleBindVehicle(device, vehicle);
        }
    };
    const handleVehicleChange = (value) => {
        const vehicle = vehicles.find(vehicle => vehicle.name === value);
        setVehicle(vehicle);
    };
    const handleDeviceChange = (value) => {
        const device = devices.find(device => `${device.name} (${device.uniqueId})` === value);
        setDevice(device);
    };
    return (React.createElement(StyledBindVehicleScreen, null,
        React.createElement(StyledInputWrapper, null,
            React.createElement(Text, { text: "Select Vehicle" }),
            React.createElement(Spacer, { height: 5 }),
            React.createElement(SelectInput, { options: vehicleOptions, initialValue: vehicleOptions[0], handleChange: handleVehicleChange }),
            React.createElement(Spacer, { height: 15 }),
            React.createElement(Text, { text: "Select Device" }),
            React.createElement(Spacer, { height: 5 }),
            React.createElement(SelectInput, { options: deviceOptions, initialValue: deviceOptions[0], handleChange: handleDeviceChange })),
        React.createElement(StyledButtonContainer, null,
            React.createElement(TextButton, { text: "Link", color: "#FFFFFF", backgroundColor: "#3F51B5", borderRadius: 5, borderWidth: 0, handleClick: handleClick }))));
};
export default BindVehicleScreen;
