import moment from "moment";
import { ApptBookingRequests } from "./appt-booking-requests";
import { ShowStatusMessage } from "./status-message";
export const apptBooking = (orderSelected, bookingConfig) => {
    let containerEl = $("#appt-booking-calendar");
    let datePicker = $(".searchdate");
    let jobCode = $("#appt-booking-job-code option:selected");
    let latitude = $("#appt-booking-lat");
    let longitude = $("#appt-booking-lon");
    let orderNumber = orderSelected[0].split(",")[2];
    const orderId = orderSelected[0].split(",")[0];
    let searchDate = moment(datePicker[0].value, "YYYY-MM-DD");
    let originalColor;
    let timeline = false;
    let calendarSlots = false;
    let apptBookingConf = JSON.parse(bookingConfig);
    let calendarLockEnabled = apptBookingConf ? apptBookingConf.calendar_lock_enabled : false;
    let calendarLockHours = apptBookingConf ? apptBookingConf.calendar_lock_hours : 12;
    containerEl.fullCalendar("destroy");
    containerEl.fullCalendar({
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        themeSystem: "jquery-ui",
        slotEventOverlap: false,
        eventOverlap: false,
        resourceAreaWidth: 150,
        selectable: true,
        selectHelper: true,
        minTime: "00:00:00",
        maxTime: "24:00:00",
        now: searchDate.format("YYYY-MM-DD"),
        editable: false,
        aspectRatio: 1.8,
        scrollTime: "00:00",
        displayEventTime: false,
        height: 590,
        width: 888,
        customButtons: {
            twoHour: {
                text: "2-Hour",
                click: function () {
                    //you code
                }
            },
            fourHour: {
                text: "4-Hour",
                click: function () {
                    //you code
                }
            },
            allDay: {
                text: "All Day",
                click: function () {
                    //you code
                }
            },
            variable: {
                text: "Variable",
                click: function () {
                    //you code
                }
            },
            listWeek: {
                text: "Week View",
                click: function () {
                    $(".fc-today-button").show();
                    $(".fc-right")
                        .find(".fc-button-group")
                        .show();
                    if (timeline)
                        new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl);
                    $("#appt-booking-calendar").fullCalendar("changeView", "listWeek");
                }
            },
            month: {
                text: "Month View",
                click: function () {
                    $(".fc-today-button").show();
                    $(".fc-right")
                        .find(".fc-button-group")
                        .show();
                    if (timeline)
                        new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl);
                    $("#appt-booking-calendar").fullCalendar("changeView", "month");
                }
            },
            agendaWeek: {
                text: "Timeline View",
                click: function () {
                    $(".fc-today-button").show();
                    $(".fc-right")
                        .find(".fc-button-group")
                        .show();
                    if (timeline)
                        new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl);
                    $("#appt-booking-calendar").fullCalendar("changeView", "agendaWeek");
                }
            },
            timelineDay: {
                text: "90-Day View",
                click: function () {
                    timeline = true;
                    $(".fc-today-button").hide();
                    $(".fc-right")
                        .find(".fc-button-group")
                        .hide();
                    $("#appt-booking-calendar").fullCalendar("addResource", {
                        id: searchDate.format("YYYY-MM-DD"),
                        title: searchDate.format("DD (ddd)")
                    });
                    let apptBookingrequests = new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId);
                    let apptBooked = apptBookingrequests.returnApptBookings(containerEl, true, searchDate);
                    apptBooked.then(function () {
                        for (let i = 2; i <= 90; i++) {
                            let date = searchDate.add(1, "days").format("YYYY-MM-DD");
                            $("#appt-booking-calendar").fullCalendar("addResource", {
                                id: date,
                                title: searchDate.format("DD (ddd)")
                            });
                        }
                        $("[data-resource-id] > .ui-widget-content").each(function () {
                            let date = moment($(this)
                                .parent()
                                .attr("data-resource-id"), "YYYY-MM-DD");
                            if (date.format("dddd") == "Saturday" || date.format("dddd") == "Sunday") {
                                $(this).css("background-color", "#CDCDCD");
                            }
                        });
                        searchDate = moment(datePicker[0].value, "YYYY-MM-DD");
                    }, function (error) {
                        console.error(error);
                    });
                    $("#appt-booking-calendar").fullCalendar("changeView", "timelineDay");
                    $(".fc-scroller").scroll(function () {
                        $('tr[data-resource-id*="-05"]').each(function () {
                            if ($(this).visible(false, false, "vertical", "div.ui-dialog")) {
                                let dateAttribute = $(this).attr("data-resource-id");
                                let dateFromAttribute = moment(dateAttribute, "YYYY-MM-DD");
                                $(".fc-center").html("<h2>" + dateFromAttribute.format("MMMM, YYYY") + "</h2>");
                            }
                        });
                    });
                }
            }
        },
        header: {
            left: "month,listWeek,agendaWeek",
            center: "title"
            //right: "twoHour,fourHour,allDay,variable"
        },
        defaultView: "month",
        views: {},
        resourceLabelText: "  ",
        resources: [],
        events: [],
        eventMouseover: function (event, jsEvent, view) {
            originalColor = $(this).css("background-color");
            $(this).css("background-color", "grey");
            $(this).css("cursor", "pointer");
        },
        eventMouseout: function (event, jsEvent, view) {
            $(this).css("background-color", originalColor);
            $(this).css("cursor", "pointer");
        },
        eventClick: function (calEvent, jsEvent, view) {
            let confirmationText = `<div id="confirmationDialog" title="Confirmation">`;
            if (calEvent.className.includes("fc-slot-holiday")) {
                return;
            }
            if (calEvent.color == "black") {
                confirmationText += `<p>Do you confirm?</p></div>`;
                let conDialog = $(confirmationText);
                conDialog.prepend(`<p style="color:red;">WARNING: This booking will be cancelled for slot <b>${calEvent.start.format("YYYY-DD-MM")} ${calEvent.title}</b>.</p>`);
                let appointmentCancel = function () {
                    conDialog.dialog({
                        modal: true,
                        title: "Confirmation",
                        zIndex: 10000,
                        autoOpen: true,
                        width: "auto",
                        resizable: false,
                        buttons: {
                            Yes: function () {
                                $.ajax({
                                    type: "GET",
                                    url: "/sl3/rest/bookings/appointments/get_booking_id/" + orderNumber,
                                    contentType: "application/json",
                                    dataType: "json",
                                    cache: false,
                                    headers: {
                                        "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                                    },
                                    success: function (rsp) {
                                        $.ajax({
                                            type: "POST",
                                            url: "/sl3/rest/bookings/appointments/delete_booking",
                                            data: JSON.stringify({
                                                booking_id: rsp.booking_id,
                                                order_number: orderNumber
                                            }),
                                            contentType: "application/json",
                                            dataType: "json",
                                            cache: false,
                                            headers: {
                                                "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                                            },
                                            success: function (rsp) {
                                                conDialog.dialog("close");
                                                $(".ui-dialog").remove();
                                                new ShowStatusMessage().showStatusMsg("Appointment cancelled successfully", 0, null, null);
                                            },
                                            error: function (rsp) {
                                                if (rsp.responseText.includes("Invalid delete booking request")) {
                                                    conDialog.remove();
                                                    new ShowStatusMessage().showStatusMsg("Invalid delete booking request", 1, null, null);
                                                }
                                            }
                                        });
                                    },
                                    error: function (rsp) {
                                        conDialog.remove();
                                        new ShowStatusMessage().showStatusMsg("Cannot find booking for this order", 1, null, null);
                                    }
                                });
                            },
                            No: function () {
                                conDialog.dialog("close");
                            }
                        },
                        close: function (event, ui) {
                            conDialog.remove();
                        }
                    });
                };
                appointmentCancel();
            }
            else {
                let appointmentLockDate = new Date().getTime();
                if (calendarLockEnabled) {
                    appointmentLockDate += (calendarLockHours * 1000 * 60 * 60);
                }
                if (appointmentLockDate > calEvent.start) {
                    confirmationText += `<p style="color:red;">Unable to book appointment. </p>`;
                    confirmationText += `<p>The selected booking slot
                               <b>${calEvent.start.format("YYYY-MM-DD")} ${calEvent.title}</b>
                               has already started or expired.</p>`;
                    let conDialog = $(confirmationText);
                    conDialog.dialog({
                        modal: true,
                        title: "Warning",
                        zIndex: 10000,
                        autoOpen: true,
                        width: "auto",
                        resizable: false,
                        buttons: {
                            OK: function () {
                                conDialog.dialog("close");
                            }
                        },
                        close: function (event, ui) {
                            conDialog.remove();
                        }
                    });
                }
                else {
                    confirmationText += `<p>This order will be booked for slot <b>${calEvent.start.format("YYYY-MM-DD")} ${calEvent.title}</b>. Do you confirm?</p></div>`;
                    let conDialog = $(confirmationText);
                    fetch("/sl3/rest/bookings/appointments/by_order_number/" + orderNumber, {
                        headers: {
                            "Content-Type": "application/json",
                            "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                        }
                    }).then(rsp => rsp.json())
                        .then(rsp => {
                        if (rsp.error) {
                            //if exception is unchecked, print msg + dont book appt
                            if (!rsp.error.reason.includes("No booking found for order_number")
                                && !rsp.error.reason.includes("Availability does not exist")) {
                                new ShowStatusMessage().showStatusMsg(rsp.error.reason, 1);
                                return;
                            }
                        }
                        else {
                            conDialog.prepend('<p style="color:red;">WARNING: This order is already booked.</p>');
                        }
                        appointmentBook();
                    });
                    let appointmentBook = function () {
                        conDialog.dialog({
                            modal: true,
                            title: "Confirmation",
                            zIndex: 10000,
                            autoOpen: true,
                            width: "auto",
                            resizable: false,
                            buttons: {
                                Yes: function () {
                                    if (calEvent.objId == 0)
                                        //TODO: just for the sake of demoing
                                        return;
                                    $.ajax({
                                        type: "POST",
                                        url: "/sl3/rest/bookings/appointments/book_order",
                                        data: JSON.stringify({
                                            availability_id: calEvent.objId,
                                            order_data: { job_code: jobCode.text(), order_id: orderId },
                                            force_booking: false
                                        }),
                                        contentType: "application/json",
                                        dataType: "json",
                                        cache: false,
                                        headers: {
                                            "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                                        },
                                        success: function (rsp) {
                                            $.ajax({
                                                type: "POST",
                                                url: "/sl3/rest/bookings/appointments/link_booking",
                                                data: JSON.stringify({
                                                    booking_id: rsp.booking_id,
                                                    order_number: orderNumber,
                                                    delete_existing: true
                                                }),
                                                contentType: "application/json",
                                                dataType: "json",
                                                cache: false,
                                                headers: {
                                                    "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                                                },
                                                success: function (rsp) {
                                                    conDialog.dialog("close");
                                                    $(".ui-dialog").remove();
                                                    new ShowStatusMessage().showStatusMsg("Appointment booked successfully", 0, null, null);
                                                },
                                                error: function (rsp) {
                                                    conDialog.dialog("close");
                                                    console.log("error");
                                                }
                                            });
                                        },
                                        error: function (rsp) {
                                            if (rsp.responseText.includes("Can't book an order in the past")) {
                                                conDialog.remove();
                                                new ShowStatusMessage().showStatusMsg("Cannot book an order in the past", 1, null, null);
                                            }
                                            else if (rsp.responseText.includes("Max count:")) {
                                                conDialog.remove();
                                                new ShowStatusMessage().showStatusMsg("Maximum number of bookings exceeded", 1, null, null);
                                            }
                                        }
                                    });
                                },
                                No: function () {
                                    conDialog.dialog("close");
                                }
                            },
                            close: function (event, ui) {
                                conDialog.remove();
                            }
                        });
                    };
                }
            }
        },
        eventRender: function (calEvent, elmt) {
            if (calEvent.slotId != 0) {
                calendarSlots = true;
                const isFilteredSlot = ["all", calEvent.name].includes($(".cal_filter_selector").val());
                return isFilteredSlot || calEvent.className.includes("fc-slot-holiday");
            }
            else {
                calendarSlots = false;
                return true;
            }
        }
    });
    $("#appt-booking-calendar")
        .find(".fc-today-button,.fc-next-button,.fc-prev-button")
        .off("click.custom")
        .on("click.custom", function () {
        apptBookingrequests.returnApptBookings(containerEl);
    });
    new ShowStatusMessage().showStatusMsg("Loading slots, please wait...", 2, null, null);
    $.ajax({
        type: "GET",
        url: "/sl3/rest/bookings/slots",
        contentType: "application/json",
        dataType: "json",
        cache: false,
        headers: {
            "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
        },
        success: function (rspSlots) {
            let selector = '<select class="cal_filter_selector"><option value="all">Select Slot/Group</option>';
            $.ajax({
                type: "GET",
                url: "/sl3/rest/bookings/slotgroups",
                contentType: "application/json",
                dataType: "json",
                cache: false,
                headers: {
                    "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                },
                success: function (rspSlotGroups) {
                    let slotGroupDict = [];
                    for (let index in rspSlots) {
                        let currentSlot = rspSlots[index];
                        let timeStart = (currentSlot.hour_start <= 9 ? "0" + currentSlot.hour_start : currentSlot.hour_start) +
                            ":" +
                            (currentSlot.min_start === 0 ? "00" : currentSlot.min_start);
                        let timeEnd = (currentSlot.hour_end <= 9 ? "0" + currentSlot.hour_end : currentSlot.hour_end) +
                            ":" +
                            (currentSlot.min_end === 0 ? "00" : currentSlot.min_end);
                        selector += `<option value=${rspSlots[index].name} data-slot-time-start=${timeStart} data-slot-time-end=${timeEnd}>${rspSlots[index].name}</option>`;
                        if (currentSlot.slot_group != null) {
                            if (slotGroupDict[currentSlot.slot_group.name] == undefined)
                                slotGroupDict[currentSlot.slot_group.name] = [];
                            slotGroupDict[currentSlot.slot_group.name].push(timeStart + "-" + timeEnd);
                        }
                    }
                    if (!calendarSlots) {
                        for (let index in rspSlotGroups) {
                            selector += `<option value="all" data-slot-group=true data-time-slots=${slotGroupDict[rspSlotGroups[index].name]}>${rspSlotGroups[index].name}</option>`;
                        }
                    }
                    selector += "</select>";
                    $(".fc-right").append(selector);
                    $(".cal_filter_selector").on("change", function () {
                        if ($(".cal_filter_selector")
                            .find(":selected")
                            .data("slot-group")) {
                            let slotArray = [];
                            let timeSlots = $(".cal_filter_selector")
                                .find(":selected")
                                .data("time-slots");
                            for (let timeSlot of timeSlots.split(",")) {
                                let slot = { start_time: timeSlot.split("-")[0], end_time: timeSlot.split("-")[1] };
                                slotArray.push(slot);
                            }
                            new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl, false, null, slotArray, $(".cal_filter_selector")
                                .find(":selected")
                                .text());
                        }
                        else if ($(".cal_filter_selector")
                            .find(":selected")
                            .data("slot-time-start") != undefined) {
                            let startTime = $(".cal_filter_selector")
                                .find(":selected")
                                .data("slot-time-start");
                            let endTime = $(".cal_filter_selector")
                                .find(":selected")
                                .data("slot-time-end");
                            let startEndTime = [{ start_time: startTime, end_time: endTime }];
                            new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl, false, null, startEndTime);
                        }
                        else {
                            new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId).returnApptBookings(containerEl);
                        }
                        $("#appt-booking-calendar").fullCalendar("rerenderEvents");
                    });
                    $("#appt-booking-calendar").fullCalendar("rerenderEvents");
                    window.clearStatusMsg();
                    new ShowStatusMessage().showStatusMsg("Slots loaded", 0, null, null);
                },
                error: function (e) {
                    console.error(e);
                    $("#appt-booking-calendar").fullCalendar("rerenderEvents");
                    new ShowStatusMessage().showStatusMsg("Failed to load slot groups", 1, null, null);
                }
            });
        },
        error: function (e) {
            new ShowStatusMessage().showStatusMsg("Failed to load slots", 1, null, null);
        }
    });
    let apptBookingrequests = new ApptBookingRequests(datePicker[0].value, jobCode.text(), longitude.val(), latitude.val(), orderNumber, orderId);
    apptBookingrequests.returnApptBookings(containerEl);
};
