import React from "react";
import styled from "styled-components";
import { getTheme, SelectionMode, Selection, MarqueeSelection, SelectionZone } from "office-ui-fabric-react";
import SimpleListRow from "./SimpleListRow/SimpleListRow";
const StyledSimpleList = styled.div `
  width: ${props => `calc(${props.width} - 0.2rem)`};
  height: ${props => `calc(${props.height} - 0.2rem)`};
  background-color: ${props => props.backgroundColor || props.palette.white};
  border-style: solid;
  border-width: 0.1rem;
  border-color: ${props => props.borderColor || props.palette.black};
  overflow-y: scroll;
  overflow-x: hidden;
`;
class SimpleList extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleSelectionChange = () => {
            const selected = this.state.selection.getSelection();
            if (this.props.handleSelectionChange) {
                this.props.handleSelectionChange(selected);
            }
        };
        this.handleInvoke = () => {
            const selected = this.state.selection.getSelection();
            if (this.props.handleInvoke) {
                this.props.handleInvoke(selected);
            }
        };
        this.onSelectionChanged = () => {
            if (this.hasMounted) {
                this.forceUpdate();
                this.handleSelectionChange();
            }
        };
        this.hasMounted = false;
        this.state = {
            rows: this.props.rows,
            selection: new Selection({ onSelectionChanged: this.onSelectionChanged }),
            selectionMode: SelectionMode.multiple
        };
        this.state.selection.setItems(this.state.rows, false);
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.rows.length !== nextProps.rows.length) {
            return true;
        }
        if (this.state.rows.length !== nextState.rows.length) {
            return true;
        }
        for (let index = 0; index < this.props.rows.length; index++) {
            if (!nextProps.rows[index] ||
                !this.props.rows[index] ||
                nextProps.rows[index].key !== this.props.rows[index].key) {
                return true;
            }
        }
        for (let index = 0; index < this.state.rows.length; index++) {
            if (!nextState.rows[index] ||
                !this.state.rows[index] ||
                nextState.rows[index].key !== this.state.rows[index].key) {
                return true;
            }
        }
        return false;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let shouldSetState = false;
        if (prevState.rows.length !== this.props.rows.length) {
            shouldSetState = true;
        }
        for (let index = 0; index < this.props.rows.length; index++) {
            if (!prevState.rows[index] ||
                !this.props.rows[index] ||
                prevState.rows[index].key !== this.props.rows[index].key) {
                shouldSetState = true;
                break;
            }
        }
        const performClearSelect = !!this.props.performClearSelect;
        if (shouldSetState) {
            if (performClearSelect) {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { rows: this.props.rows })));
                this.state.selection.setItems(this.state.rows, false);
                this.state.selection.setAllSelected(false);
                this.props.handleFinishClearSelect();
            }
            else {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { rows: this.props.rows })));
                this.state.selection.setItems(this.state.rows, false);
            }
        }
    }
    componentDidMount() {
        this.hasMounted = true;
    }
    render() {
        const { height, rowHeight, width, borderColor, backgroundColor, selectColor, fontColor } = this.props;
        const { rows, selection } = this.state;
        return (React.createElement(StyledSimpleList, Object.assign({ className: "simple-list" }, this.props, this.state, this.theme),
            React.createElement(MarqueeSelection, { selection: selection, isEnabled: true },
                React.createElement(SelectionZone, { selection: selection, onItemInvoked: this.handleInvoke }, rows.map((row, index) => (React.createElement(SimpleListRow, { key: index, index: index, displayedText: row.displayedText, height: rowHeight, width: width, backgroundColor: backgroundColor, selectColor: selectColor, fontColor: fontColor, selection: selection })))))));
    }
}
export default SimpleList;
