import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleButton from "../../../../display/components/SimpleButton/SimpleButton";
const StyledMessageResenderSendButton = styled.div `
  float: right;
`;
class MessageResenderSendButton extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleSendMessage = () => {
            this.props.handleSendMessage();
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderSendButton, null,
            React.createElement(SimpleButton, { className: "message-resender-send-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                    width: "16rem",
                    height: "1.2rem",
                    iconText: "Resend Selected Messages",
                    textAlign: "center",
                    fontWeight: "bold"
                }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.handleSendMessage })));
    }
}
export default MessageResenderSendButton;
