import Style from "ol/style/style";
import Icon from "ol/style/icon";
import Text from "ol/style/text";
import Stroke from "ol/style/stroke";
import MultiLineString from "ol/geom/multilinestring";
import Point from "ol/geom/point";
import GPX from "ol/format/gpx";
import VectorSource from "ol/source/vector";
import VectorLayer from "ol/layer/vector";
import chroma from "chroma-js";
import * as Rx from "rxjs";
import { VectorGroupLayer } from "../util/VectorGroupLayer";
export class FsrRouting {
    static SLGpxFeatureToStyle(feature) {
        if (feature.getGeometry() instanceof MultiLineString) {
            return new Style({
                stroke: new Stroke({
                    color: chroma("#800080")
                        .alpha(0.7)
                        .rgba(),
                    width: 4
                })
            });
        }
        else if (feature.getGeometry() instanceof Point) {
            let name = feature.get("name");
            if (FsrRouting.END_NAME.test(name))
                return FsrRouting.simpleIconStyle("/sl3/images/marker-route-finish.png");
            else if (FsrRouting.WAYPOINT_NAME.test(name)) {
                let style = FsrRouting.simpleIconStyle("/sl3/images/marker-route-waypoint.png");
                let match = name.match(/\d+/);
                return match ? FsrRouting.addNumberText(style, match[0]) : style;
            }
            else
                return new Style({
                    image: new Icon({
                        src: "/sl3/images/marker-route-start.png",
                        anchor: [0.3, 1.0]
                    })
                });
        }
        return null;
    }
    static RetrieveGpxAtUrl(url) {
        let source = new VectorSource({
            url: url,
            format: new GPX({})
        });
        let layer = new VectorLayer({
            source: source,
            style: FsrRouting.SLGpxFeatureToStyle
        });
        let color = "#800080";
        let sourceLoaded = Rx.Observable.fromEvent(source, "change")
            .filter((event) => event.target.getState() === "ready")
            .first()
            .map((event) => event.target)
            .toPromise();
        // @ts-ignore
        let vectorGroupLayer = new VectorGroupLayer({ source: source, layers: [layer] });
        return { routeLayer: vectorGroupLayer, color, featuresLoaded: sourceLoaded };
    }
    static simpleIconStyle(url) {
        return new Style({
            image: new Icon({
                src: url,
                anchor: [0.5, 1.0]
            })
        });
    }
    static addNumberText(style, number) {
        style.setText(new Text({ text: `${number}`, offsetY: -28 }));
        return style;
    }
}
FsrRouting.END_NAME = /finish/i;
FsrRouting.WAYPOINT_NAME = /stopover/i;
FsrRouting.TRACK_NAME = /track/i;
