import { InventoryTableContainerActionTypes } from "./types";
export const initialState = {
    tableInitialized: false,
    tableJFilterObject: {},
    selectedIds: [],
    tableHeaderRowData: [],
    tableHeaderRowProps: {
        height: 0
    },
    tableRowVerticalScrollDataProps: {
        bufferRowsCount: 0,
        scrollThrottleTime: 0,
        verticalScroll: {
            start: 0,
            end: 0,
            topSpacerRowsCount: 0,
            displayedRowsCount: 0
        }
    },
    tableRowWrapperDataProps: {
        totalRowsCount: 0,
        tableRowsData: []
    }
};
export function inventoryTableContainerReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryTableContainerActionTypes.SET_INVENTORY_TABLE_CONTAINER_STATE: {
            return action.inventoryTableContainerState;
        }
        default:
            return state;
    }
}
