import React, { useState } from "react";
import styled from "styled-components";
import Collapse from '@material-ui/core/Collapse';
import FilterRowTitle from "./FilterRowTitle/FilterRowTitle";
export const StyledFilterRowWrapper = styled.div `
  width: 100%;
  height: max-content;
  overflow: visible;
`;
const FilterRowWrapper = (props) => {
    const { filterName, initialExpand, handleClearFilter, } = props;
    const [isExpanded, setIsExpanded] = useState(initialExpand);
    const handleClick = () => {
        setIsExpanded(prevState => {
            return !prevState;
        });
    };
    return (React.createElement(StyledFilterRowWrapper, null,
        React.createElement(FilterRowTitle, { filterName: filterName, isExpanded: isExpanded, handleClick: handleClick, handleClearFilter: handleClearFilter }),
        React.createElement(Collapse, { in: isExpanded }, props.children)));
};
export default FilterRowWrapper;
