import React from "react";
import styled from "styled-components";
import ErrorMessageDetailsOrderRow from "./ErrorMessageDetailsOrderRow/ErrorMessageDetailsOrderRow";
const StyledErrorMessageDetailsOrders = styled.div `
  width: calc(15% - 10px);
  max-width: calc(15% - 10px);
  height: 100%;
  min-width: 120px;
  padding: 5px;
  float: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
`;
class ErrorMessageDetailsOrders extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledErrorMessageDetailsOrders, { className: "error-message-details-orders-container" },
            React.createElement("label", { style: { marginBottom: "1rem" } }, "Order IDs:"),
            this.props.orderIds.map(orderId => {
                return (React.createElement(ErrorMessageDetailsOrderRow, { orderId: orderId, handleOrderRowClick: this.props.handleOrderRowClick }, { orderId }));
            })));
    }
}
export default ErrorMessageDetailsOrders;
