import React from "react";
import { TreeItem } from "@material-ui/lab";
export const renderTreeItems = (node) => {
    function recurseDocTree(tree) {
        var _a;
        const data = tree.dataObj;
        return React.createElement(TreeItem, { key: data.id.toString(), nodeId: data.id.toString(), label: data.displayName },
            React.createElement(React.Fragment, null, (_a = tree.children) === null || _a === void 0 ? void 0 : _a.map(recurseDocTree)));
    }
    ;
    return recurseDocTree(node);
};
export function getFileExtension(pageName) {
    const fileExtension = pageName.split('.').pop();
    return fileExtension;
}
export function createJsonTree(node) {
    const { displayName, pageName } = node.dataObj;
    if (node.children)
        return { displayName, pageName, children: node.children.map(createJsonTree) };
    else
        return { displayName, pageName };
}
export function createLinkedArrayWithIds(node) {
    let maxId = 0;
    function getId() {
        return ++maxId;
    }
    function helper(node, parentId) {
        var _a;
        const { displayName, pageName } = node;
        const id = getId();
        return !node.children ? [{ displayName, pageName, id, parentId }] : [{ displayName, pageName, id, parentId }, ...(_a = node.children) === null || _a === void 0 ? void 0 : _a.flatMap(x => helper(x, id))];
        //return [{displayName, pageName, id, parentId}, ...node.children?.flatMap(x => helper(x, id))]
    }
    return { nodes: helper(node, undefined), maxId };
}
export function nodeIdToPath(tree, nodeId) {
    const nodeIdInt = parseInt(nodeId);
    const foundNode = tree.getNodeById(nodeIdInt);
    const ancestors = foundNode === null || foundNode === void 0 ? void 0 : foundNode.getAncestors();
    const newVar = [...foundNode ? [foundNode] : [], ...(ancestors || [])];
    return newVar.reverse();
}
