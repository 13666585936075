import React from "react";
import styled from "styled-components";
import TableHeaderRow from "./TableHeaderRow/TableHeaderRow";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
const StyledTableHeaderRowWrapper = styled.div `
  width: max-content;
  height: ${props => StyleUtils.getCssPixelString(props.tableHeaderRowProps.height)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class TableHeaderRowWrapper extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledTableHeaderRowWrapper, Object.assign({ className: "table-header-row-wrapper" }, this.props),
            React.createElement(TableHeaderRow, Object.assign({}, this.props))));
    }
}
export default TableHeaderRowWrapper;
