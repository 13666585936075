import React from "react";
import ResizablePanelItem from "./ResizablePanelItem/ResizablePanelItem";
import ReactResizeDetector from "react-resize-detector";
import { controlBarWidth } from "./ResizablePanelControlBar/ResizablePanelControlBar";
import ResizablePanelItemWrapper from "./ResizablePanelItemWrapper/ResizablePanelItemWrapper";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ResizablePanelRef from "./ResizablePanelRef/ResizablePanelRef";
import { Utils } from "../../../helper/General/Utils";
class ResizablePanel extends React.Component {
    constructor(props) {
        super(props);
        this.onParentResize = (width, height) => {
            this.setResizableWrapperGeometry();
        };
        this.getInitialOrder = () => {
            const childrenCount = React.Children.count(this.props.children);
            const order = [];
            for (let i = 0; i < childrenCount; i++) {
                order.push(i);
            }
            return order;
        };
        this.getInitialItemStates = (parentHeight, parentWidth) => {
            const itemStates = {};
            const items = React.Children.map(this.props.children, (item) => item);
            const itemLengths = [];
            let totalLength = 0;
            let itemWithConfigCount = 0;
            items.forEach(item => {
                if (item.type === ResizablePanelItem) {
                    const length = item.props.length;
                    itemLengths.push(length);
                    totalLength = totalLength + length;
                    itemWithConfigCount++;
                }
                else {
                    itemLengths.push(null);
                }
            });
            const totalItemCount = items.length;
            const itemWithoutConfigCount = totalItemCount - itemWithConfigCount;
            const horizontal = !!this.props.horizontal;
            const parentLength = horizontal ? parentWidth : parentHeight;
            if (itemWithConfigCount > 0) {
                if (itemWithConfigCount === totalItemCount) {
                    let index = 0;
                    itemLengths.forEach(itemLength => {
                        const scaledItemLength = (itemLength / totalLength) * parentLength;
                        itemStates[index] = {
                            hidden: false,
                            itemHeight: horizontal ? parentHeight : scaledItemLength,
                            itemWidth: horizontal ? scaledItemLength : parentWidth,
                            color: items[index].props.color,
                            name: items[index].props.name
                        };
                        index++;
                    });
                }
                else {
                    if (totalLength > parentLength) {
                        const leftOverTotalLength = parentLength * (itemWithoutConfigCount / totalItemCount);
                        const itemWithoutConfigLength = leftOverTotalLength / itemWithoutConfigCount;
                        const itemWithConfigScaleRatio = (parentLength - leftOverTotalLength) / totalLength;
                        let index = 0;
                        itemLengths.forEach(itemLength => {
                            if (itemLength === null) {
                                itemStates[index] = {
                                    hidden: false,
                                    itemHeight: horizontal ? parentHeight : itemWithoutConfigLength,
                                    itemWidth: horizontal ? itemWithoutConfigLength : parentWidth,
                                    color: items[index].props.color,
                                    name: items[index].props.name
                                };
                            }
                            else {
                                const scaledItemLength = itemWithConfigScaleRatio * itemLength;
                                itemStates[index] = {
                                    hidden: false,
                                    itemHeight: horizontal ? parentHeight : scaledItemLength,
                                    itemWidth: horizontal ? scaledItemLength : parentWidth,
                                    color: items[index].props.color,
                                    name: items[index].props.name
                                };
                            }
                            index++;
                        });
                    }
                    else {
                        const leftOverTotalLength = parentLength - totalLength;
                        const itemWithoutConfigLength = leftOverTotalLength / itemWithoutConfigCount;
                        let index = 0;
                        itemLengths.forEach(itemLength => {
                            if (itemLength === null) {
                                itemStates[index] = {
                                    hidden: false,
                                    itemHeight: horizontal ? parentHeight : itemWithoutConfigLength,
                                    itemWidth: horizontal ? itemWithoutConfigLength : parentWidth,
                                    color: items[index].props.color,
                                    name: items[index].props.name
                                };
                            }
                            else {
                                itemStates[index] = {
                                    hidden: false,
                                    itemHeight: horizontal ? parentHeight : itemLength,
                                    itemWidth: horizontal ? itemLength : parentWidth,
                                    color: items[index].props.color,
                                    name: items[index].props.name
                                };
                            }
                            index++;
                        });
                    }
                }
            }
            else {
                const defaultItemLength = parentLength / totalItemCount;
                let index = 0;
                itemLengths.forEach(itemLength => {
                    itemStates[index] = {
                        hidden: false,
                        itemHeight: horizontal ? parentHeight : defaultItemLength,
                        itemWidth: horizontal ? defaultItemLength : parentWidth,
                        color: items[index].props.color,
                        name: items[index].props.name
                    };
                    index++;
                });
            }
            return itemStates;
        };
        this.parentGeometryChangeUpdateItemStates = (prevState, parentHeight, parentWidth) => {
            const prevItemStates = prevState.itemStates;
            const prevHeight = prevState.parentGeometry.parentHeight;
            const prevWidth = prevState.parentGeometry.parentWidth;
            const changeHeight = parentHeight - prevHeight;
            const changeWidth = parentWidth - prevWidth;
            let totalHeight = 0;
            let totalWidth = 0;
            let totalCount = 0;
            let hiddenCount = 0;
            Object.entries(prevState.itemStates).forEach(([index, itemState]) => {
                totalCount++;
                if (itemState.hidden) {
                    hiddenCount++;
                }
                else {
                    totalHeight = totalHeight + itemState.itemHeight;
                    totalWidth = totalWidth + itemState.itemWidth;
                }
            });
            const horizontal = !!this.props.horizontal;
            const heightScaleRatio = horizontal
                ? parentHeight / prevHeight
                : (totalHeight + changeHeight) / totalHeight;
            const widthScaleRatio = horizontal ? (totalWidth + changeWidth) / totalWidth : parentWidth / prevWidth;
            const newItemStates = {};
            Object.entries(prevItemStates).forEach(([index, prevItemState]) => {
                if (prevItemState.hidden) {
                    newItemStates[index] = {
                        hidden: prevItemState.hidden,
                        itemHeight: horizontal ? prevItemState.itemHeight * heightScaleRatio : prevItemState.itemHeight,
                        itemWidth: horizontal ? prevItemState.itemWidth : prevItemState.itemWidth * widthScaleRatio,
                        color: prevItemState.color,
                        name: prevItemState.name
                    };
                }
                else {
                    newItemStates[index] = {
                        hidden: prevItemState.hidden,
                        itemHeight: prevItemState.itemHeight * heightScaleRatio,
                        itemWidth: prevItemState.itemWidth * widthScaleRatio,
                        color: prevItemState.color,
                        name: prevItemState.name
                    };
                }
            });
            return newItemStates;
        };
        this.handleToggleHide = (event) => {
            const clickedIndex = event.index;
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { itemStates: this.toggleHideUpdateItemStates(prevState, clickedIndex) })));
        };
        this.toggleHideUpdateItemStates = (prevState, clickedIndex) => {
            const prevParentGeometry = prevState.parentGeometry;
            const { parentHeight, parentWidth } = prevParentGeometry;
            const orderedClickedIndex = prevState.order[clickedIndex];
            const prevClickedItemState = prevState.itemStates[orderedClickedIndex];
            const { horizontal } = this.props;
            const parentLength = horizontal ? parentWidth : parentHeight;
            const newHidden = prevClickedItemState ? !prevClickedItemState.hidden : false;
            const itemLength = horizontal ? prevClickedItemState.itemWidth : prevClickedItemState.itemHeight;
            if (newHidden) {
                const newItemStateObject = {};
                let totalLength = 0;
                let totalCount = 0;
                let hiddenCount = 0;
                Object.entries(prevState.itemStates).forEach(([index, itemState]) => {
                    totalCount++;
                    if (itemState.hidden) {
                        hiddenCount++;
                    }
                    else {
                        const itemLength = horizontal ? itemState.itemWidth : itemState.itemHeight;
                        totalLength = totalLength + itemLength;
                    }
                });
                const scaleRatio = (totalLength - controlBarWidth) / (totalLength - itemLength);
                Object.entries(prevState.itemStates).forEach(([index, itemState]) => {
                    if (parseInt(index) === orderedClickedIndex) {
                        const newItemState = Object.assign(Object.assign({}, itemState), { hidden: newHidden, itemHeight: horizontal ? itemState.itemHeight : controlBarWidth, itemWidth: horizontal ? controlBarWidth : itemState.itemWidth });
                        newItemStateObject[index] = newItemState;
                    }
                    else {
                        const newItemState = Object.assign(Object.assign({}, itemState), { itemHeight: horizontal
                                ? itemState.itemHeight
                                : itemState.hidden
                                    ? controlBarWidth
                                    : itemState.itemHeight * scaleRatio, itemWidth: horizontal
                                ? itemState.hidden
                                    ? controlBarWidth
                                    : itemState.itemWidth * scaleRatio
                                : itemState.itemWidth });
                        newItemStateObject[index] = newItemState;
                    }
                });
                return newItemStateObject;
            }
            else {
                const newItemStateObject = {};
                let totalLength = 0;
                let totalCount = 0;
                let hiddenCount = 0;
                Object.entries(prevState.itemStates).forEach(([index, itemState]) => {
                    totalCount++;
                    if (itemState.hidden) {
                        hiddenCount++;
                    }
                    else {
                        const itemLength = horizontal ? itemState.itemWidth : itemState.itemHeight;
                        totalLength = totalLength + itemLength;
                    }
                });
                const targetLength = parentLength - (hiddenCount - 1) * controlBarWidth;
                const targetItemLength = targetLength / (totalCount - hiddenCount + 1);
                const scaleRatio = (totalLength + controlBarWidth - targetItemLength) / totalLength;
                const allHidden = hiddenCount === totalCount;
                const allHiddenTargetItemLength = parentLength - (hiddenCount - 1) * controlBarWidth;
                Object.entries(prevState.itemStates).forEach(([index, itemState]) => {
                    if (parseInt(index) === orderedClickedIndex) {
                        const newItemState = Object.assign(Object.assign({}, itemState), { hidden: newHidden, itemHeight: horizontal ? itemState.itemHeight : allHidden ? allHiddenTargetItemLength : targetItemLength, itemWidth: horizontal ? (allHidden ? allHiddenTargetItemLength : targetItemLength) : itemState.itemWidth });
                        newItemStateObject[index] = newItemState;
                    }
                    else {
                        const newItemState = Object.assign(Object.assign({}, itemState), { itemHeight: horizontal
                                ? itemState.itemHeight
                                : itemState.hidden
                                    ? controlBarWidth
                                    : itemState.itemHeight * scaleRatio, itemWidth: horizontal
                                ? itemState.hidden
                                    ? controlBarWidth
                                    : itemState.itemWidth * scaleRatio
                                : itemState.itemWidth });
                        newItemStateObject[index] = newItemState;
                    }
                });
                return newItemStateObject;
            }
        };
        this.handleReorder = (result, provided) => {
            const source = result.source.index;
            const destination = result.destination.index;
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { order: this.reorderUpdateOrder(prevState.order, source, destination) })));
        };
        this.reorderUpdateOrder = (prevOrder, source, destination) => {
            const totalCount = Object.keys(prevOrder).length || 0;
            if (totalCount === 0) {
                return prevOrder;
            }
            if (source === destination) {
                return prevOrder;
            }
            else {
                return Utils.moveArrayElements(prevOrder, source, destination);
            }
        };
        this.setResizableWrapperGeometry = () => {
            const boundingDimensions = this.parentElement.getBoundingClientRect();
            if (boundingDimensions) {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { parentGeometry: Object.assign(Object.assign({}, prevState.parentGeometry), { parentTop: boundingDimensions.top, parentHeight: boundingDimensions.height, parentLeft: boundingDimensions.left, parentWidth: boundingDimensions.width }), order: Object.entries(prevState.order).length === 0 ? this.getInitialOrder() : prevState.order, itemStates: Object.entries(prevState.order).length === 0 ||
                        prevState.parentGeometry.parentWidth === 0 ||
                        prevState.parentGeometry.parentHeight === 0
                        ? this.getInitialItemStates(boundingDimensions.height, boundingDimensions.width)
                        : this.parentGeometryChangeUpdateItemStates(prevState, boundingDimensions.height, boundingDimensions.width) })));
            }
        };
        this.handleResize = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { itemStates: this.resizeUpdateItemStates(prevState, event.index, event.change) })));
        };
        this.resizeUpdateItemStates = (prevState, eventIndex, change) => {
            const horizontal = !!this.props.horizontal;
            const order = prevState.order;
            const itemStates = prevState.itemStates;
            const itemCount = prevState.order.length;
            const parentGeometry = prevState.parentGeometry;
            let beforeLength = 0;
            const newItemStates = {};
            let itemLength = 0;
            for (let index = 0; index < itemCount; index++) {
                const orderIndex = order[index];
                const itemState = itemStates[orderIndex];
                if (index < eventIndex) {
                    beforeLength = beforeLength + (horizontal ? itemState.itemWidth : itemState.itemHeight);
                    newItemStates[orderIndex] = Object.assign({}, itemState);
                }
                else if (index === eventIndex) {
                    itemLength = horizontal ? itemState.itemWidth : itemState.itemHeight;
                }
            }
            const totalLength = horizontal ? parentGeometry.parentWidth : parentGeometry.parentHeight;
            const afterLength = totalLength - beforeLength - itemLength;
            const newItemLength = itemLength + change;
            const afterLengthRatio = (afterLength - change) / afterLength;
            for (let index = eventIndex; index < itemCount; index++) {
                const orderIndex = order[index];
                const itemState = itemStates[orderIndex];
                if (index === eventIndex) {
                    newItemStates[orderIndex] = Object.assign(Object.assign({}, itemState), { itemHeight: horizontal ? itemState.itemHeight : newItemLength, itemWidth: horizontal ? newItemLength : itemState.itemWidth });
                }
                else {
                    newItemStates[orderIndex] = Object.assign(Object.assign({}, itemState), { itemHeight: horizontal ? itemState.itemHeight : itemState.itemHeight * afterLengthRatio, itemWidth: horizontal ? itemState.itemWidth * afterLengthRatio : itemState.itemWidth });
                }
            }
            return newItemStates;
        };
        this.getPanels = () => {
            const { parentGeometry, order, itemStates } = this.state;
            const horizontal = !!this.props.horizontal;
            const itemCount = React.Children.count(this.props.children);
            const renderedItems = [];
            const items = React.Children.map(this.props.children, (item) => item);
            const lastIndex = itemCount - 1;
            let lengthPosition = 0;
            for (let index = 0; index < itemCount; index++) {
                const orderIndex = order[index] === undefined ? index : order[index];
                const item = items[orderIndex];
                const itemState = itemStates[orderIndex];
                const itemLength = itemState ? (horizontal ? itemState.itemWidth : itemState.itemHeight) : 0;
                lengthPosition = lengthPosition + itemLength;
                if (!item) {
                    renderedItems.push(React.createElement(ResizablePanelItemWrapper, { horizontal: horizontal, key: index, index: index, orderIndex: orderIndex, lastIndex: lastIndex, lengthPosition: lengthPosition, itemState: itemState, parentGeometry: parentGeometry, toggleHideHandler: this.handleToggleHide, resizeHandler: this.handleResize, children: null }));
                }
                else {
                    if (item.type === ResizablePanelItem) {
                        renderedItems.push(React.createElement(ResizablePanelItemWrapper, { horizontal: horizontal, key: index, index: index, orderIndex: orderIndex, lastIndex: lastIndex, lengthPosition: lengthPosition, itemState: itemState, parentGeometry: parentGeometry, toggleHideHandler: this.handleToggleHide, resizeHandler: this.handleResize, children: item.props.children }));
                    }
                    else {
                        renderedItems.push(React.createElement(ResizablePanelItemWrapper, { horizontal: horizontal, key: index, index: index, orderIndex: orderIndex, lastIndex: lastIndex, lengthPosition: lengthPosition, itemState: itemState, parentGeometry: parentGeometry, toggleHideHandler: this.handleToggleHide, resizeHandler: this.handleResize, children: item }));
                    }
                }
            }
            return renderedItems;
        };
        this.resizablePanel = React.createRef();
        this.state = {
            parentGeometry: {
                parentTop: 0,
                parentHeight: 0,
                parentLeft: 0,
                parentWidth: 0
            },
            order: [],
            itemStates: {}
        };
    }
    componentDidMount() {
        this.parentElement = this.resizablePanel.current.parentElement;
        this.setResizableWrapperGeometry();
    }
    render() {
        const horizontal = !!this.props.horizontal;
        const { parentTop, parentHeight, parentLeft, parentWidth } = this.state.parentGeometry;
        const resizablePanelWrapperStyle = {
            height: StyleUtils.getCssPixelString(parentHeight),
            width: StyleUtils.getCssPixelString(parentWidth),
            display: "flex",
            flexDirection: horizontal ? "row" : "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            overflow: "hidden"
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(DragDropContext, { onDragEnd: this.handleReorder },
                React.createElement(Droppable, { droppableId: "droppable", direction: horizontal ? "horizontal" : "vertical" }, (provided, snapshot) => (React.createElement(ResizablePanelRef, { provided: provided, style: { height: "100%", width: "100%" } },
                    React.createElement("div", { className: "resizable-panel-wrapper", style: resizablePanelWrapperStyle, ref: this.resizablePanel }, this.getPanels()),
                    provided.placeholder)))),
            React.createElement(ReactResizeDetector, { handleWidth: true, handleHeight: true, onResize: this.onParentResize })));
    }
}
ResizablePanel.Item = ResizablePanelItem;
export default ResizablePanel;
