import React from "react";
import "./ContextMenu.css";
import { getTheme, Callout, DirectionalHint } from "office-ui-fabric-react";
import ContextMenuOption from "./ContextMenuOption/ContextMenuOption";
import styled from "styled-components";
import ContextMenuSeparator from "./ContextMenuSeparator/ContextMenuSeparator";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
export var CONTEXT_MENU_COSTANTS;
(function (CONTEXT_MENU_COSTANTS) {
    CONTEXT_MENU_COSTANTS["CONTEXT_MENU_SEPARATOR_STRING"] = "*context_menu_separator*";
})(CONTEXT_MENU_COSTANTS || (CONTEXT_MENU_COSTANTS = {}));
const ContextMenuAnchor = styled.div `
  height: 0;
  width: 0;
  position: absolute;
  top: ${props => StyleUtils.getCssPixelString(props.yPosition)};
  left: ${props => StyleUtils.getCssPixelString(props.xPosition)};
`;
const StyledContextMenu = styled.div `
  height: max-content;
  width: max-content;
  background-color: ${props => props.palette.white};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
class ContextMenu extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.contextMenuAnchor = React.createRef();
    }
    render() {
        const { isVisible, offset, data, separatorOptions, contextMenuActions } = this.props;
        if (!isVisible) {
            return (React.createElement(ContextMenuAnchor, Object.assign({ className: "context-menu-anchor", ref: this.contextMenuAnchor }, this.props, this.theme)));
        }
        else {
            return (React.createElement(ContextMenuAnchor, Object.assign({ className: "context-menu-anchor", ref: this.contextMenuAnchor }, this.props, this.theme),
                React.createElement(Callout, { target: this.contextMenuAnchor.current, className: "context-menu-callout", gapSpace: offset, isBeakVisible: false, onDismiss: contextMenuActions.handleDismiss, directionalHint: DirectionalHint.rightTopEdge, theme: this.theme },
                    React.createElement(StyledContextMenu, Object.assign({ className: "context-menu" }, this.props, this.theme), data.map((element, index) => {
                        if (element === CONTEXT_MENU_COSTANTS.CONTEXT_MENU_SEPARATOR_STRING) {
                            if (separatorOptions) {
                                return React.createElement(ContextMenuSeparator, Object.assign({}, separatorOptions));
                            }
                            else {
                                return React.createElement(ContextMenuSeparator, null);
                            }
                        }
                        else {
                            return (React.createElement(ContextMenuOption, { contextMenuOptionDataProps: element, contextMenuOptionActions: Object.assign({}, contextMenuActions) }));
                        }
                    })))));
        }
    }
}
export default ContextMenu;
