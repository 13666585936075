import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import Collapse from "@material-ui/core/Collapse";
const StyledAccordionContent = styled.div `
  height: max-content;
  width: 100%;
  background-color: ${props => props.backgroundColor || props.palette.white};
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  border-color: ${props => props.borderColor || props.palette.black};
  overflow: hidden;
`;
class AccordionContent extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        const { isExpanded, backgroundColor, borderColor, contentElements } = this.props;
        return (React.createElement(Collapse, { in: isExpanded },
            React.createElement(StyledAccordionContent, Object.assign({ className: "accordion-content" }, this.theme, this.props), contentElements)));
    }
}
export default AccordionContent;
