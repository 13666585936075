import React from "react";
import styled from "styled-components";
import AccordionTitle from "./AccordionTitle/AccordionTitle";
import AccordionContent from "./AccordionContent/AccordionContent";
import AccordionTitleElement from "./AccordionTitleElement/AccordionTitleElement";
import AccordionContentElement from "./AccordionContentElement/AccordionContentElement";
const StyledAccordionElement = styled.div `
  height: max-content;
  width: calc(100% - 0.2rem);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class AccordionElement extends React.Component {
    constructor(props) {
        super(props);
        this.toggleExpand = () => {
            this.props.toggleExpand(this.props.index);
        };
        this.titleClick = () => {
            if (this.props.titleClick) {
                this.props.titleClick(this.props.index);
            }
        };
    }
    render() {
        const { isExpanded } = this.props;
        let titleElement = null;
        let contentElement = null;
        React.Children.forEach(this.props.children, (child) => {
            if (child.type === AccordionTitleElement) {
                titleElement = child;
            }
            else if (child.type === AccordionContentElement) {
                contentElement = child;
            }
        });
        const { titleColor, titleBackgroundColor, titleBorderColor } = titleElement.props;
        const titleElementChildren = titleElement.props.children;
        const { contentBackgroundColor, contentBorderColor } = contentElement.props;
        const contentElementChildren = contentElement.props.children;
        return (React.createElement(StyledAccordionElement, { className: "accordion-element" },
            React.createElement(AccordionTitle, { isExpanded: isExpanded, color: titleColor, backgroundColor: titleBackgroundColor, borderColor: titleBorderColor, titleElements: titleElementChildren, toggleExpand: this.toggleExpand, titleClick: this.titleClick }),
            React.createElement(AccordionContent, { isExpanded: isExpanded, backgroundColor: contentBackgroundColor, borderColor: contentBorderColor, contentElements: contentElementChildren })));
    }
}
AccordionElement.Title = AccordionTitleElement;
AccordionElement.Content = AccordionContentElement;
export default AccordionElement;
