import React from "react";
import { LegacyScriptLoader } from "../../helper/LegacyScriptLoader";
import DOMPurify from 'dompurify';
var html = require("./index.html");
var page = { __html: DOMPurify.sanitize(html) };
class PasswordChangePage extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        import("./scripts").then(() => {
            LegacyScriptLoader.loadScripts([
                "/sl3/scripts/jquery.min.js",
                "/sl3/scripts/jquery.cookie.min.js",
                "/sl3/scripts/bootstrap.min.js",
                "/sl3/scripts/sl-common.js",
            ]);
        });
    }
    render() {
        return (React.createElement("div", { className: "password-change-page", dangerouslySetInnerHTML: page }));
    }
}
export default PasswordChangePage;
