import React from "react";
import styled from "styled-components";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";
import SimpleButton from "../SimpleButton/SimpleButton";
import { getTheme } from "office-ui-fabric-react";
import TimeInput from "../../web/components/Input/TimeInput/TimeInput";
const StyledDateTimePicker = styled.div `
  height: 26rem;
  width: 43.2rem;
  padding: 0 0 1rem 0;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10000;
`;
const StyledTimePickerWrapper = styled.div `
  height: 2rem;
  width: 42.2rem;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledApplyButtonWrapper = styled.div `
  height: 2rem;
  width: 10rem;
`;
const TimePickerApplyButtonSpacer = styled.div `
  height: 2rem;
  width: 15.7rem;
`;
class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.defaultColorOptions = {
            color: this.theme.palette.black,
            backgroundColor: this.theme.palette.white,
            selectedColor: this.theme.palette.black,
            selectedBackgroundColor: this.theme.palette.themeLighter,
            hoverColor: this.theme.palette.black,
            hoverBackgroundColor: this.theme.palette.neutralLight,
            todayColor: this.theme.palette.white,
            todayBackgroundColor: this.theme.palette.themePrimary,
            todayHoverColor: this.theme.palette.white,
            todayHoverBackgroundColor: this.theme.palette.themeDark,
            otherMonthColor: this.theme.palette.neutralSecondary,
            dividerColor: this.theme.palette.neutralLight
        };
        this.handleDateChange = (year, month, day) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { year: year, month: month, day: day })));
        };
        this.handleTimeChange = (hour, minute) => {
            this.setState(prevState => this.handleTimeChangeAndGetTimeInMillis(prevState, hour, minute));
        };
        this.handleTimeChangeAndGetTimeInMillis = (prevState, hour, minute) => {
            return Object.assign(Object.assign({}, prevState), { hour: hour, minute: minute });
        };
        this.handleApplyTime = () => {
            const { year, month, day, hour, minute } = this.state;
            const dateTimeMillis = moment()
                .year(year)
                .month(month)
                .date(day)
                .hour(hour)
                .minute(minute)
                .second(0)
                .millisecond(0)
                .valueOf();
            this.props.handleDateTimeChange(dateTimeMillis);
        };
        this.onClick = (e) => {
            if (this.props.stopClickPropagation) {
                e.stopPropagation();
            }
        };
        const currentTime = moment.now().valueOf();
        const time = this.props.initialDateTime === undefined || this.props.initialDateTime === null
            ? currentTime
            : this.props.initialDateTime;
        const currentYear = moment(time).year();
        const currentMonth = moment(time).month();
        const currentDay = moment(time).date();
        const currentHour = moment(time).hour();
        const currentMinute = moment(time).minute();
        this.state = {
            currentTime: time,
            year: currentYear,
            month: currentMonth,
            day: currentDay,
            hour: currentHour,
            minute: currentMinute
        };
    }
    render() {
        const colorOptions = this.props.colorOptions || this.defaultColorOptions;
        const normalOptionalProps = {
            backgroundColor: colorOptions.todayBackgroundColor,
            textColor: colorOptions.todayColor
        };
        const hoveringOptionalProps = {
            backgroundColor: colorOptions.todayHoverBackgroundColor,
            textColor: colorOptions.todayHoverColor
        };
        const mouseDownOptionalProps = {
            backgroundColor: colorOptions.todayHoverBackgroundColor,
            textColor: colorOptions.todayHoverColor
        };
        return (React.createElement(StyledDateTimePicker, { className: "date-time-picker", onClick: this.onClick },
            React.createElement(DatePicker, { handleDateChange: this.handleDateChange, initialDate: this.state.currentTime, colorOptions: colorOptions }),
            React.createElement(StyledTimePickerWrapper, { className: "time-picker-wrapper" },
                React.createElement(TimeInput, { initialTime: this.state.currentTime, handleTimeChange: this.handleTimeChange, isTwelveHourTime: true, interval: this.props.interval ? this.props.interval : 5 }),
                React.createElement(TimePickerApplyButtonSpacer, { className: "time-picker-apply-button-spacer" }),
                React.createElement(StyledApplyButtonWrapper, { className: "time-picker-apply-button-wrapper" },
                    React.createElement(SimpleButton, { className: "time-picker-apply-button", color: colorOptions.todayColor, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: colorOptions.todayBackgroundColor, textProps: { iconText: "Apply", height: "1.6rem", width: "10rem" }, padding: "0.2rem 0", borderRadius: "0.5rem", buttonClick: this.handleApplyTime })))));
    }
}
export default DateTimePicker;
