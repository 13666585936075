import React, { useState } from "react";
import { useInterval } from "../../hooks/General";
import chroma from "chroma-js";
import Text from "../Text/Text";
const TextCountdown = (props) => {
    const { className = "text-countdown", text = "", height = 16, width = 100, backgroundColor = "#FFFFFF", fontSize = 14, fontWeight = "normal", fontStyle = "normal", fontColor: idleFontColor = "#000000", verticalAlignment = "center", horizontalAlignment = "center", verticalSpaceFromEdge = 0, horizontalSpaceFromEdge = 0, color = { start: "#16B821", middle: "#C7CC31", end: "#D62C1B" }, countdownTimeSeconds = 60, isCountdownComplete } = props;
    const [timeSecondsRemaining, setTimeSecondsRemaining] = useState(countdownTimeSeconds);
    const [activelyLoading, setActivelyLoading] = useState(false);
    let fontColor = idleFontColor;
    if (!isCountdownComplete) {
        if (typeof color === "string") {
            fontColor = color;
        }
        else {
            const colorScale = chroma.scale([color.end, color.middle, color.start]);
            fontColor = colorScale(timeSecondsRemaining / countdownTimeSeconds).hex();
        }
    }
    useInterval(() => {
        if (activelyLoading) {
            if (isCountdownComplete) {
                setActivelyLoading(false);
                setTimeSecondsRemaining(countdownTimeSeconds);
            }
            else {
                setTimeSecondsRemaining((prevState) => {
                    return Math.max(0, prevState - 1);
                });
            }
        }
        else {
            if (!isCountdownComplete) {
                setActivelyLoading(true);
                setTimeSecondsRemaining((prevState) => {
                    return countdownTimeSeconds - 1;
                });
            }
        }
    }, 1000);
    return (React.createElement(Text, { text: timeSecondsRemaining.toString(), className: className, height: height, width: width, backgroundColor: backgroundColor, fontSize: fontSize, fontWeight: fontWeight, fontStyle: fontStyle, fontColor: fontColor, verticalAlignment: verticalAlignment, horizontalAlignment: horizontalAlignment, verticalSpaceFromEdge: verticalSpaceFromEdge, horizontalSpaceFromEdge: horizontalSpaceFromEdge }));
};
export default TextCountdown;
