import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleList from "../../SimpleList/SimpleList";
import MultiSelectRightPanelControl from "./MultiSelectRightPanelControl/MultiSelectRightPanelControl";
const MultiSelectRightPanelWrapper = styled.div `
  width: 20rem;
  height: 22.2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class MultiSelectRightPanel extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        const { selected, pendingUnselect, backgroundColor, borderColor, selectColor, fontColor, handlePendingUnselectChange, handlePendingUnselectInvoke, totalCount, selectedCount, handlePersistPendingUnselect, handleAllUnselect, unselectedPerformClearSelect, handleUnselectedFinishClearSelect } = this.props;
        return (React.createElement(MultiSelectRightPanelWrapper, null,
            React.createElement(MultiSelectRightPanelControl, { selectedCount: selectedCount, totalCount: totalCount, handlePersistPendingUnselect: handlePersistPendingUnselect, handleAllUnselect: handleAllUnselect }),
            React.createElement(SimpleList, { rows: selected, height: "20rem", rowHeight: "1.8rem", width: "20rem", borderColor: borderColor, backgroundColor: backgroundColor, selectColor: selectColor, fontColor: fontColor, handleSelectionChange: handlePendingUnselectChange, handleInvoke: handlePendingUnselectInvoke, performClearSelect: unselectedPerformClearSelect, handleFinishClearSelect: handleUnselectedFinishClearSelect })));
    }
}
export default MultiSelectRightPanel;
