import React from "react";
import Accordion from "../../Accordion/Accordion";
import AccordionElement from "../../Accordion/AccordionElement/AccordionElement";
import FilterTitle from "./FilterTitle/FilterTitle";
import FilterRow from "./FilterRow/FilterRow";
class FilterList extends React.Component {
    constructor(props) {
        super(props);
        this.toggleExpand = (index) => {
            this.props.filterRowCommonActions.filterRowToggleExpandHandler({
                index: index
            });
        };
        this.removeFilter = (index) => {
            this.props.filterRowCommonActions.filterRowRemoveHandler({
                index: index
            });
        };
        this.getFilterRow = (filterRowAndWrapperProps, index) => {
            const titleConfiguration = filterRowAndWrapperProps.optionalConfiguration
                ? filterRowAndWrapperProps.optionalConfiguration.titleConfiguration
                : {};
            const contentConfiguration = filterRowAndWrapperProps.optionalConfiguration
                ? filterRowAndWrapperProps.optionalConfiguration.contentConfiguration
                : {};
            return (React.createElement(AccordionElement, { key: index, index: index, isExpanded: filterRowAndWrapperProps.isExpanded, toggleExpand: this.toggleExpand, titleClick: this.toggleExpand },
                React.createElement(AccordionElement.Title, Object.assign({}, titleConfiguration),
                    React.createElement(FilterTitle, { index: index, isExpanded: filterRowAndWrapperProps.isExpanded, displayedText: filterRowAndWrapperProps.displayedText, filterTitleConfiguration: titleConfiguration, removeFilter: this.removeFilter })),
                React.createElement(AccordionElement.Content, Object.assign({}, contentConfiguration),
                    React.createElement(FilterRow, Object.assign({}, filterRowAndWrapperProps, { index: index })))));
        };
    }
    render() {
        return (React.createElement(Accordion, null, this.props.filterRowAndWrapperProps.map((filterData, index) => {
            return this.getFilterRow(filterData, index);
        })));
    }
}
export default FilterList;
