import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import LoginMenuTitleBar from "../LoginMenu/LoginMenuTitleBar/LoginMenuTitleBar";
import PasswordChangeMenuInput from "./PasswordChangeMenuInput/PasswordChangeMenuInput";
export const StyledPasswordChangeMenu = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const PasswordChangeMenu = (props) => {
    const { handleChangePassword, handleGoBack, slAuthEnabled, errorMessage, message, loginMenuLogoSrc, height, width, titleBarHeight, loginMenuBackgroundColor, focusColor, errorColor, fontColorWhite, fontColor, fontColorBlack, themeColor, themeColorDark, themeColorDarker } = props;
    return (React.createElement(StyledPasswordChangeMenu, { className: "password-change-menu", height: height, width: width },
        React.createElement(LoginMenuTitleBar, { logoSrc: loginMenuLogoSrc, height: titleBarHeight, backgroundColor: themeColor, fontColor: fontColorBlack, text: "Change password" }),
        React.createElement(PasswordChangeMenuInput, { slAuthEnabled: slAuthEnabled, message: message, errorMessage: errorMessage, height: height - titleBarHeight, width: width, backgroundColor: loginMenuBackgroundColor, focusColor: focusColor, labelFontColor: themeColor, inputPlaceholderColor: fontColor, inputFontColor: fontColorBlack, errorMessageFontColor: errorColor, buttonFontColor: fontColorWhite, buttonBackgroundColor: { normal: themeColor, mouseOver: themeColorDark, mouseDown: themeColorDarker }, handleChangePassword: handleChangePassword, handleGoBack: handleGoBack })));
};
export default PasswordChangeMenu;
