import React, { useState } from "react";
import styled from "styled-components";
import SelectInput from "../../../Input/SelectInput/SelectInput";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
import ControlledTextInput from "../../../Input/ControlledTextInput/ControlledTextInput";
import Spacer from "../../../Spacer/Spacer";
export const StyledNumericFilterRow = styled.div `
  height: 18px;
  width: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
export var NumericFilterRowSelectOption;
(function (NumericFilterRowSelectOption) {
    NumericFilterRowSelectOption["EQUAL_TO"] = "Equal To";
    NumericFilterRowSelectOption["NOT_EQUAL_TO"] = "Not Equal To";
    NumericFilterRowSelectOption["GREATER_THAN"] = "Greater Than";
    NumericFilterRowSelectOption["GREATER_THAN_OR_EQUAL"] = "Greater Than or Equal To";
    NumericFilterRowSelectOption["LESS_THAN"] = "Less Than";
    NumericFilterRowSelectOption["LESS_THAN_OR_EQUAL"] = "Less Than or Equal To";
})(NumericFilterRowSelectOption || (NumericFilterRowSelectOption = {}));
const NumericFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, handleFilterChange, } = props;
    const filterKeyToSelectValue = (filterKey) => {
        switch (filterKey) {
            case "$in": {
                return NumericFilterRowSelectOption.EQUAL_TO;
            }
            case "$ne": {
                return NumericFilterRowSelectOption.NOT_EQUAL_TO;
            }
            case "$gt": {
                return NumericFilterRowSelectOption.GREATER_THAN;
            }
            case "$ge": {
                return NumericFilterRowSelectOption.GREATER_THAN_OR_EQUAL;
            }
            case "$lt": {
                return NumericFilterRowSelectOption.LESS_THAN;
            }
            case "$le": {
                return NumericFilterRowSelectOption.LESS_THAN_OR_EQUAL;
            }
            default: {
                return "";
            }
        }
    };
    const initialFilterType = initialFilter ? filterKeyToSelectValue(Object.keys(initialFilter)[0]) : "";
    const [type, setType] = useState(initialFilterType);
    const [text, setText] = useState(initialFilter ? Object.values(initialFilter)[0][0] : "");
    const handleClearFilter = () => {
        setText("");
    };
    const handleTextChange = (value, index) => {
        const floatMatch = value.match(/^\d+\.\d+$/);
        const isFloat = floatMatch == null ? false : floatMatch.length > 0;
        const intMatch = value.match(/^-{0,1}\d+$/);
        const isInt = intMatch == null ? false : intMatch.length > 0;
        const isNumeric = isFloat || isInt;
        if (isNumeric) {
            handleChange(type, value);
            setText(value);
        }
    };
    const handleTypeChange = (value) => {
        handleChange(value, text);
        setType(value);
    };
    const handleChange = (type, text) => {
        let newFilterElement = null;
        switch (type) {
            case NumericFilterRowSelectOption.EQUAL_TO: {
                newFilterElement = {
                    $in: [text]
                };
                break;
            }
            case NumericFilterRowSelectOption.NOT_EQUAL_TO: {
                newFilterElement = {
                    $ne: [text]
                };
                break;
            }
            case NumericFilterRowSelectOption.GREATER_THAN: {
                newFilterElement = {
                    $gt: [text]
                };
                break;
            }
            case NumericFilterRowSelectOption.GREATER_THAN_OR_EQUAL: {
                newFilterElement = {
                    $ge: [text]
                };
                break;
            }
            case NumericFilterRowSelectOption.LESS_THAN: {
                newFilterElement = {
                    $lt: [text]
                };
                break;
            }
            case NumericFilterRowSelectOption.LESS_THAN_OR_EQUAL: {
                newFilterElement = {
                    $le: [text]
                };
                break;
            }
        }
        const newFilter = {
            [filterKey]: newFilterElement
        };
        handleFilterChange({
            key: filterKey,
            filterObject: newFilter,
        });
    };
    const inputOptions = Object.values(NumericFilterRowSelectOption);
    const initialSelectValue = initialFilter ? filterKeyToSelectValue(Object.keys(initialFilter)[0]) : "";
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledNumericFilterRow, null,
            React.createElement(SelectInput, { options: ["", ...inputOptions], height: 18, initialValue: initialSelectValue, handleChange: handleTypeChange }),
            React.createElement(Spacer, { width: 10 }),
            React.createElement(ControlledTextInput, { handleChange: handleTextChange, value: text, height: 16, width: 200, placeholder: "Enter Value" }))));
};
export default NumericFilterRow;
