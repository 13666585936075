import { InventorySchema } from "../../state/api/WebSockets/Inventory/Schema";
import { InventoryOwner } from "../../state/api/WebSockets/Inventory/Owner";
import { Utils } from "../General/Utils";
export var InventorySchemaService;
(function (InventorySchemaService) {
    InventorySchemaService.pathSeparator = "/";
    function init() {
        return new Promise((resolve, reject) => {
            const containerSchemasPromise = InventorySchema.containersRequest();
            const inventorySchemasPromise = InventorySchema.inventoriesRequest();
            const ownerTypesSchemasPromise = InventoryOwner.ownerTypesRequest();
            Promise.all([containerSchemasPromise, inventorySchemasPromise, ownerTypesSchemasPromise]).then(function (schemas) {
                const containerSchemas = schemas[0].response || [];
                const inventorySchemas = schemas[1].response || [];
                const ownerTypeSchemas = schemas[2].response || [];
                const containerList = containerSchemas.map(containerSchema => containerSchema.name);
                const inventoryList = inventorySchemas.map(itemSchema => itemSchema.name);
                const containerIndexSchemasPromise = containerList.map(containerName => InventorySchema.containerIndexesRequest(containerName));
                const inventoryIndexSchemasPromise = inventoryList.map(inventoryName => InventorySchema.inventoryIndexesRequest(inventoryName));
                Promise.all([...containerIndexSchemasPromise, ...inventoryIndexSchemasPromise]).then(function (indexSchemas) {
                    const containerListLength = containerList.length;
                    const inventoryListLength = inventoryList.length;
                    const containerIndexSchemas = Utils.deepClone(indexSchemas)
                        .splice(0, containerListLength)
                        .map(containerIndexSchemaResponse => containerIndexSchemaResponse.response);
                    const inventoryIndexSchemas = Utils.deepClone(indexSchemas)
                        .splice(containerListLength, inventoryListLength)
                        .map(containerIndexSchemaResponse => containerIndexSchemaResponse.response);
                    const inventorySchema = {
                        containers: [...containerSchemas],
                        items: [...inventorySchemas],
                        ownerTypes: [...ownerTypeSchemas],
                        containersIndexes: getContainersIndexesSchema(containerIndexSchemas, containerList),
                        itemsIndexes: getItemsIndexesSchema(inventoryIndexSchemas, inventoryList)
                    };
                    resolve(inventorySchema);
                });
            });
        });
    }
    InventorySchemaService.init = init;
    function getUserData() {
        return {
            buId: parseInt(Utils.getCookieByName("BUID")),
            buName: Utils.getCookieByName("BUNAME"),
            userName: Utils.getCookieByName("UNAME")
        };
    }
    InventorySchemaService.getUserData = getUserData;
    function getProjectDefinitionFromServerObject(projectDefinition) {
        return {
            name: Utils.snakeToCamelCase(projectDefinition.name),
            type: projectDefinition.type
        };
    }
    function getContainersIndexesSchema(containersIndexes, containersNameArray) {
        let count = 0;
        let containersIndexesSchema = [];
        containersIndexes.forEach(containerIndexes => {
            const containerIndexSchema = {
                name: containersNameArray[count],
                indexes: containerIndexes.map(containerIndex => {
                    return { name: containerIndex.name, value: Utils.snakeToCamelCase(containerIndex.value) };
                })
            };
            containersIndexesSchema.push(containerIndexSchema);
            count++;
        });
        return containersIndexesSchema;
    }
    function getItemsIndexesSchema(itemsIndexes, itemsNameArray) {
        let count = 0;
        let itemsIndexesSchema = [];
        itemsIndexes.forEach(itemIndexes => {
            const itemIndexSchema = {
                name: itemsNameArray[count],
                indexes: itemIndexes.map(itemIndex => {
                    return { name: itemIndex.name, value: Utils.snakeToCamelCase(itemIndex.value) };
                })
            };
            itemsIndexesSchema.push(itemIndexSchema);
            count++;
        });
        return itemsIndexesSchema;
    }
    function getInventoryContainerFromServerObject(container) {
        return {
            uniqueId: container.id,
            buId: container.buId,
            id: container.containerId,
            name: container.name,
            path: container.path,
            jsonClass: container.jsonClass,
            lat: container.lat,
            lon: container.lon,
            description: container.description,
            depth: container.depth,
            parentContainerId: container.parentContainer,
            ownerId: container.ownerId,
            ownerType: container.type,
            creationDate: container.createDate,
            modifyDate: container.modifyDate,
            attributes: Object.assign({}, container.properties)
        };
    }
    InventorySchemaService.getInventoryContainerFromServerObject = getInventoryContainerFromServerObject;
    function getInventoryServerObjectFromItem(item) {
        if (!item) {
            return undefined;
        }
        let path = undefined;
        if (item.path) {
            const pathArray = item.path.split("/");
            if (pathArray.length > 1) {
                pathArray.pop();
                path = pathArray.join("/");
            }
            else {
                path = pathArray[0] || undefined;
            }
        }
        const properties = item.attributes ? Object.assign({}, item.attributes) : {};
        return {
            address: item.address,
            city: item.city,
            country: item.country,
            createDate: item.creationDate,
            description: item.description,
            id: item.uniqueId,
            inventoryId: item.id,
            jsonClass: item.jsonClass,
            lat: item.lat,
            lon: item.lon,
            modifyDate: item.modifyDate,
            name: item.name,
            ownerId: item.ownerId,
            parentContainer: item.parentContainerId,
            path: path,
            type: item.ownerType,
            stateProvince: item.stateProvince,
            zip: item.zip,
            state: item.state,
            properties: properties
        };
    }
    InventorySchemaService.getInventoryServerObjectFromItem = getInventoryServerObjectFromItem;
    function getInventoryItemFromServerObject(item) {
        if (!item) {
            return undefined;
        }
        const path = `${item.path}/${item.inventoryId}`;
        const attributes = item.properties ? Object.assign({}, item.properties) : {};
        return {
            uniqueId: item.id,
            id: item.inventoryId,
            name: item.name,
            path: path,
            jsonClass: item.jsonClass,
            lat: item.lat,
            lon: item.lon,
            description: item.description,
            depth: getItemDepth(path),
            parentContainerId: item.parentContainer,
            ownerId: item.ownerId,
            ownerType: item.type,
            creationDate: item.createDate,
            modifyDate: item.modifyDate,
            state: item.state,
            country: item.country,
            stateProvince: item.stateProvince,
            city: item.city,
            address: item.address,
            zip: item.zip,
            attributes: attributes
        };
    }
    InventorySchemaService.getInventoryItemFromServerObject = getInventoryItemFromServerObject;
    function getItemDepth(path) {
        const regExp = new RegExp(InventorySchemaService.pathSeparator, "g");
        const matchArray = path.match(regExp);
        return matchArray.length || 0;
    }
    function getTypeFromJsonClass(jsonClass) {
        const strings = jsonClass.split(".");
        return strings[strings.length - 1];
    }
    InventorySchemaService.getTypeFromJsonClass = getTypeFromJsonClass;
    function getAttributesForContainerOrItem(obj) {
        const copiedObject = Object.assign({}, obj);
        delete copiedObject.attributes;
        return Object.assign(Object.assign({}, copiedObject), obj.attributes);
    }
    InventorySchemaService.getAttributesForContainerOrItem = getAttributesForContainerOrItem;
})(InventorySchemaService || (InventorySchemaService = {}));
