import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import LoginMenuTitleBar from "./LoginMenuTitleBar/LoginMenuTitleBar";
import LoginMenuInput from "./LoginMenuInput/LoginMenuInput";
import LoginMenu2FAInput from "./LoginMenu2FAInput/LoginMenu2FAInput";
export const StyledLoginMenu = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const LoginMenu = (props) => {
    const { handleLogin, handleADLogin, handleActiveDirectoryLogin, handle2FA, handle2FAResend, isTwoFactorAuthenticating, isAskingForBU, activeDirectoryEnabled, slAuthEnabled, plainAdLoginEnabled, activeDirectoryMode, isTwoFactorAuthenticationEnabled, contactInformation, countdownTimeSeconds, errorMessage, message, businessUnitOptions, initialBusinessUnit, initialLogin, loginMenuLogoSrc, height, width, titleBarHeight, loginMenuBackgroundColor, focusColor, errorColor, fontColorWhite, fontColor, fontColorBlack, themeColor, themeColorDark, themeColorDarker, isADLogin } = props;
    const isADLoginStorage = localStorage.getItem("isADLogin") === "true";
    // @ts-ignore
    return (React.createElement(StyledLoginMenu, { className: "login-menu", height: height, width: width },
        React.createElement(LoginMenuTitleBar, { logoSrc: loginMenuLogoSrc, height: titleBarHeight, backgroundColor: themeColor, fontColor: fontColorBlack }),
        isTwoFactorAuthenticating && isADLoginStorage ? (React.createElement(LoginMenu2FAInput, { handle2FA: handle2FA, handle2FAResend: handle2FAResend, message: message, errorMessage: errorMessage, countdownTimeSeconds: countdownTimeSeconds, contactInformation: contactInformation, height: height - titleBarHeight, width: width, backgroundColor: loginMenuBackgroundColor, messageFontColor: fontColorBlack, labelFontColor: themeColor, inputBorderColor: fontColor, inputFontColor: fontColorBlack, inputResendButtonFontColor: fontColorWhite, inputResendButtonBackgroundColor: { normal: themeColor, mouseOver: themeColorDark, mouseDown: themeColorDarker }, errorMessageFontColor: errorColor, buttonFontColor: fontColorWhite, buttonBackgroundColor: { normal: themeColor, mouseOver: themeColorDark, mouseDown: themeColorDarker } })) : (React.createElement(LoginMenuInput, { handleLogin: handleLogin, handleADLogin: handleADLogin, handleActiveDirectoryLogin: handleActiveDirectoryLogin, activeDirectoryEnabled: activeDirectoryEnabled, activeDirectoryMode: activeDirectoryMode, isTwoFactorAuthenticationEnabled: isTwoFactorAuthenticationEnabled, isAskingForBU: isAskingForBU, message: message, errorMessage: errorMessage, businessUnitOptions: businessUnitOptions, initialBusinessUnit: initialBusinessUnit, initialLogin: initialLogin, slAuthEnabled: slAuthEnabled, plainAdLoginEnabled: plainAdLoginEnabled, height: height - titleBarHeight, width: width, backgroundColor: loginMenuBackgroundColor, focusColor: focusColor, labelFontColor: themeColor, inputPlaceholderColor: fontColor, inputFontColor: fontColorBlack, errorMessageFontColor: errorColor, buttonFontColor: fontColorWhite, buttonBackgroundColor: { normal: themeColor, mouseOver: themeColorDark, mouseDown: themeColorDarker } }))));
};
export default LoginMenu;
