import React from "react";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../../SimpleButton/SimpleButton";
export const controlBarWidth = 22;
export const controlBarBorderWidth = 2;
const controlBarIconWidth = controlBarWidth - controlBarBorderWidth;
var RESIZABLE_PANEL_CONTROL_BAR_DIRECTION;
(function (RESIZABLE_PANEL_CONTROL_BAR_DIRECTION) {
    RESIZABLE_PANEL_CONTROL_BAR_DIRECTION["UP"] = "SL-Arrow-Up";
    RESIZABLE_PANEL_CONTROL_BAR_DIRECTION["DOWN"] = "SL-Arrow-Down";
    RESIZABLE_PANEL_CONTROL_BAR_DIRECTION["LEFT"] = "SL-Arrow-Left";
    RESIZABLE_PANEL_CONTROL_BAR_DIRECTION["RIGHT"] = "SL-Arrow-Right";
})(RESIZABLE_PANEL_CONTROL_BAR_DIRECTION || (RESIZABLE_PANEL_CONTROL_BAR_DIRECTION = {}));
var RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE;
(function (RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE) {
    RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE["HORIZONTAL"] = "SL-Drag-Horizontal";
    RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE["VERTICAL"] = "SL-Drag-Vertical";
})(RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE || (RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE = {}));
class ResizablePanelControlBar extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.getDragDropButton = (color, backgroundColor) => {
            const dragDropIconName = this.props.horizontal
                ? RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE.HORIZONTAL
                : RESIZABLE_PANEL_CONTROL_BAR_DRAG_HANDLE.VERTICAL;
            return (React.createElement(SimpleButton, { className: "resizable-panel-drag-drop-button", iconProps: {
                    iconName: dragDropIconName,
                    size: controlBarIconWidth
                }, color: color, backgroundColor: backgroundColor, iconOffsetY: "-0.2rem" }));
        };
        this.getToggleHideButton = (color, backgroundColor) => {
            let toggleHideIconName = "";
            const horizontal = this.props.horizontal;
            const hidden = this.props.hidden;
            if (horizontal) {
                if (hidden) {
                    toggleHideIconName = RESIZABLE_PANEL_CONTROL_BAR_DIRECTION.RIGHT;
                }
                else {
                    toggleHideIconName = RESIZABLE_PANEL_CONTROL_BAR_DIRECTION.LEFT;
                }
            }
            else {
                if (hidden) {
                    toggleHideIconName = RESIZABLE_PANEL_CONTROL_BAR_DIRECTION.DOWN;
                }
                else {
                    toggleHideIconName = RESIZABLE_PANEL_CONTROL_BAR_DIRECTION.UP;
                }
            }
            return (React.createElement(SimpleButton, { className: "resizable-panel-open-close-button", iconProps: {
                    iconName: toggleHideIconName,
                    size: controlBarIconWidth
                }, color: color, backgroundColor: backgroundColor, buttonClick: this.props.toggleHideHandler, iconOffsetY: "-0.1rem" }));
        };
        this.state = {
            onHoverDragDropIcon: false,
            onHoverToggleHideIcon: false
        };
    }
    render() {
        const { horizontal, hidden, length, color, name, toggleHideHandler } = this.props;
        const backgroundColor = this.theme.palette.neutralLight;
        const controlBarStyle = {
            height: horizontal ? StyleUtils.getCssPixelString(length) : controlBarIconWidth,
            width: horizontal ? controlBarIconWidth : StyleUtils.getCssPixelString(length),
            backgroundColor: backgroundColor,
            display: "flex",
            flexDirection: horizontal ? "column" : "row",
            borderColor: color,
            borderWidth: horizontal
                ? `0px ${StyleUtils.getCssPixelString(controlBarBorderWidth)} 0px 0px`
                : `0px 0px ${StyleUtils.getCssPixelString(controlBarBorderWidth)} 0px`,
            borderStyle: "solid",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            overflow: "hidden"
        };
        const dragDropButtonWrapperStyle = {
            position: "relative",
            width: StyleUtils.getCssPixelString(controlBarIconWidth),
            height: StyleUtils.getCssPixelString(controlBarIconWidth),
            top: StyleUtils.getCssPixelString(horizontal ? controlBarIconWidth / 2 : 0),
            left: StyleUtils.getCssPixelString(horizontal ? 0 : controlBarIconWidth / 2)
        };
        const toggleHideButtonWrapperStyle = {
            position: "relative",
            width: StyleUtils.getCssPixelString(controlBarIconWidth),
            height: StyleUtils.getCssPixelString(controlBarIconWidth),
            top: StyleUtils.getCssPixelString(horizontal ? (length - controlBarIconWidth) / 2 - controlBarIconWidth : 0),
            left: StyleUtils.getCssPixelString(horizontal ? 0 : (length - controlBarIconWidth) / 2 - controlBarIconWidth)
        };
        const maximumNameLength = Math.max(length / 2 - controlBarIconWidth * 3, 0);
        const nameStyle = {
            position: "relative",
            width: StyleUtils.getCssPixelString(maximumNameLength),
            height: StyleUtils.getCssPixelString(controlBarIconWidth),
            transform: horizontal ? "rotate(90deg)" : "",
            top: StyleUtils.getCssPixelString(horizontal ? (maximumNameLength - controlBarIconWidth) * 0.5 : 0),
            left: StyleUtils.getCssPixelString(horizontal ? (maximumNameLength - controlBarIconWidth) * -0.5 : 0),
            textOverflow: "ellipsis",
            fontWeight: "bold",
            fontSize: StyleUtils.getCssPixelString(controlBarIconWidth - 2),
            color: color,
            overflow: "hidden",
            whiteSpace: "nowrap",
            userSelect: "none"
        };
        return (React.createElement("div", { className: "resizable-panel-control-bar", style: controlBarStyle },
            React.createElement("div", Object.assign({ className: "resizable-panel-drag-drop-button-wrapper", style: dragDropButtonWrapperStyle }, this.props.dragHandleProps), this.getDragDropButton(color, backgroundColor)),
            React.createElement("div", { className: "resizable-panel-toggle-hide-button-wrapper", style: toggleHideButtonWrapperStyle }, this.getToggleHideButton(color, backgroundColor)),
            React.createElement("div", { className: "resizable-panel-name", style: nameStyle }, name)));
    }
}
export default ResizablePanelControlBar;
