import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import styled from "styled-components";
import ReactResizeDetector from "react-resize-detector";
import { setDefaultOptions } from "esri-loader";
import { MapIconCommonProperties } from "../types";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import { render } from "react-dom";
import { usePreviousProps } from "../../../../../hooks/General/usePreviousProps";
import MapPopup from "../Common/MapPopup/MapPopup";
import ThemeContextProvider from "../../../../theme/ThemeContextProvider";
import MapDragSelect from "../Common/MapDragSelect/MapDragSelect";
import { useEventListener } from "../../../../../hooks/General/useEventListener";
import { useDrop } from "react-dnd";
import { DraggableTypes } from "../../../../../hooks/DragDrop/types";
import mergeRefs from "react-merge-refs";
import MapLayerTree from "../Common/MapLayerTree/MapLayerTree";
import { MapUtils } from "../../../uils/MapUtils";
const StyledESRIMap = styled.div `
  height: 100%;
  width: 100%;
`;
//Map globals. Cannot be react state because react state is immutable.
let map = null;
let mapView = null;
let slFeaturesLayer = null;
let slFeaturesHighlightLayer = null;
let slRoutingLayer = null;
let slRoutingFeaturesLayer = null;
let slRoutingFeaturesHighlightLayer = null;
let slHoverRouteLayer = null;
let slHoverRouteRoutingFeaturesLayer = null;
let slHoverRouteFeaturesLayer = null;
let localHoverRouteData = null;
let localIcons = [];
let localEventAddData = null;
let localEventDeleteData = null;
let localEventModifyData = null;
let localRoutes = [];
let isDragDroppingFromMap = false;
let localMapHoverData = null;
let layerSelectWidget = null;
let firstRender = true;
let esriLayers = [];
export const destroyMap = () => {
    map = null;
    mapView = null;
    slFeaturesLayer = null;
    slFeaturesHighlightLayer = null;
    slRoutingLayer = null;
    slRoutingFeaturesLayer = null;
    slRoutingFeaturesHighlightLayer = null;
    slHoverRouteLayer = null;
    slHoverRouteRoutingFeaturesLayer = null;
    slHoverRouteFeaturesLayer = null;
    localIcons = [];
    localEventAddData = null,
        localEventDeleteData = null,
        localEventModifyData = null,
        localRoutes = [];
    layerSelectWidget = null;
    firstRender = true;
    esriLayers = [];
};
export var ESRIMapConstants;
(function (ESRIMapConstants) {
    ESRIMapConstants["SlFeaturesLayerName"] = "sl-features";
    ESRIMapConstants["SlFeaturesHighlightLayerName"] = "sl-features-highlight";
    ESRIMapConstants["SlRoutingFeaturesLayerName"] = "sl-features-routing";
    ESRIMapConstants["SlRoutingFeaturesHighlightLayerName"] = "sl-features-routing-highlight";
    ESRIMapConstants["SlRoutingLayerName"] = "sl-routing-layer";
    ESRIMapConstants["SlHoverRouteLayer"] = "sl-hover-route-layer";
    ESRIMapConstants["SlHoverRouteRoutingFeaturesLayer"] = "sl-hover-route-routing-features-layer";
    ESRIMapConstants["SlHoverRouteFeaturesLayer"] = "sl-hover-route-features-layer";
})(ESRIMapConstants || (ESRIMapConstants = {}));
const ESRIMap = (props) => {
    const { icons, eventAddData, eventDeleteData, eventModifyData, routes, urlPrefix, proxyUrl, eventMapViewObject, iconSize = 30, customLayerGroups, customLayerDisplaySettings, customLayerPopupsEnabled, customLayersOnTop = false, customLayerSelectHasSaveButton = false, initialBaseMap = "streets", initialZoom = 3, initialLat = 40, initialLon = -90, scaleBarType = "dual", highlightColor = MapIconCommonProperties.HighlightColor, highlightOutlineColor = MapIconCommonProperties.HighlightOutlineColor, highlightSizeRatio = 1.1, highlightOutlineSizeRatio = 0.05, mapIsDragging, mapHoverData, mapLayerTree, hoverRouteData, handleMapExtentChange, handleMapResize, handleSelect, handleUnselect, handleMultiSelect, handleMultiSelectCtrl, handleDragStart, handleDrag, handleDragEnd, handleDragTo, handleHover, handleHoverEnd, handleTreeDataChange, handleFirstRender, } = props;
    const [mapDragSelectProps, setMapDragSelectProps] = useState({
        isActive: false,
        isCtrl: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
    });
    const mapRef = useRef();
    const getMapIconByInternalId = (internalId) => {
        return localIcons.find(icon => icon.internalId === internalId);
    };
    const handleTreeDataSave = (newTreeData) => {
        handleTreeDataChange(newTreeData);
        if (!!layerSelectWidget && !!customLayerSelectHasSaveButton) {
            layerSelectWidget.collapse();
        }
    };
    const handleDrop = (mapDropCoordinate, orderItem) => {
        mapView.hitTest(mapDropCoordinate).then((rsp) => {
            const hitResults = rsp.results;
            hitResults.forEach(result => {
                const sourceLayerName = result.graphic.sourceLayer.id;
                switch (sourceLayerName) {
                    case ESRIMapConstants.SlFeaturesLayerName: {
                        const objectId = result.graphic.attributes.OBJECTID;
                        slFeaturesLayer.queryFeatures().then((rsp) => {
                            const features = rsp.features;
                            for (let i = 0; i < features.length; i++) {
                                const feature = features[i];
                                if (feature.attributes.OBJECTID === objectId) {
                                    const internalId = feature.attributes.internalId;
                                    const mapIcon = getMapIconByInternalId(internalId);
                                    const isSingleFsr = mapIcon.fsrIds.length === 1 && mapIcon.orderIds.length === 0 && mapIcon.vehicleIds.length === 0 && mapIcon.crewIds.length === 0 && mapIcon.projectIds.length === 0;
                                    if (isSingleFsr) {
                                        const orderIds = orderItem.selectedOrderIds;
                                        const fsrId = mapIcon.fsrIds[0];
                                        handleDragTo({
                                            orderIds: orderIds,
                                            fsrId: fsrId,
                                        });
                                    }
                                    break;
                                }
                            }
                        });
                        break;
                    }
                    default: {
                    }
                }
            });
        }).catch(e => console.error(e));
    };
    const [collectedProps, dropRef] = useDrop({
        accept: DraggableTypes.order,
        drop: (item, monitor) => {
            const orderItem = item;
            const dropCoordinate = monitor.getClientOffset();
            const clientRect = mapRef.current.getBoundingClientRect();
            const mapPosition = {
                x: clientRect.left,
                y: clientRect.top,
            };
            const mapDropCoordinate = {
                x: dropCoordinate.x - mapPosition.x,
                y: dropCoordinate.y - mapPosition.y,
            };
            handleDrop(mapDropCoordinate, orderItem);
        }
    });
    const prevProps = usePreviousProps(props);
    const onWindowMouseMove = (e) => {
        if (mapIsDragging) {
            handleDrag({
                coordinates: {
                    x: e.clientX,
                    y: e.clientY,
                }
            });
        }
    };
    const onWindowMouseUp = (e) => {
        if (mapIsDragging) {
            handleDragEnd({
                coordinates: {
                    x: e.clientX,
                    y: e.clientY,
                }
            });
        }
    };
    useEventListener("mousemove", onWindowMouseMove, window);
    useEventListener("mouseup", onWindowMouseUp, window);
    useEffect(() => {
        //@ts-ignore
        window.dojoConfig = {
            async: true,
            has: {
                "csp-restrictions": true
            }
        };
        localIcons = icons;
        localEventAddData = eventAddData;
        localEventDeleteData = eventDeleteData;
        localEventModifyData = eventModifyData;
        localRoutes = routes;
        localMapHoverData = mapHoverData;
        localHoverRouteData = hoverRouteData;
        setDefaultOptions({ version: "4.20" });
        loadModules([
            "esri/Map",
            "esri/views/MapView",
            "esri/widgets/Expand",
            "esri/widgets/BasemapGallery",
            "esri/widgets/ScaleBar",
            "esri/widgets/Search",
            "esri/layers/Layer",
            "esri/layers/FeatureLayer",
            "esri/layers/GraphicsLayer",
            "esri/layers/GroupLayer",
            "esri/core/watchUtils",
            "esri/geometry/support/webMercatorUtils",
            "esri/renderers/UniqueValueRenderer",
            "esri/geometry/Point",
            "esri/Graphic",
            "esri/symbols/PictureMarkerSymbol",
            "esri/renderers/support/UniqueValueInfo",
            "esri/core/urlUtils",
            "esri/tasks/support/Query",
            "esri/tasks/QueryTask",
            "esri/geometry/geometryEngine",
            "esri/geometry/Extent",
            "esri/geometry/Polygon",
            "esri/config"
        ], { css: true })
            .then(([Map, MapView, Expand, BasemapGallery, ScaleBar, SearchBar, Layer, FeatureLayer, GraphicsLayer, GroupLayer, watchUtils, webMercatorUtils, UniqueValueRenderer, Point, Graphic, PictureMarkerSymbol, UniqueValueInfo, urlUtils, Query, QueryTask, geometryEngine, Extent, Polygon, EsriConfig,]) => {
            if (!map) {
                initialize(Map, MapView, Expand, BasemapGallery, ScaleBar, SearchBar, Layer, FeatureLayer, watchUtils, webMercatorUtils, Point, GraphicsLayer, GroupLayer, urlUtils, EsriConfig);
            }
            if (prevProps) {
                if (prevProps.icons !== icons) {
                    if (slFeaturesLayer) {
                        updateSlFeaturesLayer();
                    }
                    if (slFeaturesHighlightLayer) {
                        updateSlFeaturesHighlightLayer();
                    }
                }
                if (prevProps.eventAddData !== eventAddData) {
                    if (slFeaturesLayer) {
                        updateSlFeaturesLayerAdd();
                    }
                }
                if (prevProps.eventModifyData !== eventModifyData) {
                    if (slFeaturesLayer) {
                        updateSlFeaturesLayerModify();
                    }
                }
                if (prevProps.eventDeleteData !== eventDeleteData) {
                    if (slFeaturesLayer) {
                        updateSlFeaturesLayerDelete();
                    }
                }
                if (prevProps.routes !== routes) {
                    if (routes.length === 0) {
                        if (slFeaturesLayer) {
                            slFeaturesLayer.visible = true;
                        }
                        if (slFeaturesHighlightLayer) {
                            slFeaturesHighlightLayer.visible = true;
                        }
                        if (slRoutingFeaturesLayer) {
                            slRoutingFeaturesLayer.visible = false;
                        }
                        if (slRoutingFeaturesHighlightLayer) {
                            slRoutingFeaturesHighlightLayer.visible = false;
                        }
                        if (slRoutingLayer) {
                            slRoutingLayer.visible = false;
                        }
                    }
                    else {
                        if (slRoutingFeaturesLayer) {
                            updateSlRoutingFeaturesLayer();
                        }
                        if (slRoutingFeaturesHighlightLayer) {
                            updateSlRoutingFeaturesHighlightLayer();
                        }
                        if (slRoutingLayer) {
                            updateSlRoutingLayer(Graphic);
                        }
                        if (slFeaturesLayer) {
                            slFeaturesLayer.visible = false;
                        }
                        if (slFeaturesHighlightLayer) {
                            slFeaturesHighlightLayer.visible = false;
                        }
                        if (slRoutingFeaturesLayer) {
                            slRoutingFeaturesLayer.visible = true;
                        }
                        if (slRoutingFeaturesHighlightLayer) {
                            slRoutingFeaturesHighlightLayer.visible = true;
                        }
                        if (slRoutingLayer) {
                            slRoutingLayer.visible = true;
                        }
                    }
                }
                if (prevProps.eventMapViewObject !== eventMapViewObject) {
                    handleMapViewChange(eventMapViewObject);
                }
                if (prevProps.customLayerGroups !== customLayerGroups) {
                    destroyMap();
                    initialize(Map, MapView, Expand, BasemapGallery, ScaleBar, SearchBar, Layer, FeatureLayer, watchUtils, webMercatorUtils, Point, GraphicsLayer, GroupLayer, urlUtils, EsriConfig);
                }
                if (prevProps.mapLayerTree !== mapLayerTree) {
                    updateMapLayerTree(mapView, Expand, mapLayerTree);
                }
                if (prevProps.customLayerDisplaySettings !== customLayerDisplaySettings) {
                    handleCustomLayerDisplaySettingsChange();
                }
                if (prevProps.hoverRouteData !== hoverRouteData) {
                    handleHoverRouteDataUpdate(Graphic, Polygon);
                }
            }
            return cleanup;
        });
    }, [
        icons,
        eventAddData,
        eventDeleteData,
        eventModifyData,
        routes,
        eventMapViewObject,
        customLayerGroups,
        mapHoverData,
        mapLayerTree,
        customLayerDisplaySettings,
        hoverRouteData,
    ]);
    const initialize = (Map, MapView, Expand, BasemapGallery, ScaleBar, SearchBar, Layer, FeatureLayer, watchUtils, webMercatorUtils, Point, GraphicsLayer, GroupLayer, urlUtils, EsriConfig) => {
        if (!!urlPrefix && !!proxyUrl) {
            urlUtils.addProxyRule({
                urlPrefix: urlPrefix,
                proxyUrl: proxyUrl,
            });
        }
        map = new Map({
            basemap: initialBaseMap
        });
        mapView = new MapView({
            container: mapRef.current,
            map: map,
            center: [initialLon, initialLat],
            zoom: initialZoom,
            ui: {
                components: ["attribution", "zoom", "compass"]
            },
            popup: {
                collapseEnabled: false,
                autoCloseEnabled: true,
                defaultPopupTemplateEnabled: true,
            }
        });
        esriLayers = [];
        if (sessionStorage[MapUtils.ESRI_TOKEN]) {
            const esriToken = JSON.parse(sessionStorage[MapUtils.ESRI_TOKEN]);
            EsriConfig.request.interceptors.push({
                urls: sessionStorage[MapUtils.ESRI_URL_PREFIX],
                before: function (params) {
                    params.requestOptions.query = params.requestOptions.query || {};
                    params.requestOptions.query.token = esriToken["token"];
                },
            });
            if (!sessionStorage[MapUtils.ESRI_TIMER_STARTED]) {
                sessionStorage.setItem(MapUtils.ESRI_TIMER_STARTED, "");
                const expiry = esriToken["expire"];
                setTimeout(() => {
                    sessionStorage.removeItem(MapUtils.ESRI_TIMER_STARTED);
                    location.reload();
                }, expiry - new Date().getTime());
            }
        }
        const handleDragSelectEvent = () => {
            mapView.on("drag", ["Shift"], (event) => {
                switch (event.action) {
                    case "start": {
                        setMapDragSelectProps(prevState => ({
                            isActive: true,
                            isCtrl: false,
                            left: event.x,
                            top: event.y,
                            width: 0,
                            height: 0,
                        }));
                        break;
                    }
                    case "update": {
                        setMapDragSelectProps(prevState => {
                            const mouseXFromOrigin = event.x - event.origin.x;
                            const mouseYFromOrigin = event.y - event.origin.y;
                            const width = Math.abs(mouseXFromOrigin);
                            const height = Math.abs(mouseYFromOrigin);
                            const left = mouseXFromOrigin > 0 ? event.origin.x : event.x;
                            const top = mouseYFromOrigin > 0 ? event.origin.y : event.y;
                            return {
                                isActive: true,
                                isCtrl: false,
                                left: left,
                                top: top,
                                width: width,
                                height: height,
                            };
                        });
                        break;
                    }
                    case "end": {
                        setMapDragSelectProps(prevState => {
                            handleDragSelect(false, prevState.left, prevState.top, prevState.width, prevState.height);
                            return {
                                isActive: false,
                                isCtrl: false,
                                left: 0,
                                top: 0,
                                width: 0,
                                height: 0,
                            };
                        });
                        break;
                    }
                }
                event.stopPropagation();
            });
            mapView.on("drag", ["Shift", "Control"], (event) => {
                switch (event.action) {
                    case "start": {
                        setMapDragSelectProps(prevState => ({
                            isActive: true,
                            isCtrl: true,
                            left: event.x,
                            top: event.y,
                            width: 0,
                            height: 0,
                        }));
                        break;
                    }
                    case "update": {
                        setMapDragSelectProps(prevState => {
                            const mouseXFromOrigin = event.x - event.origin.x;
                            const mouseYFromOrigin = event.y - event.origin.y;
                            const width = Math.abs(mouseXFromOrigin);
                            const height = Math.abs(mouseYFromOrigin);
                            const left = mouseXFromOrigin > 0 ? event.origin.x : event.x;
                            const top = mouseYFromOrigin > 0 ? event.origin.y : event.y;
                            return {
                                isActive: true,
                                isCtrl: true,
                                left: left,
                                top: top,
                                width: width,
                                height: height,
                            };
                        });
                        break;
                    }
                    case "end": {
                        setMapDragSelectProps(prevState => {
                            handleDragSelect(true, prevState.left, prevState.top, prevState.width, prevState.height);
                            return {
                                isActive: false,
                                isCtrl: false,
                                left: 0,
                                top: 0,
                                width: 0,
                                height: 0,
                            };
                        });
                        break;
                    }
                }
                event.stopPropagation();
            });
        };
        mapView.when(handleDragSelectEvent);
        const handleDragSelect = (isCtrl, left, top, width, height) => {
            const topLeftPoint = mapView.toMap({ x: left, y: top });
            const bottomRightPoint = mapView.toMap({ x: left + width, y: top + height });
            const ymax = topLeftPoint.latitude;
            const xmin = topLeftPoint.longitude;
            const ymin = bottomRightPoint.latitude;
            const xmax = bottomRightPoint.longitude;
            if (slFeaturesLayer.visible) {
                slFeaturesLayer.queryFeatures().then((rsp) => {
                    const selectedFeatures = rsp.features.filter(feature => {
                        const geometry = feature.geometry;
                        return getIsIntersecting(ymax, xmin, ymin, xmax, geometry.longitude, geometry.latitude);
                    });
                    const selectedMapIcons = selectedFeatures.map(feature => {
                        const internalId = feature.attributes.internalId;
                        const mapIcon = getMapIconByInternalId(internalId);
                        return mapIcon;
                    });
                    const multiSelectEvent = [];
                    selectedMapIcons.forEach(icon => {
                        icon.children.forEach(child => {
                            multiSelectEvent.push({
                                id: child.id,
                                legacyIdentifier: child.legacyIdentifier,
                                type: child.type,
                            });
                        });
                    });
                    if (isCtrl) {
                        handleMultiSelectCtrl(multiSelectEvent);
                    }
                    else {
                        handleMultiSelect(multiSelectEvent);
                    }
                });
            }
        };
        const getIsIntersecting = (ymax, xmin, ymin, xmax, x, y) => {
            if (ymax >= y && y >= ymin) {
                if (xmin <= xmax && xmin <= x && x <= xmax) {
                    return true;
                }
                else if (xmin > xmax && (xmin <= x || x <= xmax)) {
                    return true;
                }
            }
            return false;
        };
        mapView.on("click", (event) => {
            mapView.popup.visible = false;
            mapView.hitTest(event).then((rsp) => {
                const hitResults = rsp.results;
                hitResults.forEach(result => {
                    const sourceLayerName = result.graphic.sourceLayer.id;
                    switch (sourceLayerName) {
                        case ESRIMapConstants.SlFeaturesLayerName: {
                            const objectId = result.graphic.attributes.OBJECTID;
                            slFeaturesLayer.queryFeatures().then((rsp) => {
                                const features = rsp.features;
                                for (let i = 0; i < features.length; i++) {
                                    const feature = features[i];
                                    if (feature.attributes.OBJECTID === objectId) {
                                        const internalId = feature.attributes.internalId;
                                        const mapIcon = getMapIconByInternalId(internalId);
                                        mapView.popup.location = new Point({
                                            x: mapIcon.lon,
                                            y: mapIcon.lat
                                        });
                                        const element = document.createElement("div");
                                        const mapPopupRows = mapIcon.children.map(child => ({
                                            selected: child.selected,
                                            id: child.id,
                                            type: child.type,
                                            legacyIdentifier: child.legacyIdentifier,
                                            displayedIcon: child.displayedIcon,
                                            displayedIconColor: child.displayedIconColor,
                                            displayedIconBackgroundColor: child.displayedIconBackgroundColor,
                                            displayedText: child.displayedText
                                        }));
                                        render(React.createElement(MapPopup, { mapPopupRows: mapPopupRows, highlightColor: highlightColor, handleSelect: handleSelect, handleUnselect: handleUnselect }), element);
                                        mapView.popup.content = element;
                                        mapView.popup.title = mapIcon.iconPopupTitleString;
                                        mapView.popup.visible = true;
                                        break;
                                    }
                                }
                            });
                            break;
                        }
                        case ESRIMapConstants.SlRoutingFeaturesLayerName: {
                            const objectId = result.graphic.attributes.OBJECTID;
                            slRoutingFeaturesLayer.queryFeatures().then((rsp) => {
                                const features = rsp.features;
                                for (let i = 0; i < features.length; i++) {
                                    const feature = features[i];
                                    if (feature.attributes.OBJECTID === objectId) {
                                        const internalId = feature.attributes.internalId;
                                        const wayPoint = getRoutingMapIconsByInternalId(internalId);
                                        mapView.popup.location = new Point({
                                            x: wayPoint.lon,
                                            y: wayPoint.lat
                                        });
                                        const element = document.createElement("div");
                                        const mapPopupRow = {
                                            selected: wayPoint.selected,
                                            id: wayPoint.id,
                                            type: "order",
                                            legacyIdentifier: wayPoint.legacyIdentifier,
                                            displayedIcon: wayPoint.displayedIcon,
                                            displayedIconColor: wayPoint.displayedIconColor,
                                            displayedIconBackgroundColor: wayPoint.displayedIconBackgroundColor,
                                            displayedText: wayPoint.displayedText
                                        };
                                        const mapPopupRows = [mapPopupRow];
                                        render(React.createElement(MapPopup, { mapPopupRows: mapPopupRows, highlightColor: highlightColor, handleSelect: handleSelect, handleUnselect: handleUnselect }), element);
                                        mapView.popup.content = element;
                                        mapView.popup.title = wayPoint.iconPopupTitleString;
                                        mapView.popup.visible = true;
                                        break;
                                    }
                                }
                            });
                            break;
                        }
                        default: {
                        }
                    }
                });
            });
        });
        const getRoutingMapIconsByInternalId = (internalId) => {
            for (let routeIndex = 0; routeIndex < localRoutes.length; routeIndex++) {
                const mapRoute = localRoutes[routeIndex];
                for (let wayPointIndex = 0; wayPointIndex < mapRoute.wayPoints.length; wayPointIndex++) {
                    const wayPoint = mapRoute.wayPoints[wayPointIndex];
                    if (wayPoint.internalId === internalId) {
                        return wayPoint;
                    }
                }
            }
        };
        mapView.on("drag", (event) => {
            if (isDragDroppingFromMap) {
                event.stopPropagation();
            }
        });
        mapView.on("pointer-up", (event) => {
            isDragDroppingFromMap = false;
        });
        mapView.on("pointer-move", (event) => {
            mapView.hitTest(event).then((rsp) => {
                const hitResults = rsp.results;
                hitResults.forEach(result => {
                    const sourceLayerName = result.graphic.sourceLayer.id;
                    switch (sourceLayerName) {
                        case ESRIMapConstants.SlFeaturesLayerName: {
                            const objectId = result.graphic.attributes.OBJECTID;
                            slFeaturesLayer.queryFeatures().then((rsp) => {
                                const features = rsp.features;
                                for (let i = 0; i < features.length; i++) {
                                    const feature = features[i];
                                    if (feature.attributes.OBJECTID === objectId) {
                                        const internalId = feature.attributes.internalId;
                                        const mapIcon = getMapIconByInternalId(internalId);
                                        const isSingleOrder = mapIcon.orderIds.length === 1;
                                        if (isSingleOrder) {
                                            const orderId = mapIcon.orderIds[0];
                                            const clientRect = mapRef.current.getBoundingClientRect();
                                            if (orderId !== localMapHoverData.orderId) {
                                                handleHover({
                                                    orderId: orderId,
                                                    coordinates: {
                                                        x: event.x + clientRect.left,
                                                        y: event.y + clientRect.top,
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            if (localMapHoverData.isVisible) {
                                                handleHoverEnd();
                                            }
                                        }
                                        break;
                                    }
                                }
                            });
                            break;
                        }
                        default: {
                        }
                    }
                });
                if (hitResults.length === 0) {
                    if (localMapHoverData.isVisible) {
                        handleHoverEnd();
                    }
                }
            });
        });
        mapView.on("hold", (event) => {
            mapView.popup.visible = false;
            mapView.hitTest(event).then((rsp) => {
                const hitResults = rsp.results;
                hitResults.forEach(result => {
                    const sourceLayerName = result.graphic.sourceLayer.id;
                    switch (sourceLayerName) {
                        case ESRIMapConstants.SlFeaturesLayerName: {
                            const objectId = result.graphic.attributes.OBJECTID;
                            slFeaturesLayer.queryFeatures().then((rsp) => {
                                const features = rsp.features;
                                for (let i = 0; i < features.length; i++) {
                                    const feature = features[i];
                                    if (feature.attributes.OBJECTID === objectId) {
                                        const internalId = feature.attributes.internalId;
                                        const mapIcon = getMapIconByInternalId(internalId);
                                        const isSingleOrder = mapIcon.orderIds.length === 1;
                                        if (isSingleOrder) {
                                            isDragDroppingFromMap = true;
                                            const clientRect = mapRef.current.getBoundingClientRect();
                                            const orderNumber = mapIcon.iconPopupContentString.replace("Order: ", "");
                                            handleDragStart({
                                                orderIds: mapIcon.orderIds,
                                                orderNumbers: [orderNumber],
                                                coordinates: {
                                                    x: event.x + clientRect.left,
                                                    y: event.y + clientRect.top,
                                                }
                                            });
                                        }
                                        break;
                                    }
                                }
                            });
                            break;
                        }
                        default: {
                        }
                    }
                });
            });
        });
        watchUtils.whenTrue(mapView, "stationary", () => {
            if (mapView.extent) {
                const transformedExtent = webMercatorUtils.webMercatorToGeographic(mapView.extent);
                const extent = {
                    north: Math.min(transformedExtent.ymax.valueOf(), 85),
                    east: transformedExtent.xmax.valueOf(),
                    south: Math.max(transformedExtent.ymin.valueOf(), -85),
                    west: transformedExtent.xmin.valueOf()
                };
                if (handleMapExtentChange) {
                    handleMapExtentChange(extent);
                }
            }
        });
        slFeaturesLayer = getSlFeaturesLayer(FeatureLayer);
        slFeaturesHighlightLayer = getSlFeaturesHighlightLayer(FeatureLayer);
        slRoutingFeaturesLayer = getSlRoutingFeaturesLayer(FeatureLayer);
        slRoutingFeaturesHighlightLayer = getSlRoutingFeaturesHighlightLayer(FeatureLayer);
        slRoutingLayer = getSlRoutingLayer(GraphicsLayer);
        slRoutingFeaturesLayer.visible = false;
        slRoutingLayer.visible = false;
        slHoverRouteLayer = getSlHoverRouteLayer(GraphicsLayer);
        slHoverRouteRoutingFeaturesLayer = getSlHoverRouteRoutingFeaturesLayer(FeatureLayer);
        slHoverRouteFeaturesLayer = getSlHoverRouteFeaturesLayer(FeatureLayer);
        slHoverRouteLayer.visible = false;
        slHoverRouteRoutingFeaturesLayer.visible = false;
        slHoverRouteFeaturesLayer.visible = false;
        const defaultLayers = [slRoutingLayer, slRoutingFeaturesHighlightLayer, slRoutingFeaturesLayer, slFeaturesHighlightLayer, slFeaturesLayer, slHoverRouteLayer, slHoverRouteRoutingFeaturesLayer, slHoverRouteFeaturesLayer];
        const customLayers = [];
        const esriCustomLayers = [];
        customLayerGroups.forEach(group => {
            esriCustomLayers.push(...group.layers);
        });
        let layerCount = 0;
        esriCustomLayers.forEach((esriCustomLayer, index) => {
            layerCount++;
            const idString = layerCount.toString();
            const matchingEsriCustomSetting = customLayerDisplaySettings.find(setting => setting.url === esriCustomLayer.url);
            const url = `${esriCustomLayer.url}/${esriCustomLayer.id}`;
            const displayed = !matchingEsriCustomSetting.hiddenLayerNames.includes(esriCustomLayer.name);
            const disabled = matchingEsriCustomSetting.disabledLayerIds.includes(esriCustomLayer.id);
            const visible = displayed && !disabled;
            const type = esriCustomLayer.type;
            let customLayer = null;
            switch (type) {
                case "Feature Layer": {
                    customLayer = new FeatureLayer({ url: url, id: idString, popupEnabled: customLayerPopupsEnabled, });
                    break;
                }
                case "Group Layer": {
                    customLayer = new GroupLayer({ url: url, id: idString, popupEnabled: customLayerPopupsEnabled, });
                    break;
                }
                case "Graphics Layer": {
                    customLayer = new GraphicsLayer({ url: url, id: idString, popupEnabled: customLayerPopupsEnabled, });
                    break;
                }
                case "URL Base Layer": {
                    break;
                }
                case "Annotation Layer":
                case "Annotation SubLayer":
                case "Dimension Layer":
                    {
                        console.warn(`Ignoring unsupported ESRI Layer type: ${type}.`);
                        break;
                    }
                default: {
                    console.error(`Unknown ESRI Layer type: ${type}.`);
                }
            }
            if (customLayer) {
                customLayer.visible = visible;
                customLayers.push(customLayer);
                esriLayers[idString] = esriCustomLayer;
            }
        });
        const handleLoadError = () => {
        };
        mapView.when(handleLoadError);
        const layers = !customLayersOnTop ? [...customLayers, ...defaultLayers] : [...defaultLayers, ...customLayers];
        layers.forEach(layer => map.add(layer));
        addMapWidgets(BasemapGallery, Expand, ScaleBar, SearchBar, mapView, mapLayerTree);
    };
    const cleanup = () => {
        if (mapView) {
            mapView.container = null;
        }
    };
    const updateMapLayerTree = (mapView, Expand, mapLayerTree) => {
        addMapLayerSelect(mapView, Expand, mapLayerTree);
    };
    const handleCustomLayerDisplaySettingsChange = () => {
        map.layers.forEach((layer) => {
            if (layer.id !== ESRIMapConstants.SlFeaturesLayerName && layer.id !== ESRIMapConstants.SlFeaturesHighlightLayerName && layer.id !== ESRIMapConstants.SlRoutingFeaturesLayerName && layer.id !== ESRIMapConstants.SlRoutingFeaturesHighlightLayerName && layer.id !== ESRIMapConstants.SlRoutingLayerName && layer.id !== ESRIMapConstants.SlHoverRouteLayer && layer.id !== ESRIMapConstants.SlHoverRouteRoutingFeaturesLayer && layer.id !== ESRIMapConstants.SlHoverRouteFeaturesLayer) {
                const layerIdString = layer.id;
                const esriLayer = esriLayers[layerIdString];
                if (!!esriLayer) {
                    const matchingSettings = customLayerDisplaySettings.find(setting => setting.url === esriLayer.url);
                    const displayed = !matchingSettings.hiddenLayerNames.includes(esriLayer.name);
                    const disabled = matchingSettings.disabledLayerIds.includes(esriLayer.id);
                    layer.visible = displayed && !disabled;
                }
                else {
                    console.error(`Unable to find layer with id: ${layerIdString}.`);
                }
            }
        });
    };
    const handleHoverRouteDataUpdate = (Graphic, Polygon) => {
        updateSlHoverRouteFeaturesLayer();
        updateSlHoverRouteLayer(Graphic);
        updateSlHoverRouteRoutingFeaturesLayer();
        const showHoverRouteLayers = !!localHoverRouteData;
        const extentLatLon = [];
        if (localHoverRouteData.order) {
            extentLatLon.push([localHoverRouteData.order.lon, localHoverRouteData.order.lat]);
        }
        if (localHoverRouteData.fsr) {
            extentLatLon.push([localHoverRouteData.fsr.lon, localHoverRouteData.fsr.lat]);
        }
        if (localHoverRouteData.assignedOrders) {
            const latLons = localHoverRouteData.assignedOrders.map(o => [o.lon, o.lat]);
            extentLatLon.push(...latLons);
        }
        if (localHoverRouteData.route) {
            const startLatLon = [localHoverRouteData.route.start.lon, localHoverRouteData.route.start.lat];
            const endLatLon = [localHoverRouteData.route.end.lon, localHoverRouteData.route.end.lat];
            const wayPointLatLons = localHoverRouteData.route.wayPoints.map(w => [w.lon, w.lat]);
            const trackPointLatLons = localHoverRouteData.route.trackPoints.map(t => [t.lon, t.lat]);
            extentLatLon.push(startLatLon);
            extentLatLon.push(endLatLon);
            extentLatLon.push(...wayPointLatLons);
            extentLatLon.push(...trackPointLatLons);
        }
        if (extentLatLon.length > 0) {
            const polygon = new Polygon({
                rings: [extentLatLon],
                spatialReference: { wkid: 4326 }
            });
            mapView.goTo(polygon.extent, {
                duration: 1000
            });
        }
        slHoverRouteLayer.visible = showHoverRouteLayers;
        slHoverRouteRoutingFeaturesLayer.visible = showHoverRouteLayers;
        slHoverRouteFeaturesLayer.visible = showHoverRouteLayers;
        toggleNonHoverRouteLayers(!showHoverRouteLayers);
    };
    const toggleNonHoverRouteLayers = (visible) => {
        slFeaturesLayer.visible = visible;
        slFeaturesHighlightLayer.visible = visible;
        slRoutingLayer.visible = visible;
        slRoutingFeaturesLayer.visible = visible;
        slRoutingFeaturesHighlightLayer.visible = visible;
    };
    const addMapWidgets = (BasemapGallery, Expand, ScaleBar, SearchBar, mapView, mapLayerTree) => {
        addSearchBar(SearchBar, mapView);
        addBasemapGallary(BasemapGallery, mapView, Expand);
        addMapLayerSelect(mapView, Expand, mapLayerTree);
        addScaleBar(ScaleBar, mapView);
        addCoordinatesWidget(mapView);
    };
    const addMapLayerSelect = (mapView, Expand, mapLayerTree) => {
        const mapLayerSelectWidgetId = "map-layer-select";
        mapView.ui.remove(mapLayerSelectWidgetId);
        if (mapLayerTree.length > 0) {
            const mapLayerSelect = document.createElement("div");
            render(React.createElement(ThemeContextProvider, null,
                React.createElement(MapLayerTree, { data: mapLayerTree, hasSaveButton: customLayerSelectHasSaveButton, handleTreeDataChange: handleTreeDataSave })), mapLayerSelect);
            const mapLayerSelectExpand = new Expand({
                view: mapView,
                content: mapLayerSelect,
                id: mapLayerSelectWidgetId,
            });
            layerSelectWidget = mapLayerSelectExpand;
            mapView.ui.add(mapLayerSelectExpand, "top-right");
        }
    };
    const addSearchBar = (SearchBar, mapView) => {
        const searchWidget = new SearchBar({
            view: mapView,
        });
        mapView.ui.add(searchWidget, "top-right");
    };
    const addBasemapGallary = (BasemapGallery, mapView, Expand) => {
        const basemapGallery = new BasemapGallery({
            view: mapView,
            source: {
                portal: {
                    url: "https://www.arcgis.com",
                    useVectorBasemaps: true
                }
            },
            container: document.createElement("div")
        });
        const basemapGalleryExpand = new Expand({
            view: mapView,
            content: basemapGallery
        });
        basemapGallery.watch("activeBasemap", () => {
            const mobileSize = mapView.heightBreakpoint === "xsmall" || mapView.widthBreakpoint === "xsmall";
            if (mobileSize) {
                basemapGalleryExpand.collapse();
            }
        });
        mapView.ui.add(basemapGalleryExpand, "top-right");
    };
    const addScaleBar = (ScaleBar, mapView) => {
        const scaleBar = new ScaleBar({
            view: mapView,
            unit: scaleBarType
        });
        mapView.ui.add(scaleBar, "bottom-left");
    };
    const addCoordinatesWidget = (mapView) => {
        const coordsWidget = document.createElement("div");
        coordsWidget.id = "coordsWidget";
        coordsWidget.className = "esri-widget esri-component";
        coordsWidget.style.padding = "7px 15px 5px";
        coordsWidget.style.background = "#FFFFFFA6";
        mapView.ui.add(coordsWidget, "bottom-right");
        const updateCoords = (coords) => {
            const lat = parseFloat(coords.latitude.toFixed(3)) || 0;
            const latSuffix = lat === 0 ? " " : lat > 0 ? "N" : "S";
            const latString = `${Math.abs(lat).toFixed(3)}`;
            const lon = parseFloat(coords.longitude.toFixed(3)) || 0;
            const lonSuffix = lon === 0 ? " " : lon > 0 ? "E" : "W";
            const lonString = `${Math.abs(lon).toFixed(3)}`;
            coordsWidget.innerHTML = `${latString} ${latSuffix} ${lonString} ${lonSuffix}`;
        };
        mapView.on("pointer-move", (event) => {
            updateCoords(mapView.toMap({ x: event.x, y: event.y }));
        });
    };
    const onResize = () => {
        if (handleMapResize && mapRef.current) {
            handleMapResize({
                height: mapRef.current.offsetHeight,
                width: mapRef.current.offsetWidth
            });
        }
    };
    const updateSlRoutingFeaturesHighlightLayer = () => {
        slRoutingFeaturesHighlightLayer.queryObjectIds().then((oldObjectIds) => {
            const addFeatures = [];
            routes.forEach(route => {
                route.wayPoints.forEach(wayPoint => {
                    if (wayPoint.selected) {
                        addFeatures.push({
                            geometry: {
                                type: "point",
                                x: wayPoint.lon,
                                y: wayPoint.lat
                            }
                        });
                    }
                });
            });
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slRoutingFeaturesHighlightLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => { });
        });
    };
    const updateSlRoutingFeaturesLayer = () => {
        slRoutingFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slRoutingFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            routes.forEach(route => {
                if (!addedValues.includes(route.start.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: route.start.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: route.start.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
                route.wayPoints.forEach(wayPoint => {
                    if (!addedValues.includes(wayPoint.iconStyleName)) {
                        renderer.addUniqueValueInfo({
                            value: wayPoint.iconStyleName,
                            symbol: {
                                type: "picture-marker",
                                url: wayPoint.iconStyleString,
                                height: StyleUtils.getCssPixelString(iconSize),
                                width: StyleUtils.getCssPixelString(iconSize),
                                yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                            }
                        });
                    }
                });
                if (!addedValues.includes(route.end.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: route.end.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: route.end.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
            });
            const addFeatures = [];
            routes.forEach(route => {
                addFeatures.push({
                    attributes: {
                        name: route.start.iconStyleName,
                        internalId: -1
                    },
                    geometry: {
                        type: "point",
                        x: route.start.lon,
                        y: route.start.lat
                    }
                });
                route.wayPoints.forEach(wayPoint => {
                    addFeatures.push({
                        attributes: {
                            name: wayPoint.iconStyleName,
                            internalId: wayPoint.internalId
                        },
                        geometry: {
                            type: "point",
                            x: wayPoint.lon,
                            y: wayPoint.lat
                        }
                    });
                });
                addFeatures.push({
                    attributes: {
                        name: route.end.iconStyleName,
                        internalId: -1
                    },
                    geometry: {
                        type: "point",
                        x: route.end.lon,
                        y: route.end.lat
                    }
                });
            });
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slRoutingFeaturesLayer.renderer = renderer;
            slRoutingFeaturesLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => {
                if (prevProps.routes.length !== routes.length) {
                    slRoutingFeaturesLayer.queryExtent().then((extent) => {
                        mapView.goTo(extent, {
                            duration: 1000
                        });
                    });
                }
            });
        });
    };
    const updateSlRoutingLayer = (Graphic) => {
        slRoutingLayer.removeAll();
        routes.forEach(route => {
            const simpleLineSymbol = {
                type: "simple-line",
                color: route.color,
                width: "2px"
            };
            const pathArray = [
                [route.start.lon, route.start.lat],
                ...route.trackPoints.map(trackPoint => ([trackPoint.lon, trackPoint.lat])),
                [route.end.lon, route.end.lat]
            ];
            const polyline = {
                type: "polyline",
                paths: pathArray,
            };
            const polylineGraphic = new Graphic({
                geometry: polyline,
                symbol: simpleLineSymbol
            });
            slRoutingLayer.add(polylineGraphic);
        });
    };
    const updateSlFeaturesHighlightLayer = () => {
        slFeaturesHighlightLayer.queryObjectIds().then((oldObjectIds) => {
            const addFeatures = icons.filter(icon => icon.selected).map(icon => ({
                geometry: {
                    type: "point",
                    x: icon.lon,
                    y: icon.lat
                }
            }));
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slFeaturesHighlightLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => { });
        });
    };
    const updateSlFeaturesLayer = () => {
        slFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            icons.forEach(icon => {
                if (!addedValues.includes(icon.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: icon.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: icon.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
            });
            const addFeatures = icons.map(icon => ({
                attributes: {
                    name: icon.iconStyleName,
                    internalId: icon.internalId
                },
                geometry: {
                    type: "point",
                    x: icon.lon,
                    y: icon.lat
                }
            }));
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slFeaturesLayer.renderer = renderer;
            slFeaturesLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => {
                if (firstRender) {
                    handleFirstRender();
                    firstRender = false;
                }
            });
        });
    };
    const updateSlHoverRouteFeaturesLayer = () => {
        const hoverIcons = [];
        if (localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.assignedOrders) {
            hoverIcons.push(...localHoverRouteData.assignedOrders);
        }
        if (localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.fsr) {
            hoverIcons.push(localHoverRouteData.fsr);
        }
        if (localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.order) {
            hoverIcons.push(localHoverRouteData.order);
        }
        slHoverRouteFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slHoverRouteFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            hoverIcons.forEach(icon => {
                if (!addedValues.includes(icon.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: icon.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: icon.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
            });
            const addFeatures = hoverIcons.map(icon => ({
                attributes: {
                    name: icon.iconStyleName,
                    internalId: icon.internalId
                },
                geometry: {
                    type: "point",
                    x: icon.lon,
                    y: icon.lat
                }
            }));
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slHoverRouteFeaturesLayer.renderer = renderer;
            slHoverRouteFeaturesLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => {
            });
        });
    };
    const updateSlHoverRouteLayer = (Graphic) => {
        slHoverRouteLayer.removeAll();
        const hoverRoutes = (localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.route) ? [localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.route] : [];
        hoverRoutes.forEach(route => {
            const simpleLineSymbol = {
                type: "simple-line",
                color: route.color,
                width: "2px"
            };
            const pathArray = [
                [route.start.lon, route.start.lat],
                ...route.trackPoints.map(trackPoint => ([trackPoint.lon, trackPoint.lat])),
                [route.end.lon, route.end.lat]
            ];
            const polyline = {
                type: "polyline",
                paths: pathArray,
            };
            const polylineGraphic = new Graphic({
                geometry: polyline,
                symbol: simpleLineSymbol
            });
            slHoverRouteLayer.add(polylineGraphic);
        });
    };
    const updateSlHoverRouteRoutingFeaturesLayer = () => {
        const hoverRoutes = (localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.route) ? [localHoverRouteData === null || localHoverRouteData === void 0 ? void 0 : localHoverRouteData.route] : [];
        slHoverRouteRoutingFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slHoverRouteRoutingFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            hoverRoutes.forEach(route => {
                if (!addedValues.includes(route.start.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: route.start.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: route.start.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
                route.wayPoints.forEach(wayPoint => {
                    if (!addedValues.includes(wayPoint.iconStyleName)) {
                        renderer.addUniqueValueInfo({
                            value: wayPoint.iconStyleName,
                            symbol: {
                                type: "picture-marker",
                                url: wayPoint.iconStyleString,
                                height: StyleUtils.getCssPixelString(iconSize),
                                width: StyleUtils.getCssPixelString(iconSize),
                                yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                            }
                        });
                    }
                });
                if (!addedValues.includes(route.end.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: route.end.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: route.end.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
            });
            const addFeatures = [];
            hoverRoutes.forEach(route => {
                addFeatures.push({
                    attributes: {
                        name: route.start.iconStyleName,
                        internalId: -1
                    },
                    geometry: {
                        type: "point",
                        x: route.start.lon,
                        y: route.start.lat
                    }
                });
                route.wayPoints.forEach(wayPoint => {
                    addFeatures.push({
                        attributes: {
                            name: wayPoint.iconStyleName,
                            internalId: wayPoint.internalId
                        },
                        geometry: {
                            type: "point",
                            x: wayPoint.lon,
                            y: wayPoint.lat
                        }
                    });
                });
                addFeatures.push({
                    attributes: {
                        name: route.end.iconStyleName,
                        internalId: -1
                    },
                    geometry: {
                        type: "point",
                        x: route.end.lon,
                        y: route.end.lat
                    }
                });
            });
            const deleteFeatures = oldObjectIds.map(oldObjectId => ({ objectId: oldObjectId }));
            slHoverRouteRoutingFeaturesLayer.renderer = renderer;
            slHoverRouteRoutingFeaturesLayer.applyEdits({
                addFeatures: addFeatures,
                deleteFeatures: deleteFeatures
            }).then((rsp) => {
            });
        });
    };
    const updateSlFeaturesLayerAdd = () => {
        slFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            if (!addedValues.includes(eventAddData.mapIcon.iconStyleName)) {
                renderer.addUniqueValueInfo({
                    value: eventAddData.mapIcon.iconStyleName,
                    symbol: {
                        type: "picture-marker",
                        url: eventAddData.mapIcon.iconStyleString,
                        height: StyleUtils.getCssPixelString(iconSize),
                        width: StyleUtils.getCssPixelString(iconSize),
                        yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                    }
                });
            }
            const addFeatures = [{
                    attributes: {
                        name: eventAddData.mapIcon.iconStyleName,
                        internalId: eventAddData.mapIcon.internalId
                    },
                    geometry: {
                        type: "point",
                        x: eventAddData.mapIcon.lon,
                        y: eventAddData.mapIcon.lat
                    }
                }];
            slFeaturesLayer.renderer = renderer;
            slFeaturesLayer.applyEdits({
                addFeatures: addFeatures,
            }).then((rsp) => {
            });
        });
    };
    const updateSlFeaturesLayerModify = () => {
        slFeaturesLayer.queryFeatures().then((result) => {
            const renderer = slFeaturesLayer.renderer.clone();
            const addedValues = renderer.uniqueValueInfos.map(uniqueValueInfo => uniqueValueInfo.get("value"));
            const existingFeatures = result.features;
            let modifyInternalId = -1;
            icons.forEach((icon) => {
                switch (eventModifyData.type) {
                    case "fsr": {
                        if (icon.children.length === 1 && icon.fsrIds.length === 1) {
                            if (icon.fsrIds[0] === eventModifyData.id) {
                                modifyInternalId = icon.internalId;
                            }
                        }
                        break;
                    }
                    case "order": {
                        if (icon.children.length === 1 && icon.orderIds.length === 1) {
                            if (icon.fsrIds[0] === eventModifyData.id) {
                                modifyInternalId = icon.internalId;
                            }
                        }
                        break;
                    }
                }
            });
            if (modifyInternalId !== -1) {
                if (!addedValues.includes(eventModifyData.mapIcon.iconStyleName)) {
                    renderer.addUniqueValueInfo({
                        value: eventModifyData.mapIcon.iconStyleName,
                        symbol: {
                            type: "picture-marker",
                            url: eventModifyData.mapIcon.iconStyleString,
                            height: StyleUtils.getCssPixelString(iconSize),
                            width: StyleUtils.getCssPixelString(iconSize),
                            yoffset: StyleUtils.getCssPixelString(iconSize / 2)
                        }
                    });
                }
                const updateFeatures = [];
                existingFeatures.forEach((existingFeature) => {
                    if (existingFeature.attributes.internalId === modifyInternalId) {
                        updateFeatures.push({
                            attributes: {
                                name: eventModifyData.mapIcon.iconStyleName,
                                internalId: eventModifyData.mapIcon.internalId
                            },
                            geometry: {
                                type: "point",
                                x: eventModifyData.mapIcon.lon,
                                y: eventModifyData.mapIcon.lat
                            }
                        });
                    }
                });
                slFeaturesLayer.renderer = renderer;
                slFeaturesLayer.applyEdits({
                    updateFeatures: updateFeatures,
                }).then(rsp => {
                });
            }
        });
    };
    const updateSlFeaturesLayerDelete = () => {
        slFeaturesLayer.queryObjectIds().then((oldObjectIds) => {
            const renderer = slFeaturesLayer.renderer.clone();
            let deleteInternalId = -1;
            icons.forEach((icon) => {
                switch (eventDeleteData.type) {
                    case "fsr": {
                        if (icon.children.length === 1 && icon.fsrIds.length === 1) {
                            if (icon.fsrIds[0] === eventDeleteData.id) {
                                deleteInternalId = icon.internalId;
                            }
                        }
                        break;
                    }
                    case "order": {
                        if (icon.children.length === 1 && icon.orderIds.length === 1) {
                            if (icon.fsrIds[0] === eventDeleteData.id) {
                                deleteInternalId = icon.internalId;
                            }
                        }
                        break;
                    }
                }
            });
            const deleteFeatures = deleteInternalId !== -1 ? [{ objectId: deleteInternalId }] : [];
            slFeaturesLayer.renderer = renderer;
            slFeaturesLayer.applyEdits({
                addFeatures: [],
                deleteFeatures: deleteFeatures
            }).then((rsp) => {
            });
        });
    };
    const getSlRoutingFeaturesHighlightLayer = (FeatureLayer) => {
        return getHighlightLayer(FeatureLayer, ESRIMapConstants.SlRoutingFeaturesHighlightLayerName);
    };
    const getSlRoutingFeaturesLayer = (FeatureLayer) => {
        const fields = [
            {
                name: "OBJECTID",
                alias: "OBJECTID",
                type: "oid"
            },
            {
                name: "name",
                alias: "name",
                type: "string"
            },
            {
                name: "internalId",
                alias: "internalId",
                type: "integer"
            }
        ];
        const renderer = {
            type: "unique-value",
            field: "name",
            uniqueValueInfos: []
        };
        return new FeatureLayer({
            id: ESRIMapConstants.SlRoutingFeaturesLayerName,
            title: "",
            geometryType: "point",
            source: [],
            fields: fields,
            objectIdField: "OBJECTID",
            renderer: renderer,
            popupEnabled: false,
        });
    };
    const getSlHoverRouteRoutingFeaturesLayer = (FeatureLayer) => {
        const fields = [
            {
                name: "OBJECTID",
                alias: "OBJECTID",
                type: "oid"
            },
            {
                name: "name",
                alias: "name",
                type: "string"
            },
            {
                name: "internalId",
                alias: "internalId",
                type: "integer"
            }
        ];
        const renderer = {
            type: "unique-value",
            field: "name",
            uniqueValueInfos: []
        };
        return new FeatureLayer({
            id: ESRIMapConstants.SlHoverRouteRoutingFeaturesLayer,
            title: "",
            geometryType: "point",
            source: [],
            fields: fields,
            objectIdField: "OBJECTID",
            renderer: renderer,
            popupEnabled: false,
        });
    };
    const getSlRoutingLayer = (GraphicsLayer) => {
        return new GraphicsLayer({
            id: ESRIMapConstants.SlRoutingLayerName,
            graphics: [],
            popupEnabled: false,
        });
    };
    const getSlHoverRouteLayer = (GraphicsLayer) => {
        return new GraphicsLayer({
            id: ESRIMapConstants.SlHoverRouteLayer,
            graphics: [],
            popupEnabled: false,
        });
    };
    const getSlFeaturesHighlightLayer = (FeatureLayer) => {
        return getHighlightLayer(FeatureLayer, ESRIMapConstants.SlFeaturesHighlightLayerName);
    };
    const getHighlightLayer = (FeatureLayer, name) => {
        const fields = [
            {
                name: "OBJECTID",
                alias: "OBJECTID",
                type: "oid"
            }
        ];
        const highlightSize = iconSize * highlightSizeRatio;
        const highlightOutlineSize = Math.max(highlightSize * highlightOutlineSizeRatio, 1);
        const renderer = {
            type: "simple",
            symbol: {
                type: "simple-marker",
                style: "square",
                color: highlightColor,
                size: StyleUtils.getCssPixelString(highlightSize),
                outline: {
                    color: highlightOutlineColor,
                    width: StyleUtils.getCssPixelString(highlightOutlineSize)
                },
                yoffset: StyleUtils.getCssPixelString(highlightSize / 2)
            }
        };
        return new FeatureLayer({
            id: name,
            title: "",
            geometryType: "point",
            source: [],
            fields: fields,
            renderer: renderer,
            popupEnabled: false,
        });
    };
    const getSlFeaturesLayer = (FeatureLayer) => {
        const fields = [
            {
                name: "OBJECTID",
                alias: "OBJECTID",
                type: "oid"
            },
            {
                name: "name",
                alias: "name",
                type: "string"
            },
            {
                name: "internalId",
                alias: "internalId",
                type: "integer"
            }
        ];
        const renderer = {
            type: "unique-value",
            field: "name",
            uniqueValueInfos: []
        };
        return new FeatureLayer({
            id: ESRIMapConstants.SlFeaturesLayerName,
            title: "",
            geometryType: "point",
            source: [],
            fields: fields,
            objectIdField: "OBJECTID",
            renderer: renderer,
            popupEnabled: false,
        });
    };
    const getSlHoverRouteFeaturesLayer = (FeatureLayer) => {
        const fields = [
            {
                name: "OBJECTID",
                alias: "OBJECTID",
                type: "oid"
            },
            {
                name: "name",
                alias: "name",
                type: "string"
            },
            {
                name: "internalId",
                alias: "internalId",
                type: "integer"
            }
        ];
        const renderer = {
            type: "unique-value",
            field: "name",
            uniqueValueInfos: []
        };
        return new FeatureLayer({
            id: ESRIMapConstants.SlHoverRouteFeaturesLayer,
            title: "",
            geometryType: "point",
            source: [],
            fields: fields,
            objectIdField: "OBJECTID",
            renderer: renderer,
            popupEnabled: false,
        });
    };
    const handleMapViewChange = (event) => {
        const center = mapView.center;
        center.latitude = event.lat;
        center.longitude = event.lon;
        mapView.goTo({
            geometry: center,
            zoom: event.zoom
        }, {
            duration: 1000
        });
    };
    return (React.createElement("div", { style: { height: "100%", width: "100%" } },
        React.createElement(ReactResizeDetector, { handleWidth: true, handleHeight: true, refreshMode: "debounce", refreshRate: 500, onResize: onResize }),
        React.createElement(StyledESRIMap, { className: "esri-map", ref: mergeRefs([mapRef, dropRef]) }),
        React.createElement(MapDragSelect, Object.assign({}, mapDragSelectProps))));
};
export default ESRIMap;
