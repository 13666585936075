import React from "react";
export default class FieldForce extends React.Component {
    constructor(props) {
        super(props);
    }
    /**
     * I know this looks ugly but someone else can deal with
     * checking if a user is logged in and then
     * redirecting them based on preference
     */
    componentDidMount() {
        const auth = document.cookie
            .split("; ")
            .find((text) => text.startsWith("X-ServiceLink-Auth="));
        if (auth) {
            Promise.race([
                fetch('/sl3/api/usersettings', {
                    headers: {
                        auth
                    },
                    method: 'GET',
                    credentials: 'include'
                }),
                new Promise((_, reject) => {
                    setTimeout(() => reject(new Error('User Settings timed out')), 5000);
                }),
            ]
            // @ts-ignore
            ).then((res) => res.json())
                .then((json) => {
                const defaultGui = json.data[0].user_setting_object.defaultGui1;
                if (defaultGui === 'sl7') {
                    window.location.href = '/sl7/dispatch';
                }
                else {
                    window.location.href = '/sl3/d/dispatch';
                }
            })
                .catch((err) => {
                console.error(err);
                window.location.href = '/sl7/login';
            });
        }
        else {
            window.location.href = '/sl7/login';
        }
    }
    render() {
        return (React.createElement("div", null));
    }
}
