import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import TextInput from "../../Input/TextInput/TextInput";
import Icon from "../../Icon/Icon";
import PasswordInput from "../../Input/PasswordInput/PasswordInput";
import Spacer from "../../Spacer/Spacer";
import TextButton from "../../Button/TextButton/TextButton";
import Text from "../../Text/Text";
import Paragraph from "../../Paragraph/Paragraph";
import SelectInput from "../../Input/SelectInput/SelectInput";
export const Logo = styled.img `
  height: ${props => StyleUtils.getNormalizedPixelString(props.logoHeight)};
  width: auto;
`;
export const StyledLoginMenuInput = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
`;
export const InputWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => props.borderColor};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: solid;
`;
export const TwoFactorAuthenticationWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
`;
const LoginMenuInput = (props) => {
    const { isAskingForBU, activeDirectoryEnabled, activeDirectoryMode, errorMessage = "", message = "", businessUnitOptions = [], initialBusinessUnit, initialLogin, slAuthEnabled, plainAdLoginEnabled, handleLogin, handleADLogin, handleActiveDirectoryLogin, height = 300, width = 350, backgroundColor = "#F6F6F6", topSpacerHeight = 39, bottomSpacerHeight = 10, focusColor = "#FC970A", switchWrapperHeight = 16, switchSpacerHeight = 10, labelHeight = 16, labelFontSize = 12, labelFontColor = "#62757F", inputHeight = 28, inputWidth = 248, inputBorderWidth = 1, inputBorderRadius = 5, inputPlaceholderColor = "#757575", inputFontColor = "#000000", inputFontSize = 16, inputIconPadding = 5, inputIconSpacerWidth = 5, inputSpacerHeight = 10, inputUsernameIconName = "FsrTable-Fsr_SignedOn-StateIcon", inputUsernamePlaceholder = "Enter your username", inputPasswordIconName = "key", inputPasswordPlaceholder = "Enter your password", inputBusinessUnitIconName = "OrderTable-Order_OnSite-StateIcon", errorMessageSpacerHeight = 10, errorMessageHeight = 60, errorMessageWidth = 250, errorMessageFontSize = 12, errorMessageFontColor = "#D62C1B", buttonSpacerHeight = 10, buttonHeight = 28, buttonWidth = 250, buttonFontColor = "#FFFFFF", buttonFontSize = 16, buttonBackgroundColor = {
        normal: "#62757F",
        mouseOver: "#4E5D65",
        mouseDown: "#374248"
    }, buttonBorderRadius = 5, microsoftLoginImage = "/sl3/images/microsoft-sign-in.svg", servicelinkLoginImage = "/sl3/images/service-link-sign-in.svg", } = props;
    const servicelinkButtonStyle = {
        width: 215,
        height: 41,
        background: `url(${servicelinkLoginImage})`,
        padding: 10,
    };
    const microsoftButtonStyle = {
        width: 215,
        height: 41,
        background: `url(${microsoftLoginImage})`,
        padding: 10,
    };
    const [usernameValue, setUsernameValue] = useState(initialLogin);
    const [passwordValue, setPasswordValue] = useState("");
    const [businessUnitValue, setBusinessUnitValue] = useState(initialBusinessUnit);
    const [isUsernameFocus, setIsUsernameFocus] = useState(false);
    const [isPasswordFocus, setIsPasswordFocus] = useState(false);
    const [isBusinessUnitFocus, setIsBusinessUnitFocus] = useState(false);
    const [isUsernameInputError, setIsUsernameInputError] = useState(false);
    const [isPasswordInputError, setIsPasswordInputError] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState("");
    const [internalMessage, setInternalMessage] = useState("");
    const [ctrlPressed, setCtrlPressed] = useState(false);
    const usernameBorderColor = isUsernameInputError ? errorMessageFontColor : isUsernameFocus ? focusColor : inputPlaceholderColor;
    const passwordBorderColor = isPasswordInputError ? errorMessageFontColor : isPasswordFocus ? focusColor : inputPlaceholderColor;
    const businessUnitBorderColor = isBusinessUnitFocus ? focusColor : inputPlaceholderColor;
    const displayedErrorMessage = internalErrorMessage || errorMessage;
    const displayedNonErrorMessage = internalMessage || message;
    const displayedMessage = displayedErrorMessage || displayedNonErrorMessage;
    const isError = !!displayedErrorMessage;
    const displayedMessageColor = isError ? errorMessageFontColor : inputFontColor;
    const handleUsernameChange = (username) => {
        setUsernameValue(username);
    };
    const handlePasswordChange = (password) => {
        setPasswordValue(password);
    };
    const handleBusinessUnitChange = (businessUnit) => {
        setBusinessUnitValue(businessUnit);
    };
    const handleUsernameFocus = () => {
        setIsUsernameFocus(true);
    };
    const handlePasswordFocus = () => {
        setIsPasswordFocus(true);
    };
    const handleBusinessUnitFocus = () => {
        setIsBusinessUnitFocus(true);
    };
    const handleUsernameBlur = () => {
        setIsUsernameFocus(false);
    };
    const handlePasswordBlur = () => {
        setIsPasswordFocus(false);
    };
    const handleBusinessUnitBlur = () => {
        setIsBusinessUnitFocus(false);
    };
    const handleUsernameSubmit = (username) => {
        handleLoginButton(username, passwordValue, businessUnitValue);
    };
    const handlePasswordSubmit = (password) => {
        handleLoginButton(usernameValue, password, businessUnitValue);
    };
    const handleBusinessUnitSubmit = (businessUnit) => {
        handleLoginButton(usernameValue, passwordValue, businessUnit);
    };
    const handleLoginButtonClick = () => {
        handleLoginButton(usernameValue, passwordValue, businessUnitValue);
    };
    const handleADLoginButtonClick = () => {
        setInternalMessage("Signing in with microsoft...");
        handleADLoginButton(usernameValue, passwordValue, businessUnitValue);
    };
    const handleADLoginButton = (username, password, businessUnit) => {
        if (localStorage.getItem("winNum")) {
            localStorage.removeItem("winNum");
        }
        handleADLogin(username, password, businessUnit);
    };
    const checkLoginErrors = (username, password, businessUnit, hasWinNum) => {
        let newErrorMessage = "";
        if (hasWinNum) {
            newErrorMessage += "Already Logged In. \"Ctrl + Login\" to force";
        }
        if (!!username) {
            setIsUsernameInputError(false);
        }
        else {
            setIsUsernameInputError(true);
            if (newErrorMessage) {
                newErrorMessage += "\n\n";
            }
            newErrorMessage += "Please enter your username.";
        }
        if (!!password) {
            setIsPasswordInputError(false);
        }
        else {
            setIsPasswordInputError(true);
            if (newErrorMessage) {
                newErrorMessage += "\n\n";
            }
            newErrorMessage += "Please enter your password.";
        }
        setInternalErrorMessage(newErrorMessage);
        setUsernameValue(username);
        setPasswordValue(password);
        setBusinessUnitValue(businessUnit);
    };
    const handleLoginCases = (username, password, businessUnit) => {
        if (!!password && !!username && !!businessUnit) {
            handleLogin(username, password, businessUnit);
        }
        if (isAskingForBU) {
            handleLogin(null, null, businessUnit);
        }
        else {
            checkLoginErrors(username, password, businessUnit, false);
        }
    };
    const handleLoginButton = (username, password, businessUnit) => {
        if (localStorage.getItem("winNum")) {
            if (ctrlPressed) {
                handleLoginCases(username, password, businessUnit);
            }
            else {
                checkLoginErrors(username, password, businessUnit, true);
            }
        }
        else {
            handleLoginCases(username, password, businessUnit);
        }
    };
    const keyDownHandler = (e) => {
        if (e.key === "Control") {
            setCtrlPressed(true);
        }
    };
    const keyUpHandler = (e) => {
        if (e.key === "Control") {
            setCtrlPressed(false);
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        window.addEventListener('keyup', keyUpHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, []);
    const activeDirectorySpacerHeight = (topSpacerHeight + bottomSpacerHeight + 4 * labelHeight + switchWrapperHeight + switchSpacerHeight + 3 * inputHeight + 2 * inputSpacerHeight + buttonSpacerHeight + buttonHeight + errorMessageSpacerHeight + StyleUtils.getTopBottomPixelNumber(inputBorderWidth));
    return (React.createElement(StyledLoginMenuInput, { className: "login-menu-input", height: height, width: width, backgroundColor: backgroundColor }, isAskingForBU ? (React.createElement(React.Fragment, null,
        React.createElement(Spacer, { height: topSpacerHeight }),
        React.createElement(Spacer, { height: labelHeight * 3 + switchWrapperHeight + switchSpacerHeight + inputHeight * 2 + inputSpacerHeight }),
        businessUnitOptions.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { height: inputSpacerHeight }),
            React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "Business Unit", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
            React.createElement(InputWrapper, { className: "business-unit-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: businessUnitBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
                React.createElement(Icon, { iconName: inputBusinessUnitIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
                React.createElement(Spacer, { width: inputIconSpacerWidth }),
                React.createElement(SelectInput, { options: businessUnitOptions, initialValue: initialBusinessUnit, handleSubmit: handleBusinessUnitSubmit, handleChange: handleBusinessUnitChange, handleFocus: handleBusinessUnitFocus, handleBlur: handleBusinessUnitBlur, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })))) : (React.createElement(Spacer, { height: inputSpacerHeight + labelHeight + inputHeight + StyleUtils.getTopBottomPixelNumber(inputBorderWidth) })),
        React.createElement(Spacer, { height: errorMessageSpacerHeight }),
        React.createElement(Paragraph, { text: displayedMessage, height: errorMessageHeight, width: errorMessageWidth, backgroundColor: backgroundColor, fontSize: errorMessageFontSize, fontColor: displayedMessageColor }),
        React.createElement(Spacer, { height: buttonSpacerHeight }),
        React.createElement("button", { style: servicelinkButtonStyle, onClick: () => handleLoginButtonClick() }),
        React.createElement(Spacer, { height: bottomSpacerHeight }))) : (React.createElement(React.Fragment, null,
        slAuthEnabled ? (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { height: topSpacerHeight }),
            React.createElement(Spacer, { height: labelHeight + switchWrapperHeight + switchSpacerHeight }),
            React.createElement(Text, { className: "username-label", height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "User", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
            React.createElement(InputWrapper, { className: "username-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: usernameBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
                React.createElement(Icon, { iconName: inputUsernameIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
                React.createElement(Spacer, { width: inputIconSpacerWidth }),
                React.createElement(TextInput, { className: "username-input", initialValue: initialLogin, handleSubmit: handleUsernameSubmit, handleChange: handleUsernameChange, handleFocus: handleUsernameFocus, handleBlur: handleUsernameBlur, placeholder: inputUsernamePlaceholder, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, placeholderColor: inputPlaceholderColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })),
            React.createElement(Spacer, { height: inputSpacerHeight }),
            React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "Password", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
            React.createElement(InputWrapper, { className: "password-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: passwordBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
                React.createElement(Icon, { iconName: inputPasswordIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
                React.createElement(Spacer, { width: inputIconSpacerWidth }),
                React.createElement(PasswordInput, { className: "password-input", handleSubmit: handlePasswordSubmit, handleChange: handlePasswordChange, handleFocus: handlePasswordFocus, handleBlur: handlePasswordBlur, placeholder: inputPasswordPlaceholder, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, iconColor: inputPlaceholderColor, placeholderColor: inputPlaceholderColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })))) : (React.createElement(React.Fragment, null)),
        businessUnitOptions.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { height: inputSpacerHeight }),
            React.createElement(Text, { height: labelHeight, fontSize: labelFontSize, width: inputWidth, text: "Business Unit", horizontalAlignment: "left", fontWeight: "bold", backgroundColor: backgroundColor, fontColor: labelFontColor }),
            React.createElement(InputWrapper, { className: "business-unit-input-wrapper", height: inputHeight, width: inputWidth, borderWidth: inputBorderWidth, borderColor: businessUnitBorderColor, borderRadius: inputBorderRadius, backgroundColor: backgroundColor },
                React.createElement(Icon, { iconName: inputBusinessUnitIconName, color: inputPlaceholderColor, backgroundColor: backgroundColor, height: inputHeight - 2 * inputIconPadding, width: inputHeight - 2 * inputIconPadding, borderRadius: inputBorderRadius, padding: inputIconPadding }),
                React.createElement(Spacer, { width: inputIconSpacerWidth }),
                React.createElement(SelectInput, { className: "business-unit-select-input", options: businessUnitOptions, initialValue: initialBusinessUnit, handleSubmit: handleBusinessUnitSubmit, handleChange: handleBusinessUnitChange, handleFocus: handleBusinessUnitFocus, handleBlur: handleBusinessUnitBlur, height: inputHeight, width: inputWidth - inputHeight - inputIconSpacerWidth, backgroundColor: backgroundColor, fontColor: inputFontColor, fontSize: inputFontSize, borderWidth: 0, borderRadius: inputBorderRadius })))) : (React.createElement(Spacer, { height: inputSpacerHeight + labelHeight + inputHeight + StyleUtils.getTopBottomPixelNumber(inputBorderWidth) })),
        React.createElement(React.Fragment, null,
            React.createElement(Spacer, { height: errorMessageSpacerHeight }),
            React.createElement(Paragraph, { text: displayedMessage, height: errorMessageHeight, width: errorMessageWidth, backgroundColor: backgroundColor, fontSize: errorMessageFontSize, fontColor: displayedMessageColor }),
            React.createElement(Spacer, { height: buttonSpacerHeight })),
        activeDirectoryEnabled ?
            React.createElement(React.Fragment, null,
                slAuthEnabled ?
                    // ad + sl
                    React.createElement("button", { className: "service-link-login-button", style: servicelinkButtonStyle, onClick: () => handleLoginButtonClick() }) : React.createElement(React.Fragment, null),
                slAuthEnabled || !plainAdLoginEnabled ?
                    React.createElement("button", { className: "microsoft-login-button", style: microsoftButtonStyle, onClick: () => handleADLoginButtonClick() })
                    :
                        // only ad
                        React.createElement(TextButton, { handleClick: handleADLoginButtonClick, text: "Login", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius }))
            :
                // only sl
                React.createElement(TextButton, { handleClick: handleLoginButtonClick, text: "Login", height: buttonHeight, width: buttonWidth, color: buttonFontColor, fontWeight: "bold", backgroundColor: buttonBackgroundColor, fontSize: buttonFontSize, borderWidth: 0, borderRadius: buttonBorderRadius }),
        React.createElement(React.Fragment, null,
            React.createElement(Spacer, { height: bottomSpacerHeight }),
            React.createElement(Spacer, { height: buttonSpacerHeight }))))));
};
export default LoginMenuInput;
