import React, { useEffect, useState } from "react";
import PasswordChangePage from "../pages/PasswordChangePage";
import { PasswordChangePageContainerActionTypes } from "../../../state/containers/PasswordChangePageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const PasswordChangePageContainer = (props) => {
    const state = useSelector(store => store.passwordChange, shallowEqual);
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const history = useHistory();
    useEffect(() => {
        dispatch({
            type: PasswordChangePageContainerActionTypes.INITIALIZE
        });
        document.title = "Service-Link: Change password";
    }, []);
    useEffect(() => {
        if (state.shouldGoBack)
            setTimeout(handleGoBackInterceptor, 2000);
    }, [state.shouldGoBack]);
    const handleChangePassword = (oldPassword, newPassword) => {
        dispatch({
            type: PasswordChangePageContainerActionTypes.HANDLE_CHANGE_PASSWORD,
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    };
    const handleChangePasswordInterceptor = (interceptedOldPassword, interceptedNewPassword) => {
        setOldPassword(interceptedOldPassword);
        setNewPassword(interceptedNewPassword);
        handleChangePassword(interceptedOldPassword, interceptedNewPassword);
    };
    const handleGoBackInterceptor = history.goBack;
    return (React.createElement(PasswordChangePage, Object.assign({ handleChangePassword: handleChangePasswordInterceptor, handleGoBack: handleGoBackInterceptor }, state)));
};
export default PasswordChangePageContainer;
