import React from "react";
import TableHeaderCell from "./TableHeaderCell/TableHeaderCell";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { StyleUtils } from "../../../../../../../helper/Style/StyleUtils";
import styled from "styled-components";
const StyledTableHeaderRow = styled.div `
  width: max-content;
  height: ${props => StyleUtils.getCssPixelString(props.tableHeaderRowProps.height)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class TableHeaderRow extends React.Component {
    constructor(props) {
        super(props);
        this.reorderHandler = (result, provided) => {
            if (result.source === null ||
                result.source === undefined ||
                result.destination === null ||
                result.destination === undefined) {
                return;
            }
            const source = result.source.index;
            const destination = result.destination.index;
            this.props.tableHeaderRowActions.reorderHandler(source, destination);
        };
    }
    render() {
        const { tableHeaderRowData, tableHeaderRowProps, tableHeaderRowActions } = this.props;
        const { height } = tableHeaderRowProps;
        const { resizeHandler } = tableHeaderRowActions;
        return (React.createElement(DragDropContext, { onDragEnd: this.reorderHandler },
            React.createElement(Droppable, { droppableId: "table-header-cell-droppable", direction: "horizontal" }, (provided, snapshot) => (React.createElement(StyledTableHeaderRow, Object.assign({ className: "table-header-row", ref: provided.innerRef }, provided.droppableProps, this.props),
                tableHeaderRowData.map((cell, index) => (React.createElement(TableHeaderCell, { key: index, tableHeaderCellData: cell, height: height, dragDropIndex: index, tableHeaderCellActions: {
                        onResizeStartHandler: () => { },
                        onResizeStopHandler: resizeHandler
                    } }))),
                provided.placeholder)))));
    }
}
export default TableHeaderRow;
