import { InventoryState } from "../../state/api/WebSockets/Inventory/State";
import { Utils } from "../General/Utils";
export var InventoryStateService;
(function (InventoryStateService) {
    function init() {
        return new Promise((resolve, reject) => {
            InventoryState.globalSystemsRequest().then(function (serverStateTransitionsResponse) {
                const buId = parseInt(Utils.getCookieByName("BUID"));
                const stateTransitions = (serverStateTransitionsResponse.response);
                const matchingStateTransitions = stateTransitions.find(stateTransitions => stateTransitions.buId === buId);
                const matchingStateTransitionSystemName = matchingStateTransitions.system;
                InventoryState.globalSystemRequest(matchingStateTransitionSystemName).then(function (serverStateAttributesResponse) {
                    const stateAttributes = (serverStateAttributesResponse.response);
                    const stateTransitions = (matchingStateTransitions.rules.transitionRules);
                    const stateTransitionsAndAttributes = stateTransitions
                        .filter(stateTransition => stateTransition.state !== 0) //0 is the universal starting state and does not map to anything.
                        .map(stateTransition => {
                        const state = stateTransition.state;
                        const matchingStateAttribute = stateAttributes.find(stateAttribute => stateAttribute.state === state);
                        return {
                            id: matchingStateAttribute.id,
                            state: state,
                            stateName: matchingStateAttribute.stateName,
                            canTransitionTo: stateTransition.canTransitionTo
                        };
                    });
                    const inventoryState = {
                        system: matchingStateTransitionSystemName,
                        stateTransitions: stateTransitionsAndAttributes
                    };
                    resolve(inventoryState);
                });
            });
        });
    }
    InventoryStateService.init = init;
})(InventoryStateService || (InventoryStateService = {}));
