import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import SimpleIcon from "../SimpleIcon/SimpleIcon";
import { getTheme, TooltipHost } from "office-ui-fabric-react";
const StyledSimpleButton = styled.div `
  height: ${props => StyleUtils.getCssPixelString(!props.textProps && !props.iconProps
    ? ""
    : props.textProps
        ? props.textProps.height || props.iconProps.size
        : props.iconProps.size)};
  font-size: ${props => StyleUtils.getCssPixelString(!props.textProps && !props.iconProps
    ? ""
    : props.textProps
        ? props.textProps.height || props.iconProps.size
        : props.iconProps.size)};
  line-height: ${props => StyleUtils.getCssPixelString(!props.textProps && !props.iconProps
    ? ""
    : props.textProps
        ? props.textProps.height || props.iconProps.size
        : props.iconProps.size)};
  width: ${props => StyleUtils.getCssPixelString(!props.textProps && !props.iconProps
    ? ""
    : props.textProps
        ? props.textProps.width || props.iconProps.size
        : props.iconProps.size)};
  border-style: ${props => props.borderStyle || "solid"};
  border-width: ${props => StyleUtils.getCssPropertyFromArray(props.borderWidth || 0)};
  border-color: ${props => {
    let borderColor = props.normalOptionalProps
        ? props.normalOptionalProps.borderColor || props.palette.black
        : props.palette.black;
    if (props.isHovering) {
        borderColor = props.hoveringOptionalProps
            ? props.hoveringOptionalProps.borderColor || props.palette.black
            : props.palette.black;
    }
    if (props.isMouseDown) {
        borderColor = props.mouseDownOptionalProps
            ? props.mouseDownOptionalProps.borderColor || props.palette.black
            : props.palette.black;
    }
    return borderColor;
}};
  border-radius: ${props => StyleUtils.getCssPropertyFromArray(props.borderRadius || 0)};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.padding || 0)};
  margin: ${props => StyleUtils.getCssPropertyFromArray(props.margin || 0)};
  background-color: ${props => {
    let backgroundColor = props.normalOptionalProps
        ? props.normalOptionalProps.backgroundColor || props.backgroundColor
        : props.backgroundColor;
    if (props.isHovering) {
        backgroundColor = props.hoveringOptionalProps
            ? props.hoveringOptionalProps.backgroundColor || props.color
            : props.color;
        if (props.textProps) {
            backgroundColor = props.hoveringOptionalProps
                ? props.hoveringOptionalProps.backgroundColor || props.palette.themeLighter
                : props.palette.themeLighter;
        }
    }
    if (props.isMouseDown) {
        backgroundColor = props.mouseDownOptionalProps
            ? props.mouseDownOptionalProps.backgroundColor || props.color
            : props.color;
        if (props.textProps) {
            backgroundColor = props.mouseDownOptionalProps
                ? props.mouseDownOptionalProps.backgroundColor || props.palette.themePrimary
                : props.palette.themePrimary;
        }
    }
    return backgroundColor;
}};
  color: ${props => {
    let color = props.color;
    if (props.textProps) {
        color = props.normalOptionalProps ? props.normalOptionalProps.textColor || props.color : props.color;
        if (props.isHovering) {
            color = props.hoveringOptionalProps
                ? props.hoveringOptionalProps.textColor || props.palette.black
                : props.palette.black;
        }
        if (props.isMouseDown) {
            color = props.mouseDownOptionalProps
                ? props.mouseDownOptionalProps.textColor || props.palette.white
                : props.palette.white;
        }
    }
    return color;
}};
  text-align: ${props => (props.textProps ? props.textProps.textAlign || "center" : "center")};
  font-weight: ${props => (props.textProps ? props.textProps.fontWeight || "normal" : "normal")};
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  user-select: none;
`;
class SimpleButton extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleMouseEnter = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isHovering: true })));
        };
        this.handleMouseLeave = (event) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isHovering: false })));
        };
        this.handleMouseDown = (event) => {
            if (this.props.handleMouseDown) {
                this.props.handleMouseDown();
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: true })));
        };
        this.handleMouseUp = (event) => {
            if (this.props.handleMouseUp) {
                this.props.handleMouseUp();
            }
            if (this.simpleButton.current === event.currentTarget) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (this.props.buttonClick && this.state.isMouseDown) {
                this.props.buttonClick(event);
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: false })));
        };
        this.handleOutOfBoundsMouseUp = (event) => {
            if (this.props.handleMouseUp) {
                this.props.handleMouseUp();
            }
            if (this.simpleButton.current === event.currentTarget) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { isMouseDown: false })));
        };
        this.getIconColor = () => {
            const { color, backgroundColor, normalOptionalProps, hoveringOptionalProps, mouseDownOptionalProps } = this.props;
            let iconColor = normalOptionalProps ? normalOptionalProps.iconColor || color : color;
            if (this.state.isHovering) {
                iconColor = hoveringOptionalProps ? hoveringOptionalProps.iconColor || backgroundColor : backgroundColor;
            }
            if (this.state.isMouseDown) {
                iconColor = mouseDownOptionalProps ? mouseDownOptionalProps.iconColor || backgroundColor : backgroundColor;
            }
            return iconColor;
        };
        this.getSimpleButton = () => {
            const { iconProps, textProps, iconOffsetY, iconOffsetX } = this.props;
            const iconName = iconProps ? iconProps.iconName : "";
            const size = iconProps ? iconProps.size : "";
            const iconText = textProps ? textProps.iconText : "";
            const width = textProps ? textProps.width : "";
            const height = textProps ? textProps.height : "";
            if (iconText) {
                return (React.createElement(StyledSimpleButton, Object.assign({}, this.theme, this.props, this.state, { onMouseDown: this.handleMouseDown, onMouseUp: this.handleMouseUp, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave, ref: this.simpleButton }), iconText));
            }
            else {
                return (React.createElement(StyledSimpleButton, Object.assign({}, this.theme, this.props, this.state, { onMouseDown: this.handleMouseDown, onMouseUp: this.handleMouseUp, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave, ref: this.simpleButton }),
                    React.createElement(SimpleIcon, { iconName: iconName, color: this.getIconColor(), size: size, offsetY: iconOffsetY, offsetX: iconOffsetX })));
            }
        };
        this.state = {
            isHovering: false,
            isMouseDown: false
        };
        this.simpleButton = React.createRef();
    }
    componentDidMount() {
        document.addEventListener("mouseup", this.handleOutOfBoundsMouseUp);
    }
    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleOutOfBoundsMouseUp);
    }
    render() {
        const { toolTip } = this.props;
        if (!!toolTip) {
            return (React.createElement(TooltipHost, { content: toolTip, calloutProps: { gapSpace: 0 } }, this.getSimpleButton()));
        }
        else {
            return this.getSimpleButton();
        }
    }
}
export default SimpleButton;
