import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Autocomplete, TreeView } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import React from "react";
import { renderTreeItems } from "../helpers";
import Spacer from "../../../components/Spacer/Spacer";
const StyledPaneWrapper = styled.div `
  margin: 5px;
  flex-direction: column;
  display : flex;
`;
const root = {
    flexGrow: 1,
    width: 500,
    maxWidth: 800,
    fontSize: "14px"
};
export const useStyles = makeStyles({
    root: root,
});
export function DocumentationNavigation(props) {
    var _a;
    const { expandedNodeIds, selectedNodeIds, documentationTree, handleNodeSelectByTree, handleNodeSelectBySearch } = props;
    const classes = useStyles();
    const handleAutoCompleteOnChange = (e, node) => {
        if (node) {
            handleNodeSelectBySearch(e, node.id.toString());
        }
    };
    return React.createElement(StyledPaneWrapper, null,
        React.createElement(Autocomplete, { onChange: handleAutoCompleteOnChange, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "Search", variant: "standard" })), noOptionsText: React.createElement("div", null, "Documentation not found"), getOptionLabel: (treeNode) => treeNode.dataObj.displayName, options: ((_a = documentationTree === null || documentationTree === void 0 ? void 0 : documentationTree.rootNode) === null || _a === void 0 ? void 0 : _a.getDescendants()) || [] }),
        React.createElement(Spacer, { height: 5 }),
        React.createElement(TreeView, { className: classes.root, expanded: expandedNodeIds, selected: selectedNodeIds, defaultCollapseIcon: React.createElement(ExpandMore, null), defaultExpandIcon: React.createElement(ChevronRight, null), onNodeSelect: handleNodeSelectByTree },
            React.createElement(React.Fragment, null, documentationTree.rootNode ? renderTreeItems(documentationTree.rootNode) : null)));
}
