export var MapIconStyleType;
(function (MapIconStyleType) {
    MapIconStyleType["PATH"] = "path";
    MapIconStyleType["TEXT"] = "text";
    MapIconStyleType["RECT"] = "rect";
})(MapIconStyleType || (MapIconStyleType = {}));
export const RoutingMapIconColor = {
    Outline: "#000000",
    Start: "#FF6666",
    End: "#66CC66",
    Background: "#FFFFFF"
};
export const RoutingUniqueColors = [
    "#00bfff",
    "#000000",
    "#c0c0c0",
    "#2f4f4f",
    "#556b2f",
    "#a0522d",
    "#2e8b57",
    "#7f0000",
    "#006400",
    "#708090",
    "#808000",
    "#483d8b",
    "#bc8f8f",
    "#cd853f",
    "#4682b4",
    "#000080",
    "#9acd32",
    "#20b2aa",
    "#cd5c5c",
    "#32cd32",
    "#daa520",
    "#8fbc8f",
    "#8b008b",
    "#b03060",
    "#d2b48c",
    "#9932cc",
    "#ff0000",
    "#ff8c00",
    "#ffd700",
    "#6a5acd",
    "#c71585",
    "#0000cd",
    "#00ff00",
    "#00ff7f",
    "#e9967a",
    "#dc143c",
    "#00ffff",
    "#a020f0",
    "#adff2f",
    "#ff6347",
    "#da70d6",
    "#ff00ff",
    "#f0e68c",
    "#ffff54",
    "#6495ed",
    "#dda0dd",
    "#87ceeb",
    "#98fb98",
    "#7fffd4",
    "#ff69b4"
];
export const MapIconCommonProperties = {
    HighlightColor: "#FBE739",
    HighlightOutlineColor: "#000000"
};
export var OrderState;
(function (OrderState) {
    OrderState["RETURNED"] = "Returned";
    OrderState["PENDING"] = "Pending";
    OrderState["ASSIGNED"] = "Assigned";
    OrderState["DISPATCHED"] = "Dispatched";
    OrderState["EN_ROUTE"] = "EnRoute";
    OrderState["ON_SITE"] = "OnSite";
    OrderState["PARKED"] = "Parked";
    OrderState["COMPLETED"] = "Completed";
    OrderState["UNACKNOWLEDGED"] = "Unacknowledged";
    OrderState["CANCELLED"] = "Cancelled";
    OrderState["UNAPPROVED"] = "Unapproved";
    OrderState["MULTI_ASSIGNED"] = "MultiAssigned";
    OrderState["SUSPENDED"] = "Suspended";
    OrderState["ON_HOLD"] = "OnHold";
    OrderState["IN_ERROR"] = "InError";
})(OrderState || (OrderState = {}));
export const OrderIdToOrderState = {
    5: OrderState.RETURNED,
    10: OrderState.PENDING,
    20: OrderState.ASSIGNED,
    30: OrderState.DISPATCHED,
    40: OrderState.EN_ROUTE,
    50: OrderState.ON_SITE,
    60: OrderState.PARKED,
    70: OrderState.COMPLETED,
    80: OrderState.UNACKNOWLEDGED,
    90: OrderState.CANCELLED,
    100: OrderState.UNAPPROVED,
    110: OrderState.MULTI_ASSIGNED,
    160: OrderState.SUSPENDED,
    240: OrderState.ON_HOLD,
    1000: OrderState.IN_ERROR
};
export const OrderMapIconColor = {
    Returned: "#1EA09B",
    Pending: "#32BDDC",
    Assigned: "#6464FF",
    Dispatched: "#64E664",
    EnRoute: "#F0F064",
    OnSite: "#F0C864",
    Parked: "#DC966E",
    Completed: "#FFFFFF",
    Unacknowledged: "#FF3232",
    Cancelled: "#FFC8C8",
    Unapproved: "#FFFFC8",
    MultiAssigned: "#C887FA",
    Suspended: "#A0A0A0",
    OnHold: "#A06E46",
    InError: "#FF00FF",
    Emergency: "#FF3232",
    Mixed: "#90A4AE",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
export const OrderStateMapIconName = {
    Returned: "MapWindow-Order_Returned-StateIcon",
    Pending: "MapWindow-Order_Pending-StateIcon",
    Assigned: "MapWindow-Order_Assigned-StateIcon",
    Dispatched: "MapWindow-Order_Dispatched-StateIcon",
    EnRoute: "MapWindow-Order_EnRoute-StateIcon",
    OnSite: "MapWindow-Order_OnSite-StateIcon",
    Parked: "MapWindow-Order_Parked-StateIcon",
    Completed: "MapWindow-Order_Completed-StateIcon",
    Unacknowledged: "MapWindow-Order_Unacknowledged-StateIcon",
    Cancelled: "MapWindow-Order_Cancelled-StateIcon",
    Unapproved: "MapWindow-Order_Unapproved-StateIcon",
    MultiAssigned: "MapWindow-Order_MultiAssigned-StateIcon",
    Suspended: "MapWindow-Order_Suspended-StateIcon",
    OnHold: "MapWindow-Order_OnHold-StateIcon",
    InError: "MapWindow-Order_InError-StateIcon",
    Mixed: "MapWindow-Order_OnSite-StateIcon"
};
export const FsrState = {
    1: "SignedOff",
    2: "SignedOn",
    3: "Unavailable",
    4: "EnRoute",
    5: "OnSite",
    6: "TimerActive",
};
export const FsrMapIconColor = {
    SignedOff: "#FFFFFF",
    SignedOn: "#64E664",
    Unavailable: "#A06E46",
    EnRoute: "#F0F064",
    OnSite: "#F0C864",
    TimerActive: "#32BEDC",
    Mixed: "#90A4AE",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
export const FsrStateMapIconName = {
    SignedOff: "MapWindow-Fsr_SignedOff-StateIcon",
    SignedOn: "MapWindow-Fsr_SignedOn-StateIcon",
    Unavailable: "MapWindow-Unavailable-StateIcon",
    EnRoute: "MapWindow-Fsr_EnRoute-StateIcon",
    OnSite: "MapWindow-Fsr_OnSite-StateIcon",
    TimerActive: "MapWindow-Fsr_TimerActive-StateIcon",
    Mixed: "MapWindow-Fsr_SignedOn-StateIcon"
};
export const CrewState = {
    1: "SignedOff",
    2: "SignedOn",
    3: "Unavailable",
    4: "EnRoute",
    5: "OnSite",
    6: "TimerActive",
};
export const CrewMapIconColor = {
    SignedOff: "#FFFFFF",
    SignedOn: "#64E664",
    Unavailable: "#A06E46",
    EnRoute: "#F0F064",
    OnSite: "#F0C864",
    TimerActive: "#32BEDC",
    Mixed: "#90A4AE",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
export const VehicleType = {
    0: "UtilityVan",
    1: "DiggerDerrick",
    2: "BucketTruck",
    3: "PickupTruck",
    4: "Trailer",
    5: "ContainerVan",
    6: "Car",
};
export const VehicleStatus = {
    0: "NotConfigured",
    1: "NoGps",
    2: "Speeding",
    3: "MotionDetected",
    4: "Idling",
    5: "RapidAcceleration",
    6: "SuddenDeceleration",
    7: "HighRpm",
    8: "LowFuel",
    9: "BatteryVoltage",
    10: "CheckEngine",
    11: "IgnitionOn",
    12: "IgnitionOff",
    13: "PtoOn",
    14: "PtoOff",
    15: "Plugged",
    16: "Unplugged",
};
export const VehicleMapIconColor = {
    NotConfigured: "#A0A0A0",
    NoGps: "#FFFFFF",
    Speeding: "#E66464",
    MotionDetected: "#FFFFFF",
    Idling: "#1EA09B",
    RapidAcceleration: "#FFFFFF",
    SuddenDeceleration: "#FFFFFF",
    HighRpm: "#FFFFFF",
    LowFuel: "#F0F064",
    BatteryVoltage: "#F0F064",
    CheckEngine: "#F0F064",
    IgnitionOn: "#FFFFFF",
    IgnitionOff: "#FFFFFF",
    PtoOn: "#FFFFFF",
    PtoOff: "#FFFFFF",
    Plugged: "#FFFFFF",
    Unplugged: "#E66464",
    Mixed: "#90A4AE",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
export const VehicleTypeMapIconName = {
    UtilityVan: "MapWindow-Vehicle_UtilityVan-StateIcon",
    DiggerDerrick: "MapWindow-Vehicle_DiggerDerrick-StateIcon",
    BucketTruck: "MapWindow-Vehicle_BucketTruck-StateIcon",
    PickupTruck: "MapWindow-Vehicle_PickupTruck-StateIcon",
    Trailer: "MapWindow-Vehicle_Trailer-StateIcon",
    ContainerVan: "MapWindow-Vehicle_ContainerVan-StateIcon",
    Car: "MapWindow-Vehicle_Car-StateIcon",
    Mixed: "MapWindow-Vehicle_PickupTruck-StateIcon"
};
export const MixedMapIconColor = {
    Mixed: "#90A4AE",
    Emergency: "#FF3232",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
export const ErrorMapIconColor = {
    Error: "#FF00FF",
    bodyOutlineColor: "#C8C8C8",
    stateIconLightColor: "#FFFFFF",
    stateIconDarkColor: "#646464",
    multipleColor: "#333333",
    multipleBackgroundColor: "#B3B3B3"
};
