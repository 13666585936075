import React from "react";
import "./InventoryUnverifiedToQuarantinedScreen.css";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
import { Subject } from "rxjs";
import { interval } from "rxjs/observable/interval";
import { Utils } from "../../../../../../helper/General/Utils";
class InventoryUnverifiedToQuarantinedScreen extends React.Component {
    constructor(props) {
        super(props);
        this.inputChange = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let value = e.target.value;
            if (value === null) {
                value = "";
            }
            if (value === "") {
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { scanBarValue: value })));
            }
            else {
                this.onSearchStringChangeThrottled.next(value);
                this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedItems: prevState.originalSelectedItems, scanBarValue: value })));
            }
        };
        this.onSearchStringChangeThrottled = new Subject();
        this.state = {
            originalSelectedItems: Utils.deepClone(this.props.data.selectedItems),
            scanBarValue: "",
            buttonId: -1,
            selectedWarehouse: this.props.data.warehouses[0].uniqueId
        };
    }
    componentDidMount() {
        this.onSearchStringChangeThrottled
            .debounce(() => interval(500))
            .subscribe(string => {
            this.props.actions.inventoryUnverifiedToQuarantinedScreenUpdateSearch(string);
        });
    }
    render() {
        const mainWrapperStyle = {
            height: "100%",
            width: "100%",
            borderWidth: "2px",
            borderColor: "#333333",
            borderStyle: "solid",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            backgroundColor: "#E1E1E1"
        };
        const failureItemsReasonWrapperStyle = {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemContainerStyle = {
            height: "100%",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const itemTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const itemStyle = {
            height: "22px",
            fontSize: "19px",
            padding: StyleUtils.getCssPropertyFromArray([2, 11, 2, 11])
        };
        const failureReasonTitleStyle = {
            height: "30px",
            fontSize: "20px",
            color: "#333333",
            fontWeight: "bold",
            width: "calc(100%-22px)",
            padding: StyleUtils.getCssPropertyFromArray([0, 11, 10, 11]),
            backgroundColor: "#FFFFFF"
        };
        const failReasonContainerStyle = {
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start"
        };
        const scanBarStyle = {
            height: "30px",
            fontSize: "20px",
            width: "600px",
            padding: StyleUtils.getCssPropertyFromArray([5, 10, 5, 10]),
            textAlign: "left",
            margin: StyleUtils.getCssPropertyFromArray([0, 10, 5, 10])
        };
        const buttonStyle = {
            height: "40px",
            width: "200px",
            padding: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10]),
            color: "#333333",
            fontSize: "20px",
            textAlign: "center",
            margin: StyleUtils.getCssPropertyFromArray([5, 10, 10, 10])
        };
        const selectStyle = {
            height: "30px",
            width: "600px",
            fontSize: "15px",
            padding: StyleUtils.getCssPropertyFromArray([5, 10, 5, 10]),
            margin: StyleUtils.getCssPropertyFromArray([5, 10, 5, 10]),
            color: "#333333"
        };
        return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen", style: mainWrapperStyle },
            React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-reasons-wrapper", style: failureItemsReasonWrapperStyle },
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-items-container", style: itemContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-item-title text-unselectable", style: itemTitleStyle }, "Items"),
                    React.createElement("div", { style: {
                            height: "100%",
                            width: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden"
                        } }, this.state.scanBarValue === ""
                        ? this.state.originalSelectedItems.map((item, index) => {
                            const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                            return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-item text-unselectable", key: index, style: Object.assign(Object.assign({}, itemStyle), { backgroundColor }) }, item.attributes.flexNetId));
                        })
                        : this.props.data.selectedItems.map((item, index) => {
                            const backgroundColor = StyleUtils.tableGenerateAlternatingRowColor("#E1E1E1", index);
                            return (React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-item text-unselectable", key: index, style: Object.assign(Object.assign({}, itemStyle), { backgroundColor }) }, item.attributes.flexNetId));
                        }))),
                React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-container", style: failReasonContainerStyle },
                    React.createElement("div", { className: "inventory-in-test-to-unavailable-screen-failure-reasons-title text-unselectable", style: failureReasonTitleStyle }, "Scan Items"),
                    React.createElement("form", null,
                        React.createElement("input", { className: "search-bar-input text-unselectable", placeholder: "Scan Item Here", onChange: this.inputChange, value: this.state.scanBarValue, style: scanBarStyle })),
                    React.createElement("select", { value: this.state.selectedWarehouse, style: selectStyle, onChange: e => {
                            this.setState({ selectedWarehouse: parseInt(e.target.value) });
                        } }, this.props.data.warehouses.map(warehouse => (React.createElement("option", { value: warehouse.uniqueId }, warehouse.name)))),
                    React.createElement("button", { className: "text-unselectable", onMouseEnter: () => this.setState({ buttonId: 0 }), onMouseLeave: () => this.setState({ buttonId: -1 }), onClick: () => {
                            this.props.actions.inventoryUnverifiedToQuarantinedScreenProceed(this.props.data.selectedItems, this.state.selectedWarehouse);
                        }, style: Object.assign(Object.assign({}, buttonStyle), { backgroundColor: this.state.buttonId === 0 ? "#A2DEFF" : "#FFFFFF" }) }, "Verify Items")))));
    }
}
export default InventoryUnverifiedToQuarantinedScreen;
