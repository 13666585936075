import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Fsrs } from "../../../../state/api/REST/Fsrs/Fsrs";
import Window from "../Window/Window";
import { dispatchCloseCalendar, dispatchShowCalendar } from "../../../../state/containers/DispatchPageContainer/actions";
import { FsrCalendar } from "./FsrCalendar";
import { getFsrsCalendarEvents } from "../../../../state/services/Dispatch/service";
let zIndexGlobal = 100;
export const FsrCalendarWindow = ({ fsrId, dateOpened }) => {
    const dispatch = useDispatch();
    const closeHandler = () => dispatch(dispatchCloseCalendar(fsrId));
    //@ts-ignore
    const events = useSelector(state => state.dispatch.openWindows[fsrId].orders);
    let [fsr, setFsr] = useState(null);
    useEffect(() => {
        Fsrs
            .fsrGetRowsRequestFactory({ filter: JSON.stringify({ $and: [{ "FSR_ID": { $in: [fsrId.toString()] } }] }) })(1, 2)
            .then((resp) => resp.response[0])
            .then(setFsr);
    }, []);
    useEffect(() => {
        getFsrsCalendarEvents(fsrId, dateOpened).then(orders => dispatch(dispatchShowCalendar(fsrId, dateOpened, orders)));
    }, []);
    return fsr
        ? (React.createElement(Window, { zIndex: zIndexGlobal++, title: `${fsr.NAME}`, open: true, handleClose: closeHandler },
            React.createElement(FsrCalendar, { dateOpened: dateOpened, fsrId: fsrId, orders: events })))
        : React.createElement("div", null);
};
