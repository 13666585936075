import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../../../helper/Style/StyleUtils";
import Icon from "../../../../Icon/Icon";
import Text from "../../../../Text/Text";
import IconButton from "../../../../Button/IconButton/IconButton";
import Spacer from "../../../../Spacer/Spacer";
const StyledFilterRowTitle = styled.div `
  width: 100%;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  background-color: ${props => props.backgroundColor};
  border-radius: 5px 5px 0 0;
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const StyledIconTextWrapper = styled.div `
  display: flex;
  flex-direction: row;
  width: ${StyleUtils.getNormalizedPixelString(200)};
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
`;
const FilterRowTitle = (props) => {
    const { isExpanded, filterName, expandedFontColor = "#FFFFFF", expandedBackgroundColor = "#3F51B5", collapsedFontColor = "#111111", collapsedBackgroundColor = "#BBBBBB", handleClick, handleClearFilter } = props;
    const iconName = isExpanded ? "SL-Arrow-Down" : "SL-Arrow-Right";
    const backgroundColor = isExpanded ? expandedBackgroundColor : collapsedBackgroundColor;
    const fontColor = isExpanded ? expandedFontColor : collapsedFontColor;
    return (React.createElement(StyledFilterRowTitle, { className: "filter-row-title", backgroundColor: backgroundColor, onClick: handleClick, height: 22 },
        React.createElement(StyledIconTextWrapper, { height: 22 },
            React.createElement(Spacer, { width: 5 }),
            React.createElement(Icon, { iconName: iconName, height: 16, width: 16, margin: 3, color: fontColor }),
            React.createElement(Spacer, { width: 5 }),
            React.createElement(Text, { text: filterName, fontColor: fontColor, height: 22, width: 200, horizontalAlignment: "left" })),
        React.createElement(IconButton, { iconName: "cross", handleClick: handleClearFilter, color: collapsedFontColor, height: 16, width: 16, borderWidth: 1, margin: [2, 5, 2, 2], iconWidth: 10, iconHeight: 10, borderRadius: 2 })));
};
export default FilterRowTitle;
