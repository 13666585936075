import { DispatchPageContainerActionTypes, ModalTypes } from "./types";
import moment from "moment";
export const initialState = {
    orderFilterString: null,
    fsrFilterString: null,
    vehicleFilterString: null,
    selectedOrderIds: [],
    cachedOrders: [],
    selectedOrders: [],
    selectedFsrIds: [],
    openWindows: {},
    selectedVehicleIds: [],
    date: moment().startOf("day").toISOString(true),
    isDragging: false,
    fsrVehMode: "fsr",
    openModals: [],
    settings: {
        isRecommending: false
    },
    esriCustomLayerDisplaySettings: [],
    esriCustomLayerGroups: [],
    esriCustomLayerPopupsEnabled: true,
    mapExtent: {
        north: 0,
        east: 0,
        south: 0,
        west: 0
    },
    mapSize: {
        height: 0,
        width: 0
    },
    mapIcons: [],
    mapEventAddData: null,
    mapEventDeleteData: null,
    mapEventModifyData: null,
    mapRoutes: [],
    mapUrlPrefix: "",
    mapProxyUrl: "",
    mapIconSize: 0,
    mapMaxChildrenCount: 10,
    mapIsDragging: false,
    mapDragOrderIds: [],
    mapDragOrderNumbers: [],
    mapLayerTree: [],
    mapFinishedLoading: false,
    mapHoverRouteData: null,
    theme: null,
    mapView: null,
    filterSettings: null,
    mapHoverData: {
        coordinates: {
            x: 0,
            y: 0,
        },
        isVisible: false,
        data: "",
        orderId: 0,
    },
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DispatchPageContainerActionTypes.CLOSE_CALENDAR: {
            delete state.openWindows[action.windowId];
            return state;
        }
        case DispatchPageContainerActionTypes.SHOW_CALENDAR: {
            state.openWindows[action.fsrId] = Object.assign(Object.assign({}, action), { type: "calendar" });
            return state;
        }
        case DispatchPageContainerActionTypes.SET_DISPATCH_PAGE_CONTAINER_STATE: {
            return action.state;
        }
        case DispatchPageContainerActionTypes.SET_ORDER_FILTER_STRING: {
            return Object.assign(Object.assign({}, state), { orderFilterString: action.orderFilterString });
        }
        case DispatchPageContainerActionTypes.SET_FSR_FILTER_STRING: {
            return Object.assign(Object.assign({}, state), { fsrFilterString: action.fsrFilterString });
        }
        case DispatchPageContainerActionTypes.SET_VEHICLE_FILTER_STRING: {
            return Object.assign(Object.assign({}, state), { vehicleFilterString: action.vehicleFilterString });
        }
        case DispatchPageContainerActionTypes.SET_SELECTED_ORDER_IDS: {
            return Object.assign(Object.assign({}, state), { selectedOrderIds: [...action.selectedOrderIds] });
        }
        case DispatchPageContainerActionTypes.SET_SELECTED_ORDERS: {
            return Object.assign(Object.assign({}, state), { selectedOrders: [...action.selectedOrders] });
        }
        case DispatchPageContainerActionTypes.SET_CACHED_ORDERS: {
            return Object.assign(Object.assign({}, state), { cachedOrders: [...action.cachedOrders] });
        }
        case DispatchPageContainerActionTypes.SET_SELECTED_FSR_IDS: {
            return Object.assign(Object.assign({}, state), { selectedFsrIds: [...action.selectedFsrIds] });
        }
        case DispatchPageContainerActionTypes.SET_SELECTED_VEHICLE_IDS: {
            return Object.assign(Object.assign({}, state), { selectedVehicleIds: [...action.selectedVehicleIds] });
        }
        case DispatchPageContainerActionTypes.SET_DATE: {
            return Object.assign(Object.assign({}, state), { date: action.date });
        }
        case DispatchPageContainerActionTypes.SET_SETTINGS: {
            return Object.assign(Object.assign({}, state), { settings: Object.assign({}, action.settings) });
        }
        case DispatchPageContainerActionTypes.MODIFY_SETTINGS: {
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), action.settings) });
        }
        case DispatchPageContainerActionTypes.SET_IS_DRAGGING: {
            return Object.assign(Object.assign({}, state), { isDragging: action.isDragging });
        }
        case DispatchPageContainerActionTypes.SET_FSR_VEH_MODE: {
            return Object.assign(Object.assign({}, state), { fsrVehMode: action.fsrVehMode });
        }
        case DispatchPageContainerActionTypes.SET_MAP_EXTENT: {
            return Object.assign(Object.assign({}, state), { mapExtent: Object.assign({}, action.extent) });
        }
        case DispatchPageContainerActionTypes.SET_MAP_SIZE: {
            return Object.assign(Object.assign({}, state), { mapSize: Object.assign({}, action.size) });
        }
        case DispatchPageContainerActionTypes.SET_MAP_ICONS: {
            return Object.assign(Object.assign({}, state), { mapIcons: [...action.mapIcons] });
        }
        case DispatchPageContainerActionTypes.HANDLE_MAP_VIEW_MOVE_EVENT: {
            return Object.assign(Object.assign({}, state), { mapView: Object.assign({}, action.event) });
        }
        case DispatchPageContainerActionTypes.HANDLE_MAP_ADD_ROUTE_EVENT: {
            const mapRoute = state.mapRoutes.find(mapRoute => mapRoute.id === action.event.id && mapRoute.type === action.event.type);
            if (mapRoute) {
                return Object.assign({}, state);
            }
            else {
                //@ts-ignore
                //TODO: Remove when the openlayers react component is made
                if (window.mapSettings.type !== "ESRI") {
                    window.sl.handleOlMapUpdateRoutes(JSON.parse(JSON.stringify([...state.mapRoutes, action.event])));
                }
                return Object.assign(Object.assign({}, state), { mapRoutes: [...state.mapRoutes, action.event] });
            }
        }
        case DispatchPageContainerActionTypes.HANDLE_MAP_REMOVE_ROUTE_EVENT: {
            const mapRoutes = state.mapRoutes.filter(mapRoute => !(mapRoute.id === action.event.id && mapRoute.type === action.event.type)) || [];
            //@ts-ignore
            //TODO: Remove when the openlayers react component is made
            if (window.mapSettings.type !== "ESRI") {
                window.sl.handleOlMapUpdateRoutes(JSON.parse(JSON.stringify([...mapRoutes])));
            }
            return Object.assign(Object.assign({}, state), { mapRoutes: [...mapRoutes] });
        }
        case DispatchPageContainerActionTypes.SET_MAP_HOVER_ROUTE_DATA: {
            //@ts-ignore
            //TODO: Remove when the openlayers react component is made
            if (window.mapSettings.type !== "ESRI") {
                window.handleRouteHoverDataUpdate(action.mapHoverRouteData);
            }
            return Object.assign(Object.assign({}, state), { mapHoverRouteData: action.mapHoverRouteData });
        }
        case DispatchPageContainerActionTypes.SET_MAP_ICONS_AND_ROUTES: {
            return Object.assign(Object.assign({}, state), { mapIcons: [...action.mapIcons], mapRoutes: [...action.mapRoutes] });
        }
        case DispatchPageContainerActionTypes.SHOW_MODAL_DIALOG:
            const { message, title } = action;
            state.openModals.push({ type: ModalTypes.DIALOG, icons: [], open: true, title, message });
            return state;
        case DispatchPageContainerActionTypes.CLOSE_MODAL_DIALOG:
            state.openModals.pop();
            return state;
        case DispatchPageContainerActionTypes.SET_MAP_DRAG_DATA: {
            return Object.assign(Object.assign({}, state), { mapDragOrderIds: [...action.orderIds], mapDragOrderNumbers: [...action.orderNumbers], mapIsDragging: action.mapIsDragging });
        }
        case DispatchPageContainerActionTypes.SET_MAP_HOVER_DATA: {
            return Object.assign(Object.assign({}, state), { mapHoverData: Object.assign(Object.assign({}, state.mapHoverData), { coordinates: Object.assign(Object.assign({}, state.mapHoverData.coordinates), { x: action.mapHoverData.coordinates.x, y: action.mapHoverData.coordinates.y }), data: action.mapHoverData.data, isVisible: action.mapHoverData.isVisible, orderId: action.mapHoverData.orderId }) });
        }
        case DispatchPageContainerActionTypes.SET_MAP_LAYER_TREE: {
            return Object.assign(Object.assign({}, state), { mapLayerTree: action.mapLayerTree });
        }
        case DispatchPageContainerActionTypes.SET_MAP_LAYER_SETTINGS: {
            return Object.assign(Object.assign({}, state), { esriCustomLayerDisplaySettings: action.mapLayerSettings });
        }
        case DispatchPageContainerActionTypes.SET_MAP_FINISHED_LOADING: {
            return Object.assign(Object.assign({}, state), { mapFinishedLoading: action.mapFinishedLoading });
        }
        case DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_ADD: {
            return Object.assign(Object.assign({}, state), { mapEventAddData: action.data });
        }
        case DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_MODIFY: {
            return Object.assign(Object.assign({}, state), { mapEventModifyData: action.data });
        }
        case DispatchPageContainerActionTypes.UPDATE_EVENT_MAP_ICONS_DELETE: {
            return Object.assign(Object.assign({}, state), { mapEventDeleteData: action.data });
        }
        default:
            return state;
    }
};
export default reducer;
