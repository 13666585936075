import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryUtils;
(function (InventoryUtils) {
    InventoryUtils.UtilsServiceName = "utils";
    let UtilsActionName;
    (function (UtilsActionName) {
        UtilsActionName["USER_LOOKUP"] = "user_lookup";
        UtilsActionName["OWNER_LOOKUP"] = "owner_lookup";
    })(UtilsActionName = InventoryUtils.UtilsActionName || (InventoryUtils.UtilsActionName = {}));
    function userLookupRequest(userId) {
        const request = {
            service: InventoryUtils.UtilsServiceName,
            action: UtilsActionName.USER_LOOKUP,
            request: {
                userId: userId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryUtils.userLookupRequest = userLookupRequest;
    function ownerLookupRequest(userType, userId) {
        const request = {
            service: InventoryUtils.UtilsServiceName,
            action: UtilsActionName.OWNER_LOOKUP,
            request: {
                owner: `${userType}*${userId}`
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryUtils.ownerLookupRequest = ownerLookupRequest;
})(InventoryUtils || (InventoryUtils = {}));
