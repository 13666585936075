import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import camelcase from "camelcase";
import LogoBar from "../../components/LogoBar/LogoBar";
import Modal from "../../components/Modal/Modal";
import TreeUtil from "../../../../helper/General/tree/builder";
import "./styles.css";
import { getDocument, getSavePermissionsState, saveDocument } from "../../../../state/api/REST/Documentation/documentation";
import { ModalDialog } from "./ModalDialog";
import { createJsonTree, createLinkedArrayWithIds, nodeIdToPath } from "./helpers";
import { CreateDocumentationPage } from "./CreateDocumentationPage/CreateDocumentationPage";
import { DocumentationEditor } from "./DocumentationEditor/DocumentationEditor";
import { DocumentationNavigation } from "./DocumentationNavigation/DocumentationNavigation";
import { convertToHtml } from "./customMammoth/lib";
const StyledDocumentationPage = styled.div `
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 14px;
`;
const DocumentationPageWrapper = styled.div `
  flex-direction: row;
  display : flex;
`;
const theme = createMuiTheme({
    typography: {
        body1: { fontSize: "14px" },
        button: { fontSize: "14px" },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
const TABLE_OF_CONTENTS_FILENAME = "toc.json";
const DocumentationPage = (props) => {
    var _a, _b, _c, _d;
    const { logoBarLogoSrc = "/resources/images/comp.svg", themeColor = "#62757F", backgroundColor = "#FFFFFF", } = props;
    const [maxId, setMaxId] = useState(0);
    const [docTree, setDocTree] = useState({});
    const [selectedNodePath, setSelectedNodePath] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [isCreatePageOpen, setIsCreatePageOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isNavigateWithoutSaveOpen, setIsNavigateWithoutSaveOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [allowSave, setAllowSave] = useState(true);
    const [editorState, setEditorState] = useState("");
    const getDeepestSelection = () => selectedNodePath[selectedNodePath.length - 1];
    const getDeepestSelectionNodeData = () => { var _a; return (_a = getDeepestSelection()) === null || _a === void 0 ? void 0 : _a.dataObj; };
    const selectedNodeIds = useMemo(() => { var _a, _b; return [((_b = (_a = getDeepestSelection()) === null || _a === void 0 ? void 0 : _a.dataObj) === null || _b === void 0 ? void 0 : _b.id.toString()) || "1"]; }, [selectedNodePath]);
    useEffect(() => {
        checkPermissions();
        getDocument(TABLE_OF_CONTENTS_FILENAME).then(rsp => {
            const tree = rsp.data || {
                pageName: "main.html",
                displayName: "Documentation",
                children: []
            };
            const { nodes, maxId } = createLinkedArrayWithIds(tree);
            const buildTree = TreeUtil.buildTrees(nodes, { id: 'id', parentid: 'parentId' })[0];
            setDocTree(buildTree);
            setMaxId(maxId);
            loadDocumentIntoEditor(buildTree.rootNode.dataObj)
                .then(() => {
                setSelectedNodePath(nodeIdToPath(docTree, "1"));
            });
        });
    }, []);
    const handleCreate = (e) => setIsCreatePageOpen(!isCreatePageOpen);
    const handleDelete = (e) => setIsDeleteModalOpen(true);
    const handleNodeSelectBySearch = (e, selectedId) => {
        handleTreeNodeSelection(e, selectedId);
        setExpandedNodes(nodeIdToPath(docTree, selectedId).map(x => x.id.toString()));
    };
    const handleNodeSelectByTree = (e, selectedId) => {
        handleTreeNodeSelection(e, selectedId);
        const findPred = (x) => selectedId === x;
        const newExpandedNodes = expandedNodes.find(findPred)
            ? expandedNodes.filter(x => !findPred(x))
            : [...expandedNodes, selectedId];
        setExpandedNodes(newExpandedNodes);
    };
    const handleOkWithoutSaving = () => {
        setIsEdit(false);
        setIsNavigateWithoutSaveOpen(false);
    };
    const handleNoWithoutSaving = () => setIsNavigateWithoutSaveOpen(false);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(StyledDocumentationPage, { backgroundColor: backgroundColor },
            React.createElement(LogoBar, { logoSrc: logoBarLogoSrc, backgroundColor: themeColor }),
            React.createElement(DocumentationPageWrapper, null,
                React.createElement(DocumentationNavigation, { documentationTree: docTree, expandedNodeIds: expandedNodes, selectedNodeIds: selectedNodeIds, handleNodeSelectBySearch: handleNodeSelectBySearch, handleNodeSelectByTree: handleNodeSelectByTree }),
                React.createElement(DocumentationEditor, { key: getDeepestSelectionNodeData() ? ((_a = getDeepestSelectionNodeData()) === null || _a === void 0 ? void 0 : _a.pageName) + isEdit : "--Nothing--", isEdit: isEdit, allowSave: allowSave, allowDelete: ((_b = docTree === null || docTree === void 0 ? void 0 : docTree.rootNode) === null || _b === void 0 ? void 0 : _b.id) === ((_c = getDeepestSelection()) === null || _c === void 0 ? void 0 : _c.id), handleSave: handleEditChange, handleEdit: handleEditChange, handleDelete: handleDelete, handleCreate: handleCreate, editorState: editorState, handleEditorStateChange: setEditorState, handleDocxUpload: handleDocxUpload }),
                React.createElement(ModalDialog, { handleYes: handleOkWithoutSaving, handleNo: handleNoWithoutSaving, dialogMessage: "You are navigating away from an edit you haven't saved; Continue?", dialogTitle: "Do you want to discard your changes?", isOpen: isNavigateWithoutSaveOpen }),
                React.createElement(ModalDialog, { isOpen: isDeleteModalOpen, dialogTitle: "DeleteConfirm", dialogMessage: "Are you sure you want to delete this page?", handleYes: handleDeleteOfTreeNode, handleNo: () => setIsDeleteModalOpen(false) }),
                React.createElement(Modal, { open: isCreatePageOpen, title: "Page Create", handleClose: () => setIsCreatePageOpen(false) },
                    React.createElement(CreateDocumentationPage, { docTree: docTree, selectedNodeId: (_d = getDeepestSelection()) === null || _d === void 0 ? void 0 : _d.id, handlePublish: handlePublish }))))));
    function handleTreeNodeSelection(e, selectedId) {
        const nodeId = parseInt(selectedId);
        const node = docTree.getNodeById(nodeId).dataObj;
        if (isEdit) {
            setIsNavigateWithoutSaveOpen(true);
        }
        else {
            loadDocumentIntoEditor(node)
                .then(() => {
                setSelectedNodePath(nodeIdToPath(docTree, selectedId));
            });
        }
    }
    function handleDocxUpload(event) {
        let reader = new FileReader();
        reader.onload = async function (loadEvent) {
            let arrayBuffer = loadEvent.target.result;
            let id = 0;
            function mapMammothToDocTree(mammothNode) {
                id++;
                saveDocument(`${camelcase(mammothNode.name)}${id}.html`, mammothNode.html);
                if (mammothNode.children.length) {
                    return {
                        id: id,
                        displayName: mammothNode.name,
                        pageName: `${camelcase(mammothNode.name)}${id}.html`,
                        children: mammothNode.children.map(mapMammothToDocTree)
                    };
                }
                else {
                    return {
                        id: id,
                        displayName: mammothNode.name,
                        pageName: `${camelcase(mammothNode.name)}${id}.html`,
                    };
                }
            }
            let mammothResult = await convertToHtml({ arrayBuffer: arrayBuffer });
            let newDocTree = mapMammothToDocTree(mammothResult.value);
            setMaxId(id);
            console.log("new docTree", newDocTree);
            const nodes = createLinkedArrayWithIds(newDocTree).nodes;
            const buildTree = TreeUtil.buildTrees(nodes, { id: 'id', parentid: 'parentId' })[0];
            setDocTree(buildTree);
            loadDocumentIntoEditor(buildTree.rootNode.dataObj)
                .then(() => {
                setSelectedNodePath(nodeIdToPath(docTree, newDocTree.id.toString()));
                persistTableOfContentsToServer(buildTree.rootNode);
            });
        };
        reader.readAsArrayBuffer(event.currentTarget.files[0]);
    }
    async function handleDeleteOfTreeNode() {
        getDeepestSelection().removeParent();
        await persistTableOfContentsToServer(docTree.rootNode);
        setSelectedNodePath([docTree.rootNode]);
        setIsDeleteModalOpen(false);
    }
    function handlePublish(createPageHeader, parentId) {
        const newId = maxId + 1;
        docTree.createNode({
            id: newId,
            displayName: createPageHeader,
            pageName: `${camelcase(createPageHeader)}${newId}.html`,
            parentId
        });
        setMaxId(newId);
        setDocTree(docTree);
        setIsCreatePageOpen(!isCreatePageOpen);
    }
    function handleEditChange(e) {
        if (isEdit) {
            const treeNode = getDeepestSelectionNodeData();
            if (treeNode) {
                Promise.all([saveDocument(treeNode.pageName, editorState), persistTableOfContentsToServer(docTree.rootNode)])
                    .then(() => setIsEdit(!isEdit));
            }
        }
        else {
            setIsEdit(!isEdit);
        }
    }
    function persistTableOfContentsToServer(docRoot) {
        return saveDocument(TABLE_OF_CONTENTS_FILENAME, JSON.stringify(createJsonTree(docRoot)));
    }
    async function loadDocumentIntoEditor(tree) {
        const documentContents = await getDocument(tree.pageName);
        return setEditorState(documentContents.data);
    }
    async function checkPermissions() {
        const promise = await getSavePermissionsState();
        setAllowSave(promise.data);
    }
};
export default DocumentationPage;
