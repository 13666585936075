/**
 * Timeline body/grid
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectableGroup from "../../../../../../vendor/react-selectable-fast/src/SelectableGroup"
import {Grid} from "react-virtualized"


class TimelineBody extends Component {
  componentDidMount() {
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps) {
    const {props} = this;
    if (!props.shallowUpdateCheck) {
      return true;
    }

    // prettier-ignore
    const shallowChange = props.height !== nextProps.height
      || props.width !== nextProps.width
      || props.rowCount !== nextProps.rowCount;

    if (props.forceRedrawFunc) {
      return shallowChange || props.forceRedrawFunc(props, nextProps);
    }

    return shallowChange;
  }

  _onSectionRendered({rowStartIndex, rowStopIndex}) {
    const startIndex = rowStartIndex;
    const stopIndex = rowStopIndex;

    this.props.onRowsRendered({
      startIndex,
      stopIndex
    });
  }

  render() {
    const {width, columnWidth, height, rowHeight, rowCount} = this.props;
    const {grid_ref_callback, cellRenderer} = this.props;

    return (
      <SelectableGroup
        resetOnStart
        globalMouse
        ignoreList={['.rct9k-row']}
        onSelectionFinish={items => {
          window.selectItemsFromTableBridge(items.map(item => item.props.group));
        }}
      >
        <Grid
          id={"scheduler"}
          ref={grid_ref_callback}
          autoContainerWidth
          onSectionRendered={this._onSectionRendered.bind(this)}
          cellRenderer={cellRenderer}
          columnCount={2}
          columnWidth={columnWidth}
          height={height}
          rowCount={rowCount}
          rowHeight={rowHeight}
          width={width}
        />
      </SelectableGroup>
    );
  }
}

TimelineBody.propTypes = {
  width: PropTypes.number.isRequired,
  columnWidth: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  rowHeight: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  grid_ref_callback: PropTypes.func.isRequired,
  cellRenderer: PropTypes.func.isRequired,
  shallowUpdateCheck: PropTypes.bool,
  forceRedrawFunc: PropTypes.func
};

TimelineBody.defaultProps = {
  shallowUpdateCheck: false,
  forceRedrawFunc: null
};
export default TimelineBody;
