import Theme from "../../../theme/Theme";
import * as ReactDOM from "react-dom";
import ErrorMessageDetailsScreen from "./components/ErrorMessageDetailsScreen/ErrorMessageDetailsScreen";
import ImportLogsMenu from "./components/ImportLogsMenu/ImportLogsMenu";
import React from "react";
import MessageResenderScreen from "../../../components/MessageResenderScreen/MessageResenderScreen";
import ChildValidatorScreen from "../../../components/ChildValidator/ChildValidatorScreen";
const init = () => {
    (function ($) {
        $.fn.extend({
            buildErrorMessageDetailsScreen: function (orderId) {
                Theme.initializeTheme();
                return this.each(() => {
                    ReactDOM.render(React.createElement(ErrorMessageDetailsScreen, { orderId: orderId }), this[0]);
                });
            },
            destroyErrorMessageDetailsScreen: function () {
                return this.each(() => {
                    try {
                        ReactDOM.unmountComponentAtNode(this[0]);
                        // ReactDOM.unmountComponentAtNode(document.querySelector("#toolbar .buttonset"));
                    }
                    catch (e) { }
                });
            },
            buildMessageResenderScreen: function () {
                Theme.initializeTheme();
                return this.each(() => {
                    ReactDOM.render(React.createElement(MessageResenderScreen, null), this[0]);
                });
            },
            destroyMessageResenderScreen: function () {
                return this.each(() => {
                    try {
                        ReactDOM.unmountComponentAtNode(this[0]);
                    }
                    catch (e) { }
                });
            },
            buildChildValidatorScreen: function (orderNumber) {
                Theme.initializeTheme();
                return this.each(() => {
                    ReactDOM.render(React.createElement(ChildValidatorScreen, { orderNumber: orderNumber }), this[0]);
                });
            },
            destroyChildValidatorScreen: function () {
                return this.each(() => {
                    try {
                        ReactDOM.unmountComponentAtNode(this[0]);
                    }
                    catch (e) { }
                });
            },
            buildImportLogsScreen: function () {
                Theme.initializeTheme();
                return this.each(() => {
                    ReactDOM.render(React.createElement(ImportLogsMenu, null), this[0]);
                });
            },
            destroyImportLogsScreen: function () {
                return this.each(() => {
                    try {
                        ReactDOM.unmountComponentAtNode(this[0]);
                    }
                    catch (e) { }
                });
            }
        });
    })(jQuery);
};
export default init;
