import React from 'react';
import { noop } from './utils';
const SelectableGroupContext = React.createContext({
    selectable: {
        register(_) { },
        unregister(_) { },
        selectInList(_) { },
        selectAll: noop,
        clearSelection: noop,
        getScrolledContainer: () => null
    }
});
export default SelectableGroupContext;
