export var MapUtils;
(function (MapUtils) {
    MapUtils.getESRILayerTree = (esriLayerGroups, settings) => {
        const esriGroupsLayerTree = [];
        esriLayerGroups.forEach((esriLayerGroup, index) => {
            const url = esriLayerGroup.url;
            const urlSplit = url.split("/");
            const urlName = urlSplit.length > 2 ? urlSplit[urlSplit.length - 2] : "Unknown Layer";
            const setting = settings.find((setting) => setting.url === url);
            const esriLayerTree = [];
            esriLayerGroup.layers.forEach((esriLayer) => {
                const treeNode = MapUtils.getTreeNodeById(esriLayerTree, esriLayer.id, url);
                const subLayers = esriLayer.subLayers.map(subLayer => ({
                    url: url,
                    id: subLayer.id,
                    name: subLayer.name,
                    type: "",
                    description: "",
                    visible: false,
                    disabled: false,
                    subLayers: [],
                }));
                if (!!treeNode) {
                    treeNode.type = esriLayer.type;
                    treeNode.description = esriLayer.description;
                    if (!!setting) {
                        treeNode.visible = !setting.hiddenLayerNames.includes(esriLayer.name);
                        treeNode.disabled = setting.disabledLayerIds.includes(esriLayer.id);
                    }
                    else {
                        treeNode.visible = esriLayer.defaultVisibility;
                        treeNode.disabled = false;
                    }
                    treeNode.subLayers = subLayers;
                }
                else {
                    esriLayerTree.push({
                        url: url,
                        id: esriLayer.id,
                        name: esriLayer.name,
                        type: esriLayer.type,
                        description: esriLayer.description,
                        visible: !!setting ? !setting.hiddenLayerNames.includes(esriLayer.name) : esriLayer.defaultVisibility,
                        disabled: !!setting ? setting.disabledLayerIds.includes(esriLayer.id) : false,
                        subLayers: subLayers,
                    });
                }
            });
            esriGroupsLayerTree.push({
                url: url,
                id: -1,
                name: urlName,
                type: "URL Base Layer",
                description: "",
                visible: !!setting ? !setting.hiddenLayerNames.includes(null) : true,
                disabled: !!setting ? setting.disabledLayerIds.includes(-1) : false,
                subLayers: esriLayerTree,
            });
        });
        return esriGroupsLayerTree;
    };
    function* traverseESRITree(tree) {
        yield tree;
        for (let subLayer of tree.subLayers) {
            yield* traverseESRITree(subLayer);
        }
    }
    MapUtils.traverseESRITree = traverseESRITree;
    MapUtils.treeToFlatMap = (data) => {
        return data.flatMap(node => Array.from(traverseESRITree(node)));
    };
    MapUtils.setVisibleById = (treeState, id, visible, url) => {
        const treeFlatMap = MapUtils.treeToFlatMap(treeState);
        const matchingNode = treeFlatMap.find(node => node.id === id && node.url === url);
        if (!!matchingNode) {
            const matchingNodeFlatMap = MapUtils.treeToFlatMap([matchingNode]);
            matchingNodeFlatMap.forEach((node) => {
                if (node.id === id) {
                    node.visible = visible;
                }
                else {
                    node.disabled = !visible;
                }
            });
        }
        return treeState;
    };
    MapUtils.getTreeNodeById = (treeState, id, url) => {
        const treeFlatMap = MapUtils.treeToFlatMap(treeState);
        return treeFlatMap.find(node => node.id === id && node.url === url);
    };
    MapUtils.ESRI_TOKEN = "ESRI_TOKEN";
    MapUtils.ESRI_TIMER_STARTED = "ESRI_TIMER_STARTED";
    MapUtils.ESRI_URL_PREFIX = "ESRI_URL_PREFIX";
})(MapUtils || (MapUtils = {}));
