import { DragDropActionTypes } from "./types";
export const initialState = {
    isDragDropActive: false,
    item: null,
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DragDropActionTypes.BEGIN: {
            return Object.assign(Object.assign({}, state), { isDragDropActive: true, item: action.item });
        }
        case DragDropActionTypes.END: {
            return Object.assign(Object.assign({}, state), { isDragDropActive: false, item: null });
        }
        default: {
            return state;
        }
    }
};
export default reducer;
