import React, { useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from "@material-ui/styles";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import { createMuiTheme } from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import styled from "styled-components";
const getVariableColor = (color, value) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        return value ? color.on : color.off;
    }
};
const StyledSwitchInput = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const SwitchInput = (props) => {
    const { startingValue = false, label = { on: "On", off: "Off" }, name = "switch-input", height = 24, width = 100, backgroundColor = { on: "#8CBBE9", off: "#9F9F9F" }, color = { on: "#1975d2", off: "#FFFFFF" }, fontSize = 14, fontColor = "#000000", fontWeight = "normal", handleChange, } = props;
    const [value, setValue] = useState(startingValue);
    const SwitchComponent = withStyles({
        switchBase: {
            color: getVariableColor(color, false),
            "&$checked": {
                color: getVariableColor(color, true),
                "&:hover": {
                    backgroundColor: StyleUtils.getRgbaHex(getVariableColor(color, true), 0.1),
                },
            },
            "&$checked + $track": {
                backgroundColor: getVariableColor(backgroundColor, true),
            },
            "&:hover": {
                backgroundColor: StyleUtils.getRgbaHex(getVariableColor(color, false), 0.1),
            },
        },
        checked: {},
        track: {
            backgroundColor: getVariableColor(backgroundColor, false),
        },
        colorPrimary: {},
    })(Switch);
    const theme = createMuiTheme();
    theme.typography.body1 = {
        fontSize: StyleUtils.getNormalizedPixelString(fontSize),
        lineHeight: StyleUtils.getNormalizedPixelString(fontSize),
        fontWeight: fontWeight,
        color: fontColor,
    };
    const onChange = (event, checked) => {
        setValue(prevState => {
            if (handleChange) {
                handleChange(checked);
            }
            return checked;
        });
    };
    return (React.createElement(StyledSwitchInput, { className: "switch-input", height: height, width: width },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(FormControlLabel, { control: React.createElement(SwitchComponent, { checked: value, onChange: onChange, name: name, color: "primary", size: "small" }), label: getVariableColor(label, value) }))));
};
export default SwitchInput;
