import React from "react";
import styled from "styled-components";
const StyledMessageResenderFilter = styled.div `
    width: fit-content;
    height: 100%;
    padding: 5px 15px 5px 5px;
    float: left;
    overflow-y: scroll;
    overflow: auto;
`;
export default class MessageResenderFilter extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(StyledMessageResenderFilter, null, this.props.filters.map(filter => {
            return React.createElement("div", null,
                React.createElement("input", { type: "checkbox", onChange: () => this.props.handleCheckboxChange(filter) }),
                filter);
        })));
    }
}
