import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryState;
(function (InventoryState) {
    InventoryState.StateServiceName = "state";
    let StateActionName;
    (function (StateActionName) {
        StateActionName["INVENTORY"] = "inventory";
        StateActionName["INVENTORY_TRANSITION"] = "transition/inventory";
        StateActionName["INVENTORY_HISTORY"] = "inventory/history";
        StateActionName["GLOBAL_SYSTEM"] = "global/system";
        StateActionName["GLOBAL_SYSTEMS"] = "global/systems";
        StateActionName["GLOBAL_NEXT_STATES"] = "global/next_states";
    })(StateActionName = InventoryState.StateActionName || (InventoryState.StateActionName = {}));
    function inventoryRequestById(targetId) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY,
            request: {
                usePath: false,
                targetId: targetId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryRequestById = inventoryRequestById;
    function inventoryRequestByPath(itemId, containerPath) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY,
            request: {
                usePath: false,
                itemId: itemId,
                containerPath: containerPath
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryRequestByPath = inventoryRequestByPath;
    function inventoryTransitionRequestById(targetId, comment, stateObject) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY_TRANSITION,
            request: {
                usePath: false,
                targetId: targetId,
                comment: comment,
                state: stateObject
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryTransitionRequestById = inventoryTransitionRequestById;
    function inventoryTransitionRequestByPath(itemId, containerPath, comment, stateObject) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY_TRANSITION,
            request: {
                usePath: false,
                itemId: itemId,
                containerPath: containerPath,
                comment: comment,
                state: stateObject
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryTransitionRequestByPath = inventoryTransitionRequestByPath;
    function inventoryHistoryRequestById(targetId) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY_HISTORY,
            request: {
                usePath: false,
                targetId: targetId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryHistoryRequestById = inventoryHistoryRequestById;
    function inventoryHistoryRequestByPath(itemId, containerPath) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.INVENTORY_HISTORY,
            request: {
                usePath: false,
                itemId: itemId,
                containerPath: containerPath
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.inventoryHistoryRequestByPath = inventoryHistoryRequestByPath;
    function globalSystemRequest(systemName) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.GLOBAL_SYSTEM,
            request: {
                name: systemName
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.globalSystemRequest = globalSystemRequest;
    function globalSystemsRequest() {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.GLOBAL_SYSTEMS,
            request: {}
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.globalSystemsRequest = globalSystemsRequest;
    function globalNextStatesRequest(stateId) {
        const request = {
            service: InventoryState.StateServiceName,
            action: StateActionName.GLOBAL_NEXT_STATES,
            request: {
                stateId: stateId
            }
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryState.globalNextStatesRequest = globalNextStatesRequest;
})(InventoryState || (InventoryState = {}));
