import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import SimpleButton from "../SimpleButton/SimpleButton";
const StyledTitle = styled.div `
  height: 1.6rem;
  width: ${props => (!!props.handleClose ? "calc(100% + 1.4rem)" : "100%")};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledTitleTextWrapper = styled.div `
  height: 1.4rem;
  width: ${props => (!!props.handleClose ? "calc(100% - 2rem)" : "100%")};
  color: ${props => props.palette.white};
  padding: 0 0.3rem;
  border-style: solid;
  border-color: ${props => props.palette.neutralPrimaryAlt};
  border-width: 0.1rem;
  background-color: ${props => props.palette.themePrimary};
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
const StyledTitleCloseButtonWrapper = styled.div `
  height: 1.4rem;
  width: 1.4rem;
  margin: 0.1rem 0;
  position: relative;
  left: -2.5rem;
`;
class Title extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleClose = () => {
            if (this.props.handleClose) {
                this.props.handleClose();
            }
        };
    }
    render() {
        const { titleDisplayedText, handleClose } = this.props;
        return (React.createElement(StyledTitle, Object.assign({ className: "title" }, this.theme, this.props),
            React.createElement(StyledTitleTextWrapper, Object.assign({ className: "title-text-wrapper" }, this.theme, this.props), titleDisplayedText || ""),
            !!handleClose ? (React.createElement(StyledTitleCloseButtonWrapper, { className: "title-close-button-wrapper" },
                React.createElement(SimpleButton, { className: "title-close-button", color: this.theme.palette.white, backgroundColor: this.theme.palette.themePrimary, iconProps: { iconName: Title.closeButtonName, size: "1.2rem" }, padding: "0.1rem", borderRadius: "0.2rem", buttonClick: this.handleClose, iconOffsetY: "-0.1rem" }))) : null));
    }
}
Title.closeButtonName = "cross";
export default Title;
