import { WebSocketService } from "../../../../helper/WebSocket/WebSocketService";
export var InventoryLinkHistory;
(function (InventoryLinkHistory) {
    InventoryLinkHistory.LinkHistoryServiceName = "link_history";
    let LinkHistoryActionName;
    (function (LinkHistoryActionName) {
        LinkHistoryActionName["CONTAINER_QUERY"] = "container/query";
        LinkHistoryActionName["INVENTORY_QUERY"] = "inventory/query";
    })(LinkHistoryActionName = InventoryLinkHistory.LinkHistoryActionName || (InventoryLinkHistory.LinkHistoryActionName = {}));
    function containerQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryLinkHistory.LinkHistoryServiceName,
            action: LinkHistoryActionName.CONTAINER_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinkHistory.containerQueryRequest = containerQueryRequest;
    function inventoryQueryRequest(startIndex, endIndex, orderBy, query) {
        const queryRequest = {
            start: startIndex,
            end: endIndex,
            orderBy: orderBy,
            query: query
        };
        const request = {
            service: InventoryLinkHistory.LinkHistoryServiceName,
            action: LinkHistoryActionName.INVENTORY_QUERY,
            request: queryRequest
        };
        return WebSocketService.sendRequest(request);
    }
    InventoryLinkHistory.inventoryQueryRequest = inventoryQueryRequest;
})(InventoryLinkHistory || (InventoryLinkHistory = {}));
