import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleCheckbox from "../../../../../../../components/SimpleCheckbox/SimpleCheckbox";
import SimpleButton from "../../../../../../../components/SimpleButton/SimpleButton";
const StyledErrorMessageDetailsMessageRow = styled.div `
  height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #eeeeee;
  border-bottom: 1px solid white;
`;
class ErrorMessageDetailsMessageRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleClick = (event) => {
            this.props.handleMessageRowClick({
                messageId: this.props.orderErrorDetails.messageId
            });
        };
    }
    render() {
        // const { orderErrorDetails } = this.props;
        // const { func, userName, creationTimeStamp, causedError } = orderErrorDetails;
        const displayedText = `
            ${this.props.orderErrorDetails.func}, 
            ${this.props.orderErrorDetails.userName}, 
            ${this.props.orderErrorDetails.creationTimeStamp}`;
        return (React.createElement(StyledErrorMessageDetailsMessageRow, { className: "error-message-details-message-row" },
            React.createElement(SimpleCheckbox, { id: this.props.orderErrorDetails.messageId, label: "Skip? ", wrapperPadding: "0.1rem", onCheckboxChangeHandler: this.props.handleCheckboxChange }),
            React.createElement(SimpleButton, { className: "error-message-details-message-row", textProps: {
                    iconText: displayedText,
                    height: "1.2rem",
                    width: "calc(100% - 1rem)",
                    textAlign: "left",
                    fontWeight: "normal"
                }, padding: "0.4rem 0.5rem", color: this.props.orderErrorDetails.causedError ? this.theme.palette.red : this.theme.palette.black, backgroundColor: "#EEEEEE", buttonClick: this.handleClick })));
    }
}
export default ErrorMessageDetailsMessageRow;
