import React, { useState } from "react";
import { Utils } from "../../../helper/General/Utils";
import { Slider } from "@material-ui/core";
import moment from "moment";
export const DateSlider = props => {
    let [dateRange, setDateRange] = useState(props.dateRange);
    const hourStart = 0;
    const hourEnd = 24;
    return React.createElement(Slider, { min: hourStart, max: hourEnd, value: dateRange, 
        // marks={marks}
        valueLabelDisplay: "auto", "aria-labelledby": "range-slider", valueLabelFormat: formatValue, onChange: handleChange, onChangeCommitted: Utils.compose(props.updateDate), getAriaValueText: formatValue });
    function handleChange(event, range) {
        setDateRange(range);
    }
    function formatValue(hourIndexFromToday) {
        return moment()
            .startOf('day')
            .add(hourIndexFromToday, 'hour')
            .format('LT');
    }
};
