import React, { useContext, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DispatchPageContainerActionTypes } from "../../../state/containers/DispatchPageContainer/types";
import ESRIMap from "../components/Map/ESRIMap/ESRIMap";
import { ThemeContext } from "../../theme/ThemeContextProvider";
import $ from "jquery";
import { dateValueToLegacyDateString } from "../hooks/legacy/Dispatch";
import { Orders } from "../../../state/api/REST/Orders/Orders";
import { Fsrs } from "../../../state/api/REST/Fsrs/Fsrs";
import LoadingPageTransparent from "../pages/LoadingPageTransparent";
const ESRIMapContainer = (props) => {
    const state = useSelector(store => store.dispatch, shallowEqual);
    const dispatch = useDispatch();
    const { theme: contextTheme } = useContext(ThemeContext);
    const theme = contextTheme;
    useEffect(() => {
        dispatch({
            type: DispatchPageContainerActionTypes.INITIALIZE,
            theme: theme
        });
    }, []);
    const handleMapExtentChange = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_EXTENT_CHANGE_EVENT,
            event: event
        });
    };
    const handleMapResize = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_SIZE_CHANGE_EVENT,
            event: event
        });
    };
    const handleSelect = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_SELECT_EVENT,
            event: event
        });
    };
    const handleUnSelect = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_UNSELECT_EVENT,
            event: event
        });
    };
    const handleMultiSelect = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_EVENT,
            event: event,
        });
    };
    const handleMultiSelectCtrl = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_MULTI_SELECT_CTRL_EVENT,
            event: event,
        });
    };
    const handleDragStart = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_DRAG_EVENT,
            orderIds: event.orderIds,
            orderNumbers: event.orderNumbers,
            mapIsDragging: true,
        });
        window.dragOn = true;
        window.dragType = "o";
        $("#dragdrop").css({
            transform: `translate(${event.coordinates.x + 5}px, ${event.coordinates.y}px)`,
        });
        $("#dragdrop")
            .removeClass("fsr")
            .addClass("order")
            .html("" + event.orderIds.length);
        $("#dragdrop").show();
    };
    const handleDrag = (event) => {
        $("#dragdrop").css({
            transform: `translate(${event.coordinates.x + 5}px, ${event.coordinates.y}px)`,
        });
    };
    const handleDragEnd = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_DRAG_EVENT,
            orderIds: [],
            orderNumbers: [],
            mapIsDragging: false,
        });
        $("#dragdrop").css({
            transform: `translate(0px, 0px)`,
        });
        window.dragOn = false;
        if (window._isRecommend && window.fsrVehMode === "fsr") {
            $("#" + "f" + "table_refresh").trigger("click"); //refresh table
        }
        $("#dragdrop").hide();
        window.isHoldRecommend = false;
        window.isDragRecommend = false;
        const element = document.elementFromPoint(event.coordinates.x, event.coordinates.y);
        const parentElement = element.parentElement;
        if (parentElement) {
            const dataType = parentElement.getAttribute("data-type");
            const dataName = parentElement.getAttribute("data-obj_name");
            const dataStateId = parentElement.getAttribute("data-state_id");
            const dataId = parentElement.getAttribute("data-obj_id");
            const fsrId = parseInt(dataId);
            const fsrStateId = parseInt(dataStateId);
            const orderIds = state.mapDragOrderIds;
            const orderNumbers = state.mapDragOrderNumbers;
            const dateString = state.date;
            const isoDateString = dateValueToLegacyDateString(dateString);
            if (dataType === "fsr" && !!fsrId) {
                window.clearSelectedItems(true, true, true);
                const dateString = state.date;
                const isoDateString = dateValueToLegacyDateString(dateString);
                Fsrs.getFsrById(fsrId, isoDateString).then((fsrRsp) => {
                    Orders.getOrderByNumber(orderNumbers[0], isoDateString).then((orderRsp) => {
                        const fsrRspData = fsrRsp.data[0];
                        const orderRspData = orderRsp.data[0];
                        if (fsrRspData && orderRspData) {
                            const fsrObject = `${fsrRspData.FSR_ID},${fsrRspData.STATE_ID},${fsrRspData.LOGIN}`;
                            window.fsrSelected = [fsrObject];
                            const orderObject = `${orderRspData.ORDER_ID},${orderRspData.STATE_ID},${orderRspData.ORDER_NUMBER}`;
                            window.orderSelected = [orderObject];
                            window.selectTableItemsFromList("order");
                            window.selectTableItemsFromList("fsr");
                            window.selectTableItemsFromList("vehicle");
                            window.refreshDispatchToolbar("order");
                            window.refreshDispatchToolbar("fsr");
                            window.refreshDispatchToolbar("vehicle");
                            doDispatch();
                        }
                    });
                });
            }
        }
    };
    const handleDragTo = (event) => {
        const dateString = state.date;
        const isoDateString = dateValueToLegacyDateString(dateString);
        Fsrs.getFsrById(event.fsrId, isoDateString).then((rsp) => {
            try {
                const data = rsp.data;
                const fsr = data[0];
                const lat = fsr.lat;
                const lon = fsr.lon;
                const stateId = fsr.STATE_ID;
                const type = "fsr";
                const objName = fsr.LOGIN.split(" ")[2].replace("(", "").replace(")", "");
                const obj = lon + "," + lat + "," + event.fsrId + "," + stateId + "," + type + "," + objName;
                window.clearSelectedItems(true, false, true);
                window.selectItemsFromMap([obj], false);
                doDispatch();
            }
            catch (e) {
                console.error(e);
            }
        }).catch((e) => {
            console.error(e);
        });
    };
    const doDispatch = () => {
        if ($("#order_dispatch").is(":visible") || $("#order_dispatch_add").hasClass("act")) {
            $("#order_dispatch").trigger("click"); //Trigger dispatch
        }
        else {
            $("#order_reassign").trigger("click"); //Trigger re-assign
        }
        $("#ftable_refresh").trigger("click"); //refresh table
        $("#otable_refresh").trigger("click"); //refresh table
    };
    const handleHover = (event) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_EVENT,
            orderId: event.orderId,
            coordinates: event.coordinates,
        });
    };
    const handleHoverEnd = () => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_HOVER_END_EVENT,
        });
    };
    const handleTreeDataChange = (newTreeData) => {
        dispatch({
            type: DispatchPageContainerActionTypes.HANDLE_MAP_TREE_DATA_CHANGE_EVENT,
            newTreeData: newTreeData,
        });
    };
    const handleFirstRender = () => {
        dispatch({
            type: DispatchPageContainerActionTypes.SET_MAP_FINISHED_LOADING,
            mapFinishedLoading: true,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ESRIMap, { handleMapExtentChange: handleMapExtentChange, handleMapResize: handleMapResize, handleSelect: handleSelect, handleUnselect: handleUnSelect, handleMultiSelect: handleMultiSelect, handleMultiSelectCtrl: handleMultiSelectCtrl, handleDragStart: handleDragStart, handleDrag: handleDrag, handleDragEnd: handleDragEnd, handleDragTo: handleDragTo, handleHover: handleHover, handleHoverEnd: handleHoverEnd, handleTreeDataChange: handleTreeDataChange, handleFirstRender: handleFirstRender, icons: state.mapIcons, routes: state.mapRoutes, iconSize: state.mapIconSize, eventMapViewObject: state.mapView, urlPrefix: state.mapUrlPrefix, proxyUrl: state.mapProxyUrl, mapIsDragging: state.mapIsDragging, mapHoverData: state.mapHoverData, mapLayerTree: state.mapLayerTree, customLayerGroups: state.esriCustomLayerGroups, customLayerDisplaySettings: state.esriCustomLayerDisplaySettings, hoverRouteData: state.mapHoverRouteData, customLayerPopupsEnabled: state.esriCustomLayerPopupsEnabled, eventAddData: state.mapEventAddData, eventModifyData: state.mapEventModifyData, eventDeleteData: state.mapEventDeleteData }),
        React.createElement(LoadingPageTransparent, { isLoading: !state.mapFinishedLoading })));
};
export default ESRIMapContainer;
