import React, { useState } from "react";
import styled from "styled-components";
import SelectInput from "../../../Input/SelectInput/SelectInput";
import FilterRowWrapper from "../FilterRowWrapper/FilterRowWrapper";
import ControlledTextInput from "../../../Input/ControlledTextInput/ControlledTextInput";
import Spacer from "../../../Spacer/Spacer";
export const StyledStringFilterRow = styled.div `
  height: 18px;
  width: calc(100% - 2rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;
export var StringFilterRowSelectOption;
(function (StringFilterRowSelectOption) {
    StringFilterRowSelectOption["EQUAL_TO"] = "Equal To";
    StringFilterRowSelectOption["NOT_EQUAL_TO"] = "Not Equal To";
    StringFilterRowSelectOption["CONTAINS"] = "Contains";
    StringFilterRowSelectOption["NOT_CONTAINS"] = "Not Contains";
    StringFilterRowSelectOption["STARTS_WITH"] = "Starts With";
    StringFilterRowSelectOption["ENDS_WITH"] = "Ends With";
})(StringFilterRowSelectOption || (StringFilterRowSelectOption = {}));
const StringFilterRow = (props) => {
    const { filterKey, filterName, initialFilter, initialExpand, handleFilterChange, } = props;
    const filterKeyToSelectValue = (filterKey) => {
        switch (filterKey) {
            case "$in": {
                return StringFilterRowSelectOption.EQUAL_TO;
            }
            case "$ne": {
                return StringFilterRowSelectOption.NOT_EQUAL_TO;
            }
            case "$contains": {
                return StringFilterRowSelectOption.CONTAINS;
            }
            case "$nexist": {
                return StringFilterRowSelectOption.NOT_CONTAINS;
            }
            case "$sw": {
                return StringFilterRowSelectOption.STARTS_WITH;
            }
            case "$ew": {
                return StringFilterRowSelectOption.ENDS_WITH;
            }
            default: {
                return "";
            }
        }
    };
    const initialFilterType = initialFilter ? filterKeyToSelectValue(Object.keys(initialFilter)[0]) : "";
    const [type, setType] = useState(initialFilterType);
    const [text, setText] = useState(initialFilter ? Object.values(initialFilter)[0][0] : "");
    const handleClearFilter = () => {
        setText("");
    };
    const handleTextChange = (value, index) => {
        handleChange(type, value);
        setText(value);
    };
    const handleTypeChange = (value) => {
        handleChange(value, text);
        setType(value);
    };
    const handleChange = (type, text) => {
        let newFilterElement = null;
        switch (type) {
            case StringFilterRowSelectOption.EQUAL_TO: {
                newFilterElement = {
                    $in: [text]
                };
                break;
            }
            case StringFilterRowSelectOption.NOT_EQUAL_TO: {
                newFilterElement = {
                    $ne: [text]
                };
                break;
            }
            case StringFilterRowSelectOption.CONTAINS: {
                newFilterElement = {
                    $contains: [text]
                };
                break;
            }
            case StringFilterRowSelectOption.NOT_CONTAINS: {
                newFilterElement = {
                    $nexist: [text]
                };
                break;
            }
            case StringFilterRowSelectOption.STARTS_WITH: {
                newFilterElement = {
                    $sw: [text]
                };
                break;
            }
            case StringFilterRowSelectOption.ENDS_WITH: {
                newFilterElement = {
                    $ew: [text]
                };
                break;
            }
        }
        const newFilter = {
            [filterKey]: newFilterElement
        };
        handleFilterChange({
            key: filterKey,
            filterObject: newFilter,
        });
    };
    const inputOptions = Object.values(StringFilterRowSelectOption);
    const initialSelectValue = initialFilter ? filterKeyToSelectValue(Object.keys(initialFilter)[0]) : "";
    return (React.createElement(FilterRowWrapper, { filterName: filterName, initialExpand: initialExpand, handleClearFilter: handleClearFilter },
        React.createElement(StyledStringFilterRow, null,
            React.createElement(SelectInput, { options: ["", ...inputOptions], height: 18, initialValue: initialSelectValue, handleChange: handleTypeChange }),
            React.createElement(Spacer, { width: 10 }),
            React.createElement(ControlledTextInput, { handleChange: handleTextChange, value: text, height: 16, width: 200, placeholder: "Enter Value" }))));
};
export default StringFilterRow;
