import { takeEvery, call, put } from "redux-saga/effects";
import { InventoryInitializationActionTypes } from "./types";
import { WebSocketService } from "../../../helper/WebSocket/WebSocketService";
import { InventorySchemaService } from "../../../helper/InventorySchema/InventorySchemaService";
import { InventoryStateService } from "../../../helper/InventoryState/InventoryStateService";
import { InventoryCacheActionTypes } from "../InventoryCache/types";
export const inventoryInitializationSagas = {
    getInitializationSaga: takeEvery(InventoryInitializationActionTypes.INVENTORY_INITIALIZE, inventoryInitializationSaga)
};
export function* inventoryInitializationSaga(action) {
    const webSocketSuccess = yield call(WebSocketService.init);
    if (!webSocketSuccess) {
        return;
    }
    const inventorySchema = yield call(InventorySchemaService.init);
    const inventoryState = yield call(InventoryStateService.init);
    const userData = InventorySchemaService.getUserData();
    const inventoryGlobalState = {
        initializationComplete: false,
        userData: userData,
        schema: inventorySchema,
        stateTransitions: inventoryState,
        theme: action.theme
    };
    yield put({ type: InventoryInitializationActionTypes.INVENTORY_SET_GLOBAL_STATE, globalState: inventoryGlobalState });
    yield put({ type: InventoryCacheActionTypes.INITIALIZE_CACHE });
}
export default inventoryInitializationSagas;
