import React, { useEffect } from "react";
import Modal from "../components/Modal/Modal";
import { ManagePageContainerActionTypes } from "../../../state/containers/ManagePageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BindVehicleScreen from "../components/Screens/BindVehicleScreen/BindVehicleScreen";
const ManagePageVehicleLinkWindowContainer = (props) => {
    const state = useSelector(store => store.manage, shallowEqual);
    const dispatch = useDispatch();
    const { open, handleClose, } = props;
    useEffect(() => {
        if (open) {
            dispatch({
                type: ManagePageContainerActionTypes.OPEN_BIND_VEHICLE_WINDOW,
            });
        }
    }, [open]);
    const handleBindVehicle = (device, vehicle) => {
        dispatch({
            type: ManagePageContainerActionTypes.BIND_VEHICLE,
            device: device,
            vehicle: vehicle,
        });
        handleClose();
    };
    const sortedVehicles = state.traccarVehicles.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        else if (a.name > b.name) {
            return 1;
        }
        else {
            return 0;
        }
    });
    const sortedDevices = state.traccarDevices.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        else if (a.name > b.name) {
            return 1;
        }
        else {
            return 0;
        }
    });
    return (React.createElement(Modal, { open: open, title: "Link Vehicles", handleClose: handleClose, dimension: { width: 600, height: 260 } },
        React.createElement(BindVehicleScreen, { vehicles: sortedVehicles, devices: sortedDevices, handleBindVehicle: handleBindVehicle })));
};
export default ManagePageVehicleLinkWindowContainer;
