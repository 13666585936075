import React, { useState } from "react";
import styled from "styled-components";
import { Breadcrumbs, Button, TextField } from "@material-ui/core";
import { TreeView } from "@material-ui/lab";
import { nodeIdToPath, renderTreeItems } from "../helpers";
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { FlexParent } from "../../../../../helper/Layout/FlexParent";
const StyledCreateDocumentationPage = styled.div `
    margin : 5px;
`;
export const CreateDocumentationPage = (props) => {
    const { docTree, handlePublish, selectedNodeId } = props;
    const [createPageHeader, setCreatePageHeader] = useState("");
    const [selectedNodePath, setSelectedNodePath] = useState(nodeIdToPath(docTree, selectedNodeId.toString()));
    const rootNode = docTree.rootNode;
    const selectedNode = docTree.getNodeById(selectedNodeId);
    const defaultExpandedNodes = React.useMemo(() => selectedNodePath.map(x => x.id.toString()), []);
    const breadCrumbsLabels = [...selectedNodePath.map(x => x.dataObj), { displayName: createPageHeader, id: "-1" }];
    const breadCrumbs = breadCrumbsLabels
        .map(node => React.createElement("div", { key: node.id }, node.displayName));
    return React.createElement(StyledCreateDocumentationPage, null,
        React.createElement("h2", null, "Enter the name of your documentation page"),
        React.createElement(FlexParent, null,
            React.createElement(TextField, { style: { margin: "5px" }, id: "outlined-basic", label: "Name of Page Header", onChange: (e) => setCreatePageHeader(e.target.value), fullWidth: true }),
            React.createElement(Button, { variant: "contained", onClick: () => handlePublish(createPageHeader, (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.id) || 1) }, "Publish")),
        React.createElement("h2", null, "Create under..."),
        React.createElement(Breadcrumbs, null, breadCrumbs),
        React.createElement(TreeView, { defaultExpanded: defaultExpandedNodes, defaultSelected: [selectedNodeId.toString()], defaultCollapseIcon: React.createElement(ExpandMore, null), defaultExpandIcon: React.createElement(ChevronRight, null), onNodeSelect: onNodeSelectUpdateSelectedPath },
            React.createElement(React.Fragment, null, renderTreeItems(rootNode))));
    function onNodeSelectUpdateSelectedPath(evt, nodeId) {
        setSelectedNodePath(nodeIdToPath(docTree, nodeId));
    }
};
