import { InventoryFilterContainerActionTypes } from "./types";
const inventoryFilterFieldsChanged = (event) => {
    return { type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_FIELDS_CHANGED, fieldsToFilterOn: event };
};
const createMutateAction = (action) => {
    return { type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_MUTATE, innerAction: action };
};
const getFilterConfiguration = () => {
    return { type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_GET_CONFIGURATION };
};
const saveFilterRequest = (event) => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_SAVE_CONFIGURATION_REQUEST,
        name: event.saveName
    };
};
const deleteFilterRequest = (event) => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_CONFIGURATION_REQUEST,
        name: event.saveName
    };
};
const deleteFilterResponse = (event) => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_CONFIGURATION_RESPONSE,
        name: event.saveName
    };
};
const workingFilterChangeRequestHandler = (event) => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_CHANGE_WORKING_SET,
        name: event.key
    };
};
const filterRowDeleteHandler = (event) => {
    return { type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_DELETE_ROW, index: event.index };
};
const filterRowToggleExpandHandler = (event) => {
    return { type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_TOGGLE_EXPAND, index: event.index };
};
const updateHandler = (event) => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_STATE_UPDATE,
        update: event
    };
};
const resetHandler = () => {
    return {
        type: InventoryFilterContainerActionTypes.INVENTORY_FILTER_RESET_CONFIGURATION
    };
};
export { workingFilterChangeRequestHandler, updateHandler, getFilterConfiguration, saveFilterRequest, filterRowDeleteHandler, deleteFilterRequest, resetHandler, filterRowToggleExpandHandler, createMutateAction, inventoryFilterFieldsChanged };
