export var ScreenUtils;
(function (ScreenUtils) {
    let SCREEN_TYPES;
    (function (SCREEN_TYPES) {
        SCREEN_TYPES["INVENTORY"] = "INVENTORY";
    })(SCREEN_TYPES = ScreenUtils.SCREEN_TYPES || (ScreenUtils.SCREEN_TYPES = {}));
    let INVENTORY_SCREEN_NAMES;
    (function (INVENTORY_SCREEN_NAMES) {
        INVENTORY_SCREEN_NAMES["UPLOAD"] = "UPLOAD";
        INVENTORY_SCREEN_NAMES["HISTORY"] = "HISTORY";
        INVENTORY_SCREEN_NAMES["MODIFY_OWNER"] = "MODIFY_OWNER";
        INVENTORY_SCREEN_NAMES["MODIFY_PARENT_CONTAINER"] = "MODIFY_PARENT_CONTAINER";
        INVENTORY_SCREEN_NAMES["MODIFY_CONTAINER_SCAN"] = "MODIFY_CONTAINER_SCAN";
        INVENTORY_SCREEN_NAMES["UNVERIFIED_TO_QUARANTINED"] = "UNVERIFIED_TO_QUARANTINED";
        INVENTORY_SCREEN_NAMES["IN_TEST_TO_UNAVAILABLE"] = "IN_TEST_TO_UNAVAILABLE";
        INVENTORY_SCREEN_NAMES["DAMAGED_TO_REMOVED"] = "DAMAGED_TO_REMOVED";
    })(INVENTORY_SCREEN_NAMES = ScreenUtils.INVENTORY_SCREEN_NAMES || (ScreenUtils.INVENTORY_SCREEN_NAMES = {}));
})(ScreenUtils || (ScreenUtils = {}));
