export class ShowStatusMessage {
  showStatusMsg(msg, rc, desc = null, perc = null) {
    this.clearStatusMsg();
    if (rc == 0 || rc == 1 || rc == -1) {
      var stateClass = rc == 0 ? "ui-state-highlight" : "ui-state-error";
      var iconType = rc == 0 ? "ui-icon-info" : "ui-icon-alert";
      var textColor = rc == 0 ? "green" : "darkred";
      var descMessage =
        desc ? '</br><span style="font-weight:normal;color:black;">' + desc + "</span>" : "";
      var forceButtonBdy = "";
      if (rc == -1) {
        forceButtonBdy =
          '<span style="float: right; font-weight: bold; margin-right:5px; text-decoration: underline; cursor:pointer;color:black;" title="Force">Force?</span>';
      }
      $("body").append(
        `<div id="status_msg_special" style="display: none;overflow: hidden;z-index: 100000; width: 400px;" title=""><p></p></div>`
      );
      $("#status_msg_special")
        .show()
        .html(
          '<div class="' +
            stateClass +
            ' ui-corner-all" style="padding:1px;"> ' +
            '<p style=" margin: 2px 0;color:' +
            textColor +
            ';white-space:normal;">' +
            '<span class="ui-icon ' +
            iconType +
            '" style="float: left; margin-right:5px;"></span>' +
            '<span class="ui-icon ui-icon-close" style="float: right; margin-right:5px;cursor:pointer;" title="Close"></span>' +
            "" +
            forceButtonBdy +
            "" +
            '<strong style="font-weight:bold;">' +
            msg +
            "</strong> " +
            descMessage +
            "" +
            "</p>" +
            "</div>"
        )
          //@ts-ignore
        .position({ my: "top", at: "top", of: window })
        .find(".ui-icon-close")
        .off("click")
        .end()
        .find('span[title="Force"]')
        .off("click")
        .on("click", function(e) {
          $("#data_delete").trigger("click");
        });
      var closeTimer = rc == 0 ? 30 : 60; //Seconds
      if (rc == 0 || rc == 1) {
        $(document).one("click", function() {
          $("#status_msg_special")
            .html("")
            .hide();
          $(document).off("click.status_msg");
        });
      }
      return false;
    } else if (rc == 2) {
      var value = perc || false;
      $("#progressbar")
        .show()
        .find(".progress-label")
        .html(msg)
        .end()
        //@ts-ignore
          .progressbar({
          value: value
        })
        .position({ my: "top", at: "top", of: window });
    } else if (rc == -2) {
      $("body").append('<div id="grey_screen2" class="grey_screen"></div>');
    }
  }

  clearStatusMsg() {
    $("#progressbar")
        .hide()
        .progressbar()
        .progressbar("destroy");
  }
}
