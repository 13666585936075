import { InventoryFilterActionTypes } from "./types";
export function setTableFilterState(inventoryFilterState) {
    return {
        type: InventoryFilterActionTypes.SET_INVENTORY_FILTER_STATE,
        inventoryFilterState: inventoryFilterState
    };
}
export function updateOmniSearchFilter(filters) {
    return {
        type: InventoryFilterActionTypes.UPDATE_INVENTORY_OMNI_SEARCH_FILTER,
        filters: filters
    };
}
export function updateWarehouseFilter(filters) {
    return {
        type: InventoryFilterActionTypes.UPDATE_INVENTORY_WAREHOUSE_FILTER,
        filters: filters
    };
}
export function updateMultiFilter(filters) {
    return {
        type: InventoryFilterActionTypes.UPDATE_INVENTORY_MULTI_FILTER,
        filters: filters
    };
}
export function updateTableFilterObject() {
    return {
        type: InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT
    };
}
