import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AVLPageContainerActionTypes } from "../../../state/containers/AVLPageContainer/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Traccar from "../../../state/api/REST/Traccar/Traccar";
import LogoBar from "../components/LogoBar/LogoBar";
import Spacer from "../components/Spacer/Spacer";
import TextButton from "../components/Button/TextButton/TextButton";
import { redirect } from "../../../state/containers/LoginPageContainer/sagas";
const StyledAvlPageContainer = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
`;
const StyledAVLPageContentWrapper = styled.div `
  height: calc(100% - 21px);
  width: 100%;
`;
const StyledAVLPageLogoBarWrapper = styled.div `
  height: 20px;
  width: calc(100% - 405px);
`;
const StyledAVLPageNavigationWrapper = styled.div `
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: #BBBBBB;
  background-color: #62757F;
`;
const StyledAvlIframe = styled.iframe `
  position: relative;
  height: 100%;
  width: 100%;
  border-width: 0;
`;
const AVLPageContainer = (props) => {
    const state = useSelector(store => store.avl, shallowEqual);
    const dispatch = useDispatch();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [inIframe, setInIframe] = useState(false);
    useEffect(() => {
        //Wait needed for authentication due to legacy code. Remove when switching to history API.
        Traccar.authenticate().then((success) => {
            if (success) {
                setIsAuthenticated(true);
            }
        });
        dispatch({
            type: AVLPageContainerActionTypes.INIT,
        });
    }, []);
    useEffect(() => {
        setInIframe(window.self !== window.top);
    }, []);
    const src = "/avlPage";
    return (React.createElement(StyledAvlPageContainer, { className: "avl-page" },
        React.createElement(StyledAVLPageNavigationWrapper, null,
            React.createElement(StyledAVLPageLogoBarWrapper, null,
                React.createElement(LogoBar, { logoSrc: "/resources/images/comp.svg", backgroundColor: "#62757F", height: 20, logoHeight: 18 })),
            React.createElement(Spacer, { width: 10 }),
            !inIframe && React.createElement(TextButton, { height: 20, width: 50, text: "Dispatch", borderWidth: 0, color: "#000000", backgroundColor: "#62757F", fontWeight: 700, fontSize: 12, handleClick: () => { redirect("/sl3/d/dispatch/"); } }),
            React.createElement(Spacer, { width: 10 }),
            !inIframe && React.createElement(TextButton, { height: 20, width: 50, text: "Admin", borderWidth: 0, color: "#000000", backgroundColor: "#62757F", fontWeight: 700, fontSize: 12, handleClick: () => { redirect("/sl3/d/manage/"); } })),
        React.createElement(StyledAVLPageContentWrapper, null, isAuthenticated ?
            (React.createElement(StyledAvlIframe, { src: src })) : null)));
};
export default AVLPageContainer;
