import React, { useEffect, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import MaterialUIModal from '@material-ui/core/Modal';
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
import ModalTitle from "./ModalTitle/ModalTitle";
import Fade from '@material-ui/core/Fade';
const StyledModal = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  top: ${props => StyleUtils.getCssPixelString(props.top)};
  left: ${props => StyleUtils.getCssPixelString(props.left)};
  outline: none;
`;
const StyledModalContentWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.height)};
  width: ${props => StyleUtils.getCssPixelString(props.width)};
  overflow: auto;
`;
const Modal = (props) => {
    const { open, title, icons = [], spaceFromEdge = 50, titleHeight = 38, handleClose, dimension } = props;
    const [windowHeight, setHeight] = useState(200);
    const [windowWidth, setWidth] = useState(200);
    useEffect(() => {
        setModalSize();
    }, []);
    const setModalSize = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    };
    const onResize = () => {
        setModalSize();
    };
    let computedHeight, computedWidth;
    let spaceFromEdgeX, spaceFromEdgeY;
    if (dimension) {
        computedWidth = dimension.width;
        computedHeight = dimension.height;
        spaceFromEdgeX = windowWidth / 2 - dimension.width / 2;
        spaceFromEdgeY = windowHeight / 2 - dimension.height / 2;
    }
    else {
        computedHeight = windowHeight - spaceFromEdge * 2;
        computedWidth = windowWidth - spaceFromEdge * 2;
        spaceFromEdgeX = spaceFromEdge;
        spaceFromEdgeY = spaceFromEdge;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MaterialUIModal, { open: open, onClose: handleClose },
            React.createElement(Fade, { in: open, timeout: 500 },
                React.createElement(StyledModal, { className: "modal", height: Math.max(200, computedHeight), width: Math.max(200, computedWidth), top: spaceFromEdgeY, left: spaceFromEdgeX },
                    React.createElement(ModalTitle, { handleClose: handleClose, title: title, icons: icons, height: titleHeight }),
                    React.createElement(StyledModalContentWrapper, { height: windowHeight - titleHeight, width: computedWidth }, props.children)))),
        React.createElement(ReactResizeDetector, { handleWidth: true, handleHeight: true, onResize: onResize })));
};
export default Modal;
