import React from "react";
import MonthPicker from "./MonthPicker/MonthPicker";
import DayPicker from "./DayPicker/DayPicker";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import YearPicker from "./YearPicker/YearPicker";
import moment from "moment";
import SimpleButton from "../SimpleButton/SimpleButton";
const StyledDatePicker = styled.div `
  height: 23rem;
  width: 42.2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const MonthYearWrapper = styled.div `
  height: 23rem;
  width: 20rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const Separator = styled.div `
  height: 23rem;
  width: 0.2rem;
  background-color: ${props => props.colorOptions ? props.colorOptions.dividerColor : props.defaultColorOptions.dividerColor};
`;
const DayWrapper = styled.div `
  height: 22rem;
  width: 20rem;
  padding: 0.5rem;
`;
const GoToTodayWrapper = styled.div `
  height: 5rem;
  width: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const GoToTodaySpacer = styled.div `
  height: 5rem;
  width: 9.5rem;
`;
class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.defaultMonthObject = [
            {
                monthIndex: 0,
                monthShortName: "Jan",
                monthLongName: "January"
            },
            {
                monthIndex: 1,
                monthShortName: "Feb",
                monthLongName: "February"
            },
            {
                monthIndex: 2,
                monthShortName: "Mar",
                monthLongName: "March"
            },
            {
                monthIndex: 3,
                monthShortName: "Apr",
                monthLongName: "April"
            },
            {
                monthIndex: 4,
                monthShortName: "May",
                monthLongName: "May"
            },
            {
                monthIndex: 5,
                monthShortName: "Jun",
                monthLongName: "June"
            },
            {
                monthIndex: 6,
                monthShortName: "Jul",
                monthLongName: "July"
            },
            {
                monthIndex: 7,
                monthShortName: "Aug",
                monthLongName: "August"
            },
            {
                monthIndex: 8,
                monthShortName: "Sep",
                monthLongName: "September"
            },
            {
                monthIndex: 9,
                monthShortName: "Oct",
                monthLongName: "October"
            },
            {
                monthIndex: 10,
                monthShortName: "Nov",
                monthLongName: "November"
            },
            {
                monthIndex: 11,
                monthShortName: "Dec",
                monthLongName: "December"
            }
        ];
        this.defaultWeekdayObject = [
            {
                weekdayIndex: 0,
                weekdayShortName: "S",
                weekdayLongName: "Sunday"
            },
            {
                weekdayIndex: 1,
                weekdayShortName: "M",
                weekdayLongName: "Monday"
            },
            {
                weekdayIndex: 2,
                weekdayShortName: "T",
                weekdayLongName: "Tuesday"
            },
            {
                weekdayIndex: 3,
                weekdayShortName: "W",
                weekdayLongName: "Wednesday"
            },
            {
                weekdayIndex: 4,
                weekdayShortName: "T",
                weekdayLongName: "Thursday"
            },
            {
                weekdayIndex: 5,
                weekdayShortName: "F",
                weekdayLongName: "Friday"
            },
            {
                weekdayIndex: 6,
                weekdayShortName: "S",
                weekdayLongName: "Saturday"
            }
        ];
        this.defaultColorOptions = {
            color: this.theme.palette.black,
            backgroundColor: this.theme.palette.white,
            selectedColor: this.theme.palette.black,
            selectedBackgroundColor: this.theme.palette.themeLighter,
            hoverColor: this.theme.palette.black,
            hoverBackgroundColor: this.theme.palette.neutralLight,
            todayColor: this.theme.palette.white,
            todayBackgroundColor: this.theme.palette.themePrimary,
            todayHoverColor: this.theme.palette.white,
            todayHoverBackgroundColor: this.theme.palette.themeDark,
            otherMonthColor: this.theme.palette.neutralSecondary,
            dividerColor: this.theme.palette.neutralLight
        };
        this.getInitialYearMonthDay = () => {
            const time = this.props.initialDate === undefined || this.props.initialDate === null
                ? moment.now().valueOf()
                : this.props.initialDate;
            const year = moment(time).year();
            const month = moment(time).month();
            const day = moment(time).date();
            return [year, month, day];
        };
        this.handleMonthChange = (month) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedMonth: month, displayedMonth: month, selectedYear: prevState.displayedYear, selectedDay: null, displayedDay: prevState.displayedDay })));
        };
        this.handleYearChange = (year) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { displayedYear: year })));
        };
        this.handleYearMonthChange = (year, month) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { displayedMonth: month, displayedYear: year })));
        };
        this.handleYearMonthDayChange = (year, month, day) => {
            this.props.handleDateChange(year, month, day);
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedYear: year, displayedYear: year, selectedMonth: month, displayedMonth: month, selectedDay: day, displayedDay: day })));
        };
        this.handleGoToToday = () => {
            const currentTime = moment.now().valueOf();
            const currentYear = moment(currentTime).year();
            const currentMonth = moment(currentTime).month();
            const currentDay = moment(currentTime).date();
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { displayedYear: currentYear, displayedMonth: currentMonth, displayedDay: currentDay })));
        };
        const [year, month, day] = this.getInitialYearMonthDay();
        this.state = {
            selectedYear: year,
            displayedYear: year,
            selectedMonth: month,
            displayedMonth: month,
            selectedDay: day,
            displayedDay: day
        };
    }
    render() {
        const currentTime = moment.now().valueOf();
        const { selectedYear, displayedYear, selectedMonth, displayedMonth, selectedDay, displayedDay } = this.state;
        const colorOptions = this.props.colorOptions || this.defaultColorOptions;
        const monthObject = this.props.monthObject || this.defaultMonthObject;
        const weekdayObject = this.props.weekdayObject || this.defaultWeekdayObject;
        const normalOptionalProps = {
            backgroundColor: colorOptions.todayBackgroundColor,
            textColor: colorOptions.todayColor
        };
        const hoveringOptionalProps = {
            backgroundColor: colorOptions.todayHoverBackgroundColor,
            textColor: colorOptions.todayHoverColor
        };
        const mouseDownOptionalProps = {
            backgroundColor: colorOptions.todayHoverBackgroundColor,
            textColor: colorOptions.todayHoverColor
        };
        return (React.createElement(StyledDatePicker, { className: "date-picker" },
            React.createElement(DayWrapper, { className: "day-picker-wrapper" },
                React.createElement(DayPicker, { selectedYear: selectedYear, displayedYear: displayedYear, currentTime: currentTime, selectedMonth: selectedMonth, displayedMonth: displayedMonth, selectedDay: selectedDay, displayedDay: displayedDay, monthOptions: monthObject, weekdayOptions: weekdayObject, colorOptions: colorOptions, handleYearMonthChange: this.handleYearMonthChange, handleYearMonthDayChange: this.handleYearMonthDayChange })),
            React.createElement(Separator, Object.assign({}, this.props, this.theme, { defaultColorOptions: this.defaultColorOptions })),
            React.createElement(MonthYearWrapper, { className: "month-year-picker-wrapper" },
                React.createElement(YearPicker, { selectedYear: selectedYear, displayedYear: displayedYear, currentTime: currentTime, colorOptions: colorOptions, handleYearChange: this.handleYearChange }),
                React.createElement(MonthPicker, { selectedYear: selectedYear, displayedYear: displayedYear, currentTime: currentTime, selectedMonth: selectedMonth, displayedMonth: displayedMonth, monthOptions: monthObject, colorOptions: colorOptions, handleMonthClick: this.handleMonthChange }),
                React.createElement(GoToTodayWrapper, { className: "go-to-today-wrapper" },
                    React.createElement(GoToTodaySpacer, { className: "go-to-today-spacer" }),
                    React.createElement(SimpleButton, { className: "go-to-today-button", color: colorOptions.todayColor, normalOptionalProps: normalOptionalProps, hoveringOptionalProps: hoveringOptionalProps, mouseDownOptionalProps: mouseDownOptionalProps, backgroundColor: colorOptions.todayBackgroundColor, textProps: { iconText: "Go to Today", height: "1.6rem", width: "10rem" }, padding: "0.2rem 0", margin: "1.5rem 0", borderRadius: "0.5rem", buttonClick: this.handleGoToToday })))));
    }
}
export default DatePicker;
