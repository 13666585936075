import React from "react";
import { getTheme } from "office-ui-fabric-react";
class SimpleListRow extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        const { width, height, selection, index, fontColor, selectColor, backgroundColor } = this.props;
        let isSelected = false;
        if (selection && index !== undefined) {
            isSelected = selection.isIndexSelected(index);
        }
        const wrapperStyle = {
            width: `calc(${width} - 17px)`,
            height: height
        };
        const style = {
            width: `calc(100% - 1rem)`,
            height: `calc(${height} - 0.2rem)`,
            fontSize: `calc(${height} - 0.2rem)`,
            lineHeight: `calc(${height} - 0.2rem)`,
            padding: "0.1rem 0.5rem",
            color: fontColor || this.theme.palette.black,
            backgroundColor: isSelected
                ? selectColor || this.theme.palette.themeLight
                : backgroundColor || this.theme.palette.white,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            userSelect: "none"
        };
        return (React.createElement("div", { className: "simple-list-row-wrapper", "data-is-focusable": true, "data-selection-index": index, style: wrapperStyle },
            React.createElement("div", { className: "simple-list-row", style: style }, this.props.displayedText)));
    }
}
export default SimpleListRow;
