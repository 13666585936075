export var IndexedDBService;
(function (IndexedDBService) {
    let indexedDB = null;
    const name = "sl";
    IndexedDBService.initialize = () => {
        return new Promise((resolve, reject) => {
            if (window.indexedDB) {
                const indexedDBOpenRequest = window.indexedDB.open(name, 1);
                indexedDBOpenRequest.onerror = () => {
                    console.error(`Unable to create Indexed DB with the name: ${name}.`);
                    resolve(false);
                };
                indexedDBOpenRequest.onupgradeneeded = (e) => {
                    indexedDB = e.target.result;
                    indexedDB.createObjectStore(name);
                    console.log(`Successfully created Indexed DB with the name: ${name}.`);
                    e.target.transaction.oncomplete = (e) => {
                        resolve(true);
                    };
                };
                indexedDBOpenRequest.onsuccess = (e) => {
                    indexedDB = e.target.result;
                    console.log(`Successfully connected to Indexed DB with the name: ${name}.`);
                    resolve(true);
                };
            }
        });
    };
    IndexedDBService.get = (key) => {
        return new Promise((resolve, reject) => {
            if (indexedDB) {
                const transaction = indexedDB.transaction("sl", "readonly");
                transaction.onerror = () => {
                    console.log(`Unable to open Indexed DB transaction.`);
                };
                const objectStore = transaction.objectStore(name);
                const objectStoreRequest = objectStore.get(key);
                objectStoreRequest.onerror = () => {
                    resolve(null);
                };
                objectStoreRequest.onsuccess = (e) => {
                    resolve(e.target.result);
                };
                objectStoreRequest.onerror = (e) => {
                    console.error(`Unable to get value with the key: ${key}.`);
                    resolve(null);
                };
            }
            else {
                console.error("Indexed DB is not supported by this browser.");
                resolve(null);
            }
        });
    };
    IndexedDBService.add = (key, value) => {
        return new Promise((resolve, reject) => {
            if (indexedDB) {
                const transaction = indexedDB.transaction([name], "readwrite");
                transaction.onerror = () => {
                    console.error(`Unable to open Indexed DB transaction.`);
                };
                const objectStore = transaction.objectStore(name);
                const objectStoreRequest = objectStore.add(value, key);
                objectStoreRequest.onsuccess = (e) => {
                    resolve(true);
                };
                objectStoreRequest.onerror = (e) => {
                    console.error(`Unable to add value: ${value} with the key: ${key}.`);
                    resolve(false);
                };
            }
            else {
                console.error("Indexed DB is not supported by this browser.");
                resolve(false);
            }
        });
    };
    IndexedDBService.put = (key, value) => {
        return new Promise((resolve, reject) => {
            if (indexedDB) {
                const transaction = indexedDB.transaction([name], "readwrite");
                transaction.onerror = () => {
                    console.error(`Unable to open Indexed DB transaction.`);
                };
                const objectStore = transaction.objectStore(name);
                const objectStoreRequest = objectStore.put(value, key);
                objectStoreRequest.onsuccess = (e) => {
                    resolve(true);
                };
                objectStoreRequest.onerror = (e) => {
                    console.error(`Unable to update value: ${value} with the key: ${key}.`);
                    resolve(false);
                };
            }
            else {
                console.error("Indexed DB is not supported by this browser.");
                resolve(false);
            }
        });
    };
    IndexedDBService.remove = (key) => {
        return new Promise((resolve, reject) => {
            if (indexedDB) {
                const transaction = indexedDB.transaction(name, "readwrite");
                transaction.onerror = () => {
                    console.error(`Unable to open Indexed DB transaction.`);
                };
                const objectStore = transaction.objectStore(name);
                const objectStoreRequest = objectStore.delete(key);
                objectStoreRequest.onsuccess = (e) => {
                    resolve(true);
                };
                objectStoreRequest.onerror = (e) => {
                    console.error(`Unable to remove Indexed DB key: ${key}.`);
                    resolve(false);
                };
            }
            else {
                console.error("Indexed DB is not supported by this browser.");
                resolve(false);
            }
        });
    };
    IndexedDBService.clear = () => {
        return new Promise((resolve, reject) => {
            if (indexedDB) {
                const transaction = indexedDB.transaction(name, "readwrite");
                transaction.onerror = () => {
                    console.error(`Unable to open Indexed DB transaction.`);
                };
                const objectStore = transaction.objectStore(name);
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = (e) => {
                    resolve(true);
                };
                objectStoreRequest.onerror = (e) => {
                    console.error(`Unable to clear Indexed DB with the name: ${name}.`);
                    resolve(false);
                };
            }
            else {
                console.error("Indexed DB is not supported by this browser.");
                resolve(false);
            }
        });
    };
})(IndexedDBService || (IndexedDBService = {}));
export var IndexedDBKeys;
(function (IndexedDBKeys) {
    IndexedDBKeys["IS_NEW_GUI"] = "isNewGUI";
    IndexedDBKeys["BU"] = "bu";
    IndexedDBKeys["LOGIN"] = "login";
    IndexedDBKeys["NOTIFICATIONS"] = "notifications";
    IndexedDBKeys["THEME"] = "theme";
    IndexedDBKeys["ESRI_MAP_LAYER_SETTINGS"] = "esriMapLayerSettings";
})(IndexedDBKeys || (IndexedDBKeys = {}));
export default IndexedDBService;
