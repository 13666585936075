import { put, select, takeEvery } from "redux-saga/effects";
import { InventoryOmniSearchContainerActionTypes } from "./types";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { InventoryFilterActionTypes } from "../../services/InventoryFilter/types";
export const inventoryOmniSearchContainerSagas = {
    handleSearchChangeSaga: takeEvery(InventoryOmniSearchContainerActionTypes.HANDLE_SEARCH_CHANGE, handleSearchChangeSaga)
};
function* handleSearchChangeSaga(action) {
    const omniSearchContainerState = yield select(getInventoryOmniSearchContainerStateSelector);
    const searchFields = omniSearchContainerState.searchFields;
    const searchFieldFilters = !action.searchString
        ? []
        : searchFields.map(searchField => {
            return [
                {
                    field: searchField,
                    operator: FilterUtils.J_FILTER_OPERATORS.contains,
                    value: action.searchString
                }
            ];
        });
    yield put({
        type: InventoryOmniSearchContainerActionTypes.SET_INVENTORY_OMNI_SEARCH_CONTAINER_STATE,
        inventoryOmniSearchContainerState: Object.assign(Object.assign({}, omniSearchContainerState), { searchString: action.searchString })
    });
    yield put({
        type: InventoryFilterActionTypes.UPDATE_INVENTORY_OMNI_SEARCH_FILTER,
        filters: searchFieldFilters
    });
}
function getInventoryOmniSearchContainerStateSelector(store) {
    return store.inventory.inventoryOmniSearchContainer;
}
export default inventoryOmniSearchContainerSagas;
