import React, { useContext, useState } from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import { ThemeContext } from "../../../../theme/ThemeContextProvider";
export const StyledControlledTextInput = styled.input `
  outline: none;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  text-align: ${props => props.textAlign};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isInFocus)};
  color: ${props => getVariableColor(props.fontColor, props.isInFocus)};
  font-weight: ${props => props.fontWeight};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => getVariableColor(props.borderColor, props.isInFocus)};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
  ::placeholder {
    color: ${props => getVariableColor(props.placeholderColor, props.isInFocus)}
  }
`;
const getVariableColor = (color, isInFocus) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        return isInFocus ? color.inFocus : color.normal;
    }
};
const ControlledTextInput = (props, ref) => {
    const { index, value, className = "controlled-text-input", placeholder = "Input", type = "text", height = 16, width = 160, fontSize = 14, backgroundColor = "#FFFFFF", placeholderColor = "#757575", fontColor = "#000000", fontWeight = "normal", textAlign = "left", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleSubmit, handleChange, handleFocus, handleBlur, handleLeftMove, handleRightMove, handleDelete } = props;
    const [isInFocus, setIsInFocus] = useState(false);
    const { theme: contextTheme } = useContext(ThemeContext);
    const theme = contextTheme;
    const onFocus = (e) => {
        setIsInFocus(true);
        if (handleFocus) {
            handleFocus(index);
        }
    };
    const onBlur = (e) => {
        setIsInFocus(false);
        if (handleBlur) {
            handleBlur(index);
        }
    };
    const onKeyPress = (e) => {
        const charCode = e.charCode;
        switch (charCode) {
            case 13: { //Enter
                if (handleSubmit) {
                    handleSubmit(value, index);
                }
                break;
            }
        }
    };
    const onKeyDown = (e) => {
        const keyCode = e.keyCode;
        switch (keyCode) {
            case 37: { //Left Arrow
                if (handleLeftMove) {
                    handleLeftMove(index);
                }
                e.preventDefault();
                break;
            }
            case 39: { //Right Arrow
                if (handleRightMove) {
                    handleRightMove(index);
                }
                e.preventDefault();
                break;
            }
            case 8: {
                if (handleDelete) {
                    handleDelete(index);
                }
                break;
            }
        }
    };
    const onChange = (e) => {
        var _a;
        const newValue = ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || "";
        if (handleChange) {
            handleChange(newValue, index);
        }
    };
    return (React.createElement(StyledControlledTextInput, { className: className, type: type, ref: ref, placeholder: placeholder, onFocus: onFocus, onBlur: onBlur, onKeyPress: onKeyPress, onChange: onChange, onKeyDown: onKeyDown, value: value, isInFocus: isInFocus, height: height, width: width, fontSize: fontSize, backgroundColor: backgroundColor, placeholderColor: placeholderColor, fontColor: fontColor, fontWeight: fontWeight, textAlign: textAlign, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding }));
};
// @ts-ignore
export default React.forwardRef(ControlledTextInput);
