import React from "react";
import LogoBar from "../components/LogoBar/LogoBar";
import styled from "styled-components";
import LoadingBar from "../components/LoadingBar/LoadingBar";
import PasswordChangeMenu from "../components/PasswordChangeMenu/PasswordChangeMenu";
const StyledPasswordChangePage = styled.div `
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;
const StyledPasswordChangeMenuWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
const PasswordChangePage = (props) => {
    const { handleChangePassword, handleGoBack, isInitialized, errorMessage, message, slAuthEnabled, loginMenuLogoSrc = "/resources/images/comp.svg", logoBarLogoSrc = "/resources/images/comp.svg", isLoadingComplete, height = 456, width = 430, titleBarHeight = 50, backgroundColor = "#FFFFFF", loginMenuBackgroundColor = "#F6F6F6", focusColor = "#FC970A", loadingColor = "#D62C1B", errorColor = "#D62C1B", fontColorWhite = "#FFFFFF", fontColor = "#757575", fontColorBlack = "#000000", themeColor = "#62757F", themeColorDark = "#4E5D65", themeColorDarker = "#374248" } = props;
    return (React.createElement(StyledPasswordChangePage, { className: "password-change-page", backgroundColor: backgroundColor }, isInitialized ? (React.createElement(React.Fragment, null,
        React.createElement(LoadingBar, { isLoadingComplete: isLoadingComplete, loadingColor: loadingColor, loadedColor: themeColor }),
        React.createElement(LogoBar, { logoSrc: logoBarLogoSrc, backgroundColor: themeColor }),
        React.createElement(StyledPasswordChangeMenuWrapper, null,
            React.createElement(PasswordChangeMenu, { handleChangePassword: handleChangePassword, handleGoBack: handleGoBack, errorMessage: errorMessage, message: message, slAuthEnabled: slAuthEnabled, loginMenuLogoSrc: loginMenuLogoSrc, height: height, width: width, titleBarHeight: titleBarHeight, loginMenuBackgroundColor: loginMenuBackgroundColor, focusColor: focusColor, errorColor: errorColor, fontColorWhite: fontColorWhite, fontColor: fontColor, fontColorBlack: fontColorBlack, themeColor: themeColor, themeColorDark: themeColorDark, themeColorDarker: themeColorDarker })))) : null));
};
export default PasswordChangePage;
