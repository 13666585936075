import React from "react";
import TableRow from "./TableRow/TableRow";
import styled from "styled-components";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
const StyledTableRowWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.tableRowWrapperData.totalRowsCount * props.rowHeight)};
  width: max-content;
`;
const StyledInViewTableRowsWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(props.displayedRowsCount * props.rowHeight)};
  width: max-content;
  position: relative;
  left: 0;
  top: ${props => StyleUtils.getCssPixelString(props.topSpacerRowsCount * props.rowHeight)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
class TableRowWrapper extends React.Component {
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.tableRowWrapperData.tableRowsData !== nextProps.tableRowWrapperData.tableRowsData;
    }
    render() {
        const { tableRowWrapperData, tableCellActions, tableCellCommonActions } = this.props;
        return (React.createElement(StyledTableRowWrapper, Object.assign({ className: "table-row-wrapper" }, this.props),
            React.createElement(StyledInViewTableRowsWrapper, Object.assign({ className: "in-view-table-row-wrapper" }, this.props), tableRowWrapperData.tableRowsData.map((row, index) => (React.createElement(TableRow, Object.assign({ key: index }, row, { tableCellActions: tableCellActions, tableCellCommonActions: tableCellCommonActions })))))));
    }
}
export default TableRowWrapper;
