export var InventoryTableContainerActionTypes;
(function (InventoryTableContainerActionTypes) {
    InventoryTableContainerActionTypes["SET_INVENTORY_TABLE_CONTAINER_STATE"] = "SET_INVENTORY_TABLE_CONTAINER_STATE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["INITIALIZE"] = "INITIALIZE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["UPDATE"] = "UPDATE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_USER_SETTINGS"] = "GET_USER_SETTINGS.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TOTAL_ROWS_COUNT"] = "GET_TOTAL_ROWS_COUNT.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TABLE_ROWS_DATA"] = "GET_TABLE_ROWS_DATA.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TABLE_COLUMNS_DATA"] = "GET_TABLE_COLUMNS_DATA.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["SET_VERTICAL_SCROLL"] = "SET_VERTICAL_SCROLL.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_CELL_LEFT_CLICK"] = "HANDLE_CELL_LEFT_CLICK.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_CELL_RIGHT_CLICK"] = "HANDLE_CELL_RIGHT_CLICK.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_COLUMN_REORDER"] = "HANDLE_COLUMN_REORDER.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_COLUMN_RESIZE"] = "HANDLE_COLUMN_RESIZE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_SELECT_ALL"] = "HANDLE_SELECT_ALL.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["OPEN_CONTEXT_MENU"] = "OPEN_CONTEXT_MENU.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["SET_TABLE_J_FILTER_OBJECT"] = "SET_TABLE_J_FILTER_OBJECT.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_STATE_CHANGE"] = "HANDLE_STATE_CHANGE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_SERVER_ORIGIN_MESSAGE"] = "HANDLE_SERVER_ORIGIN_MESSAGE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TABLE_ROWS_DATA_SELECTED_CHANGE"] = "GET_TABLE_ROWS_DATA_SELECTED_CHANGE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TABLE_ROWS_DATA_STATE_CHANGE"] = "GET_TABLE_ROWS_DATA_STATE_CHANGE.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["GET_TABLE_ROWS_DATA_BY_IDS"] = "GET_TABLE_ROWS_DATA_BY_IDS.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["TABLE_TO_CSV"] = "TABLE_TO_CSV.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HISTORICAL_DATA_TO_CSV"] = "HISTORICAL_DATA_TO_CSV.inventoryTableContainer.inventory.state";
    InventoryTableContainerActionTypes["HANDLE_BACKGROUND_RIGHT_CLICK"] = "HANDLE_BACKGROUND_RIGHT_CLICK.inventoryTableContainer.inventory.state";
})(InventoryTableContainerActionTypes || (InventoryTableContainerActionTypes = {}));
