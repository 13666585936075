import { InventoryInitializationActionTypes } from "./types";
export const inventoryInitialState = {
    initializationComplete: false,
    userData: null,
    schema: null,
    stateTransitions: null,
    theme: null
};
export function inventoryInitializationReducer(state = inventoryInitialState, action) {
    switch (action.type) {
        case InventoryInitializationActionTypes.INVENTORY_SET_GLOBAL_STATE: {
            return Object.assign({}, action.globalState);
        }
        default:
            return state;
    }
}
