// @ts-nocheck
'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from "styled-components";
import _ from 'lodash';
import { getMaxOverlappingItems, rowItemsRenderer, rowLayerRenderer } from './utils/itemUtils';
import { getPixelAtTime, getTimeAtPixel } from './utils/timeUtils';
import Timebar from './components/timebar';
import SelectBox from './components/selector';
import { DefaultGroupRenderer, DefaultItemRenderer } from './components/renderers';
import TimelineBody from './components/body';
// startsWith polyfill for IE11 support
import 'core-js/fn/string/starts-with';
import { AutoSizer } from "react-virtualized";
import SLInfiniteLoader from "../InfiniteLoader";
import compose from "compose-function";
import { RouteStates } from "../../RouteStates";
import { RowEventType } from "../../RowEventType";
const NoResultsDiv = styled.div `
    height: ${props => (props.height)}px;
    width: ${props => (props.width)}px;
    margin-left: ${props => (props.width / 2)}px;
    margin-top: ${props => (props.height / 2)}px;
`;
/**
 * Timeline class
 * @reactProps {!number} items - this is prop1
 * @reactProps {string} prop2 - this is prop2
 */
export default class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.deleteRowNoUpdateCount = x => this.deleteRow(x, x => x);
        this.addRowNoUpdateCount = x => this.addRow(x, x => x);
        this.addRow = ({ groups, items, rowLayers }, setState = this.setState.bind(this)) => {
            let pivotIndex = Object.keys(groups)[0];
            console.log("adding this", pivotIndex);
            if (pivotIndex < this.viewingWindow.startIndex || pivotIndex > this.viewingWindow.stopIndex) {
                console.log("added out of range", pivotIndex);
                return;
            }
            const adder = x => parseInt(x) + 1;
            const partitionForAdd = (pivotIndex) => ([rowIndex]) => parseInt(rowIndex) >= parseInt(pivotIndex);
            const keepingFunctionForAdd = (toStay, pivotIndex) => Object.fromEntries(toStay);
            // console.log("before:", this.groups)
            this.updateRowsAroundThisPivot(pivotIndex, adder, partitionForAdd, keepingFunctionForAdd, x => _.reverse(x), setState);
            // console.log("results1:", this.groups)
            this.updateTimelineData({ groups, items, rowLayers });
            // console.log("results2:", this.groups)
        };
        this.deleteRow = (fsrEvent, setState = this.setState.bind(this)) => {
            let [pivotIndex] = this.matchToRowIndex(fsrEvent);
            console.log("deleting this", pivotIndex);
            if (pivotIndex == -1)
                return;
            const adder = x => parseInt(x) - 1;
            const partitionForDelete = (pivotIndex) => ([rowIndex]) => parseInt(rowIndex) > parseInt(pivotIndex);
            const keepingFuncForDelete = (toStay, pivotIndex) => Object.fromEntries(toStay.filter(([rowIndex]) => rowIndex != pivotIndex));
            delete this.rowHeightCache[pivotIndex];
            delete this.rowItemMap[pivotIndex];
            this.rowLayers = this.rowLayers.filter(x => x.rowNumber !== pivotIndex);
            // console.log("before:", this.groups)
            this.updateRowsAroundThisPivot(pivotIndex, adder, partitionForDelete, keepingFuncForDelete, x => x, setState);
            // console.log("results:", this.groups)
        };
        this.updateRowsAroundThisPivot = (pivotIndex, adder, partitioningFunc, chooseWhatToKeep, iterate, setState) => {
            setState({ rowCount: adder(this.state.rowCount) });
            let [toMove, toStay] = _.partition(Object.entries(this.groups), partitioningFunc(pivotIndex));
            iterate(toMove).forEach(([rowIndex, group]) => {
                let items = this.rowItemMap[rowIndex];
                if (items) {
                    items.forEach(i => {
                        i.row = adder(i.row);
                    });
                    delete this.rowItemMap[rowIndex];
                    this.rowItemMap[adder(rowIndex)] = items;
                    this.rowHeightCache[adder(rowIndex)] = this.rowHeightCache[rowIndex];
                    delete this.rowHeightCache[rowIndex];
                    this.rowLayers
                        .filter(x => x.rowNumber == rowIndex)
                        .forEach(x => {
                        x.rowNumber = adder(x.rowNumber);
                    });
                }
            });
            let movedGroups = Object.fromEntries(toMove.map(([index, group]) => {
                group.rowId = adder(group.rowId);
                return [adder(index), group];
            }));
            let keptGroups = chooseWhatToKeep(toStay, pivotIndex);
            this.groups = Object.assign(Object.assign({}, movedGroups), keptGroups);
        };
        /**
         * re-computes the grid's row sizes
         * @param {Object?} config Config to pass wo react-virtualized's compute func
         */
        this.refreshGrid = (config = {}) => {
            var _a;
            (_a = this === null || this === void 0 ? void 0 : this._grid) === null || _a === void 0 ? void 0 : _a.recomputeGridSize(config);
        };
        this.matchToRowIndex = group => {
            let matchingGroup = Object.values(this.groups)
                .find(existingGroup => parseInt(group.FSR_ID) == parseInt(existingGroup.FSR_ID));
            if (!matchingGroup) {
                return [-1, group];
            }
            return [matchingGroup.rowId, Object.assign(Object.assign({}, matchingGroup), group)];
        };
        this.updateFromModifyEvent = (payload, getNewIndex) => {
            let { groups, items, rowLayers } = payload;
            let groupsAsArray = Object.values(groups);
            let updateRow = ([matchingIndex, group]) => {
                //row, rowId, rowNumber
                group.rowId = matchingIndex;
                items.forEach(item => {
                    item.row = matchingIndex;
                });
                rowLayers.forEach(rowLayer => {
                    rowLayer.rowNumber = matchingIndex;
                });
                return [matchingIndex, group];
            };
            let entries = groupsAsArray
                .map(getNewIndex)
                .filter(([matchingIndex]) => matchingIndex != -1)
                .map(updateRow);
            groups = _.isEmpty(entries) ? groups : Object.fromEntries(entries);
            return { groups, items, rowLayers };
        };
        this.updateTimelineData = ({ groups, items, rowLayers }) => {
            let timeMap = this.createTimeMap(items);
            let { itemRowMap, rowItemMap, rowHeightCache } = timeMap;
            if (process.env.NODE_ENV !== 'production') {
                Object.entries(rowItemMap).map(([index, itemsToAdd]) => {
                    var _a;
                    let theSet = new Set((_a = this.rowItemMap[index]) === null || _a === void 0 ? void 0 : _a.map(presentItem => presentItem.scheduleKey));
                    itemsToAdd.forEach(i => theSet.add(i.scheduleKey));
                    if (theSet.size > 1) {
                        console.log("holy shti");
                        debugger;
                    }
                });
            }
            let toMerge = Object.assign({ groups: groups }, timeMap);
            _.merge(this, toMerge);
            let returnedLayers = new Set(rowLayers.map(({ rowNumber }) => parseInt(rowNumber)));
            this.rowLayers = [
                ...this.rowLayers.filter(({ rowNumber }) => !returnedLayers.has(rowNumber)),
                ...rowLayers
            ];
            let filterEntriesThatFallOutsideViewingWindow = ([index, value]) => {
                return this.viewingWindow.startIndex <= parseInt(index) && parseInt(index) <= this.viewingWindow.stopIndex;
            };
            this.groups = Object.fromEntries(Object.entries(this.groups).filter(filterEntriesThatFallOutsideViewingWindow));
            Object.entries(this.groups).forEach(([key, group]) => {
                if (parseInt(group.ROUTE_STATE) != RouteStates.ROUTE_SUCCESS) {
                    delete this.rowItemMap[key];
                    delete this.rowHeightCache[key];
                    this.rowLayers = this.rowLayers.filter(rowLayer => rowLayer.rowNumber != key);
                }
            });
        };
        this.loadMoreRows = ({ startIndex, stopIndex }) => {
            this.viewingWindow = { startIndex, stopIndex };
            return this.props.loadMoreRows({ startIndex, stopIndex })
                .then(payload => {
                //TODO: currently there's a race condition with events that will make ghost rows appear due to mutating
                //these fields, we need to have all internal datastructures updated with something more immutable. For now
                //this should fix the issue
                this.groups = {};
                this.rowItemMap = {};
                this.rowLayers = [];
                return payload;
            })
                .then(this.updateTimelineData);
        };
        this.ItemRow = ({ rowIndex, style, width, rowEventStream }) => {
            let itemsInRow = this.rowItemMap[rowIndex];
            let group = this.groups[rowIndex];
            // console.log("these are the layers before filter", this.rowLayers)
            const layersInRow = this.rowLayers.filter(r => r.rowNumber === rowIndex);
            // console.log("and this is after", this.rowLayers)
            let rowHeight = this.props.itemHeight;
            if (this.rowHeightCache[rowIndex]) {
                rowHeight = rowHeight * this.rowHeightCache[rowIndex];
            }
            let ItemParent = (props) => React.createElement("div", { style: style, "data-row-index": rowIndex, className: "rct9k-row" }, props.children);
            return (React.createElement(ItemParent, null,
                rowItemsRenderer(itemsInRow, this.props.startDate, this.props.endDate, width, this.props.itemHeight, this.props.itemRenderer, []),
                rowLayerRenderer(layersInRow, this.props.startDate, this.props.endDate, width, rowHeight)));
        };
        this.isRowLoaded = ({ index }) => {
            return !!this.groups[index];
        };
        this.selecting = false;
        this.state = { selection: [], cursorTime: null, rowCount: -1 };
        this.items = [];
        this.groups = {};
        this.rowLayers = [];
        this.cellRenderer = this.cellRenderer.bind(this);
        this.rowHeight = this.rowHeight.bind(this);
        this.setTimeMap = this.setTimeMap.bind(this);
        this.getItem = this.getItem.bind(this);
        this.changeGroup = this.changeGroup.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.getTimelineWidth = this.getTimelineWidth.bind(this);
        this.itemFromElement = this.itemFromElement.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.grid_ref_callback = this.grid_ref_callback.bind(this);
        this.select_ref_callback = this.select_ref_callback.bind(this);
        this.throttledMouseMoveFunc = _.throttle(this.throttledMouseMoveFunc.bind(this), 20);
        this.mouseMoveFunc = this.mouseMoveFunc.bind(this);
        this.getCursor = this.getCursor.bind(this);
        this.refresh = this.refresh.bind(this);
        this.key = 0;
        this.viewingWindow = { startIndex: 0, stopIndex: 25 };
        this.setTimeMap([]);
    }
    /**
     * Checks if the given bit is set in the given mask
     * @param {number} bit Bit to check
     * @param {number} mask Mask to check against
     * @returns {boolean} True if bit is set; else false
     */
    static isBitSet(bit, mask) {
        return (bit & mask) === bit;
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.props.getRowCount()
            .then(count => this.setState({ rowCount: count }));
        this.eventsSubscription = this.props.groupChangeEvents
            .subscribe((events) => {
            console.log(events.map(ev => ev.groups[0].REMAINING_ORDERS));
            events === null || events === void 0 ? void 0 : events.forEach(event => {
                let fsrEvent = event.groups[0];
                let eventTargetedIndex = parseInt(fsrEvent.INDEX);
                let useTheEventsIndex = fsr => ([eventTargetedIndex, fsr]);
                if (fsrEvent.method === RowEventType.DELETE) {
                    this.deleteRow(fsrEvent);
                }
                else if (fsrEvent.method === RowEventType.ADD) {
                    this.addRow(this.updateFromModifyEvent(event, useTheEventsIndex));
                }
                else if (fsrEvent.method === RowEventType.MODIFY) {
                    //TODO: Code assumes the event comes as an entire payload which isn't the case currently
                    let [rowId, group] = this.matchToRowIndex(fsrEvent);
                    if (parseInt(rowId) === eventTargetedIndex) {
                        console.log(`updating row: ${rowId}`);
                        this.updateTimelineData(this.updateFromModifyEvent(event, this.matchToRowIndex));
                    }
                    else { //Row is different, time to shuffle around some rows
                        console.log(this.groups);
                        console.log(`Shuffling rows, found rowId: ${rowId}, targeted index: ${eventTargetedIndex}`);
                        this.deleteRowNoUpdateCount(fsrEvent);
                        this.addRowNoUpdateCount(this.updateFromModifyEvent(event, useTheEventsIndex));
                    }
                }
            });
            this.refreshGrid();
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        [this.eventsSubscription].forEach(x => x.unsubscribe());
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.startDate.valueOf() == nextProps.startDate.valueOf()
            && this.props.endDate.valueOf() == nextProps.endDate.valueOf()) {
            //Do nothing
        }
        else {
            this.refresh();
        }
    }
    /**
     * Re-renders the grid when the window or container is resized
     */
    updateDimensions() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.forceUpdate();
            this._grid.recomputeGridSize();
        }, 100);
    }
    /**
     * Sets the internal maps used by the component for looking up item & row data
     * @param {Object[]} items The items to be displayed in the grid
     * @param {moment} startDate The visible start date of the timeline
     * @param {moment} endDate The visible end date of the timeline
     */
    setTimeMap(items, startDate = undefined, endDate = undefined) {
        _.merge(this, this.createTimeMap(items, startDate, endDate));
    }
    createTimeMap(items, startDate = undefined, endDate = undefined) {
        if (!startDate || !endDate) {
            startDate = this.props.startDate;
            endDate = this.props.endDate;
        }
        let mappedItemData = {
            itemRowMap: {},
            rowItemMap: {},
            rowHeightCache: {} // (rowNo) => max number of stacked items
        };
        let visibleItems = _.filter(items, i => {
            return i.end > startDate && i.start < endDate;
        });
        let itemRows = _.groupBy(visibleItems, 'row');
        _.forEach(itemRows, (visibleItems, row) => {
            const rowInt = parseInt(row);
            visibleItems = _.uniqBy(visibleItems, i => i.key);
            if (mappedItemData.rowItemMap[rowInt] === undefined)
                mappedItemData.rowItemMap[rowInt] = [];
            _.forEach(visibleItems, item => {
                mappedItemData.itemRowMap[item.key] = rowInt;
                mappedItemData.rowItemMap[rowInt].push(item);
            });
            mappedItemData.rowHeightCache[rowInt] = getMaxOverlappingItems(visibleItems);
        });
        return mappedItemData;
    }
    /**
     * Returns an item given its DOM element
     * @param {Object} e the DOM element of the item
     * @return {Object} Item details
     * @prop {number|string} index The item's index
     * @prop {number} rowNo The row number the item is in
     * @prop {number} itemIndex Not really used - gets the index of the item in the row map
     * @prop {Object} item The provided item object
     */
    itemFromElement(e) {
        const index = e.getAttribute('data-item-index');
        const rowNo = this.itemRowMap[index];
        const itemIndex = _.findIndex(this.rowItemMap[rowNo], i => i.key == index);
        const item = this.rowItemMap[rowNo][itemIndex];
        return { index, rowNo, itemIndex, item };
    }
    /**
     * Gets an item given its ID
     * @param {number} id item id
     * @return {Object} Item object
     */
    getItem(id) {
        // This is quite stupid and shouldn't really be needed
        const rowNo = this.itemRowMap[id];
        const itemIndex = _.findIndex(this.rowItemMap[rowNo], i => i.key == id);
        return this.rowItemMap[rowNo][itemIndex];
    }
    /**
     * Move an item from one row to another
     * @param {object} item The item object whose groups is to be changed
     * @param {number} curRow The item's current row index
     * @param {number} newRow The item's new row index
     */
    changeGroup(item, curRow, newRow) {
        item.row = newRow;
        this.itemRowMap[item.key] = newRow;
        this.rowItemMap[curRow] = this.rowItemMap[curRow].filter(i => i.key !== item.key);
        this.rowItemMap[newRow].push(item);
    }
    /**
     * Set the currently selected time ranges (for the timebar to display)
     * @param {Object[]} selections Of the form `[[start, end], [start, end], ...]`
     */
    setSelection(selections) {
        let newSelection = _.map(selections, s => {
            return { start: s[0].clone(), end: s[1].clone() };
        });
        this.setState({ selection: newSelection });
    }
    /**
     * Clears the currently selected time range state
     */
    clearSelection() {
        this.setState({ selection: [] });
    }
    /**
     * Get the width of the timeline NOT including the left group list
     * @param {?number} totalWidth Total timeline width. If not supplied we use the timeline ref
     * @returns {number} The width in pixels
     */
    getTimelineWidth(totalWidth) {
        var _a, _b;
        const { groupOffset } = this.props;
        if (totalWidth !== undefined || ((_b = (_a = this === null || this === void 0 ? void 0 : this._grid) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.width) === undefined)
            return totalWidth - groupOffset;
        return this._grid.props.width - groupOffset;
    }
    /**
     * @param {number} width container width (in px)
     */
    cellRenderer(width) {
        return ({ columnIndex, key, parent, rowIndex, style }) => {
            let itemCol = 1;
            if (itemCol == columnIndex) {
                return React.createElement(this.ItemRow, Object.assign({ key: key }, { rowIndex, style, width }));
            }
            else {
                const GroupComp = this.props.groupRenderer;
                let group = this.groups[rowIndex];
                return (React.createElement("div", { "data-row-index": rowIndex, key: key, style: style, className: "rct9k-group" },
                    React.createElement(GroupComp, { group: group })));
            }
        };
    }
    getCursor() {
        const { showCursorTime, cursorTimeFormat } = this.props;
        const { cursorTime } = this.state;
        return showCursorTime && cursorTime ? cursorTime.clone().format(cursorTimeFormat) : null;
    }
    /**
     * Helper for react virtuaized to get the row height given a row index
     */
    rowHeight({ index }) {
        let rh = this.rowHeightCache[index] ? this.rowHeightCache[index] : 1;
        return rh * this.props.itemHeight;
    }
    grid_ref_callback(reactComponent) {
        this._grid = reactComponent;
        this._gridDomNode = ReactDOM.findDOMNode(this._grid);
        return reactComponent;
    }
    select_ref_callback(reactComponent) {
        this._selectBox = reactComponent;
    }
    /**
     * Event handler for onMouseMove.
     * Only calls back if a new snap time is reached
     */
    throttledMouseMoveFunc(e) {
        const { componentId } = this.props;
        const leftOffset = document.querySelector(`.rct9k-id-${componentId} .parent-div`).getBoundingClientRect().left;
        const cursorSnappedTime = getTimeAtPixel(e.clientX - this.props.groupOffset - leftOffset, this.props.startDate, this.props.endDate, this.getTimelineWidth(), this.props.snapMinutes);
        if (!this.mouse_snapped_time || this.mouse_snapped_time.unix() !== cursorSnappedTime.unix()) {
            if (cursorSnappedTime.isSameOrAfter(this.props.startDate)) {
                this.mouse_snapped_time = cursorSnappedTime;
                this.setState({ cursorTime: this.mouse_snapped_time });
            }
        }
    }
    mouseMoveFunc(e) {
        e.persist();
        this.throttledMouseMoveFunc(e);
    }
    refresh() {
        this.loadMoreRows(this.viewingWindow)
            .then(this.refreshGrid);
    }
    render() {
        const { groupOffset, showCursorTime, timebarFormat, componentId, groupTitleRenderer, timelineRenderer, shallowUpdateCheck, forceRedrawFunc, startDate, endDate } = this.props;
        const divCssClass = `rct9k-timeline-div rct9k-id-${componentId}`;
        let varTimebarProps = {};
        if (timebarFormat)
            varTimebarProps['timeFormats'] = timebarFormat;
        function columnWidth(width) {
            return ({ index }) => {
                if (index === 0)
                    return groupOffset;
                return width - groupOffset;
            };
        }
        // Markers (only current time marker atm)
        const markers = [];
        if (showCursorTime && this.mouse_snapped_time) {
            const cursorPix = getPixelAtTime(this.mouse_snapped_time, startDate, endDate, this.getTimelineWidth());
            markers.push({
                left: cursorPix + this.props.groupOffset,
                key: 1
            });
        }
        return (React.createElement("div", { style: { height: "100%" }, className: divCssClass },
            React.createElement(AutoSizer, { className: "rct9k-autosizer", onResize: this.refreshGrid }, ({ height, width }) => (React.createElement("div", { className: "parent-div", onMouseMove: this.mouseMoveFunc },
                React.createElement(SelectBox, { ref: this.select_ref_callback }),
                React.createElement(Timebar, Object.assign({ cursorTime: this.getCursor(), start: this.props.startDate, end: this.props.endDate, width: width, leftOffset: groupOffset, selectedRanges: this.state.selection, timelineRenderer: timelineRenderer, groupTitleRenderer: groupTitleRenderer }, varTimebarProps)),
                this.state.rowCount === -1 ? React.createElement(NoResultsDiv, { width: width, height: height - 60 }, "Loading...") :
                    this.state.rowCount === 0 ? React.createElement(NoResultsDiv, { width: width, height: height - 60 }, "No results") :
                        React.createElement(SLInfiniteLoader, { windowSize: 50, isRowLoaded: this.isRowLoaded, loadMoreRows: this.loadMoreRows, rowCount: this.state.rowCount }, ({ onRowsRendered, registerChild }) => {
                            return (React.createElement(TimelineBody, { width: width, columnWidth: columnWidth(width), height: height - 60, rowHeight: this.rowHeight, rowCount: this.state.rowCount, cellRenderer: this.cellRenderer(this.getTimelineWidth(width)), grid_ref_callback: compose(registerChild, this.grid_ref_callback), shallowUpdateCheck: shallowUpdateCheck, forceRedrawFunc: forceRedrawFunc, onRowsRendered: onRowsRendered }));
                        }))))));
    }
}
Timeline.propTypes = {
    groupOffset: PropTypes.number.isRequired,
    rowLayers: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
        rowNumber: PropTypes.number.isRequired,
        style: PropTypes.object.isRequired
    })),
    filter: PropTypes.string,
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    snapMinutes: PropTypes.number,
    showCursorTime: PropTypes.bool,
    cursorTimeFormat: PropTypes.string,
    componentId: PropTypes.string,
    itemHeight: PropTypes.number,
    timebarFormat: PropTypes.object,
    itemRenderer: PropTypes.func,
    groupRenderer: PropTypes.func,
    groupTitleRenderer: PropTypes.func,
    shallowUpdateCheck: PropTypes.bool,
    forceRedrawFunc: PropTypes.func
};
Timeline.defaultProps = {
    rowLayers: [],
    groupOffset: 150,
    itemHeight: 40,
    snapMinutes: 15,
    cursorTimeFormat: 'D MMM YYYY HH:mm',
    componentId: 'r9k1',
    showCursorTime: true,
    groupRenderer: DefaultGroupRenderer,
    itemRenderer: DefaultItemRenderer,
    groupTitleRenderer: () => React.createElement("div", null),
    shallowUpdateCheck: false,
    forceRedrawFunc: null,
};
