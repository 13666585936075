import { BrowserAuthError, PublicClientApplication } from "@azure/msal-browser";
import Auth from "../../state/api/REST/Auth/Auth";
import { AppStore } from "../../App";
import { LoginPageContainerActionTypes } from "../../state/containers/LoginPageContainer/types";
export var MSALService;
(function (MSALService) {
    let msal = null;
    const scopes = ["User.Read"];
    MSALService.initialize = (clientId, tenantId, company, bu, buOptions, silentOnly) => {
        if (!msal) {
            const msalConfig = {
                auth: {
                    clientId: clientId,
                    authority: `https://login.microsoftonline.com/${tenantId}`,
                },
            };
            msal = new PublicClientApplication(msalConfig);
        }
        msal.handleRedirectPromise().then(tokenResponse => {
            const request = {
                scopes: scopes,
                prompt: 'select_account',
            };
            if (!tokenResponse && silentOnly) {
                return;
            }
            msal.acquireTokenPopup(request).then(silentTokenResponse => {
                AppStore.store.dispatch({
                    type: LoginPageContainerActionTypes.SET_ACCESS_TOKEN,
                    token: silentTokenResponse.accessToken,
                });
                if (buOptions.length === 1) {
                    Auth.login({
                        company: company,
                        bu: buOptions[0],
                        logon_type: "microsoft",
                        user_type: "Desktop",
                        access_token: silentTokenResponse.accessToken,
                        email: silentTokenResponse.account.username,
                    }).then(rsp => {
                        AppStore.store.dispatch({
                            type: LoginPageContainerActionTypes.PROCESS_TOKEN_RESPONSE,
                            data: rsp,
                            startTime: performance.now(),
                        });
                    }).catch(e => {
                        const store = AppStore.store.getState();
                        const state = store.login;
                        let error = "";
                        switch (e.response.status) {
                            case 400: {
                                error = "Bad login request.";
                                break;
                            }
                            case 401: {
                                error = "Unauthorized.";
                                break;
                            }
                            case 403: {
                                error = "Forbidden.";
                                break;
                            }
                            case 404: {
                                error = "Login endpoint is not found.";
                                break;
                            }
                            case 500: {
                                error = "Internal server error.";
                                break;
                            }
                            case 503: {
                                error = "Server is unavailable.";
                                break;
                            }
                            default: {
                                error = "Unexpected server response.";
                            }
                        }
                        console.error(e);
                        AppStore.store.dispatch({
                            type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                            state: Object.assign(Object.assign({}, state), { isLoadingComplete: true, errorMessage: error })
                        });
                    });
                }
                else {
                    const store = AppStore.store.getState();
                    const state = store.login;
                    AppStore.store.dispatch({
                        type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                        state: Object.assign(Object.assign({}, state), { isAskingForBU: true, message: "Please select BU" })
                    });
                }
            }).catch(e => {
                const store = AppStore.store.getState();
                const state = store.login;
                let errorMessage = "Cannot access microsoft login page, please try with HTTPS link";
                if (e instanceof BrowserAuthError) {
                    if (e.errorCode === "interaction_in_progress") {
                        errorMessage = "Authentication already in progress";
                    }
                    if (e.errorCode === "user_cancelled") {
                        errorMessage = "Authentication cancelled";
                    }
                }
                console.error(e);
                AppStore.store.dispatch({
                    type: LoginPageContainerActionTypes.SET_LOGIN_PAGE_CONTAINER_STATE,
                    state: Object.assign(Object.assign({}, state), { isLoadingComplete: true, errorMessage: errorMessage })
                });
            });
        });
        window.msalService = MSALService;
    };
    MSALService.signOut = () => {
        if (msal) {
            msal.logout();
        }
    };
})(MSALService || (MSALService = {}));
