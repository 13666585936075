export var LegacyScriptLoader;
(function (LegacyScriptLoader) {
    LegacyScriptLoader.loadScripts = async (scripts) => {
        await LegacyScriptLoader.clearAllScripts();
        for (const script of scripts) {
            await LegacyScriptLoader.loadScript(script);
        }
        return true;
    };
    LegacyScriptLoader.loadScript = (script) => {
        return new Promise(((resolve, reject) => {
            const element = window.document.createElement("script");
            element.type = "text/javascript";
            element.src = script;
            element.onload = () => {
                console.log(`loaded script: ${script}.`);
                resolve(true);
            };
            window.document.body.appendChild(element);
        }));
    };
    LegacyScriptLoader.clearAllScripts = () => {
        return new Promise((resolve, reject) => {
            while (window.document.body.getElementsByTagName("script").length > 0) {
                const scripts = window.document.body.getElementsByTagName("script");
                scripts[0].parentNode.removeChild(scripts[0]);
            }
            resolve(true);
        });
    };
})(LegacyScriptLoader || (LegacyScriptLoader = {}));
