var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import styled from "styled-components";
import _ from 'lodash';
import { createSelectable } from '../../../../../../vendor/react-selectable-fast/src';
import { useDrag } from "react-dnd";
import { FsrTimeTableCard } from "../../../../FsrTimeTableCard/FsrTimeTableCard";
import chroma from "chroma-js";
import { SLRawHtmlTooltip } from "../../../Material";
import { Orders } from "../../../../../../state/api/REST/Orders/Orders";
const TravelDiv = styled.div `
  background-color: #000080;
  width: ${props => `${props.ratioToTotalTime * 100.0}%`};
  top: 0;
  height: 8px;
  bottom: 0;
`;
export const customItemRenderer = (props) => {
    const { item } = props, rest = __rest(props, ["item"]);
    const originalTimeBlock = item.originalTimeBlock;
    const group = _.keyBy(originalTimeBlock.activities, (x) => x.type);
    let [zaProps, dragRef] = useDrag({ item: { type: "Order", id: group.Order.order.id } });
    let ratio = group.TravelTime ?
        (group.TravelTime.end - group.TravelTime.start)
            / (originalTimeBlock.end - originalTimeBlock.start)
        : 0.0;
    rest.style = Object.assign(Object.assign({}, rest.style), { height: 20, border: `1px solid ${chroma(item.color).darken(.8).css()}`, width: props.width - 2 });
    return (React.createElement(SLRawTooltipOrder, { orderId: group.Order.order.id },
        React.createElement("span", Object.assign({ ref: dragRef }, rest),
            React.createElement(TravelDiv, { ratioToTotalTime: ratio }))));
    function SLRawTooltipOrder({ orderId, children }) {
        let [tooltipHtml, setTooltipHtml] = useState("Getting Data...");
        return React.createElement(SLRawHtmlTooltip, { enterDelay: 1000, html: tooltipHtml, onOpen: evt => Orders.getRawHtmlTooltipForOrder(orderId).then(({ response }) => setTooltipHtml(response)) }, children);
    }
};
export const customGroupRenderer = createSelectable(FsrTimeTableCard);
