import moment from "moment";
import "fullcalendar";
import "fullcalendar-scheduler";
import { ShowStatusMessage } from "./status-message";
/**
 * @Description: Class to handle requests for appt bookings.
 * @Author: Sudharaka
 * @Date: 8/20/2018
 **/
export class ApptBookingRequests {
    constructor(day, jobCode, longitude, latitude, orderNumber, orderId) {
        this.day = day;
        this.jobCode = jobCode;
        this.longitude = longitude;
        this.latitude = latitude;
        this.orderNumber = orderNumber;
        this.orderId = orderId;
    }
    returnApptBookings(calendar, timeline = false, searchDate = null, startEndTimes = null, slot_group = null) {
        const calendarView = calendar.fullCalendar("getView");
        let startDate, endDate;
        if (calendarView) {
            startDate = calendarView.intervalStart;
            endDate = calendarView.intervalEnd;
        }
        else {
            startDate = moment(this.day);
            endDate = startDate.add(31, "days");
        }
        let requestPromise = new Promise((resolve, reject) => {
            let calEvents = [];
            let calHolidays = [];
            let requestOrderId = this.orderId;
            let requestOrderNumber = this.orderNumber;
            fetch("/sl3/api/holidays", {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                }
            })
                .then(response => response.json())
                .then(rspHolidays => {
                let holidays = rspHolidays.data;
                for (let holiday in holidays) {
                    let thisHoliday = holidays[holiday], holidayName = "  " + thisHoliday.name, //hack to put holiday as the top slot
                    holidayDate = sl.roundDateToMidnight(thisHoliday.holiday_date);
                    const holidayObj = {
                        title: holidayName,
                        start: holidayDate,
                        className: "fc-slot-holiday",
                        allDay: true,
                        color: "#FF6347"
                    };
                    calHolidays.push(holidayObj);
                    if (thisHoliday.recurring == "Y") {
                        let nextHolidayDate = moment(holidayDate);
                        for (let i = 0; i < 9; i++) {
                            let nextHolidayObj = Object.assign({}, holidayObj);
                            nextHolidayDate.add(1, "years"); // Add a year to the date
                            nextHolidayObj.start = sl.roundDateToMidnight(nextHolidayDate); // Update start date
                            calHolidays.push(nextHolidayObj);
                        }
                    }
                }
            })
                .then(() => {
                fetch("/sl3/rest/bookings/appointments/query_by_id", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                    },
                    body: JSON.stringify({
                        start_time: startDate.format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
                        end_time: endDate.format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
                        order_id: requestOrderId,
                        include_full_slots: false //Not sure what is this used for, this is placeholder for now.
                    })
                })
                    .then(response => response.json())
                    .then(rsp => {
                    if (!rsp.error) {
                        let orderEvent = null;
                        fetch("/sl3/rest/bookings/appointments/by_order_number/" + requestOrderNumber, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "X-ServiceLink-Auth": getCookieByName('X-ServiceLink-Auth')
                            }
                        })
                            .then(response => response.json())
                            .then(orderEventRsp => {
                            if (orderEventRsp.error) {
                                //if checked exceptions, dont print err message, else print
                                if (!orderEventRsp.error.reason.includes("No booking found for order_number")
                                    && !orderEventRsp.error.reason.includes("Availability does not exist")) {
                                    new ShowStatusMessage().showStatusMsg(orderEventRsp.error.reason, 1);
                                }
                            }
                            else {
                                orderEvent = orderEventRsp;
                            }
                        })
                            .catch(err => {
                            new ShowStatusMessage().showStatusMsg(err, 1);
                        }).finally(() => {
                            //availabilities == empty OK, as long as it's not null
                            if (rsp.availabilities) {
                                putCalendarEvents(rsp, orderEvent, slot_group);
                            }
                        });
                    }
                    else {
                        new ShowStatusMessage().showStatusMsg(rsp.error.reason, 1);
                    }
                })
                    .catch(err => {
                    if (err.responseText.includes("Not enough data was supplied to determine filter")) {
                        new ShowStatusMessage().showStatusMsg("Not enough data was supplied to determine filter.", 1);
                        reject();
                    }
                });
            })
                .catch(err => {
                console.error(err);
            });
            function putCalendarEvents(rsp, orderEvent = null, slot_group = null) {
                const availabilitiesArray = rsp.availabilities;
                if (orderEvent != null) {
                    availabilitiesArray.push({ distance_km: -1, availability: orderEvent });
                }
                for (let bookingQueryElement of availabilitiesArray) {
                    let availability = bookingQueryElement.availability;
                    if (availability.max_appointments != availability.appointments_booked ||
                        availability.id == 0 ||
                        bookingQueryElement.distance_km == -1) {
                        if (slot_group != null) {
                            if (availability.slot.slot_group != null && availability.slot.slot_group.name == slot_group)
                                addCalEvents(bookingQueryElement.distance_km, availability);
                            else
                                continue;
                        }
                        else {
                            addCalEvents(bookingQueryElement.distance_km, availability);
                        }
                    }
                }
                calendar.fullCalendar("removeEvents");
                calendar.fullCalendar("removeEventSources");
                calendar.fullCalendar("addEventSource", calEvents);
                calendar.fullCalendar("addEventSource", calHolidays);
                resolve();
            }
            function addCalEvents(distance_km, availability) {
                var name = availability.slot.name;
                var year = timeline ? searchDate.format("YYYY") : availability.year;
                var month = timeline
                    ? searchDate.format("MM")
                    : availability.month <= 9
                        ? "0" + availability.month
                        : availability.month;
                var day = timeline
                    ? searchDate.format("DD")
                    : availability.day <= 9
                        ? "0" + availability.day
                        : availability.day;
                var timeStart = (availability.slot.hour_start <= 9 ? "0" + availability.slot.hour_start : availability.slot.hour_start) +
                    ":" +
                    (availability.slot.min_start === 0 ? "00" : availability.slot.min_start);
                var timeEnd = (availability.slot.hour_end <= 9 ? "0" + availability.slot.hour_end : availability.slot.hour_end) +
                    ":" +
                    (availability.slot.min_end === 0 ? "00" : availability.slot.min_end);
                var timeSlot = timeStart + "-" + timeEnd;
                let color;
                if (distance_km == -1) {
                    color = "black";
                }
                if (distance_km > 10) {
                    color = "red";
                }
                else if (distance_km > 5) {
                    color = "#CCCC00";
                }
                else if (distance_km > 0) {
                    color = "green";
                }
                let distance_miles = Math.round(distance_km * 0.62137);
                let calEvt;
                if (timeline) {
                    calEvt = {
                        title: availability.id == 0 ? timeSlot + " " + distance_miles + " miles" : timeSlot + " " + name,
                        start: moment(year + "-" + month + "-" + day + "T" + timeStart + ":00"),
                        end: moment(year + "-" + month + "-" + day + "T" + timeEnd + ":00"),
                        name: name,
                        color: color,
                        resourceId: year + "-" + month + "-" + day,
                        objId: availability.id,
                        slotId: availability.id,
                        max: availability.max_appointments,
                        current: availability.appointments_booked
                    };
                }
                else {
                    calEvt = {
                        name: name,
                        title: availability.id == 0 ? timeSlot + " " + distance_miles + " miles" : timeSlot + " " + name,
                        start: moment(year + "-" + month + "-" + day + " " + timeStart + ":00"),
                        end: moment(year + "-" + month + "-" + day + "T" + timeEnd + ":00"),
                        objId: availability.id,
                        slotId: availability.id,
                        max: availability.max_appointments,
                        current: availability.appointments_booked,
                        color: color
                    };
                }
                calEvents.push(calEvt);
            } // addCalEvents
        });
        return requestPromise;
    }
}
