import React from "react";
import TableHeaderAndBodyWrapper from "./TableHeaderAndBodyWrapper/TableHeaderAndBodyWrapper";
import Title from "../Title/Title";
import styled from "styled-components";
const StyledTable = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
class Table extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = (event) => {
            if (event.key === "a" && event.ctrlKey) {
                this.props.handleSelectAll();
            }
        };
    }
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }
    render() {
        const { titleText } = this.props;
        return (React.createElement(StyledTable, { className: "table" },
            React.createElement(Title, { titleDisplayedText: titleText }),
            React.createElement(TableHeaderAndBodyWrapper, Object.assign({}, this.props))));
    }
}
export default Table;
