import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
export const StyledParagraphWrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: ${props => {
    switch (props.horizontalAlignment) {
        case "left":
            return "flex-start";
        case "center":
            return "center";
        case "right":
            return "flex-end";
    }
}};
  justify-content: ${props => {
    switch (props.verticalAlignment) {
        case "top":
            return "flex-start";
        case "center":
            return "center";
        case "bottom":
            return "flex-end";
    }
}};
  overflow: hidden;
  white-space: pre-wrap;
  user-select: none;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => props.backgroundColor};
`;
export const StyledParagraph = styled.p `
  color: ${props => props.fontColor};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  font-weight: ${props => props.fontWeight};
  margin-left: ${props => props.horizontalAlignment === "left" ? StyleUtils.getNormalizedPixelString(props.horizontalSpaceFromEdge) : 0};
  margin-right: ${props => props.horizontalAlignment === "right" ? StyleUtils.getNormalizedPixelString(props.horizontalSpaceFromEdge) : 0};
  margin-top: ${props => props.verticalAlignment === "top" ? StyleUtils.getNormalizedPixelString(props.verticalSpaceFromEdge) : 0};
  margin-bottom: ${props => props.verticalAlignment === "bottom" ? StyleUtils.getNormalizedPixelString(props.verticalSpaceFromEdge) : 0};
`;
const Paragraph = (props) => {
    const { text = "text", height = 100, width = 200, backgroundColor = "#FFFFFF", fontSize = 14, fontWeight = "normal", fontStyle = "normal", fontColor = "#000000", verticalAlignment = "center", horizontalAlignment = "center", verticalSpaceFromEdge = 0, horizontalSpaceFromEdge = 0 } = props;
    return (React.createElement(StyledParagraphWrapper, { className: "text-wrapper", height: height, width: width, backgroundColor: backgroundColor, verticalAlignment: verticalAlignment, horizontalAlignment: horizontalAlignment },
        React.createElement(StyledParagraph, { className: "text", fontSize: fontSize, fontWeight: fontWeight, fontStyle: fontStyle, fontColor: fontColor, verticalAlignment: verticalAlignment, horizontalAlignment: horizontalAlignment, verticalSpaceFromEdge: verticalSpaceFromEdge, horizontalSpaceFromEdge: horizontalSpaceFromEdge }, text)));
};
export default Paragraph;
