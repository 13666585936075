import React from "react";
import styled from "styled-components";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Label } from "office-ui-fabric-react";
import moment from "moment";
const StyledMessageResenderMessageContent = styled.div `
  width: calc(30% - 10px);
  max-width: calc(30% - 10px);
  max-height: 100%;
  padding: 5px;
  float: left;
  overflow-y: scroll;
  overflow: auto;
`;
class MessageResenderMessageContent extends React.Component {
    constructor(props) {
        super(props);
        this.handleDataChange = (event) => {
            this.props.handlePayloadChange(event.target.value);
        };
    }
    render() {
        return (React.createElement(StyledMessageResenderMessageContent, { className: "message-resender-message-content-container" }, !this.props.LocalQueueMessage ? null : (React.createElement("div", null,
            React.createElement(Label, null,
                "Message ID: ",
                this.props.LocalQueueMessage.id),
            React.createElement(Label, null,
                "Order Number: ",
                this.props.LocalQueueMessage.order_number),
            React.createElement(Label, null,
                "Outbound Queue: ",
                this.props.LocalQueueMessage.outbound_queue),
            React.createElement(Label, null,
                "Function: ",
                this.props.LocalQueueMessage.function),
            React.createElement(Label, null,
                "Is Request: ",
                this.props.LocalQueueMessage.is_request),
            React.createElement(Label, null,
                "Timestamp: ",
                moment(this.props.LocalQueueMessage.timestamp).format(userSettings.display_date_format + " HH:mm")),
            React.createElement(TextField, { label: "Payload:", className: "message-resender-message-content-payload", multiline: true, rows: 15, style: { width: "100%" }, value: this.props.LocalQueueMessage.payload, onChange: this.handleDataChange, readOnly: true })))));
    }
}
export default MessageResenderMessageContent;
