import React, { useState } from "react";
import { createStyles, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Button from "@material-ui/core/Button";
import CheckboxTreeItem from "./CheckboxTreeItem/CheckboxTreeItem";
import Typography from "@material-ui/core/Typography";
import { MapUtils } from "../../../../uils/MapUtils";
var setVisibleById = MapUtils.setVisibleById;
const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: "300px",
        width: "500px",
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    title: {
        height: "22px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
    },
    titleText: {
        fontSize: "16px",
        lineHeight: "16px",
        color: theme.palette.primary.contrastText,
    },
    treeWrapper: {
        height: "calc(100% - 50px)",
        overflowY: "scroll",
        overflowX: "hidden",
        width: "100%",
    },
    treeWrapperNoSaveButton: {
        height: "calc(100% - 22px)",
    },
    treeItem: {
        "& .MuiTreeItem-label": {
            paddingLeft: "0px",
        }
    },
    buttonWrapper: {
        height: "28px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main
    },
    button: {
        height: "18px",
    }
}));
const MapLayerTree = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { data, hasSaveButton, handleTreeDataChange, } = props;
    const [localData, setLocalData] = useState(data);
    const handleTreeItemClick = (value, id, url) => {
        setLocalData(prevState => {
            const newState = [...setVisibleById(prevState, id, value, url)];
            if (!hasSaveButton) {
                handleTreeDataChange(newState);
            }
            return newState;
        });
    };
    const handleSave = (e) => {
        handleTreeDataChange(localData);
    };
    const renderTreeItems = (treeData) => {
        const label = treeData.id === -1 ? treeData.name : `${treeData.id} - ${treeData.name}`;
        const urlId = `${treeData.url}|${treeData.id}`;
        return (React.createElement(TreeItem, { key: urlId, className: classes.treeItem, nodeId: urlId, label: React.createElement(CheckboxTreeItem, { id: treeData.id, url: treeData.url, isChecked: treeData.visible, isDisabled: treeData.disabled, label: label, handleClick: handleTreeItemClick }) }, treeData.subLayers.map((subLayer) => renderTreeItems(subLayer))));
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.title },
            React.createElement(Typography, { className: classes.titleText, variant: "h5" }, "Select Layers")),
        React.createElement("div", { className: clsx(classes.treeWrapper, {
                [classes.treeWrapperNoSaveButton]: !hasSaveButton
            }) },
            React.createElement(TreeView, { defaultCollapseIcon: React.createElement(ExpandMoreIcon, null), defaultExpandIcon: React.createElement(ChevronRightIcon, null) }, localData.map((treeData) => renderTreeItems(treeData)))),
        hasSaveButton ? (React.createElement("div", { className: classes.buttonWrapper },
            React.createElement(Button, { className: classes.button, variant: "contained", onClick: handleSave }, "Save"))) : null));
};
export default MapLayerTree;
