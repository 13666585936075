import React from "react";
import styled from "styled-components";
const StyledTestPage = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const TestPage = (props) => {
    return (React.createElement("div", { style: { height: "100%" } }));
};
export default TestPage;
