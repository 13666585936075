import React from "react";
import styled from "styled-components";
import { StyleUtils } from "../../../../helper/Style/StyleUtils";
export const StyledLogoBar = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: 100%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${props => {
    switch (props.logoAlign) {
        case "center": {
            return "center";
        }
        case "left": {
            return "flex-start";
        }
        case "right": {
            return "flex-end";
        }
    }
}};
`;
export const LogoWrapper = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: max-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  user-select: none;
  left: ${props => {
    switch (props.logoAlign) {
        case "center": {
            return "0px";
        }
        case "left": {
            return StyleUtils.getNormalizedPixelString(props.logoEdgeOffset);
        }
        case "right": {
            return StyleUtils.getNormalizedPixelString(-props.logoEdgeOffset);
        }
    }
}};
`;
export const Logo = styled.img `
  height: ${props => StyleUtils.getNormalizedPixelString(props.logoHeight)};
  width: auto;
`;
const LogoBar = (props) => {
    const { logoSrc = "/resources/images/comp.svg", logoAlign = "left", logoEdgeOffset = 20, logoHeight = 30, height = 50, backgroundColor = "#62757F" } = props;
    return (React.createElement(StyledLogoBar, { className: "logo-bar", height: height, backgroundColor: backgroundColor, logoAlign: logoAlign },
        React.createElement(LogoWrapper, { className: "logo-wrapper", height: height, logoEdgeOffset: logoEdgeOffset, logoAlign: logoAlign },
            React.createElement(Logo, { className: "logo-bar-img", src: logoSrc, logoHeight: logoHeight }))));
};
export default LogoBar;
