import { AVLPageContainerActionTypes } from "./types";
export const initialState = {
    host: "localhost",
    port: "8082",
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AVLPageContainerActionTypes.SET_CONNECTION_INFO: {
            return Object.assign(Object.assign({}, state), { host: action.host, port: action.port });
        }
        default:
            return state;
    }
};
export default reducer;
