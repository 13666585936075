import React, { useState } from "react";
import TextInput from "../TextInput/TextInput";
import styled from "styled-components";
import { StyleUtils } from "../../../../../helper/Style/StyleUtils";
import IconButton from "../../Button/IconButton/IconButton";
export const StyledPasswordInputWrapper = styled.div `
  outline: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  background-color: ${props => getVariableColor(props.backgroundColor, props.isInFocus)};
  margin: ${props => StyleUtils.getNormalizedArrayPixelString(props.margin)};
  border-width: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderWidth)};
  border-color: ${props => getVariableColor(props.borderColor, props.isInFocus)};
  border-radius: ${props => StyleUtils.getNormalizedArrayPixelString(props.borderRadius)};
  border-style: ${props => props.borderStyle};
  padding: ${props => StyleUtils.getNormalizedArrayPixelString(props.padding)};
`;
const getVariableColor = (color, isInFocus) => {
    if (typeof color === "string") {
        return color;
    }
    else {
        return isInFocus ? color.inFocus : color.normal;
    }
};
const PasswordInput = (props) => {
    const { index, className = "password-input", placeholder = "Enter your password", filterRegex, maxLength = 100, height = 16, width = 240, fontSize = 14, iconColor = "#757575", backgroundColor = "#FFFFFF", placeholderColor = "#757575", fontColor = "#000000", fontWeight = "normal", textAlign = "left", margin = 0, borderWidth = 1, borderColor = "#000000", borderRadius = 0, borderStyle = "solid", padding = 0, handleSubmit, handleChange, handleFocus, handleBlur } = props;
    const [value, setValue] = useState("");
    const [isInFocus, setIsInFocus] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const iconBackgroundColor = getVariableColor(backgroundColor, isInFocus);
    const type = isHidden ? "password" : "text";
    const iconName = isHidden ? "eye-blocked" : "eye";
    const onToggleHide = () => {
        setIsHidden(prevState => {
            return !prevState;
        });
    };
    const onFocus = () => {
        setIsInFocus(true);
        if (handleFocus) {
            handleFocus(index);
        }
    };
    const onBlur = () => {
        setIsInFocus(false);
        if (handleBlur) {
            handleBlur(index);
        }
    };
    const onSubmit = (value) => {
        if (handleSubmit) {
            handleSubmit(value, index);
        }
    };
    const onChange = (value) => {
        setValue(prevState => {
            if (handleChange) {
                handleChange(value, index);
            }
            return value;
        });
    };
    return (React.createElement(StyledPasswordInputWrapper, { className: "password-input-wrapper", isInFocus: isInFocus, height: height, width: width, backgroundColor: backgroundColor, margin: margin, borderWidth: borderWidth, borderColor: borderColor, borderRadius: borderRadius, borderStyle: borderStyle, padding: padding },
        React.createElement(TextInput, { type: type, className: className, index: index, placeholder: placeholder, filterRegex: filterRegex, maxLength: maxLength, handleFocus: onFocus, handleBlur: onBlur, handleSubmit: onSubmit, handleChange: onChange, height: height, width: StyleUtils.subtractNormalizedPixelString(width, height), fontSize: fontSize, backgroundColor: backgroundColor, placeholderColor: placeholderColor, fontColor: fontColor, fontWeight: fontWeight, textAlign: textAlign, margin: 0, borderWidth: 0, padding: 0, borderRadius: borderRadius }),
        React.createElement(IconButton, { iconName: iconName, handleClick: onToggleHide, height: height, color: iconColor, backgroundColor: { normal: iconBackgroundColor, mouseDown: iconBackgroundColor, mouseOver: iconBackgroundColor }, borderWidth: 0, borderRadius: borderRadius })));
};
export default PasswordInput;
