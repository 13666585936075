import { put, select, takeEvery } from "redux-saga/effects";
import { InventoryFilterActionTypes } from "./types";
import { FilterUtils } from "../../../helper/General/FilterUtils";
import { InventoryTableContainerActionTypes } from "../../containers/InventoryTableContainer/types";
var J_FILTER_OPERATORS = FilterUtils.J_FILTER_OPERATORS;
var combineJFilterObjects = FilterUtils.combineJFilterObjects;
export const inventoryFilterSagas = {
    updateInventoryOmniSearchFilterSaga: takeEvery(InventoryFilterActionTypes.UPDATE_INVENTORY_OMNI_SEARCH_FILTER, updateInventoryOmniSearchFilterSaga),
    updateInventoryMultiSearchFilterSaga: takeEvery(InventoryFilterActionTypes.UPDATE_INVENTORY_MULTI_FILTER, updateInventoryMultiSearchFilterSaga),
    updateInventoryWarehouseFilterSaga: takeEvery(InventoryFilterActionTypes.UPDATE_INVENTORY_WAREHOUSE_FILTER, updateInventoryWarehouseFilterSaga),
    updateTableFilterObjectSaga: takeEvery(InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT, updateTableFilterObjectSaga)
};
function* updateInventoryOmniSearchFilterSaga(action) {
    const filterState = yield select(getInventoryFilterStateSelector);
    yield put({
        type: InventoryFilterActionTypes.SET_INVENTORY_FILTER_STATE,
        inventoryFilterState: Object.assign(Object.assign({}, filterState), { inventoryOmniSearchFilter: action.filters })
    });
    yield put({
        type: InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT
    });
}
function* updateInventoryMultiSearchFilterSaga(action) {
    const filterState = yield select(getInventoryFilterStateSelector);
    yield put({
        type: InventoryFilterActionTypes.SET_INVENTORY_FILTER_STATE,
        inventoryFilterState: Object.assign(Object.assign({}, filterState), { inventoryFilter: action.filters })
    });
    yield put({
        type: InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT
    });
}
function* updateInventoryWarehouseFilterSaga(action) {
    const filterState = yield select(getInventoryFilterStateSelector);
    yield put({
        type: InventoryFilterActionTypes.SET_INVENTORY_FILTER_STATE,
        inventoryFilterState: Object.assign(Object.assign({}, filterState), { inventoryWarehouseFilter: action.filters })
    });
    yield put({
        type: InventoryFilterActionTypes.UPDATE_TABLE_FILTER_OBJECT
    });
}
function getFilterObject(filters, joinOperator) {
    return filters.length === 0
        ? null
        : filters.length > 1
            ? FilterUtils.filtersToJFilter(filters, joinOperator)
            : FilterUtils.filtersToJFilter(filters, FilterUtils.J_FILTER_JOIN_OPERATORS.and);
}
function* updateTableFilterObjectSaga(action) {
    const filterState = yield select(getInventoryFilterStateSelector);
    const allFilterObjects = [];
    const warehouseFilter = filterState.inventoryWarehouseFilter || [];
    const warehouseFilterObject = getFilterObject(warehouseFilter, FilterUtils.J_FILTER_JOIN_OPERATORS.and);
    if (warehouseFilterObject) {
        allFilterObjects.push(warehouseFilterObject);
    }
    const omniSearchFilter = filterState.inventoryOmniSearchFilter || [];
    const omniSearchFilterObject = getFilterObject(omniSearchFilter, FilterUtils.J_FILTER_JOIN_OPERATORS.or);
    if (omniSearchFilterObject) {
        allFilterObjects.push(omniSearchFilterObject);
    }
    const multiFilter = filterState.inventoryFilter || [];
    const multiFilterObject = getFilterObject(multiFilter, FilterUtils.J_FILTER_JOIN_OPERATORS.and);
    if (multiFilterObject) {
        allFilterObjects.push(multiFilterObject);
    }
    if (allFilterObjects.length === 0) {
        const globalState = yield select(getGlobalStateSelector);
        const idField = globalState.schema.itemsIndexes[0].indexes.find(index => index.value === "id").name || "Id";
        const selectAllFilter = {
            field: idField,
            operator: J_FILTER_OPERATORS.notEqual,
            value: "-1"
        };
        const combinedFilterObject = FilterUtils.filterToJFilter([selectAllFilter], FilterUtils.J_FILTER_JOIN_OPERATORS.and);
        yield put({
            type: InventoryTableContainerActionTypes.SET_TABLE_J_FILTER_OBJECT,
            filterObject: combinedFilterObject
        });
    }
    else {
        if (allFilterObjects.length === 1) {
            yield put({
                type: InventoryTableContainerActionTypes.SET_TABLE_J_FILTER_OBJECT,
                filterObject: allFilterObjects[0]
            });
        }
        else {
            yield put({
                type: InventoryTableContainerActionTypes.SET_TABLE_J_FILTER_OBJECT,
                filterObject: combineJFilterObjects(allFilterObjects, FilterUtils.J_FILTER_JOIN_OPERATORS.and)
            });
        }
    }
}
function getGlobalStateSelector(store) {
    return store.inventory.inventoryGlobalState;
}
function getInventoryFilterStateSelector(store) {
    return store.inventory.inventoryFilterState;
}
export default inventoryFilterSagas;
