import React from "react";
export const getIcoMoonIcon = (className, icon) => {
    return React.createElement(IcoMoonIcon, { className: className, icon: icon });
};
class IcoMoonIcon extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { className, icon } = this.props;
        const paths = icon.paths;
        const attrs = icon.attrs;
        const svgStyle = {
            display: "inline-block",
            verticalAlign: "middle"
        };
        return (React.createElement("svg", { className: className, style: svgStyle, viewBox: `0 0 ${icon.width || "1024"} 1024`, xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", null, paths.map((path, index) => {
                return React.createElement("path", Object.assign({ key: index, d: path }, attrs[index]));
            }))));
    }
}
export default IcoMoonIcon;
