import * as React from "react";
import Modal from "../../web/components/Modal/Modal";
import { useDispatch } from "react-redux";
import { dispatchCloseModal } from "../../../state/containers/DispatchPageContainer/actions";
import styled from "styled-components";
import TextButton from "../../web/components/Button/TextButton/TextButton";
import Spacer from "../../web/components/Spacer/Spacer";
export const Message = styled.div `
  white-space: pre;
  text-align: center;
`;
export const FlexParentCenter = styled.div `
  justify-content: center;
  flex-direction: row;
  display : flex;
`;
const ModalDialog = props => {
    const { message, title, } = props;
    const dispatch = useDispatch();
    let handleCloseInNegative = () => dispatch(dispatchCloseModal(false));
    return (React.createElement(Modal, { open: true, dimension: { width: 500, height: 300 }, title: title, handleClose: handleCloseInNegative },
        React.createElement(Message, null, message),
        React.createElement(FlexParentCenter, null,
            React.createElement(TextButton, { text: "Yes", handleClick: () => dispatch(dispatchCloseModal(true)) }),
            React.createElement(Spacer, { width: 5 }),
            React.createElement(TextButton, { text: "No", handleClick: handleCloseInNegative }))));
};
export default ModalDialog;
