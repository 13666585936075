import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { StyleUtils } from "../../../../../../../../helper/Style/StyleUtils";
import { Resizable } from "re-resizable";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
const StyledTableHeaderCell = styled.div `
  width: max-content;
  height: max-content;
  background-color: ${props => props.tableHeaderCellData.backgroundColor};
  border-style: solid;
  border-color: ${props => props.tableHeaderCellData.borderColor};
  border-width: ${props => StyleUtils.getCssPropertyFromArray(props.tableHeaderCellData.borderWidth)};
  padding: ${props => StyleUtils.getCssPropertyFromArray(props.tableHeaderCellData.padding)};
`;
class TableHeaderCell extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.defaultTableColumnOptionalProps = {
            fontColor: this.theme.palette.black,
            backgroundColor: this.theme.palette.white,
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "14px",
            padding: "2px",
            borderWidth: "1px",
            borderColor: this.theme.palette.black
        };
        this.resizableEnable = {
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
        };
        this.onResizeStartHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (this.props.tableHeaderCellActions.onResizeStartHandler) {
                this.props.tableHeaderCellActions.onResizeStartHandler(event);
            }
        };
        this.onResizeStopHandler = (event, direction, elementRef, delta) => {
            if (this.props.tableHeaderCellActions.onResizeStopHandler) {
                this.props.tableHeaderCellActions.onResizeStopHandler({
                    index: this.props.tableHeaderCellData.index,
                    width: elementRef.offsetWidth
                });
            }
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.height !== this.props.height ||
            nextProps.tableHeaderCellData.displayedText !== this.props.tableHeaderCellData.displayedText ||
            nextProps.tableHeaderCellData.index !== this.props.tableHeaderCellData.index);
    }
    render() {
        const { tableHeaderCellData, dragDropIndex, height } = this.props;
        const { index, reorderable, resizable, width, displayedText } = this.props.tableHeaderCellData;
        const tableHeaderCellDataWithDefault = Object.assign(Object.assign({}, this.props.tableHeaderCellData), { fontColor: tableHeaderCellData.fontColor || this.defaultTableColumnOptionalProps.fontColor, backgroundColor: tableHeaderCellData.backgroundColor || this.defaultTableColumnOptionalProps.backgroundColor, textAlign: tableHeaderCellData.textAlign || this.defaultTableColumnOptionalProps.textAlign, fontWeight: tableHeaderCellData.fontWeight || this.defaultTableColumnOptionalProps.fontWeight, fontSize: tableHeaderCellData.fontSize !== undefined && tableHeaderCellData.fontSize !== null
                ? tableHeaderCellData.fontSize
                : this.defaultTableColumnOptionalProps.fontSize, padding: tableHeaderCellData.padding !== undefined && tableHeaderCellData.padding !== null
                ? tableHeaderCellData.padding
                : this.defaultTableColumnOptionalProps.padding, borderWidth: tableHeaderCellData.borderWidth !== undefined && tableHeaderCellData.borderWidth !== null
                ? tableHeaderCellData.borderWidth
                : this.defaultTableColumnOptionalProps.borderWidth, borderColor: tableHeaderCellData.borderColor || this.defaultTableColumnOptionalProps.borderColor });
        const isDefaultBorderPadding = !this.props.tableHeaderCellData.padding && !this.props.tableHeaderCellData.borderWidth;
        const resizableHeight = isDefaultBorderPadding
            ? Math.max(height - 6, 0)
            : this.props.tableHeaderCellData.height;
        const resizableWidth = isDefaultBorderPadding ? Math.max(width - 6, 0) : width;
        const textStyle = {
            textAlign: tableHeaderCellDataWithDefault.textAlign,
            fontWeight: tableHeaderCellDataWithDefault.fontWeight,
            color: tableHeaderCellDataWithDefault.fontColor,
            fontSize: StyleUtils.getCssPixelString(resizableHeight),
            lineHeight: StyleUtils.getCssPixelString(resizableHeight),
            userSelect: "none"
        };
        return (React.createElement(Draggable, { key: dragDropIndex, draggableId: dragDropIndex.toString(), index: dragDropIndex, isDragDisabled: !reorderable }, (provided, snapshot) => (React.createElement(StyledTableHeaderCell, Object.assign({ className: "table-header-cell", ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, this.props, { tableHeaderCellData: tableHeaderCellDataWithDefault, isDefaultBorderPadding: isDefaultBorderPadding }), resizable ? (React.createElement(Resizable, { className: "table-header-cell-text-resizable", defaultSize: {
                width: resizableWidth,
                height: resizableHeight
            }, onResizeStart: this.onResizeStartHandler, onResizeStop: this.onResizeStopHandler, enable: this.resizableEnable, style: textStyle }, displayedText)) : (React.createElement("div", { className: "table-header-cell-text", style: Object.assign(Object.assign({}, textStyle), { height: StyleUtils.getCssPixelString(resizableHeight), width: StyleUtils.getCssPixelString(resizableWidth) }) }, displayedText))))));
    }
}
export default TableHeaderCell;
