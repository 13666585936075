import React from "react";
import styled from "styled-components";
import { getTheme } from "office-ui-fabric-react";
import MultiSelectLeftPanel from "./MultiSelectLeftPanel/MultiSelectLeftPanel";
import MultiSelectRightPanel from "./MultiSelectRightPanel/MultiSelectRightPanel";
import { partition } from "lodash";
const StyledMultiSelectWrapper = styled.div `
  width: 42.2rem;
  height: 23.2rem;
  background-color: ${props => props.backgroundColor || props.palette.white};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const MultiSelectDivider = styled.div `
  width: 0.2rem;
  height: 23rem;
  background-color: ${props => props.dividerColor || props.palette.neutralLight};
`;
class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        //Left Panel Functions
        this.handlePendingSelectChange = (data) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { pendingSelect: data })));
        };
        this.handlePendingSelectInvoke = (data) => {
            this.setState(prevState => this.handlePendingSelectInvokeGetNewState(prevState, data));
        };
        this.handlePendingSelectInvokeGetNewState = (prevState, data) => {
            const newSelected = this.sortMultiSelectData([...prevState.selected, ...data]);
            const newUnselected = this.sortMultiSelectData(this.getDifference(this.props.multiSelectData, newSelected));
            const selectedCount = newSelected.length;
            const newFilteredUnselected = this.getFilteredArray(prevState.filterString, newUnselected);
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: newUnselected,
                selected: newSelected,
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: newSelected, unselected: newUnselected, filteredUnselected: newFilteredUnselected, selectedCount: selectedCount, pendingUnselect: [], pendingSelect: [], selectedPerformClearSelect: true });
        };
        this.handlePersistPendingSelect = () => {
            this.setState(prevState => this.handlePersistPendingSelectGetNewState(prevState));
        };
        this.handlePersistPendingSelectGetNewState = (prevState) => {
            const newSelected = this.sortMultiSelectData([
                ...prevState.selected,
                ...prevState.pendingSelect
            ]);
            const newUnselected = this.sortMultiSelectData(this.getDifference(this.props.multiSelectData, newSelected));
            const selectedCount = newSelected.length;
            const newFilteredUnselected = this.getFilteredArray(prevState.filterString, newUnselected);
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: newUnselected,
                selected: newSelected,
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: newSelected, unselected: newUnselected, filteredUnselected: newFilteredUnselected, selectedCount: selectedCount, pendingUnselect: [], pendingSelect: [], selectedPerformClearSelect: true });
        };
        this.handleAllSelect = () => {
            this.setState(prevState => this.handleAllSelectGetNewState(prevState));
        };
        this.handleAllSelectGetNewState = (prevState) => {
            const newSelected = this.sortMultiSelectData(this.props.multiSelectData);
            const multiSelectUpdateEvent = {
                filteredUnselected: [],
                unselected: [],
                selected: newSelected,
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: newSelected, unselected: [], filteredUnselected: [], selectedCount: prevState.totalCount, pendingUnselect: [], pendingSelect: [] });
        };
        this.handleSelectedFinishClearSelect = () => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { selectedPerformClearSelect: false })));
        };
        //Right Panel Functions
        this.handlePendingUnselectChange = (data) => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { pendingUnselect: data })));
        };
        this.handlePendingUnselectInvoke = (data) => {
            this.setState(prevState => this.handlePendingUnselectInvokeGetNewState(prevState, data));
        };
        this.handlePendingUnselectInvokeGetNewState = (prevState, data) => {
            const newUnselected = this.sortMultiSelectData([...prevState.unselected, ...data]);
            const newSelected = this.sortMultiSelectData(this.getDifference(this.props.multiSelectData, newUnselected));
            const selectedCount = newSelected.length;
            const newFilteredUnselected = this.getFilteredArray(prevState.filterString, newUnselected);
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: newUnselected,
                selected: newSelected,
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: newSelected, unselected: newUnselected, filteredUnselected: newFilteredUnselected, selectedCount: selectedCount, unselectedPerformClearSelect: true });
        };
        this.handlePersistPendingUnselect = () => {
            this.setState(prevState => this.handlePersistPendingUnselectGetNewState(prevState));
        };
        this.handlePersistPendingUnselectGetNewState = (prevState) => {
            const newUnselected = this.sortMultiSelectData([
                ...prevState.unselected,
                ...prevState.pendingUnselect
            ]);
            const newSelected = this.sortMultiSelectData(this.getDifference(this.props.multiSelectData, newUnselected));
            const selectedCount = newSelected.length;
            const newFilteredUnselected = this.getFilteredArray(prevState.filterString, newUnselected);
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: newUnselected,
                selected: newSelected,
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: newSelected, unselected: newUnselected, filteredUnselected: newFilteredUnselected, selectedCount: selectedCount, pendingUnselect: [], pendingSelect: [], unselectedPerformClearSelect: true });
        };
        this.handleAllUnselect = () => {
            this.setState(prevState => this.handleAllUnselectGetNewState(prevState));
        };
        this.handleAllUnselectGetNewState = (prevState) => {
            const newUnselected = this.sortMultiSelectData(this.props.multiSelectData);
            const newFilteredUnselected = this.sortMultiSelectData(this.getFilteredArray(prevState.filterString, this.props.multiSelectData));
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: newUnselected,
                selected: [],
                filterString: prevState.filterString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selected: [], unselected: newUnselected, filteredUnselected: newFilteredUnselected, selectedCount: 0, pendingUnselect: [], pendingSelect: [] });
        };
        this.handleUnselectedFinishClearSelect = () => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { unselectedPerformClearSelect: false })));
        };
        //General Functions
        this.toggleOpposite = (event) => {
            this.setState(prevState => this.toggleOppositeGetNewState(event, prevState));
        };
        this.toggleOppositeGetNewState = (event, prevState) => {
            const newOpposite = event.isChecked;
            const multiSelectUpdateEvent = {
                filteredUnselected: prevState.filteredUnselected,
                unselected: prevState.unselected,
                selected: prevState.selected,
                filterString: prevState.filterString,
                opposite: newOpposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { opposite: newOpposite });
        };
        this.handleSearchBarChange = (searchString) => {
            this.setState(prevState => this.handleSearchBarChangeGetNewState(searchString, prevState));
        };
        this.handleSearchBarChangeGetNewState = (searchString, prevState) => {
            const newFilteredUnselected = searchString
                ? this.getFilteredArray(searchString, prevState.unselected)
                : prevState.unselected;
            const multiSelectUpdateEvent = {
                filteredUnselected: newFilteredUnselected,
                unselected: prevState.unselected,
                selected: prevState.selected,
                filterString: searchString,
                opposite: prevState.opposite
            };
            this.props.multiSelectUpdated(multiSelectUpdateEvent);
            return Object.assign(Object.assign({}, prevState), { selectedPerformClearSelect: true, unselectedPerformClearSelect: true, pendingSelect: [], pendingUnselect: [], filteredUnselected: newFilteredUnselected, filterString: searchString });
        };
        this.sortMultiSelectData = (array) => {
            return array.sort(function (a, b) {
                if (a.displayedText === b.displayedText) {
                    return 0;
                }
                if (a.displayedText < b.displayedText) {
                    return -1;
                }
                else {
                    return 1;
                }
            });
        };
        this.getDifference = (set, subSet) => {
            return set.filter(element => {
                let matching = false;
                for (let index = 0; index < subSet.length; index++) {
                    if (subSet[index].displayedText === element.displayedText) {
                        matching = true;
                        break;
                    }
                }
                return !matching;
            });
        };
        this.getFilteredArray = (searchString, unfilteredArray) => {
            if (!searchString) {
                return unfilteredArray;
            }
            else {
                return unfilteredArray.filter(data => data.displayedText.toLowerCase().includes(searchString.toLowerCase()));
            }
        };
        let selectedSet = new Set(this.props.selectedKeys);
        let [selected, unselected] = partition(this.sortMultiSelectData(this.props.multiSelectData), x => selectedSet.has(x.key));
        this.state = {
            totalCount: this.props.multiSelectData.length,
            selectedCount: selected.length,
            filteredUnselected: unselected,
            unselected: unselected,
            pendingUnselect: [],
            selected: selected,
            pendingSelect: [],
            opposite: false,
            selectedPerformClearSelect: false,
            unselectedPerformClearSelect: false,
            filterString: ""
        };
    }
    render() {
        const { unselected, pendingUnselect, selected, pendingSelect, totalCount, selectedCount, selectedPerformClearSelect, unselectedPerformClearSelect, filteredUnselected, opposite } = this.state;
        const { backgroundColor, borderColor, selectColor, fontColor, showOpposite, dividerColor } = this.props;
        return (React.createElement(StyledMultiSelectWrapper, Object.assign({ className: "multi-select-wrapper" }, this.theme, { backgroundColor: backgroundColor }),
            React.createElement(MultiSelectLeftPanel, { unselected: filteredUnselected, pendingSelect: pendingSelect, showOpposite: showOpposite, backgroundColor: backgroundColor, borderColor: borderColor, selectColor: selectColor, fontColor: fontColor, selectedPerformClearSelect: unselectedPerformClearSelect, handleToggleOpposite: this.toggleOpposite, handleSelectedFinishClearSelect: this.handleUnselectedFinishClearSelect, handlePendingSelectChange: this.handlePendingSelectChange, handlePendingSelectInvoke: this.handlePendingSelectInvoke, handlePersistPendingSelect: this.handlePersistPendingSelect, handleAllSelect: this.handleAllSelect, handleSearchBarChange: this.handleSearchBarChange }),
            React.createElement(MultiSelectDivider, Object.assign({ className: "multi-select-divider" }, this.theme, { dividerColor: dividerColor })),
            React.createElement(MultiSelectRightPanel, { totalCount: totalCount, selectedCount: selectedCount, selected: selected, pendingUnselect: pendingUnselect, backgroundColor: backgroundColor, borderColor: borderColor, selectColor: selectColor, fontColor: fontColor, unselectedPerformClearSelect: selectedPerformClearSelect, handleUnselectedFinishClearSelect: this.handleSelectedFinishClearSelect, handlePendingUnselectChange: this.handlePendingUnselectChange, handlePendingUnselectInvoke: this.handlePendingUnselectInvoke, handlePersistPendingUnselect: this.handlePersistPendingUnselect, handleAllUnselect: this.handleAllUnselect })));
    }
}
export default MultiSelect;
