export const getOrderFromServerOrder = (serverOrder) => {
    const children = {};
    if (serverOrder.child_order_map) {
        Object.entries(serverOrder.child_order_map).forEach(([key, value]) => {
            children[key] = getOrderFromServerOrder(value);
        });
    }
    return {
        id: serverOrder.id,
        name: serverOrder.name,
        number: serverOrder.number,
        buId: serverOrder.bu_id,
        companyId: serverOrder.company_id,
        state: serverOrder.state,
        stateId: serverOrder.state_id,
        orderTypeId: serverOrder.order_type_id,
        parentId: serverOrder.parent_id,
        children: children,
        jobCode: {
            jobCode1Id: serverOrder.job_code_id1,
            jobCode2Id: serverOrder.job_code_id2,
            jobCode3Id: serverOrder.job_code_id3,
            jobCodeIds: serverOrder.job_code_ids,
        },
        originatorId: serverOrder.originator_id,
        isEmergency: serverOrder.emergency,
        isStale: serverOrder.stale,
        isDelivered: serverOrder.delivered,
        isForced: serverOrder.forced,
        isGeoCoded: serverOrder.geocoded,
        isDuplicate: serverOrder.duplicate,
        duplicateNumber: serverOrder.duplicate_number,
        location: {
            lat: serverOrder.lat,
            lon: serverOrder.lon,
            city: serverOrder.city,
            address: serverOrder.address,
            street: serverOrder.street,
            areaId: serverOrder.area_id,
        },
        assignment: {
            assignedFsrId: serverOrder.assigned_fsr_id,
            oldAssignedFsrId: serverOrder.old_assigned_fsr_id,
            groupIds: serverOrder.group_ids,
            isGrouped: serverOrder.grouped,
            priority: serverOrder.priority,
            priorityId: serverOrder.priority_id,
        },
        appointment: {
            type: serverOrder.appointment_type,
            typeId: serverOrder.appoint_type_id,
            start: serverOrder.appt_start_time,
            end: serverOrder.appt_end_time,
        },
        schedule: {
            creationDate: serverOrder.creation_date,
            creationStatusId: serverOrder.creation_status_id,
            completionDate: serverOrder.completion_date,
            completionTime: serverOrder.completion_time,
            dateRange: serverOrder.date_range,
            dueDate: serverOrder.due_date,
            estimatedTravelTime: serverOrder.estimated_travel_time,
            eta: serverOrder.eta,
            routeDistance: serverOrder.route_distance,
            routeSequenceNumber: serverOrder.route_sequence_number,
            routeTime: serverOrder.route_time,
            scheduledUndated: serverOrder.scheduled_undated,
            segmentPercent: serverOrder.segment_percent,
            seqNumber: serverOrder.seq_number,
            travelTime: serverOrder.travel_time,
            isUndated: serverOrder.undated,
            windowType: serverOrder.window_type,
        },
        project: {
            projectNumber: serverOrder.project_number,
            isProjectParent: serverOrder.project_parent,
            isProjectChild: serverOrder.project_child,
        },
        misc: {
            isActionInProgress: serverOrder.action_in_progress,
            isBlackoutPeriod: serverOrder.blackout_period,
            isParked: serverOrder.parked,
            isLongCycle: serverOrder.long_cycle,
            addRemove: serverOrder.add_remove,
            branchCode: serverOrder.branch_code,
            branchKey: serverOrder.branch_key,
            convexHullArea: serverOrder.convex_hull_area,
            dependencies: serverOrder.dependencies,
            districtCode: serverOrder.district_code,
            extensionValues: serverOrder.extension_values,
            featureId: serverOrder.feature_id,
            filterKey: serverOrder.filter_key,
            hierarchicalValues: serverOrder.hierarchical_values,
            neighboursDistance: serverOrder.neighbours_distance,
            proximityNotificationRadius: serverOrder.proximity_notification_radius,
            queueName: serverOrder.queue_name,
            values: serverOrder.values,
        }
    };
};
