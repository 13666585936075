import { InventoryOmniSearchContainerActionTypes } from "./types";
export const initialState = {
    searchString: "",
    searchFields: [
        "OpenIndex1",
        "OpenIndex2",
        "OpenIndex3",
        "OpenIndex5",
        "Country",
        "StateProvince",
        "City",
        "Address",
        "Zip"
    ]
};
export function inventoryOmniSearchContainerReducer(state = initialState, action) {
    switch (action.type) {
        case InventoryOmniSearchContainerActionTypes.SET_INVENTORY_OMNI_SEARCH_CONTAINER_STATE: {
            return action.inventoryOmniSearchContainerState;
        }
        default:
            return state;
    }
}
