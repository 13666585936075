import axios from "axios";
import { SLRestEndpoint } from "../types";
import { getOrderFromServerOrder } from "./types";
import Cookies from "universal-cookie";
export var OrderAPI;
(function (OrderAPI) {
    OrderAPI.OrderServiceName = "order";
    let OrderEndpointName;
    (function (OrderEndpointName) {
        OrderEndpointName["GET_ORDERS_BY_ORDER_NUMBERS"] = "get_orders_by_order_numbers";
        OrderEndpointName["GET_ORDERS_BY_ORDER_IDS"] = "get_orders_by_order_ids";
    })(OrderEndpointName = OrderAPI.OrderEndpointName || (OrderAPI.OrderEndpointName = {}));
    OrderAPI.getOrdersByOrderNumbers = (req) => {
        const serverReq = {
            order_numbers: req.orderNumbers,
        };
        const cookie = new Cookies();
        return axios({
            method: "POST",
            url: generateURL(OrderEndpointName.GET_ORDERS_BY_ORDER_NUMBERS),
            data: serverReq,
            headers: {
                "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
            }
        }).then(rsp => {
            return rsp.data.orders.map(serverOrder => getOrderFromServerOrder(serverOrder));
        });
    };
    OrderAPI.getOrdersByOrderIds = (req) => {
        const serverReq = {
            order_ids: req.orderIds,
        };
        const cookie = new Cookies();
        return axios({
            method: "POST",
            url: generateURL(OrderEndpointName.GET_ORDERS_BY_ORDER_IDS),
            data: serverReq,
            headers: {
                "X-ServiceLink-Auth": cookie.get("X-ServiceLink-Auth")
            }
        }).then(rsp => {
            return rsp.data.orders.map(serverOrder => getOrderFromServerOrder(serverOrder));
        });
    };
    const generateURL = (endpoint) => {
        return `${SLRestEndpoint}/${OrderAPI.OrderServiceName}/${endpoint}`;
    };
})(OrderAPI || (OrderAPI = {}));
export default OrderAPI;
