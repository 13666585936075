import { PasswordChangePageContainerActionTypes } from "./types";
export const initialState = {
    isInitialized: false,
    shouldGoBack: false,
    errorMessage: "",
    message: "",
    isLoadingComplete: true,
    width: 430
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PasswordChangePageContainerActionTypes.SET_STATE: {
            return action.state;
        }
        default:
            return state;
    }
};
export default reducer;
