import React, { useState } from "react";
import FilterComponent from "../../Filter/FilterComponent";
import Modal from "../../Modal/Modal";
import { FilterUtils } from "../../../../../helper/General/FilterUtils";
import TextButton from "../../Button/TextButton/TextButton";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
import styled from "styled-components";
const StyledFilterPickerContainer = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const StyledButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
`;
const StyledFilterWrapper = styled.div `
  height: ${props => props.editable ? "calc(100% - 30px)" : "100%"};
  width: 100%;
`;
const FilterEditScreen = (props) => {
    const { open, windowTitle, buttonHeight, editable, saveButtonText = "Apply", handleClose, filterFields, filters, handleFiltersSave, } = props;
    const filterFieldKeys = filters.map(filter => {
        return FilterUtils.getFilterFieldKey(filter);
    });
    const getInitialDisplayedFilterFields = (filterFields) => {
        return filterFields.map(filterField => {
            const displayed = filterFieldKeys.includes(filterField.field) || filterField.primary;
            return Object.assign(Object.assign({}, filterField), { displayed: displayed });
        });
    };
    const [filterPickerOpen, setFilterPickerOpen] = useState(false);
    const [filterChanged, setFilterChanged] = useState(false);
    const [displayedFilterFields, setDisplayedFilterFields] = useState(getInitialDisplayedFilterFields(filterFields));
    const [currentFilters, setCurrentFilters] = useState(filters);
    const handleFilterPickerOpen = () => {
        setFilterPickerOpen(true);
    };
    const handleFilterPickerClose = () => {
        setFilterPickerOpen(false);
    };
    const handleSave = () => {
        handleFiltersSave({
            $and: currentFilters,
        });
        handleFilterClose();
    };
    const handleFilterClose = () => {
        setFilterChanged(false);
        handleClose();
    };
    const handleDisplayedFilterFieldsChange = (event) => {
        setDisplayedFilterFields(prevState => {
            const newDisplayedFilterFields = prevState.map(displayedFilterField => {
                const selectedKeys = event.selected.map(field => field.key);
                const displayed = selectedKeys.includes(displayedFilterField.field);
                return Object.assign(Object.assign({}, displayedFilterField), { displayed: displayed });
            });
            return newDisplayedFilterFields;
        });
    };
    const handleFilterChange = (e) => {
        if (!filterChanged) {
            setCurrentFilters(filters);
            setFilterChanged(true);
        }
        setCurrentFilters(prevState => {
            let filterArray = currentFilters;
            if (e.filterObject) {
                const prevFilterKeys = prevState.map(filter => FilterUtils.getFilterFieldKey(filter));
                const exists = e.key === "JOB_CODE_ID1|JOB_CODE_ID2|JOB_CODE_ID3" ? prevFilterKeys.includes("JOB_CODE_ID1") : prevFilterKeys.includes(e.key);
                if (exists) {
                    filterArray = prevState.map(filter => {
                        const prevFilterKey = FilterUtils.getFilterFieldKey(filter);
                        const isMatchingFilter = e.key === "JOB_CODE_ID1|JOB_CODE_ID2|JOB_CODE_ID3" ? prevFilterKey === "JOB_CODE_ID1" : prevFilterKey === e.key;
                        if (isMatchingFilter) {
                            return e.filterObject;
                        }
                        else {
                            return filter;
                        }
                    });
                }
                else {
                    filterArray = [...prevState, e.filterObject];
                }
            }
            else {
                filterArray = prevState.filter(filter => {
                    return FilterUtils.getFilterFieldKey(filter) !== e.key;
                });
            }
            return filterArray;
        });
    };
    const displayedFilterSelectData = displayedFilterFields.map(displayedFilterField => ({
        key: displayedFilterField.field,
        displayedText: displayedFilterField.name,
    }));
    const displayedFilterSelectInitialSelect = displayedFilterFields.filter(displayedFilterField => displayedFilterField.displayed).map(displayedFilterField => displayedFilterField.field);
    const sortedDisplayedFilterFields = displayedFilterFields.filter(field => field.displayed).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        else if (a.name > b.name) {
            return 1;
        }
        else {
            return 0;
        }
    });
    return (React.createElement(Modal, { open: open, title: windowTitle, handleClose: handleFilterClose, dimension: { width: 1000, height: 600 } },
        React.createElement(StyledFilterWrapper, { editable: editable },
            React.createElement(FilterComponent, { filters: filters, filterFields: sortedDisplayedFilterFields, handleFilterChange: handleFilterChange })),
        editable ? (React.createElement(StyledButtonContainer, null,
            React.createElement(TextButton, { text: "Close", color: "#FFFFFF", backgroundColor: "#3F51B5", borderRadius: 5, margin: [0, 0, 0, 100], borderWidth: 0, handleClick: handleFilterClose }),
            React.createElement(TextButton, { text: saveButtonText, color: "#FFFFFF", backgroundColor: { normal: "#3F51B5", mouseOver: "#3F51B5", mouseDown: "#3F51B5", disabled: "#888888" }, borderRadius: 5, margin: [0, 0, 0, 0], borderWidth: 0, handleClick: handleSave, isEnabled: filterChanged }),
            React.createElement(TextButton, { text: "More Filters", color: "#FFFFFF", backgroundColor: "#3F51B5", borderRadius: 5, margin: [0, 100, 0, 0], borderWidth: 0, handleClick: handleFilterPickerOpen }))) : null,
        React.createElement(Modal, { open: filterPickerOpen, title: "Select Filters", handleClose: handleFilterPickerClose, dimension: { height: 350, width: 500 } },
            React.createElement(StyledFilterPickerContainer, null,
                React.createElement(MultiSelect, { multiSelectData: displayedFilterSelectData, selectedKeys: displayedFilterSelectInitialSelect, multiSelectUpdated: handleDisplayedFilterFieldsChange })))));
};
export default FilterEditScreen;
