import React from "react";
import { MapIconCommonProperties } from "../../types";
import { StyleUtils } from "../../../../../../helper/Style/StyleUtils";
import ThemeContextProvider from "../../../../../theme/ThemeContextProvider";
import styled from "styled-components";
import Spacer from "../../../Spacer/Spacer";
import MapPopupRow from "./MapPopupRow/MapPopupRow";
export const StyledMapPopup = styled.div `
  height: ${props => props.height != null ? StyleUtils.getNormalizedPixelString(props.height) : "100%"};
  font-size: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  line-height: ${props => StyleUtils.getNormalizedPixelString(props.fontSize)};
  width: 100%;
  background-color: #FFFFFF;
  color: ${props => props.color};
`;
export const StyledMapPopupRowWrapper = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const MapPopup = (props) => {
    const { mapPopupRows, fontColor = "#666666", borderColor = "#C8C8C8", highlightColor = MapIconCommonProperties.HighlightColor, fontSize = 9, rowHeight = 14, rowSeparationHeight = 2, handleSelect, handleUnselect } = props;
    if (mapPopupRows.length > 0) {
        return (React.createElement(ThemeContextProvider, null,
            React.createElement(StyledMapPopup, { className: "map-popup", color: fontColor, height: (rowHeight + rowSeparationHeight) * mapPopupRows.length, fontSize: fontSize }, mapPopupRows.map((mapPopupRow, index) => (React.createElement(StyledMapPopupRowWrapper, { className: "map-popup-row-wrapper", key: index, height: rowHeight + rowSeparationHeight },
                React.createElement(Spacer, { height: rowSeparationHeight }),
                React.createElement(MapPopupRow, { selected: mapPopupRow.selected, id: mapPopupRow.id, type: mapPopupRow.type, legacyIdentifier: mapPopupRow.legacyIdentifier, displayedIcon: mapPopupRow.displayedIcon, displayedIconColor: mapPopupRow.displayedIconColor, displayedIconBackgroundColor: mapPopupRow.displayedIconBackgroundColor, displayedText: mapPopupRow.displayedText, fontColor: fontColor, borderColor: borderColor, highlightColor: highlightColor, fontSize: fontSize, rowHeight: rowHeight, handleSelect: handleSelect, handleUnselect: handleUnselect })))))));
    }
    else {
        return (React.createElement(StyledMapPopup, { className: "map-popup", color: fontColor, height: rowHeight, fontSize: fontSize }, "Please zoom in to see more details."));
    }
};
export default MapPopup;
