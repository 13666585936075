import React from "react";
import IconButton from "../../../../Button/IconButton/IconButton";
import Spacer from "../../../../Spacer/Spacer";
import Text from "../../../../Text/Text";
import styled from "styled-components";
import { StyleUtils } from "../../../../../../../helper/Style/StyleUtils";
export const StyledMapPopupRow = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: 100%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
`;
export const StyledIconWrapper = styled.div `
  height: ${props => StyleUtils.getNormalizedPixelString(props.height)};
  width: ${props => StyleUtils.getNormalizedPixelString(props.width)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;
const MapPopupRow = (props) => {
    const { selected, id, type, legacyIdentifier, displayedIcon, displayedIconColor, displayedIconBackgroundColor, displayedText, fontColor, borderColor, fontSize, rowHeight, highlightColor, handleSelect, handleUnselect } = props;
    const onClick = () => {
        if (selected) {
            handleUnselect({
                type: type,
                id: id,
                legacyIdentifier: legacyIdentifier
            });
        }
        else {
            handleSelect({
                type: type,
                id: id,
                legacyIdentifier: legacyIdentifier
            });
        }
    };
    const backgroundColor = selected ? highlightColor : null;
    return (React.createElement(StyledMapPopupRow, { className: "map-popup-row", height: rowHeight, backgroundColor: backgroundColor },
        React.createElement(StyledIconWrapper, { className: "map-popup-row-icon-wrapper", height: rowHeight, width: rowHeight * 2 },
            React.createElement(IconButton, { handleClick: onClick, iconName: displayedIcon, height: rowHeight - 2, width: rowHeight * 2 - 2, iconHeight: fontSize, iconWidth: fontSize, backgroundColor: displayedIconBackgroundColor, color: displayedIconColor, borderRadius: 3, borderWidth: 1, borderColor: borderColor })),
        React.createElement(Spacer, { width: rowHeight / 2 }),
        React.createElement(Text, { text: displayedText, height: rowHeight, width: "max-content", horizontalAlignment: "left", fontColor: fontColor, fontSize: rowHeight })));
};
export default MapPopupRow;
