import React from "react";
import styled from "styled-components";
import OmniSearch from "../../components/OmniSearch/OmniSearch";
import { getTheme } from "office-ui-fabric-react";
import { connect } from "react-redux";
import { InventoryOmniSearchContainerActionTypes } from "../../../state/containers/InventoryOmniSearchContainer/types";
const StyledInventoryOmniSearchContainer = styled.div `
  height: 100%;
  width: 100%;
  background-color: ${props => props.palette.white};
`;
class InventoryOmniSearchContainer extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
    }
    render() {
        return (React.createElement(StyledInventoryOmniSearchContainer, Object.assign({ className: "inventory-omni-search-container" }, this.theme),
            React.createElement(OmniSearch, { title: "Inventory Omnisearch", searchChangeDebounceTime: 250, handleSearchChange: this.props.handleOmniSearchChange })));
    }
}
const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleOmniSearchChange(string) {
            dispatch({
                type: InventoryOmniSearchContainerActionTypes.HANDLE_SEARCH_CHANGE,
                searchString: string
            });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryOmniSearchContainer);
