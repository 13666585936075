import React, { Component } from 'react';
import { castTouchToMouseEvent, detectMouseButton, doObjectsCollide, getBoundsForNode, isNodeInRoot, noop } from './utils';
import SelectableGroupContext from './Context';
import Selectbox from './Selectbox';
class SelectableGroup extends Component {
    constructor() {
        super(...arguments);
        this.state = { selectionMode: false };
        this.mouseDownStarted = false;
        this.mouseMoveStarted = false;
        this.mouseMoved = false;
        this.mouseUpStarted = false;
        this.selectionStarted = false;
        this.deselectionStarted = false;
        this.mouseDownData = {
            selectboxY: 0,
            selectboxX: 0,
            target: null
        };
        this.registry = new Set();
        this.selectedItems = new Set();
        this.selectingItems = new Set();
        this.ignoreCheckCache = new Map();
        this.ignoreList = this.props.ignoreList.concat(['.selectable-select-all', '.selectable-deselect-all']);
        this.ignoreListNodes = [];
        this.selectbox = null;
        this.selectableGroup = null;
        this.scrollContainer = null;
        this.maxScrollTop = 0;
        this.maxScrollLeft = 0;
        this.scrollBounds = null;
        this.updateRegistry = () => {
            const containerScroll = {
                scrollTop: this.scrollContainer.scrollTop,
                scrollLeft: this.scrollContainer.scrollLeft
            };
            for (const selectableItem of this.registry.values()) {
                selectableItem.registerSelectable(containerScroll);
            }
        };
        this.registerSelectable = (selectableItem) => {
            this.registry.add(selectableItem);
            if (selectableItem.state.isSelected) {
                this.selectedItems.add(selectableItem);
            }
        };
        this.unregisterSelectable = (selectableItem) => {
            this.registry.delete(selectableItem);
            const isHandled = this.selectedItems.has(selectableItem) || this.selectingItems.has(selectableItem);
            this.selectedItems.delete(selectableItem);
            this.selectingItems.delete(selectableItem);
            if (isHandled) {
                // Notify third-party dev that component did unmount and handled item probably should be deleted
                this.props.onSelectionFinish([...this.selectedItems]);
            }
        };
        this.updateContainerScroll = (evt) => {
            const { scrollTop, scrollLeft } = this.scrollContainer;
            this.checkScrollTop(evt.clientY, scrollTop);
            this.checkScrollBottom(evt.clientY, scrollTop);
            this.checkScrollLeft(evt.clientX, scrollLeft);
            this.checkScrollRight(evt.clientX, scrollLeft);
        };
        this.getScrollStep = (offset) => {
            const { minimumSpeedFactor, scrollSpeed } = this.props;
            return Math.max(offset, minimumSpeedFactor) * scrollSpeed;
        };
        this.checkScrollTop = (clientY, currentTop) => {
            const offset = this.scrollBounds.top - clientY;
            if (offset > 0 || clientY < 0) {
                this.scrollContainer.scrollTop = currentTop - this.getScrollStep(offset);
            }
        };
        this.checkScrollBottom = (clientY, currentTop) => {
            const offset = clientY - this.scrollBounds.bottom;
            if (offset > 0 || clientY > window.innerHeight) {
                const newTop = currentTop + this.getScrollStep(offset);
                this.scrollContainer.scrollTop = Math.min(newTop, this.maxScrollTop);
            }
        };
        this.checkScrollLeft = (clientX, currentLeft) => {
            const offset = this.scrollBounds.left - clientX;
            if (offset > 0 || clientX < 0) {
                const newLeft = currentLeft - this.getScrollStep(offset);
                this.scrollContainer.scrollLeft = newLeft;
            }
        };
        this.checkScrollRight = (clientX, currentLeft) => {
            const offset = clientX - this.scrollBounds.right;
            if (offset > 0 || clientX > window.innerWidth) {
                const newLeft = currentLeft + this.getScrollStep(offset);
                this.scrollContainer.scrollLeft = Math.min(newLeft, this.maxScrollLeft);
            }
        };
        this.updateSelectBox = (event) => {
            const evt = castTouchToMouseEvent(event);
            this.updateContainerScroll(evt);
            if (this.mouseMoveStarted) {
                return;
            }
            this.mouseMoveStarted = true;
            this.mouseMoved = true;
            const { mouseDownData } = this;
            const { clientX, clientY } = evt;
            const { scrollLeft, scrollTop } = this.scrollContainer;
            const pointY = clientY - this.scrollBounds.top + scrollTop;
            const selectboxY = Math.min(pointY, mouseDownData.selectboxY);
            const pointX = clientX - this.scrollBounds.left + scrollLeft;
            const selectboxX = Math.min(pointX, mouseDownData.selectboxX);
            this.selectbox.setState({
                x: selectboxX,
                y: selectboxY,
                isSelecting: true,
                width: Math.abs(pointX - mouseDownData.selectboxX),
                height: Math.abs(pointY - mouseDownData.selectboxY)
            }, () => {
                this.updateSelecting();
                this.props.duringSelection([...this.selectingItems]);
                this.mouseMoveStarted = false;
            });
        };
        this.updateSelecting = () => {
            const selectboxNode = this.selectbox.getRef();
            if (!selectboxNode) {
                return;
            }
            const selectboxBounds = getBoundsForNode(selectboxNode);
            this.selectItems(Object.assign(Object.assign({}, selectboxBounds), { offsetWidth: selectboxBounds.offsetWidth || 1, offsetHeight: selectboxBounds.offsetHeight || 1 }));
        };
        this.selectItems = (selectboxBounds, options = {}) => {
            const { tolerance, enableDeselect, mixedDeselect } = this.props;
            selectboxBounds.top += this.scrollContainer.scrollTop;
            selectboxBounds.left += this.scrollContainer.scrollLeft;
            for (const item of this.registry.values()) {
                this.processItem({
                    item,
                    selectboxBounds,
                    tolerance: tolerance,
                    mixedDeselect: mixedDeselect,
                    enableDeselect: enableDeselect,
                    isFromClick: options && options.isFromClick
                });
            }
        };
        this.clearSelection = () => {
            for (const item of this.selectedItems.values()) {
                item.setState({ isSelected: false });
                this.selectedItems.delete(item);
            }
            this.setState({ selectionMode: false });
            this.props.onSelectionFinish([...this.selectedItems]);
            this.props.onSelectionClear();
        };
        //TODO: Clean up duplication
        this.selectInListExclusively = (values) => {
            if (Array.from(values).some(x => !x.state.isSelected))
                this.clearSelection();
            this.updateWhiteListNodes();
            for (const item of values) {
                if (!this.isInIgnoreList(item.node)) {
                    item.setState({ isSelected: true });
                    this.selectedItems.add(item);
                }
            }
            this.setState({ selectionMode: true });
            this.props.onSelectionFinish([...this.selectedItems]);
        };
        this.selectInList = (values) => {
            this.updateWhiteListNodes();
            for (const item of values) {
                if (!this.isInIgnoreList(item.node) && !item.state.isSelected) {
                    item.setState({ isSelected: true });
                    this.selectedItems.add(item);
                }
            }
            this.setState({ selectionMode: true });
            this.props.onSelectionFinish([...this.selectedItems]);
        };
        this.selectAll = () => {
            this.selectInList(this.registry.values());
        };
        this.mouseDown = (e) => {
            const isNotLeftButtonClick = !e.type.includes('touch') &&
                !detectMouseButton(e, 1, { allowShiftClick: this.props.allowShiftClick });
            if (this.mouseDownStarted || this.props.disabled || isNotLeftButtonClick) {
                return;
            }
            this.updateWhiteListNodes();
            if (this.isInIgnoreList(e.target)) {
                this.mouseDownStarted = false;
                return;
            }
            if (this.props.resetOnStart) {
                this.clearSelection();
            }
            this.mouseDownStarted = true;
            this.mouseUpStarted = false;
            const evt = castTouchToMouseEvent(e);
            if (!this.props.globalMouse && !isNodeInRoot(evt.target, this.selectableGroup)) {
                const offsetData = getBoundsForNode(this.selectableGroup);
                const collides = doObjectsCollide({
                    top: offsetData.top,
                    left: offsetData.left,
                    width: 0,
                    height: 0,
                    offsetHeight: offsetData.offsetHeight,
                    offsetWidth: offsetData.offsetWidth
                }, {
                    top: evt.pageY,
                    left: evt.pageX,
                    width: 0,
                    height: 0,
                    offsetWidth: 0,
                    offsetHeight: 0
                });
                if (!collides) {
                    return;
                }
            }
            this.updateRootBounds();
            this.updateRegistry();
            this.mouseDownData = {
                target: evt.target,
                selectboxY: evt.clientY - this.scrollBounds.top + this.scrollContainer.scrollTop,
                selectboxX: evt.clientX - this.scrollBounds.left + this.scrollContainer.scrollLeft
            };
            // evt.preventDefault()
            document.addEventListener('mousemove', this.updateSelectBox);
            document.addEventListener('touchmove', this.updateSelectBox);
            document.addEventListener('mouseup', this.mouseUp);
            document.addEventListener('touchend', this.mouseUp);
        };
        this.mouseUp = (event) => {
            if (this.mouseUpStarted) {
                return;
            }
            this.mouseUpStarted = true;
            this.mouseDownStarted = false;
            this.removeTempEventListeners();
            if (!this.mouseDownData) {
                return;
            }
            const evt = castTouchToMouseEvent(event);
            const { pageX, pageY } = evt;
            if (!this.mouseMoved && isNodeInRoot(evt.target, this.selectableGroup)) {
                this.handleClick(evt, pageY, pageX);
            }
            else {
                for (const item of this.selectingItems.values()) {
                    item.setState({ isSelected: true, isSelecting: false });
                }
                this.selectedItems = new Set([...this.selectedItems, ...this.selectingItems]);
                this.selectingItems.clear();
                if (evt.which === 1 && this.mouseDownData.target === evt.target) {
                    this.preventEvent(evt.target, 'click');
                }
                this.selectbox.setState({
                    isSelecting: false,
                    width: 0,
                    height: 0
                });
                this.props.onSelectionFinish([...this.selectedItems]);
            }
            this.toggleSelectionMode();
            this.cleanUp();
            this.mouseMoved = false;
        };
        this.keyListener = (evt) => {
            if (evt.keyCode === 27) {
                // escape
                this.clearSelection();
            }
        };
        this.getGroupRef = (ref) => {
            this.selectableGroup = ref;
        };
        this.getSelectboxRef = (ref) => {
            this.selectbox = ref;
        };
        this.defaultContainerStyle = {
            position: 'relative'
        };
        this.contextValue = {
            selectable: {
                register: this.registerSelectable,
                unregister: this.unregisterSelectable,
                selectInList: this.selectInListExclusively,
                selectAll: this.selectAll,
                clearSelection: this.clearSelection,
                getScrolledContainer: () => this.scrollContainer
            }
        };
    }
    componentDidMount() {
        if (this.props.scrollContainer) {
            this.scrollContainer = document.querySelector(this.props.scrollContainer);
        }
        else {
            this.scrollContainer = this.selectableGroup;
        }
        this.selectableGroup.addEventListener('mousedown', this.mouseDown);
        this.selectableGroup.addEventListener('touchstart', this.mouseDown);
        if (this.props.deselectOnEsc) {
            document.addEventListener('keydown', this.keyListener);
            document.addEventListener('keyup', this.keyListener);
        }
    }
    componentWillUnmount() {
        this.selectableGroup.removeEventListener('mousedown', this.mouseDown);
        this.selectableGroup.removeEventListener('touchstart', this.mouseDown);
        if (this.props.deselectOnEsc) {
            document.removeEventListener('keydown', this.keyListener);
            document.removeEventListener('keyup', this.keyListener);
        }
        this.removeTempEventListeners();
    }
    removeTempEventListeners() {
        document.removeEventListener('mousemove', this.updateSelectBox);
        document.removeEventListener('touchmove', this.updateSelectBox);
        document.removeEventListener('mouseup', this.mouseUp);
        document.removeEventListener('touchend', this.mouseUp);
    }
    updateRootBounds() {
        this.scrollBounds = this.scrollContainer.getBoundingClientRect();
        this.maxScrollTop = this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight;
        this.maxScrollLeft = this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;
    }
    toggleSelectionMode() {
        const { selectedItems, state: { selectionMode } } = this;
        if (selectedItems.size && !selectionMode) {
            this.setState({ selectionMode: true });
        }
        if (!selectedItems.size && selectionMode) {
            this.setState({ selectionMode: false });
        }
    }
    processItem(options) {
        const { item, tolerance, selectboxBounds, enableDeselect, mixedDeselect, isFromClick } = options;
        if (this.isInIgnoreList(item.node)) {
            return null;
        }
        const isCollided = doObjectsCollide(selectboxBounds, item.bounds, tolerance, this.props.delta);
        const { isSelecting, isSelected } = item.state;
        if (isFromClick && isCollided) {
            if (isSelected) {
                this.selectedItems.delete(item);
            }
            else {
                this.selectedItems.add(item);
            }
            item.setState({ isSelected: !isSelected });
            return (this.clickedItem = item);
        }
        if (!isFromClick && isCollided) {
            if (isSelected && enableDeselect && (!this.selectionStarted || mixedDeselect)) {
                item.setState({ isSelected: false });
                item.deselected = true;
                this.deselectionStarted = true;
                return this.selectedItems.delete(item);
            }
            const canSelect = mixedDeselect ? !item.deselected : !this.deselectionStarted;
            if (!isSelecting && !isSelected && canSelect) {
                item.setState({ isSelecting: true });
                this.selectionStarted = true;
                this.selectingItems.add(item);
                return { updateSelecting: true };
            }
        }
        if (!isFromClick && !isCollided && isSelecting) {
            if (this.selectingItems.has(item)) {
                item.setState({ isSelecting: false });
                this.selectingItems.delete(item);
                return { updateSelecting: true };
            }
        }
        return null;
    }
    isInIgnoreList(target) {
        if (!target) {
            return;
        }
        if (this.ignoreCheckCache.get(target) !== undefined) {
            return this.ignoreCheckCache.get(target);
        }
        const shouldBeIgnored = this.ignoreListNodes.some(ignoredNode => target === ignoredNode || ignoredNode.contains(target));
        this.ignoreCheckCache.set(target, shouldBeIgnored);
        return shouldBeIgnored;
    }
    updateWhiteListNodes() {
        this.ignoreListNodes = Array.from(document.querySelectorAll(this.ignoreList.join(', ')));
    }
    preventEvent(target, type) {
        const preventHandler = (evt) => {
            target.removeEventListener(type, preventHandler, true);
            evt.preventDefault();
            evt.stopPropagation();
        };
        target.addEventListener(type, preventHandler, true);
    }
    handleClick(evt, top, left) {
        if (!this.props.selectOnClick) {
            return;
        }
        const { clickClassName, allowClickWithoutSelected, onSelectionFinish } = this.props;
        const classNames = evt.target.classList || [];
        const isMouseUpOnClickElement = Array.from(classNames).indexOf(clickClassName) > -1;
        if (allowClickWithoutSelected ||
            this.selectedItems.size ||
            isMouseUpOnClickElement ||
            evt.ctrlKey) {
            this.selectItems({
                top,
                left,
                width: 0,
                height: 0,
                offsetWidth: 0,
                offsetHeight: 0
            }, { isFromClick: true });
            onSelectionFinish([...this.selectedItems], this.clickedItem);
            if (evt.which === 1) {
                this.preventEvent(evt.target, 'click');
            }
            if (evt.which === 2 || evt.which === 3) {
                this.preventEvent(evt.target, 'contextmenu');
            }
        }
    }
    cleanUp() {
        this.deselectionStarted = false;
        this.selectionStarted = false;
        if (this.props.mixedDeselect) {
            for (const item of this.registry.values()) {
                item.deselected = false;
            }
        }
    }
    render() {
        const { selectionMode } = this.state;
        const { component: GroupComponent = 'div', className, style, selectionModeClass, fixedPosition, selectboxClassName, children } = this.props;
        return (React.createElement(SelectableGroupContext.Provider, { value: this.contextValue },
            React.createElement(GroupComponent, { ref: this.getGroupRef, style: Object.assign({}, this.defaultContainerStyle, style), className: `${className} ${selectionMode ? selectionModeClass : ''}` },
                React.createElement(Selectbox, { ref: this.getSelectboxRef, className: selectboxClassName, fixedPosition: fixedPosition }),
                children)));
    }
}
SelectableGroup.defaultProps = {
    clickClassName: '',
    tolerance: 0,
    globalMouse: false,
    ignoreList: [],
    scrollSpeed: 0.25,
    minimumSpeedFactor: 60,
    duringSelection: noop,
    onSelectionFinish: noop,
    onSelectionClear: noop,
    allowClickWithoutSelected: true,
    selectionModeClass: 'in-selection-mode',
    resetOnStart: false,
    disabled: false,
    deselectOnEsc: true,
    fixedPosition: false,
    delta: 1,
    allowShiftClick: false,
    selectOnClick: true
};
export default SelectableGroup;
