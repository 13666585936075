import React from "react";
import styled from "styled-components";
import MultiSelect from "../../../../../MultiSelect/MultiSelect";
const StyledMultiSelectFilterRow = styled.div `
  height: max-content;
  width: calc(100% - 1rem);
  padding: 0.5rem;
`;
class MultiSelectFilterRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleMultiSelectUpdate = (event) => {
            this.props.filterRowActions.multiSelectUpdated({
                selectedKeys: event.selected.map(x => x.key),
                type: this.props.type,
                index: this.props.index
            });
        };
    }
    render() {
        return (React.createElement(StyledMultiSelectFilterRow, { className: "multi-select-filter-row" },
            React.createElement(MultiSelect, { showOpposite: true, multiSelectUpdated: this.handleMultiSelectUpdate, multiSelectData: this.props.filterRowData.multiSelectData, selectedKeys: this.props.filterRowState.selectedKeys })));
    }
}
export default MultiSelectFilterRow;
