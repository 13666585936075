import React from "react";
import axios from "axios";
import { Subject } from "rxjs";
import { bufferTime, filter } from "rxjs/operators";
var SERVICE_STATUS;
(function (SERVICE_STATUS) {
    SERVICE_STATUS["STOPPED"] = "STOPPED";
    SERVICE_STATUS["ONLINE"] = "ONLINE";
    SERVICE_STATUS["ERROR"] = "ERROR";
})(SERVICE_STATUS || (SERVICE_STATUS = {}));
class AVLTestToolContainer extends React.Component {
    constructor(props) {
        super(props);
        this.geoLocStream = new Subject();
        this.processingQueue = false;
        this.handleDeviceIdChange = (event) => {
            this.setState({ deviceId: event.target.value });
        };
        this.processGeoLocation = (pos) => this.geoLocStream.next(pos);
        this.geoPositionError = (err) => {
            let error = err.message || "An unknown error occurred.";
            switch (err.code) {
                case err.PERMISSION_DENIED:
                    error = "User denied the request for Geolocation.";
                    break;
                case err.POSITION_UNAVAILABLE:
                    error = "Location information is unavailable.";
                    break;
                case err.TIMEOUT:
                    error = "The request to get user location timed out.";
                    break;
            }
            this.setState({
                serviceStatus: SERVICE_STATUS.ERROR,
                error
            });
        };
        this.handleServiceChange = () => {
            const { serviceStatus, deviceId, queue } = this.state;
            if (serviceStatus === SERVICE_STATUS.STOPPED) {
                if (!deviceId) {
                    this.setState({
                        error: "Device ID is required to start the service."
                    });
                }
                else {
                    this.setState({
                        error: ""
                    });
                    if (navigator.geolocation) {
                        this.geoLocId = navigator.geolocation.watchPosition(this.processGeoLocation, this.geoPositionError, {
                            enableHighAccuracy: false
                        });
                        if (!this.processingQueue && queue.length) {
                            this.processingQueue = true;
                            this.processQueue(true);
                        }
                    }
                    else {
                        this.setState({
                            serviceStatus: SERVICE_STATUS.ERROR,
                            error: "Geolocation is not supported by this browser."
                        });
                    }
                }
            }
            else {
                this.setState({
                    serviceStatus: SERVICE_STATUS.STOPPED,
                    error: ""
                });
                if (this.geoLocId)
                    navigator.geolocation.clearWatch(this.geoLocId);
                this.stopQueueProcess();
            }
        };
        this.stopQueueProcess = () => {
            this.processingQueue = false;
            if (this.processQueueTimer)
                clearTimeout(this.processQueueTimer);
        };
        this.clearQueue = () => {
            this.setState({ queue: [] }, () => {
                this.stopQueueProcess();
            });
        };
        this.processQueue = (init) => {
            const { queue, deviceId, serviceStatus } = this.state;
            if (!init && serviceStatus === SERVICE_STATUS.STOPPED) {
                this.stopQueueProcess();
                return;
            }
            const pos = queue[queue.length - 1];
            if (!pos) {
                console.log(pos);
            }
            const coords = pos.coords, lat = coords.latitude, lon = coords.longitude, timestamp = pos.timestamp, altitude = coords.altitude, speed = coords.speed;
            let params = { id: deviceId, lat, lon, timestamp };
            if (altitude) {
                params.altitude = altitude;
            }
            if (speed) {
                params.speed = speed;
            }
            axios({
                method: "POST",
                url: `/avlserver/`,
                // url: `/avl/?id=avltest1&lat=${pos.coords.latitude}&lon=${pos.coords.longitude}&timestamp=${pos.timestamp}&hdop={3}&altitude={4}&speed={5}`
                params
            }).then(rsp => {
                console.log(rsp);
                const _queue = this.state.queue;
                _queue.pop();
                this.setState({
                    queue: _queue,
                    serviceStatus: SERVICE_STATUS.ONLINE,
                    error: `lat: ${lat}\nlon: ${lon}\nspeed: ${speed}`
                }, () => {
                    if (_queue.length) {
                        this.processQueue(false);
                    }
                    else {
                        this.stopQueueProcess();
                    }
                });
            }).catch((err) => {
                this.setState({
                    serviceStatus: SERVICE_STATUS.ERROR,
                    error: `${err.response.status} - ${err.response.statusText}\n${err.message}`
                });
                this.processQueueTimer = setTimeout(() => {
                    this.processQueue(false);
                }, 10000);
            });
        };
        this.state = {
            serviceStatus: SERVICE_STATUS.STOPPED,
            deviceId: "",
            error: "",
            queue: []
        };
    }
    componentDidMount() {
        this.geoLocStream.pipe(bufferTime(100)).pipe(filter(e => e.length > 0))
            .subscribe((posArray) => {
            const _queue = this.state.queue;
            const triggerProcess = _queue.length === 0;
            this.setState({
                queue: [...posArray, ..._queue]
            }, () => {
                if (triggerProcess) {
                    this.processingQueue = true;
                    this.processQueue(true);
                }
            });
        });
    }
    render() {
        const { serviceStatus, deviceId, error, queue } = this.state;
        const serviceStarted = serviceStatus !== SERVICE_STATUS.STOPPED;
        const statusColor = serviceStatus === SERVICE_STATUS.ONLINE ? "green" : (serviceStatus === SERVICE_STATUS.ERROR ? "red" : 'black'), errorColor = serviceStatus !== SERVICE_STATUS.ONLINE ? "red" : 'black';
        return React.createElement("div", { style: { padding: 20, fontSize: 15 } },
            React.createElement("label", { htmlFor: "deviceid" }, "Device ID: "),
            React.createElement("input", { type: "text", id: "deviceid", name: "deviceid", value: deviceId, onChange: this.handleDeviceIdChange, size: 20, disabled: serviceStarted }),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("button", { type: "button", style: {}, onClick: this.handleServiceChange }, `${serviceStatus === SERVICE_STATUS.STOPPED ? 'Start' : 'Stop'} service`),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("label", { htmlFor: "serviceStatus" }, "Status: "),
            React.createElement("input", { type: "text", id: "serviceStatus", name: "status", size: 15, disabled: true, value: `${serviceStatus} ${queue.length ? `(${queue.length})` : ''}`, style: { color: statusColor, fontWeight: 600 } }),
            serviceStatus !== SERVICE_STATUS.ONLINE && queue.length > 0 && React.createElement("button", { type: "button", style: { marginLeft: 10 }, onClick: this.clearQueue }, "Clear"),
            React.createElement("br", null),
            React.createElement("br", null),
            !!error && React.createElement("textarea", { id: "errordetails", name: "errordetails", rows: 6, cols: 33, disabled: true, style: { color: errorColor }, value: error }));
    }
}
const AVLTestTool = (props) => {
    return (React.createElement(AVLTestToolContainer, null));
};
export default AVLTestTool;
