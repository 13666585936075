import React from "react";
import { Modal, getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import { StyleUtils } from "../../../helper/Style/StyleUtils";
import Title from "../Title/Title";
const StyledModalScreenWrapper = styled.div `
  height: ${props => StyleUtils.getCssPixelString(Math.min(props.height, props.maxHeight || props.height))};
  width: ${props => StyleUtils.getCssPixelString(Math.min(props.width, props.maxWidth || props.width))};
  background-color: ${props => props.palette.white};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
`;
const StyledChildrenWrapper = styled.div `
  height: calc(100% - 1.6rem);
  width: 100%;
`;
class ModalScreen extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.updateWindowDimensions = () => {
            this.setState({
                width: Math.max(window.innerWidth - this.props.minDistanceFromEdge, 0),
                height: Math.max(window.innerHeight - this.props.minDistanceFromEdge, 0)
            });
        };
        this.handleClose = () => {
            if (this.props.onDismiss) {
                this.props.onDismiss();
            }
        };
        this.state = {
            width: 0,
            height: 0
        };
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }
    render() {
        const { isVisible, isBlocking, titleText } = this.props;
        return (React.createElement(Modal, { isOpen: isVisible, isBlocking: isBlocking, onDismiss: this.handleClose },
            React.createElement(StyledModalScreenWrapper, Object.assign({ className: "modal-screen-wrapper" }, this.props, this.state, this.theme),
                React.createElement(Title, { titleDisplayedText: titleText || "", handleClose: this.handleClose }),
                React.createElement(StyledChildrenWrapper, { className: "modal-screen-children-wrapper" }, this.props.children))));
    }
}
export default ModalScreen;
