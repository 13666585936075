import React from "react";
import styled from "styled-components";
import SimpleCheckbox from "../../../SimpleCheckbox/SimpleCheckbox";
import SimpleButton from "../../../SimpleButton/SimpleButton";
import { getTheme } from "office-ui-fabric-react";
const StyledMultiSelectLeftPanelControl = styled.div `
  width: 20rem;
  height: 1.8rem;
  padding: 0.2rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;
const StyledMultiSelectLeftPanelSearchBar = styled.input `
  height: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  width: ${props => (props.showOpposite ? "9.9rem" : "15rem")};
  color: ${props => props.fontColor || props.palette.black};
  user-select: none;
`;
const ButtonSeparator = styled.div `
  height: 1.8rem;
  width: 0.5rem;
`;
class MultiSelectLeftPanelControl extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.getCheckbox = () => {
            if (this.props.showOpposite) {
                return (React.createElement(SimpleCheckbox, { id: 0, label: "!=", wrapperPadding: "0 0 0 0.5rem", wrapperWidth: "5.1rem", textPadding: "0.3rem 0 0.3rem 0.5rem", textWidth: "1.8rem", onCheckboxChangeHandler: this.props.handleToggleOpposite }));
            }
            else {
                return React.createElement(ButtonSeparator, null);
            }
        };
        this.handleSearchBarChange = (event) => {
            const newValue = event.currentTarget ? event.currentTarget.value || "" : "";
            this.props.handleSearchBarChange(newValue);
        };
    }
    render() {
        const { handlePersistPendingSelect, handleAllSelect } = this.props;
        const color = this.props.color || this.theme.palette.black;
        const backgroundColor = this.props.backgroundColor || this.theme.palette.white;
        return (React.createElement(StyledMultiSelectLeftPanelControl, null,
            React.createElement(StyledMultiSelectLeftPanelSearchBar, Object.assign({}, this.props, this.theme, { onChange: this.handleSearchBarChange, placeholder: "Search..." })),
            this.getCheckbox(),
            React.createElement(SimpleButton, { className: "select-icon", iconProps: {
                    iconName: "SL-Arrow-Right",
                    size: "1.2rem"
                }, color: color, backgroundColor: backgroundColor, borderRadius: "0.2rem", padding: "0.2rem", iconOffsetY: "-0.1rem", borderWidth: "0.1rem", buttonClick: handlePersistPendingSelect }),
            React.createElement(ButtonSeparator, null),
            React.createElement(SimpleButton, { className: "select-all-icon", iconProps: {
                    iconName: "SL-Arrow-DoubleRight",
                    size: "1.2rem"
                }, color: color, backgroundColor: backgroundColor, borderRadius: "0.2rem", padding: "0.2rem", iconOffsetY: "-0.1rem", borderWidth: "0.1rem", buttonClick: handleAllSelect })));
    }
}
export default MultiSelectLeftPanelControl;
