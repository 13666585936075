import React from "react";
import { getTheme } from "office-ui-fabric-react";
import styled from "styled-components";
import SimpleButton from "../../../../../../components/SimpleButton/SimpleButton";
const StyledErrorMessageDetailsUpdate = styled.div `
  float: right;
`;
class ErrorMessageDetailsUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.theme = getTheme();
        this.handleMessageUpdate = () => {
            this.props.handleMessageUpdate();
        };
    }
    render() {
        return (React.createElement(StyledErrorMessageDetailsUpdate, null,
            React.createElement(SimpleButton, { className: "error-message-details-update-button", padding: "0.3rem 1.1rem", color: this.theme.palette.black, backgroundColor: this.theme.palette.white, textProps: {
                    width: "10rem",
                    height: "1.2rem",
                    iconText: "Update Messages",
                    textAlign: "center",
                    fontWeight: "bold"
                }, borderWidth: "0.1rem", borderRadius: "0.2rem", buttonClick: this.handleMessageUpdate })));
    }
}
export default ErrorMessageDetailsUpdate;
