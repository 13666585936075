import SockJS from "sockjs-client";
import { Observable } from "rxjs";
import { Utils } from "../General/Utils";
import { InventoryTableContainerActionTypes } from "../../state/containers/InventoryTableContainer/types";
import { AppStore } from "../../App";
export const WEBSOCKET_PATH = "/ws/inventory";
export const MAX_RESULT_PER_REQUEST = 10000;
const autoReconnect = true;
const autoReconnectTime = 10000;
const webSocketTimeout = 30000;
const modifiedServerOriginMessageBufferTime = 1000;
const createdDeletedServerOriginMessageBufferTime = 3000;
const logMessageTimings = false;
const logSendMessages = false;
const logReturnMessages = false;
export function SLStateChangeServerOriginMessageProcessor(message) {
    return {
        entityType: message.entityType,
        uniqueId: message.id,
        stateName: message.state,
        stateId: message.stateId,
        state: message.stateNumber,
        system: message.system,
        timestamp: message.timestamp
    };
}
export function SLOwnerChangeServerOriginMessageProcessor(message) {
    return {
        uniqueId: message.id,
        id: message.inventoryId,
        owner: message.owner,
        parent: message.parent,
        path: message.path,
        timestamp: message.timestamp,
        type: message.type
    };
}
export var SERVER_ORIGIN_MESSAGE_ACTION_TYPES;
(function (SERVER_ORIGIN_MESSAGE_ACTION_TYPES) {
    SERVER_ORIGIN_MESSAGE_ACTION_TYPES["MODIFIED"] = "modified";
    SERVER_ORIGIN_MESSAGE_ACTION_TYPES["CREATED"] = "created";
    SERVER_ORIGIN_MESSAGE_ACTION_TYPES["DELETED"] = "deleted";
})(SERVER_ORIGIN_MESSAGE_ACTION_TYPES || (SERVER_ORIGIN_MESSAGE_ACTION_TYPES = {}));
export var SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES;
(function (SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES) {
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["ORDERS"] = "orders";
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["STATES"] = "states";
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["INVENTORIES"] = "inventories";
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["FSRS"] = "fsrs";
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["BUS"] = "bus";
    SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES["COMPANIES"] = "companies";
})(SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES || (SERVER_ORIGIN_MESSAGE_SYSTEM_TYPES = {}));
//Note: All incoming responses are converted to camelCase. All outgoing requests are converted to snake_case.
export var WebSocketService;
(function (WebSocketService) {
    let socket = null;
    let authCorrelationId = "";
    let isAuthenticated = false;
    let isConnected = false;
    let reconnecting = false;
    let responseMessageObservable = null;
    let serverEventObservable = null;
    let serverEventModifiedObservable = null;
    let serverEventModifiedObservableBuffered = null;
    let serverEventCreatedDeletedObservable = null;
    let serverEventCreatedObservable = null;
    let serverEventCreatedObservableBuffered = null;
    let serverEventDeletedObservable = null;
    let serverEventDeletedObservableBuffered = null;
    let authResponseMessageObservable = null;
    let webSocketPerformanceObject = {};
    WebSocketService.outBoundConversionExceptions = ["query"];
    WebSocketService.inBoundConversionExceptions = [];
    function init() {
        startWebSocket();
        return authResponseMessageObservable
            .first(response => response.correlation === authCorrelationId)
            .map(response => !!response.success)
            .toPromise();
    }
    WebSocketService.init = init;
    function startWebSocket() {
        getNewSocket();
        attachHandlers();
    }
    WebSocketService.startWebSocket = startWebSocket;
    function getNewSocket() {
        console.log("Attempting to get a new Websocket...");
        if (socket) {
            closeSocket();
        }
        else {
            socket = new SockJS(new URL(WEBSOCKET_PATH, window.location.origin).href);
        }
    }
    function closeSocket() {
        isAuthenticated = false;
        socket.close();
        socket = null;
    }
    function handleOnOpen() {
        socket.onopen = () => {
            console.log(`WebSocket Opened.`);
            isConnected = true;
            authenticate();
        };
    }
    function handleOnClose() {
        socket.onclose = () => {
            console.log(`Websocket Closed.`);
            isConnected = false;
            isAuthenticated = false;
            reconnect();
        };
    }
    function handleOnMessage() {
        let messageObservable = Observable.create((subscriber) => {
            socket.onmessage = (event) => {
                subscriber.next(event);
                if (logReturnMessages) {
                    console.log(Utils.snakeToCamelCaseObject(JSON.parse(event.data), WebSocketService.inBoundConversionExceptions));
                }
                if (logMessageTimings) {
                    const correlation = JSON.parse(event.data).correlation;
                    if (correlation) {
                        setMessagePerformanceTime(MessagePerformanceTimes.MESSAGE_RECEIVED_TIME, correlation);
                        logMessagePerformance(correlation);
                        deleteMessagePerformanceTime(correlation);
                    }
                }
            };
        })
            .map(event => Utils.snakeToCamelCaseObject(JSON.parse(event.data), WebSocketService.inBoundConversionExceptions))
            .share();
        [responseMessageObservable, serverEventObservable] = messageObservable.partition(response => !!response.correlation);
        [serverEventModifiedObservable, serverEventCreatedDeletedObservable] = serverEventObservable.partition(response => {
            return response.action === SERVER_ORIGIN_MESSAGE_ACTION_TYPES.MODIFIED;
        });
        serverEventModifiedObservableBuffered = serverEventModifiedObservable.bufferTime(modifiedServerOriginMessageBufferTime);
        [serverEventCreatedObservable, serverEventDeletedObservable] = serverEventCreatedDeletedObservable.partition(response => {
            return response.action === SERVER_ORIGIN_MESSAGE_ACTION_TYPES.CREATED;
        });
        serverEventCreatedObservableBuffered = serverEventCreatedObservable.bufferTime(createdDeletedServerOriginMessageBufferTime);
        serverEventDeletedObservableBuffered = serverEventDeletedObservable.bufferTime(createdDeletedServerOriginMessageBufferTime);
        authResponseMessageObservable = messageObservable
            .filter(response => response.correlation === authCorrelationId)
            .map(response => response);
    }
    function handleOnError() {
        socket.onerror = (error) => {
            reconnect();
        };
    }
    function reconnect() {
        if (autoReconnect && !reconnecting) {
            reconnecting = true;
            window.setInterval(() => {
                if (isConnected) {
                    window.clearInterval();
                    reconnecting = false;
                }
                else {
                    startWebSocket();
                }
            }, autoReconnectTime);
        }
    }
    function sendRequest(request) {
        if (!request.correlation) {
            request.correlation = Utils.generateUUIDV1();
        }
        if (logMessageTimings) {
            setMessagePerformanceTime(MessagePerformanceTimes.MESSAGE_START_TIME, request.correlation);
        }
        let returnPromise = null;
        if (socket) {
            returnPromise = responseMessageObservable
                .first(response => response.correlation === request.correlation)
                .timeout(webSocketTimeout)
                .toPromise();
            if (logSendMessages) {
                console.log(Utils.camelToSnakeCaseObject(request, WebSocketService.outBoundConversionExceptions));
            }
            socket.send(JSON.stringify(Utils.camelToSnakeCaseObject(request, WebSocketService.outBoundConversionExceptions)));
            if (logMessageTimings) {
                setMessagePerformanceTime(MessagePerformanceTimes.MESSAGE_SENT_TIME, request.correlation);
            }
        }
        else {
            returnPromise = new Promise((resolve, reject) => {
                throw new Error("Socket is closed!");
            });
            if (logMessageTimings) {
                deleteMessagePerformanceTime(request.correlation);
            }
        }
        return returnPromise;
    }
    WebSocketService.sendRequest = sendRequest;
    function handleServerUpdate() {
        serverEventModifiedObservableBuffered.subscribe(responses => {
            const filteredResponses = [];
            responses.forEach(response => {
                const matchingResponses = filteredResponses.find(filteredResponse => filteredResponse.eventData.id === response.eventData.id);
                if (!matchingResponses) {
                    filteredResponses.push(response);
                }
            });
            updateClientModified(filteredResponses);
        });
        serverEventCreatedObservableBuffered.subscribe(responses => {
            updateClientCreated(responses);
        });
        serverEventDeletedObservableBuffered.subscribe(responses => {
            updateClientDeleted(responses);
        });
    }
    function updateClientModified(responses) {
        if (responses && responses.length > 0) {
            AppStore.store.dispatch({
                type: InventoryTableContainerActionTypes.HANDLE_SERVER_ORIGIN_MESSAGE,
                messageType: SERVER_ORIGIN_MESSAGE_ACTION_TYPES.MODIFIED,
                messages: responses
            });
        }
    }
    function updateClientCreated(responses) {
        if (responses && responses.length > 0) {
            AppStore.store.dispatch({
                type: InventoryTableContainerActionTypes.HANDLE_SERVER_ORIGIN_MESSAGE,
                messageType: SERVER_ORIGIN_MESSAGE_ACTION_TYPES.CREATED,
                messages: responses
            });
        }
    }
    function updateClientDeleted(responses) {
        if (responses && responses.length > 0) {
            AppStore.store.dispatch({
                type: InventoryTableContainerActionTypes.HANDLE_SERVER_ORIGIN_MESSAGE,
                messageType: SERVER_ORIGIN_MESSAGE_ACTION_TYPES.DELETED,
                messages: responses
            });
        }
    }
    function attachHandlers() {
        if (socket) {
            handleOnOpen();
            handleOnClose();
            handleOnMessage();
            handleOnError();
            handleServerUpdate();
        }
        else {
            reconnect();
        }
    }
    function authenticate() {
        console.log(`Authenticating...`);
        const correlationId = Utils.generateUUIDV1();
        authCorrelationId = correlationId;
        const request = {
            correlation: correlationId,
            service: "auth",
            action: "connect",
            request: {
                token: Utils.getCookieByName("X-ServiceLink-Auth")
            }
        };
        sendRequest(request)
            .then(response => {
            const authResponse = response;
            if (authResponse.correlation === authCorrelationId) {
                if (authResponse.success) {
                    console.log(`Websocket Authentication Success!`);
                    authCorrelationId = "";
                    isAuthenticated = true;
                }
                else {
                    console.log(`Websocket Authentication Failed!`);
                    console.log(response.error);
                    authCorrelationId = "";
                    isAuthenticated = false;
                }
            }
        })
            .catch(error => {
            console.log(`Websocket Authentication Failed!`);
            isAuthenticated = false;
        });
    }
    let MessagePerformanceTimes;
    (function (MessagePerformanceTimes) {
        MessagePerformanceTimes["MESSAGE_START_TIME"] = "MESSAGE_START_TIME";
        MessagePerformanceTimes["MESSAGE_SENT_TIME"] = "MESSAGE_SENT_TIME";
        MessagePerformanceTimes["MESSAGE_RECEIVED_TIME"] = "MESSAGE_RECEIVED_TIME";
    })(MessagePerformanceTimes || (MessagePerformanceTimes = {}));
    function logMessagePerformance(correlation) {
        const performanceObject = webSocketPerformanceObject[correlation];
        console.info(`Message with correlation ID: ${correlation} was sent in ${performanceObject.messageSentTime -
            performanceObject.messageStartTime}ms and was received in ${performanceObject.messageReceivedTime -
            performanceObject.messageStartTime}ms.`);
    }
    function setMessagePerformanceTime(timeType, correlation) {
        const currentTime = performance.now();
        switch (timeType) {
            case MessagePerformanceTimes.MESSAGE_START_TIME:
                webSocketPerformanceObject[correlation] = {
                    messageStartTime: currentTime,
                    messageSentTime: null,
                    messageReceivedTime: null
                };
                break;
            case MessagePerformanceTimes.MESSAGE_SENT_TIME:
                webSocketPerformanceObject[correlation] = Object.assign(Object.assign({}, webSocketPerformanceObject[correlation]), { messageSentTime: currentTime });
                break;
            case MessagePerformanceTimes.MESSAGE_RECEIVED_TIME:
                webSocketPerformanceObject[correlation] = Object.assign(Object.assign({}, webSocketPerformanceObject[correlation]), { messageReceivedTime: currentTime });
                break;
        }
    }
    function deleteMessagePerformanceTime(correlation) {
        delete webSocketPerformanceObject[correlation];
    }
})(WebSocketService || (WebSocketService = {}));
