import axios from "axios";
import { Utils } from "../../../../helper/General/Utils";
export var Inventory;
(function (Inventory) {
    Inventory.AUTH_HEADER = "X-ServiceLink-Auth";
    var readFileAsString = Utils.readFileAsString;
    async function importInventoryUploadRequest(file) {
        let payload = {
            file_name: file.name,
            data: await readFileAsString(file)
        };
        return axios({
            method: "POST",
            url: "/sl/philly/csv/upload",
            data: payload,
            headers: {
                [Inventory.AUTH_HEADER]: Utils.getCookieByName(Inventory.AUTH_HEADER)
            }
        }).then(response => {
            let result = response.data;
            if (response.status !== 200) {
                return this.reject({
                    success: false
                });
            }
            return result;
        });
    }
    Inventory.importInventoryUploadRequest = importInventoryUploadRequest;
})(Inventory || (Inventory = {}));
